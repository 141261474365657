import { ObjectOccurrenceRelation } from '@/models/objectOccurrenceRelation';
import { ProgressStep } from '@/models/progressStep';
import { ComparisonResultAction } from '@/models/revision';

export interface SimoCellPreviewItem {
  id?: string;
  label: string;
  progressStep: ProgressStep;
  comparisonResultAction?: ComparisonResultAction;
}

export enum RelationType {
  Known,
  Unknown,
  NoRelation,
  NotSet,
}

export type SimoCellPreview =
  | {
      relationType:
        | RelationType.NoRelation
        | RelationType.Unknown
        | RelationType.NotSet;
    }
  | {
      relationType: RelationType.Known;
      items: SimoCellPreviewItem[];
      exceedingItems?: SimoCellPreviewItem[];
    };

export function getLabel(oor: ObjectOccurrenceRelation) {
  if (oor.no_relations) return '-';
  if (oor.unknown_relations) return '?';
  return `${getCode(oor)}${oor.number}` || '-';
}

export function getCode(oor: ObjectOccurrenceRelation) {
  const showOorSign =
    !!oor?.comparisonResult?.action &&
    (oor.no_relations || oor.unknown_relations);
  return showOorSign
    ? oor.name
    : (oor.classification_entry && oor.classification_entry.code) || '';
}

export function getProgressStep(
  oor: ObjectOccurrenceRelation
): ProgressStep | null {
  const steps = (oor.progress_step_checked || [])
    .map((stepChecked) => stepChecked.progress_step)
    .filter(Boolean);
  return steps.reduce(
    (maxStep, step) =>
      !maxStep || step.order > maxStep.order ? step : maxStep,
    null
  );
}

function compareProgressStep(
  aStep: ProgressStep | null,
  bStep: ProgressStep | null
): number {
  return ((aStep && aStep.order) ?? -1) - ((bStep && bStep.order) ?? -1);
}

export function simoCellPreview(
  oors: ObjectOccurrenceRelation[],
  itemsLimit?: number,
  ungroupRelations?: boolean
): SimoCellPreview {
  if (oors?.length <= 0) return { relationType: RelationType.NotSet };
  if (oors.length === 1 && oors[0].no_relations)
    return { relationType: RelationType.NoRelation };
  if (oors.length === 1 && oors[0].unknown_relations)
    return { relationType: RelationType.Unknown };
  const limit = itemsLimit || 3;
  if (oors.length <= limit || ungroupRelations) {
    return {
      relationType: RelationType.Known,
      items: oors
        .map((oor) => {
          const comparisonResultAction = oor?.comparisonResult?.action;
          const isUnknownOrNoRelation =
            oor.unknown_relations || oor.no_relations;
          const showRelationSign =
            comparisonResultAction && isUnknownOrNoRelation;
          const oorName = () => {
            const relationsSign = {
              no_relations: '-',
              unknown_relations: '?',
            };
            const oorSpecialRelation = oor.no_relations
              ? 'no_relations'
              : oor.unknown_relations
                ? 'unknown_relations'
                : null;
            return relationsSign[oorSpecialRelation] || '';
          };
          return {
            id: oor.id,
            label: showRelationSign
              ? oorName()
              : sameCodeIncluded(oors, oor)
                ? getLabel(oor)
                : getCode(oor),
            progressStep: getProgressStep(oor),
            comparisonResultAction: comparisonResultAction || null,
          };
        })
        .sort(
          (a, b) =>
            compareProgressStep(a.progressStep, b.progressStep) ||
            a.label.localeCompare(b.label)
        ),
    };
  }
  const classificationCodes = [...new Set(oors.map(getCode))];
  const items = classificationCodes
    .map((code, index) => {
      const groupProgressStep = oors
        .filter((oor) => getCode(oor) === code)
        .map(getProgressStep)
        .sort(compareProgressStep)[0];
      return {
        label: code || '',
        progressStep: groupProgressStep,
        comparisonResultAction: oors[index]?.comparisonResult?.action || null,
      };
    })
    .sort(
      (a, b) =>
        compareProgressStep(a.progressStep, b.progressStep) ||
        a.label.localeCompare(b.label)
    );
  const exceedingItems: SimoCellPreviewItem[] =
    items.length > limit ? items.slice(limit - 1) : [];

  if (exceedingItems.length === 0)
    return {
      relationType: RelationType.Known,
      items: items,
    };

  return {
    relationType: RelationType.Known,
    items: items.slice(0, limit - 1),
    exceedingItems,
  };
}

export function sortRelations(
  oors: ObjectOccurrenceRelation[]
): ObjectOccurrenceRelation[] {
  return oors.slice().sort((a, b) => {
    return getCode(a).localeCompare(getCode(b));
  });
}

export function sameCodeIncluded(
  oors: ObjectOccurrenceRelation[],
  oor: ObjectOccurrenceRelation
): boolean {
  const codeToFind = {
    code: getCode(oor),
    id: oor.id,
  };
  return !!oors.find((_oor) => {
    const oorCode = getCode(_oor);
    return oorCode === codeToFind.code && _oor.id !== codeToFind.id;
  });
}
