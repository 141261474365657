import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import jv from '../components/jv';

export default createModule({
  path: 'exportStruct',
  resourceProfile: resources.exportStruct,
  components: [crud, indexManager, jv],
  setup({ getAccessors, components, resourceProfile }) {
    const $crud = components.$crud.public;

    const actions = {
      async createExportStruct(
        context,
        payload: {
          contextId: string;
          indexData: { requestType: string; requestId: string };
          export_type?: 'ooc' | 'oor' | 'oodr';
        }
      ) {
        const resource = {
          export_type: payload.export_type || 'ooc',
        };
        const relationships = {
          context: payload.contextId,
        };

        try {
          const data = await $crud.dispatchCreateResource({
            resource,
            relationships,
            basePath: `/async/${resourceProfile.path}`,
          });
          components.$indexManager.public.dispatchInjectToPageIndex({
            resourceId: data.id,
            requestType: payload.indexData.requestType,
            requestId: payload.indexData.requestId,
            prepend: true,
          });
          return { id: data.id as string };
        } catch (err) {
          return Promise.reject(err);
        }
      },
    };

    const { dispatch } = getAccessors();

    return {
      module: {
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        dispatchCreateExportStruct: dispatch(actions.createExportStruct),
      },
    };
  },
});
