export function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(value, max));
}

/**
 * Returns: [min, min+1, min+2, ..., max-1]
 */
export function range(min: number, max: number): number[] {
  return Array(Math.max(max, min) - min)
    .fill(0)
    .map((_, i) => i + min);
}

export function toFixedTrunc(value: number, digits: number): string {
  if (digits < 0 || digits > 10 || Math.floor(digits) !== digits) {
    throw new Error('Digits must be an integer in range 0 - 10');
  }
  if (!digits) return Math.trunc(value).toString();
  let str = value.toString();
  let dotIndex = str.indexOf('.');
  if (dotIndex < 0) {
    str = str + '.';
    dotIndex = str.length - 1;
  }
  return `${str}0000000000`.slice(0, dotIndex + 1 + digits);
}
