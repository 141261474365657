import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';

export default createModule({
  path: 'ownership',
  resourceProfile: resources.ownerships,
  components: [crud],
  setup({ components }) {
    return {
      module: {},
      public: {
        ...components.$crud.public,
      },
    };
  },
});
