import {
  ResourceProfile,
  checkRelationshipPathIsEnabled,
} from '@/services/resources';

export interface ResourceIdentification {
  resourceId: string;
}

const filtersPayloadParameters = {
  query: 'query',
  stepsBackward: 'steps_backward',
  stepsForward: 'steps_forward',
  filterAncestorIds: 'filter[ancestor_id_in]',
  filterContextId: 'filter[context_id_eq]',
  filterUpstreamContextId: 'filter[upstream_context_id_eq]',
  filterBaseContextId: 'filter[base_context_id]',
  filterBaseContextsIds: 'filter[base_context_ids_eq]',
  filterBaseProjectId: 'filter[base_project_id]',
  filterTargetId: 'filter[target_id_eq]',
  filterTargetIdIn: 'filter[target_id_in]',
  filterTargetType: 'filter[target_type_eq]',
  filterProgressModelId: 'filter[progress_model_id_eq]',
  filterProgressStepInWithEmpty: 'filter[progress_step_in][with_empty]',
  filterProgressStepInOrderIn: 'filter[progress_step_in][order_in]',
  filterProgressStepsGte: 'filter[progress_steps_gte]',
  filterProgressStepsLte: 'filter[progress_steps_lte]',
  filterArchived: 'filter[archived]',
  filterPublished: 'filter[published]',
  filterProjectId: 'filter[project_id_eq]',
  filterSyntaxId: 'filter[syntax_id_eq]',
  filterSyntaxElementIds: 'filter[syntax_element_id_in]',
  filterArchivedOrHasArchivedContent: 'filter[archived_self_or_contexts]',
  filterClassificationTableType: 'filter[classification_table_type_eq]',
  filterOOCClassificationCodeIn: 'filter[ooc_classification_code_in]',
  filterOORClassificationCodeIn: 'filter[oor_classification_code_in]',
  filterOORClassificationTableIds: 'filter[classification_table_ids_cont]',
  filterClassificationTableId: 'filter[classification_table_id_eq]',
  filterClassificationTableIds: 'filter[classification_table_id_in]',
  filterClassificationParents: 'filter[classification_entry_id_blank]',
  filterClassificationEntryId: 'filter[classification_entry_id_eq]',
  filterObjectOccurrenceIdsCont: 'filter[object_occurrence_ids_cont]',
  filterObjectOccurrenceStructureLevelIn: 'filter[structure_level_in]',
  filterObjectOccurrenceSourceIdsCont:
    'filter[object_occurrence_source_ids_cont]',
  filterObjectOccurrenceTargetIdsCont:
    'filter[object_occurrence_target_ids_cont]',
  filterObjectOccurrenceHasNoChildren: 'filter[components_blank]',
  filterNotTradeStudyContext: 'filter[trade_study_id_blank]',
  filterWithExternals: 'filter[with_externals]',
  filterExclude: 'filter[exclude]',
  filterUserId: 'filter[user_id_eq]',
  filterOOCOwnerIds: 'filter[owners][owner_ids_in]',
  filterOOCOwnerGroupIds: 'filter[owners][owner_group_ids_in]',
  filterOOCOwnerCompaniesIn: 'filter[owners][companies_in]',
  filterOOCExcludeRelatedToOwned: 'filter[owners][exclusive]',
  filterWithoutOwnedOocs: 'filter[owned_oocs_blank]',
  filterOwnerTitle: 'filter[title_cont]',
  filterOwnerCompanyIn: 'filter[company_in]',
  filterOwnerGroupIn: 'filter[group_id_in]',
  filterNameContains: 'filter[name_cont]',
  filterParentId: 'filter[parent_id_eq]',
  filterDomaClassificationEntryWithoutParent:
    'filter[classification_entry_id_blank]',
  filterDomaClassificationEntryTableArchived:
    'filter[classification_table_archived]',
  filterDomaClassificationEntryTablePublished:
    'filter[classification_table_published]',
  filterContextIds: 'filter[context_id_in]',
  filterCreatedAfter: 'filter[created_after]',
  filterEquivalentId: 'filter[equivalent_of_id]',
  filterEquivalentIn: 'filter[equivalent_of_id_in]',
  filterSyntaxesWithClassification: 'filter[classified]',
} as const;

const wsPayloadParameters = {
  wsTargetType: 'type',
  wsTargetId: 'item_id',
  wsAuthorization: 'authorization',
  wsAuth0Domain: 'auth0_domain',
} as const;

const payloadParameters = {
  ...filtersPayloadParameters,
  ...wsPayloadParameters,
  page: 'page[number]',
  pageSize: 'page[size]',
  depth: 'depth',
  include: 'include',
  sort: 'sort',
} as const;

export function getOOCAnalysisIdentifier(context, oocId, steps) {
  return `${context}_${oocId}_${steps}`;
}

export type PathParameters = Partial<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<keyof typeof payloadParameters, any>
>;

export function getIndexRequestIdentifier<Params extends PathParameters>(
  payload: Params
): string {
  return buildParametersPath(payload, filtersPayloadParameters) || 'generic';
}

//  "/tags?filter[target_id_eq]=f08e7570-2c16-4d92-a379-5b443453428c&filter[target_type_eq]=ClassificationEntry"
export function buildParametersPath<Params extends PathParameters>(
  payload: Params,
  parametersDict: Record<string, string> = payloadParameters
): string {
  let parametersPath = '';
  if (payload) {
    const queryString = Object.entries(parametersDict)
      .map(([key, parameterKey]) => {
        const value = payload[key];
        if ([null, undefined, ''].includes(value)) return null;
        if (Array.isArray(value)) {
          return encodeListParameter(parameterKey, value);
        }
        return `${parameterKey}=${encodeURIComponent(value)}`;
      })
      .filter(Boolean)
      .join('&');
    parametersPath = queryString !== '' ? `?${queryString}` : '';
  }
  return parametersPath;
}

export function buildParametersMap(payload: PathParameters) {
  return Object.keys(payload)
    .filter((key) => payloadParameters[key])
    .reduce((acc, curr) => {
      return { ...acc, [payloadParameters[curr]]: payload[curr] };
    }, {});
}

export function buildRequestPath(resourceProfile, payload) {
  if (!resourceProfile.createByRelationship) {
    return resourceProfile.path;
  } else {
    const { relationshipKey, relationshipId } = payload;
    const { relationships, parentType, createAsComponent } = resourceProfile;
    const pathTarget = createAsComponent ? 'components' : resourceProfile.path;
    return relationshipId && relationshipKey
      ? `${relationships[relationshipKey].path}/${relationshipId}/relationships/${resourceProfile.path}`
      : `${relationships[parentType].path}/${payload.relationships[parentType]}/relationships/${pathTarget}`;
  }
}

export function buildMultipleRelationsRequestPath(payload: {
  baseResourceProfile: ResourceProfile;
  baseResourceId: string;
  relationships: Array<{
    resourceProfile: ResourceProfile;
    id: string;
    suffix?: string;
  }>;
}) {
  const { baseResourceProfile, baseResourceId, relationships } = payload;
  const relationPathFragments = getRelationshipPathFragments(relationships);
  return `/${baseResourceProfile.path}/${baseResourceId}/${relationPathFragments}`;
}

function getRelationshipPathFragments(
  relationships: Array<{
    resourceProfile: ResourceProfile;
    id: string;
    suffix?: string;
  }>
) {
  return relationships
    .map((rel) => {
      const basePath = `relationships/${rel.resourceProfile.path}/${rel.id}`;
      return rel.suffix ? `${basePath}/${rel.suffix}` : basePath;
    })
    .join('/');
}

export function encodeListParameter(key, list) {
  return list.map((value) => `${key}[]=${encodeURIComponent(value)}`).join('&');
}
export function extractPollingId(url: string) {
  const tokens = (url || '').split('/');
  return tokens.includes('polling') ? tokens[tokens.length - 1] : null;
}

export function buildIncludeParam(
  resourceProfile: ResourceProfile,
  manuallyIncludedPaths: string[] = []
) {
  const globallyIncludedPaths = resourceProfile?.includeRelationships || [];
  const param = globallyIncludedPaths
    .concat(manuallyIncludedPaths)
    .filter((p, i, all) => all.indexOf(p) === i) // deduplicate
    .filter((path) =>
      checkRelationshipPathIsEnabled({
        path: path.split('.'),
        relationships: resourceProfile?.relationships,
      })
    )
    .join(',');
  return param || undefined;
}

export const getProjectIdFromUrlPath = (path: string): string => {
  const pathSegments = path.split('/');
  const projectsIndex = pathSegments.indexOf('projects');
  if (projectsIndex !== -1 && pathSegments.length > projectsIndex + 1) {
    return pathSegments[projectsIndex + 1];
  }
  return '';
};

export const getContextIdFromUrlPath = (path: string): string => {
  const pathSegments = path.split('/');
  const projectsIndex = pathSegments.indexOf('projects');
  if (projectsIndex !== -1 && pathSegments.length > projectsIndex + 2) {
    return pathSegments[projectsIndex + 2];
  }
  return '';
};
