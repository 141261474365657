<template>
  <v-banner v-if="isVisible" class="banner-warning" lines="one">
    <slot />

    <template #actions>
      <slot name="actions" />
      <v-btn v-if="!permanent" icon @click="isVisible = false">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-banner>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({})
export default class BannerWarning extends Vue {
  @Prop(Boolean) permanent: boolean;

  isVisible = true;
}
</script>

<style lang="scss" scoped>
.banner-warning {
  flex: none;
  align-items: center;
  width: 100%;
  font-size: 14px;
  background-color: rgba(var(--v-theme-accent), 1);
}
</style>
