import { readonly, Ref, ref, UnwrapRef } from 'vue';
import { watchDebounced, WatchDebouncedOptions } from '@vueuse/core';

export const debouncedComputed = <T>(
  getter: Ref<T> | (() => T),
  options: WatchDebouncedOptions<false>
) => {
  const computedValue = ref(
    typeof getter === 'function' ? getter() : getter.value
  );
  watchDebounced(
    getter,
    (val) => {
      computedValue.value = val as UnwrapRef<T>;
    },
    options
  );
  return readonly(computedValue);
};
