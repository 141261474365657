import { FlatTreeNode } from '@/models/treeStructure';

export function treeCursor(
  event: KeyboardEvent,
  selectedNodeId: string,
  flatTree: readonly FlatTreeNode[],
  selectNodeHandler: (id: string) => void
): void {
  event.preventDefault();
  event.stopPropagation();
  selectNodeHandler(handleKey(event.key, selectedNodeId, flatTree));
}

function handleKey(
  key: string,
  selectedNodeId: string,
  flatTree: readonly FlatTreeNode[]
): string {
  const selectedNode = flatTree.find((it) => it.item.id === selectedNodeId);
  const nodeIndex = Math.max(
    0,
    flatTree.findIndex((el) => el.item.id === selectedNodeId)
  );
  switch (key) {
    case `ArrowUp`:
      return getPreviousNode({ nodeIndex, flatTree }).item.id;
    case `ArrowDown`:
      if (nodeIndex === 0) return flatTree[1].item.id;
      return getNextNode({ nodeIndex, flatTree }).item.id;
    case `ArrowRight`:
      if (selectedNode.item.children.length) {
        return selectedNode.item.children[0].id;
      } else {
        return selectedNodeId;
      }
    case `ArrowLeft`:
      if (selectedNode.item.parentId) {
        return selectedNode.item.parentId;
      } else {
        return selectedNodeId;
      }
    default:
      return selectedNodeId;
  }
}

const getPreviousNode = (payload: {
  nodeIndex: number;
  flatTree: readonly FlatTreeNode[];
}) => {
  const { nodeIndex, flatTree } = payload;
  const previousNode = flatTree
    .slice(0, nodeIndex)
    .reverse()
    .find((node) => node.item.parentId === flatTree[nodeIndex].item.parentId);
  return previousNode || flatTree[nodeIndex];
};

const getNextNode = (payload: {
  nodeIndex: number;
  flatTree: readonly FlatTreeNode[];
}) => {
  const { nodeIndex, flatTree } = payload;
  const nextNode = flatTree
    .slice(nodeIndex + 1)
    .find((node) => node.item.parentId === flatTree[nodeIndex].item.parentId);
  return nextNode || flatTree[nodeIndex];
};
