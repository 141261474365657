import { getCurrentInstance, computed } from 'vue';

export const useRouter = () => {
  const currentInstance = getCurrentInstance();

  return currentInstance.proxy.$router;
};

export const useRoute = () => {
  const currentInstance = getCurrentInstance();

  return computed(() => currentInstance?.proxy.$route);
};
