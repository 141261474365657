import { JVRestructuredRecord } from '@/models/jv';

export const cacheResource = (config: {
  normalizedResource: any;
  cachedResource: JVRestructuredRecord | Record<string, JVRestructuredRecord>;
  mergeRecord: (record: unknown, touchRelations?: boolean) => Promise<unknown>;
  addRecord: (payload: unknown) => Promise<unknown>;
}) => {
  const { cachedResource, normalizedResource, mergeRecord, addRecord } = config;
  if (cachedResource) {
    const resourceIsMostRecent =
      normalizedResource.updated_at > cachedResource.updated_at;
    if (resourceIsMostRecent) mergeRecord(normalizedResource, true);
    else return;
  } else addRecord(normalizedResource);
};
