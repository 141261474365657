<template>
  <v-dialog
    class="dialog-window"
    :modelValue="show"
    @update:modelValue="emit('closeModal')"
  >
    <div
      class="top-section"
      :class="{ 'card-intersecting-top': !topIntersecting }"
    >
      <div class="top-section__version-number">
        Current Version {{ appVersionData.version }}
      </div>
      <div class="top-section__title">Release Notes</div>
    </div>

    <v-card class="rounded-0 card-container">
      <v-container fluid>
        <div v-intersect="onTopIntersecting" />
        <v-row class="pb-5">
          <div class="d-flex">
            <v-list
              class="release-list"
              mandatory
              v-model:selected="selectedRelease"
            >
              <v-list-subheader>Releases</v-list-subheader>
              <v-list-item
                v-for="(release, index) in releaseNotes"
                :key="release.id"
                :value="index"
                :title="release.title"
                rounded="true"
                active-class="active-list-item"
              ></v-list-item>
            </v-list>
          </div>
        </v-row>
        <div v-intersect="onBottomIntersecting" />
      </v-container>
    </v-card>
    <div
      class="card-actions"
      :class="{ 'card-intersecting-bottom': !bottomIntersecting }"
    >
      <v-btn color="primary" variant="flat" @click="emit('closeModal')">
        Close
      </v-btn>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useDirectStore } from '@/composables/store';

type Props = {
  show: boolean;
};

type Emits = {
  (e: 'closeModal'): void;
  (e: 'update:modelValue', value: boolean): void;
};

defineProps<Props>();
const emit = defineEmits<Emits>();
const store = useDirectStore();

const topIntersecting = ref(false);
const bottomIntersecting = ref(false);
const selectedRelease = ref([0]);
const releaseNotes = ref(null);

const onTopIntersecting = (isIntersecting: boolean) => {
  topIntersecting.value = isIntersecting;
};
const onBottomIntersecting = (isIntersecting: boolean) => {
  bottomIntersecting.value = isIntersecting;
};

const appVersionData = computed(() => store.account.getAppVersionData());
</script>
<style lang="scss" scoped>
.dialog-window {
  width: 1000px;
}
.top-section {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  min-height: 70px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  z-index: 2;
  border-bottom: thin white solid;
}

.top-section__version-number {
  position: absolute;
  left: 10px;
}

.top-section__title {
  padding-left: 10px;
  font-size: 1.5em;
}

.keyboard-shortcuts-switch {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.card-intersecting-top {
  border-color: grey;
}

.card-intersecting-bottom {
  border-color: grey !important;
}

.card-container {
  height: 60dvh;
  min-height: 60dvh;
}
.card-actions {
  display: flex;
  position: relative;
  background-color: white;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  min-height: 70px;
  padding-right: 10px;
  border-radius: 0 0 5px 5px;
  border-top: thin white solid;
}

.release-list {
  flex: 1 0 auto;
  position: fixed;
  top: 90px;
}
#release-note-container-id {
  padding: 30px;
  padding-left: 180px;
}

#release-note-container-id :deep(h2) {
  font-size: 1.43em !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  margin-top: 1.8em !important;
}

#release-note-container-id :deep(ol) {
  padding-left: 2.385ch;
}

#release-note-container-id :deep(p) {
  font-size: 1em;
  line-height: 1.714;
  font-weight: normal;
  margin-top: 0.75rem;
  margin-bottom: 0px;
  letter-spacing: -0.005em;
}

.active-list-item {
  color: white;
  background-color: rgba(var(--v-theme-primary));
}
</style>
