<template>
  <div
    data-spec="progress-widget"
    class="progress-widget"
    :class="{
      'progress-widget--in-progress': inProgress,
      'progress-widget--readonly': readonly || isPlaceholder,
    }"
  >
    <template v-if="isPlaceholder">
      <div
        class="progress-widget__step-wrapper"
        v-for="index in 5"
        :key="index"
      >
        <div class="progress-widget__step progress-widget__step--placeholder" />
      </div>
    </template>

    <template v-else>
      <div
        class="progress-widget__step-wrapper"
        v-for="step in progressSteps"
        :key="step.id"
        :tabindex="readonly ? undefined : '0'"
        @keydown.space="onClick(step)"
        @click.stop="onClick(step)"
      >
        <div
          data-spec="progress-step"
          class="progress-widget__step"
          :class="{
            'progress-widget__step--selected': selectedIds.includes(step.id),
          }"
          :style="stepStyle(step)"
        >
          {{ stepLabel(step) }}
        </div>
      </div>
      <v-tooltip
        v-if="!readonly && (!noProgressStepSelected || alwaysClearable)"
        location="top"
      >
        <span>Clear</span>
        <template #activator="{ props }">
          <v-btn
            class="progress-widget__reset-button"
            v-bind="props"
            variant="text"
            icon
            height="22"
            width="22"
            size="x-small"
            @keydown.space="onResetClick()"
            @click.stop="onResetClick()"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Vue, Prop } from 'vue-property-decorator';
import { ProgressStep } from '@/models/progressStep';

@Options({
  emits: ['update:selectedProgressStepId'],
})
export default class ProgressWidgetField extends Vue {
  @Prop({ type: String }) selectedProgressStepId!: string;
  @Prop({ default: () => [] }) progressSteps: ProgressStep[];
  @Prop(Boolean) readonly: boolean;
  @Prop(Boolean) inProgress: boolean;
  @Prop(Boolean) alwaysEmit: boolean;
  @Prop(Boolean) alwaysClearable: boolean;

  get isPlaceholder() {
    return this.progressSteps.length === 0;
  }

  get noProgressStepSelected() {
    return !this.selectedProgressStepId;
  }

  get selectedIds() {
    return this.maxSelectedStep
      ? this.maxSelectedStep.name === '?'
        ? [this.maxSelectedStep.id]
        : this.lowerOrEqualStepsIds(this.maxSelectedStep)
      : [];
  }

  get maxSelectedStep(): ProgressStep | undefined {
    return this.progressSteps.find(
      (step) => step.id === this.selectedProgressStepId
    );
  }

  stepStyle(step: ProgressStep) {
    const stepColor = step.hex_color
      ? `#${step.hex_color}`
      : 'rgb(var(--v-theme-grayScale))';
    if (this.selectedIds.includes(step.id)) {
      return {
        backgroundColor: stepColor,
      };
    }
  }

  stepLabel(step: ProgressStep) {
    return (step.name || '').slice(0, 1);
  }

  lowerOrEqualStepsIds(maxStep: ProgressStep): string[] {
    return this.progressSteps
      .filter((current) => (current.order || 0) <= (maxStep.order || 0))
      .map((c) => c.id);
  }

  onClick(step: ProgressStep) {
    if (
      !this.alwaysEmit &&
      this.maxSelectedStep &&
      this.maxSelectedStep.id === step.id
    )
      return;
    this.$emit('update:selectedProgressStepId', step.id);
  }

  onResetClick() {
    if (!this.alwaysClearable && this.noProgressStepSelected) return;
    this.$emit('update:selectedProgressStepId', null);
  }
}
</script>

<style lang="scss" scoped>
.progress-widget {
  display: flex;
  align-items: center;
}
.progress-widget--in-progress {
  opacity: 0.5;
  cursor: progress;
  pointer-events: none;
}

.progress-widget--readonly {
  pointer-events: none;
}

.progress-widget__step-wrapper {
  padding: 2px 1px;
  flex-shrink: 0;
  cursor: pointer;

  &:focus,
  &:hover {
    .progress-widget__step {
      background-color: #fff;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.87);
    }
    .progress-widget__step--selected {
      color: #000;
    }
  }
}

.progress-widget__step {
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: rgb(var(--v-theme-color));
  border-radius: 3px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  transition:
    background-color 0.2s,
    color 0.2s;
  background-color: rgb(var(--v-theme-background));
}

.progress-widget__step--selected {
  color: #fff;
}

.progress-widget__step--placeholder {
  background-color: rgb(var(--v-theme-grayScale));
  border-color: rgb(var(--v-theme-grayScale));
  opacity: 0.2;
}

.progress-widget__reset-button {
  margin: auto 0 auto 4px;
  width: 20px;
  height: 20px;
}
</style>
