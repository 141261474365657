import { utils } from 'jsonapi-vuex';
import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import { WebSocketEvent, WebSocketEventResponse } from '@/models/ws';
import { JsonApiIdentification, JsonApiResource } from '@/models/api';
import jv from '../components/jv';
import wsConnection from '../components/wsConnection';
import notifications from './notifications';
import progress from './progress';
import ooc from './ooc';
import simo from './simo';
import doma from './doma';
import { eventBus } from '@/services/eventBus/eventBus';
import { ResourceType } from '@/services/resources';
import { JVTopLevelSingleResource } from '@/models/jv';
import { OwningEntityType } from '@/utils/objectOccurrence';
import { cacheResource } from '@/utils/wsTools';
import { wasRequestPerformed } from '@/services/api';

interface State {
  contextId: string | null;
}

const extractContextId = (url: string): string => {
  const itemIdIndex = url.indexOf('item_id=');
  if (itemIdIndex === -1) {
    return null;
  }

  const authorizationIndex = url.indexOf('&authorization', itemIdIndex);
  if (authorizationIndex === -1) {
    return null;
  }

  const extractedId = url.substring(itemIdIndex + 8, authorizationIndex);
  return extractedId;
};

export default createModule({
  path: 'wsContext',
  modules: [notifications, ooc, progress, simo, doma],
  components: [jv, wsConnection],
  setup({ components, modules, getAccessors }) {
    const $wsConnection = components.$wsConnection;
    const mutations = {};

    const actions = {
      handleError(context, event) {
        const contextId = extractContextId(event.target.url);
        if (!contextId) return;
        const isConnected = $wsConnection.public.getIsConnected(contextId);
        if (!isConnected) return;
        console.error('<ws> error');
        console.error(event);
        modules.notifications.public.dispatchNotify({
          message:
            'Could not establish a connection to the server, please try again later...',
          type: 'error',
        });
      },
      commitCacheRecords(context, payload: { resources: JsonApiResource[] }) {
        payload.resources.forEach((resource) => {
          const normalizedResource = utils.jsonapiToNorm(resource);
          const cachedResource = components.$jv.protected.get({
            id: resource.id,
            type: resource.type,
          });
          cacheResource({
            normalizedResource,
            cachedResource,
            mergeRecord: components.$jv.protected.commitMergeRecords,
            addRecord: components.$jv.protected.commitAddRecords,
          });
        });
      },
      commitMessageChanges(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        const { event, data, target } = payload;
        switch (event.operation) {
          case 'delete': {
            const dependentResources = (data.data.relationships
              .dependent_resources?.data || []) as JsonApiIdentification[];
            dependentResources
              .concat(target)
              .filter(Boolean)
              .forEach(({ id, type }) => {
                let oor;
                if (type === resources.objectOccurrenceRelations.type) {
                  oor = modules.simo.public.getSimplifiedResourceSet()[id];
                }
                components.$jv.protected.commitDeleteRecord({
                  _jv: { id, type },
                });
                if (oor && !wasRequestPerformed(event.request_id)) {
                  const { target, source } = oor;
                  modules.simo.protected.dispatchReloadCell({
                    targetId: target.id,
                    sourceId: source.id,
                  });
                }
              });
            break;
          }
          case 'create': {
            dispatch(actions.commitCacheRecords)({
              resources: data.included || [],
            });
            break;
          }
          case 'update':
          case 'update_progress': {
            // ignore change if target is not a file export and is not in cache
            const localResource =
              target &&
              components.$jv.protected.get({
                id: target.id,
                type: target.type,
              });
            if (localResource || target?.type === 'export') {
              dispatch(actions.commitCacheRecords)({
                resources: data.included || [],
              });
              if (event.operation === 'update_progress') {
                const target = data.data.relationships.target
                  .data as JsonApiIdentification;
                const progress = data.included.find(
                  (res) => res.id === target.id
                ).relationships['progress_step_checked'];
                modules.progress.protected.dispatchUpdateTargetProgressRelationship(
                  {
                    target,
                    progressStepCheckedId: progress?.data[0]?.id || null,
                  }
                );
              }
              // SPIKE: don't use eventBus like this if possible
              // this event tells SIMO component it should call
              // a grid tick because oor progress has changed
              eventBus.$emit('websocket-resource-update', {
                type: payload.target.type as ResourceType,
                id: payload.target.id,
              });
            }
            break;
          }
          case 'syntax_update_started':
          case 'syntax_update_cancelled':
          case 'syntax_update_finished':
            dispatch(actions.commitCacheRecords)({
              resources: data.included || [],
            });
            eventBus.$emit('syntax_update', { action: event.operation });
            break;
          default:
            break;
        }
      },
      onMessageReceived(context, payload: { target; event; data }) {
        const { target, event, data } = payload;
        dispatch(actions.commitMessageChanges)({ event, data, target });
      },
      onMessageUnhandled(context, payload: { target; event; data }) {
        const { target, event, data } = payload;
        if (target && target.type === resources.ownerships.type) {
          dispatch(actions.handleExternalMessageOwnerships)({
            event,
            data,
            target,
          });
        }
        if (target && target.type === resources.objectOccurrences.type) {
          dispatch(actions.handleExternalMessageOOC)({
            event,
            data,
            target,
          });
        }
        if (
          target &&
          target.type === resources.objectOccurrenceRelations.type
        ) {
          dispatch(actions.handleExternalMessageOOR)({ event, data, target });
        }
        if (
          target &&
          target.type === resources.objectOccurrenceDomainRelation.type
        ) {
          dispatch(actions.handleExternalMessageOODR)({
            event,
            data,
            target,
          });
        }
      },
      handleExternalMessageOwnerships(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        const ooc = payload.data.included.find(
          (inc) => inc.type === 'object_occurrence'
        );
        const owningEntity = {
          id: payload.data.included.find((inc) => inc.type === 'ownership').id,
          type: payload.data.included.find(
            (inc) => inc.type === 'owner' || inc.type === 'owner_group'
          ).type,
        };
        const { data } = payload.data.data.relationships.channel;
        const contextId = !Array.isArray(data) ? data.id : data[0].id;
        switch (payload.event.operation) {
          case 'delete': {
            modules.ooc.protected.dispatchExternalOwnershipRemove({
              tree: { contextId },
              oocId: ooc.id,
              owningEntity: {
                id: owningEntity.id,
                type: owningEntity.type as OwningEntityType,
              },
            });
            break;
          }
          case 'create': {
            modules.ooc.protected.dispatchExternalOwnershipAdd({
              tree: { contextId },
              oocId: ooc.id,
              owningEntity: {
                id: owningEntity.id,
                type: owningEntity.type as OwningEntityType,
              },
            });
            break;
          }
          default:
            break;
        }

        // SAME FOR ACTIONS FOR OOCS
        // Almost any change in OOC can cause OOC index changes in SIMO:
        // - add, delete, position/parent change cause structure changes
        // - update or progress change can change index because of filtering
        modules.simo.public.dispatchSetDirtyStructure({ dirtyStructure: true });
        // SPIKE: don't use eventBus like this if possible
        // this event tells CORE it should refetch revision comparison
        // in some cases because base context has been changed
        eventBus.$emit('websocket-resource-update', {
          type: ooc.type as ResourceType,
          id: ooc.id,
        });
      },
      handleExternalMessageOOC(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        const { data } = payload.data.data.relationships.channel;
        const contextId = !Array.isArray(data) ? data.id : data[0].id;
        const getNormalizedTarget = () => {
          const resource = payload.data.included.find(
            (i) => i.id === payload.target.id
          );
          return utils.jsonapiToNorm(resource);
        };
        switch (payload.event.operation) {
          case 'delete': {
            const dependentResources = (payload.data.data.relationships
              .dependent_resources?.data || []) as JsonApiIdentification[];
            const descendantsIds = dependentResources
              .filter(({ type }) => type === resources.objectOccurrences.type)
              .map(({ id }) => id);
            modules.ooc.protected.dispatchExternalRemoveNode({
              tree: { contextId },
              id: payload.target.id,
              descendantsIds,
            });
            break;
          }
          case 'create': {
            modules.ooc.protected.dispatchExternalAddNode({
              tree: { contextId },
              resource: getNormalizedTarget(),
            });
            break;
          }
          case 'update': {
            modules.ooc.protected.dispatchExternalUpdateNode({
              tree: { contextId },
              resource: getNormalizedTarget(),
            });
            break;
          }
          default:
            break;
        }
        // Almost any change in OOC can cause OOC index changes in SIMO:
        // - add, delete, position/parent change cause structure changes
        // - update or progress change can change index because of filtering
        modules.simo.public.dispatchSetDirtyStructure({ dirtyStructure: true });
        // SPIKE: don't use eventBus like this if possible
        // this event tells CORE it should refetch revision comparison
        // in some cases because base context has been changed
        eventBus.$emit('websocket-resource-update', {
          type: payload.target.type as ResourceType,
          id: payload.target.id,
        });
      },
      handleExternalMessageOOR(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        if (['update', 'create'].includes(payload.event.operation)) {
          const resource = payload.data.included.find(
            (i) => i.id === payload.target.id
          );
          const normalizedResource = utils.jsonapiToNorm(resource);
          const { source, target } = normalizedResource._jv.relationships;
          modules.simo.protected.dispatchReloadCell({
            sourceId: source.data.id,
            targetId: target.data.id,
          });
        }
      },
      handleExternalMessageOODR(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        switch (payload.event.operation) {
          case 'create': {
            modules.doma.protected.dispatchExternalAddOodr({
              oodrId: payload.target.id,
            });
          }
        }
      },
    };

    const { dispatch } = getAccessors<State>();

    return {
      module: {
        mutations,
        actions,
      },
      protected: {
        ...components.$wsConnection.protected,
        onMessageReceived: dispatch(actions.onMessageReceived),
        onMessageUnhandled: dispatch(actions.onMessageUnhandled),
      },
      public: {
        ...components.$wsConnection.public,
        getTargetId: (context: string) =>
          $wsConnection.public.getTarget(context)?.id,
        dispatchConnect: (payload: { contextId: string }) =>
          $wsConnection.public.dispatchConnect({
            target: { id: payload.contextId, type: resources.contexts.type },
            onMessageReceived: dispatch(actions.onMessageReceived),
            onMessageUnhandled: dispatch(actions.onMessageUnhandled),
            onError: dispatch(actions.handleError),
          }),
      },
    };
  },
});
