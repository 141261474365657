<template>
  <div class="search-bar__container">
    <v-text-field
      v-model="searchQuery"
      :label="label"
      variant="outlined"
      hide-details
      single-line
      clearable
      clear-icon="mdi-close"
      density="compact"
      bg-color="white"
      :debounceOn="debounceOn"
      prepend-inner-icon="mdi-magnify"
      @keydown.enter="submit"
      @click:append="submit"
      @click:clear="clear"
    />
  </div>
</template>
<script lang="ts" setup>
import { debounce } from '@/utils/tools';
import { ref, watch } from 'vue';
const searchQuery = ref();

const props = defineProps<{
  debounceOn: boolean;
  label: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', searchQuery): void;
}>();

const submit = () => {
  emit('update:modelValue', searchQuery.value);
  close();
};

const clear = () => {
  emit('update:modelValue', '');
  close();
};

const debouncedSubmit = debounce(submit, 300);

watch(searchQuery, () => {
  if (props.debounceOn) debouncedSubmit();
  else submit;
});
</script>

<style lang="scss" scoped>
.search-bar__container {
  width: 100%;
  padding: 0 20px 0 20px;
}
</style>
