<template>
  <AppToolbarItem
    ref="appToolbarItem"
    :key="`toolbar-deadline-${projectId}`"
    :label="$t('deadlines.viewTitle')"
    toolId="deadlines"
    icon="mdi-calendar-clock"
    drawerPortalTarget="app-main-content"
    @activated="emit('activated')"
    @deactivated="emit('deactivated')"
  >
    <template #drawer="{ active }">
      <template v-if="active">
        <div class="app-toolbar-deadline__drawer">
          <div class="app-toolbar-deadline-routing" v-if="deadlineId">
            <v-btn @click="goBack" variant="plain" icon="mdi-arrow-left" />
            <div class="app-toolbar-deadline-routing__close">
              <v-btn @click="closeView" variant="plain" icon="mdi-close" />
            </div>
          </div>
          <component
            :is="component"
            :projectId="projectId"
            :deadlineId="deadlineId"
            @open:deadlineDetails="setDeadlineId"
          >
            <template v-if="!deadlineId" #header-append>
              <div class="app-toolbar-deadline-routing__close">
                <v-btn @click="closeView" variant="plain" icon="mdi-close" />
              </div>
            </template>
          </component>
        </div>
      </template>
    </template>
  </AppToolbarItem>
</template>

<script lang="ts" setup>
import AppToolbarItem from './appToolbarItem.vue';
import { computed, defineAsyncComponent, ref } from 'vue';

type Props = {
  projectId: string;
};

type Emits = {
  (e: 'activated'): void;
  (e: 'deactivated'): void;
};

defineProps<Props>();
const emit = defineEmits<Emits>();

const appToolbarItem = ref<InstanceType<typeof AppToolbarItem>>();

const deadlineId = ref<string | null>(null);

const deadlineComponent = defineAsyncComponent(
  () => import('@/components/deadlines/deadlines.vue')
);

const deadlineDetailsComponent = defineAsyncComponent(
  () => import('@/components/deadlines/deadlineDetails/deadlineDetails.vue')
);

const component = computed(() =>
  deadlineId.value ? deadlineDetailsComponent : deadlineComponent
);

const goBack = () => {
  setDeadlineId(null);
};

const closeView = () => {
  goBack();
  appToolbarItem.value.deactivate();
};

const setDeadlineId = (id: string | null) => (deadlineId.value = id);
</script>
<style lang="scss" scoped>
.app-toolbar-deadline__drawer {
  position: absolute;
  width: calc(100% - 52px);
  height: calc(100% - 54px);
  padding: 0 12px 24px;
  background-color: rgb(var(--v-theme-background));
  overflow: auto;
  z-index: 100;
}
.app-toolbar-deadline-routing {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
}
.app-toolbar-deadline-routing__close {
  display: flex;
  justify-content: end;
  flex-grow: 1;
}
</style>
