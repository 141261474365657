import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { ComponentType } from './_types';
import { ResourceIdentification } from './crud';
import jv from './jv';

export type PublisherAccessors = ModuleComponentPublicAccessors<
  typeof publisher
>;

const publisher = createModuleComponent({
  type: ComponentType.Publisher,
  dependencies: [jv],
  setup: ({ getAccessors, resourceProfile, components }) => {
    const actions = {
      async publishResource(context, payload: ResourceIdentification) {
        const resourceMeta = { _jv: { type: resourceProfile.type } };
        const url = `/${resourceProfile.path}/${payload.resourceId}/publish`;
        await components.$jv.protected.dispatchPost([resourceMeta, { url }]);
      },
    };

    const { dispatch } = getAccessors();

    return {
      module: {
        actions,
      },
      public: {
        dispatchPublishResource: dispatch(actions.publishResource),
      },
    };
  },
});

export default publisher;
