<template>
  <v-tooltip location="bottom" :disabled="!label">
    <div>{{ label }}</div>
    <template #activator="{ props: attrs }">
      <v-badge
        :model-value="!!badge"
        :dot="badgeIsDot"
        :content="typeof badge === 'string' ? badge : ''"
        color="error"
      >
        <v-btn
          v-bind="attrs"
          :disabled="disabled"
          @click="onToolItemClick"
          icon
          width="28"
          height="28"
          :elevation="isActive ? 1 : 0"
          :variant="isActive ? 'elevated' : 'text'"
        >
          <v-icon>
            {{ icon }}
          </v-icon>
          <Portal v-if="active && hasDrawer" :to="drawerPortalTarget">
            <slot name="drawer" :close="toolbar.close" :active="active" />
          </Portal>
        </v-btn>
      </v-badge>
    </template>
  </v-tooltip>
</template>
<script lang="ts" setup>
import { computed, inject, useSlots, watch } from 'vue';
import { ToolbarContext } from '@/components/layout/types';
import { Portal } from 'portal-vue';

type Props = {
  icon: string;
  label?: string;
  toolId?: string;
  badge?: string | boolean;
  disabled?: boolean;
  drawerPortalTarget?: string;
  toolType?: string;
  isActive?: boolean;
};

type Emits = {
  (e: 'activated'): void;
  (e: 'deactivated'): void;
  (e: 'click', evtPayload): void;
};

const randomId = `tool-${Math.random()}`.replace('.', '-');

const props = withDefaults(defineProps<Props>(), {
  drawerPortalTarget: 'toolbar-drawer',
  label: '',
  toolId: '',
  badge: '',
  toolType: '',
  isActive: false,
});
const emit = defineEmits<Emits>();

const slots = useSlots();
const toolbar = inject<ToolbarContext>('toolbarContext');

const badgeIsDot = computed(() => {
  return typeof props.badge === 'boolean';
});

const id = computed(() => props.toolId || randomId);

const active = computed(() => toolbar.activeTool === id.value);
const activate = () => {
  if (hasDrawer.value) toolbar.setTool(id.value);
};
const deactivate = () => toolbar.close();

const hasDrawer = computed(() => !!slots?.drawer);

const onToolItemClick = async (ev?: MouseEvent) => {
  const itemsWithoutDrawers = ['core-toolbar-print', 'dashboard-toolbar-print'];
  const isWithoutDrawer = !itemsWithoutDrawers.includes(props.toolType);
  if (hasDrawer.value && isWithoutDrawer) {
    // Prevent hiding toolbar if already opened
    ev?.preventDefault();
    active.value ? deactivate() : activate();
  } else {
    emit('click', ev);
  }
};

watch(active, (isActive) => {
  if (isActive) emit('activated');
  else emit('deactivated');
});

defineExpose({
  active,
  activate,
  deactivate,
});
</script>
