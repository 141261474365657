import { itemIndexToPageNumber, pageToStartIndex } from './utils';

type IndexItem = string | null;

export interface PageIndex {
  items: IndexItem[];
  totalCount: number;
}

export interface PageInfo {
  page: number;
  pageSize: number;
}

export function createPageIndex(): PageIndex {
  return {
    items: [],
    totalCount: 0,
  };
}

export function invalidatePageIndex(
  index: PageIndex,
  pagesToKeep: PageInfo[] = []
): PageIndex {
  const items = index.items.map((item, itemIndex) => {
    const shouldKeep = pagesToKeep.some(({ page, pageSize }) => {
      return page === itemIndexToPageNumber(itemIndex, pageSize);
    });
    return shouldKeep ? item : undefined;
  });
  return {
    ...index,
    items,
  };
}

// TODO: changes currentPage to paginationData.currentPage
// TODO: changes totalCount to paginationData.totalCount
export function mergePageIndex(
  index: PageIndex,
  pageInfo: PageInfo,
  pageItems: string[]
): PageIndex {
  const { items, totalCount } = index;
  const newItems = items.map((id) => (pageItems.includes(id) ? undefined : id));
  const from = pageToStartIndex(pageInfo.page, pageInfo.pageSize);
  if (from > newItems.length) newItems.length = from;
  newItems.splice(from, pageItems.length, ...pageItems);
  return {
    items: newItems,
    totalCount: Math.max(totalCount, newItems.length),
  };
}

// TODO: call it manually
export function updateTotalPageIndex(
  index: PageIndex,
  newTotalCount: number
): PageIndex {
  const { items } = index;
  return {
    items: items.slice(0, newTotalCount),
    totalCount: newTotalCount,
  };
}

export function injectToPageIndex(
  index: PageIndex,
  pageInfo: PageInfo,
  item: string
): PageIndex {
  const { items, totalCount } = index;
  if (items.some((nI) => nI === item)) return index;
  const newItems = Array.from(items);
  const idx = pageToStartIndex(pageInfo.page, pageInfo.pageSize);
  newItems.splice(idx, 0, item);
  return {
    ...index,
    items: newItems,
    totalCount: totalCount + 1,
  };
}

export function appendToPageIndex(index: PageIndex, item: string): PageIndex {
  const { items, totalCount } = index;
  if (items.some((nI) => nI === item)) return index;
  const newItems = Array.from(items);
  newItems.length = totalCount + 1;
  newItems.splice(totalCount, 1, item);
  return {
    ...index,
    items: newItems,
    totalCount: totalCount + 1,
  };
}

export function prependToPageIndex(index: PageIndex, item: string): PageIndex {
  const { items, totalCount } = index;
  if (items.some((nI) => nI === item)) return index;
  const newItems = Array.from(items);
  newItems.unshift(item);
  return {
    ...index,
    items: newItems,
    totalCount: totalCount + 1,
  };
}

export function removeFromPageIndex(
  index: PageIndex,
  predicate: (item: string) => boolean
): PageIndex {
  const { items, totalCount } = index;
  const newItems = items.filter((it) => !predicate(it));
  const diff = items.length - newItems.length;
  return {
    ...index,
    items: newItems,
    totalCount: totalCount - diff,
  };
}
