<!-- eslint-disable vue/require-default-prop -->
<script lang="ts">
import { useResourceList } from '@/composables/resourceList';
import { defineComponent, toRefs, watch, PropType } from 'vue';
import { ResourceListConfig } from '@/utils/resourceList';
import { toReactive } from '@vueuse/core';

export default defineComponent({
  props: {
    resourceConfig: Object as PropType<ResourceListConfig>,
    query: {
      type: String,
      default: '',
    },
    queryFilterKey: {
      type: String,
      default: 'query',
    },
    requestType: {
      type: String,
      default: 'resourceList',
    },
    requestId: {
      type: String,
      default: '',
    },
    pageSize: {
      type: Number,
      default: 30,
    },
    reloadOnRender: {
      type: Boolean,
      default: false,
    },
    reloadOnActivated: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:loading': (isLoading: boolean) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    'update:items': (items: any[]) => true,
  },
  setup(props, { emit, expose, slots }) {
    const resourceListContext = useResourceList(toRefs(props));

    watch(
      [resourceListContext.loading, resourceListContext.items],
      ([newLoading, newItems], [oldLoading, oldItems]) => {
        if (newLoading !== oldLoading) {
          emit('update:loading', newLoading);
        }
        if (newItems !== oldItems) {
          emit('update:items', newItems);
        }
      },
      { immediate: true }
    );

    expose({
      ...resourceListContext,
    });

    const reactiveContext = toReactive(resourceListContext);

    return () => slots.default?.(reactiveContext);
  },
});
</script>
