import { eventBus } from '@/services/eventBus/eventBus';
import { STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS } from '@/utils/keyboardShortcutId';

export function keypressListener(e: KeyboardEvent): void {
  let shortcutsActive = true;
  if (localStorage.getItem(STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS)) {
    shortcutsActive = JSON.parse(
      localStorage.getItem(STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS)
    );
  }
  if (inputTypeFocused()) return;
  if (
    !isAnyModifierPressed() &&
    Object.values(specialKeys).includes(e.key) &&
    shortcutsActive
  ) {
    handleKeypress(e);

    return;
  }
  if (!Object.keys(keyLogger).includes(e.code)) return;

  keyLogger[e.code] = e.type === 'keydown';
  const pressed = getPressedKeys();
  if (pressed.length < 2) return;

  if (validShortcutUsed(pressed)) {
    eventBus.$emit(`shortcut-used`, { event: e, keys: pressed.join('-') });
    clearKeylogger(pressed);
  }
}

const handleKeypress = (e: KeyboardEvent) => {
  if (e.type === 'keydown') {
    eventBus.$emit('keydown', e);
  }
};

const getPressedKeys = () => {
  return mapMetaKeysToCtrl(
    Object.keys(keyLogger).filter((code) => keyLogger[code])
  );
};

const mapMetaKeysToCtrl = (pressed: string[]) => {
  return pressed.map((key) => {
    if ([modifiers.MetaLeft, modifiers.OSLeft].includes(key))
      return modifiers.ControlLeft;
    if ([modifiers.MetaRight, modifiers.OSRight].includes(key))
      return modifiers.ControlRight;
    return key;
  });
};

const clearKeylogger = (pressed: string[]) => {
  // Clear keyLogger when no component fired preventDefault after receiving shortcut with key modifier
  if (!shouldClearKeylogger(pressed)) return;
  Object.keys(keyLogger).forEach((x) => (keyLogger[x] = false));
};

export function inputTypeFocused(): boolean {
  const activeEl = document.activeElement;
  const enabledInputFocused = isElementInput(activeEl) && !activeEl.disabled;
  const enabledBtnFocused = isElementClickable(activeEl) && !activeEl.disabled;
  return enabledInputFocused || enabledBtnFocused;
}

export function keypressHandler(e, prevent = false, stop = false, fun = null) {
  if (prevent) e.event.preventDefault();
  if (stop) e.event.stopPropagation();

  if (typeof fun === 'function') {
    fun(e);
  } else console.warn('Unhandled keyboard event: ', e);
}

function isAnyModifierPressed() {
  return Object.values(modifiersKeylogger).some(Boolean);
}

function validShortcutUsed(pressed) {
  return (
    pressed.length > 2 ||
    !pressed.reduce(
      (acc, cur) => acc && Object.values(modifiers).includes(cur),
      true
    )
  );
}

function shouldClearKeylogger(pressed) {
  return (
    pressed.length > 2 ||
    !!keyLogger[modifiers.MetaLeft] ||
    !!keyLogger[modifiers.ControlLeft] ||
    !!keyLogger[modifiers.MetaRight] ||
    !!keyLogger[modifiers.ControlRight]
  );
}

const keyLogger = {
  KeyU: false,
  KeyB: false,
  KeyC: false,
  KeyP: false,
  KeyD: false,
  KeyE: false,
  KeyF: false,
  KeyG: false,
  KeyH: false,
  KeyK: false,
  KeyN: false,
  KeyS: false,
  KeyT: false,
  KeyV: false,
  KeyX: false,
  KeyZ: false,
  Slash: false,
  ArrowLeft: false,
  ArrowRight: false,
  ControlLeft: false,
  ControlRight: false,
  OSLeft: false,
  OSRight: false,
  MetaLeft: false,
  MetaRight: false,
  AltLeft: false,
  AltRight: false,
  ShiftLeft: false,
  ShiftRight: false,
  Enter: false,
};

const modifiersKeylogger = {
  get ControlLeft() {
    return keyLogger['ControlLeft'];
  },
  get ControlRight() {
    return keyLogger['ControlRight'];
  },
  get MetaLeft() {
    return keyLogger['MetaLeft'];
  },
  get MetaRight() {
    return keyLogger['MetaRight'];
  },
  get AltLeft() {
    return keyLogger['AltLeft'];
  },
  get AltRight() {
    return keyLogger['AltRight'];
  },
  get ShiftLeft() {
    return keyLogger['ShiftLeft'];
  },
  get ShiftRight() {
    return keyLogger['ShiftRight'];
  },
};

const modifiers = {
  ControlLeft: 'ControlLeft',
  ControlRight: 'ControlRight',
  OSLeft: 'OSLeft', // firefox
  OSRight: 'OSRight', // firefox
  MetaLeft: 'MetaLeft', // chrome
  MetaRight: 'MetaRight', // chrome
  AltLeft: 'AltLeft',
  AltRight: 'AltRight',
  ShiftLeft: 'ShiftLeft',
  ShiftRight: 'ShiftRight',
};

const specialKeys = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Enter: 'Enter',
  Escape: 'Escape',
  F2: 'F2',
  F3: 'F3',
  Delete: 'Delete',
  Tab: 'Tab',
};

const isElementInput = (
  element: Element
): element is HTMLInputElement | HTMLTextAreaElement => {
  return ['input', 'textarea'].includes(element.tagName.toLowerCase());
};

const isElementClickable = (
  element: Element
): element is HTMLButtonElement | HTMLSelectElement => {
  return ['button', 'select'].includes(element.tagName.toLowerCase());
};
