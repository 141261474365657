export enum PermissionTargetType {
  Global = 'global',
  Project = 'project',
  Context = 'context',
}

export const PermissionGlobal = {
  Admin: 'admin',
} as const;

export const PermissionProject = {
  Write: 'project:write',
  Context_write: 'project:context_write',
  Read: 'project:read',
} as const;

export const PermissionContext = {
  Write: 'context:write',
  Read: 'context:read',
} as const;

export type PermissionType =
  | (typeof PermissionGlobal)[keyof typeof PermissionGlobal]
  | (typeof PermissionProject)[keyof typeof PermissionProject]
  | (typeof PermissionContext)[keyof typeof PermissionContext];

export type PermissionDefinition<Type extends PermissionType = PermissionType> =
  {
    type: Type;
    targetType: PermissionTargetType;
    implies?: PermissionType[];
  };

export const permissionDefinitions: {
  [key in PermissionType]: PermissionDefinition<key>;
} = {
  [PermissionGlobal.Admin]: {
    type: PermissionGlobal.Admin,
    targetType: PermissionTargetType.Global,
    implies: [PermissionProject.Write],
  },
  [PermissionProject.Write]: {
    type: PermissionProject.Write,
    targetType: PermissionTargetType.Project,
    implies: [PermissionProject.Context_write],
  },
  [PermissionProject.Context_write]: {
    type: PermissionProject.Context_write,
    targetType: PermissionTargetType.Project,
    implies: [PermissionContext.Write, PermissionProject.Read],
  },
  [PermissionProject.Read]: {
    type: PermissionProject.Read,
    targetType: PermissionTargetType.Project,
    implies: [PermissionContext.Read],
  },
  [PermissionContext.Write]: {
    type: PermissionContext.Write,
    targetType: PermissionTargetType.Context,
    implies: [PermissionContext.Read],
  },
  [PermissionContext.Read]: {
    type: PermissionContext.Read,
    targetType: PermissionTargetType.Context,
  },
};
