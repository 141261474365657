<template>
  <v-btn
    class="intercom-widget"
    :class="{ 'intercom-widget--collapsed': !isIntercomOpened && isCollapsed }"
    color="appDesign"
    size="large"
    icon
    @click="toggleChat"
  >
    <template v-if="!isIntercomOpened && isCollapsed"> ? </template>
    <template v-else>
      <img src="@/assets/intercom-chat-icon.svg" />
      <button
        v-if="!isIntercomOpened"
        class="intercom-widget__close-button"
        @click.stop.prevent="hideWidget"
      >
        <v-icon size="x-small">mdi-close</v-icon>
      </button>
    </template>
  </v-btn>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';

interface IntercomSettings {
  app_id: string;
  name: string;
  user_id: string;
  user_hash: string;
  admin: boolean;
}

declare global {
  interface Window {
    Intercom: {
      (command: 'boot', intercomSettings: IntercomSettings): void;
      (command: 'onShow', handler: () => void): void;
      (command: 'onHide', handler: () => void): void;
      (
        command:
          | 'update'
          | 'shutdown'
          | 'show'
          | 'hide'
          | 'showMessage'
          | 'showNewMessage'
      ): void;
    };
  }
}

const STORAGE_KEY_INTERCOM_BUTTON_STATE = 'sec-intercom-button-state';

enum IntercomButtonState {
  Collapsed = 'collapsed',
  Expanded = 'expanded',
}

@Options({})
export default class IntercomWidget extends Vue {
  isIntercomOpened = false;
  buttonState: IntercomButtonState =
    (localStorage.getItem(
      STORAGE_KEY_INTERCOM_BUTTON_STATE
    ) as IntercomButtonState) || IntercomButtonState.Expanded;

  set isCollapsed(value: boolean) {
    this.buttonState = value
      ? IntercomButtonState.Collapsed
      : IntercomButtonState.Expanded;
    localStorage.setItem(STORAGE_KEY_INTERCOM_BUTTON_STATE, this.buttonState);
  }

  get isCollapsed() {
    return this.buttonState === IntercomButtonState.Collapsed;
  }

  async beforeMount() {
    const intercom_data =
      await this.$store.$direct.account.dispatchLoadIntercomData();

    const intercom_options = {
      app_id: window.__env.VUE_APP_INTERCOM_APP_ID || 'on7dm9g5',
      user_id: intercom_data.attributes.user_id, // User ID
      name: intercom_data.attributes.name, // User name
      user_hash: intercom_data.id, // HMAC using SHA-256
      admin: this.$store.$direct.userPermission.isAdmin(), // Has account:write permission
      alignment: 'right',
      horizontal_padding: 180,
      vertical_padding: 90,
      hide_default_launcher: true,
    };

    // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
    if (window.location.hostname !== 'localhost') {
      window.Intercom('boot', intercom_options);
    }
    window.Intercom('onShow', () => {
      this.isIntercomOpened = true;
    });
    window.Intercom('onHide', () => {
      this.isIntercomOpened = false;
    });
  }

  toggleChat() {
    this.isCollapsed = false;
    if (this.isIntercomOpened) {
      window.Intercom('hide');
    } else {
      window.Intercom('show');
    }
  }

  hideWidget() {
    this.isCollapsed = true;
  }

  public render() {
    return null;
  }
}
</script>
<style lang="scss" scoped>
.intercom-widget {
  position: fixed;
  bottom: 25px;
  right: 340px;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  transition:
    width 0.4s,
    height 0.4s,
    transform 0.4s;

  img {
    width: 28px;
  }

  &:not(.intercom-widget--collapsed) {
    // disable pointer events for expanding animation
    // otherwise close button is flickering
    animation-name: disable-pointer-events-temporarily;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &:hover {
    .intercom-widget__close-button {
      visibility: visible;
    }
  }
}
.intercom-widget--collapsed {
  width: 20px !important;
  height: 20px !important;
  transform: translate(10px, 14px);
}
.intercom-widget__close-button {
  visibility: hidden;
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: rgb(var(--v-theme-colorInverse));
  color: rgb(var(--v-theme-color)) !important;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

@keyframes disable-pointer-events-temporarily {
  0% {
    pointer-events: none;
  }
  100% {
    pointer-events: auto;
  }
}
</style>
