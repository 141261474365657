import { DelayedResponseService } from '@/services/delayedResponse';
import store from '..';
import { createModuleComponent } from '../utils/factories';
import { ComponentType } from './_types';

export default createModuleComponent({
  type: ComponentType.DelayedResponseContext,
  setup: () => {
    return {
      module: {},
      protected: {
        // Not very elegant but allows us to use this getter across other components without much boilerplate
        getDelayedResponseService: (context: string): DelayedResponseService =>
          store.$_moduleInstances.wsContext.protected.getDelayedResponseService(
            context
          ),
      },
    };
  },
});
