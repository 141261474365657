import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import processingTracker from '../components/processingTracker';
import archiver from '../components/archiver';
import publisher from '../components/publisher';

const REQUEST_TYPE_CORE_PUBLISHED = 'REQUEST_TYPE_CORE_PUBLISHED';
const REQUEST_TYPE_SIMO_PUBLISHED = 'REQUEST_TYPE_SIMO_PUBLISHED';

export interface ClassificationTableFilters {
  type: 'core' | 'simo' | null;
}

const getDefaultFilters = (): ClassificationTableFilters => ({
  type: null,
});

interface State {
  filters: ClassificationTableFilters;
}

export default createModule({
  path: 'classificationTable',
  resourceProfile: resources.classificationTable,
  components: [crud, indexManager, processingTracker, archiver, publisher],
  setup({ components, getAccessors }) {
    const state = (): State => ({
      filters: getDefaultFilters(),
    });

    const getters = {
      filters: (state: State) => state.filters,
    };

    const mutations = {
      setFilters(
        state: State,
        payload: { filters: ClassificationTableFilters }
      ) {
        state.filters = payload.filters;
      },
    };

    const actions = {
      setFilters(_, payload: { filters: ClassificationTableFilters }) {
        commit(mutations.setFilters)({ filters: { ...payload.filters } });
      },
      clearFilters(_) {
        commit(mutations.setFilters)({ filters: getDefaultFilters() });
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        ...components.$processingTracker.public,
        ...components.$archiver.public,
        ...components.$publisher.public,
        getCorePublishedTables: () =>
          components.$indexManager.public.getFullResource(
            REQUEST_TYPE_CORE_PUBLISHED
          ),
        dispatchLoadCorePublishedTables: () =>
          components.$indexManager.public.dispatchLoadFullResource({
            requestType: REQUEST_TYPE_CORE_PUBLISHED,
            filterArchived: false,
            filterPublished: true,
            filterClassificationTableType: 'core',
          }),
        getSimoPublishedTables: () =>
          components.$indexManager.public.getFullResource(
            REQUEST_TYPE_SIMO_PUBLISHED
          ),
        dispatchLoadSimoPublishedTables: () =>
          components.$indexManager.public.dispatchLoadFullResource({
            requestType: REQUEST_TYPE_SIMO_PUBLISHED,
            filterArchived: false,
            filterPublished: true,
            filterClassificationTableType: 'simo',
          }),
        getFilters: read(getters.filters),
        dispatchSetFilters: dispatch(actions.setFilters),
        dispatchClearFilters: dispatch(actions.clearFilters),
      },
    };
  },
});
