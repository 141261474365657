// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Migration<PreviousModel = any, NextModel = any> = (
  model: PreviousModel
) => Promise<NextModel> | NextModel;
export type Migrations = Record<string, Migration>;

export function compareVersions(a, b) {
  return a === b ? 0 : compareArrays(parseVersion(a), parseVersion(b));
}

function parseVersion(ver) {
  return ver.split('.').map((num) => parseInt(num, 10));
}

function compareArrays(a, b) {
  if (a.length === 0 && b.length === 0) {
    return 0;
  } else if (a.length === 0 || b.length === 0) {
    return a.length - b.length;
  } else {
    const i = a.shift();
    const j = b.shift();
    return i === j ? compareArrays(a, b) : i - j;
  }
}

export async function migrate(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any,
  migrations: Migrations,
  version: string
) {
  return runMigrations(model, prepareMigrations(version, migrations));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function runMigrations(model: any, migrations: Migration[]) {
  if (!migrations.length) return model;
  const [migration, ...nextMigrations] = migrations;
  const nextModel = await migration(model);
  return runMigrations(nextModel, nextMigrations);
}

function prepareMigrations(version: string, migrations: Migrations) {
  return Object.keys(migrations)
    .filter((item) => compareVersions(version, item) < 0)
    .sort(compareVersions)
    .map((key) => migrations[key]);
}

// const migrations: Migrations = {
//   // example migrations definitions
//   // '0.0.7': function (ent) {
//   //   console.log('migrating to 0.0.7')
//   //   return ent
//   // },
//   // '0.1.0': function (ent) {
//   //   console.log('migrating to 0.1.0')
//   //   return ent
//   // },
//   // '0.1.1': function (ent) {
//   //   console.log('migrating to 0.1.1')
//   //   return ent
//   // }
// }
