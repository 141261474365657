/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResourceProfile, ResourceType } from '@/services/resources';
import { hasOwnProperty, objectAssignDescriptors } from '@/utils/tools';
import { JsonApiIdentification } from '@/models/api';

export type SimplifiedResource<T = unknown> = T & {
  __type: ResourceType;
};

export function extractJVAttributes(
  entry: any,
  attributes: readonly string[] = []
) {
  if (entry && entry._jv && entry._jv.id) {
    return attributes.reduce(
      (acc, curr) => {
        if (hasOwnProperty(entry, curr)) acc[curr] = entry[curr];
        return acc;
      },
      { id: entry._jv.id }
    );
  } else {
    return null;
  }
}

export function extractJVRelations(
  entry: any,
  relationships: { [key: string]: ResourceProfile | ResourceProfile[] } = {}
) {
  if (entry && entry._jv && entry._jv.id) {
    return Object.keys(relationships).reduce((acc, key) => {
      if (entry[key]) {
        const relationshipDef = relationships[key];
        const relationshipProfile = Array.isArray(relationshipDef)
          ? entry[key] &&
            entry[key]._jv &&
            relationshipDef.find(
              (profile) => profile.type === entry[key]._jv.type
            )
          : relationshipDef;
        if (relationshipProfile) {
          const simplifier = entitySimplifier(relationshipProfile);
          Object.defineProperty(acc, key, {
            get() {
              return relationshipProfile.relationshipMany
                ? Object.values(entry[key] || {}).map(simplifier)
                : simplifier(entry[key]);
            },
            enumerable: true,
            configurable: true,
          });
        }
      }
      return acc;
    }, {});
  } else {
    return null;
  }
}

export function entitySimplifier<T extends { id: string } = any>(
  profile: ResourceProfile
) {
  return (entry: any) => {
    const type = entry?._jv?.type;
    const attributes = extractJVAttributes(entry, profile.attributes);
    const relationships = extractJVRelations(entry, profile.relationships);
    const entity = objectAssignDescriptors({}, attributes, relationships);
    Object.defineProperty(entity, '__type', { value: type });
    return Object.freeze(entity as SimplifiedResource<T>);
  };
}

export function extractRelatedEntityId(
  relatedResource: string,
  entry: any
): string | undefined {
  const link = entry?._jv?.relationships?.[relatedResource]?.links?.related;
  return extractIdFromLinkRelated(link);
}

export const extractIdFromLinkRelated = (link: string) =>
  link?.split('/').slice(-1)[0] || undefined;

export function extractRelatedRootNodeId(
  relatedResourceType: string,
  entry: any
) {
  return extractRelatedEntityId(`root_${relatedResourceType}`, entry);
}

type ResourceRelationshipPayload<T extends ResourceProfile> = {
  [K in keyof T['relationships']]: T['relationships'][K] extends ResourceProfile[]
    ? JsonApiIdentification | JsonApiIdentification[]
    : string | string[] | JsonApiIdentification | JsonApiIdentification[];
};
export interface BuildRelationshipsJVDataPayload<T extends ResourceProfile> {
  relationshipKey?: string;
  relationships: ResourceRelationshipPayload<T>;
}

export function buildRelationshipsJVData<T extends ResourceProfile>(
  resourceProfile: ResourceProfile,
  payload: BuildRelationshipsJVDataPayload<T>
) {
  const parentType = resourceProfile.parentType || payload.relationshipKey;
  const includableRelationships = Object.keys(
    resourceProfile.relationships
  ).filter((type) => type !== parentType);
  const { relationships: payloadRelationships } = payload;
  return includableRelationships.reduce((acc, curr) => {
    const currentRelationshipProfile = resourceProfile.relationships[curr];
    const currentRelationshipPayload = payloadRelationships[curr];
    const hasRelationshipPayload =
      !!currentRelationshipPayload || currentRelationshipPayload === null;
    const toRelationshipData = (data: string | JsonApiIdentification) =>
      data === null || (data as JsonApiIdentification)?.id
        ? data
        : {
            id: data,
            type: (currentRelationshipProfile as ResourceProfile).type,
          };
    return {
      ...acc,
      ...(hasRelationshipPayload && {
        [curr]: {
          data: Array.isArray(currentRelationshipPayload)
            ? currentRelationshipPayload.map(toRelationshipData)
            : toRelationshipData(currentRelationshipPayload),
        },
      }),
    };
  }, {});
}
