import { Directive, nextTick } from 'vue';

const inputHandler = (event: InputEvent, callback: (value: string) => void) => {
  const input = event.target as HTMLInputElement;
  const start = input.selectionStart;
  const end = input.selectionEnd;
  callback(input.value.toUpperCase());
  nextTick(() => {
    input.setSelectionRange(start, end);
  });
};

const findInput = (el: HTMLElement): HTMLElement => {
  if (el.tagName === 'INPUT') return el;
  return el.querySelector('input');
};

const listeners: Map<HTMLElement, (event: InputEvent) => void> = new Map();

export const Uppercase: Directive = {
  beforeMount(el) {
    const input = findInput(el);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const modelCallback = (input as any)?.__vnode?.props?.[
      'onUpdate:modelValue'
    ] as (value: string) => void;
    if (!modelCallback) return;
    const handler = (event: InputEvent) => inputHandler(event, modelCallback);
    input.addEventListener('input', handler);
    listeners.set(input, handler);
  },

  unmounted(el) {
    const input = findInput(el);
    const handler = listeners.get(input);
    if (!input || !handler) return;
    input.removeEventListener('keydown', handler);
  },
};
