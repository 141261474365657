import { api } from '@/services/api';

const jobPromiseHooks: {
  [id: string]: {
    promise: Promise<unknown>;
    resolve: (value?: unknown) => void;
    reject: (value?: unknown) => void;
  };
} = {};

const polling = {
  namespaced: true,
  state: {
    jobs: {},
    clock: null,
    intervalId: null,
  },
  mutations: {
    addJob(state, payload) {
      const { id, url } = payload;
      state.jobs[id] = {
        id,
        url,
        status: 'processing',
        attempt: 1,
        pending: false,
        timestamp: Date.now(),
      };
    },
    updateJob(state, payload) {
      const { id, status, url } = payload;
      const { attempt } = state.jobs[id];
      const nextAttempt = attempt ? attempt + 1 : 1;
      state.jobs[id] = {
        id,
        status,
        url,
        pending: false,
        attempt: nextAttempt,
        timestamp: Date.now(),
      };
    },
    setJobPending(state, payload) {
      const { id, pending } = payload;
      const job = state.jobs[id];
      if (job) job.pending = pending;
    },
    removeJob(state, payload) {
      const { id } = payload;
      delete state.jobs[id];
    },
    tickTheClock(state) {
      state.clock = Date.now();
    },
    setIntervalId(state, payload) {
      state.intervalId = payload;
    },
    closePolling(state) {
      clearTimeout(state.intervalId);
      state.intervalId = null;
    },
  },
  actions: {
    addJob({ commit, getters }, payload) {
      const { id } = payload;
      let promise;
      if (!getters.jobs[id]) {
        commit('addJob', payload);
      }
      if (!jobPromiseHooks[id]) {
        let hooks;
        promise = new Promise((resolve, reject) => {
          hooks = { resolve, reject };
        });
        jobPromiseHooks[id] = { ...hooks, promise };
      } else {
        promise = jobPromiseHooks[id].promise;
      }
      return promise;
    },
    updateJob({ commit }, payload) {
      commit('updateJob', payload);
    },
    removeJob({ commit }, payload) {
      commit('removeJob', payload);
    },
    processSingleJob({ commit, dispatch }, payload) {
      // make get request on polling
      const { id, url } = payload;
      commit('setJobPending', { id, pending: true });
      api
        .request({
          url,
          method: 'get',
          data: {},
          _sechub_retry: 1,
        })
        .then((response) => {
          // console.log(response)
          const status = response.status;
          // console.log('poll check status', response)
          switch (status) {
            case 200:
            case 201: // created - success
              dispatch('removeJob', payload);
              jobPromiseHooks[id].resolve(response);
              delete jobPromiseHooks[id];
              break;
            case 205: // in progress
              dispatch('updateJob', payload);
              break;
            default: // error
              dispatch('removeJob', payload);
              jobPromiseHooks[id].reject(response);
              delete jobPromiseHooks[id];
              break;
          }
        })
        .catch((error) => {
          // console.log('job error', error)
          dispatch('updateJob', {
            ...payload,
            status: 'error',
          });
          jobPromiseHooks[id].reject(error);
          delete jobPromiseHooks[id];
        })
        .finally(() => {
          commit('setJobPending', { id, pending: false });
        });
    },
    processJobs({ commit, getters, dispatch }) {
      commit('tickTheClock');
      getters.jobsToCheck.forEach((job) => {
        dispatch('processSingleJob', job);
      });
      commit(
        'setIntervalId',
        setTimeout(() => {
          dispatch('processJobs');
        }, 1000)
      );
    },
    initPolling({ dispatch }) {
      dispatch('processJobs');
    },
    closePolling({ commit }) {
      commit('closePolling');
    },
  },
  getters: {
    jobs: (state) => state.jobs,
    jobsList: (state) => Object.keys(state.jobs).map((id) => state.jobs[id]),
    jobsToCheck: (state) => {
      const now = state.clock;
      return Object.keys(state.jobs)
        .map((id) => state.jobs[id])
        .filter(
          (job) =>
            job.status === 'processing' &&
            !job.pending &&
            (now - job.timestamp) / 1000 >
              (1 + 1.1 ** job.attempt) * job.attempt
        );
    },
  },
};
export default polling;
