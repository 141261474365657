export const hasOwnProperty = (obj, key) =>
  !!obj && Object.prototype.hasOwnProperty.call(obj, key);

export function pick<T, K extends keyof T = keyof T>(
  obj: T,
  keys: readonly K[]
) {
  return keys.reduce<Pick<T, K>>(
    (acc, curr) => {
      if (obj && hasOwnProperty(obj, curr)) acc[curr] = obj[curr];
      return acc;
    },
    {} as Pick<T, K>
  );
}

export function omit<T, K extends keyof T = keyof T>(
  obj: T,
  keys: readonly K[]
) {
  return Object.keys(obj).reduce<Omit<T, K>>(
    (acc, curr) => {
      if (!keys.includes(curr as K)) acc[curr] = obj[curr];
      return acc;
    },
    {} as Omit<T, K>
  );
}

export function merge(target, patch = {}, deep = false) {
  const isObject = (obj) =>
    typeof obj === 'object' && obj !== null && !Array.isArray(obj);
  const extended = { ...patch, ...target };
  return Object.keys(target).reduce((acc, curr) => {
    if (hasOwnProperty(patch, curr)) {
      if (isObject(target[curr]) && isObject(patch[curr])) {
        if (deep) {
          acc[curr] = merge(target[curr], patch[curr]);
        } else {
          acc[curr] = {
            ...target[curr],
            ...patch[curr],
          };
        }
      } else {
        acc[curr] = patch[curr];
      }
    }
    return acc;
  }, extended);
}

export function debounce(func, timeout?: number, immediate?: boolean) {
  let timeoutId;
  return (...args) => {
    const callNow = immediate && !timeoutId;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      if (!immediate) func(...args);
    }, timeout);
    if (callNow) func(...args);
  };
}

export function throttle(func, timeout?: number, immediate?: boolean) {
  let timeoutId;
  return (...args) => {
    if (!timeoutId) {
      timeoutId = setTimeout(() => {
        timeoutId = null;
        if (!immediate) func(...args);
      }, timeout);
      if (immediate) func(...args);
    }
  };
}

export function throttleAnimationFrame(func) {
  let animationFrameRequested = false;
  let lastArgs = null;
  return (...args) => {
    lastArgs = args;
    if (!animationFrameRequested) {
      animationFrameRequested = true;
      requestAnimationFrame(() => {
        animationFrameRequested = false;
        func(...lastArgs);
      });
    }
  };
}

export function deepEqual(a: unknown, b: unknown) {
  if (a === b) return true;
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (Array.isArray(a) || Array.isArray(b)) {
      if (!Array.isArray(a) || !Array.isArray(b) || a.length !== b.length)
        return false;
      return a.every((ai, i) => deepEqual(ai, b[i]));
    }
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    return Object.keys(a).every(
      (key) =>
        Object.prototype.hasOwnProperty.call(b, key) &&
        deepEqual(a[key], b[key])
    );
  }
  return false;
}

export const dedupe = <T>(
  list: T[],
  uniqueString: (item: T) => string = (i) => `${i}`
): T[] => {
  const map = list.reduce(
    (acc, item) => ({ ...acc, [uniqueString(item)]: item }),
    {} as Record<string, T>
  );
  return Object.values(map);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

/**
 * Like `Object.assign` but working on descriptors level
 * `Object.assign` only treats everything as regular property
 */
export function objectAssignDescriptors<T, S extends unknown[]>(
  target: T,
  ...sources: S
): T & UnionToIntersection<S[number]> {
  sources.forEach((source) => {
    const descriptors = Object.keys(source || {}).reduce((descriptors, key) => {
      descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
      return descriptors;
    }, {});
    Object.defineProperties(target, descriptors);
  });
  return target as T & UnionToIntersection<S[number]>;
}

export const delay = async (ms?: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * [source](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping)
 */
export const escapeRegExp = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

/**
 * Digests the string value into a single hash value (number) in such a way
 * that it is unlikely that another string will have the same code.
 * [source](https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/)
 */
export const stringToHashCode = (str: string): number => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * See {@link stringToHashCode} for details
 */
export const arrayToHashCode = (arr: string[]): number =>
  arr.reduce((result, id) => (result << 5) - result + stringToHashCode(id), 1);
