import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import { getUserId } from '@/auth';
import jv from '@/store/components/jv';

export default createModule({
  path: 'dashboard',
  resourceProfile: resources.dashboard,
  components: [jv, crud, indexManager],
  setup({ components, getAccessors, resourceProfile }) {
    const getters = {
      getDashboard: () => (projectId) => {
        const resources = components.$jv.protected.get(
          resourceProfile.type,
          // https://github.com/dchester/jsonpath#jsonpath-syntax
          `$[?(@._jv.relationships.project.data?.id=="${projectId}")]`
        );
        const id = Object.keys(resources)[0];
        return id && components.$crud.public.getSimplifiedResourceSet()[id];
      },
    };

    const actions = {
      loadDashboard(context, payload: { projectId: string }) {
        const { projectId } = payload;
        return components.$crud.public.dispatchLoadSingleResource({
          basePath: `/projects/${projectId}/relationships/dashboards/${getUserId()}`,
        });
      },
    };

    const { read, dispatch } = getAccessors();

    return {
      module: {
        getters,
        actions,
      },
      public: {
        ...components.$crud.public,
        getDashboard: read(getters.getDashboard),
        dispatchLoadDashboard: dispatch(actions.loadDashboard),
      },
    };
  },
});
