export const getClassificationEntryRules = (
  { required }: { required: boolean } = { required: true }
) => ({
  code: [
    (value) => !required || !!value || 'Code is required',
    (value) =>
      /^[A-Z]*$/.test(value || '') || 'Only capital letters are allowed',
    (value) =>
      /^[^IO]*$/.test(value || '') ||
      'Characters "I" and "O" are not permitted',
  ],
  definition: [(value) => !required || !!value || 'Definition is required'],
  name: [(value) => !required || !!value || 'Name is required'],
});

export const contextNameRules = [
  (value) => !!value || 'Name is required',
  (value) =>
    !(value?.includes('<') || value?.includes('>')) ||
    'Characters "<" and ">" are not permitted',
];

export const projectNameRules = [
  (value) => !!value || 'Name is required',
  (value) =>
    /[|/?*"^><]/.test(value) ? 'Characters |/?*^>< are not permitted' : true,
];

export const classificationNameRules = [
  (value) => !!value || 'Name is required',
];

export const classificationFileRules = [
  (value) => !!value || 'File is required',
];

export const userSearchRule = (value: string) =>
  value.length > 2 || 'Minimum field length is 3';
