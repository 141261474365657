<template>
  <FocusTrap
    :active="userCanEditContext && !isDialogOpened"
    :initialFocus="() => formRef.queryFocusableNodes()[0]"
  >
    <div class="context-form">
      <GenericForm
        ref="form"
        :resource="context"
        :formConfig="formConfig"
        :readonly="!userCanEditContext"
        :progressInPayload="!this.editMode && !!fileImportCoreModel"
        :showErrorDetails="showErrorDetails"
        submitLabel="Create Context"
        @cancel="onCancel"
        @created="onCreated"
        @failed="onFailed"
      >
        <template #field-syntax="{ values, saveRelationship, field }">
          <SyntaxSelectorInput
            :use-label="false"
            :initial-selection="values[field.attribute]"
            @update:selected-syntax="
              updateSyntax($event, values, saveRelationship)
            "
            :disable-modal-selector="
              (!field.ignoreFormReadonly && !userCanEditContext) ||
              field.readonly
            "
            :rules="field.rules"
            :errorMessages="field.errorMessages"
          />
        </template>
        <template #field-import>
          <ImportUploadField
            v-model="fileImportCoreModel"
            placeholder="Select a file"
            outlined
            hide-details
          />
          <a
            class="import-template-link text-body-2 mb-4 mt-2"
            :href="importCoreTemplateUrl"
            download="Import Core Template.xlsx"
          >
            <v-icon class="ml-1 mr-3" size="small">mdi-download</v-icon>
            download import core template
          </a>
        </template>
        <template #field-import_simo="{ values }">
          <v-tooltip
            :disabled="!!values.simo_classification_table"
            location="top"
          >
            <span>{{ $t('apps.design.core.selectClassificationTable') }}</span>
            <template #activator="{ props }">
              <div v-bind="props">
                <ImportUploadField
                  v-model="fileImportSimoModel"
                  :disabled="!values.simo_classification_table"
                  placeholder="Select a file"
                  outlined
                  hide-details
                />
              </div>
            </template>
          </v-tooltip>
          <div v-if="editMode && fileImportSimoModel" class="text-right mt-3">
            <v-btn
              color="primary"
              @click="importContextSimo(context)"
              :loading="importingSimo"
            >
              Import
            </v-btn>
          </div>
          <a
            v-else
            class="import-template-link text-body-2 mb-5 mt-2"
            :href="importSimoTemplateUrl"
            download="Import Simo Template.xlsx"
          >
            <v-icon class="ml-1 mr-3" size="small">mdi-download</v-icon>
            download import simo template
          </a>
        </template>
      </GenericForm>

      <!-- <div
        v-if="canDuplicate || canUpdateContextSyntax"
        class="context-form__actions"
      >
        <v-btn
          v-if="canDuplicate"
          color="primary"
          variant="outlined"
          @click="onDuplicate"
        >
          <v-icon start>mdi-content-copy</v-icon> Copy
        </v-btn>
        <v-tooltip location="top" :disabled="!hasTradeStudies">
          <span>
            {{ $t('syntaxUpdate.notAllowedDuringActiveTradeStudies') }}</span
          >
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn
                v-if="syntaxUpdateAllowed && canUpdateContextSyntax"
                :disabled="hasTradeStudies"
                color="primary"
                variant="outlined"
                @click="onSyntaxUpdate"
              >
                Update syntax
              </v-btn>
            </div>
          </template>
        </v-tooltip>
        <v-btn
          v-if="canUpdateContextSyntax && isSyntaxUpdateInProgress"
          @click="cancelSyntaxUpdate"
          :loading="isCancelingSyntaxUpdate"
          color="primary"
          variant="outlined"
        >
          Cancel syntax update
        </v-btn>
      </div> -->

      <div class="context-form__actions" v-if="editMode">
        <v-tooltip location="left" :disabled="false">
          <template #activator="{ props }">
            <div v-bind="props" class="project-form__actions">
              <v-btn color="primary" variant="outlined" :disabled="true">
                <v-icon start>mdi-content-copy</v-icon> Copy
              </v-btn>
            </div>
          </template>
          <span>
            Copying of contexts is unfortunately down for maintenance at the
            moment.
          </span>
        </v-tooltip>

        <v-tooltip location="left" :disabled="false">
          <template #activator="{ props }">
            <div v-bind="props" class="project-form__actions">
              <v-btn color="primary" variant="outlined" :disabled="true">
                <v-icon start>mdi-content-copy</v-icon>
                Update syntax
              </v-btn>
            </div>
          </template>
          <span>
            Syntax updates are unfortunately down for maintenance at the moment.
          </span>
        </v-tooltip>
      </div>

      <div v-if="canArchiveRestore" class="context-form__archive">
        <v-btn
          v-if="isArchived"
          color="error"
          :loading="isUnarchiving"
          variant="outlined"
          @click="unarchiveContext"
        >
          <v-icon start>mdi-restore</v-icon> restore
        </v-btn>
        <v-tooltip v-else :disabled="!isSyntaxUpdateInProgress" location="left">
          <span>{{ $t('syntaxUpdate.notAllowedDuringSyntaxUpdate') }}</span>
          <template #activator="{ props }">
            <div v-bind="props" class="width-fit-content">
              <v-btn
                color="error"
                :disabled="isSyntaxUpdateInProgress"
                :loading="isArchiving"
                variant="outlined"
                @click="confirmArchive"
              >
                <v-icon start>mdi-alert-circle</v-icon> archive
              </v-btn>
            </div>
          </template>
        </v-tooltip>
        <ConfirmDialog
          ref="confirmDialog"
          :title="dialogModalConfig.title"
          :okButtonText="dialogModalConfig.confirmButtonText"
          okButtonColor="error"
          cancelButtonIcon="mdi-close"
          okButtonIcon="mdi-alert-circle"
          :disableButtons="isArchiving"
          maxWidth="540"
        />
      </div>
    </div>
  </FocusTrap>
</template>

<script lang="ts">
import { Options, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { FocusTrap } from 'focus-trap-vue';
import { Portal } from 'portal-vue';
import GenericForm from '../genericForm.vue';
import ImportUploadField from '@/components/importUploadField.vue';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { Context } from '@/models/context';
import { Project } from '@/models/project';
import { resources } from '@/services/resources';
import { RouteName } from '@/enums/routing';
import {
  CreateResourcePayload,
  FormFieldDefinition,
  FormFieldType,
  FormConfig,
} from '@/services/forms';
import { composeMessage } from '@/services/errorHandler';
import importCoreTemplateUrl from '@/assets/downloads/import_core_template.xlsx?url';
import importSimoTemplateUrl from '@/assets/downloads/import_simo_template.xlsx?url';
import { contextNameRules } from '@/utils/validationRules';
import SyntaxSelectorInput from '@/components/syntaxSelector/syntaxSelectorInput.vue';

type ConfirmationDialogType = 'archived' | 'cancel_syntax_update';

@Options({
  components: {
    SyntaxSelectorInput,
    ConfirmDialog,
    FocusTrap,
    GenericForm,
    ImportUploadField,
    Portal,
  },
  emits: [
    'created',
    'cancel',
    'duplicate',
    'import',
    'archive',
    'syntaxUpdate',
  ],
})
export default class ProjectsContextForm extends Vue {
  @Prop({ default: null }) contextId?: string | null;
  @Prop({ required: true }) projectId: string;
  @Prop(Boolean) syntaxUpdateAllowed?: boolean;
  @Prop(Boolean) hasTradeStudies?: boolean;
  @Prop({ required: true }) contextNameList: string[];
  @Ref('form') readonly formRef: GenericForm;
  @Ref() readonly confirmDialog: ConfirmDialog;

  confirmationDialogType: ConfirmationDialogType = null;
  fileImportCoreModel: File = null;
  fileImportSimoModel: File = null;
  loadedNumberOfOors = false;
  numberOfOors = 0;
  isArchiving = false;
  isCancelingSyntaxUpdate = false;
  isUnarchiving = false;
  isDialogOpened = false;
  showErrorDetails = false;
  importingSimo = false;

  get importCoreTemplateUrl() {
    return importCoreTemplateUrl;
  }

  get importSimoTemplateUrl() {
    return importSimoTemplateUrl;
  }

  get dialogModalConfig() {
    const title: Record<ConfirmationDialogType, ReturnType<typeof this.$t>> = {
      archived: this.$t('archiving.confirmArchiveContext'),
      cancel_syntax_update: this.$t('syntaxUpdate.confirmCancelSyntaxUpdate'),
    };

    const confirmButtonText: Record<
      ConfirmationDialogType,
      ReturnType<typeof this.$t>
    > = {
      archived: this.$t('archiving.archiveLabel'),
      cancel_syntax_update: this.$t('general.confirm'),
    };

    return {
      title: title[this.confirmationDialogType] || '',
      confirmButtonText: confirmButtonText[this.confirmationDialogType] || '',
    };
  }

  get formConfig(): FormConfig {
    const schema: FormFieldDefinition[] = [
      {
        attribute: 'name',
        label: 'Name',
        type: FormFieldType.Text,
        rules: [
          ...contextNameRules,
          (value: string) => {
            if (this.editMode && this.context.name === value) return true;
            return (
              !this.contextNameList.includes(value.toLowerCase()) ||
              'Name within project must be unique'
            );
          },
        ],
        readonly: this.isArchived,
      },
      {
        attribute: 'description',
        label: 'Description',
        type: FormFieldType.Textarea,
        readonly: this.isArchived,
      },
      {
        attribute: 'progress',
        label: 'Progress',
        type: FormFieldType.ProgressWidget,
        targetType: resources.contexts.type,
        targetId: this.contextId,
        progressSteps: this.$store.$direct.progress.getProgressSteps(
          resources.contexts.type
        ),
        ignoreFormReadonly: true,
        readonly:
          this.isArchived ||
          (this.editMode &&
            !this.$store.$direct.userPermission.canWriteContextProgress(
              this.projectId
            )),
      },
      {
        attribute: 'syntax',
        label: 'Syntax',
        type: FormFieldType.Slot,
        readonly: this.editMode || this.isArchived,
        ignoreValue: false,
        isRelationship: true,
        rules: [(value) => !!value || 'Syntax is required'],
        errorMessages: this.hasContextObsoleteSyntax
          ? ['Syntax is archived or uses archived resources']
          : [],
      },
      {
        attribute: 'simo_classification_table',
        label: 'SIMO classification table',
        type: FormFieldType.ResourceSelector,
        placeholder: this.isSimoClassificationTableReadonly
          ? 'No classification selected'
          : '',
        resourceConfig: {
          module: this.$store.$direct.classificationTable,
          pathParameters: {
            filterClassificationTableType: 'simo',
            filterArchived: false,
            filterPublished: 'true',
          },
        },
        readonly: this.hasClassificationTable || this.isArchived,
      },
      !this.editMode && {
        attribute: 'import',
        label: 'Import CORE',
        type: FormFieldType.Slot,
        ignoreValue: true,
      },
      this.shouldShowSimoImportField && {
        attribute: 'import_simo',
        label: 'Import SIMO',
        type: FormFieldType.Slot,
        ignoreValue: true,
      },
    ];
    return {
      schema: schema.filter(Boolean),
      createResource: (payload) =>
        !this.fileImportCoreModel
          ? this.$store.$direct.context.dispatchCreateResource({
              ...payload,
              relationshipKey: 'project',
              relationshipId: this.projectId,
            })
          : this.importContext(payload),
      updateResource: this.$store.$direct.context.dispatchUpdateResource,
    };
  }

  async importContext(payload: CreateResourcePayload) {
    this.showErrorDetails = true;
    const isCoreAndSimoImport =
      !!this.fileImportCoreModel && !!this.fileImportSimoModel;
    const { id } = await this.importContextCore(payload);
    this.onCreated(id);
    if (isCoreAndSimoImport) {
      const context =
        this.$store.$direct.context.getSimplifiedResourceSet()[id];
      await this.importContextSimo(context);
    }
    this.$store.$direct.notifications.dispatchNotify({
      type: 'success',
      message: 'Context has been successfully imported',
    });
    return { id };
  }

  async importContextCore(payload: CreateResourcePayload) {
    const progress =
      this.$store.$direct.progress
        .getProgressSteps(resources.contexts.type)
        .find((step) => step.id === payload.relationships?.progress)?.name ||
      undefined;
    const { id } = await this.$store.$direct.context.dispatchImportContextCore({
      projectId: this.projectId,
      file: this.fileImportCoreModel,
      context: {
        ...(payload.resource as Pick<Context, 'name' | 'description'>),
        syntax_id: payload.relationships.syntax,
        progress,
      },
    });
    if (id) {
      try {
        await this.$store.$direct.context.dispatchUpdateResource({
          resourceId: id,
          resource: {},
          relationships: {
            simo_classification_table:
              payload.relationships.simo_classification_table,
          },
        });
      } catch (error) {
        this.$store.$direct.notifications.dispatchNotify({
          type: 'error',
          message: 'Failed to set SIMO classification table',
        });
      }
    }
    return { id };
  }

  get RouteName() {
    return RouteName;
  }

  get contextResourceType() {
    return resources.contexts.type;
  }

  get context(): Context {
    return (
      this.contextId &&
      this.$store.$direct.context.getSimplifiedResourceSet()[this.contextId]
    );
  }

  get hasClassificationTable(): boolean {
    return !!this.context?.simo_classification_table;
  }

  get project(): Project {
    return this.$store.$direct.project.getSimplifiedResourceSet()[
      this.projectId
    ];
  }

  get isArchived() {
    return this.editMode && this.context.archived;
  }

  get isSimoClassificationTableReadonly() {
    return this.hasClassificationTable || this.isArchived;
  }

  get isSyntaxUpdateInProgress() {
    return !!this.context?.temporal;
  }

  get canArchiveContext() {
    return this.$store.$direct.userPermission.canArchiveContext(this.projectId);
  }

  get canArchiveRestore() {
    return this.editMode && this.canArchiveContext && !this.project.archived;
  }

  get canUpdateContextSyntax() {
    return (
      this.$store.$direct.userPermission.canUpdateContextSyntax(
        this.projectId
      ) && this.editMode
    );
  }

  get hasSyntaxInUpdate() {
    return !!this.context?.temporal;
  }

  get canDuplicate() {
    return (
      this.$store.$direct.userPermission.canCreateContext(this.projectId) &&
      this.editMode
    );
  }

  get editMode() {
    return !!this.context;
  }

  get userCanEditContext() {
    return (
      !this.editMode ||
      (!!this.project &&
        !!this.context &&
        !this.context.trade_study &&
        this.$store.$direct.userPermission.canWriteContext(this.projectId))
    );
  }

  get hasContextObsoleteSyntax() {
    return (
      this.editMode &&
      this.$store.$direct.context.getHasObsoleteSyntax(this.contextId)
    );
  }

  isObsoleteSyntax(syntaxId: string) {
    return this.$store.$direct.syntax.getHasObsoleteClassificationTable(
      syntaxId
    );
  }

  get shouldShowSimoImportField() {
    if (this.isArchived) return false;
    if (this.editMode) {
      return (
        this.userCanEditContext &&
        this.loadedNumberOfOors &&
        !this.hasContextImportInProgress &&
        this.numberOfOors === 0 &&
        !this.hasSyntaxInUpdate
      );
    }

    return !!this.fileImportCoreModel;
  }

  get hasContextImportInProgress() {
    const imports = this.$store.$direct.importFile.getPaginatedResource(
      'default',
      `context-${this.context?.id}`
    );
    return imports.some(({ processing }) => processing);
  }

  async loadLastContextImport() {
    if (!this.context?.id) return;
    await this.$store.$direct.importFile.dispatchLoadLastContextImport({
      contextId: this.context.id,
    });
  }

  async importContextSimo(context: Context) {
    if (!this.fileImportSimoModel || !context.simo_classification_table.id) {
      return;
    }
    this.importingSimo = true;
    const file = this.fileImportSimoModel;
    try {
      await this.$store.$direct.context.dispatchImportContextSimo({
        contextId: context.id,
        file,
      });
      this.$emit('import', context.id);
    } finally {
      this.importingSimo = false;
    }
  }

  onCancel() {
    this.$emit('cancel');
  }

  onCreated(contextId: string) {
    this.$emit('created', { contextId, projectId: this.projectId });
  }

  onFailed() {
    this.showErrorDetails = false;
  }

  onDuplicate() {
    this.$emit('duplicate');
  }

  onSyntaxUpdate() {
    this.$emit('syntaxUpdate');
  }

  async cancelSyntaxUpdate() {
    this.confirmationDialogType = 'cancel_syntax_update';
    this.isDialogOpened = true;

    this.confirmDialog
      .confirm()
      .then(async (val) => {
        if (val) {
          this.isCancelingSyntaxUpdate = true;
          await this.$store.$direct.context.dispatchCancelSyntaxUpdate({
            contextId: this.contextId,
          });
          await this.$store.$direct.context.dispatchLoadSingleResource({
            resourceId: this.contextId,
          });
          this.$store.$direct.notifications.dispatchNotify({
            type: 'success',
            message: this.$t('syntaxUpdate.successfullyCanceled'),
          });
        }
      })
      .finally(() => {
        this.isDialogOpened = false;
        this.confirmationDialogType = null;
        this.isCancelingSyntaxUpdate = false;
      });
  }

  async confirmArchive() {
    if (this.isSyntaxUpdateInProgress) return;
    this.confirmationDialogType = 'archived';
    this.isDialogOpened = true;
    this.confirmDialog
      .confirm()
      .then(async (val) => {
        if (val) {
          await this.archiveContext();
          this.$emit('archive', { contextId: this.contextId, archived: true });
        }
      })
      .finally(() => {
        this.isDialogOpened = false;
        this.confirmationDialogType = null;
      });
  }

  async archiveContext() {
    if (this.editMode && !this.isArchived) {
      this.isArchiving = true;
      await this.$store.$direct.context
        .dispatchArchiveResource({ resourceId: this.contextId })
        .catch((err) =>
          this.$store.$direct.notifications.dispatchNotify({
            type: 'error',
            message: composeMessage(err),
          })
        );
      this.isArchiving = false;
    }
  }

  async unarchiveContext() {
    if (this.editMode && this.isArchived) {
      this.isUnarchiving = true;
      await this.$store.$direct.context
        .dispatchUnarchiveResource({ resourceId: this.contextId })
        .catch((err) =>
          this.$store.$direct.notifications.dispatchNotify({
            type: 'error',
            message: composeMessage(err),
          })
        );
      this.isUnarchiving = false;
      this.$emit('archive', { contextId: this.contextId, archived: false });
    }
  }

  async loadNumberOfRootNodeChildren(contextId: string) {
    const rootNodeId =
      this.$store.$direct.context.getRelatedRootsNodeId(contextId);
    const ooc = await this.$store.$direct.ooc.dispatchLoadSingleResource({
      resourceId: rootNodeId,
      depth: 0,
    });
    return Object.keys(ooc?.components || {}).length;
  }

  async loadNumberOfOors(contextId: string): Promise<void> {
    const data = await this.$store.$direct.simo.dispatchLoadSingleResource({
      filterContextId: contextId,
      page: 1,
      pageSize: 1,
    });
    this.numberOfOors = data._jv.json.meta?.total_count || 0;
  }

  updateSyntax(updatedSyntax, formModel, updateRelationshipCb) {
    formModel['syntax'] = updatedSyntax;
    updateRelationshipCb('syntax');
  }

  @Watch('contextId', { immediate: true })
  async resetState() {
    this.fileImportCoreModel = null;
    this.fileImportSimoModel = null;
    this.loadedNumberOfOors = false;
    if (this.contextId) {
      try {
        await this.loadLastContextImport();
        await this.loadNumberOfOors(this.contextId);
      } finally {
        this.loadedNumberOfOors = true;
      }
    }
  }

  @Watch('hasContextImportInProgress')
  async onHasContextImportInProgressChange(value, oldValue) {
    if (oldValue && !value) {
      try {
        this.loadedNumberOfOors = false;
        await this.loadNumberOfOors(this.contextId);
      } finally {
        this.loadedNumberOfOors = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.import-template-link {
  display: block;
  text-decoration: none;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.context-form {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.context-form__actions {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    margin: 0 auto;
    width: calc(100% - 48px);
    height: 0;
    border-bottom: 1px solid rgb(var(--v-theme-line));
  }
}

.context-form__archive {
  margin-top: auto;
  padding: 24px;
  text-align: right;
  display: flex;
  justify-content: end;
}
.width-fit-content {
  width: fit-content;
}
</style>
