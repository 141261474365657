<template>
  <v-card>
    <v-card-title> Syntax selector </v-card-title>
    <div class="syntax-selector-modal-body">
      <ResourceSelector
        ref="syntaxSelectorComponent"
        :resource-config="stxResourceConfig"
        v-model="syntaxSelection"
        :emit-blur="false"
        variant="outlined"
        :menu-props="{
          attach: '.syntax-selector-modal-body',
        }"
        :disabled="isSelectionDisabled"
        placeholder="Select syntax"
      />
      <div class="syntax-tree-wrapper">
        <div
          class="syntax-tree-wrapper__empty-state"
          v-if="!isSyntaxLoaded && !syntaxSelection"
        >
          <p>Syntax preview</p>
        </div>
        <div
          v-else-if="!isSyntaxLoaded"
          class="syntax-tree-wrapper__empty-state"
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="rgb(var(--v-theme-color))"
          />
        </div>
        <TreeStructure
          v-else
          ref="tree"
          readonly
          :rootNode="syntaxTree"
          :resourceProfile="resourceProfile"
          disableShortcuts
          lockStructure
          :itemsLoaded="isTreeLoaded"
          :tree="treeId"
          @selectNode="() => {}"
          @scroll="() => {}"
        />
      </div>
    </div>
    <v-card-actions class="syntax-selector-modal__actions">
      <v-btn
        v-if="!isSelectionDisabled"
        color="primary"
        @click="saveSelectedSyntax"
        >Save</v-btn
      >
      <v-btn @click="emits('closeModal')">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import ResourceSelector from '@/components/resourceSelector.vue';
import {
  computed,
  nextTick,
  onMounted,
  provide,
  reactive,
  ref,
  watch,
} from 'vue';
import { useDirectStore } from '@/composables/store';
import TreeStructure from '@/components/setup/syntax/treeStructure/treeStructure.vue';
import { createIncludeRelationshipPath, resources } from '@/services/resources';
import { TreeIdentification } from '@/utils/tree';
import { ResourceListConfig } from '@/utils/resourceList';

const props = withDefaults(
  defineProps<{
    initialValue: any;
    isSelectionDisabled: boolean;
    resourceConfig?: ResourceListConfig;
  }>(),
  { isSelectionDisabled: false, resourceConfig: null }
);

const store = useDirectStore();

const syntaxSelection = ref<any>();
const isSyntaxLoaded = ref<boolean>(false);
const isTreeLoaded = ref<boolean>(false);
const resourceProfile = resources.syntaxNode;
const syntaxSelectorComponent = ref<HTMLElement | null>(null);

const emits = defineEmits<{
  (e: 'update:syntaxSelection', selection: any): void;
  (e: 'closeModal'): void;
}>();

const treeContextLegacy = reactive({
  resourceType: resources.syntaxNode.type,
  get module() {
    return store.syntaxNode;
  },
  get edited() {
    return false;
  },
  get isDraggingNode() {
    return false;
  },
  get canDrop() {
    return false;
  },
  canEdit: () => false,
  canCutDuplicatePaste: () => false,
  canPaste: () => false,
  canCreate: () => false,
});

provide('treeContext', treeContextLegacy);

const stxResourceConfig = computed(() => {
  if (props.resourceConfig) {
    return props.resourceConfig;
  }
  return {
    module: store.syntax,
    pathParameters: {
      filterArchived: false,
      filterPublished: true,
    },
  };
});

const syntaxId = computed(() => {
  if (syntaxSelection.value && syntaxSelection.value === null) {
    return null;
  }
  return syntaxSelection.value?.id;
});

const syntaxTree = computed(() => {
  if (!syntaxSelection.value) {
    return null;
  }
  return store.syntaxNode.getStructuredTree({
    contextId: syntaxSelection.value.id,
  });
});

const treeId = computed((): TreeIdentification => {
  return { contextId: syntaxId.value };
});

const rootSyntaxNodeId = computed(() => {
  return store.syntax.getRelatedRootsNodeId(syntaxId.value);
});

const loadSyntaxAndNodes = async () => {
  await store.syntax.dispatchLoadSingleResource({
    resourceId: syntaxId.value,
  });
  await loadFullSyntaxTree();
  isTreeLoaded.value = true;
};

const loadFullSyntaxTree = async () => {
  return store.syntaxNode.dispatchLoadFullTree({
    tree: treeId.value,
    rootNodeId: rootSyntaxNodeId.value,
  });
};

const loadClassificationTables = () => {
  return store.classificationTable.dispatchLoadCorePublishedTables();
};

const loadSyntaxElements = () => {
  return store.syntaxElement.dispatchLoadFullResource({
    filterSyntaxId: syntaxId.value,
    include: [
      createIncludeRelationshipPath(resources.syntaxElement)(
        'classification_table'
      ),
    ],
  });
};

const saveSelectedSyntax = () => {
  emits('update:syntaxSelection', syntaxSelection.value);
  emits('closeModal');
};

onMounted(async () => {
  if (props.initialValue) {
    syntaxSelection.value = props.initialValue;
  }
  await nextTick();
  if (!syntaxSelection.value && !props.isSelectionDisabled) {
    (syntaxSelectorComponent.value as any).$refs.vcomponent.menu = true;
    const inputTag = (
      syntaxSelectorComponent.value as any
    ).$refs.vcomponent.$el.querySelector('input');
    inputTag.click();
    setTimeout(() => {
      inputTag.focus();
    }, 300);
  }
});

watch(syntaxId, () => {
  isSyntaxLoaded.value = false;
  const storedTree = store.syntaxNode.getStructuredTree({
    contextId: syntaxSelection.value.id,
  });
  if (storedTree) {
    isSyntaxLoaded.value = true;
    isTreeLoaded.value = true;
    return;
  }
  Promise.all([
    loadSyntaxAndNodes(),
    loadSyntaxElements(),
    loadClassificationTables(),
  ])
    .then(() => {
      isSyntaxLoaded.value = true;
    })
    .catch((error) =>
      error('Some data could not be loaded - reload the page to try again')
    );
});
</script>

<style scoped lang="scss">
.syntax-selector-modal-body {
  padding: 0 5%;
}
.syntax-tree-wrapper {
  background-color: rgb(var(--v-theme-background));
  height: 50vh;
}
.syntax-tree-wrapper__empty-state {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
    opacity: 0.55;
  }
}
.syntax-selector-modal__actions {
  display: flex;
  justify-content: flex-end;
}
</style>
