<template>
  <v-list-item lines="three" class="user-notifications-export__list-item">
    <div>
      <v-list-item-title>{{ notification.title }}</v-list-item-title>
      <v-list-item-subtitle>{{ notification.name }}</v-list-item-subtitle>
      <v-list-item-subtitle>
        Started: {{ notification.started }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Finished: {{ notification.finished }}
      </v-list-item-subtitle>
    </div>
    <div class="user-notifications-export__files">
      <v-btn
        v-if="notification?.csv_endpoint"
        @click="
          downloadFile(notification.csv_endpoint, {
            filename,
          })
        "
        variant="plain"
        size="small"
        color="primary"
        density="compact"
      >
        Download csv
      </v-btn>
      <v-btn
        v-if="notification?.excel_endpoint"
        @click="
          downloadFile(notification.excel_endpoint, {
            filename,
          })
        "
        variant="plain"
        size="small"
        color="primary"
        density="compact"
      >
        Download xlsx
      </v-btn>
    </div>
  </v-list-item>
</template>
<script lang="ts" setup>
import { useExportStore } from '@/stores/export';
import { computed } from 'vue';

const props = defineProps<{
  notification: any;
}>();

const { downloadFile } = useExportStore();

const filename = computed(() =>
  [props.notification.title, props.notification.name].join(' - ')
);
</script>
<style lang="scss" scoped>
.user-notifications-export__list-item :deep(.v-list-item__content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-notifications-export__files {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  margin: 0;
}
</style>
