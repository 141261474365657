<template>
  <div class="recent-work__cards-wrapper">
    <v-card
      v-for="recentWork in recentWorkURLs"
      :key="recentWork.id"
      @click="goToURL(recentWork.path)"
      class="recent-work__cards"
    >
      <v-img
        class="recent-work__image"
        eager
        :src="
          recentWorkImage(
            recentWork.type,
            recentWork.contextIds.length,
            recentWork.revisionNumber
          )
        "
      >
      </v-img>
      <div class="recent-work__cards-text-area">
        <div class="recent-work__cards-text-container">
          <div class="recent-work__title-wrapper">
            <span class="recent-work_title">{{ recentWork.title }}</span>
            <div class="recent-work_title-curve"></div>
          </div>
          <div
            class="context-text__wrapper"
            v-if="recentWork.contextNames.length === 1"
          >
            <div class="context-text">
              {{ contextTextGenerator(recentWork) }}
            </div>
          </div>
          <div v-else class="context-text__wrapper">
            <div class="doma__context-text">
              {{
                domaStringConverter(
                  recentWork.contextNames,
                  recentWork?.contextPrefix
                )
              }}
            </div>
            <div
              v-if="recentWork.contextNames.length - 2 > 0"
              class="doma__context-more"
            >
              {{ recentWork.contextNames.length - 2 + ' more' }}
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts" setup>
import core1ContextImg from '@/assets/recentWorkDummy/CORE-1-Context.png';
import core2ContextImg from '@/assets/recentWorkDummy/CORE-2-Context.png';
import core3ContextImg from '@/assets/recentWorkDummy/CORE-3-Context.png';
import coreRevisionImg from '@/assets/recentWorkDummy/CORE-1-Revision.png';
import simoRevisionImg from '@/assets/recentWorkDummy/SIMO-Revision.png';
import simoImg from '@/assets/recentWorkDummy/SIMO.png';
import { RecentWorkURL } from '@/models/recentWorkURL';
import { useRouter } from '@/composables/router';

defineProps<{
  recentWorkURLs: RecentWorkURL[];
}>();

const router = useRouter();

const goToURL = (path: string) => {
  router.push({
    path,
  });
};

const recentWorkImage = (
  type: string,
  numberOfContexts: number,
  revisionNumber: number
) => {
  if (revisionNumber && type !== 'SIMO') return coreRevisionImg;
  if (revisionNumber && type === 'SIMO') return simoRevisionImg;
  if (type === 'SIMO') return simoImg;
  if (numberOfContexts >= 3) return core3ContextImg;
  if (numberOfContexts === 2) return core2ContextImg;
  return core1ContextImg;
};
const contextTextGenerator = (recentWork) => {
  if (!recentWork) return '';
  const { revisionNumber, tradeStudy, contextPrefix, contextNames } =
    recentWork;
  let contextString = '';
  // Base contextName
  const baseName = contextPrefix
    ? `${contextPrefix[0]} ${contextNames[0]}`
    : contextNames[0];
  if (revisionNumber) {
    contextString = `${baseName} / Revision ${revisionNumber}`;
  } else if (tradeStudy) {
    contextString = `${baseName} / Trade-Study ${tradeStudy}`;
  } else {
    contextString = baseName;
  }
  return contextString;
};

const domaStringConverter = (contextNames, contextPrefix) => {
  let domaString = '';
  if (contextPrefix) {
    for (let i = 0; i < 2; i++) {
      domaString += contextPrefix[i] + ' ' + contextNames[i];
      if (contextNames.length - 1 !== i) {
        domaString += ' / ';
      }
    }
  } else {
    for (let i = 0; i < 2; i++) {
      domaString += contextPrefix[i] + ' ' + contextNames[i];
      if (contextNames.length - 1 !== i) {
        domaString += ' / ';
      }
    }
  }
  return domaString;
};
</script>

<style lang="scss" scoped>
.recent-work__cards-wrapper {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(240px, 0.4fr));
  overflow: hidden;
  max-height: 300px;
}
.recent-work__cards {
  background-color: #eee;
  transition: transform 0.4s ease-out;
  margin: 10px;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    transform: scale(1.05);
    border: 1px solid gray;
  }

  &:hover .recent-work_title {
    white-space: normal;
    max-height: 70px;
  }

  &:focus .recent-work_title {
    white-space: normal;
    max-height: 70px;
  }
}

.recent-work__image {
  padding-bottom: 40px;
  :deep(.v-img__img) {
    height: unset;
  }
}

.recent-work__cards-text-area {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
}

.recent-work__cards-text-container {
  display: flex;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.recent-work__title-wrapper {
  display: flex;
  align-items: flex-end;
}
.recent-work_title {
  font-size: 1.5rem;
  border-top-right-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
  transition: max-height 0.5s ease-out;
  max-height: 35px;
  background-color: white;
}

.recent-work_title-curve {
  height: 18px;
  width: 18px;
  background-color: white;
  background: radial-gradient(
    circle at 80% 20%,
    transparent,
    transparent 14.75px,
    #fff 15px
  );
}

.context-text__wrapper {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 10px;
  overflow: hidden;
  max-height: 25px;
  width: 100%;
  background-color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.context-text {
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doma__context-text {
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.doma__context-more {
  font-size: 1.1rem;
  padding-right: 5px;
  color: #a9a9a9;
}
</style>
