import {
  findRelationshipByType,
  resources,
  ResourceType,
} from '@/services/resources';
import { createModule } from '../utils/factories';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import context from './context';
import { Context } from '@/models/context';
import jv from '../components/jv';
import { JsonApiIdentification } from '@/models/api';
import { CustomField, CustomFieldParent } from '@/models/customField';
import { Project } from '@/models/project';
import project from './project';

const contextCustomFieldsRelationshipKey = findRelationshipByType(
  resources.contexts,
  resources.customField.type
);

export default createModule({
  path: 'customField',
  resourceProfile: resources.customField,
  components: [crud, indexManager, jv],
  modules: [context, project],
  setup({ getAccessors, components, modules, resourceProfile }) {
    const getters = {
      customFields:
        () =>
        (payload: {
          scope: { type: ResourceType; id: string };
          parent: CustomFieldParent;
        }) => {
          const resource =
            payload.scope.type === 'context'
              ? (modules.context.public.getSimplifiedResourceSet()[
                  payload.scope.id
                ] as Context)
              : (modules.project.public.getSimplifiedResourceSet()[
                  payload.scope.id
                ] as Project);

          return (resource?.custom_fields || [])
            .filter((field) => {
              if (field.parent_type !== payload.parent.type) {
                return false;
              }

              if (payload.parent.type === 'object_occurrence_relation') {
                if (field.no_relations) {
                  return payload.parent.no_relations;
                }
                if (field.unknown_relations) {
                  return payload.parent.unknown_relations;
                }
                return (
                  !payload.parent.no_relations &&
                  !payload.parent.unknown_relations
                );
              }
              return true;
            })
            .sort((a, b) => (a.created_at > b.created_at ? 1 : -1));
        },
    };

    const actions = {
      async createCustomField(
        context,
        payload: {
          name: string;
          parent: CustomFieldParent;
          position: number;
          scope: { id: string; type: ResourceType };
        }
      ) {
        const { type, ...relationAtributes } = payload.parent;
        const { id: customFieldId } =
          await components.$crud.public.dispatchCreateResource({
            resource: {
              name: payload.name,
              position: payload.position,
              parent_type: type,
              ...relationAtributes,
            },
            relationships: {
              scope: { id: payload.scope.id, type: payload.scope.type },
            },
          });
        dispatch(actions.addCustomFieldToContextRelationship)({
          scope: payload.scope,
          customFieldId,
        });
      },
      async updateCustomField(
        context,
        payload: { customFieldId: string; name?: string; position?: number }
      ) {
        return components.$crud.public.dispatchUpdateResource({
          resourceId: payload.customFieldId,
          resource: {
            name: payload?.name || undefined,
            position: payload?.position || undefined,
          },
        });
      },
      async deleteCustomField(
        context,
        payload: {
          scope: { type: ResourceType; id: string };
          customFieldId: string;
        }
      ) {
        await components.$crud.public.dispatchDeleteResource({
          resourceId: payload.customFieldId,
        });
        dispatch(actions.removeCustomFieldFromContextRelationship)(payload);
      },
      addCustomFieldToContextRelationship(
        context,
        payload: {
          scope: { type: ResourceType; id: string };
          customFieldId: string;
        }
      ) {
        const $jv = components.$jv.protected;
        $jv.commitAlterRelationship({
          id: payload.scope.id,
          type: payload.scope.type,
          relationshipKey: contextCustomFieldsRelationshipKey,
          relationship: (currentRelationship) => ({
            ...currentRelationship,
            data: [
              ...((currentRelationship.data as JsonApiIdentification[]) || []),
              { id: payload.customFieldId, type: resourceProfile.type },
            ],
          }),
        });
      },
      removeCustomFieldFromContextRelationship(
        context,
        payload: {
          scope: { type: ResourceType; id: string };
          customFieldId: string;
        }
      ) {
        const $jv = components.$jv.protected;
        $jv.commitAlterRelationship({
          id: payload.scope.id,
          type: payload.scope.type,
          relationshipKey: contextCustomFieldsRelationshipKey,
          relationship: (currentRelationship) => {
            const jsonapiIdList =
              (currentRelationship.data as JsonApiIdentification[]) || [];
            return {
              ...currentRelationship,
              data: jsonapiIdList.filter(
                (identification) => identification.id !== payload.customFieldId
              ),
            };
          },
        });
      },
    };

    const { read, dispatch } = getAccessors();
    return {
      module: { getters, actions },
      protected: {},
      public: {
        dispatchCreateCustomField: dispatch(actions.createCustomField),
        dispatchUpdateCustomField: dispatch(actions.updateCustomField),
        dispatchDeleteCustomField: dispatch(actions.deleteCustomField),
        getCustomField: (customFieldId) =>
          components.$crud.public.getSimplifiedResourceSet()[
            customFieldId
          ] as CustomField,
        getCustomFields: read(getters.customFields),
      },
    };
  },
});
