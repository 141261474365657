import { SyntaxElement } from './syntaxElement';
import { Ownership } from './ownership';
import { Context } from './context';
import { ProgressStepChecked } from './progressStepChecked';

export interface OOCPayloadAttributes {
  name: string;
  number: number | string | null;
  // "...sorting position within the OOCs siblings"
  // "It should probably default to 1"
  position: number;
  // Type will always be 'regular' for CORE operations
  object_occurrence_type: OOCType;
  // should not be sent to API unless explicitly selected
  hex_color?: string;
}

interface OOCPayloadInfo {
  // id of node or temp id
  id: string;
  parentId: string;
  prefix: string;
  classification_code: string;
  classification_entry_id?: string | null; // undefined if not changed, null if unclassified
  syntax_element_name?: string;
  syntax_element_id?: string; // undefined if not changed
}

export type OOCEditorPayload = OOCPayloadAttributes & OOCPayloadInfo;

export interface OOCEditorPatch {
  id: string;
  name?: string;
  number?: number | null;
  classification_entry_id?: string | null;
  syntax_element_id?: string;
}

export enum OOCType {
  External = 'external',
  Regular = 'regular',
}

type DeltaInfo = {
  changes_type: 'addition' | 'deletion' | 'change' | null;
};

export type OocSyntaxChangesNeeded = 'code' | 'definition';

export interface OOC {
  id: string;
  classification_code: string;
  name: string;
  position: number | null;
  prefix: string;
  reference_designation: string;
  object_occurrence_type: OOCType;
  hex_color: string | null;
  number: number | null;
  validation_errors: Array<string | number>;
  ownerships?: Ownership[];
  allowed_children_syntax_elements?: SyntaxElement[];
  syntax_element?: SyntaxElement;
  context?: Context;
  progress_step_checked?: Array<ProgressStepChecked>;
  delta?: DeltaInfo;
  changes_needed?: OocSyntaxChangesNeeded;
  component_changes_needed?: OocSyntaxChangesNeeded[];
  components?: OOC[];
  updated_at?: string;
}
