<template>
  <VirtualList
    class="treeItems"
    ref="treeWrapper"
    :items="flatVisibleTree"
    :item-height="nodeItemHeight"
    :get-key="(row) => row && row.item.id"
  >
    <template #item="{ row: { item, unfinishedLevels } }">
      <div
        class="row-wrapper"
        :ref="item.id"
        :key="item.id"
        :style="listRowStyle"
      >
        <div
          v-if="item.parentId !== null"
          class="flat-indentation"
          :style="{ width: '30px' }"
        />
        <div
          v-for="(it, index) in unfinishedLevels.slice(0, -1)"
          :key="index"
          class="flat-indentation"
          :style="{
            width: '80px',
            'border-left': `solid 1px ${it ? '#999999' : 'transparent'}`,
          }"
        />
        <div data-spec="expander-container" class="expander-container">
          <div
            v-if="item.parentId !== null && item.expandable"
            :class="[
              { collapsed: !item.expanded },
              treeLoadingSubRoots.has(item.id)
                ? 'expand-spinner'
                : 'expand-icon',
            ]"
            @click.stop="toggleExpanded(item)"
            :data-spec="!item.expanded ? 'collapse-icon' : 'expand-icon'"
          >
            <v-progress-circular
              v-if="treeLoadingSubRoots.has(item.id)"
              indeterminate
              size="13"
              width="2"
              color="grey"
            />
          </div>
          <TreeItem
            :item="item"
            :readonly="readonly"
            :processingStatus="getProcessingStatus(item.id)"
            :lockStructure="lockStructure"
            :editorIsActive="editorIsActive"
            :selectedNodeId="selectedNodeId"
            :isLoading="
              (treeLoadingSubRoots.size > 0 || !itemsLoaded) &&
              item.parentId === null
            "
            @dragStart="dragStart(item.id)"
            @dragEnd="dragEnd"
            @dropNode="dropNode"
            @remove="removeNode(item.id)"
            @select="selectNode(item.id)"
            @edit="editNode(item.id)"
            @cancelEdit="cancelEditNode(item.id)"
            @createNode="createNode"
            @saveNode="saveNode"
            @copy="copyNode(item.id)"
            @cut="cutNode(item.id)"
            @paste="pasteNode(item.id)"
            @duplicate="duplicateNode(item.id)"
            @expand="expandNode(item.id)"
            @collapse="collapseNode(item.id)"
          >
            <template #name="scope">
              <slot name="name" v-bind="scope" />
            </template>
            <template #append="scope">
              <slot name="append" v-bind="scope" />
            </template>
          </TreeItem>
        </div>
      </div>
    </template>
  </VirtualList>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import TreeItem, {
  itemHeight,
} from '@/components/setup/syntax/treeStructure/newTreeItem.vue';
import VirtualList from '@/components/virtualList.vue';
import { treeCursor } from '@/services/treeCursor';
import { eventBus } from '@/services/eventBus/eventBus';
import { keypressHandler } from '@/services/keypressHandler';
import {
  StructuredTreeNode,
  TreeContextLegacy,
  FlatTreeNode,
} from '@/models/treeStructure';
import { parentChain, TreeIdentification } from '@/utils/tree';
import { ProcessingStatus } from '@/store/components/processingTracker';

@Options({
  name: 'TreeStructure',
  components: {
    TreeItem,
    VirtualList,
  },
  emits: [
    'search',
    'selectNode',
    'dropNode',
    'dragStart',
    'dragEnd',
    'removeNode',
    'cutNode',
    'copyNode',
    'duplicateNode',
    'pasteNode',
    'createNode',
    'saveNode',
    'editNode',
    'cancelEditNode',
  ],
})
export default class TreeStructure extends Vue {
  @Prop({ required: true }) tree: TreeIdentification;
  @Prop(Boolean) disableShortcuts: boolean;
  @Prop(Boolean) lockStructure: boolean;
  @Prop(Boolean) readonly: boolean;
  @Prop({ default: () => true }) itemsLoaded: boolean;
  @Prop(Boolean) editorIsActive: boolean;
  @Prop() rootNode!: StructuredTreeNode;

  @Inject({ from: 'treeContext' }) context: TreeContextLegacy;

  get nodeItemHeight() {
    return itemHeight;
  }

  get listRowStyle() {
    return {
      '--v-nodeHeight': `${this.nodeItemHeight}px`,
      '--v-halfNodeHeight': `${this.nodeItemHeight / 2}px`,
      '--v-halfNodeHeightNegative': `-${this.nodeItemHeight / 2}px`,
    };
  }

  get flatVisibleTree(): readonly FlatTreeNode[] {
    const flattenSubtree = (flatNode: FlatTreeNode): FlatTreeNode[] => {
      if (this.getProcessingStatus(flatNode.item.id) === 'delete') {
        flatNode = {
          ...flatNode,
          item: {
            ...flatNode.item,
            expandable: false,
            expanded: false,
            children: [],
          },
        };
      }
      const children = flatNode.item.children;
      const visibleChildren = flatNode.item.expanded ? children : [];
      return [
        flatNode,
        ...visibleChildren.flatMap((item, index) =>
          flattenSubtree({
            item,
            unfinishedLevels: [
              ...flatNode.unfinishedLevels,
              index < children.length - 1,
            ],
          })
        ),
      ];
    };
    return Object.freeze(
      flattenSubtree({
        item: this.rootNode,
        unfinishedLevels: [],
      })
    );
  }

  get selectedNodeId() {
    return this.context.module.getSelectedNodeId(this.tree);
  }

  get treeLoadingSubRoots(): Set<string> {
    return this.context.module.getTreeLoadingSubRoots(this.tree);
  }

  getProcessingStatus(id: string): ProcessingStatus | undefined {
    return this.context.module.getItemProcessingStatus(id);
  }

  get inEditMode() {
    return !!this.context.edited;
  }

  created() {
    eventBus.$on('keydown', this.onKeyDown);
    eventBus.$on('shortcut-used', this.onShortcutUsed);
  }

  mounted() {
    this.selectNode(this.rootNode.id);
  }

  beforeUnmount() {
    eventBus.$off('keydown', this.onKeyDown);
    eventBus.$off('shortcut-used', this.onShortcutUsed);
  }

  onShortcutUsed(e) {
    if (!this.disableShortcuts && !this.inEditMode) {
      keypressHandler(e, true, false, (e) => this.shortcuts(e));
    }
  }

  async onKeyDown(e: KeyboardEvent) {
    if (this.disableShortcuts || this.inEditMode) return;
    if (e.key === 'Enter') {
      if (
        this.rootNode.id !== this.selectedNodeId &&
        !['input', 'textarea', 'button', 'select'].includes(
          document.activeElement.tagName.toLowerCase()
        )
      ) {
        e.preventDefault();
        e.stopPropagation();
        if (!this.inEditMode) this.editNode(this.selectedNodeId);
        return;
      }
    }
    if (e.key === 'ArrowRight') await this.expandNode(this.selectedNodeId);
    treeCursor(e, this.selectedNodeId, this.flatVisibleTree, this.selectNode);
  }

  @Watch('selectedNodeId')
  handleSelectionChange() {
    this.scrollToNode(this.selectedNodeId);
  }

  // Handle case when there is no selected node
  @Watch('selectedNodeContext')
  onSelectedNodeContextChange(
    newValue: TreeStructure['selectedNodeContext'],
    oldValue: TreeStructure['selectedNodeContext']
  ) {
    if (newValue !== null) return;
    if (oldValue) {
      const previousSiblingId = this.flatVisibleTree
        .filter(
          (node) =>
            node.item.parentId === oldValue.parentId &&
            node.item.position <= oldValue.position
        )
        .reduce(
          (max, current) =>
            max.position > current.item.position
              ? max
              : { position: current.item.position, id: current.item.id },
          { position: -Infinity, id: null }
        ).id;
      const nodesIdsByPriority = [
        previousSiblingId,
        ...oldValue.parentChain,
      ].filter(Boolean);
      const visibleNodeId = nodesIdsByPriority.find(this.isNodeVisible);
      if (visibleNodeId) {
        this.selectNode(visibleNodeId);
        return;
      }
    }
    this.selectNode(this.rootNode.id);
  }

  async scrollToNode(id: string) {
    await this.context.module.dispatchTreeExpandPathToNode({
      tree: this.tree,
      id,
    });
    this.scrollNodeIntoView(id);
  }

  shortcuts(e) {
    // TODO
    switch (e.keys) {
      case `KeyC-ControlLeft`:
        this.copyNode(this.selectedNodeId);
        break;
      case `KeyV-ControlLeft`:
        this.pasteNode(this.selectedNodeId);
        break;
      case `KeyD-ControlLeft`:
        this.removeNode(this.selectedNodeId);
        break;
      case `KeyF-ControlLeft`:
        this.$emit('search');
        break;
    }
  }

  public isNodeExpandable(nodeId: string): boolean {
    return !!this.flatVisibleTree.find((node) => node.item.id === nodeId)?.item
      ?.expandable;
  }

  toggleExpanded(node: StructuredTreeNode) {
    if (!node.expandable) return Promise.resolve();
    return node.expanded
      ? this.collapseNode(node.id)
      : this.expandNode(node.id);
  }

  expandNode(id: string) {
    return this.context.module
      .dispatchTreeExpandNode({ tree: this.tree, id })
      .catch((error) => console.error(error));
  }

  collapseNode(id: string) {
    return this.context.module.dispatchTreeCollapseNode({
      tree: this.tree,
      id,
    });
  }

  scrollNodeIntoView(id: string) {
    const node = this.$refs[id] as Element;
    const wrapper = (this.$refs.treeWrapper as Vue).$el as Element;
    const nodeBottom = node ? node.getBoundingClientRect().bottom : 0;
    const wrapperBottom = wrapper ? wrapper.getBoundingClientRect().bottom : 0;
    const isEditorInViewport =
      this.editorIsActive &&
      node &&
      wrapper &&
      nodeBottom > wrapperBottom - 150;
    const isNodeInViewport = node && nodeBottom > wrapperBottom - 150;
    if (isEditorInViewport || isNodeInViewport) {
      wrapper.scrollTo({
        top: wrapper.scrollTop - wrapperBottom + nodeBottom + 100,
        behavior: 'smooth',
      });
    } else if (node)
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
  }

  isNodeVisible(id: string) {
    return this.flatVisibleTree.some((node) => node.item.id === id);
  }

  get selectedNodeContext() {
    if (!this.isNodeVisible(this.selectedNodeId)) return null;
    const node = this.flatVisibleTree.find(
      (node) => node.item.id === this.selectedNodeId
    );
    return {
      parentChain: parentChain(
        this.flatVisibleTree.map((node) => node.item),
        this.selectedNodeId
      ),
      parentId: node.item.parentId,
      position: node.item.position,
    };
  }

  selectNode(id) {
    this.$emit('selectNode', id);
  }

  dropNode(node) {
    this.$emit('dropNode', node);
    this.expandNode(node.parentId);
  }

  dragStart(nodeId) {
    this.$emit('dragStart', nodeId);
  }

  dragEnd() {
    this.$emit('dragEnd');
  }

  removeNode(id) {
    this.$emit('removeNode', id);
  }

  cutNode(id) {
    this.$emit('cutNode', id);
  }

  copyNode(id) {
    this.$emit('copyNode', id);
  }

  duplicateNode(id) {
    if (this.lockStructure) return;
    this.$emit('duplicateNode', id);
  }

  pasteNode(id) {
    if (this.lockStructure) return;
    this.$emit('pasteNode', id);
  }

  createNode(node) {
    if (this.lockStructure) return;
    this.$emit('createNode', node);
    this.expandNode(node.parentId);
  }

  saveNode(node) {
    this.$emit('saveNode', node);
  }

  editNode(id: string) {
    if (!this.getProcessingStatus(id)) {
      this.$emit('editNode', id);
    }
  }

  cancelEditNode(id) {
    this.$emit('cancelEditNode', id);
  }
}
</script>

<style lang="scss" scoped>
$expanderSize: 24px;
$horizontalLineWidth: 68px;
$nodeExpanderMargin: 56px;
$line-color: rgb(var(--v-theme-line));
$border-line: 1px solid $line-color;
$expander-color: rgb(var(--v-theme-primary));

.treeItems {
  padding: $space-regular;
  width: 100%;
  overflow-x: auto;
}

.row-wrapper {
  display: flex;

  .indentation {
    display: flex;
    width: 80px;
    z-index: 0;
    &--left {
      width: 100%;
      margin-top: -45px;
      height: calc(100% + 45px);
      border-left: $border-line;
      z-index: 0;
    }
    &--small {
      width: 30px;
    }
  }
}

.flat-indentation {
  height: var(--v-nodeHeight);
  transform: translateY(-50%);
  flex-shrink: 0;
}

.expander-container {
  position: relative;
  .expand-icon,
  .expand-spinner {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: white;
    border: 1px black solid;
    border-radius: 50%;
    height: 21px;
    width: 21px;
    transform: translate(-50%, -50%);
    z-index: 7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .expand-icon {
    &::after {
      content: '';
      position: absolute;
      top: 9px;
      left: 3px;
      width: 13px;
      height: 1px;
      background-color: $expander-color;
      z-index: 8;
    }
  }
  .expand-spinner {
    &::before {
      display: none;
    }
  }
  .collapsed {
    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 9px;
      width: 1px;
      height: 13px;
      background-color: $expander-color;
      z-index: 8;
    }
  }
}
</style>
