import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { ComponentType } from './_types';
import {
  buildIncludeParam,
  buildParametersPath,
  buildRequestPath,
  PathParameters,
} from '@/utils/path';
import {
  buildRelationshipsJVData,
  BuildRelationshipsJVDataPayload,
  entitySimplifier,
  extractRelatedEntityId,
} from '@/utils/jvTools';
import { JsonApiIdentification } from '@/models/api';
import { pick } from '@/utils/tools';
import jv from './jv';
import { ResourceProfile } from '@/services/resources';

export type CrudAccessors = ModuleComponentPublicAccessors<typeof crud>;
type LoadSingleResourcePayload = (
  | Partial<ResourceIdentification>
  | { basePath: string }
) &
  PathParameters;
export interface ResourceIdentification {
  resourceId: string;
}

const crud = createModuleComponent({
  type: ComponentType.Crud,
  dependencies: [jv],
  setup: ({ getAccessors, resourceProfile, components }) => {
    const resourceSimplifier = entitySimplifier(resourceProfile);

    const state = () => ({});

    const mutations = {};

    const getters = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      simplifiedResourceSet: <T extends { id: string } = any>() => {
        return new Proxy(
          {},
          {
            get(_, id: string) {
              const record = components.$jv.protected.get({
                id,
                type: resourceProfile.type,
              });
              return record && resourceSimplifier(record);
            },
            ownKeys() {
              const recordSet = components.$jv.protected.get(
                resourceProfile.type
              );
              return Object.keys(recordSet);
            },
            getOwnPropertyDescriptor(_) {
              return {
                enumerable: true,
                configurable: true,
              };
            },
          }
        ) as Record<string, T>;
      },
      resourceMetaProperty: () => (id: string, metaKey: string) => {
        const resource = components.$jv.protected.get({
          id,
          type: resourceProfile.type,
        });
        return resource?._jv?.meta?.[metaKey];
      },
      relationshipMetaProperty:
        () => (id: string, relationshipKey: string, metaKey: string) => {
          const resource = components.$jv.protected.get({
            id,
            type: resourceProfile.type,
          });
          return resource?._jv?.relationships?.[relationshipKey]?.meta?.[
            metaKey
          ];
        },
      relationshipData: () => (id: string, relationshipKey: string) => {
        const jvResource = components.$jv.protected.get({
          id,
          type: resourceProfile.type,
        });
        const relationshipData =
          jvResource?._jv?.relationships?.[relationshipKey]?.data;
        const relationshipProfile = resourceProfile.relationships[
          relationshipKey
        ] as ResourceProfile | ResourceProfile[];
        const relationshipMany = Array.isArray(relationshipProfile)
          ? relationshipProfile.some((rel) => rel.relationshipMany)
          : relationshipProfile.relationshipMany;
        return relationshipMany
          ? (relationshipData as JsonApiIdentification[]) || []
          : (relationshipData as JsonApiIdentification) || null;
      },
      relationshipId: () => (id: string, relationshipKey: string) => {
        const relData = read(getters.relationshipData)(id, relationshipKey);
        return Array.isArray(relData)
          ? relData.map((data) => data?.id)
          : relData?.id;
      },
      relationshipIdFromLinkage:
        () => (id: string, relationshipKey: string) => {
          return extractRelatedEntityId(
            relationshipKey,
            components.$jv.protected.get({ id, type: resourceProfile.type })
          );
        },
    };

    const actions = {
      loadSingleResource(context, payload: LoadSingleResourcePayload) {
        const parametersPath = buildParametersPath({
          ...payload,
          include: buildIncludeParam(resourceProfile, payload.include),
        });
        const basePath =
          'basePath' in payload
            ? payload.basePath
            : `${resourceProfile.path}${
                payload.resourceId ? `/${payload.resourceId}` : ''
              }`;
        const url = `${basePath}${parametersPath}`;
        return components.$jv.protected.dispatchGet(url);
      },
      async createResource(
        context,
        payload: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resource: Record<string, any>;
          relationshipKey?: string;
          relationshipId?: string;
          include?: string[];
          basePath?: string;
        } & Partial<BuildRelationshipsJVDataPayload<typeof resourceProfile>>
      ) {
        let relationships;
        const payloadRelationships = payload.relationships;
        if (payloadRelationships && resourceProfile.relationships) {
          relationships = buildRelationshipsJVData(resourceProfile, {
            ...payload,
            relationships: payloadRelationships,
          });
        }
        const newResource = {
          _jv: {
            type: resourceProfile.type,
            relationships,
          },
          ...payload.resource,
        };
        const parametersPath = buildParametersPath({
          include: buildIncludeParam(resourceProfile, payload.include),
        });
        const basePath =
          payload.basePath || `/${buildRequestPath(resourceProfile, payload)}`;
        const url = `${basePath}${parametersPath}`;

        const data = await components.$jv.protected.dispatchPost([
          newResource,
          { url },
        ]);
        return { id: data._jv.id || data._jv.json?.data?.id };
      },
      async updateResource(
        context,
        payload: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          resource: Record<string, any>;
          include?: string[];
        } & ResourceIdentification &
          Partial<BuildRelationshipsJVDataPayload<typeof resourceProfile>>
      ) {
        let relationships;
        const payloadRelationships = payload.relationships;
        if (payloadRelationships && resourceProfile.relationships) {
          relationships = buildRelationshipsJVData(resourceProfile, {
            ...payload,
            relationships: payloadRelationships,
          });
        }
        const attributes = pick(payload.resource, resourceProfile.attributes);
        const updatedResource = {
          _jv: {
            id: payload.resourceId,
            type: resourceProfile.type,
            relationships,
          },
          ...attributes,
        };
        const parametersPath = buildParametersPath({
          include: buildIncludeParam(resourceProfile, payload.include),
        });
        const url = `/${resourceProfile.path}/${payload.resourceId}${parametersPath}`;
        await components.$jv.protected.dispatchPatch([
          updatedResource,
          { url },
        ]);
      },
      async deleteResource(context, payload: ResourceIdentification) {
        await components.$jv.protected.dispatchDelete([
          { _jv: { type: resourceProfile.type, id: payload.resourceId } },
          { url: `/${resourceProfile.path}/${payload.resourceId}` },
        ]);
      },
    };

    const { read, dispatch } = getAccessors();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        getSimplifiedResourceSet: read(getters.simplifiedResourceSet),
        getResourceMetaProperty: read(getters.resourceMetaProperty),
        getRelationshipMetaProperty: read(getters.relationshipMetaProperty),
        getRelationshipData: read(getters.relationshipData),
        getRelationshipId: read(getters.relationshipId),
        getRelationshipIdFromLinkage: read(getters.relationshipIdFromLinkage),

        dispatchLoadSingleResource: dispatch(actions.loadSingleResource),
        dispatchCreateResource: dispatch(actions.createResource),
        dispatchUpdateResource: dispatch(actions.updateResource),
        dispatchDeleteResource: dispatch(actions.deleteResource),
      },
    };
  },
});

export default crud;
