import { createModule } from '../utils/factories';

export interface AppNotification {
  message: string;
  type?: 'info' | 'success' | 'error';
  timeout?: number;
  action?: {
    label: string;
    callback: () => void;
  };
}

interface State {
  notifications: AppNotification[];
}

export default createModule({
  path: 'notifications',
  setup({ getAccessors }) {
    const state = (): State => ({
      notifications: [],
    });

    const getters = {
      activeNotification: (state: State) =>
        state.notifications.length ? state.notifications[0] : null,
    };

    const mutations = {
      addNotification(state: State, notification: AppNotification) {
        state.notifications.push(notification);
      },
      shiftNotifications(state: State) {
        state.notifications.shift();
      },
    };

    const actions = {
      notify(_, notification: AppNotification) {
        commit(mutations.addNotification)(notification);
      },
      closeActiveNotification(_) {
        commit(mutations.shiftNotifications)();
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        getActiveNotification: read(getters.activeNotification),
        dispatchNotify: dispatch(actions.notify),
        dispatchCloseActiveNotification: dispatch(
          actions.closeActiveNotification
        ),
      },
    };
  },
});
