<template>
  <ProgressWidgetField
    :selectedProgressStepId="selectedProgressStep"
    :progressSteps="progressSteps"
    :readonly="readonly"
    :inProgress="pendingUpdate"
    @update:selectedProgressStepId="onInputUpdate"
  />
</template>

<script lang="ts">
import { Options, Vue, Prop } from 'vue-property-decorator';
import { ProgressStepChecked } from '@/models/progressStepChecked';
import { composeMessage } from '@/services/errorHandler';
import ProgressWidgetField from './progressWidgetField.vue';

@Options({
  components: {
    ProgressWidgetField,
  },
  emits: ['progress-updated'],
})
export default class ProgressWidget extends Vue {
  @Prop({ required: true }) targetId!: string;
  @Prop({ required: true }) targetType!: string;
  @Prop(Boolean) readonly: boolean;

  pendingSelectedStepId: string | null = null;
  pendingProgressReset = false;

  get pendingUpdate() {
    return this.pendingProgressReset || !!this.pendingSelectedStepId;
  }

  get progressSteps() {
    return this.$store.$direct.progress.getProgressSteps(this.targetType);
  }

  get selectedProgressStep(): string {
    return this.pendingSelectedStepId
      ? this.pendingSelectedStepId
      : this.progressStepsChecked[0]?.progress_step?.id;
  }

  get progressStepsChecked(): ProgressStepChecked[] {
    return this.$store.$direct.progress.getProgressStepsChecked(
      this.targetType,
      this.targetId
    );
  }

  onInputUpdate(progressStepId: string | null) {
    if (progressStepId) return this.updateProgress(progressStepId);
    this.resetProgress();
  }

  async updateProgress(progressStepId: string) {
    if (this.pendingUpdate || this.readonly || !progressStepId) return;
    this.pendingSelectedStepId = progressStepId;
    try {
      await this.$store.$direct.progress.dispatchUpdateProgress({
        target: { id: this.targetId, type: this.targetType },
        progressStepsCheckedIds: this.progressStepsChecked
          .map((stepChecked) => stepChecked?.id)
          .filter(Boolean),
        progressStepId,
      });
      this.$emit('progress-updated');
    } catch (err) {
      this.$store.$direct.notifications.dispatchNotify({
        type: 'error',
        message: composeMessage(err),
      });
      console.error(err);
    }
    this.pendingSelectedStepId = null;
  }

  async resetProgress() {
    if (this.pendingUpdate || this.readonly || !this.selectedProgressStep)
      return;
    this.pendingProgressReset = true;
    try {
      await this.$store.$direct.progress.dispatchResetProgress({
        progressStepId: this.progressStepsChecked[0].id,
        target: { id: this.targetId, type: this.targetType },
      });
      this.$emit('progress-updated');
    } catch (err) {
      this.$store.$direct.notifications.dispatchNotify({
        type: 'error',
        message: composeMessage(err),
      });
    }
    this.pendingProgressReset = false;
  }
}
</script>
