<template>
  <v-file-input
    :model-value="modelValue"
    @update:model-value="($event) => $emit('update:modelValue', $event)"
    :disabled="disabled || loading"
    variant="outlined"
    :loading="loading"
    :accept="accept"
  />
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  inheritAttrs: true,
  emits: ['update:modelValue'],
})
export default class ImportUploadField extends Vue {
  @Prop({ default: '.xml,.xlsx,application/vnd.ms-excel,text/csv,.csv' })
  accept: string;
  @Prop() modelValue: File[];
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) loading: boolean;
}
</script>
