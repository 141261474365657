<template>
  <div class="base-autocomplete">
    <v-menu
      v-bind="menuPropsWithDefaults"
      :modelValue="isMenuVisible"
      :close-on-content-click="false"
      :open-on-click="false"
      :attach="computedAttach"
    >
      <template #activator="{ props: menuAttrs }">
        <div v-bind="menuAttrs">
          <v-text-field
            v-bind="inputAttrs"
            v-model="inputModel"
            ref="textField"
            :id="inputId"
            :class="[
              'base-autocomplete__input',
              {
                'base-autocomplete__text-field--icon-rotated':
                  !hideDropdownIcon && isMenuVisible,
              },
            ]"
            :loading="loadingValue || loadingItems"
            :append-inner-icon="hideDropdownIcon ? '' : '$dropdown'"
            :clearable="clearable"
            :placeholder="placeholder"
            :disabled="disabled"
            hide-details
            autocomplete="off"
            variant="underlined"
            density="compact"
            @click:clear="onClearClick"
            @click="onInputFocus"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @keydown="onInputKeyDown"
          />
        </div>
      </template>

      <div class="base-autocomplete__menu-content" data-spec="menu-content">
        <v-list v-if="visibleItems.length" class="pa-0" style="width: 100%">
          <template v-for="item in visibleItems">
            <slot
              name="item"
              :item="item as any"
              :attrs="listItemAttrs(item)"
              :on="listItemOn(item)"
            >
              <v-list-item
                v-if="allowDefaultSlots"
                v-bind="listItemAttrs(item)"
                v-on="listItemOn(item)"
              >
                <v-list-item-title>
                  <slot name="item-text" :item="item as any">
                    {{ itemText(item) }}
                  </slot>
                </v-list-item-title>
              </v-list-item>
            </slot>
          </template>
        </v-list>

        <template v-if="!visibleItems.length && !loadingItems && !hideNoData">
          <slot name="no-data">
            <div class="pa-4">No options available</div>
          </slot>
        </template>

        <template v-if="hasMoreItems || (!visibleItems.length && loadingItems)">
          <div
            ref="loadingMoreItemsSpinnerRef"
            class="pa-4 text-center"
            data-spec="loading-items-spinner"
          >
            <v-progress-circular
              color="rgb(var(--v-theme-color))"
              width="3"
              indeterminate
            />
          </div>
        </template>
      </div>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRef,
  useAttrs,
  watch,
} from 'vue';
import { watchDebounced, whenever } from '@vueuse/core';
import { useKeyboardNavigation } from './keyboardNavigation';
import { useSearchInput } from './searchInput';
import { useElementIntersectionVisibility } from '@/composables/elementIntersectionVisibility';
import type { VTextField } from 'vuetify/components';

defineOptions({ inheritAttrs: false });

const props = withDefaults(
  defineProps<{
    modelValue?: unknown;
    searchInput?: string;
    items?: unknown[];
    loadingItems?: boolean;
    loadingValue?: boolean;
    hasMoreItems?: boolean;
    itemValue?: (item: unknown) => string;
    itemText?: (item: unknown) => string;
    allowNoSelectedItem?: boolean; // do not add current selection to items if it's not there
    attach?: string | boolean | Element;
    autofocus?: boolean;
    hideNoData?: boolean;
    hideDropdownIcon?: boolean;
    clearable?: boolean;
    placeholder?: string;
    disabled?: boolean;
    openMenuOnItemClick?: boolean;
    sortBy?: string;
    menuProps?: {
      bottom?: boolean;
      location?: 'top' | 'bottom' | 'start' | 'end' | 'center';
      minWidth?: string | number;
      maxWidth?: string | number;
      width?: string | number;
      maxHeight?: string | number;
      absolute?: boolean;
      contentClass?: string;
    };
    allowDefaultSlots?: boolean;
    visibleItemsFilteringFn?: (param: never) => boolean;
  }>(),
  {
    modelValue: null,
    searchInput: '',
    openMenuOnItemClick: false,
    items: () => [],
    itemValue: (item) => item.id,
    itemText: (item) => item.name,
    attach: undefined,
    autofocus: false,
    hideNoData: false,
    hideDropdownIcon: false,
    clearable: false,
    placeholder: 'No value',
    disabled: false,
    sortBy: null,
    menuProps: () => ({}),
    allowDefaultSlots: true,
    visibleItemsFilteringFn: null,
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', item: unknown): void;
  (event: 'update:searchInput', query: string): void;
  (event: 'update:isMenuOpen', value: boolean): void;
  (event: 'update:isInputOverflown', value: boolean): void;
  (event: 'keydown', e: KeyboardEvent): void;
  (event: 'loadMore'): void;
  (event: 'selection', item: unknown): void;
}>();

const inputId = `bai-${Math.random().toString(16).slice(2)}`;

const isMenuOpen = ref(false);
const isMenuVisible = computed(
  () =>
    isMenuOpen.value &&
    !props.disabled &&
    (!props.hideNoData || visibleItems.value.length > 0)
);

watch(isMenuOpen, (value) => {
  emit('update:isMenuOpen', value);
});

const { inputModel, isSearchQueryChanged } = useSearchInput({
  selectedItem: toRef(props, 'modelValue'),
  itemValue: props.itemValue,
  itemText: props.itemText,
  isMenuOpen,
  initialValue: toRef(props, 'searchInput'),
});

watch(inputModel, (query) => {
  emit('update:searchInput', query);
});

const computedAttach = computed(() => {
  if (props.attach === '') {
    return true;
  }
  return props.attach || undefined;
});

const onInputFocus = () => {
  isMenuOpen.value = true;
};
const onInputBlur = async () => {
  isMenuOpen.value = false;
};
const onClearClick = () => {
  if (!props.clearable) return;
  submit(null);
  isMenuOpen.value = false;
};

const isValueInItems = (items: unknown[], value: unknown) =>
  !value ||
  items.some((item) => props.itemValue(item) === props.itemValue(value));
const isFirstOccurrence = (item: unknown, index: number, all: unknown[]) => {
  const itemValue = props.itemValue(item);
  return all.findIndex((i) => props.itemValue(i) === itemValue) === index;
};
const visibleItems = computed(() => {
  const items = props.items.slice();
  if (
    props.modelValue &&
    !props.allowNoSelectedItem &&
    !isSearchQueryChanged.value &&
    !isValueInItems(props.items, props.modelValue)
  ) {
    items.unshift(props.modelValue);
  }

  let result = items.filter(isFirstOccurrence);

  if (props.visibleItemsFilteringFn) {
    result = items.filter(props.visibleItemsFilteringFn);
  }
  if (props.sortBy) {
    result.sort(sortVisibleItems);
  }
  return result;
});

const sortVisibleItems = (itemA: any, itemB: any): number => {
  const valueA = itemA[props.sortBy];
  const valueB = itemB[props.sortBy];

  if (!valueA || !valueB) return 0;
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0; // equal names
};

const loadingMoreItemsSpinnerRef = ref<HTMLElement>();
const isVisibleLoadingMoreItemsSpinner = useElementIntersectionVisibility(
  loadingMoreItemsSpinnerRef
);
const shouldLoadMoreItems = computed(() => {
  if (
    !props.hasMoreItems ||
    props.disabled ||
    props.loadingItems ||
    !isMenuOpen.value
  ) {
    return false;
  }
  const shouldLoadFirstPage = visibleItems.value.length === 0;
  const shouldLoadNextPage = isVisibleLoadingMoreItemsSpinner.value;

  return shouldLoadFirstPage || shouldLoadNextPage;
});
whenever(shouldLoadMoreItems, () => emit('loadMore'));

const textField = ref<VTextField>();
const getInput = () => document.getElementById(inputId) as HTMLInputElement;
const focusInput = () => {
  getInput()?.focus();
};
const selectInput = async () => {
  await nextTick();
  getInput()?.select();
};
onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => focusInput());
  }
});
watch(
  isMenuOpen,
  (isOpen) => {
    if (!isOpen || props.searchInput) return;
    selectInput();
  },
  { immediate: true }
);

const listItemId = (itemValue: string) =>
  `${inputId}-${btoa(itemValue).replace(/=/g, '0')}`;
const listItemAttrs = (item: unknown) => {
  const itemValue = props.itemValue(item);
  const isActive =
    !!props.modelValue && props.itemValue(props.modelValue) === itemValue;
  const isHighlighted = itemValue === highlightedItemValue.value;
  const testAttrs =
    import.meta.env.MODE === 'test'
      ? {
          'data-spec-active': isActive || undefined,
          'data-spec-highlighted': isHighlighted || undefined,
        }
      : {};
  return {
    ...testAttrs,
    key: itemValue,
    active: isActive || isHighlighted,
    color: isActive ? 'primary' : undefined,
    id: listItemId(itemValue),
    link: true,
  };
};
const listItemOn = (item: unknown) => ({
  mousedown: (event) => {
    event.preventDefault();
    submit(item);
    isMenuOpen.value = false;
  },
});

const inputAttrs = useAttrs();

watchDebounced(
  inputModel,
  () => {
    const input = document.getElementById(inputId) as HTMLInputElement;
    const isInputOverflown = input
      ? input.offsetWidth < input.scrollWidth
      : false;
    emit('update:isInputOverflown', isInputOverflown);
  },
  {
    debounce: 1,
    maxWait: 100,
    immediate: true,
  }
);

const menuPropsWithDefaults = computed(() => ({
  maxHeight: 304,
  ...props.menuProps,
}));

const openMenu = async () => {
  if (!isMenuOpen.value) {
    focusInput();
    await nextTick();
    isMenuOpen.value = true;
  }
};

const hasChanged = (oldValue: unknown, newValue: unknown) => {
  if (!oldValue && !newValue) return false;
  if (!oldValue || !newValue) return true;
  return props.itemValue(oldValue) !== props.itemValue(newValue);
};

const submit = async (item: unknown | null) => {
  if (props.disabled) return;
  if (hasChanged(props.modelValue, item)) {
    emit('update:modelValue', item);
    openMenu();
  }
  emit('selection', item);

  selectInput();
  if (props.openMenuOnItemClick) {
    await nextTick();
    openMenu();
  }
};

const { highlightedItemValue, keyDownHandler } = useKeyboardNavigation({
  items: visibleItems,
  itemValue: props.itemValue,
  defaultHighlightedItem: computed(() =>
    !isSearchQueryChanged.value ? props.modelValue : null
  ),
  isMenuVisible: isMenuVisible,
  openMenu: () => {
    isMenuOpen.value = true;
  },
  closeMenu: () => {
    isMenuOpen.value = false;
  },
  scrollToItem: (itemValue, options) => {
    document.getElementById(listItemId(itemValue))?.scrollIntoView?.(options);
  },
  submit,
});

const onInputKeyDown = (event: KeyboardEvent) => {
  keyDownHandler(event);
  if (event.defaultPrevented) {
    event.stopPropagation();
    return;
  }
  emit('keydown', event);
};

defineExpose({
  openMenu,
});
</script>

<style lang="scss">
.base-autocomplete {
  width: 100%;
}
.base-autocomplete__input > .v-input__control {
  width: 100%;
}
.base-autocomplete__menu-content {
  background-color: rgb(var(--v-theme-cardBackground));
  overflow-y: auto;
}
.base-autocomplete__text-field--icon-rotated {
  .v-input__icon--append .v-icon {
    transform: rotate(180deg);
  }
}
</style>
