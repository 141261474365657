<template>
  <FocusTrap active :initialFocus="() => formRef.queryFocusableNodes()[0]">
    <div class="project-duplicate-form">
      <GenericForm
        ref="form"
        :formConfig="formConfig"
        submitLabel="Duplicate"
        @cancel="onCancel"
        @created="onCreated"
      />
    </div>
  </FocusTrap>
</template>

<script lang="ts">
import { Vue, Prop, Options, Ref } from 'vue-property-decorator';
import GenericForm from '../genericForm.vue';
import { FocusTrap } from 'focus-trap-vue';
import {
  FormFieldDefinition,
  FormFieldType,
  FormConfig,
} from '@/services/forms';
import { projectNameRules } from '@/utils/validationRules';
import { Project } from '@/models/project';

@Options({
  components: {
    FocusTrap,
    GenericForm,
  },
  emits: ['created', 'cancel'],
})
export default class ProjectProjectDuplicateFrom extends Vue {
  @Prop({ required: true }) projectId: string;
  @Prop({ required: true }) projectNamesList: string[];
  @Ref('form') readonly formRef: GenericForm;

  get formConfig(): FormConfig {
    const schema: FormFieldDefinition[] = [
      {
        attribute: 'name',
        label: 'Name of new project',
        type: FormFieldType.Text,
        rules: [
          ...projectNameRules,
          (value: string) => {
            return (
              !this.projectNamesList.includes(value.toLowerCase()) ||
              this.$t('apps.design.forms.uniqueProjectName')
            );
          },
        ],
        initialValue: `${this.project.name} - Copy`,
      },
    ];
    return {
      schema,
      createResource: (payload) => {
        return this.$store.$direct.project.dispatchDuplicateProject({
          projectId: this.projectId,
          name: `${payload.resource.name}`.trim(),
        });
      },
      updateResource: () => null,
    };
  }
  get project(): Project {
    return this.$store.$direct.project.getSimplifiedResourceSet()[
      this.projectId
    ];
  }
  onCancel() {
    this.$emit('cancel');
  }
  onCreated(projectId: string) {
    this.$emit('created', projectId);
  }
}
</script>

<style lang="scss" scoped>
.project-duplicate-form {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.project-duplicate-form__actions {
  position: relative;
  padding: 24px;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    margin: 0 auto;
    width: calc(100% - 48px);
    height: 0;
    border-bottom: 1px solid rgb(var(--v-theme-line));
  }
}
</style>
