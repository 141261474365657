import { createModuleComponent } from '../utils/factories';
import { ComponentType } from './_types';
import { ObjectOccurrenceRelation } from '@/models/objectOccurrenceRelation';
import { OORComparisonResult } from '@/models/revision';
import crud from './crud';

const sourceTargetIndex = (sourceId, targetId) => `${sourceId}:${targetId}`;
export const getSourceTargetIndexes = (
  sourceIds: string[],
  targetIds: string[]
) => {
  return sourceIds
    .flatMap((sourceId) =>
      targetIds.map((targetId) =>
        sourceId !== targetId ? sourceTargetIndex(sourceId, targetId) : null
      )
    )
    .filter(Boolean);
};

export interface ChainAnalysisMeta {
  steps: number[];
}

export interface ComparisonMeta {
  comparisonResult: OORComparisonResult;
}

type OorsMeta =
  | ChainAnalysisMeta
  | ComparisonMeta
  | (ChainAnalysisMeta & ComparisonMeta);

export type ChainAnalysisFragment = State['requestIndex'][string];

export type UpdateRequestIndexPayload =
  | { chainAnalysis: false; comparison: false }
  | {
      chainAnalysis: false;
      comparison: true;
      meta: Record<string, ComparisonMeta>;
    }
  | {
      chainAnalysis: true;
      comparison: false;
      meta: Record<string, ChainAnalysisMeta>;
    }
  | {
      chainAnalysis: true;
      comparison: true;
      meta: Record<string, ChainAnalysisMeta & ComparisonMeta>;
    };

interface State {
  requestIndex: Partial<{
    [indexId: string]: Partial<{
      [sourceTargetId: string]: Map<string, true | OorsMeta>;
    }>;
  }>;
}

const simoIndexManager = createModuleComponent({
  type: ComponentType.SimoIndexManager,
  dependencies: [crud],
  setup: ({ getAccessors, components }) => {
    const state = (): State => ({
      requestIndex: {},
    });

    const getters = {
      getIndex: (state: State) => (indexId: string) =>
        state.requestIndex[indexId],
      getBySourceAndTarget:
        (state: State) =>
        (indexId: string, sourceId: string, targetId: string) => {
          const oorIds = [
            ...(state.requestIndex[indexId]?.[
              sourceTargetIndex(sourceId, targetId)
            ]?.keys() || []),
          ];
          const oors = oorIds
            .map((id) => components.$crud.public.getSimplifiedResourceSet()[id])
            .filter(Boolean)
            .map((oor) => ({
              ...oor,
              comparisonResult: read(getters.getComparison)(
                indexId,
                sourceId,
                targetId
              )?.get(oor.id)?.comparisonResult,
            }));

          return oors as ObjectOccurrenceRelation[];
        },
      getMetaBySourceAndTarget:
        (state: State) =>
        (indexId: string, sourceId: string, targetId: string) => {
          return state.requestIndex[indexId]?.[
            sourceTargetIndex(sourceId, targetId)
          ];
        },
      getComparison:
        () => (indexId: string, sourceId: string, targetId: string) =>
          read(getters.getMetaBySourceAndTarget)(
            indexId,
            sourceId,
            targetId
          ) as Map<string, ComparisonMeta>,
      getChainAnalysisSteps:
        () => (indexId: string, sourceId: string, targetId: string) => {
          const metaMap = read(getters.getMetaBySourceAndTarget)(
            indexId,
            sourceId,
            targetId
          );
          const stepsSet = [...(metaMap?.values() || [])].reduce<Set<number>>(
            (acc, val) => {
              const meta = val as ChainAnalysisMeta;
              const steps = meta?.steps || [];
              steps.forEach((step) => acc.add(step));
              return acc;
            },
            new Set()
          );
          return stepsSet ? [...stepsSet] : [];
        },
      isLoadedBySourceAndTarget:
        (state: State) =>
        (indexId: string, sourceId: string, targetId: string) => {
          return !!state.requestIndex[indexId]?.[
            sourceTargetIndex(sourceId, targetId)
          ];
        },
    };

    const mutations = {
      prepareIndex(state: State, payload: { indexId: string }) {
        if (!state.requestIndex[payload.indexId]) {
          state.requestIndex[payload.indexId] = {};
        }
      },
      updateRequestIndex(
        state: State,
        payload: {
          indexId: string;
          sourceIds: string[];
          targetIds: string[];
          oors: ObjectOccurrenceRelation[];
        } & UpdateRequestIndexPayload
      ) {
        const { indexId } = payload;
        payload.sourceIds.forEach((sourceId) => {
          payload.targetIds.forEach((targetId) => {
            const stIndex = sourceTargetIndex(sourceId, targetId);
            state.requestIndex[indexId][stIndex] = new Map();
          });
        });
        payload.oors.forEach((oor) => {
          const sourceId = oor?.source && oor?.source.id;
          const targetId = oor?.target && oor?.target.id;
          if (sourceId && targetId) {
            const stIndex = sourceTargetIndex(sourceId, targetId);
            const meta =
              payload.chainAnalysis || payload.comparison
                ? payload.meta[oor.id]
                : true;
            state.requestIndex[indexId][stIndex]?.set(oor.id, meta);
          }
        });
      },
      injectToRequestIndex(
        state: State,
        payload: {
          indexId: string;
          oor: ObjectOccurrenceRelation;
          chainAnalysis: boolean;
        }
      ) {
        const { indexId, oor, chainAnalysis } = payload;
        const stIndex = sourceTargetIndex(oor.source.id, oor.target.id);
        if (!state.requestIndex[indexId][stIndex]) {
          state.requestIndex[indexId][stIndex] = new Map();
        }
        const meta = chainAnalysis ? { steps: [] } : true;
        state.requestIndex[indexId][stIndex].set(oor.id, meta);
        // RESET ALL INDEXES BUT CURRENT (invalidate cache)
        state.requestIndex = {
          [indexId]: state.requestIndex[indexId],
        };
      },
      removeFromRequestIndex(
        state: State,
        payload: { indexId: string; oor: ObjectOccurrenceRelation }
      ) {
        const { indexId, oor } = payload;
        const stIndex = sourceTargetIndex(oor.source.id, oor.target.id);
        if (state.requestIndex[indexId][stIndex]) {
          state.requestIndex[indexId][stIndex].delete(oor.id);
        }
      },
      invalidateIndexEntriesBySourceAndTarget(
        state: State,
        payload: { indexId: string; stIndexesToPreserve: string[] }
      ) {
        const { indexId, stIndexesToPreserve } = payload;
        commit(mutations.resetRequestIndex)({ indexId });
        const currentRequestIndex = state.requestIndex[indexId];
        stIndexesToPreserve.forEach(
          (stIndex) =>
            (state.requestIndex[indexId][stIndex] =
              currentRequestIndex[stIndex])
        );
      },
      resetRequestIndex(state: State, payload: { indexId: string }) {
        state.requestIndex[payload.indexId] = {};
      },
      resetRequestIndexes(state: State) {
        state.requestIndex = {};
      },
    };

    const { read, commit } = getAccessors();

    return {
      module: {
        state,
        getters,
        mutations,
      },
      protected: {
        getIndex: read(getters.getIndex),
        getBySourceAndTarget: read(getters.getBySourceAndTarget),
        getMetaBySourceAndTarget: read(getters.getMetaBySourceAndTarget),
        getChainAnalysisSteps: read(getters.getChainAnalysisSteps),
        getIsLoadedBySourceAndTarget: read(getters.isLoadedBySourceAndTarget),
        getComparison: read(getters.getComparison),
        commitPrepareIndex: commit(mutations.prepareIndex),
        commitInjectToRequestIndex: commit(mutations.injectToRequestIndex),
        commitRemoveFromRequestIndex: commit(mutations.removeFromRequestIndex),
        commitUpdateRequestIndex: commit(mutations.updateRequestIndex),
        commitResetRequestIndex: commit(mutations.resetRequestIndex),
        commitResetRequestIndexes: commit(mutations.resetRequestIndexes),
        commitInvalidateIndexEntriesBySourceAndTarget: commit(
          mutations.invalidateIndexEntriesBySourceAndTarget
        ),
      },
      public: {},
    };
  },
});

export default simoIndexManager;
