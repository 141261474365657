import { computed, onActivated, onMounted, unref, watch } from 'vue';
import { MaybeRef } from '@vueuse/core';
import { PathParameters } from '@/utils/path';
import { range } from '@/utils/math';
import { ResourceListConfig } from '@/utils/resourceList';
import { lastPageNumber } from '@/utils/pagination/utils';

export const useResourceList = <Resource>(options: {
  resourceConfig: MaybeRef<ResourceListConfig>;
  query?: MaybeRef<string>;
  queryFilterKey?: MaybeRef<string>;
  requestType?: MaybeRef<string>;
  requestId?: MaybeRef<string>;
  pageSize?: MaybeRef<number>;
  reloadOnRender?: MaybeRef<boolean>;
  reloadOnActivated?: MaybeRef<boolean>;
}) => {
  const {
    resourceConfig,
    query,
    queryFilterKey = 'query',
    requestType,
    requestId,
    pageSize,
    reloadOnRender,
    reloadOnActivated,
  } = options;

  const pathParameters = computed((): Partial<PathParameters> => {
    return {
      ...(unref<ResourceListConfig>(resourceConfig).pathParameters || {}),
      [unref<string>(queryFilterKey)]: unref<string>(query)?.trim(),
    };
  });

  const computedRequestId = computed(() => {
    return (
      unref<string>(requestId) ||
      Object.entries(pathParameters.value)
        .map(([key, value]) => `${key}:${value}`)
        .join(';')
    );
  });

  const paginationData = computed(() => {
    if (
      !unref<ResourceListConfig>(resourceConfig).module.getIsIndexInitialized(
        unref<string>(requestType),
        computedRequestId.value
      )
    ) {
      return null;
    }
    return unref(<ResourceListConfig>resourceConfig).module.getPaginationData(
      unref<string>(requestType),
      computedRequestId.value
    );
  });

  const currentPage = computed(() => {
    return paginationData.value?.currentPageInfo?.page;
  });

  const lastPage = computed(() => {
    return (
      paginationData.value &&
      lastPageNumber(paginationData.value.totalCount, unref<number>(pageSize))
    );
  });

  const items = computed<Resource[]>(() => {
    if (!paginationData.value) {
      return [];
    }
    return unref<ResourceListConfig>(resourceConfig).module.getFullResource(
      unref<string>(requestType),
      computedRequestId.value
    );
  });

  const hasMore = () => {
    return !currentPage.value || currentPage.value !== lastPage.value;
  };

  const loading = computed(() => {
    return (
      unref<ResourceListConfig>(resourceConfig).module.getPagesInProgress(
        unref<string>(requestType),
        computedRequestId.value
      ).length > 0
    );
  });

  const requestPayload = computed(() => {
    const basePath = unref<ResourceListConfig>(resourceConfig)?.basePath;
    return {
      ...pathParameters.value,
      basePath,
      requestType: unref<string>(requestType),
      requestId: computedRequestId.value,
    };
  });

  const loadMore = () => {
    if (loading.value) {
      return;
    }
    const page = currentPage.value ? currentPage.value + 1 : 1;
    return loadPage(page);
  };

  const reload = () => {
    unref<ResourceListConfig>(resourceConfig).module.dispatchResetIndex({
      requestType: unref<string>(requestType),
      requestId: computedRequestId.value,
    });
  };

  const loadPage = (page: number) => {
    return unref<ResourceListConfig>(
      resourceConfig
    ).module.dispatchLoadPaginatedResource({
      ...requestPayload.value,
      pageInfo: {
        page,
        pageSize: unref<number>(pageSize),
      },
    });
  };

  const loadRest = () => {
    if (!paginationData.value) {
      return unref<ResourceListConfig>(
        resourceConfig
      ).module.dispatchLoadFullResource(requestPayload.value);
    }
    if (currentPage.value >= lastPage.value) return Promise.resolve();
    const pagesToLoad = range(currentPage.value + 1, lastPage.value + 1);
    return Promise.all(pagesToLoad.map(loadPage));
  };

  onMounted(() => {
    if (unref(reloadOnRender) && !unref(reloadOnActivated)) {
      reload();
    }
  });

  onActivated(() => {
    if (unref(reloadOnActivated)) {
      reload();
    }
  });

  const pathParametersIdentifier = computed(() => {
    return (
      unref<ResourceListConfig>(resourceConfig).pathParameters &&
      Object.entries(unref<ResourceListConfig>(resourceConfig).pathParameters)
        .map(([key, val]) => `${key}:${val}`)
        .join(';')
    );
  });

  watch(pathParametersIdentifier, reload);

  return {
    hasMore,
    loadMore,
    loadRest,
    loadPage,
    loading,
    items,
    query,
  };
};
