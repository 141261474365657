import { ResourceType } from '@/services/resources';
import { ProgressStep } from '@/models/progressStep';
import { ResourceListConfig } from '@/utils/resourceList';

export enum FormFieldType {
  Text,
  Textarea,
  Select,
  ProgressWidget,
  ResourceSelector,
  Slot,
}

export type ValidationRule = (value: unknown) => boolean | string;

type FormFieldDefinitionBase = {
  type: FormFieldType;
  attribute: string;
  label?: string;
  placeholder?: string;
  rules?: ValidationRule[];
  readonly?: boolean;
  ignoreFormReadonly?: boolean;
  initialValue?: unknown;
  errorMessages?: string[];
};

export type FormFieldDefinition = FormFieldDefinitionBase &
  (
    | { type: FormFieldType.Text }
    | { type: FormFieldType.Textarea }
    | {
        type: FormFieldType.Select;
        options: Array<{ text: string; value: unknown; disabled?: boolean }>;
      }
    | {
        type: FormFieldType.ProgressWidget;
        targetType: ResourceType;
        targetId: string;
        progressSteps: ProgressStep[];
      }
    | {
        type: FormFieldType.ResourceSelector;
        resourceConfig: ResourceListConfig;
        itemText?: (item) => string;
      }
    | {
        type: FormFieldType.Slot;
        ignoreValue?: boolean;
        isRelationship?: boolean;
        afterCreated?: ({ resourceId }) => Promise<void>;
      }
  );

export type CreateResourcePayload = {
  resource: Record<string, unknown>;
  relationships: Record<string, string>;
};

type UpdateResourcePayload = {
  resourceId: string;
  resource: Record<string, unknown>;
  relationships?: Record<string, string>;
};
export interface FormConfig {
  schema: FormFieldDefinition[];
  createResource: (payload: CreateResourcePayload) => Promise<{ id: string }>;
  updateResource: (payload: UpdateResourcePayload) => Promise<unknown>;
}

export const isIgnoredField = (
  field: FormFieldDefinition,
  keepProgress = false
) => {
  return (
    (field.type === FormFieldType.ProgressWidget && !keepProgress) ||
    (field.type === FormFieldType.Slot && field.ignoreValue)
  );
};

export const isRelationshipField = (
  field: FormFieldDefinition,
  progressAsRelationship = false
) => {
  return (
    field.type === FormFieldType.ResourceSelector ||
    (field.type === FormFieldType.Slot && field.isRelationship) ||
    (field.type === FormFieldType.ProgressWidget && progressAsRelationship)
  );
};
