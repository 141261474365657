<template>
  <div class="main-panel">
    <v-progress-linear
      data-spec="loader"
      v-if="loadingInProgress"
      style="z-index: 100"
      color="primary"
      indeterminate
      absolute
    />

    <slot v-if="isWithCustomSlots" name="resourceTableDefaultSlot" />
    <slot v-else />

    <slot
      name="cursor"
      :attrs="{
        rows: registry,
      }"
      :on="{
        'expand:row': expandRow,
        'collapse:row': collapseRow,
        'click:row': onRowClick,
      }"
    />

    <!--    TODO for sake fo moving forward I'm leaving two tables since there is an issue with slots that are used on users page -->
    <v-data-table
      v-if="isWithCustomSlots"
      class="table-panel__table"
      :headers="headers"
      :items="resources"
      :items-per-page="pageSize"
      item-value="id"
      fixed-header
      height="100%"
      @update:modelValue="currentItems = $event"
      flex-grow="2"
    >
      <template v-if="hideHeaders" #headers></template>
      <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps" />
      </template>
      <template #body="{ sortBy }">
        <!-- <tbody> -->
        <slot name="body-prepend-row" :columns="columns" />

        <template
          v-for="(
            { item, depth, parent, lastWithinParent }, index
          ) in getSortedItemsWithChildren(sortBy)"
          :key="item.id"
        >
          <ResourceTableRow
            :ref="`tableRows-${index}`"
            :item="item"
            :parent="parent"
            :depth="depth"
            :isSelected="selectedItemId === item.id"
            :columns="columns"
            :nesting="resourceConfig.nesting"
            :isExpanded="isExpanded(item)"
            :hasChildren="
              (!otherResourceNesting || !parent) && hasChildren(item.id)
            "
            :loadingChildren="loadingNestedInProgress.has(item.id)"
            @click="onRowClick(item)"
          >
            <template
              v-for="slot of Object.keys($slots).filter((s) =>
                s.startsWith('attribute-')
              )"
              #[slot]="scope"
            >
              <slot :name="slot" v-bind="scope" />
            </template>
          </ResourceTableRow>

          <slot name="append-row" v-bind="{ item, columns, depth }" />

          <slot
            v-if="lastWithinParent"
            name="group-append-row"
            v-bind="{ parent, columns, depth }"
          />
        </template>
        <template v-if="infiniteScroll && hasMore && resources.length">
          <tr>
            <td
              data-spec="pagination"
              class="no-data-placeholder"
              :colspan="headers.length"
              center
            >
              <div
                v-intersect="
                  (isIntersecting) =>
                    isIntersecting &&
                    !loadingInProgress &&
                    hasMore &&
                    goToPage(lastLoadedPage + 1)
                "
              />

              <transition name="fade" mode="out-in">
                <span :key="noDataText">Loading...</span>
              </transition>
            </td>
          </tr>
        </template>
        <template v-if="isTableEmpty(itemsWithChildren)">
          <tr>
            <td
              data-spec="no-data-placeholder"
              class="no-data-placeholder"
              :colspan="headers.length"
            >
              <transition name="fade" mode="out-in">
                <span :key="noDataText">{{ noDataText }}</span>
              </transition>
            </td>
          </tr>
        </template>
        <slot name="body-append-row" :columns="columns" />
        <!-- </tbody> -->
      </template>

      <template #bottom>
        <!-- hide footer -->
      </template>
    </v-data-table>
    <v-data-table
      v-else
      class="table-panel__table"
      :headers="headers"
      :items="resources"
      :items-per-page="pageSize"
      item-value="id"
      fixed-header
      height="100%"
      @update:modelValue="currentItems = $event"
      flex-grow="2"
    >
      <template v-if="hideHeaders" #headers></template>
      <template #body="{ sortBy }">
        <!-- <tbody> -->
        <slot name="body-prepend-row" :columns="columns" />

        <template
          v-for="(
            { item, depth, parent, lastWithinParent }, index
          ) in getSortedItemsWithChildren(sortBy)"
          :key="item.id"
        >
          <ResourceTableRow
            :ref="`tableRows-${index}`"
            :item="item"
            :parent="parent"
            :depth="depth"
            :isSelected="selectedItemId === item.id"
            :columns="columns"
            :nesting="resourceConfig.nesting"
            :isExpanded="isExpanded(item)"
            :hasChildren="
              (!otherResourceNesting || !parent) && hasChildren(item.id)
            "
            :loadingChildren="loadingNestedInProgress.has(item.id)"
            @click="onRowClick(item)"
          >
            <template
              v-for="slot of Object.keys($slots).filter((s) =>
                s.startsWith('attribute-')
              )"
              #[slot]="scope"
            >
              <slot :name="slot" v-bind="scope" />
            </template>
          </ResourceTableRow>

          <slot name="append-row" v-bind="{ item, columns, depth }" />

          <slot
            v-if="lastWithinParent"
            name="group-append-row"
            v-bind="{ parent, columns, depth }"
          />
        </template>
        <template v-if="infiniteScroll && hasMore && resources.length">
          <tr>
            <td
              data-spec="pagination"
              class="no-data-placeholder"
              :colspan="headers.length"
              center
            >
              <div
                v-intersect="
                  (isIntersecting) =>
                    isIntersecting &&
                    !loadingInProgress &&
                    hasMore &&
                    goToPage(lastLoadedPage + 1)
                "
              />

              <transition name="fade" mode="out-in">
                <span :key="noDataText">Loading...</span>
              </transition>
            </td>
          </tr>
        </template>
        <template v-if="isTableEmpty(itemsWithChildren)">
          <tr>
            <td
              data-spec="no-data-placeholder"
              class="no-data-placeholder"
              :colspan="headers.length"
            >
              <transition name="fade" mode="out-in">
                <span :key="noDataText">{{ noDataText }}</span>
              </transition>
            </td>
          </tr>
        </template>
        <slot name="body-append-row" :columns="columns" />
        <!-- </tbody> -->
      </template>

      <template #bottom>
        <!-- hide footer -->
      </template>
    </v-data-table>
    <v-pagination
      v-if="!infiniteScroll"
      data-spec="pagination"
      color="primary"
      :model-value="currentPage"
      :length="lastPage"
      :total-visible="5"
      @update:modelValue="goToPage"
    />
  </div>
</template>

<script lang="ts">
import { Options, Prop, Watch, Vue } from 'vue-property-decorator';
import { AppNotification } from '@/store/modules/notifications';
import { composeMessage } from '@/services/errorHandler';
import { PathParameters } from '@/utils/path';
import ResourceTableRow from './resourceTableRow.vue';
import {
  DataTableHeader,
  defaultColumns,
  nestedRequestType,
  ResourceConfig,
  TableColumnDefinition,
} from '@/utils/resourceTable';
import { lastPageNumber } from '@/utils/pagination/utils';
import { PayloadPaginatedResource } from '@/store/components/indexManager';
import { setup } from 'vue-class-component';
import { useRegistry } from '@/composables/registrable';
import { computed } from 'vue';

interface Resource {
  id: string;
}

interface ResourceTableItem {
  item: Resource;
  depth: number;
  parent?: Resource;
  lastWithinParent?: boolean;
}

type SortingInfo = {
  key: string;
  order: 'asc' | 'desc';
};

@Options({
  components: {
    ResourceTableRow,
  },
  emits: ['click:item', 'update:page', 'update:items', 'expand:item'],
})
export default class ResourceTable extends Vue {
  @Prop({ required: true }) resourceConfig: ResourceConfig;
  @Prop({ default: () => Object.values(defaultColumns) })
  customColumns: TableColumnDefinition[];
  @Prop({ type: Number, default: 1 }) page: number;
  @Prop({ type: Number, default: 30 }) pageSize: number;
  @Prop(Boolean) hideHeaders: boolean;
  @Prop(String) selectedItemId?: string;
  @Prop(String) customNoDataText?: string;
  @Prop({ type: String, default: 'default' }) requestType?: string;
  @Prop({ type: Boolean, default: true }) infiniteScroll: boolean;
  @Prop({ type: Boolean, default: false }) isWithCustomSlots: boolean;

  loadingInProgress = false;
  lastPathParameters: PathParameters | null = null;
  expandedItemIds = new Set<string>();
  loadingNestedInProgress = new Set<string>();
  currentItems: Resource[] = [];

  registry = setup(() => {
    const { registry } = useRegistry<typeof ResourceTableRow>();
    return computed(() => registry.value.map((r) => r.proxy));
  });

  get hasMore() {
    return this.lastLoadedPage !== this.lastPage;
  }

  get lastPage() {
    return lastPageNumber(
      this.resourceConfig.module.getPaginationData(this.requestType).totalCount,
      this.pageSize
    );
  }
  get lastLoadedPage() {
    return Math.ceil(
      this.resourceConfig.module.getFullResource(this.requestType).length /
        this.pageSize
    );
  }

  goToPage(page: number) {
    if (this.infiniteScroll && this.hasMore) {
      this.loadPage(page);
    }
    if (!this.infiniteScroll && page !== this.currentPage) {
      this.loadPage(page);
    }
  }

  get currentPage() {
    return this.resourceConfig.module.getPaginationData(this.requestType)
      .currentPageInfo?.page;
  }

  get otherResourceNesting(): boolean {
    return !!(this.resourceConfig.nesting && this.resourceConfig.nestingModule);
  }

  get joinedParameters() {
    const { pathParameters } = this.resourceConfig;
    return pathParameters ? Object.entries(pathParameters).join() : '';
  }

  get columns(): TableColumnDefinition[] {
    return [...this.customColumns].filter(Boolean);
  }

  get headers() {
    const headers: DataTableHeader[] = this.columns.map((col) => ({
      title: col.header,
      key: col.attribute,
      sortable: col.sortable !== false,
      ...col.headerProps,
    }));
    return headers;
  }

  get noDataText() {
    if (this.loadingInProgress) return 'Loading...';
    if (this.lastPathParameters?.query)
      return `No results matching "${this.lastPathParameters.query}"`;
    return this.customNoDataText || 'Table is empty';
  }

  get indexIdentifier() {
    return this.resourceConfig.module.getRequestInUseId(this.requestType);
  }

  get resources() {
    const items = this.infiniteScroll
      ? this.resourceConfig.module.getFullResource(this.requestType)
      : this.resourceConfig.module.getPaginatedResource(this.requestType);

    if (this.resourceConfig.transformResourceIndex) {
      return this.resourceConfig.transformResourceIndex(items);
    }
    return items;
  }

  loadPage(page = 1) {
    const pathParameters: PayloadPaginatedResource = {
      ...this.resourceConfig.pathParameters,
      requestType: this.requestType,
      pageInfo: {
        page,
        pageSize: this.pageSize,
      },
    };
    this.loadingInProgress = true;
    this.lastPathParameters = pathParameters;
    return this.resourceConfig.module
      .dispatchLoadPaginatedResource(pathParameters)
      .catch((error) => {
        this.error(`Loading failed: ${composeMessage(error)}`);
      })
      .finally(() => {
        this.loadingInProgress = false;
      });
  }

  isTableEmpty(items): boolean {
    return items.length === 0;
  }

  @Watch('indexIdentifier')
  onIndexIdentifierChange() {
    this.expandedItemIds.clear();
  }

  @Watch('joinedParameters')
  onParameterChange() {
    this.loadPage();
  }
  @Watch('currentPage')
  onCurrentPageChange() {
    this.$emit('update:page', this.currentPage);
  }
  @Watch('page')
  onRoutePageChange(page: number) {
    this.goToPage(page);
  }
  async beforeMount() {
    const page = this.page || 1;
    await this.loadPage(page);
  }

  get itemsWithChildren() {
    const rows: ResourceTableItem[] = [];
    return rows.concat(
      this.resources.flatMap((item, index, all) =>
        this.itemWithNested(item, 0, null, index === all.length - 1)
      )
    );
  }

  getSortedItemsWithChildren(sortInfo: readonly any[]) {
    let rows: ResourceTableItem[] = [];
    rows = rows.concat(
      this.resources.flatMap((item, index, all) =>
        this.itemWithNested(item, 0, null, index === all.length - 1)
      )
    );
    return sortInfo.length ? this.sortObjects(rows, sortInfo[0]) : rows;
  }

  sortObjects(
    array: ResourceTableItem[],
    sortingInfo: SortingInfo
  ): ResourceTableItem[] {
    return [...array].sort((a: ResourceTableItem, b: ResourceTableItem) => {
      const key = sortingInfo.key;
      if (typeof a.item[key] === 'number' && typeof b.item[key] === 'number') {
        return sortingInfo.order === 'asc'
          ? (a.item[key] as any) - (b.item[key] as any)
          : (b.item[key] as any) - (a.item[key] as any);
      } else {
        return sortingInfo.order === 'asc'
          ? String(a.item[key]).localeCompare(String(b.item[key]))
          : String(b.item[key]).localeCompare(String(a.item[key]));
      }
    });
  }

  itemWithNested(
    item: Resource,
    depth: number,
    parent: Resource | null,
    lastWithinParent: boolean
  ) {
    const rows: ResourceTableItem[] = [
      { item, depth, parent, lastWithinParent },
    ];
    if (!this.resourceConfig.nesting) return rows;
    if (!this.isExpanded(item)) return rows;
    const children = this.itemChildren(item.id);
    return rows.concat(
      children.flatMap((child, index, all) =>
        this.itemWithNested(child, depth + 1, item, index === all.length - 1)
      )
    );
  }

  itemChildren(itemId: string): Resource[] {
    if (!this.resourceConfig.nesting) return [];
    const storeModule =
      this.resourceConfig.nestingModule || this.resourceConfig.module;
    const items =
      storeModule.getFullResource(
        nestedRequestType(itemId, this.resourceConfig.nestingRelationshipKey)
      ) || [];
    if (this.resourceConfig.transformNestedResourceIndex) {
      return this.resourceConfig.transformNestedResourceIndex(items);
    }
    return items;
  }

  isExpanded(item) {
    return this.expandedItemIds.has(item.id);
  }

  public expandRow(item: Resource) {
    if (!this.resourceConfig.nesting) return;
    if (!this.hasChildren(item.id)) return;
    this.expandedItemIds.add(item.id);
    this.loadNestedResource(item);
  }

  getNestedPathParameters(item) {
    if (!this.resourceConfig.nesting) return {};
    const { nestingPathParameters } = this.resourceConfig;
    return nestingPathParameters?.(item) || {};
  }

  loadNestedResource(item: Resource) {
    if (!this.resourceConfig.nesting) return;
    this.loadingNestedInProgress.add(item.id);
    const pathParameters = this.getNestedPathParameters(item);
    const storeModule =
      this.resourceConfig.nestingModule || this.resourceConfig.module;
    return storeModule
      .dispatchLoadFullResource({
        requestType: nestedRequestType(
          item.id,
          this.resourceConfig.nestingRelationshipKey
        ),
        ...pathParameters,
      })
      .catch((error) => {
        this.error(`Loading failed: ${composeMessage(error)}`);
      })
      .finally(() => {
        this.loadingNestedInProgress.delete(item.id);
        this.$emit('expand:item', item.id);
      });
  }

  collapseRow(item) {
    this.expandedItemIds.delete(item.id);
  }

  toggleRow(item) {
    if (this.isExpanded(item)) {
      this.collapseRow(item);
    } else {
      this.expandRow(item);
    }
  }

  onRowClick(item: Resource) {
    const { parent } = this.itemsWithChildren.find(
      (row) => row.item.id === item.id
    );
    if (
      this.resourceConfig.nesting &&
      (!this.otherResourceNesting || !parent)
    ) {
      this.toggleRow(item);
    } else {
      this.$emit('click:item', item.id);
    }
  }

  showNotification(payload: AppNotification) {
    this.$store.$direct.notifications.dispatchNotify(payload);
  }

  error(message: string) {
    this.showNotification({ message, type: 'error' });
  }

  hasChildren(id: string): boolean {
    if (!this.resourceConfig.nesting) return false;
    if (this.itemChildren(id).length > 0) return true;
    const relKey = this.resourceConfig.nestingRelationshipKey;
    const childrenCount =
      this.resourceConfig.module.getRelationshipMetaProperty(
        id,
        relKey,
        'count'
      );
    return !!(childrenCount && childrenCount > 0);
  }

  @Watch('resources')
  onCurrentItemsChange(items) {
    this.$emit('update:items', items);
  }
}
</script>

<style lang="scss" scoped>
.main-panel {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  & > :deep(*) {
    flex-shrink: 0;
  }
}
.table-panel__table {
  background-color: rgb(var(--v-theme-background));
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  & :deep(tbody) {
    background-color: white;
  }
  & :deep(tr) {
    cursor: pointer;
  }
  & :deep(.buttons-cell) {
    width: 15%;
    cursor: auto;
  }
  // override Vuetify style for additional rows (slots)
  & :deep(tr:not(.resource-table-row)) {
    background-color: transparent !important;
  }
}
.table-panel__body {
  background-color: white;
}
.no-data-placeholder {
  text-align: center;
  pointer-events: none;
  opacity: 0.5;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
