import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { buildIncludeParam } from '@/utils/path';
import { ComponentType } from './_types';
import { ResourceIdentification } from './crud';
import jv from './jv';

export type ArchiverAccessors = ModuleComponentPublicAccessors<typeof archiver>;

const archiver = createModuleComponent({
  type: ComponentType.Archiver,
  dependencies: [jv],
  setup: ({ getAccessors, resourceProfile, components }) => {
    const actions = {
      async archiveResource(context, payload: ResourceIdentification) {
        const resourceMeta = { _jv: { type: resourceProfile.type } };
        let url = `/${resourceProfile.path}/${payload.resourceId}/archive`;
        const includePath = buildIncludeParam(resourceProfile);
        url = includePath ? `${url}?include=${includePath}` : url;
        await components.$jv.protected.dispatchPost([resourceMeta, { url }]);
      },
      async unarchiveResource(context, payload: ResourceIdentification) {
        const resourceMeta = { _jv: { type: resourceProfile.type } };
        let url = `/${resourceProfile.path}/${payload.resourceId}/unarchive`;
        const includePath = buildIncludeParam(resourceProfile);
        url = includePath ? `${url}?include=${includePath}` : url;
        await components.$jv.protected.dispatchPost([resourceMeta, { url }]);
      },
    };

    const { dispatch } = getAccessors();

    return {
      module: {
        actions,
      },
      public: {
        dispatchArchiveResource: dispatch(actions.archiveResource),
        dispatchUnarchiveResource: dispatch(actions.unarchiveResource),
      },
    };
  },
});

export default archiver;
