import { createModule } from '../utils/factories';
import { createIncludeRelationshipPath, resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import createTreeComponent from '../components/tree';
import processingTracker from '../components/processingTracker';

const tree = createTreeComponent({
  includeRelationships: [
    createIncludeRelationshipPath(resources.syntaxNode)('components'),
  ],
});

export default createModule({
  path: 'syntaxNode',
  resourceProfile: resources.syntaxNode,
  components: [crud, indexManager, processingTracker, tree],
  setup({ components }) {
    return {
      module: {},
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        ...components.$processingTracker.public,
        ...components.$tree.public,
      },
    };
  },
});
