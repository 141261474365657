/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module, Store } from 'vuex';
import {
  GetAccessor,
  DispatchAccessorNoPayload,
  DispatchAccessorWithPayload,
  MutationHandlerWithPayload,
  MutationHandlerNoPayload,
  getStoreAccessors,
  CommitAccessorNoPayload,
  CommitAccessorWithPayload,
  ActionHandlerNoPayload,
  ActionHandlerWithPayload,
  GetterHandler,
  GetterFunctionHandler,
  GetFunctionAccessor,
} from './accessors';
import { ComponentType } from '../components/_types';
import { ResourceProfile } from '@/services/resources';

interface BoundStoreAccessors<TModuleState, TRootState> {
  commit(
    handler: MutationHandlerNoPayload<TModuleState>
  ): OmitFirstArg<CommitAccessorNoPayload<TModuleState, TRootState>>;
  commit<TPayload>(
    handler: MutationHandlerWithPayload<TModuleState, TPayload>
  ): OmitFirstArg<
    CommitAccessorWithPayload<TModuleState, TRootState, TPayload>
  >;
  dispatch<TResult>(
    handler: ActionHandlerNoPayload<TModuleState, TRootState, TResult>
  ): OmitFirstArg<DispatchAccessorNoPayload<TModuleState, TRootState, TResult>>;
  dispatch<TPayload, TResult>(
    handler: ActionHandlerWithPayload<
      TModuleState,
      TRootState,
      TPayload,
      TResult
    >
  ): OmitFirstArg<
    DispatchAccessorWithPayload<TModuleState, TRootState, TPayload, TResult>
  >;
  read<TArgs extends unknown[], TResult>(
    handler: GetterFunctionHandler<TModuleState, TRootState, TArgs, TResult>
  ): OmitFirstArg<
    GetFunctionAccessor<TModuleState, TRootState, TArgs, TResult>
  >;
  read<TResult>(
    handler: GetterHandler<TModuleState, TRootState, TResult>
  ): OmitFirstArg<GetAccessor<TModuleState, TRootState, TResult>>;
  legacy: {
    commit: Store<any>['commit'];
    dispatch: Store<any>['dispatch'];
    getters: Store<any>['getters'];
    state: Store<any>['state'];
  };
}

type BoundPublicAccessor<T extends (...args: any) => any = any> = T;

interface BoundPublicAccessors {
  [key: string]: BoundPublicAccessor;
}

type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

export type ComponentDepsBoundAccessors<
  Dep extends ModuleComponentConstructor,
> = {
  [K in Dep['type']]: Dep extends { type: K }
    ? {
        public: ReturnType<Dep>['public'];
        protected: ReturnType<Dep>['protected'];
      }
    : never;
};

export type ModuleDepsBoundAccessors<Dep extends ModuleConstructor> = {
  [K in Dep['path']]: Dep extends { path: K }
    ? {
        public: ReturnType<Dep>['public'];
        protected: ReturnType<Dep>['protected'];
      }
    : never;
};

type ModuleInstance<Mod extends ModuleConstructor = any> = ReturnType<Mod>;

type ModuleInstanceProtectedAccessors<
  Inst extends ModuleComponent<any, any, any>,
> = Inst['protected'];

type ModuleDirectAccessors<Inst extends ModuleComponent<any, any, any>> =
  Inst['public'];

export type ModuleInstancesDict<
  D extends Record<string, Mod>,
  Mod extends ModuleConstructor = any,
> = {
  [K in keyof D]: ModuleInstance<D[K]>;
};

export type ModuleDirectAccessorsDict<
  D extends Record<string, Inst>,
  Inst extends ModuleComponent<any, any, any> = any,
> = {
  [K in keyof D]: ModuleDirectAccessors<D[K]>;
};

export interface ModuleComponentConstructor {
  (options: {
    path: string;
    components: ComponentDepsBoundAccessors<any>;
    resourceProfile?: ResourceProfile;
    getStore: () => Store<any>;
  }): ModuleComponent<any, any, any>;
  type: ComponentType;
  deps: ModuleComponentConstructor[];
}

export interface ModuleConstructor<Path extends string = string> {
  (options: {
    modules: ModuleDepsBoundAccessors<any>;
    components: ComponentDepsBoundAccessors<any>;
    getStore: () => Store<any>;
  }): ModuleComponent<any, any, any>;
  path: Path;
  depsMod: ModuleConstructor[];
  depsComp: ModuleComponentConstructor[];
  resourceProfile?: ResourceProfile;
}

export interface ModuleComponent<
  Mod,
  Public extends BoundPublicAccessors,
  Protected extends BoundPublicAccessors,
> {
  module: Mod;
  public?: Public;
  protected?: Protected;
}

export type ModuleComponentProtectedAccessors<
  Comp extends ModuleComponentConstructor,
> = ModuleInstanceProtectedAccessors<ReturnType<Comp>>;

export type ModuleComponentPublicAccessors<
  Comp extends ModuleComponentConstructor,
> = ModuleDirectAccessors<ReturnType<Comp>>;

export type ModuleProtectedAccessors<Mod extends ModuleConstructor> =
  ModuleInstanceProtectedAccessors<ReturnType<Mod>>;

const bindStoreToAccessor = (a, getStore: () => Store<any>) => (h) => {
  const accessor = a(h);
  return (...args) => accessor(getStore(), ...args);
};

const createBoundAccessorsGetter =
  (path: string, getStore: () => Store<any>) => () => {
    const { commit, dispatch, read } = getStoreAccessors(path);
    return {
      commit: bindStoreToAccessor(commit, getStore),
      dispatch: bindStoreToAccessor(dispatch, getStore),
      read: bindStoreToAccessor(read, getStore),
      legacy: {
        commit: (type: string, payload?: any) =>
          getStore().commit(type, payload),
        dispatch: (type: string, payload?: any) =>
          getStore().dispatch(type, payload),
        get getters() {
          return new Proxy(getStore()?.getters, {
            get(_, key) {
              return getStore().getters[key];
            },
          });
        },
        get state() {
          return new Proxy(getStore()?.state, {
            get(_, key) {
              return getStore().state[key];
            },
          });
        },
      },
    };
  };

export const createModuleComponent = <
  S,
  R,
  Type extends ComponentType,
  CDep extends ModuleComponentConstructor,
  Mod extends Module<S, R>,
  Public extends BoundPublicAccessors,
  Protected extends BoundPublicAccessors,
>(def: {
  type: Type;
  dependencies?: CDep[];
  setup: (context: {
    getAccessors: <MS, MR = Record<string, never>>() => BoundStoreAccessors<
      MS,
      MR
    >;
    components: ComponentDepsBoundAccessors<CDep>;
    resourceProfile?: ResourceProfile;
  }) => ModuleComponent<Mod, Public, Protected>;
}) => {
  const constructor = (options: {
    path: string;
    components: ComponentDepsBoundAccessors<CDep>;
    resourceProfile?: ResourceProfile;
    getStore: () => Store<any>;
  }) => {
    const { path, components, resourceProfile, getStore } = options;
    const result = def.setup({
      getAccessors: createBoundAccessorsGetter(`${path}/${def.type}`, getStore),
      components,
      resourceProfile,
    });
    return {
      module: { ...result.module, namespaced: true },
      public: result.public,
      protected: result.protected,
    } as const;
  };
  constructor.type = def.type;
  constructor.deps = def.dependencies || [];
  return constructor;
};

export const createModule = <
  S,
  R,
  Path extends string,
  Profile extends ResourceProfile,
  MDep extends ModuleConstructor,
  CDep extends ModuleComponentConstructor,
  Mod extends Module<S, R>,
  Public extends BoundPublicAccessors,
  Protected extends BoundPublicAccessors,
>(def: {
  path: Path;
  resourceProfile?: Profile;
  modules?: MDep[];
  components?: CDep[];
  setup: (context: {
    getAccessors: <
      MS = Record<string, never>,
      MR = Record<string, never>,
    >() => BoundStoreAccessors<MS, MR>;
    components: ComponentDepsBoundAccessors<CDep>;
    modules: ModuleDepsBoundAccessors<MDep>;
    resourceProfile?: Profile;
  }) => ModuleComponent<Mod, Public, Protected>;
}) => {
  const constructor = (options: {
    modules: ModuleDepsBoundAccessors<MDep>;
    components: ComponentDepsBoundAccessors<CDep>;
    getStore: () => Store<any>;
  }) => {
    const getStore = options.getStore;
    const result = def.setup({
      getAccessors: createBoundAccessorsGetter(def.path, getStore),
      components: options.components,
      modules: options.modules,
      resourceProfile: def.resourceProfile,
    });
    return {
      module: {
        ...result.module,
        modules: Object.entries(options.components).reduce(
          (acc, [type, comp]) => ({ ...acc, [type]: (comp as any).module }),
          {}
        ),
        namespaced: true,
      },
      public: result.public,
      protected: result.protected,
    } as const;
  };
  constructor.path = def.path;
  constructor.depsComp = getModuleInternalDeps(def.components);
  constructor.depsMod = def.modules || [];
  constructor.resourceProfile = def.resourceProfile;
  return constructor;
};

const createComponentAccessorsProxy = <Dep extends ModuleComponentConstructor>(
  instances: ComponentDepsBoundAccessors<Dep>,
  comp: ComponentType,
  access: 'public' | 'protected'
) => {
  return new Proxy(
    {},
    {
      get(_, accessorKey) {
        return (...args) => instances[comp][access][accessorKey](...args);
      },
    }
  );
};

const getModuleInternalDeps = (
  components: ModuleComponentConstructor[] = []
) => {
  const deps = components
    .concat(components.flatMap((comp) => comp.deps))
    .filter(
      (comp, index, arr) => arr.findIndex((c) => c.type === comp.type) === index
    );
  return deps;
};

const instantiateModuleComponents = <
  CDep extends ModuleComponentConstructor,
>(options: {
  path: string;
  components: CDep[];
  resourceProfile: ResourceProfile;
  getStore: () => Store<any>;
}) => {
  const { path, components, resourceProfile, getStore } = options;
  const dependencies = (components || []).reduce(
    (acc, dep) => ({
      ...acc,
      [dep.type]: dep,
    }),
    {} as Record<ComponentType, ModuleComponentConstructor>
  );

  // Create component proxies to prevent destructuring uninitialized components
  // with proxies destructuring will work fine unless accessors are called outside
  // getters, actions or mutations which is not supported
  const instances = {} as ComponentDepsBoundAccessors<CDep>;
  const proxies = {} as ComponentDepsBoundAccessors<CDep>;
  Object.keys(dependencies).forEach((type: ComponentType) => {
    proxies[type] = {
      protected: createComponentAccessorsProxy(instances, type, 'protected'),
      public: createComponentAccessorsProxy(instances, type, 'public'),
    };
  });
  Object.entries(dependencies).forEach(([type, dep]) => {
    instances[type] = dep({
      path,
      components: proxies,
      resourceProfile,
      getStore,
    });
  });
  return instances;
};

// Check if all module-module dependencies are fulfilled
const checkModuleDependencies = <
  Dict extends Record<string, ModuleConstructor>,
>(
  modules: Dict,
  instances: ModuleInstancesDict<Dict>
) => {
  if (!import.meta.env.PROD) {
    Object.values(modules).forEach((mod) => {
      mod.depsMod.forEach((depMod) => {
        if (!Object.keys(instances).includes(depMod.path)) {
          throw new Error(
            `Module '${mod.path}': Missing module dependency '${depMod.path}'`
          );
        }
      });
    });
  }
};

export const instantiateModulesAndDirectAccessors = <
  Dict extends Record<string, Mod>,
  Mod extends ModuleConstructor = any,
>(options: {
  modules: Dict;
  getStore: () => Store<any>;
}) => {
  const { modules, getStore } = options;
  const moduleInstances = {} as ModuleInstancesDict<Dict>;
  Object.entries(modules).forEach(([key, mod]) => {
    const componentInstances = instantiateModuleComponents({
      path: mod.path,
      components: mod.depsComp,
      resourceProfile: mod.resourceProfile,
      getStore,
    });
    moduleInstances[key as keyof Dict] = (mod as any)({
      modules: moduleInstances,
      components: componentInstances,
      getStore,
    });
  });

  checkModuleDependencies(modules, moduleInstances);

  const storeModules = Object.entries(moduleInstances).reduce(
    (acc, [key, instance]) => ({
      ...acc,
      [key]: (instance as any).module,
    }),
    {}
  );

  const directAccessors = Object.entries(moduleInstances).reduce(
    (acc, [key, mod]) => ({
      ...acc,
      [key]: mod.public || {},
    }),
    {}
  ) as ModuleDirectAccessorsDict<typeof moduleInstances>;

  return {
    storeModules,
    moduleInstances,
    directAccessors,
  };
};
