<template>
  <v-dialog width="1000" :modelValue="show" @update:modelValue="closeModal">
    <div
      class="top-section"
      :class="{ 'card-intersecting-top': !topIntersecting }"
    >
      <div class="top-section__title">Keyboard Shortcuts</div>

      <v-switch
        v-model="shortcutsActive"
        color="primary"
        class="keyboard-shortcuts-switch"
        :label="`Keyboard shortcuts are ${shortcutStatus}`"
      />
    </div>

    <v-card
      class="rounded-0 card-container"
      :class="{ 'card-disabled': !shortcutsActive }"
    >
      <v-container fluid>
        <div v-intersect="onTopIntersecting" />
        <v-row class="pb-5">
          <v-col>
            <KeyShortcutsSection
              sectionTitle="App wide shortcuts"
              :shortcuts="appShortcuts"
            />
            <KeyShortcutsSection
              sectionTitle="Project Shortcuts"
              :shortcuts="projectShortcuts"
            />
          </v-col>
          <v-col>
            <KeyShortcutsSection
              sectionTitle="Node Tree"
              :shortcuts="treeShortcuts"
            />
            <KeyShortcutsSection
              sectionTitle="SIMO Grid"
              :shortcuts="gridShortcuts"
            />
            <KeyShortcutsSection
              sectionTitle="Doma"
              :shortcuts="domaShortcuts"
            />
          </v-col>
        </v-row>
        <div v-intersect="onBottomIntersecting" />
      </v-container>
    </v-card>
    <div
      class="card-actions"
      :class="{ 'card-intersecting-bottom': !bottomIntersecting }"
    >
      <v-btn color="primary" variant="flat" @click="closeModal"> Close </v-btn>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import KeyShortcutsSection from './keyShortcutsSection.vue';
import { ShortcutDefinition } from './types';
import { STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS } from '@/utils/keyboardShortcutId';

@Options({
  name: 'KeyShortcuts',
  components: {
    KeyShortcutsSection,
  },
  emits: ['closeModal'],
})
export default class KeyShortcuts extends Vue {
  @Prop({ default: () => false }) show: boolean;

  topIntersecting = false;
  bottomIntersecting = false;
  shortcutsActive = true;

  onTopIntersecting(isIntersecting: boolean) {
    this.topIntersecting = isIntersecting;
  }

  onBottomIntersecting(isIntersecting: boolean) {
    this.bottomIntersecting = isIntersecting;
  }

  appShortcuts: ShortcutDefinition[] = [
    {
      description: 'Go to Setup',
      shortcut: [['G', 'S']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Go to Projects',
      shortcut: [['G', 'P']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Go to Users',
      shortcut: [['G', 'U']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'See keyboard shortcuts',
      shortcut: [[this.platformCtrlKey, '/']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
  ];

  projectShortcuts: ShortcutDefinition[] = [
    {
      description: 'Go to Dashboard',
      shortcut: [['K', 'D']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Go to CORE (if in context)',
      shortcut: [['K', 'C']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Go to SIMO (if in context)',
      shortcut: [['K', 'S']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    // {
    //   description: 'Go to Project dashboard',
    //   shortcut: [['K', 'H']],
    //   type: 'text'
    // },
    // { // Not functional yet
    //   description: "Go to Table view",
    //   shortcut: [["G", "T"]],
    //   type: 'text'
    // },
    // {
    //   description: "Go to Node Tree view",
    //   shortcut: [["G", "E"]],
    //   type: 'text'
    // }
  ];

  treeShortcuts: ShortcutDefinition[] = [
    {
      description: 'Select node above/below',
      shortcut: [['mdi-arrow-up', 'mdi-arrow-down']],
      type: 'icon',
      conjunction: 'or',
    },
    {
      description: "Expand node and select it's first child",
      shortcut: [['mdi-arrow-right']],
      type: 'icon',
    },
    {
      description: 'Select parent of currently selected node',
      shortcut: [['mdi-arrow-left']],
      type: 'icon',
    },
    {
      description: 'Collapse subtree',
      shortcut: [[this.platformCtrlKey, 'mdi-arrow-left']],
      type: 'both',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Add new item below the selected element',
      shortcut: [['Enter']],
      type: 'text',
    },
    {
      description: 'Add new child to the selected element',
      shortcut: [[this.platformCtrlKey, 'Enter']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Edit selected element',
      shortcut: [['F2']],
      type: 'text',
    },
    {
      description: 'Delete selected node',
      shortcut: [['Delete']],
      type: 'text',
    },
    {
      description: 'Cut selected node',
      shortcut: [[this.platformCtrlKey, 'X']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Copy selected node',
      shortcut: [[this.platformCtrlKey, 'C']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Paste node',
      shortcut: [[this.platformCtrlKey, 'V']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Search in the structure',
      shortcut: [[this.platformCtrlKey, 'F']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Show hidden relationships',
      shortcut: [[this.platformCtrlKey, 'E']],
      type: 'text',
      conjunction: 'mdi-plus',
    },
    {
      description: 'Change active tree',
      shortcut: [
        ['Shift', 'mdi-arrow-left'],
        ['Shift', 'mdi-arrow-right'],
      ],
      type: 'both',
      conjunction: 'mdi-plus',
    },
    // {
    //   description: "Create sibling node",
    //   shortcut: [["LeftShift", this.platformCtrlKey, "N"]],
    //   type: 'text'
    // },
    // { // This is not working
    //   description: 'Undo',
    //   shortcut: [[this.platformCtrlKey, 'Z']],
    //   type: 'text',
    // },
    // { // TODO replace with working shortcut
    //   description: "Redo",
    //   shortcut: [[this.platformCtrlKey, "Y"]],
    //   type: 'text'
    // },
    // { // Not functional yet
    //   description: "Focus on node, collapse other subtrees",
    //   shortcut: [[this.platformAltKey, "E"]],
    //   type: 'text'
    // },
    // {
    //   description: "Expand the node down to N-levels below; N={0-9}",
    //   shortcut: [[this.platformAltKey, "{N}"]],
    //   type: 'text'
    // }
  ];

  gridShortcuts: ShortcutDefinition[] = [
    {
      description: 'Move cell selection in a direction',
      shortcut: [
        ['mdi-arrow-up', 'mdi-arrow-down', 'mdi-arrow-left', 'mdi-arrow-right'],
      ],
      type: 'icon',
    },
    {
      description: "Set selected OOR cell(s) relations to 'No relations'",
      shortcut: [['-']],
      type: 'text',
    },
    {
      description: "Set selected OOR cell(s) relations to 'Unknown relations'",
      shortcut: [['/']],
      type: 'text',
    },
  ];

  domaShortcuts: ShortcutDefinition[] = [
    {
      description: 'Delete selected relation',
      shortcut: [['Delete']],
      type: 'text',
    },
  ];

  get isMac() {
    return navigator.userAgent.includes('Mac');
  }

  get platformCtrlKey() {
    if (this.isMac) return 'Left ⌘';
    else return 'Left Ctrl';
  }

  get platformAltKey() {
    if (this.isMac) return 'Option';
    else return 'LeftAlt';
  }

  get shortcutStatus() {
    if (this.shortcutsActive) return 'enabled';
    else return 'disabled';
  }

  closeModal() {
    this.$emit('closeModal');
  }

  mounted() {
    if (localStorage.getItem(STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS)) {
      this.shortcutsActive = JSON.parse(
        localStorage.getItem(STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS)
      );
    }
  }

  @Watch('shortcutsActive')
  saveToLocalStorage() {
    localStorage.setItem(
      STORAGE_KEY_KEYBOARD_SHORTCUT_STATUS,
      JSON.stringify(this.shortcutsActive)
    );
  }
}
</script>
<style lang="scss" scoped>
.top-section {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  z-index: 2;
}

.top-section__title {
  padding-left: 10px;
  font-size: 1.5em;
}

.keyboard-shortcuts-switch {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.card-intersecting-top {
  border-bottom: thin grey solid;
}

.card-intersecting-bottom {
  border-top: thin grey solid;
}

.card-actions {
  display: flex;
  position: relative;
  background-color: white;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  padding-right: 10px;
  border-radius: 0 0 5px 5px;
}

.card-disabled {
  color: rgba(0, 0, 0, 0.5);
}
</style>
