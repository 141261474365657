import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import { DomaClassificationEntry } from '@/models/domaClassificationEntry';
import { SyntaxAspectType } from '@/models/syntax';
import { isAllowedDomaClassificationEntry } from '@/utils/doma/classificationEntries';

const REQUEST_TYPE_DOMA_ENTRIES = 'doma-entries';

export default createModule({
  path: 'domaClassificationEntry',
  resourceProfile: resources.domaClassificationEntry,
  components: [crud, indexManager],
  setup({ components, getAccessors }) {
    const getters = {
      entries: () => {
        return components.$indexManager.public.getFullResource(
          REQUEST_TYPE_DOMA_ENTRIES
        ) as DomaClassificationEntry[];
      },
      entriesBySyntaxAspect:
        () => (from: SyntaxAspectType, to: SyntaxAspectType) => {
          const entries = read(getters.entries)();
          return entries.filter((entry) =>
            isAllowedDomaClassificationEntry(from, to, entry)
          );
        },
    };
    const actions = {
      loadDomaEntries() {
        return components.$indexManager.public.dispatchLoadFullResource({
          requestType: REQUEST_TYPE_DOMA_ENTRIES,
          filterDomaClassificationEntryWithoutParent: false,
          filterDomaClassificationEntryTableArchived: false,
          filterDomaClassificationEntryTablePublished: true,
          sort: 'code',
        });
      },
    };

    const { read, dispatch } = getAccessors();

    return {
      module: {
        getters,
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        getEntriesBySyntaxAspect: read(getters.entriesBySyntaxAspect),
        dispatchLoadDomaEntries: dispatch(actions.loadDomaEntries),
      },
    };
  },
});
