import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { ComponentType } from './_types';
import {
  appendToPageIndex,
  createPageIndex,
  injectToPageIndex,
  invalidatePageIndex,
  mergePageIndex,
  PageIndex,
  PageInfo,
  prependToPageIndex,
  removeFromPageIndex,
  updateTotalPageIndex,
} from '@/utils/pagination/pageIndex';
import {
  fullPaginatedResource,
  isPageIndexed,
  lastPageNumber,
  paginate,
} from '@/utils/pagination/utils';
import {
  buildIncludeParam,
  buildParametersPath,
  getIndexRequestIdentifier,
  PathParameters,
} from '@/utils/path';
import { range } from '@/utils/math';
import jv from './jv';
import crud from './crud';
import {
  JVRestructuredCollection,
  JVTopLevelCollectionResource,
} from '@/models/jv';
import { omit } from '@/utils/tools';

export type IndexManagerAccessors = ModuleComponentPublicAccessors<
  typeof indexManager
>;

interface IndexData {
  index: PageIndex;
  currentPageInfo: PageInfo | null;
  fullIndexPromise: Promise<{ jvData: JVRestructuredCollection }> | null;
  pagePromises: Array<{
    pageInfo: PageInfo;
    promise: Promise<{ jvData: JVRestructuredCollection }>;
  }>;
}

interface State {
  indexes: Partial<{
    [requestType: string]: Partial<{
      [requestId: string]: IndexData;
    }>;
  }>;
  requestsInUse: Partial<{ [requestType: string]: string }>;
}

type RequestPayload = {
  requestType?: string;
  requestId?: string;
  basePath?: string;
} & Omit<PathParameters, 'page' | 'pageSize'>;

export type PayloadFullResource = RequestPayload & {
  pageSize?: number;
};

export type PayloadPaginatedResource = RequestPayload & {
  pageInfo: PageInfo;
  preload?: boolean;
  overwrite?: boolean;
};

const DEFAULT_FULL_RESOURCE_PAGE_SIZE = 50;

const getRequestIdentification = (
  payload: Record<string, unknown> & RequestPayload
) => {
  const basePath = payload.basePath || '';
  const requestType = payload.requestType || 'default';
  const requestId =
    payload.requestId || `${basePath}${getIndexRequestIdentifier(payload)}`;
  return { requestType, requestId };
};

const indexManager = createModuleComponent({
  type: ComponentType.IndexManager,
  dependencies: [jv, crud],
  setup: ({ getAccessors, components, resourceProfile }) => {
    const state = (): State => ({
      indexes: {},
      requestsInUse: {},
    });

    const mutations = {
      prepareIndex(
        state: State,
        payload: { requestType: string; requestId: string }
      ) {
        if (!state.indexes[payload.requestType]) {
          state.indexes[payload.requestType] = {};
        }
        if (!state.indexes[payload.requestType][payload.requestId]) {
          const indexData: IndexData = {
            index: createPageIndex(),
            currentPageInfo: null,
            fullIndexPromise: null,
            pagePromises: [],
          };
          state.indexes[payload.requestType][payload.requestId] = indexData;
        }
      },
      updatePageIndex(
        state: State,
        payload: {
          requestType: string;
          requestId: string;
          items: string[];
          pageInfo: PageInfo;
          totalCount?: number;
        }
      ) {
        const { requestType, requestId, items, pageInfo, totalCount } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.currentPageInfo = pageInfo;
          data.index = mergePageIndex(data.index, pageInfo, items);
          if (totalCount || totalCount === 0) {
            data.index = updateTotalPageIndex(data.index, totalCount);
          }
        }
      },
      injectToPageIndex(
        state: State,
        payload: {
          id: string;
          requestType: string;
          requestId: string;
          append?: boolean;
          prepend?: boolean;
        }
      ) {
        const { requestType, requestId, id } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          if (payload.append) {
            data.index = appendToPageIndex(data.index, id);
          } else if (payload.prepend || !data.currentPageInfo) {
            data.index = prependToPageIndex(data.index, id);
          } else {
            data.index = injectToPageIndex(
              data.index,
              data.currentPageInfo,
              id
            );
          }
        }
      },
      removeFromPageIndex(
        state: State,
        payload: { id: string; requestType: string; requestId: string }
      ) {
        const { requestType, requestId, id } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.index = removeFromPageIndex(data.index, (item) => item === id);
        }
      },
      invalidatePageIndex(
        state: State,
        payload: {
          pagesToKeep: PageInfo[];
          requestType?: string;
          requestId?: string;
        }
      ) {
        const { pagesToKeep, requestId, requestType } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.index = invalidatePageIndex(data.index, pagesToKeep);
        }
      },
      resetIndex(
        state: State,
        payload: { requestType: string; requestId: string }
      ) {
        const { requestId, requestType } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          delete state.indexes[requestType][requestId];
        }
      },
      resetIndexes(
        state: State,
        payload: { requestType: string; keepCurrent?: boolean }
      ) {
        const { requestType, keepCurrent } = payload;
        const requestId = read(getters.getRequestInUseId)(requestType);
        const newIndex =
          keepCurrent && requestId
            ? { [requestId]: state.indexes[requestType]?.[requestId] }
            : {};
        state.indexes[payload.requestType] = newIndex;
      },
      setCurrentPage(
        state: State,
        payload: {
          requestType: string;
          requestId: string;
          pageInfo: PageInfo;
        }
      ) {
        const { requestType, requestId, pageInfo } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.currentPageInfo = pageInfo;
        }
      },
      setRequestInUse(
        state: State,
        payload: { requestType: string; requestId: string }
      ) {
        state.requestsInUse[payload.requestType] = payload.requestId;
      },
      setFullIndexInProgress(
        state: State,
        payload: {
          requestType: string;
          requestId: string;
          promise: Promise<{ jvData: JVRestructuredCollection }>;
        }
      ) {
        const { requestType, requestId, promise } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.fullIndexPromise = promise;
        }
      },
      resetFullIndexInProgress(
        state: State,
        payload: { requestType: string; requestId: string }
      ) {
        const { requestType, requestId } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.fullIndexPromise = null;
        }
      },
      setPaginatedIndexInProgress(
        state: State,
        payload: {
          requestType: string;
          requestId: string;
          pageInfo: PageInfo;
          promise: Promise<{ jvData: JVRestructuredCollection }>;
        }
      ) {
        const { requestType, requestId, pageInfo, promise } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.pagePromises = (data.pagePromises || [])
            .filter(
              (d) =>
                d.pageInfo.page !== pageInfo.page ||
                d.pageInfo.pageSize !== pageInfo.pageSize
            )
            .concat({ pageInfo, promise });
        }
      },
      resetPaginatedIndexInProgress(
        state: State,
        payload: { requestType: string; requestId: string; pageInfo: PageInfo }
      ) {
        const { requestType, requestId, pageInfo } = payload;
        const data = state.indexes[requestType]?.[requestId];
        if (data) {
          data.pagePromises = (data.pagePromises || []).filter(
            (d) =>
              d.pageInfo.page !== pageInfo.page ||
              d.pageInfo.pageSize !== pageInfo.pageSize
          );
        }
      },
    };

    const getters = {
      getIsIndexInitialized:
        () =>
        (requestType = 'default', requestId?: string) =>
          !!read(getters.getIndexData)(requestType, requestId),
      getPagesInProgress:
        () =>
        (requestType = 'default', requestId?: string) => {
          return (
            read(getters.getIndexData)(requestType, requestId)?.pagePromises ||
            []
          ).map((d) => d.pageInfo);
        },
      getRequestInUseId:
        (state: State) =>
        (requestType = 'default') =>
          state.requestsInUse[requestType],
      getPaginationData:
        () =>
        (requestType = 'default', requestId?: string) => {
          const data = read(getters.getIndexData)(requestType, requestId);
          return {
            totalCount: data?.index?.totalCount || 0,
            currentPageInfo: data?.currentPageInfo,
          };
        },
      getIndexData:
        (state: State) =>
        (requestType = 'default', requestId?: string) => {
          if (requestId) {
            return state.indexes[requestType]?.[requestId];
          }
          const requestsInUse = state.requestsInUse[requestType];
          return state.indexes[requestType]?.[requestsInUse];
        },
      getFullIndexPromise:
        (state: State) =>
        (requestType = 'default', requestId: string) => {
          return state.indexes[requestType]?.[
            requestId || state.requestsInUse[requestType]
          ]?.fullIndexPromise;
        },
      getPaginatedIndexPromise:
        (state: State) =>
        (requestType = 'default', requestId: string, pageInfo: PageInfo) => {
          return state.indexes[requestType]?.[
            requestId || state.requestsInUse[requestType]
          ]?.pagePromises.find(
            (d) =>
              d.pageInfo.page === pageInfo.page &&
              d.pageInfo.pageSize === pageInfo.pageSize
          );
        },
      resourceFullIds:
        () =>
        (requestType = 'default', requestId?: string) => {
          const ids =
            read(getters.getIndexData)(
              requestType,
              requestId
            )?.index?.items.filter(Boolean) || [];
          return ids;
        },
      resourceFull:
        () =>
        (requestType = 'default', requestId?: string) => {
          return read(getters.resourceFullIds)(requestType, requestId)
            .map((id) => components.$crud.public.getSimplifiedResourceSet()[id])
            .filter(Boolean);
        },
      resourcePaginated:
        () =>
        (requestType = 'default', requestId?: string) => {
          const data = read(getters.getIndexData)(requestType, requestId);
          const ids = paginate(
            data?.index?.items || [],
            data?.currentPageInfo?.page,
            data?.currentPageInfo?.pageSize
          ).filter(Boolean);
          return ids
            .map((id) => components.$crud.public.getSimplifiedResourceSet()[id])
            .filter(Boolean);
        },
      resourcePaginatedFull:
        () =>
        (requestType = 'default', requestId?: string) => {
          const index = read(getters.getIndexData)(
            requestType,
            requestId
          )?.index;
          if (!index) return [];
          const ids = fullPaginatedResource(
            index.items || [],
            index.totalCount || index.items.length
          );
          return ids.map(
            (id: string) =>
              components.$crud.public.getSimplifiedResourceSet()[id]
          );
        },
    };

    const actions = {
      async loadPaginatedResource(
        context,
        payload: PayloadPaginatedResource
      ): Promise<{ jvData: JVRestructuredCollection }> {
        const { requestType, requestId } = getRequestIdentification(payload);
        const { pageInfo } = payload;
        const basePath = payload.basePath || resourceProfile.path;
        const parametersPath = buildParametersPath({
          ...payload,
          include: buildIncludeParam(resourceProfile, payload.include),
          page: pageInfo.page,
          pageSize: pageInfo.pageSize,
        });
        const requestPath = `${basePath}${parametersPath}`;

        commit(mutations.prepareIndex)({ requestType, requestId });
        commit(mutations.setRequestInUse)({ requestType, requestId });
        const index = read(getters.getIndexData)(requestType, requestId)?.index;
        if (
          !payload.overwrite &&
          isPageIndexed(index.items, pageInfo.page, pageInfo.pageSize)
        ) {
          if (!payload.preload) {
            commit(mutations.setCurrentPage)({
              requestType,
              requestId,
              pageInfo,
            });
          }
          return Promise.resolve({ jvData: {} });
        }
        const previousPromise = read(getters.getPaginatedIndexPromise)(
          requestType,
          requestId,
          pageInfo
        )?.promise;
        if (previousPromise) {
          // identical request already running
          return previousPromise;
        }

        const promise = new Promise<{ jvData: JVRestructuredCollection }>(
          // eslint-disable-next-line no-async-promise-executor
          async (resolve, reject) => {
            let jvData: JVTopLevelCollectionResource;
            try {
              jvData = await components.$jv.protected.dispatchGet(requestPath);
              const collectionIds = Object.keys(jvData).filter(
                (key) => key !== '_jv'
              );
              commit(mutations.updatePageIndex)({
                requestType,
                requestId,
                pageInfo,
                items: collectionIds,
                totalCount: jvData._jv.json?.meta?.total_count,
              });
              if (!payload.preload) {
                commit(mutations.setCurrentPage)({
                  requestType,
                  requestId,
                  pageInfo,
                });
              }
            } catch (error) {
              reject(error);
              return;
            }
            resolve({ jvData: omit(jvData, ['_jv']) });
          }
        ).finally(() => {
          commit(mutations.resetPaginatedIndexInProgress)({
            requestType,
            requestId,
            pageInfo,
          });
        });
        commit(mutations.setPaginatedIndexInProgress)({
          requestType,
          requestId,
          pageInfo,
          promise,
        });
        return promise;
      },
      async loadFullResource(context, payload: PayloadFullResource) {
        const { requestType, requestId } = getRequestIdentification(payload);
        const previousPromise = read(getters.getFullIndexPromise)(
          requestType,
          requestId
        );
        if (previousPromise) {
          // identical request already running
          return previousPromise;
        }
        const setInProgress = () =>
          commit(mutations.setFullIndexInProgress)({
            requestType,
            requestId,
            promise,
          });
        const pageSize = payload.pageSize || DEFAULT_FULL_RESOURCE_PAGE_SIZE;
        const promise = new Promise<{ jvData: JVRestructuredCollection }>(
          (resolve, reject) => {
            dispatch(actions.loadPaginatedResource)({
              ...payload,
              pageInfo: { page: 1, pageSize },
            })
              .then((res) => {
                const data = read(getters.getIndexData)(requestType, requestId);
                const lastPage = lastPageNumber(
                  data.index.totalCount,
                  pageSize
                );
                const pagesToLoad = range(2, lastPage + 1);
                const requests = pagesToLoad.map((page) => {
                  return dispatch(actions.loadPaginatedResource)({
                    ...payload,
                    pageInfo: { page, pageSize },
                    preload: true,
                  });
                });
                Promise.all(requests)
                  .then((responses) => {
                    const reduced = [res, ...responses].reduce(
                      (acc, curr) => {
                        return {
                          jvData: {
                            ...acc.jvData,
                            ...curr.jvData,
                          },
                        };
                      },
                      { jvData: {} as JVRestructuredCollection }
                    );
                    resolve(reduced);
                  })
                  .catch(reject);
              })
              .catch(reject);
          }
        ).finally(() => {
          commit(mutations.resetFullIndexInProgress)({
            requestType,
            requestId,
          });
        });
        setInProgress();
        return promise;
      },
      injectToPageIndex(
        context,
        payload: {
          resourceId: string;
          requestType?: string;
          requestId?: string;
          append?: boolean;
          prepend?: boolean;
        }
      ) {
        const requestType = payload.requestType || 'default';
        const requestId =
          payload.requestId || read(getters.getRequestInUseId)(requestType);
        commit(mutations.prepareIndex)({ requestType, requestId });
        return commit(mutations.injectToPageIndex)({
          id: payload.resourceId,
          requestType,
          requestId,
          append: payload.append,
          prepend: payload.prepend,
        });
      },
      removeFromPageIndex(
        context,
        payload: {
          resourceId: string;
          requestType?: string;
          requestId?: string;
        }
      ) {
        const requestType = payload.requestType || 'default';
        const requestId =
          payload.requestId || read(getters.getRequestInUseId)(requestType);
        return commit(mutations.removeFromPageIndex)({
          id: payload.resourceId,
          requestType,
          requestId,
        });
      },
      invalidatePageIndex(
        context,
        payload: {
          pagesToKeep: PageInfo[];
          requestType?: string;
          requestId?: string;
        }
      ) {
        commit(mutations.invalidatePageIndex)(payload);
      },
      resetIndex(context, payload: { requestType: string; requestId: string }) {
        commit(mutations.resetIndex)(payload);
      },
      resetIndexes(
        context,
        payload: { requestType: string; keepCurrent?: boolean }
      ) {
        commit(mutations.resetIndexes)(payload);
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        mutations,
        getters,
        actions,
      },
      public: {
        getRequestInUseId: read(getters.getRequestInUseId),
        getPaginationData: read(getters.getPaginationData),
        getFullResourceIds: read(getters.resourceFullIds),
        getFullResource: read(getters.resourceFull),
        getPaginatedResource: read(getters.resourcePaginated),
        getPaginatedResourceFull: read(getters.resourcePaginatedFull),
        getIsIndexInitialized: read(getters.getIsIndexInitialized),
        getPagesInProgress: read(getters.getPagesInProgress),
        dispatchInjectToPageIndex: dispatch(actions.injectToPageIndex),
        dispatchRemoveFromPageIndex: dispatch(actions.removeFromPageIndex),
        dispatchInvalidatePageIndex: dispatch(actions.invalidatePageIndex),
        dispatchResetIndex: dispatch(actions.resetIndex),
        dispatchResetIndexes: dispatch(actions.resetIndexes),
        dispatchLoadPaginatedResource: (payload: PayloadPaginatedResource) =>
          dispatch(actions.loadPaginatedResource)(payload),
        dispatchLoadFullResource: (payload: PayloadFullResource = {}) =>
          dispatch(actions.loadFullResource)(payload),
      },
    };
  },
});

export default indexManager;
