<template>
  <div class="node-name" :style="labelCustomStyle">
    <template v-if="isRoot">
      {{ isOOCsTree ? item.reference_designation : '&lt;Root&gt;' }}
    </template>
    <template v-else>
      <div ref="reference" class="reference mr-3">
        <div
          class="reference-background"
          :class="{ 'reference-background--expanded': isHighlighted }"
        />
        {{ reference }}
      </div>
      <div class="node-text">
        {{ item.name }}
        <slot name="additionalContent" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { SyntaxItem } from '@/models/syntaxItem';
import { OOC } from '@/models/objectOccurrence';
import { getContrastColor } from '@/utils/colorContrast';
import { singleLevelReferenceDesignation } from '@/utils/objectOccurrence';

@Options({
  name: 'TreeItemLabel',
  components: {},
})
export default class TreeItemLabel extends Vue {
  @Prop({ default: () => null }) item:
    | (Partial<SyntaxItem> & Partial<OOC>)
    | null;
  @Prop(Boolean) isRoot: boolean;
  @Prop({ default: () => 'rgb(var(--v-theme-cardBackground))' })
  referenceBgColor: string;
  @Prop(Boolean) isHighlighted: boolean;
  @Prop(Boolean) isSyntaxTree: boolean;
  @Prop(Boolean) isOOCsTree: boolean;

  get referenceColor() {
    return getContrastColor(this.referenceBgColor);
  }

  get labelCustomStyle() {
    return {
      '--v-referenceTextColor': this.referenceColor,
      '--v-referenceBackgroundColor':
        this.item.hex_color && `#${this.item.hex_color}`,
    };
  }

  get reference(): string {
    if (!this.item) return '';
    return singleLevelReferenceDesignation(this.item);
  }
}
</script>

<style lang="scss" scoped>
.reference-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: var(--v-referenceBackgroundColor);
  z-index: -1;
  transition: width 0.15s ease-in;
}
.reference-background--expanded {
  width: 100%;
}
.node-name {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
}
.reference {
  position: relative;
  display: flex;
  font-weight: bold;
  padding: 0 13px 0 22px;
  height: 100%;
  align-items: center;
  background-color: transparent;
  color: var(--v-referenceTextColor);
  z-index: 0;
}
.node-text {
  display: block;
  overflow: hidden;
  width: 100%;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
