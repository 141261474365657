import { computed } from 'vue';
import { useDirectStore } from './store';
import { formatDateTime } from '@/utils/dateStandard';
import { UserNotification } from '@/models/userNotification';
import { ExportStruct } from '@/models/exportStruct';
import { ImportStruct } from '@/models/importStruct';

const formatTime = (date: string) => {
  const time = formatDateTime(date);
  if (typeof time === 'boolean') return null;
  return time.replace(' ', ' - ');
};

export const useNotification = <T extends UserNotification['origin']>(
  notificationId: string
) => {
  const store = useDirectStore();

  const notification = computed<UserNotification>(
    () => store.userNotifications.getSimplifiedResourceSet()[notificationId]
  );

  const origin = computed<T>(() => {
    if (notification.value.origin_type === 'Import')
      return store.importFile.getSimplifiedResourceSet()[
        notification.value.origin_id
      ];
    return store.exportStruct.getSimplifiedResourceSet()[
      notification.value.origin_id
    ];
  });

  const isFailed = computed<boolean>(() => !!origin.value.failed_at);

  const isCompleted = computed<boolean>(() => !origin.value.processing);

  const isExport = computed(() => notification.value.origin_type === 'Export');

  const exportType = computed(() => {
    if (notification.value.origin_type !== 'Export') return null;
    return (origin.value as ExportStruct).export_type === 'ooc'
      ? 'CORE'
      : 'SIMO';
  });

  const importType = computed(() => {
    if (notification.value.origin_type !== 'Import') return null;
    const type = (origin.value as ImportStruct).import_type;
    if (type === 'ooc') return 'CORE';
    if (type === 'oor') return 'SIMO';
    if (type === 'ct') return 'CLASSIFICATION TABLE';
    return 'UNKNOWN';
  });

  const notificationTitle = computed(() => {
    const notificationType = isExport.value
      ? exportType.value
      : importType.value;

    const notificationPrefix = `${notificationType} ${
      isExport.value ? 'Export' : 'Import'
    }`;

    if (isFailed.value) return `${notificationPrefix} failed`;
    else if (isCompleted.value) return `${notificationPrefix} completed`;
    else return `${notificationPrefix} processing`;
  });

  const timeStart = computed(() =>
    origin.value ? formatTime(origin.value.created_at) : null
  );

  const timeEnd = computed(() =>
    origin.value
      ? formatTime(origin.value.processed_at || origin.value.failed_at)
      : null
  );

  return {
    timeStart,
    timeEnd,
    notificationTitle,
    isFailed,
    isCompleted,
    importType,
    origin,
  };
};
