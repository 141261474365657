import { createModule } from '../utils/factories';
import { resources, ResourceType } from '@/services/resources';
import permission from './permission';
import users from './users';
import indexManager from '../components/indexManager';
import context from './context';
import jv from '../components/jv';
import crud from '../components/crud';
import {
  PermissionContext,
  permissionDefinitions,
  PermissionGlobal,
  PermissionProject,
  PermissionTargetType,
  PermissionType,
} from '@/services/permissions';
import { JsonApiIdentification } from '@/models/api';
import { getUserId } from '@/auth';
import { JVRestructuredRecord } from '@/models/jv';
import {
  calculatePermissions,
  PermissionOwnershipType,
  SimplifiedUserPermission,
} from '@/services/permissionsCalculator';
import tradeStudies from '@/store/modules/tradeStudies';
import { UserPermission } from '@/models/userPermission';
import { User } from '@/models/user';
import { Context } from '@/models/context';
import { canSeeRevisionRequestType } from '@/utils/constants';
import { Permission } from '@/models/permission';

const REQUEST_TYPE_CURRENT_USER = 'current_user';
const REQUEST_ID_CURRENT_USER = 'current_user';

const getRelationshipId = (
  record: JVRestructuredRecord,
  relationshipName: string
) => {
  const identification =
    (record?._jv?.relationships?.[relationshipName]
      ?.data as JsonApiIdentification) || null;
  return identification?.id;
};

const PermissionTargetResourceType: Record<
  PermissionTargetType,
  ResourceType | null
> = {
  [PermissionTargetType.Global]: null,
  [PermissionTargetType.Project]: 'project',
  [PermissionTargetType.Context]: 'context',
};

export default createModule({
  path: 'userPermission',
  resourceProfile: resources.userPermissions,
  components: [indexManager, jv, crud],
  modules: [permission, users, context, tradeStudies],
  setup({ components, modules, getAccessors, resourceProfile }) {
    const getters = {
      currentUserPermissions: () => {
        return components.$indexManager.public.getFullResource(
          REQUEST_TYPE_CURRENT_USER,
          REQUEST_ID_CURRENT_USER
        ) as UserPermission[];
      },
      simplifiedPermissions:
        () =>
        (userPermissions: UserPermission[]): SimplifiedUserPermission[] => {
          return userPermissions
            .map((userPermission) =>
              components.$jv.protected.get({
                type: resourceProfile.type,
                id: userPermission.id,
              })
            )
            .map((userPermission: JVRestructuredRecord) => {
              const type = userPermission.permission?.name;
              const target =
                getRelationshipId(userPermission, 'target') || null;
              return { type, target };
            })
            .filter(({ type }) => !!type);
        },
      hasGlobalPermission:
        () =>
        (
          permissionType: (typeof PermissionGlobal)[keyof typeof PermissionGlobal]
        ): boolean => {
          const accountPermissions = calculatePermissions(
            getCurrentUserSimplifiedPermissions()
          );
          return [
            PermissionOwnershipType.Direct,
            PermissionOwnershipType.Indirect,
          ].includes(accountPermissions[permissionType]);
        },
      hasProjectPermission:
        () =>
        (
          permissionType: (typeof PermissionProject)[keyof typeof PermissionProject],
          projectId: string
        ): boolean => {
          if (!projectId) return false;
          const projectPermissions = calculatePermissions(
            getCurrentUserSimplifiedPermissions(),
            projectId
          );
          return [
            PermissionOwnershipType.Direct,
            PermissionOwnershipType.Indirect,
          ].includes(projectPermissions[permissionType]);
        },
      hasContextPermission:
        () =>
        (
          permissionType: (typeof PermissionContext)[keyof typeof PermissionContext],
          projectId: string,
          contextId: string
        ): boolean => {
          if (!projectId || !contextId) return false;
          const tradeStudyId =
            modules.context.public.getTradeStudyId(contextId);
          const upstreamContextId = tradeStudyId
            ? modules.tradeStudies.public.getBaseContextId(tradeStudyId)
            : undefined;
          const contextPermissions = calculatePermissions(
            getCurrentUserSimplifiedPermissions(),
            projectId,
            {
              id: contextId,
              isTradeStudy: !!tradeStudyId,
              upstreamContextId,
            }
          );
          return [
            PermissionOwnershipType.Direct,
            PermissionOwnershipType.Indirect,
          ].includes(contextPermissions[permissionType]);
        },
      hasUpstreamContextPermission:
        () =>
        (
          permissionType: (typeof PermissionContext)[keyof typeof PermissionContext],
          projectId: string,
          contextId: string
        ): boolean => {
          if (!projectId || !contextId) return false;
          const tradeStudyId =
            modules.context.public.getTradeStudyId(contextId);
          const upstreamContextId = tradeStudyId
            ? modules.tradeStudies.public.getBaseContextId(tradeStudyId)
            : contextId;
          return hasContextPermission(
            permissionType,
            projectId,
            upstreamContextId
          );
        },

      isAdmin: (): boolean => hasGlobalPermission('admin'),
      isManager:
        () =>
        (projectId: string): boolean =>
          hasProjectPermission('project:write', projectId),
      isEditor:
        () =>
        (projectId: string): boolean =>
          hasProjectPermission('project:context_write', projectId),
      isViewer:
        () =>
        (projectId: string): boolean =>
          hasProjectPermission('project:read', projectId),

      // Module access
      canSeeAdminApp: (): boolean => isAdmin(),
      canSeeSetupApp: (): boolean => isAdmin(),
      canSeeProjectsApp: (): boolean => true,

      // Projects & Context
      canCreateContext:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),
      canCreateProject: (): boolean => isAdmin(),
      canWriteContext:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),
      canReadContext: () => (projectId: string, contextId: string) =>
        hasContextPermission('context:read', projectId, contextId),
      canWriteProject: (): boolean => isAdmin(),
      canArchiveContext:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),
      canWriteProjectProgress: (): boolean => isAdmin(),
      canWriteContextProgress:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),
      canSeeArchivedProjects: (): boolean => isAdmin(),
      canSeeArchivedContext:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),

      canWriteDescriptionFields:
        () =>
        (projectId: string): boolean =>
          isAdmin() || isManager(projectId),

      // Owners & owner groups
      canWriteOwnersAndGroups:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),
      canDeleteOwnershipTrueUser:
        () =>
        (projectId: string): boolean =>
          isManager(projectId),

      // CORE
      canWriteOOC:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasContextPermission('context:write', projectId, contextId),

      // SIMO
      canWriteOOR:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasContextPermission('context:write', projectId, contextId),
      canWriteSimoFilterSets:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasContextPermission('context:write', projectId, contextId),
      canSeeMeetingPlanner:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasContextPermission('context:write', projectId, contextId),

      // DOMA
      canWriteOODR:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasContextPermission('context:write', projectId, contextId),

      // Trade Study
      canCreateTradeStudy:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasUpstreamContextPermission('context:write', projectId, contextId),
      canReadMainContext:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasUpstreamContextPermission('context:read', projectId, contextId),
      canCompareDiscardTradeStudy:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasUpstreamContextPermission('context:write', projectId, contextId),
      canMergeRejectTradeStudyDelta:
        () =>
        (projectId: string, contextId: string): boolean =>
          hasUpstreamContextPermission('context:write', projectId, contextId),

      // Revisions
      canReadRevisions: () => (projectId: string) => {
        const projectContexts: Context[] =
          modules.context.public.getPaginatedResourceFull(
            canSeeRevisionRequestType(projectId)
          );
        return projectContexts.some((ctx) =>
          hasContextPermission('context:read', projectId, ctx.id)
        );
      },
      canReadRevision:
        () =>
        (projectId: string, contextId: string): boolean =>
          isViewer(projectId) ||
          hasContextPermission('context:read', projectId, contextId),

      canWriteRevisions: () => (projectId: string) => isManager(projectId),

      // Syntax update
      canUpdateContextSyntax: () => (projectId: string) =>
        isAdmin() || isManager(projectId),
    };

    const actions = {
      async loadCurrentUserPermissions() {
        return components.$indexManager.public.dispatchLoadFullResource({
          requestType: REQUEST_TYPE_CURRENT_USER,
          requestId: REQUEST_ID_CURRENT_USER,
          filterUserId: getUserId(),
        });
      },
      async grantPermission(
        context,
        payload: {
          userId: string;
          permissionType: PermissionType;
          targetId?: string;
        }
      ) {
        const { userId, permissionType, targetId } = payload;
        const definition = permissionDefinitions[permissionType];
        const targetResourceType =
          PermissionTargetResourceType[definition.targetType];
        if (targetResourceType && !targetId) {
          throw new Error('Target id is not specified for targeted permission');
        }

        const permissionResources = modules.permission.public.getFullResource();
        const permissionId = permissionResources.find(
          (per) => per.name === permissionType
        ).id as string;

        const relationships = {
          target: targetResourceType && {
            type: targetResourceType,
            id: targetId,
          },
          permission: permissionId,
          user: userId,
        };

        await components.$crud.public.dispatchCreateResource({
          resource: {},
          relationships,
        });

        await modules.users.public.dispatchLoadSingleResource({
          resourceId: userId,
        });
      },
      async clearContextRelatedPermissions(
        context,
        payload: {
          userId: string;
          contextId: string;
          reloadAfterDelete?: boolean;
        }
      ) {
        const user = modules.users.public.getSimplifiedResourceSet()[
          payload.userId
        ] as User;

        if (!user || !user.user_permissions.length) return;

        const clearContextRelatedPermissions = user.user_permissions.filter(
          ({ target }) => target?.id === payload.contextId
        );

        await Promise.all(
          clearContextRelatedPermissions.map(async ({ permission, target }) => {
            await dispatch(actions.denyPermission)({
              userId: payload.userId,
              permissionType: permission.name,
              targetId: target.id,
              reload: false,
            });
          })
        );

        if (payload.reloadAfterDelete)
          await modules.users.public.dispatchLoadSingleResource({
            resourceId: payload.userId,
          });
      },
      async clearProjectRelatedPermissions(
        context,
        payload: {
          userId: string;
          projectId: string;
          reloadAfterDelete?: boolean;
        }
      ) {
        const user = modules.users.public.getSimplifiedResourceSet()[
          payload.userId
        ] as User;

        if (!user || !user.user_permissions.length) return;

        const projectRelatedPermissions = user.user_permissions.filter(
          (userPermission) => {
            const { target } = userPermission;
            if ('revisions_count' in target) {
              return target.id === payload.projectId;
            } else {
              const relatedProjectId =
                modules.context.public.getRelatedProjectId(target.id);
              return relatedProjectId === payload.projectId;
            }
          }
        );

        const permissionsList =
          modules.permission.public.getPaginatedResourceFull() as Permission[];

        await Promise.all(
          projectRelatedPermissions.map(async ({ permission, target }) => {
            const permissionType = permissionsList.find(
              ({ id }) => id === permission.id
            )?.name;
            await dispatch(actions.denyPermission)({
              userId: payload.userId,
              permissionType,
              targetId: target.id,
              reload: false,
            });
          })
        );

        if (payload.reloadAfterDelete)
          await modules.users.public.dispatchLoadSingleResource({
            resourceId: payload.userId,
          });
      },
      async clearPermissions(
        context,
        payload: {
          userId: string;
          reloadAfterDelete?: boolean;
        }
      ) {
        const user = modules.users.public.getSimplifiedResourceSet()[
          payload.userId
        ] as User;

        if (!user || !user.user_permissions.length) return;
        const permissionsList =
          modules.permission.public.getPaginatedResourceFull() as Permission[];
        await Promise.all(
          user.user_permissions.map(async ({ permission, target }) => {
            const permissionType = permissionsList.find(
              ({ id }) => id === permission.id
            )?.name;
            if (!permissionType) return;
            await dispatch(actions.denyPermission)({
              userId: payload.userId,
              targetId: target?.id,
              permissionType,
              reload: false,
            });
          })
        );
        if (payload.reloadAfterDelete)
          await modules.users.public.dispatchLoadSingleResource({
            resourceId: payload.userId,
          });
      },
      async denyPermission(
        context,
        payload: {
          userId: string;
          permissionType: PermissionType;
          targetId?: string;
          reload?: boolean;
        }
      ) {
        const { reload = true } = payload;
        const { userId, permissionType, targetId } = payload;
        const definition = permissionDefinitions[permissionType];
        const targetResourceType =
          PermissionTargetResourceType[definition.targetType];
        if (targetResourceType && !targetId) {
          throw new Error('Target id is not specified for targeted permission');
        }

        const user = modules.users.public.getSimplifiedResourceSet()[
          userId
        ] as User;
        const userPermission = user?.user_permissions.find(
          (per) =>
            per.permission.name === permissionType &&
            ((!per.target && !targetId) || per.target?.id === targetId)
        );

        const isExplicitPermission = !!userPermission;
        if (isExplicitPermission) {
          await components.$crud.public.dispatchDeleteResource({
            resourceId: userPermission.id,
          });
        } else {
          const contextWrite = user?.user_permissions.find(
            ({ permission, target }) =>
              permission.name === 'context:write' && target?.id === targetId
          );
          if (!!contextWrite && permissionType === 'context:read') {
            await components.$crud.public.dispatchDeleteResource({
              resourceId: contextWrite.id,
            });
          }
        }

        if (reload)
          await modules.users.public.dispatchLoadSingleResource({
            resourceId: userId,
          });
      },
    };

    const { read, dispatch } = getAccessors();

    const getSimplifiedPermissions = read(getters.simplifiedPermissions);
    const getCurrentUserSimplifiedPermissions = () =>
      getSimplifiedPermissions(read(getters.currentUserPermissions)());

    const hasGlobalPermission = read(getters.hasGlobalPermission);
    const hasProjectPermission = read(getters.hasProjectPermission);
    const hasContextPermission = read(getters.hasContextPermission);
    const hasUpstreamContextPermission = read(
      getters.hasUpstreamContextPermission
    );
    const canSeeAdminApp = read(getters.canSeeAdminApp);
    const canSeeSetupApp = read(getters.canSeeSetupApp);
    const canSeeProjectsApp = read(getters.canSeeProjectsApp);
    const canCreateProject = read(getters.canCreateProject);
    const canCreateContext = read(getters.canCreateContext);
    const canWriteProject = read(getters.canWriteProject);
    const canArchiveContext = read(getters.canArchiveContext);
    const canWriteContext = read(getters.canWriteContext);
    const canReadContext = read(getters.canReadContext);
    const canWriteProjectProgress = read(getters.canWriteProjectProgress);
    const canWriteContextProgress = read(getters.canWriteContextProgress);
    const canSeeArchivedProjects = read(getters.canSeeArchivedProjects);
    const canSeeArchivedContext = read(getters.canSeeArchivedContext);
    const canWriteDescriptionFields = read(getters.canWriteDescriptionFields);
    const canWriteOwnersAndGroups = read(getters.canWriteOwnersAndGroups);
    const canDeleteOwnershipTrueUser = read(getters.canDeleteOwnershipTrueUser);
    const canWriteOOC = read(getters.canWriteOOC);
    const canWriteOOR = read(getters.canWriteOOR);
    const canWriteSimoFilterSets = read(getters.canWriteSimoFilterSets);
    const canSeeMeetingPlanner = read(getters.canSeeMeetingPlanner);
    const canWriteOODR = read(getters.canWriteOODR);
    const canCreateTradeStudy = read(getters.canCreateTradeStudy);
    const canReadMainContext = read(getters.canReadMainContext);
    const canCompareDiscardTradeStudy = read(
      getters.canCompareDiscardTradeStudy
    );
    const canMergeRejectTradeStudyDelta = read(
      getters.canMergeRejectTradeStudyDelta
    );

    const isAdmin = read(getters.isAdmin);
    const isManager = read(getters.isManager);
    const isEditor = read(getters.isEditor);
    const isViewer = read(getters.isViewer);
    const canReadRevisions = read(getters.canReadRevisions);
    const canReadRevision = read(getters.canReadRevision);
    const canWriteRevisions = read(getters.canWriteRevisions);

    const canUpdateContextSyntax = read(getters.canUpdateContextSyntax);

    const canSeeOtherApps =
      read(getters.canSeeAdminApp) && read(getters.canSeeSetupApp);

    return {
      module: {
        getters,
        actions,
      },
      public: {
        dispatchLoadCurrentUserPermissions: dispatch(
          actions.loadCurrentUserPermissions
        ),
        dispatchGrantPermission: dispatch(actions.grantPermission),
        dispatchDenyPermission: dispatch(actions.denyPermission),
        dispatchClearPermissions: dispatch(actions.clearPermissions),
        dispatchClearProjectRelatedPermissions: dispatch(
          actions.clearProjectRelatedPermissions
        ),
        dispatchClearContextRelatedPermissions: dispatch(
          actions.clearContextRelatedPermissions
        ),

        getSimplifiedPermissions,
        isAdmin,
        isManager,
        isEditor,
        isViewer,
        canSeeAdminApp,
        canSeeSetupApp,
        canSeeOtherApps,
        canSeeProjectsApp,
        canSeeArchivedProjects,
        canSeeArchivedContext,
        canCreateProject,
        canArchiveContext,
        canCreateContext,
        canWriteProject,
        canWriteContext,
        canReadContext,
        canWriteProjectProgress,
        canWriteContextProgress,
        canWriteDescriptionFields,
        canWriteOwnersAndGroups,
        canDeleteOwnershipTrueUser,
        canWriteOOC,
        canWriteOOR,
        canWriteSimoFilterSets,
        canSeeMeetingPlanner,
        canWriteOODR,
        canCreateTradeStudy,
        canReadMainContext,
        canCompareDiscardTradeStudy,
        canMergeRejectTradeStudyDelta,
        canReadRevisions,
        canReadRevision,
        canWriteRevisions,
        canUpdateContextSyntax,
      },
    };
  },
});
