import {
  provide,
  InjectionKey,
  shallowRef,
  inject,
  onMounted,
  getCurrentInstance,
  onBeforeUnmount,
  ComponentInternalInstance,
  Component,
} from 'vue';

const registryInjectionKey = Symbol('registrable') as InjectionKey<{
  register: (vm: ComponentInternalInstance) => void;
  unregister: (vm: ComponentInternalInstance) => void;
}>;

export const useRegistry = <T extends Component>() => {
  type CompInstance = ComponentInternalInstance & { proxy: T };
  const registry = shallowRef<CompInstance[]>([]);
  const register = (registrant: CompInstance) => {
    registry.value.push(registrant);
  };
  const unregister = (registrant: CompInstance) => {
    const index = registry.value.indexOf(registrant);
    if (index < 0) return;
    registry.value.splice(index, 1);
  };

  provide(registryInjectionKey, {
    register,
    unregister,
  });

  return { registry };
};

export const useRegistrant = () => {
  const registry = inject(registryInjectionKey);
  if (!registry) {
    throw new Error('useRegistrant() called without registry parent.');
  }
  const { register, unregister } = registry;

  const currentInstance = getCurrentInstance();

  onMounted(() => register(currentInstance));
  onBeforeUnmount(() => unregister(currentInstance));
};
