import { jwtDecode } from 'jwt-decode';
import auth0 from 'auth0-js';
import router from '@/router';

export const STORAGE_REDIRECT_URL = 'sec-redirect-url';
const STORAGE_KEY_ID_TOKEN = 'sec-id-token';
const STORAGE_KEY_ACCESS_TOKEN_KEY = 'sec-access-token';

const URL_PARAMETER_ACCESS_TOKEN = 'access_token';
const URL_PARAMETER_ID_TOKEN = 'id_token';

const CLIENT_ID = window.__env.VUE_APP_AUTH0_CLIENT_ID;
const CLIENT_DOMAIN = window.__env.VUE_APP_AUTH0_DOMAIN;
const REDIRECT = `${window.__env.VUE_APP_CLIENT_HOST}/callback`;
const AUDIENCE = window.__env.VUE_APP_AUTH0_AUDIENCE;

const auth = new auth0.WebAuth({
  clientID: CLIENT_ID,
  domain: CLIENT_DOMAIN,
  audience: AUDIENCE,
});

interface DecodedIdToken {
  name: string;
  email: string;
  exp: number;
  nickname: string;
  picture: string; // URL
  sub: string; // ID
  org_id: string;
}

interface DecodedAccessToken {
  exp: number;
}

export function refresh() {
  return new Promise((resolve, reject) => {
    auth.renewAuth(
      {
        responseType: 'token id_token',
        redirectUri: REDIRECT,
      },
      (err, res) => {
        if (err) reject(login());
        else {
          setAccessToken(res.accessToken);
          setIdToken(res.idToken);
          resolve(res.accessToken);
        }
      }
    );
  });
}

export function login() {
  if (router.currentRoute.value.name !== 'auth-callback') {
    // Store the URL that the user entered before redirecting around to authentication.
    // This allows us to redirect back to the user's URL after authentication.
    localStorage.setItem(STORAGE_REDIRECT_URL, window.location.href);
  }
  auth.authorize({
    responseType: 'token id_token',
    redirectUri: REDIRECT,
  });
}

export function logout() {
  auth.logout({ returnTo: REDIRECT });
}

export function requireAuth(to, from, next) {
  if (!isLoggedIn()) {
    login();
  } else {
    next();
  }
}

const STORAGE_REFRESH_KEY = 'sec-refresh-performed';

export function wasRefreshPerformed() {
  return !!localStorage.getItem(STORAGE_REFRESH_KEY)?.length;
}

export function setRefreshPerformed() {
  return localStorage.setItem(STORAGE_REFRESH_KEY, 'true');
}

export function removeRefreshPerformed() {
  localStorage.removeItem(STORAGE_REFRESH_KEY);
}

export function getIdToken() {
  return localStorage.getItem(STORAGE_KEY_ID_TOKEN);
}

export function getAccessToken() {
  return localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN_KEY);
}

export function clearIdToken() {
  localStorage.removeItem(STORAGE_KEY_ID_TOKEN);
}

export function clearAccessToken() {
  localStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN_KEY);
}

function getParameterByName(name) {
  const match = RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function hasAuthParameters() {
  const accessToken = getParameterByName(URL_PARAMETER_ACCESS_TOKEN);
  const idToken = getParameterByName(URL_PARAMETER_ID_TOKEN);
  return !!(accessToken && idToken);
}

export function setAccessToken(token?: string) {
  const accessToken = token
    ? token
    : getParameterByName(URL_PARAMETER_ACCESS_TOKEN);
  localStorage.setItem(STORAGE_KEY_ACCESS_TOKEN_KEY, accessToken);
}

export function setIdToken(token?: string) {
  const idToken = token ? token : getParameterByName(URL_PARAMETER_ID_TOKEN);
  localStorage.setItem(STORAGE_KEY_ID_TOKEN, idToken);
}

export function isLoggedIn() {
  const idData = getDecodedIdToken();
  return !!idData && !isTokenExpired(idData);
}

function getTokenExpirationDate(idData: DecodedIdToken) {
  if (!idData.exp) return null;
  const date = new Date(0);
  date.setUTCSeconds(idData.exp);
  return date;
}

function isTokenExpired(idData: DecodedIdToken) {
  const expirationDate = getTokenExpirationDate(idData);
  return expirationDate < new Date();
}

function getDecodedIdToken() {
  const idToken = getIdToken();
  if (!idToken) return null;
  return jwtDecode(idToken) as DecodedIdToken;
}

export function getDecodedAccessToken() {
  const accessToken = getAccessToken();
  if (!accessToken) return null;
  return jwtDecode(accessToken) as DecodedAccessToken;
}

export function getUserId() {
  const idData = getDecodedIdToken();
  return idData?.sub;
}

export function getExpirationTime() {
  const idData = getDecodedAccessToken();
  return idData?.exp;
}

export function getUserData() {
  const idData = getDecodedIdToken();
  return {
    id: idData.sub,
    email: idData.email,
    name: idData.name,
  };
}

export function getIDsForRecentWorkCards() {
  const idData = getDecodedIdToken();
  return idData.sub.concat(idData.org_id);
}
