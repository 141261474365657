export interface Syntax {
  id: string;
  name: string;
  description: string;
  published: boolean;
  archived: boolean;
  aspect: SyntaxAspectType;
  numbers_only?: boolean;
}

export interface SyntaxDraft {
  id?: string;
  name: string;
  description: string;
  published: boolean;
  archived: boolean;
}

export type SyntaxAspectType =
  | 'functional'
  | 'location'
  | 'product'
  | 'type'
  | 'user_defined';

type SyntaxAspectPrefix = '=' | '+' | '-' | '%' | '#';

interface SyntaxAspect {
  type: SyntaxAspectType;
  prefix: SyntaxAspectPrefix;
  name: string;
  sub: string;
}

export const aspects: SyntaxAspect[] = [
  {
    type: 'functional',
    prefix: '=',
    name: 'Functional (=)',
    sub: 'e',
  },
  {
    type: 'location',
    prefix: '+',
    name: 'Location (+)',
    sub: 's',
  },
  {
    type: 'product',
    prefix: '-',
    name: 'Product (-)',
    sub: 'm',
  },
  {
    type: 'type',
    prefix: '%',
    name: 'Type (%)',
    sub: 'p',
  },
  {
    type: 'user_defined',
    prefix: '#',
    name: 'User defined (#)',
    sub: 'h',
  },
];
