import { createModule } from '../utils/factories';
import { resourceRelationship, resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import relationManager from '../components/relationManager';
import processingTracker from '../components/processingTracker';
import importFile from './importFile';
import jv from '../components/jv';
import archiver from '../components/archiver';
import { api } from '@/services/api';
import { extractPollingId } from '@/utils/path';
import { JsonApiResource } from '@/models/api';
import { extractIdFromLinkRelated } from '@/utils/jvTools';
import { canSeeRevisionRequestType } from '@/utils/constants';

const syntaxUpdatePaths = {
  start: 'syntax_update/start',
  finish: 'syntax_update/finish',
  cancel: 'syntax_update/cancel',
};

export default createModule({
  path: 'context',
  resourceProfile: resources.contexts,
  components: [
    archiver,
    crud,
    indexManager,
    jv,
    processingTracker,
    relationManager,
  ],
  modules: [importFile],
  setup({ getAccessors, components, modules, resourceProfile }) {
    const getters = {
      relatedSyntaxId:
        () =>
        (contextId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            contextId,
            'syntax'
          );
        },
      relatedRootsNodeId:
        () =>
        (contextId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            contextId,
            'root_object_occurrence'
          );
        },
      relatedProjectId:
        () =>
        (contextId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            contextId,
            'project'
          );
        },
      hasObsoleteSyntax:
        () =>
        (contextId: string): boolean => {
          return !!components.$crud.public.getRelationshipMetaProperty(
            contextId,
            'syntax',
            'obsolete'
          );
        },
      tradeStudyId:
        () =>
        (contextId: string): string => {
          return components.$crud.public.getRelationshipId(
            contextId,
            resourceRelationship(resourceProfile)('trade_study')
          ) as string;
        },
      baseContextId:
        () =>
        (contextId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            contextId,
            'base_context'
          );
        },
    };
    const actions = {
      async loadActiveNonTradeStudyContext(
        context,
        payload: { projectId: string }
      ) {
        await components.$indexManager.public.dispatchLoadPaginatedResource({
          filterProjectId: payload.projectId,
          filterArchived: false,
          filterArchivedOrHasArchivedContent: false,
          filterNotTradeStudyContext: true,
          requestType: canSeeRevisionRequestType(payload.projectId),
          pageInfo: {
            pageSize: 1,
            page: 1,
          },
        });
      },
      async importContextCore(
        context,
        payload: Parameters<
          typeof modules.importFile.public.dispatchImportContextCore
        >[0]
      ) {
        return modules.importFile.public
          .dispatchImportContextCore(payload)
          .then((res) => {
            return {
              id: extractIdFromLinkRelated(
                res?.data?.relationships?.target?.links?.related
              ),
            };
          });
      },
      async importContextSimo(
        context,
        payload: Parameters<
          typeof modules.importFile.public.dispatchImportContextSimo
        >[0]
      ) {
        return modules.importFile.public.dispatchImportContextSimo(payload);
        // TODO
        // .then(() => {
        //   return { id: context._jv.id };
        // });
      },
      async duplicateContext(
        context,
        payload: { contextId: string; projectId: string; name: string }
      ) {
        const path = `/async/${resourceProfile.path}/${payload.contextId}/copy`;
        const response = await api.post(path, {
          data: {
            type: resourceProfile.type,
            attributes: {
              name: payload.name,
            },
            relationships: {
              project: {
                data: {
                  type: resources.projects.type,
                  id: payload.projectId,
                },
              },
            },
          },
        });
        const pollLocation = response.headers.location;
        const pollId = extractPollingId(pollLocation);
        if (!pollId) throw new Error('Unexpected response');
        const pollingPromise: Promise<{ data?: { data?: JsonApiResource } }> =
          context.dispatch(
            'polling/addJob',
            {
              id: pollId,
              url: pollLocation,
            },
            { root: true }
          );
        return pollingPromise.then((response) => {
          const copiedContextId = response.data.data.id;
          // refetch the context:
          // committing to jv store is not sufficient because
          // polling returns context without relationships included
          return components.$crud.public
            .dispatchLoadSingleResource({
              resourceId: copiedContextId,
            })
            .then(() => ({ id: copiedContextId }));
        });
      },
      async updateSyntax(
        context,
        payload: { contextId: string; syntaxId: string }
      ) {
        const path = `async/${syntaxUpdatePaths.start}/${payload.contextId}/${payload.syntaxId}`;
        const response = await api.post(path);

        const pollLocation = response.headers.location;
        const pollId = extractPollingId(pollLocation);
        if (!pollId) throw new Error('Unexpected response');
        const pollingPromise: Promise<{ data?: { data?: JsonApiResource } }> =
          context.dispatch(
            'polling/addJob',
            {
              id: pollId,
              url: pollLocation,
            },
            { root: true }
          );
        return pollingPromise;
      },
      finishSyntaxUpdate(context, payload: { contextId: string }) {
        const path = `${syntaxUpdatePaths.finish}/${payload.contextId}`;
        return api.post(path);
      },
      async cancelSyntaxUpdate(context, payload: { contextId: string }) {
        const path = `async/${syntaxUpdatePaths.cancel}/${payload.contextId}`;
        const response = await api.post(path);

        const pollLocation = response.headers.location;
        const pollId = extractPollingId(pollLocation);
        if (!pollId) throw new Error('Unexpected response');
        const pollingPromise: Promise<{ data?: { data?: JsonApiResource } }> =
          context.dispatch(
            'polling/addJob',
            {
              id: pollId,
              url: pollLocation,
            },
            { root: true }
          );
        return pollingPromise;
      },
    };
    const { read, dispatch } = getAccessors();
    return {
      module: {
        getters,
        actions,
      },
      public: {
        ...components.$processingTracker.public,
        ...components.$crud.public,
        ...components.$archiver.public,
        ...components.$indexManager.public,
        ...components.$relationManager.public,
        getRelatedProjectId: read(getters.relatedProjectId),
        getRelatedRootsNodeId: read(getters.relatedRootsNodeId),
        getRelatedSyntaxId: read(getters.relatedSyntaxId),
        getHasObsoleteSyntax: read(getters.hasObsoleteSyntax),
        getTradeStudyId: read(getters.tradeStudyId),
        getBaseContextId: read(getters.baseContextId),
        dispatchLoadActiveNonTradeStudyContext: dispatch(
          actions.loadActiveNonTradeStudyContext
        ),
        dispatchImportContextCore: dispatch(actions.importContextCore),
        dispatchImportContextSimo: dispatch(actions.importContextSimo),
        dispatchDuplicateContext: dispatch(actions.duplicateContext),

        dispatchUpdateSyntax: dispatch(actions.updateSyntax),
        dispatchFinishSyntaxUpdate: dispatch(actions.finishSyntaxUpdate),
        dispatchCancelSyntaxUpdate: dispatch(actions.cancelSyntaxUpdate),
      },
    };
  },
});
