<script setup lang="ts">
import { computed } from 'vue';
import { useCurrentElement, useResizeObserver } from '@vueuse/core';

const emit = defineEmits<{
  (e: 'notify'): void;
}>();

const currentElement = useCurrentElement();
const parentElement = computed(
  () => (currentElement.value as HTMLElement)?.parentElement
);
useResizeObserver(parentElement, () => emit('notify'));
</script>

<template>
  <span class="resize-observer" />
</template>

<style lang="scss" scoped>
.resize-observer {
  display: none;
}
</style>
