import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import indexManager from '../components/indexManager';
import progressModel from './progressModel';
import { ProgressModel } from '@/models/progressModel';

export default createModule({
  path: 'progressStep',
  resourceProfile: resources.progressStep,
  components: [indexManager],
  modules: [progressModel],
  setup({ modules }) {
    return {
      module: {},
      public: {
        getFullResource: (progressModelId: string) => {
          const progressModel: ProgressModel =
            modules.progressModel.public.getSimplifiedResourceSet()[
              progressModelId
            ];
          return progressModel.progress_steps
            .slice()
            .sort((a, b) => a.order - b.order);
        },
      },
    };
  },
});
