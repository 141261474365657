<template>
  <v-dialog
    :modelValue="showDialog"
    :max-width="maxWidth"
    @update:model-value="handleStateChange"
  >
    <v-card :class="resourceTablePanelPreventCloseClass">
      <v-card-title v-if="title" class="text-h5 justify-center">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="text" class="break-spaces">
        {{ text }}
      </v-card-text>
      <slot name="default" />
      <v-card-actions class="py-4">
        <v-spacer />
        <v-btn
          variant="text"
          :color="cancelButtonColor"
          :disabled="disableButtons"
          @click="onButtonPress(false)"
          @keydown.esc="onButtonPress(false)"
        >
          <v-icon v-if="cancelButtonIcon" :start="!!cancelButtonText">
            {{ cancelButtonIcon }}
          </v-icon>
          {{ cancelButtonText }}
        </v-btn>
        <v-btn
          data-spec="confirm-btn"
          variant="flat"
          :color="okButtonColor"
          :disabled="disableButtons"
          @click="onButtonPress(true)"
        >
          <v-icon v-if="okButtonIcon" :start="!!okButtonText">
            {{ okButtonIcon }}
          </v-icon>
          {{ okButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import { resourceTablePanelPreventCloseClass } from '@/components/resourceTable/resourceTablePanel.vue';

@Options({
  name: 'ConfirmDialog',
  components: {},
  emits: ['update:isDialogOpen'],
})
export default class ConfirmDialog extends Vue {
  @Prop({ default: null }) title?: string | null;
  @Prop({ default: null }) text?: string | null;
  @Prop({ default: 'Cancel' }) cancelButtonText?: string;
  @Prop({ default: 'Ok' }) okButtonText?: string;
  @Prop({ default: 'error' }) cancelButtonColor?: string;
  @Prop({ default: 'success' }) okButtonColor?: string;
  @Prop({ default: null }) cancelButtonIcon?: string | null;
  @Prop({ default: null }) okButtonIcon?: string | null;
  @Prop({ default: 360 }) maxWidth?: string | number;
  @Prop(Boolean) disableButtons: boolean;

  showDialog = false;
  resolve = null;
  resourceTablePanelPreventCloseClass = resourceTablePanelPreventCloseClass;

  onButtonPress(val) {
    if (this.disableButtons) return;
    this.resolve(val);
    this.showDialog = false;
  }

  handleStateChange(val: boolean) {
    if (!val) return this.onButtonPress(false);
    this.showDialog = true;
  }

  handleEnterKey(e: KeyboardEvent) {
    if (e.code !== 'Enter') return;
    this.onButtonPress(true);
  }

  public confirm() {
    return new Promise<boolean>((resolve) => {
      this.showDialog = true;
      this.resolve = resolve;
    });
  }

  @Watch('showDialog', { immediate: true })
  onShowDialogChange(value: boolean) {
    if (value) window.addEventListener('keydown', this.handleEnterKey);
    else window.removeEventListener('keydown', this.handleEnterKey);
    this.$emit('update:isDialogOpen', value);
  }
}
</script>
<style scoped>
.break-spaces {
  white-space: break-spaces;
}
</style>
