import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { ComponentType } from './_types';
import jv from './jv';
import crud, { CrudAccessors } from './crud';
import processingTracker from './processingTracker';
import relationManager from './relationManager';
import treeIndexManager from './treeIndexManager';
import treeOperations from './treeOperations';
import treeSelectionManager from './treeSelectionManager';
import { JsonApiIdentification } from '@/models/api';
import {
  getStructuredSubtree,
  applyTreeDataOperation,
  traverseSubtreeRelations,
  parentChain,
  isTempNodeId,
  TreeIdentification,
  treeId,
} from '@/utils/tree';
import { utils as jvUtils } from 'jsonapi-vuex';
import { api } from '@/services/api';
import { extractPollingId } from '@/utils/path';
import {
  TreeDataNode,
  TreeOperationType,
  TreeOperationAdd,
} from '@/models/treeStructure';
import { JVTopLevelSingleResource } from '@/models/jv';

export type TreeAccessors = ModuleComponentPublicAccessors<
  ReturnType<typeof treeComponent>
>;

type CrudLoadSingleResourcePayload = Parameters<
  CrudAccessors['dispatchLoadSingleResource']
>[0];
type CrudUpdatePayload = Parameters<CrudAccessors['dispatchUpdateResource']>[0];
type CrudCreatePayload = Parameters<CrudAccessors['dispatchCreateResource']>[0];

interface State {
  trees: Partial<{
    [treeId: string]: {
      rootNodeId: string;
      subRootsToLoad: Set<string>;
      loadingSubRoots: Set<string>;
      expandedNodes: Set<string>;
    };
  }>;
}

type TreeComponentConfig = {
  includeRelationships?: string[];
  wrapCreate?: (
    promise: ReturnType<CrudAccessors['dispatchCreateResource']>,
    payload: {
      tree: TreeIdentification;
    }
  ) => ReturnType<CrudAccessors['dispatchCreateResource']>;
  wrapUpdate?: (
    promise: ReturnType<CrudAccessors['dispatchUpdateResource']>,
    payload: {
      tree: TreeIdentification;
      targetId: string;
    }
  ) => ReturnType<CrudAccessors['dispatchUpdateResource']>;
  wrapDelete?: (
    promise: ReturnType<CrudAccessors['dispatchDeleteResource']>,
    payload: {
      tree: TreeIdentification;
      targetId: string;
    }
  ) => ReturnType<CrudAccessors['dispatchDeleteResource']>;
};

const treeComponent = (config: TreeComponentConfig = {}) =>
  createModuleComponent({
    type: ComponentType.Tree,
    dependencies: [
      jv,
      crud,
      processingTracker,
      relationManager,
      treeIndexManager,
      treeOperations,
      treeSelectionManager,
    ],
    setup: ({ getAccessors, resourceProfile, components }) => {
      const state = (): State => ({
        trees: {},
      });

      const getters = {
        trees: (state: State) => state.trees,
        treeRootNodeId: (state: State) => (tree: TreeIdentification) =>
          state.trees[treeId(tree)]?.rootNodeId || null,
        treeLoadingSubRoots: (state: State) => (tree: TreeIdentification) =>
          state.trees[treeId(tree)]?.loadingSubRoots || null,
        treeData:
          () =>
          (tree: TreeIdentification): TreeDataNode[] => {
            const stableTreeData =
              components.$treeIndexManager.protected.getTreeData(tree);
            const operations = [
              ...components.$treeOperations.protected
                .getTreeOperations(tree)
                .values(),
            ];
            return operations.reduce(
              (treeData, op) => applyTreeDataOperation(treeData, op)[0],
              stableTreeData
            );
          },
        structuredTree:
          (state: State) =>
          (tree: TreeIdentification, treeInitialData: TreeDataNode[] = []) => {
            if (!state.trees[treeId(tree)]) return null;
            const { rootNodeId, subRootsToLoad, expandedNodes } =
              state.trees[treeId(tree)];
            let treeData = [
              ...treeInitialData,
              ...read(getters.treeData)(tree),
            ];
            treeData = [
              ...new Map(treeData.map((item) => [item['id'], item])).values(),
            ];
            const rootNode = treeData.find((node) => node.id === rootNodeId);
            return getStructuredSubtree(
              rootNode,
              treeData,
              subRootsToLoad,
              expandedNodes
            );
          },
      };

      const mutations = {
        ensureTreeIndexInitialized(
          state: State,
          payload: {
            tree: TreeIdentification;
            rootNodeId: string;
          }
        ) {
          if (!state.trees[treeId(payload.tree)]) {
            state.trees[treeId(payload.tree)] = {
              rootNodeId: payload.rootNodeId,
              subRootsToLoad: new Set(),
              loadingSubRoots: new Set(),
              expandedNodes: new Set(),
            };
          } else {
            state.trees[treeId(payload.tree)].rootNodeId = payload.rootNodeId;
          }
        },
        addTreeSubRootToLoad(
          state: State,
          payload: { tree: TreeIdentification; id: string }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          const { id } = payload;
          state.trees[treeId(payload.tree)].subRootsToLoad.add(id);
        },
        removeSubTreeToLoad(
          state: State,
          payload: { tree: TreeIdentification; id: string }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          const { id } = payload;
          state.trees[treeId(payload.tree)].subRootsToLoad.delete(id);
        },
        resetSubTreeToLoad(
          state: State,
          payload: { tree: TreeIdentification }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          state.trees[treeId(payload.tree)].subRootsToLoad.clear();
        },
        addTreeNodeLoading(
          state: State,
          payload: { tree: TreeIdentification; id: string }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          const { id } = payload;
          state.trees[treeId(payload.tree)].loadingSubRoots.add(id);
        },
        removeTreeNodeLoading(
          state: State,
          payload: { tree: TreeIdentification; id: string }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          const { id } = payload;
          state.trees[treeId(payload.tree)].loadingSubRoots.delete(id);
        },
        treeAddToExpandedNodes(
          state: State,
          payload: { tree: TreeIdentification; id: string }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          state.trees[treeId(payload.tree)].expandedNodes.add(payload.id);
        },
        treeRemoveFromExpandedNodes(
          state: State,
          payload: { tree: TreeIdentification; id: string }
        ) {
          if (!state.trees[treeId(payload.tree)]) return;
          state.trees[treeId(payload.tree)].expandedNodes.delete(payload.id);
        },
        setTrees(state: State, payload: any) {
          if (state.trees.length) return;
          state.trees = payload;
        },
      };

      const wrapCreate: TreeComponentConfig['wrapCreate'] =
        config.wrapCreate || ((promise) => promise);

      const wrapUpdate: TreeComponentConfig['wrapUpdate'] =
        config.wrapUpdate || ((promise) => promise);

      const wrapDelete: TreeComponentConfig['wrapUpdate'] =
        config.wrapUpdate || ((promise) => promise);

      const actions = {
        async createResource(
          context,
          payload: CrudCreatePayload & {
            tree: TreeIdentification;
            actionId?: string;
          }
        ): ReturnType<CrudAccessors['dispatchCreateResource']> {
          const crudPayload: CrudCreatePayload = {
            ...payload,
            include: config.includeRelationships,
          };
          const result = await wrapCreate(
            components.$crud.public.dispatchCreateResource(crudPayload),
            { tree: payload.tree }
          );
          if (payload.actionId && payload.tree) {
            const operation = components.$treeOperations.protected
              .getTreeOperations(payload.tree)
              .get(payload.actionId) as TreeOperationAdd;
            components.$treeIndexManager.protected.dispatchApplyTreeOperation({
              tree: payload.tree,
              operation: { ...operation, id: result.id },
            });
          }
          return result;
        },
        async updateResource(
          context,
          payload: CrudUpdatePayload & { tree: TreeIdentification }
        ): ReturnType<CrudAccessors['dispatchUpdateResource']> {
          let actionId = null;
          if (payload.tree) {
            actionId =
              await components.$treeOperations.public.dispatchAddTreeOperation({
                tree: payload.tree,
                operation: {
                  operationType: TreeOperationType.Patch,
                  id: payload.resourceId,
                  attributes: payload.resource,
                },
              });
          }
          const crudPayload: CrudUpdatePayload = {
            ...payload,
            include: config.includeRelationships,
          };
          try {
            await wrapUpdate(
              components.$crud.public.dispatchUpdateResource(crudPayload),
              {
                tree: payload.tree,
                targetId: payload.resourceId,
              }
            );
          } finally {
            if (payload.tree) {
              components.$treeOperations.public.dispatchRemoveTreeOperation({
                tree: payload.tree,
                actionId,
              });
            }
          }
        },
        async deleteResource(
          context,
          payload: Parameters<CrudAccessors['dispatchDeleteResource']>[0] & {
            tree: TreeIdentification;
          }
        ): ReturnType<CrudAccessors['dispatchDeleteResource']> {
          let actionId = null;
          if (payload.tree) {
            actionId =
              await components.$treeOperations.public.dispatchAddTreeOperation({
                tree: payload.tree,
                operation: {
                  operationType: TreeOperationType.Remove,
                  id: payload.resourceId,
                },
              });
          }
          try {
            await wrapDelete(
              components.$crud.public.dispatchDeleteResource(payload),
              {
                tree: payload.tree,
                targetId: payload.resourceId,
              }
            );
            if (payload.tree) {
              const operation = components.$treeOperations.protected
                .getTreeOperations(payload.tree)
                .get(actionId);
              components.$treeIndexManager.protected.dispatchApplyTreeOperation(
                {
                  tree: payload.tree,
                  operation,
                }
              );
            }
          } finally {
            if (payload.tree) {
              components.$treeOperations.public.dispatchRemoveTreeOperation({
                tree: payload.tree,
                actionId,
              });
            }
          }
        },
        loadTreeRootNode(
          context,
          payload: {
            tree: TreeIdentification;
            rootNodeId: string;
            expandAll?: boolean;
            forceInit?: boolean;
          }
        ) {
          const { tree, rootNodeId, expandAll, forceInit } = payload;
          const isAlreadyCached =
            read(getters.treeRootNodeId)(tree) === rootNodeId;
          if (!isAlreadyCached || forceInit) {
            commit(mutations.ensureTreeIndexInitialized)({
              tree,
              rootNodeId,
            });
            components.$treeIndexManager.protected.commitResetTreeIndex({
              tree,
            });
            components.$treeIndexManager.protected.commitUpdateTreeIndex({
              tree,
              indexPatch: [
                {
                  id: rootNodeId,
                  parentId: null,
                  position: 1,
                },
              ],
            });
          }
          const subRootsToExpand = new Set<string>(
            read(getters.trees)()[treeId(tree)].expandedNodes.values() || []
          );
          return dispatch(actions.treeExpandSubtree)({
            tree,
            rootNodeId,
            expandAll,
            subRootsToExpand,
            refetch: isAlreadyCached,
          });
        },
        loadSubTree(
          context,
          payload: {
            tree: TreeIdentification;
            resourceId: string;
            depth?: number;
          }
        ) {
          commit(mutations.addTreeNodeLoading)({
            tree: payload.tree,
            id: payload.resourceId,
          });
          const depth = payload.depth || 1;
          const isRevisionComparison = !!payload.tree.comparisonBaseRevisionId;
          const loadParams: CrudLoadSingleResourcePayload = isRevisionComparison
            ? {
                depth,
                include: [...config.includeRelationships, 'deleted_components'],
                basePath: `/revision_comparison/${payload.tree.comparisonBaseRevisionId}/${payload.tree.contextId}/object_occurrences/${payload.resourceId}`,
              }
            : {
                depth,
                include: config.includeRelationships,
                resourceId: payload.resourceId,
              };
          return components.$crud.public
            .dispatchLoadSingleResource(loadParams)
            .then((rawOoc: JVTopLevelSingleResource) => {
              const childRelList = traverseSubtreeRelations(rawOoc, depth);
              const jvGet = components.$jv.protected.get;
              const childInSet = childRelList.filter((ch) =>
                jvGet({ id: ch.id, type: resourceProfile.type })
              );
              const indexPatch = childInSet.map(
                ({ id, parentId, comparisonResult }) => ({
                  id,
                  parentId,
                  position: jvGet({ id, type: resourceProfile.type })
                    .position as number,
                  comparisonResult,
                })
              );
              components.$treeIndexManager.protected.commitUpdateTreeIndex({
                tree: payload.tree,
                indexPatch,
                replaceLevels: true,
              });
              const deepestLoadedLevelIds = childRelList
                .filter((item) => item.depth === 1)
                .map((item) => item.id);
              const deepestLoadedLevelChildren = childRelList.filter(
                (item) => item.depth === 0
              );
              const deepestLoadedSubRootIds = deepestLoadedLevelIds.filter(
                (id) =>
                  deepestLoadedLevelChildren.some((i) => i.parentId === id)
              );
              // Resolve with nodes from deepest loaded level that are not leaves (has children)
              return deepestLoadedSubRootIds;
            })
            .finally(() => {
              commit(mutations.removeTreeNodeLoading)({
                tree: payload.tree,
                id: payload.resourceId,
              });
            });
        },
        // TODO: subTreeIds (of deleted nodes)
        async treeExpandSubtree(
          context,
          payload: {
            tree: TreeIdentification;
            rootNodeId: string;
            expandAll?: boolean;
            subRootsToExpand?: Set<string>;
            refetch?: boolean;
          }
        ) {
          const {
            rootNodeId,
            expandAll,
            subRootsToExpand = new Set(),
            refetch = false,
          } = payload;
          const loadExpandedSubtree = async (nodeId) => {
            const subTreeIds = (await dispatch(actions.loadSubTree)({
              tree: payload.tree,
              resourceId: nodeId,
              depth: 1,
            })) as string[];
            commit(mutations.treeAddToExpandedNodes)({
              tree: payload.tree,
              id: nodeId,
            });
            commit(mutations.removeSubTreeToLoad)({
              tree: payload.tree,
              id: nodeId,
            });
            if (!refetch)
              subTreeIds.forEach((id) =>
                commit(mutations.addTreeSubRootToLoad)({
                  tree: payload.tree,
                  id,
                })
              );
            const subTreeIdsToLoad = expandAll
              ? subTreeIds
              : subTreeIds.filter((id) => subRootsToExpand.has(id));
            const responses = await Promise.all(
              subTreeIdsToLoad.map(loadExpandedSubtree)
            );
            const notLoadedSubRoots = subTreeIds.filter(
              (id) => !subTreeIdsToLoad.includes(id)
            );
            return notLoadedSubRoots.concat(responses.flat());
          };
          const notLoadedSubRoots = await loadExpandedSubtree(rootNodeId);
          if (refetch)
            notLoadedSubRoots.forEach((id) =>
              commit(mutations.addTreeSubRootToLoad)({
                tree: payload.tree,
                id,
              })
            );
          return notLoadedSubRoots;
        },
        treeExpandNode(
          context,
          payload: { tree: TreeIdentification; id: string }
        ) {
          const { tree, id } = payload;
          if (!read(getters.trees)()[treeId(tree)]) return;
          if (read(getters.trees)()[treeId(tree)].subRootsToLoad.has(id)) {
            return dispatch(actions.treeExpandSubtree)({
              tree,
              rootNodeId: id,
            });
          } else {
            commit(mutations.treeAddToExpandedNodes)({ tree, id });
          }
        },
        treeCollapseNode(
          context,
          payload: { tree: TreeIdentification; id: string }
        ) {
          commit(mutations.treeRemoveFromExpandedNodes)(payload);
        },
        async treeExpandPathToNode(
          context,
          payload: { tree: TreeIdentification; id: string }
        ) {
          const { tree, id } = payload;
          const treeData = read(getters.treeData)(tree);
          const isNodeLoaded = treeData.some((node) => node.id === id);
          if (isNodeLoaded) {
            const parents = parentChain(treeData, id);
            return Promise.all(
              parents.map((parentId) =>
                dispatch(actions.treeExpandNode)({ tree, id: parentId })
              )
            );
          }
          if (isTempNodeId(id)) return;
          const path: JsonApiIdentification[] = await dispatch(
            actions.loadTreePath
          )({
            fromId: id,
            toId: read(getters.trees)()[treeId(tree)].rootNodeId,
          });
          for (const node of path.slice(1).reverse()) {
            await dispatch(actions.treeExpandNode)({ tree, id: node.id });
          }
        },
        async loadTreePath(
          context,
          payload: { fromId: string; toId: string }
        ): Promise<JsonApiIdentification[]> {
          const type = resourceProfile.type;
          const { fromId, toId } = payload;
          const { data } = await api.get<JsonApiIdentification[]>(
            `/utils/path/from/${type}/${fromId}/to/${type}/${toId}`,
            { data: {} }
          );
          return data;
        },
        loadFullTree(
          context,
          payload: { tree: TreeIdentification; rootNodeId: string }
        ) {
          return dispatch(actions.loadTreeRootNode)({
            ...payload,
            expandAll: true,
          });
        },
        async copySubtree(
          context,
          payload: {
            tree: TreeIdentification;
            resourceId: string;
            position?: number;
            parentId: string;
          }
        ) {
          let copiedNodeId: string;
          const { tree, resourceId, parentId, position = 1 } = payload;
          dispatch(actions.treeExpandNode)({ tree, id: parentId });
          const path = `async/${resourceProfile.path}/${resourceId}/copy`;
          const data = { type: resourceProfile.type, id: parentId };
          const { ...node } = read(getters.treeData)(tree).find(
            (n) => n.id === resourceId
          );
          const actionId =
            await components.$treeOperations.public.dispatchAddTreeOperation({
              tree,
              operation: {
                operationType: TreeOperationType.Add,
                parentId,
                position,
                attributes: { ...node, parentId, position },
              },
            });
          components.$treeSelectionManager.public.dispatchSelectNode({
            tree,
            id: actionId,
          });
          try {
            const response = await api.post(`/${path}`, { data });
            const pollLocation = response.headers.location;
            const pollId = extractPollingId(pollLocation);
            if (pollId) {
              components.$processingTracker.public.dispatchAddProcessingItem({
                id: actionId,
                status: 'copy',
              });

              const results = await context.dispatch(
                'polling/addJob',
                {
                  id: pollId,
                  url: pollLocation,
                  relationshipKey: resourceProfile.type,
                  relationshipId: resourceId,
                  relatedId: pollId,
                },
                { root: true }
              );
              const copiedNode = results.data.data;
              const baseJvNode = components.$jv.protected.get({
                id: resourceId,
                type: resourceProfile.type,
              });
              const copiedJvNode = jvUtils.jsonapiToNorm(
                copiedNode
              ) as JVTopLevelSingleResource;

              components.$jv.protected.commitAddRecords(copiedJvNode);
              copiedNodeId = copiedJvNode._jv.id;

              const resource = { position };
              await wrapUpdate(
                components.$crud.public.dispatchUpdateResource({
                  resourceId: copiedNodeId,
                  resource,
                }),
                {
                  tree: payload.tree,
                  targetId: copiedNodeId,
                }
              );
              const operation = components.$treeOperations.protected
                .getTreeOperations(tree)
                .get(actionId);
              components.$treeIndexManager.protected.dispatchApplyTreeOperation(
                { tree, operation: { ...operation, id: copiedNodeId } }
              );
              // TODO: check copiedJvNode components instead of baseJvNode after API fix
              const hasChildren = !!(
                baseJvNode._jv.relationships.components
                  ?.data as JsonApiIdentification[]
              )?.length;
              if (hasChildren) {
                commit(mutations.addTreeSubRootToLoad)({
                  tree,
                  id: copiedNodeId,
                });
              }
            } else {
              throw new Error('OOC copy polling location not provided');
            }
          } finally {
            components.$treeOperations.public.dispatchRemoveTreeOperation({
              tree,
              actionId,
            });
            components.$processingTracker.public.dispatchRemoveProcessingItem({
              id: actionId,
            });
          }
          return copiedNodeId;
        },
        async moveSubtree(
          context,
          payload: {
            tree: TreeIdentification;
            resourceId: string;
            parentId: string;
            position: number;
            syntaxElement?: string;
          }
        ) {
          const { tree } = payload;
          // needed for temp tree data position patches
          await dispatch(actions.treeExpandNode)({
            tree,
            id: payload.parentId,
          });
          const { position: oldPosition, parentId: oldParentId } = read(
            getters.treeData
          )(tree).find((node) => node.id === payload.resourceId);
          const { resourceId, parentId } = payload;
          let position = payload.position;
          if (parentId === oldParentId && position > oldPosition) {
            // desired position is smaller because of moved node
            position -= 1;
          }
          if (parentId === oldParentId && position === oldPosition) {
            // nothing to change
            return;
          }
          const actionId =
            await components.$treeOperations.public.dispatchAddTreeOperation({
              tree,
              operation: {
                operationType: TreeOperationType.Move,
                id: resourceId,
                parentId,
                position,
              },
            });
          components.$treeSelectionManager.public.dispatchSelectNode({
            tree,
            id: resourceId,
          });
          try {
            if (oldParentId !== parentId) {
              const updateRelationPayload = {
                resourceId,
                relationshipKey: resourceProfile.parentRelationship,
                relationshipId: parentId,
                syntaxElement: payload.syntaxElement,
              };
              await wrapUpdate(
                components.$relationManager.public.dispatchUpdateResourceRelation(
                  updateRelationPayload
                ),
                {
                  tree: payload.tree,
                  targetId: payload.resourceId,
                }
              );
            }
            await wrapUpdate(
              components.$crud.public.dispatchUpdateResource({
                resourceId,
                resource: { position },
              }),
              {
                tree: payload.tree,
                targetId: payload.resourceId,
              }
            );
            const operation = components.$treeOperations.protected
              .getTreeOperations(tree)
              .get(actionId);
            components.$treeIndexManager.protected.dispatchApplyTreeOperation({
              tree,
              operation,
            });
          } finally {
            components.$treeOperations.public.dispatchRemoveTreeOperation({
              tree,
              actionId,
            });
          }
        },
        setupTrees(context, payload) {
          commit(mutations.setTrees)(payload);
        },
      };

      const { read, dispatch, commit } = getAccessors<State>();

      return {
        module: {
          state,
          getters,
          mutations,
          actions,
        },
        protected: {
          dispatchApplyTreeOperation:
            components.$treeIndexManager.protected.dispatchApplyTreeOperation,
        },
        public: {
          getTreeData: read(getters.treeData),
          getStructuredTree: read(getters.structuredTree),
          getTreeLoadingSubRoots: read(getters.treeLoadingSubRoots),
          getTrees: read(getters.trees),

          dispatchLoadTreeRootNode: dispatch(actions.loadTreeRootNode),
          dispatchTreeExpandNode: dispatch(actions.treeExpandNode),
          dispatchTreeCollapseNode: dispatch(actions.treeCollapseNode),
          dispatchTreeExpandPathToNode: dispatch(actions.treeExpandPathToNode),
          dispatchLoadTreePath: dispatch(actions.loadTreePath),
          dispatchLoadFullTree: dispatch(actions.loadFullTree),
          dispatchCopySubtree: dispatch(actions.copySubtree),
          dispatchMoveSubtree: dispatch(actions.moveSubtree),
          dispatchSetupTrees: dispatch(actions.setupTrees),

          // OVERRIDES
          dispatchCreateResource: dispatch(actions.createResource),
          dispatchDeleteResource: dispatch(actions.deleteResource),
          dispatchUpdateResource: dispatch(actions.updateResource),

          // Tree Selection Manager (subcomponent)
          getSelectedNodeId:
            components.$treeSelectionManager.public.getSelectedNodeId,
          getSelectionManager:
            components.$treeSelectionManager.public.getMultipleSelectionManager,
          dispatchSelectNode:
            components.$treeSelectionManager.public.dispatchSelectNode,
          getActiveTree: components.$treeSelectionManager.public.getActiveTree,
          dispatchSetActiveTree:
            components.$treeSelectionManager.public.dispatchSetActiveTree,
          dispatchCreateManager:
            components.$treeSelectionManager.public.dispatchCreateManager,
          // Tree Operations (subcomponent)
          dispatchAddTreeOperation:
            components.$treeOperations.public.dispatchAddTreeOperation,
          dispatchRemoveTreeOperation:
            components.$treeOperations.public.dispatchRemoveTreeOperation,
        },
      };
    },
  });

export default treeComponent;
