import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import indexManager from '../components/indexManager';

export default createModule({
  path: 'permission',
  resourceProfile: resources.permissions,
  components: [indexManager],
  setup({ components }) {
    return {
      module: {},
      public: {
        ...components.$indexManager.public,
      },
    };
  },
});
