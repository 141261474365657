import { ApplicationType } from '@/enums/applicationType';
import { Application } from '@/models/application';
import { SubApplication } from '@/models/subApplication';
import { translate } from '@/localization';
import store from '@/store';
import {
  setupPalette,
  designPalette,
  adminPalette,
  launchpadPalette,
} from '@/colors/colorPalette';
import { RouteName } from '@/enums/routing';
import { RouteLocationNormalized } from 'vue-router';

const subApplicationsDesign = (
  route: RouteLocationNormalized
): SubApplication[] => [
  {
    id: 'core',
    name: translate('apps.design.topbar.tools.core'),
    icon: 'mdi-file-tree',
    tooltip: '<b>CO</b>mmon <b>R</b>eference designation <b>E</b>ditor',
    route: route.params.revisionId ? RouteName.RevisionCore : RouteName.Core,
  },
  {
    id: 'simo',
    name: translate('apps.design.topbar.tools.simo'),
    icon: 'mdi-grid',
    tooltip: '<b>S</b>ystems <b>I</b>nterface <b>MO</b>nitor',
    route: route.params.revisionId ? RouteName.RevisionSimo : RouteName.Simo,
    checkPermissions: (route: RouteLocationNormalized) => {
      if (!route.params.contextId) return { access: true };
      const context =
        store.$direct.context.getSimplifiedResourceSet()[
          route.params.contextId as string
        ];
      if (!context) return { access: true };
      return {
        access: !!context?.simo_classification_table,
        message: translate('apps.design.core.selectClassificationTable'),
      };
    },
  },
];

const subApplicationsSetup: SubApplication[] = [
  {
    id: 'ClassificationTablesManager',
    name: translate('apps.setup.topbar.tools.classification'),
    icon: 'mdi-alpha-a-box-outline',
    route: RouteName.ClassificationTables,
  },
  {
    id: 'SyntaxesManager',
    name: translate('apps.setup.topbar.tools.syntax'),
    icon: 'mdi-graph  mdi-rotate-270',
    route: RouteName.SyntaxManager,
  },
];

const subApplicationsAdmin: SubApplication[] = [
  {
    id: 'users',
    name: translate('apps.admin.topbar.tools.users'),
    icon: 'mdi-account-supervisor',
    route: RouteName.Users,
  },
];

export function getApplications(): Application[] {
  return [
    {
      type: ApplicationType.Design,
      route: RouteName.Design,
      name: 'Projects',
      icon: 'mdi-format-list-bulleted',
      color: 'appDesign',
      colors: designPalette,
      subApplications: subApplicationsDesign,
      checkPermissions: () => store.$direct.userPermission.canSeeProjectsApp(),
    },
    {
      type: ApplicationType.Setup,
      route: RouteName.Setup,
      name: translate('apps.setup.appName'),
      icon: 'mdi-settings-outline',
      color: 'appSetup',
      colors: setupPalette,
      subApplications: () => subApplicationsSetup,
      checkPermissions: () => store.$direct.userPermission.canSeeSetupApp(),
    },
    {
      type: ApplicationType.Admin,
      route: RouteName.Admin,
      name: translate('apps.admin.appName'),
      icon: 'mdi-account-settings-outline',
      color: 'appAdmin',
      colors: adminPalette,
      subApplications: () => subApplicationsAdmin,
      checkPermissions: () => store.$direct.userPermission.canSeeAdminApp(),
    },
    {
      type: ApplicationType.Launchpad,
      route: RouteName.Launchpad,
      name: translate('apps.launchpad.appName'),
      icon: 'mdi-rocket',
      color: 'appLaunchpad',
      colors: launchpadPalette,
    },
  ];
}

export function getApplicationByType(applicationType: ApplicationType) {
  return getApplications().find((app) => {
    return app.type === applicationType;
  }) as Application;
}

export function getSubApplicationByCurrentRoute(
  application: Application,
  currentRoute: RouteLocationNormalized
) {
  return application
    .subApplications?.(currentRoute)
    ?.find((subApp) => subApp.route === currentRoute.name);
}
