<template>
  <v-dialog :attach="attach" width="60%">
    <template #activator="{ props: activatorProps }">
      <button
        v-bind="activatorProps"
        type="button"
        class="syntax-selector__activator"
      >
        <label v-if="props.useLabel">Syntax</label>
        <v-textarea
          :model-value="selection ? selection.name : 'Please select syntax'"
          readonly
          :hide-details="hideDetails"
          variant="outlined"
          auto-grow
          rows="1"
          :rules="rules"
          :error-messages="errorMessages"
        />
      </button>
    </template>
    <template #default="{ isActive }">
      <SyntaxSelectorModal
        @close-modal="isActive.value = false"
        @update:syntaxSelection="handleSelection"
        :initialValue="selection"
        :is-selection-disabled="disableModalSelector"
        :resource-config="resourceConfig"
      />
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import SyntaxSelectorModal from '@/components/syntaxSelector/syntaxSelectorModal.vue';
import { computed, onMounted, ref } from 'vue';
import { ResourceListConfig } from '@/utils/resourceList';

const props = withDefaults(
  defineProps<{
    useLabel?: boolean;
    initialSelection: any;
    disableModalSelector?: boolean;
    errorMessages?: string[];
    resourceConfig?: ResourceListConfig;
    hideDetails?: boolean;
    attach?: boolean;
  }>(),
  {
    useLabel: true,
    disableModalSelector: false,
    errorMessages: () => [],
    resourceConfig: null,
    attach: true,
  }
);

onMounted(() => {
  if (props.initialSelection) selection.value = props.initialSelection;
});

const emits = defineEmits(['update:selectedSyntax']);

const selection = ref<any>(null);
const handleSelection = (selectedSyntax: any) => {
  selection.value = selectedSyntax;
  emits('update:selectedSyntax', selection.value);
};

const rules = computed(() => [selection.value || 'Syntax is required']);
</script>

<style scoped lang="scss">
.syntax-selector__activator {
  display: flex;
  flex-direction: column;
  width: 100%;

  :deep(.v-input) {
    width: 100%;
  }

  :deep(textarea) {
    cursor: pointer;
  }
}

:deep(.v-messages__message) {
  text-align: start;
}
</style>
