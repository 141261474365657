import { FlatTreeNode } from '@/models/treeStructure';
import TreeStructure from './treeStructure.vue';

export type EventMoveNode = ReturnType<
  InstanceType<typeof TreeStructure>['emitMoveNode']
>;
export type EventCloneNode = ReturnType<
  InstanceType<typeof TreeStructure>['emitCloneNode']
>;
export type EventRemoveNode = ReturnType<
  InstanceType<typeof TreeStructure>['emitRemoveNode']
>;
export type EventEditNode = ReturnType<
  InstanceType<typeof TreeStructure>['emitEditNode']
>;
export type EventCreateSibling = ReturnType<
  InstanceType<typeof TreeStructure>['emitCreateSibling']
>;
export type EventCreateChild = ReturnType<
  InstanceType<typeof TreeStructure>['emitCreateChildNode']
>;

export enum OocEditTypes {
  Name = 'name',
  Number = 'number',
  SystemTag = 'system_tag',
}

export type ItemSlotScope = {
  item: FlatTreeNode['item'];
  isCopied: boolean;
  isCut: boolean;
  isSelected: boolean;
  isHovered: boolean;

  actions: {
    canEdit: () => string | boolean;
    canCopy: () => string | boolean;
    canCut: () => string | boolean;
    canPaste: () => string | boolean;
    canDuplicate: () => string | boolean;
    canRemove: () => string | boolean;
    canEditNumber: () => string | boolean;
    canEditSystem: () => string | boolean;
    hasClassificationTable: () => boolean;

    select: ($event: MouseEvent) => void;
    editName: () => void;
    editNumber: () => void;
    editSystem: () => void;
    copy: () => void;
    cut: () => void;
    paste: () => void;
    duplicate: () => void;
    checkEventLogs: () => void;
    remove: () => void;
    showHiddenRelations: () => void;
  };
};
