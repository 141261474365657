type ObjectPropertyPath = string[];
export type ObjectMapping = [ObjectPropertyPath, string?];

export function useSimpleMapper<T extends object, R extends object>(
  sourceObject: T,
  mappings: ObjectMapping[]
): R {
  const result: Partial<R> = {};

  for (const [path, outputKey] of mappings) {
    let value: any = sourceObject;
    for (const key of path) {
      if (value && typeof value === 'object' && key in value) {
        value = value[key];
      } else {
        value = undefined;
        break;
      }
    }

    if (value !== undefined) {
      const resultKey = outputKey || path[path.length - 1];
      result[resultKey as keyof R] = value;
    }
  }

  return result as R;
}
