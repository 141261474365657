import mitt from 'mitt';

type EventType<TDict> = keyof TDict;
type EmitArgs<TDict, T extends EventType<TDict>> = TDict[T] extends undefined
  ? [T]
  : [T, TDict[T]];

interface EventBus<TDict extends Record<string, unknown>> {
  $emit<T extends EventType<TDict>>(...args: EmitArgs<TDict, T>): void;
  $on<T extends EventType<TDict>>(
    event: T,
    handler: (payload: TDict[T]) => void
  ): void;
  $off<T extends EventType<TDict>>(
    event?: T,
    handler?: (payload: TDict[T]) => void
  ): void;
}

export const createEventBus = <
  Events extends Record<string, unknown>,
>(): EventBus<Events> => {
  const emitter = mitt<Events>();

  return {
    $on: emitter.on,
    $off: emitter.off,
    $emit: emitter.emit as EventBus<Events>['$emit'],
  };
};
