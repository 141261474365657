import { reactive } from 'vue';

const STORAGE_KEY_PREFIX = 'sec-last-used';

type LastUsedStore<T = string> = {
  [storeId: string]: Array<T>;
};

const cache: LastUsedStore<unknown> = reactive({});

const getStorageKey = (userId: string, storeId: string) =>
  `${STORAGE_KEY_PREFIX}-${userId}-${storeId}`;

const ensureStoreCached = (storeKey: string) => {
  if (cache[storeKey]) return;
  const data = localStorage.getItem(storeKey);
  let store: LastUsedStore[string] = [];
  if (data) {
    try {
      store = data && JSON.parse(data);
    } catch {
      /* ignore parsing error */
    }
    if (!store || !Array.isArray(store)) {
      localStorage.removeItem(storeKey);
    }
  }
  cache[storeKey] = store;
  return store;
};

const unshiftToStore = <T = string>(
  newItem: T,
  storeKey: string,
  maxLength: number
) => {
  ensureStoreCached(storeKey);
  cache[storeKey] = [newItem, ...cache[storeKey]]
    .filter((item, index, all) => all.indexOf(item) === index)
    .slice(0, maxLength);
  updateStorage(storeKey);
};

const removeFromStore = <T = string>(
  itemToRemove: T | ((item: T) => boolean),
  storeKey: string
) => {
  const shouldRemoveItem =
    typeof itemToRemove === 'function'
      ? (itemToRemove as (item: T) => boolean)
      : (item: T) => item === itemToRemove;
  ensureStoreCached(storeKey);
  cache[storeKey] = cache[storeKey].filter(
    (item: T) => !shouldRemoveItem(item)
  );
  updateStorage(storeKey);
};

const updateStorage = (storeKey: string) => {
  const store = cache[storeKey];
  if (!store) return;
  if (!store.length) localStorage.removeItem(storeKey);
  else localStorage.setItem(storeKey, JSON.stringify(store));
};

export const useLastUsedStore = <T = string>(
  userId: string,
  storeId: string,
  maxLength: number
) => {
  const storeKey = getStorageKey(userId, storeId);
  ensureStoreCached(storeKey);

  return {
    get store() {
      return cache[storeKey] as LastUsedStore<T>[string];
    },
    unshift: (item: T) => unshiftToStore(item, storeKey, maxLength),
    remove: (item: T | ((item: T) => boolean)) =>
      removeFromStore(item, storeKey),
  };
};
