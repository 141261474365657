import {
  DefineComponent,
  defineComponent,
  PropType,
  Ref,
  SetupContext,
  VNode,
} from 'vue';

type SetupFunction<Props, RawBindings> = (
  props: Readonly<Props>,
  ctx: SetupContext
) => RawBindings;

type Supply<Props extends Record<string, unknown>> = DefineComponent<{
  externalReady: {
    type: BooleanConstructor;
    required: true;
  };
  props: {
    type: PropType<Props>;
    required: true;
  };
  onFail: {
    type: PropType<() => void>;
    required: true;
  };
}>;

type SupplyOutput = {
  isReady: Ref<boolean>;
  render?: (renderChildren: () => VNode) => VNode;
};

export const createSupply = <Props extends Record<string, unknown>>(
  setupFunction: SetupFunction<
    Readonly<{ props: Props; onFail: () => void }>,
    SupplyOutput
  >
): Supply<Props> =>
  defineComponent({
    props: {
      externalReady: {
        type: Boolean,
        required: true,
      },
      props: {
        type: Object as PropType<Props>,
        required: true,
      },
      onFail: {
        type: Function as PropType<() => void>,
        required: true,
      },
    },
    setup: (props, ctx) => {
      const { render, isReady } = setupFunction(
        props as { props: Props; onFail: () => void },
        ctx
      ) as SupplyOutput;

      const renderChildren = () =>
        ctx.slots.default?.({
          isReady: props.externalReady && isReady.value,
        })[0];

      return () => render?.(renderChildren) || renderChildren();
    },
  });

export const createDependency = <Props extends Record<string, unknown>>(dep: {
  dependency: Supply<Props>;
  props: Props;
}) => dep;

export type Dependency = ReturnType<typeof createDependency>;
