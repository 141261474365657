<template>
  <v-list class="sidebar-list" v-model:opened="openedGroups">
    <slot />
  </v-list>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { onMounted } from 'vue';

const props = withDefaults(
  defineProps<{
    opened?: string[];
  }>(),
  {
    opened: () => [],
  }
);

const emit = defineEmits<{
  (event: 'update:opened', value: string[]): void;
}>();

const openedGroups = computed({
  get: () => props.opened,
  set: (value: string[]) => emit('update:opened', value),
});

onMounted(() => {
  if (props.opened.length) {
    openedGroups.value = props.opened;
  }
});
</script>

<style lang="scss" scoped>
.sidebar-list {
  padding-top: 0;
  background-color: transparent;
}
</style>
