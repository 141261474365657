import { utils } from 'jsonapi-vuex';
import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import { WebSocketEvent, WebSocketEventResponse } from '@/models/ws';
import { JsonApiIdentification, JsonApiResource } from '@/models/api';
import jv from '../components/jv';
import wsConnection from '../components/wsConnection';
import notifications from './notifications';
import userNotification from './userNotification';
import importFile from './importFile';
import { JVTopLevelSingleResource } from '@/models/jv';
import { getUserId } from '@/auth';
import { useNotificationStore } from '@/stores/notifications';
import { cacheResource } from '@/utils/wsTools';
import { useExportStore } from '@/stores/export';
import { SnackbarType, useSnackbar } from '@/stores/snackbarStore';

interface State {
  contextId: string | null;
}

const isNewReturnSchema = (payload) => !payload.target;

export default createModule({
  path: 'wsUser',
  modules: [notifications, userNotification, importFile],
  components: [jv, wsConnection],
  setup({ components, modules, getAccessors }) {
    const $wsConnection = components.$wsConnection;
    const mutations = {};

    const actions = {
      handleError(context, event) {
        console.error('<ws> error');
        console.error(event);
        modules.notifications.public.dispatchNotify({
          message:
            'Could not establish a connection to the server, please try again later...',
          type: 'error',
        });
      },
      commitCacheRecords(context, payload: { resources: JsonApiResource[] }) {
        payload.resources.forEach((resource) => {
          const normalizedResource = utils.jsonapiToNorm(resource);
          const cachedResource = components.$jv.protected.get({
            id: resource.id,
            type: resource.type,
          });
          cacheResource({
            normalizedResource,
            cachedResource,
            mergeRecord: components.$jv.protected.commitMergeRecords,
            addRecord: components.$jv.protected.commitAddRecords,
          });
        });
      },
      commitMessageChanges(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        const { event, data } = payload;
        switch (event.operation) {
          case 'delete': {
            break;
          }
          case 'create': {
            dispatch(actions.commitCacheRecords)({
              resources: data.included || [],
            });
            break;
          }
          case 'update': {
            break;
          }
          default:
            break;
        }
      },
      onMessageReceived(context, payload: { target; event; data }) {
        const { showSnackbar } = useSnackbar();
        const { setHasNewNotification } = useNotificationStore();
        if (isNewReturnSchema(payload)) {
          if (payload.data['exportId']) {
            const { removeDomaOngoingExport } = useExportStore();
            removeDomaOngoingExport(payload.data.resource);
            if (payload.data['success']) {
              showSnackbar('Export completed');
              setHasNewNotification(true);
            } else {
              showSnackbar('Export failed', SnackbarType.error);
            }
          }
          return;
        }
        const { target, event, data } = payload;
        dispatch(actions.commitMessageChanges)({ event, data, target });
      },
      onMessageUnhandled(context, payload: { target; event; data }) {
        const { target } = payload;
        if (target && target.type === resources.notifications.type) {
          dispatch(actions.handleNotification)(payload);
        }
      },
      handleNotification(
        context,
        payload: {
          event: JVTopLevelSingleResource & WebSocketEvent;
          data: WebSocketEventResponse;
          target: JsonApiIdentification;
        }
      ) {
        const getNormalizedTarget = () => {
          const resource = payload.data.included.find(
            (i) => i.id === payload.target.id
          );
          return utils.jsonapiToNorm(resource);
        };
        const resource = getNormalizedTarget();
        switch (payload.event.operation) {
          case 'create': {
            modules.userNotifications.public.dispatchInjectToPageIndex({
              resourceId: resource._jv.id,
            });
            break;
          }
          case 'update': {
            const { included } = payload.data;
            dispatch(actions.commitCacheRecords)({
              resources: included || [],
            });
            break;
          }
          default:
            break;
        }
      },
    };

    const { dispatch } = getAccessors<State>();

    return {
      module: {
        mutations,
        actions,
      },
      protected: {
        ...components.$wsConnection.protected,
        onMessageReceived: dispatch(actions.onMessageReceived),
        onMessageUnhandled: dispatch(actions.onMessageUnhandled),
      },
      public: {
        ...components.$wsConnection.public,
        getTargetId: () => $wsConnection.public.getTarget(getUserId())?.id,
        dispatchConnect: () =>
          $wsConnection.public.dispatchConnect({
            target: { id: getUserId(), type: resources.users.type },
            onMessageReceived: dispatch(actions.onMessageReceived),
            onMessageUnhandled: dispatch(actions.onMessageUnhandled),
            onError: dispatch(actions.handleError),
          }),
      },
    };
  },
});
