export enum RouteName {
  Root = 'root',
  Launchpad = 'launchpad',
  Design = 'design',
  Core = 'core',
  CoreList = 'coreList',
  CoreSplitView = 'coreSplitView',
  Simo = 'simo',
  RevisionCore = 'revision-core',
  RevisionCoreSplitView = 'revision-doma',
  RevisionSimo = 'revision-simo',
  Setup = 'setup',
  ClassificationTables = 'classificationTablesManager',
  ClassificationEntries = 'classificationTable',
  SyntaxManager = 'syntaxesManager',
  Syntax = 'syntax',
  Admin = 'admin',
  Users = 'users',
  AuthCallback = 'auth-callback',
  SimoTradeStudy = 'simo-trade-study',
  ProjectDetails = 'project-details',
  Revisions = 'revisions',
  Projects = 'projects',
  Dashboard = 'dashboard',
  EventLog = 'event-log',
  Owner = 'owner',
  OwnerGroups = 'owner-groups',
}
