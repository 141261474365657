<template>
  <div class="shortcuts-section-container">
    <div v-if="sectionTitle" class="section-title">{{ sectionTitle }}</div>
    <div
      v-for="item in shortcuts"
      :key="item.description"
      class="shortcuts-container"
    >
      <div class="shortcut-description">{{ item.description }}</div>
      <div
        v-for="shortcut in item.shortcut"
        :key="`${shortcut[0]}`"
        class="shortcut-wrapper"
      >
        <div v-if="item.type === 'both'" class="d-flex align-center">
          <div class="keyButton">
            <span>{{ shortcut[0] }}</span>
          </div>
          <v-icon
            v-if="item.conjunction === 'mdi-plus'"
            icon="mdi-plus"
            size="x-small"
          />
          <div class="keyButton">
            <span>
              <v-icon :icon="shortcut[1]" color="black" size="x-small" />
            </span>
          </div>
        </div>
        <div
          v-else
          class="d-flex align-center"
          v-for="(keyButton, index) in shortcut"
          :key="keyButton"
        >
          <div v-if="index === 1">
            <v-icon
              v-if="item.conjunction === 'mdi-plus'"
              icon="mdi-plus"
              size="x-small"
            />
            <span v-if="item.conjunction === 'or'">{{ item.conjunction }}</span>
          </div>
          <span v-if="item.type === 'text'" class="keyButton">
            {{ keyButton }}
          </span>
          <span v-else class="keyButton"
            ><v-icon color="black" size="x-small">{{ keyButton }}</v-icon></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { ShortcutDefinition } from './types';

@Options({
  name: 'KeyShortcutsSection',
  components: {},
})
export default class KeyShortcutsSection extends Vue {
  @Prop({ default: () => null }) sectionTitle: string | null;
  @Prop({ default: () => [] }) shortcuts: ShortcutDefinition[];
}
</script>

<style lang="scss" scoped>
.keyButton {
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  vertical-align: middle;
  border: 1px solid #d1d5da;
  border-radius: 2px;
  padding: 3px 4px;
  box-shadow: inset 0 -1px 0 #d1d5da;
  font-size: 12px;
  min-width: 20px;
}

.shortcuts-section-container {
  margin-bottom: 25px;
}
.shortcuts-container {
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 15px;
}
.shortcut-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}

.section-title {
  border-bottom: thin solid black;
  font-weight: 450;
  font-size: 1.1em;
}

.shortcut-description {
  width: 250px;
}
</style>
