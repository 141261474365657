import { ComponentType } from './_types';
import { createModuleComponent } from '../utils/factories';
import crud from '../components/crud';
import { ObjectOccurrenceRelation } from '@/models/objectOccurrenceRelation';
import { JsonApiIdentification } from '@/models/api';
import simoIndexManager from './simoIndexManager';
import delayedResponseContext from './delayedResponseContext';
import { ResourceIdentification } from '@/utils/path';

export interface SimoContext {
  indexId: string;
  chainAnalysis: boolean;
}
export interface RelationContext extends SimoContext {
  sourceId: string;
  targetId: string;
  contextId: string;
}

export default createModuleComponent({
  type: ComponentType.SimoCrud,
  dependencies: [crud, simoIndexManager, delayedResponseContext],
  setup({ getAccessors, components, resourceProfile }) {
    const $simoIndexManager = components.$simoIndexManager.protected;
    const $crud = components.$crud.public;
    const getDelayedResponseContext =
      components.$delayedResponseContext.protected.getDelayedResponseService;

    const actions = {
      async createResource(
        context,
        payload: {
          indexId: string;
          chainAnalysis: boolean;
          contextId: string;
        } & Parameters<typeof $crud.dispatchCreateResource>[0]
      ) {
        const { indexId, chainAnalysis, resource } = payload;

        if (resource.no_relations || resource.unknown_relations) {
          delete resource.name;
        }
        const { id } = await getDelayedResponseContext(
          payload.contextId
        ).waitForOperation($crud.dispatchCreateResource(payload), {
          operation: 'create',
          targetType: resourceProfile.type,
          preventsDefault: true,
          transformWsResponse: ({ data }) => ({
            id: (data.data.relationships.target.data as JsonApiIdentification)
              .id,
          }),
        });

        const oor = $crud.getSimplifiedResourceSet()[
          id
        ] as ObjectOccurrenceRelation;
        $simoIndexManager.commitPrepareIndex({ indexId });
        $simoIndexManager.commitInjectToRequestIndex({
          indexId,
          oor,
          chainAnalysis,
        });
      },
      async removeSpecialRelations(context, payload: RelationContext) {
        const relations = $simoIndexManager.getBySourceAndTarget(
          payload.indexId,
          payload.sourceId,
          payload.targetId
        );
        const specialRelations = (relations || []).filter(
          (oor) => oor.no_relations || oor.unknown_relations
        );
        return Promise.all(
          specialRelations.map((oor) => {
            return getDelayedResponseContext(
              payload.contextId
            ).waitForOperation(
              $crud.dispatchDeleteResource({ resourceId: oor.id }),
              {
                operation: 'delete',
                targetType: resourceProfile.type,
                targetId: oor.id,
                preventsDefault: true,
              }
            );
          })
        );
      },
      async createRelation(
        context,
        payload: RelationContext & {
          classificationEntryId: string;
          resource: { name: string; number: number };
          sync?: true;
        }
      ) {
        const removePromise = dispatch(actions.removeSpecialRelations)(payload);
        if (!payload.sync) await removePromise;
        return dispatch(actions.createResource)({
          contextId: payload.contextId,
          indexId: payload.indexId,
          chainAnalysis: payload.chainAnalysis,
          resource: payload.resource,
          relationships: {
            source: payload.sourceId,
            target: payload.targetId,
            classification_entry: payload.classificationEntryId,
          },
        });
      },
      async removeRelation(
        context,
        payload: {
          indexId: string;
          contextId: string;
        } & ResourceIdentification
      ) {
        const { indexId, resourceId } = payload;
        const oor = $crud.getSimplifiedResourceSet()[
          payload.resourceId
        ] as ObjectOccurrenceRelation;
        $simoIndexManager.commitPrepareIndex({ indexId });
        $simoIndexManager.commitRemoveFromRequestIndex({ indexId, oor });
        return getDelayedResponseContext(payload.contextId).waitForOperation(
          $crud.dispatchDeleteResource({ resourceId }),
          {
            operation: 'delete',
            targetType: resourceProfile.type,
            targetId: resourceId,
            preventsDefault: true,
          }
        );
      },
      async setNoRelations(
        context,
        payload: RelationContext & { sync?: true }
      ) {
        const removePromise = dispatch(actions.removeSpecialRelations)(payload);
        if (!payload.sync) await removePromise;
        return dispatch(actions.createResource)({
          contextId: payload.contextId,
          indexId: payload.indexId,
          chainAnalysis: payload.chainAnalysis,
          resource: { name: '-', no_relations: true },
          relationships: {
            source: payload.sourceId,
            target: payload.targetId,
          },
        });
      },
      async setUnknownRelations(
        context,
        payload: RelationContext & { sync?: true }
      ) {
        const removePromise = dispatch(actions.removeSpecialRelations)(payload);
        if (!payload.sync) await removePromise;
        return dispatch(actions.createResource)({
          contextId: payload.contextId,
          indexId: payload.indexId,
          chainAnalysis: payload.chainAnalysis,
          resource: { name: '?', unknown_relations: true },
          relationships: {
            source: payload.sourceId,
            target: payload.targetId,
          },
        });
      },
    };

    const { dispatch } = getAccessors();

    return {
      module: {
        actions,
      },
      public: {
        getSimplifiedResourceSet: $crud.getSimplifiedResourceSet,
        getRelationshipId: $crud.getRelationshipId,
        dispatchCreateRelation: dispatch(actions.createRelation),
        dispatchSetNoRelations: dispatch(actions.setNoRelations),
        dispatchSetUnknownRelations: dispatch(actions.setUnknownRelations),
        dispatchDeleteRelation: dispatch(actions.removeRelation),
        dispatchRemoveSpecialRelations: dispatch(
          actions.removeSpecialRelations
        ),
        dispatchUpdateRelation: $crud.dispatchUpdateResource,
        dispatchLoadSingleResource: $crud.dispatchLoadSingleResource,
      },
    };
  },
});
