<template>
  <v-tooltip location="top" offset="15px" :disabled="!enableTooltip">
    <span>{{ tooltipText }}</span>
    <template #activator="{ props: tooltipProps }">
      <BaseAutocomplete
        ref="autocompleteRef"
        class="ooc-editor-autocomplete"
        v-model="selectionModel"
        :items="options"
        :loadingItems="isLoading"
        :hasMoreItems="hasMoreItems"
        :openMenuOnItemClick="keepMenuOpen"
        :item-value="getOptionValue"
        :item-text="getOptionText"
        v-model:searchInput="query"
        v-model:isInputOverflown="enableTooltip"
        :placeholder="placeholder"
        :disabled="disabled"
        :hideNoData="!debouncedQuery"
        :attach="attach"
        :menu-props="{
          location: 'bottom',
          maxHeight: 304,
          width: '100%',
          absolute: true,
          contentClass: 'ooc-editor-autocomplete__menu-content',
          ...menuProps,
        }"
        :allowNoSelectedItem="!isSyntaxElementSelected"
        :autofocus="autofocus"
        hideDropdownIcon
        v-bind="tooltipProps"
        @keydown="onKeydown"
        @loadMore="resourceList.loadMore()"
        @selection="onSelection"
      >
        <template #item="{ item, attrs, on }">
          <OOCListItem
            v-bind="{ ...attrs, ...itemAttrs }"
            :fontSize="dense ? undefined : 'var(--core-node-font-size)'"
            :dense="dense ? 'compact' : 'relaxed'"
            :ooc="suggestionToOOC(item)"
            :lightFont="item.isAlternativeName"
            :alternativeName="item.name"
            v-on="on"
          >
            <template #append-inner>
              <v-chip
                class="mr-2"
                variant="flat"
                color="#00695C"
                v-if="isCurrentSystem(item)"
              >
                Current
              </v-chip>
              <v-chip
                class="mr-2"
                variant="flat"
                color="#00695C"
                v-if="hasPreviousName(item)"
              >
                Current name
              </v-chip>
            </template>
            <template #append>
              <v-tooltip
                v-if="definitionTooltipEnabled && item.definition"
                :activator="`#${attrs.id}`"
                max-width="512px"
                location="right"
              >
                {{ item.definition }}
              </v-tooltip>
            </template>
          </OOCListItem>
        </template>

        <template #no-data>
          <div class="pa-4">No matching objects found</div>
        </template>
      </BaseAutocomplete>
    </template>
  </v-tooltip>
</template>

<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import {
  classificationEntryAlternativeNames,
  classificationEntryNameSortedMatches,
  classifiedSuggestions,
  OOCEditorSuggestion,
  SuggestionsContext,
  suggestionToOOC,
  suggestionWithId,
  unclassifiedSuggestions,
} from '@/utils/oocSuggestions';
import { useResourceList } from '@/composables/resourceList';
import { useDirectStore } from '@/composables/store';
import { SyntaxElement } from '@/models/syntaxElement';
import { debouncedComputed } from '@/composables/debouncedComputed';
import { ClassificationEntry } from '@/models/classificationEntry';
import BaseAutocomplete from '@/components/baseAutocomplete/baseAutocomplete.vue';
import OOCListItem from '@/components/design/core/OOCListItem.vue';
import { panelLayoutDataAttributes } from '@/utils/constants';
import { onMounted } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: unknown; // OOCEditorSuggestion;
    editedOOCName?: string;
    allowedSyntaxElements: unknown[]; // SyntaxElement[];
    editedOocClassificationEntryId?: string;
    placeholder?: string;
    disabled?: boolean;
    autofocus?: boolean;
    attach?: unknown; // `HTMLElement | string | boolean` breaks runtime validation
    dense?: boolean;
    menuProps?: {
      transition?: boolean;
    };
    markPreviousName?: boolean;
  }>(),
  {
    editedOOCName: undefined,
    editedOocClassificationEntryId: undefined,
    allowedSyntaxElements: () => [],
    placeholder: undefined,
    attach: undefined,
    menuProps: undefined,
    markPreviousName: false,
    autofocus: false,
  }
);

const emit = defineEmits<{
  (event: 'update:modelValue', item: OOCEditorSuggestion): void;
  (event: 'submit'): void;
  (event: 'cancel'): void;
  (event: 'keydown', e: KeyboardEvent): void;
}>();

const selection = computed(() => props.modelValue as OOCEditorSuggestion);

const autocompleteRef = ref<InstanceType<typeof BaseAutocomplete>>();

const selectionModel = computed<OOCEditorSuggestion>({
  get() {
    // if name is empty pretend as it nothing was selected
    // autocomplete will then show placeholder
    return selection.value?.name ? selection.value : null;
  },
  set(newSelection) {
    emit('update:modelValue', newSelection);
  },
});

const query = ref('');
const debouncedQuery = debouncedComputed(query, {
  debounce: 300,
  maxWait: 1500,
});
watch(query, (newQuery) => {
  selectionModel.value = suggestionWithId({
    ...selection.value,
    name: newQuery || '',
  });
});

const getOptionText = (s: OOCEditorSuggestion) => s.name;
const getOptionValue = (s: OOCEditorSuggestion) => s.__suggestionId;

const enableTooltip = ref(false);
const tooltipText = computed(() =>
  selection.value ? getOptionText(selection.value) : query.value
);

const definitionTooltipEnabled = ref(true);

const store = useDirectStore();

const keepMenuOpen = computed(() => !selection.value.classification_entry_id);

const allowedClassificationTableIds = computed(() => {
  const allowedTables = props.allowedSyntaxElements.map(
    (element: SyntaxElement) => {
      return store.syntaxElement.getRelationshipId(
        element.id,
        'classification_table'
      ) as string;
    }
  );
  return [...new Set(allowedTables.filter(Boolean))];
});

const itemAttrs = computed(() => ({
  [panelLayoutDataAttributes.item]: true,
}));

const resourceList = useResourceList<ClassificationEntry>({
  resourceConfig: computed(() => ({
    module: store.classificationEntry,
    pathParameters: {
      filterClassificationTableIds: allowedClassificationTableIds.value,
      sort: debouncedQuery.value ? null : 'code',
    },
  })),
  query: debouncedQuery,
  requestType: 'ooc-editor',
  pageSize: 8,
});

const hasAllowedClassificationTables = computed(() => {
  return !!allowedClassificationTableIds.value.length;
});

const isSyntaxElementSelected = computed(() => !!selection?.value?.aspect);

const options = computed(() => {
  return isSyntaxElementSelected.value
    ? alternativeNameOptions.value
    : syntaxElementOptions.value;
});

const isCurrentSystem = (entry) => {
  if (isSyntaxElementSelected.value) return false;
  return entry.classification_entry_id === props.editedOocClassificationEntryId;
};

const syntaxElementOptions = computed(() => {
  const items = resourceList.items.value;

  const suggestionsContext: SuggestionsContext = {
    classificationEntries: items,
    syntaxElements: props.allowedSyntaxElements.filter(
      Boolean
    ) as SyntaxElement[],
    getClassificationEntryTableId: (entryId) =>
      store.classificationEntry.getRelationshipId(
        entryId,
        'classification_table'
      ) as string,
    getSyntaxElementTableId: (elementId) =>
      store.syntaxElement.getRelationshipId(
        elementId,
        'classification_table'
      ) as string,
  };
  return [
    ...unclassifiedSuggestions(suggestionsContext, query.value),
    ...classifiedSuggestions(suggestionsContext, debouncedQuery.value),
  ];
});

const selectionClassificationEntry = computed(() => {
  const classificationEntryId = selection.value.classification_entry_id;
  return (
    classificationEntryId &&
    store.classificationEntry.getSimplifiedResourceSet()[classificationEntryId]
  );
});

const loadingClassificationEntries = new Set<string>();
const loadingEntriesNumber = ref(0);
const loadClassificationEntry = (resourceId?: string) => {
  if (resourceId && !loadingClassificationEntries.has(resourceId)) {
    loadingEntriesNumber.value++;
    store.classificationEntry
      .dispatchLoadSingleResource({ resourceId })
      .finally(() => {
        loadingEntriesNumber.value--;
      });
  }
};

const hasPreviousName = (entry) => {
  if (!props.markPreviousName) return false;
  return entry.name.trim() === props.editedOOCName;
};

const alternativeNameOptions = computed(() => {
  if (!isSyntaxElementSelected.value) return [];
  const classificationEntryId = selection.value.classification_entry_id;
  if (!selectionClassificationEntry.value) {
    loadClassificationEntry(classificationEntryId);
    return [];
  }
  const alternativeNames = classificationEntryAlternativeNames(
    selectionClassificationEntry.value
  );
  const isPredefinedName = [...alternativeNames, props.editedOOCName].includes(
    selection.value.name
  );
  return classificationEntryNameSortedMatches(
    selectionClassificationEntry.value,
    isPredefinedName ? '' : selection.value.name
  )
    .map((name) =>
      suggestionWithId({
        ...selection.value,
        name,
        classification_entry_name: selectionClassificationEntry.value.name,
        definition: selectionClassificationEntry.value.definition,
        isAlternativeName: true,
      })
    )
    .sort((a, b) => {
      if (a.name === selection.value.name) return -1;
      if (b.name === selection.value.name) return 1;
      return 0;
    });
});

const isLoading = computed(() => {
  return resourceList.loading.value || loadingEntriesNumber.value > 0;
});

const hasMoreItems = computed(() => {
  return !!(
    hasAllowedClassificationTables.value &&
    !isSyntaxElementSelected.value &&
    debouncedQuery.value &&
    resourceList.hasMore()
  );
});

const onSelection = async (item: OOCEditorSuggestion) => {
  const wasClassified = !!selection.value.classification_entry_code;
  const isClassified = !!item.classification_entry_code;
  if (!wasClassified && isClassified) {
    // open menu with alternative names
    await nextTick();
    autocompleteRef.value?.openMenu();
    return;
  }
  const isFinalSelection = !!item.aspect;
  if (isFinalSelection) {
    await nextTick();
    emit('submit');
  }
};

const onKeydown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    emit('submit');
  }
  if (e.key === 'Escape') {
    emit('cancel');
  }
};

watch(
  () => props.editedOocClassificationEntryId,
  (id) => {
    if (id) loadClassificationEntry(id);
  },
  { immediate: true }
);

const emitFirstSyntaxForPureNumbers = computed(
  () =>
    !isSyntaxElementSelected.value &&
    !hasAllowedClassificationTables.value &&
    options.value.length === 1
);

onMounted(() => {
  if (emitFirstSyntaxForPureNumbers.value) {
    emit('update:modelValue', options.value[0]);
  }
});

onBeforeUnmount(() => (definitionTooltipEnabled.value = false));
</script>

<style lang="scss">
.ooc-editor-autocomplete__menu-content {
  min-height: fit-content;
  top: 100% !important;
  left: 0 !important;
  background-color: rgb(var(--v-theme-cardBackground));
  &::-webkit-scrollbar-track {
    background-color: rgb(var(--v-theme-cardBackground));
  }
}

.ooc-editor-autocomplete {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.35rem;

  .v-input input {
    font-size: var(--core-node-font-size);
  }
}
</style>
