import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import indexManager from '../components/indexManager';
import crud from '../components/crud';
import processingTracker from '../components/processingTracker';

export default createModule({
  path: 'users',
  resourceProfile: resources.users,
  components: [indexManager, crud, processingTracker],
  setup({ components }) {
    return {
      module: {},
      public: {
        ...components.$indexManager.public,
        ...components.$crud.public,
        ...components.$processingTracker.public,
      },
    };
  },
});
