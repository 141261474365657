import { MaybeRef } from '@vueuse/core';
import { computed, ref, toRef, unref, watch } from 'vue';

export const useKeyboardNavigation = (props: {
  items: MaybeRef<unknown[]>;
  itemValue: (item: unknown) => string;
  defaultHighlightedItem: MaybeRef<unknown>;
  isMenuVisible: MaybeRef<boolean>;
  openMenu: () => void;
  closeMenu: () => void;
  submit: (item: unknown) => void;
  scrollToItem: (itemValue: string, options: ScrollIntoViewOptions) => void;
}) => {
  const explicitHighlightedItemValue = ref(null);

  const highlightedItemValue = computed(() => {
    if (explicitHighlightedItemValue.value)
      return explicitHighlightedItemValue.value;
    // auto highlight default item
    if (hasDefaultHighlightedItem.value)
      return props.itemValue(unref(props.defaultHighlightedItem));
    // auto highlight first item
    return unref<unknown[]>(props.items).length
      ? props.itemValue(unref(props.items)[0])
      : null;
  });

  const hasDefaultHighlightedItem = computed(() => {
    const defaultItem = unref(props.defaultHighlightedItem);
    if (!defaultItem) return false;
    const defaultItemValue = props.itemValue(defaultItem);
    return unref<unknown[]>(props.items).some(
      (item) => props.itemValue(item) === defaultItemValue
    );
  });

  const hasExplicitHighlightedItem = computed(() =>
    unref<unknown[]>(props.items).some(
      (item) => props.itemValue(item) === explicitHighlightedItemValue.value
    )
  );
  watch(hasExplicitHighlightedItem, (val) => {
    // reset explicit highlighted item if it is not in the list
    if (!val) explicitHighlightedItemValue.value = null;
  });
  watch(
    () => unref(props.isMenuVisible),
    (val) => {
      // reset explicit highlighted item on menu close
      if (!val) explicitHighlightedItemValue.value = null;
    }
  );
  watch(
    [toRef(props, 'isMenuVisible'), highlightedItemValue],
    ([isVisible, highlightedValue], [wasVisible]) => {
      if (isVisible && highlightedValue) {
        setTimeout(() => {
          props.scrollToItem(highlightedValue, {
            block: wasVisible ? 'nearest' : 'center',
            inline: 'nearest',
          });
        }, 50);
      }
    }
  );

  const itemIndex = (value: string | number) =>
    unref<unknown[]>(props.items).findIndex(
      (item) => props.itemValue(item) === value
    );

  const handleArrowKeys = (event: KeyboardEvent) => {
    if (!['ArrowUp', 'ArrowDown'].includes(event.key)) return;

    event.preventDefault();

    if (!unref(props.isMenuVisible)) {
      props.openMenu();
      return;
    }
    if (!highlightedItemValue.value) return;
    const index = itemIndex(highlightedItemValue.value);
    if (index < 0) return;

    if (event.key === 'ArrowUp' && index > 0) {
      const newIndex = event.metaKey || event.ctrlKey ? 0 : index - 1;
      explicitHighlightedItemValue.value = props.itemValue(
        unref(props.items)[newIndex]
      );
    }
    if (
      event.key === 'ArrowDown' &&
      index < unref<unknown[]>(props.items).length - 1
    ) {
      const newIndex =
        event.metaKey || event.ctrlKey
          ? unref<unknown[]>(props.items).length - 1
          : index + 1;
      explicitHighlightedItemValue.value = props.itemValue(
        unref(props.items)[newIndex]
      );
    }
  };

  const handleEnterAndTab = (event: KeyboardEvent) => {
    if (!['Enter', 'Tab'].includes(event.key)) return;
    if (!unref(props.isMenuVisible)) return;
    const highlightedItem =
      highlightedItemValue.value &&
      unref<unknown[]>(props.items).find(
        (item) => props.itemValue(item) === highlightedItemValue.value
      );
    if (highlightedItem) {
      props.submit(highlightedItem);
    }
    props.closeMenu();
    event.preventDefault();
  };

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key !== 'Escape') return;
    if (!unref(props.isMenuVisible)) return;
    event.preventDefault();
    props.closeMenu();
  };

  const keyDownHandler = (event: KeyboardEvent) => {
    handleArrowKeys(event);
    if (event.defaultPrevented) return;

    handleEnterAndTab(event);
    if (event.defaultPrevented) return;

    handleEscape(event);
    if (event.defaultPrevented) return;

    const noModifiers = !event.ctrlKey && !event.metaKey && !event.altKey;
    const isAlphanumericKey = event.key.length === 1;
    const isSpecialKey = ['Enter', 'ArrowDown', 'ArrowUp'].includes(event.key);
    if (noModifiers && (isAlphanumericKey || isSpecialKey)) {
      props.openMenu();
    }
  };

  return {
    highlightedItemValue,
    keyDownHandler,
  };
};
