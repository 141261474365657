import { createStore, Store } from 'vuex';
import { instantiateModulesAndDirectAccessors } from './utils/factories';
import polling from './legacy/polling';
import jsonapiModule from './jsonapiModule';

import account from './modules/account';
import classificationEntry from './modules/classificationEntry';
import classificationEntryStats from './modules/classificationEntryStats';
import classificationTable from './modules/classificationTable';
import context from './modules/context';
import customField from './modules/customField';
import customFieldValue from './modules/customFieldValue';
import doma from './modules/doma';
import domaClassificationEntry from './modules/domaClassificationEntry';
import exportStruct from './modules/exportStruct';
import filterSet from './modules/simoFilterSets';
import importFile from './modules/importFile';
import intercom from './modules/intercom';
import jvBatch from './modules/jvBatch';
import meetingPlanner from './modules/meetingPlanner';
import notifications from './modules/notifications';
import dashboard from './modules/dashboard';
import dashboardWidget from '@/store/modules/dashboardWidget';
import ooc from './modules/ooc';
import oorChainElement from './modules/oorChainElement';
import owners from './modules/owners';
import ownerCompanies from './modules/ownerCompanies';
import ownerGroups from './modules/ownerGroups';
import ownership from './modules/ownership';
import permission from './modules/permission';
import progress from './modules/progress';
import progressModel from './modules/progressModel';
import progressStep from './modules/progressStep';
import project from './modules/project';
import revision from './modules/revision';
import simo from './modules/simo';
import syntax from './modules/syntax';
import syntaxElement from './modules/syntaxElement';
import syntaxNode from './modules/syntaxNode';
import tags from './modules/tags';
import tradeStudies from './modules/tradeStudies';
import tradeStudyDeltaGroups from './modules/tradeStudyDeltaGroups';
import tradeStudyDeltas from './modules/tradeStudyDeltas';
import urlStruct from './modules/urlStruct';
import userColors from './modules/userColors';
import userPermission from './modules/userPermission';
import users from './modules/users';
import userNotification from './modules/userNotification';
import wsContext from './modules/wsContext';
import wsUser from './modules/wsUser';

const { directAccessors, storeModules, moduleInstances } =
  instantiateModulesAndDirectAccessors({
    getStore: () => store,
    modules: {
      [account.path]: account,
      [classificationEntry.path]: classificationEntry,
      [classificationEntryStats.path]: classificationEntryStats,
      [classificationTable.path]: classificationTable,
      [context.path]: context,
      [customField.path]: customField,
      [customFieldValue.path]: customFieldValue,
      [doma.path]: doma,
      [domaClassificationEntry.path]: domaClassificationEntry,
      [exportStruct.path]: exportStruct,
      [filterSet.path]: filterSet,
      [importFile.path]: importFile,
      [intercom.path]: intercom,
      [jvBatch.path]: jvBatch,
      [meetingPlanner.path]: meetingPlanner,
      [notifications.path]: notifications,
      [dashboard.path]: dashboard,
      [dashboardWidget.path]: dashboardWidget,
      [ooc.path]: ooc,
      [oorChainElement.path]: oorChainElement,
      [owners.path]: owners,
      [ownerCompanies.path]: ownerCompanies,
      [ownerGroups.path]: ownerGroups,
      [ownership.path]: ownership,
      [permission.path]: permission,
      [progress.path]: progress,
      [progressModel.path]: progressModel,
      [progressStep.path]: progressStep,
      [project.path]: project,
      [revision.path]: revision,
      [simo.path]: simo,
      [syntax.path]: syntax,
      [syntaxElement.path]: syntaxElement,
      [syntaxNode.path]: syntaxNode,
      [tags.path]: tags,
      [tradeStudies.path]: tradeStudies,
      [tradeStudyDeltaGroups.path]: tradeStudyDeltaGroups,
      [tradeStudyDeltas.path]: tradeStudyDeltas,
      [urlStruct.path]: urlStruct,
      [userColors.path]: userColors,
      [userPermission.path]: userPermission,
      [users.path]: users,
      [userNotification.path]: userNotification,
      [wsContext.path]: wsContext,
      [wsUser.path]: wsUser,
    },
  });

const store = createStore({
  state: {},
  mutations: {},
  modules: {
    ...storeModules,
    polling,
    jv: jsonapiModule,
  },
});

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $direct: typeof directAccessors;
    $_moduleInstances: typeof moduleInstances;
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: Store<Record<string, never>>;
  }
}

store.$direct = directAccessors;
store.$_moduleInstances = moduleInstances;

export default store as Store<Record<string, never>>;
