import { createModuleComponent } from '../utils/factories';
import { ComponentType } from './_types';
import {
  TreeOperation,
  TreeOperationType,
  TreeOperationPayload,
} from '@/models/treeStructure';
import { treeId, TreeIdentification } from '@/utils/tree';

interface State {
  trees: Partial<{
    [treeId: string]: {
      treeOperations: Map<string, TreeOperation>;
    };
  }>;
}

const treeOperations = createModuleComponent({
  type: ComponentType.TreeOperations,
  setup: ({ getAccessors }) => {
    const state = (): State => ({
      trees: {},
    });

    const getters = {
      treeOperations: (state: State) => (tree: TreeIdentification) => {
        if (!state.trees[treeId(tree)])
          commit(mutations.ensureTreeIndexInitialized)({ tree });
        return state.trees[treeId(tree)].treeOperations;
      },
    };

    const mutations = {
      ensureTreeIndexInitialized(
        state: State,
        payload: { tree: TreeIdentification }
      ) {
        if (!state.trees[treeId(payload.tree)]) {
          state.trees[treeId(payload.tree)] = {
            treeOperations: new Map(),
          };
        }
      },
      addTreeOperation(
        state: State,
        payload: {
          tree: TreeIdentification;
          actionId: string;
          operation: TreeOperation;
        }
      ) {
        const { tree, actionId, operation } = payload;
        commit(mutations.ensureTreeIndexInitialized)({ tree });
        state.trees[treeId(tree)].treeOperations.set(actionId, operation);
      },
      removeTreeOperation(
        state: State,
        payload: { tree: TreeIdentification; actionId: string }
      ) {
        const { tree, actionId } = payload;
        if (!state.trees[treeId(tree)]) return;
        state.trees[treeId(tree)].treeOperations.delete(actionId);
      },
    };

    const actions = {
      addTreeOperation(
        context,
        payload: { tree: TreeIdentification; operation: TreeOperationPayload }
      ): Promise<string> {
        const { tree } = payload;
        commit(mutations.ensureTreeIndexInitialized)({ tree });
        const actionId = `temp-${Date.now() + Math.random()}`;
        const operation =
          payload.operation.operationType === TreeOperationType.Add
            ? { ...payload.operation, id: actionId }
            : payload.operation;
        commit(mutations.addTreeOperation)({ tree, actionId, operation });
        return Promise.resolve(actionId);
      },
      removeTreeOperation(
        context,
        payload: { tree: TreeIdentification; actionId: string }
      ) {
        commit(mutations.removeTreeOperation)(payload);
      },
    };

    const { read, commit, dispatch } = getAccessors();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {
        getTreeOperations: read(getters.treeOperations),
      },
      public: {
        dispatchAddTreeOperation: dispatch(actions.addTreeOperation),
        dispatchRemoveTreeOperation: dispatch(actions.removeTreeOperation),
      },
    };
  },
});

export default treeOperations;
