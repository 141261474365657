import { defineStore } from 'pinia';
import { ref } from 'vue';

export enum SnackbarType {
  info = 'info',
  success = 'success',
  error = 'error',
}

export const useSnackbar = defineStore('snackbar', () => {
  const isSnackbarOpen = ref(false);
  const snackbarType = ref<SnackbarType>(SnackbarType.info);

  const message = ref('');
  const snackbarTimeout = ref(2500);

  const showSnackbar = (
    newMessage: string,
    type: SnackbarType = SnackbarType.info,
    timeout: number = 2500
  ) => {
    message.value = newMessage;
    isSnackbarOpen.value = true;
    snackbarType.value = type;
    snackbarTimeout.value = timeout;
  };

  const closeSnackbar = () => {
    isSnackbarOpen.value = false;
  };

  return {
    isSnackbarOpen,
    message,
    showSnackbar,
    closeSnackbar,
    snackbarType,
    snackbarTimeout,
  };
});
