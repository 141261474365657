<template>
  <v-navigation-drawer
    ref="designNavigationDrawer"
    class="elevation-6"
    color="primary-darken-2"
    :rail="!labelsVisible"
    :rail-width="miniToolbarWidth"
    :width="205"
    order="2"
    permanent
    theme="appDesign"
    :expand-on-hover="!!projectId"
  >
    <v-list
      v-if="!!projectId"
      nav
      v-model:opened="openView"
      open-strategy="multiple"
      :class="[
        'toolbar-list',
        {
          'toolbar-list__items--padding': toolbar.isExpanded,
        },
      ]"
    >
      <div>
        <AppToolbarItem
          v-for="projectBasedRoute in projectBasedRoutes"
          :key="projectBasedRoute.label"
          v-bind="projectBasedRoute"
          :exact="true"
          :label-visible="labelsVisible"
        />
        <AppToolbarDeadline :projectId="projectId" />
      </div>
      <div>
        <v-list-subheader class="toolbar-list__subheading">
          {{ contextsLabel }}
        </v-list-subheader>
        <v-skeleton-loader
          type="list-item, list-item, list-item"
          class="design-navigation-drawer__context-loader"
          :loading="loadingContexts"
        >
          <template v-if="contextBasedRoutes.length">
            <AppToolbarItem
              v-for="contextBasedRoute in contextBasedRoutes"
              :key="contextBasedRoute.label"
              v-bind="contextBasedRoute"
              :icon="labelsVisible ? '' : contextBasedRoute.icon"
              :badge="showSyntaxUpdateBadge(contextBasedRoute.id) ? '!' : false"
              :label="contextBasedRoute.label.slice(1)"
              :disabled="isContextDisabled(contextBasedRoute.id)"
              class="toolbar-list__item--icon-center"
            >
              <template #icon>
                <div class="design-navigation-drawer__context-icon">
                  {{ contextBasedRoute.icon }}
                </div>
              </template>
            </AppToolbarItem>
          </template>
          <AppToolbarItem
            v-else
            icon="mdi-information-outline"
            label="No contexts"
            inactive
          />
        </v-skeleton-loader>
      </div>
      <div v-if="canSeeDoma">
        <v-list-subheader class="toolbar-list__subheading">
          Doma
        </v-list-subheader>
        <DesignNavigationDrawerDraggable
          :expanded="toolbar.isExpanded"
          :projectId="projectId as string"
          @expanded="domaExpanded"
          icon="mdi-file-tree"
          group-title="Tree view"
        />
      </div>

      <div>
        <v-list-subheader class="toolbar-list__subheading">
          More
        </v-list-subheader>
        <AppToolbarItem
          v-for="route in moreRoutes"
          :key="route.label"
          v-bind="route"
          exact
          :label-visible="labelsVisible"
        />
      </div>
    </v-list>
    <template #append>
      <v-list class="toolbar-list toolbar-list--small-gap">
        <v-list-item
          @click="emit('expanded')"
          data-spec="expand-sidebar"
          density="compact"
        >
          <div class="toolbar-list__expand-content">
            <v-icon v-if="labelsVisible" size="small">
              mdi-arrow-collapse-left
            </v-icon>
            <v-icon v-else size="small"> mdi-arrow-expand-right </v-icon>
          </div>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { useRoute } from '@/composables/router';
import AppToolbarItem from '@/components/toolbar/appToolbarItem.vue';
import DesignNavigationDrawerDraggable from './designNavigationDrawerDraggable.vue';
import { useProjectLayout } from '@/composables/projectLayout';
import { ToolbarContext } from '@/components/layout/types';
import AppToolbarDeadline from '@/components/toolbar/appToolbarDeadline.vue';
import { RouteName } from '@/enums/routing';
import { Context } from '@/models/context';

type Props = {
  labelsVisible: boolean;
  miniToolbarWidth: number;
};

type Emits = {
  (e: 'expanded'): void;
};

defineProps<Props>();
const emit = defineEmits<Emits>();
const toolbar = inject<ToolbarContext>('toolbarContext');

const store = useDirectStore();
const route = useRoute();

const openView = ref([]);

const { loadingContexts, projectBasedRoutes, contextBasedRoutes, moreRoutes } =
  useProjectLayout();

const designNavigationDrawer = ref();

const projectId = computed<string>(() =>
  route.value.params.projectId?.toString()
);

const domaExpanded = () => {
  if (!toolbar.isExpanded) emit('expanded');
};

const isInSimo = computed(
  () =>
    route.value.name === RouteName.Simo ||
    route.value.name === RouteName.RevisionSimo
);

const contextsLabel = computed(() => {
  return isInSimo.value ? 'SIMO' : 'CORE';
});

const canSeeDoma = computed(() => contextBasedRoutes.value.length > 1);

const isContextDisabled = (contextId: string) => {
  const context: Context = store.context.getSimplifiedResourceSet()[contextId];

  if (isInSimo.value) {
    return !context?.simo_classification_table;
  }
};

const showSyntaxUpdateBadge = (contextId: string) => {
  const context = store.context.getSimplifiedResourceSet()[
    contextId
  ] as Context;
  return !!context?.temporal;
};

watch(
  () => toolbar.isExpanded,
  (newValue: boolean) => {
    openView.value = newValue ? ['TreeView'] : [];
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
$font-size: 20px;

.toolbar-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0 0;

  :deep(.v-badge.app-toolbar-item__badge) {
    .v-icon {
      font-size: $font-size;
    }
  }
}

.toolbar-list--small-gap {
  gap: 12px;
}

.toolbar-list__item--icon-center {
  :deep(.v-list-item__icon) {
    justify-items: center;
  }
}

.toolbar-list__expand-content {
  flex-grow: 0;
  flex-basis: auto;
  display: flex;
  justify-content: flex-end;
}

.toolbar-list__subheading {
  text-transform: uppercase;
  padding-left: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;

  > :deep(.v-list-subheader__text) {
    overflow: unset;
  }
}

.toolbar-list__subheading--center {
  justify-content: center;
}

.design-navigation-drawer__context-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: $font-size;
  padding-left: 6px;
  min-width: 17px;
}

.design-navigation-drawer__context-loader {
  :deep(.v-skeleton-loader__list-item) {
    background-color: transparent;
  }
}

.app-version__item {
  padding: 0.25rem;
  padding-inline-start: 0.25rem !important;
  padding-inline-end: 0.25rem !important;
}

::v-deep .v-navigation-drawer__content {
  scrollbar-width: thin;
  scrollbar-color: #e6e9ef #5c5f77;
}
</style>
