import { WebSocketEvent, WebSocketEventResponse } from '@/models/ws';
import { ResourceType } from './resources';
import { wasRequestPerformed, HEADER_REQUEST_ID } from './api';
import { AxiosResponse } from 'axios';

type OperationIdentifier =
  | {
      operation: Exclude<WebSocketEvent['operation'], 'create'>;
      targetType: ResourceType;
      targetId: string;
    }
  | {
      operation: 'create';
      targetType: ResourceType;
    };

const isNewReturnSchema = (payload: OperationIdentifier) => !payload.targetType;
export class DelayedResponseService {
  private expectedOperations: OperationHandler[] = [];
  private delayedResponses: DelayedResponseHandler[] = [];
  private unhandledResponses: Record<
    string,
    WebSocketEventResponse | undefined
  > = {};

  public runOperationHandlers(
    payload: OperationIdentifier & {
      data: WebSocketEventResponse;
      event: MessageEvent;
    }
  ): boolean {
    if (isNewReturnSchema(payload)) return false;
    const { operation, targetType } = payload;
    const targetId = (payload as { targetId: unknown }).targetId as string;
    const isNotification = targetType === 'notification';
    const isExternalMessage = payload.data.meta?.inbound_message;
    const isDotNetPayload = !payload.targetType && !payload.operation;
    if (isExternalMessage || isNotification || isDotNetPayload) return false;
    const requestId = payload.data.data?.attributes?.request_id;
    if (wasRequestPerformed(requestId)) {
      return this.handlePerformedRequest(requestId, payload.data);
    }
    const handler = this.getExpectedOperationHandler({
      operation,
      targetType,
      targetId,
    });
    if (handler) {
      const message = { data: payload.data, event: payload.event };
      const transformedRes = handler.transformWsResponse
        ? handler.transformWsResponse(message)
        : undefined;
      this.resolveOperationHandler(handler, transformedRes);
      return !!handler.preventsDefault;
    }
    this.unhandledResponses[requestId] = payload.data;
    return false;
  }

  public cleanOperationHandlers() {
    this.expectedOperations.forEach((op) => op.reject());
    this.expectedOperations = [];
    this.unhandledResponses = {};
  }

  private getExpectedOperationHandler(payload: {
    operation: WebSocketEvent['operation'];
    targetType: ResourceType;
    targetId?: string;
  }): OperationHandler<unknown> {
    return this.expectedOperations.find((op) => {
      return (
        op.operation === payload.operation &&
        op.targetType === payload.targetType &&
        (payload.operation === 'create' ||
          ('targetId' in op && op.targetId === payload.targetId))
      );
    });
  }

  private handlePerformedRequest(
    requestId: string,
    data: WebSocketEventResponse
  ) {
    const handler = this.delayedResponses.find(
      (h) => h.requestId === requestId
    );
    if (handler) this.handleDelayedResponse(handler, data);
    return true;
  }

  private rejectOperationHandler(handler: OperationHandler, reason: unknown) {
    if (handler.pending) {
      this.expectedOperations = this.expectedOperations.filter(
        (h) => h !== handler
      );
      handler.pending = false;
      handler.reject(reason);
    }
  }

  private resolveOperationHandler<Res>(
    handler: OperationHandler<Res>,
    response: Res
  ) {
    if (handler.pending) {
      this.expectedOperations = this.expectedOperations.filter(
        (h) => h !== handler
      );
      handler.pending = false;
      handler.resolve(response);
    }
  }

  private handleDelayedResponse(
    handler: DelayedResponseHandler,
    response: WebSocketEventResponse
  ) {
    if (handler.pending) {
      this.delayedResponses = this.delayedResponses.filter(
        (h) => h !== handler
      );
      handler.pending = false;
      if (response.data.attributes.operation === 'failure') {
        handler.reject({
          response: {
            data: {
              errors: response.data.attributes.errors,
            },
          },
        });
      } else {
        handler.resolve(response);
      }
    }
  }

  public waitForOperation<Res>(
    promise: Promise<Res>,
    payload: OperationIdentifier & OperationConfig<Res>
  ) {
    return new Promise<Res>((resolve, reject) => {
      const handler = { ...payload, resolve, reject, pending: true };
      this.expectedOperations.push(handler);
      promise
        .then((data) => this.resolveOperationHandler(handler, data))
        .catch((err) => this.rejectOperationHandler(handler, err));
    });
  }

  public waitForDelayedResponse<
    Res extends WebSocketEventResponse = WebSocketEventResponse,
  >(promise: Promise<AxiosResponse>) {
    return new Promise<Res>((resolve, reject) => {
      promise
        .then((data) => {
          const requestId = data.headers[HEADER_REQUEST_ID];
          const handler = { requestId, resolve, reject, pending: true };
          this.delayedResponses.push(handler);
          if (this.unhandledResponses[requestId]) {
            const unhandledRes = this.unhandledResponses[requestId];
            delete this.unhandledResponses[requestId];
            this.handleDelayedResponse(handler, unhandledRes);
          }
        })
        .catch((err) => reject(err));
    });
  }
}

export type OperationConfig<Res> = {
  transformWsResponse?: (wsRes: {
    data: WebSocketEventResponse;
    event: MessageEvent;
  }) => Res;
  preventsDefault?: boolean;
};

type DelayedResponseIdentifier = {
  requestId: string;
};

type ResponseHandler<Res = unknown> = {
  pending: boolean;
  resolve: (response: Res | PromiseLike<Res>) => void;
  reject: (reason?: unknown) => void;
};

type OperationHandler<Res = unknown> = OperationIdentifier &
  OperationConfig<Res> &
  ResponseHandler<Res>;

type DelayedResponseHandler = DelayedResponseIdentifier &
  ResponseHandler<WebSocketEventResponse>;
