import { createModule } from '../utils/factories';
import { MeetingPlan } from '@/models/meetingPlanner';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '@/store/components/indexManager';

export default createModule({
  path: 'meetingPlanner',
  resourceProfile: resources.meetingPlanner,
  components: [crud, indexManager],
  setup({ components, getAccessors }) {
    const actions = {
      createResource(
        context,
        payload: {
          contextId: string;
          resource: Omit<MeetingPlan, 'id' | 'url'>;
        }
      ) {
        return components.$crud.public.dispatchCreateResource({
          resource: payload.resource,
          relationships: {
            context: payload.contextId,
          },
        });
      },
    };
    const { dispatch } = getAccessors();
    return {
      module: {
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        dispatchCreateResource: dispatch(actions.createResource),
      },
    };
  },
});
