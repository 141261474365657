import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { ComponentType } from './_types';
import jv from './jv';

export type ProcessingTrackerAccessors = ModuleComponentPublicAccessors<
  typeof processingTracker
>;

export type ProcessingStatus =
  | 'save'
  | 'publish'
  | 'archive'
  | 'delete'
  | 'move'
  | 'copy';

interface State {
  processingItems: {
    [key: string]: ProcessingStatus;
  };
}

const processingTracker = createModuleComponent({
  type: ComponentType.ProcessingTracker,
  dependencies: [jv],
  setup: ({ getAccessors }) => {
    const state = (): State => ({
      processingItems: {},
    });

    const getters = {
      itemProcessingStatus: (state: State) => (id: string) =>
        state.processingItems[id],
    };

    const mutations = {
      addProcessingItem(
        state: State,
        payload: { id: string; status: ProcessingStatus }
      ) {
        state.processingItems[payload.id] = payload.status;
      },
      removeProcessingItem(state: State, payload: { id: string }) {
        delete state.processingItems[payload.id];
      },
    };

    const actions = {
      addProcessingItem(
        context,
        payload: { id: string; status: ProcessingStatus }
      ) {
        commit(mutations.addProcessingItem)(payload);
      },
      removeProcessingItem(context, payload: { id: string }) {
        commit(mutations.removeProcessingItem)(payload);
      },
    };

    const { read, commit, dispatch } = getAccessors();

    return {
      module: {
        getters,
        state,
        mutations,
        actions,
      },
      public: {
        getItemProcessingStatus: read(getters.itemProcessingStatus),
        dispatchAddProcessingItem: dispatch(actions.addProcessingItem),
        dispatchRemoveProcessingItem: dispatch(actions.removeProcessingItem),
      },
    };
  },
});

export default processingTracker;
