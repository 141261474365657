import chroma from 'chroma-js';
import { getDictionaryFactory } from '@/utils/collections';
import { ThemeDefinition } from 'vuetify';

export const oocColorPalette: Array<string> = [
  '468013',
  '283199',
  '0080b7',
  '8b3f8b',
  'fd0100',
  'ffd700',
  '000000',
  '5ca718',
  '4a54d0',
  '00a8f0',
  'b45ab4',
  'ff8080',
  'fff911',
  '7f7f7f',
  '76d61f',
  'a0b3ef',
  '8cddff',
  'd183cf',
  'ffc4c4',
  'fffacd',
  'c3c3c3',
];

// A theme switcher was removed from the app, so there is no possibility
// to switch to the dark mode but for now, the code related to this will
// stay here in case it could be needed in the future.

type Theme = 'dark' | 'light';

type ThemeVariants = {
  dark: string;
  light: string;
  darken2?: string;
  darken1?: string;
  lighten1?: string;
  lighten2?: string;
  lighten3?: string;
  lighten4?: string;
  lighten5?: string;
};

export type ThemedColor = {
  [key in keyof ThemeDefinition['colors']]: {
    dark: string;
    light: string;
  };
};

// Green

export const setupPalette: ThemedColor = {
  primary: {
    dark: '#00695C',
    light: '#00695c',
  },
  'primary-darken-2': { dark: '#00392e', light: '#00392e' },
  'primary-darken-1': { dark: '#005045', light: '#005045' },
  'primary-lighten-1': { dark: '#2b8375', light: '#2b8375' },
  'primary-lighten-2': { dark: '#499d8e', light: '#499d8e' },
  'primary-lighten-3': { dark: '#64b8a9', light: '#64b8a9' },
  'primary-lighten-4': { dark: '#80d4c4', light: '#80d4c4' },
  'primary-lighten-5': { dark: '#eefcfa', light: '#eefcfa' },
};

// Blue

export const designPalette = getDictionaryFactory<ThemeVariants>()({
  primary: {
    dark: '#175082',
    light: '#175082',
  },
  'primary-darken-2': { dark: '#002C52', light: '#002C52' },
  'primary-darken-1': { dark: '#003666', light: '#003666' },
  'primary-lighten-1': { dark: '#206FA4', light: '#206FA4' },
  'primary-lighten-2': { dark: '#3d6f9c', light: '#3d6f9c' },
  'primary-lighten-3': { dark: '#638FB6', light: '#638FB6' },
  'primary-lighten-4': { dark: '#C9DCED', light: '#C9DCED' },
  'primary-lighten-5': { dark: '#EEF5FC', light: '#EEF5FC' },

  'background-darken-1': { dark: '#d6d6d6', light: '#d6d6d6' },

  'cardBackground-darken-1': { dark: '#e2e2e2', light: '#e2e2e2' },
});

// Red

export const adminPalette = getDictionaryFactory<ThemeVariants>()({
  primary: { dark: '#770844', light: '#87477E' },
  'primary-lighten-4': { dark: '#EEDDED', light: '#EEDDED' },
});

export const launchpadPalette = getDictionaryFactory<ThemeVariants>()({
  primary: { dark: '#000064', light: '#000064' },
  secondary: { dark: '#ed7d31', light: '#ed7d31' },
});

export const themedColorPalette = getDictionaryFactory<ThemeVariants>()({
  primary: { dark: '#4DB6AC', light: '#00695C' },
  secondary: { dark: '#78909c', light: '#546E7A' },
  accent: { dark: '#FFE18D', light: '#F9A825' },
  error: { dark: '#FF5722', light: '#FF5722' },
  info: { dark: '#1E88E5', light: '#1E88E5' },
  success: { dark: '#43a047', light: '#43a047' },
  warning: { dark: '#FDD835', light: '#FDD835' },

  headerBackground: { dark: '#232323', light: '#E6E6E6' },
  background: { dark: '#000000', light: '#F2F2F2' },
  color: { dark: '#FAFAFA', light: '#1B1B1B' },
  colorInverse: { dark: '#000000', light: '#FAFAFA' },
  backgroundInverse: { dark: '#FAFAFA', light: '#1B1B1B' },
  line: { dark: '#6B6B6B', light: '#E0E0E0' },
  wrapperBackground: { dark: '#0F0F0F', light: '#EFEFEF' },
  cardBackground: { dark: '#222', light: '#FFF' },
  grayScale: { dark: '#999', light: '#999' },
  tradeStudy: { dark: '#99BE4E', light: '#99BE4E' },

  diffAddition: { dark: '#33a340', light: '#33a340' },
  diffDeletion: { dark: '#d1776b', light: '#d1776b' },
  diffChange: { dark: '#eebd18', light: '#eebd18' },
  diffAdditionBackground: { dark: '#ccefd0', light: '#ccefd0' },
  diffDeletionBackground: { dark: '#efd0cc', light: '#efd0cc' },
  diffChangeBackground: { dark: '#fff3ca', light: '#fff3ca' },

  syntaxChangeCode: { dark: '#efd0cc', light: '#efd0cc' },
  syntaxChangeDefinition: { dark: '#fff3ca', light: '#fff3ca' },

  appSetup: setupPalette.primary,
  appDesign: designPalette.primary,
  appAdmin: adminPalette.primary,
  appLaunchpad: launchpadPalette.primary,
});

export type ColorType = keyof typeof themedColorPalette;

export type Lightness =
  | 'darken-1'
  | 'darken-2'
  | 'darken-3'
  | 'darken-4'
  | 'darken-5'
  | 'lighten-1'
  | 'lighten-2'
  | 'lighten-3'
  | 'lighten-4'
  | 'lighten-5';

export function getPaletteByTheme(
  theme: Theme,
  overrides: Partial<
    Record<keyof typeof themedColorPalette, ThemeVariants>
  > = {}
) {
  const colorMap = { ...themedColorPalette, ...overrides };
  return Object.keys(colorMap).reduce(
    (acc, colorType: ColorType) => {
      acc[colorType] = colorMap[colorType][theme];
      return acc;
    },
    {} as Record<ColorType, string>
  );
}

export const simoHighlightColorOwnersBridge = chroma('#8cddff')
  .alpha(0.3)
  .css();

export const simoHighlightColorOwnerGroupsBridge = chroma('#8b3f8b')
  .alpha(0.3)
  .css();
export const simoHighlightColorDefault = chroma(
  // designPalette.primary.light.lighten4
  designPalette['primary-lighten-4'].light
)
  .alpha(0.5)
  .css();
