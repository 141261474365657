import {
  createModuleComponent,
  ModuleComponentPublicAccessors,
} from '../utils/factories';
import { ComponentType } from './_types';
import { ResourceIdentification } from './crud';
import jv from './jv';
import { entitySimplifier } from '@/utils/jvTools';
import { buildRequestPath } from '@/utils/path';

export type RelationManagerAccessors = ModuleComponentPublicAccessors<
  typeof relationManager
>;

const relationManager = createModuleComponent({
  type: ComponentType.RelationManager,
  dependencies: [jv],
  setup: ({ getAccessors, resourceProfile, components }) => {
    const state = () => ({});

    const mutations = {};

    const getters = {};

    const actions = {
      async loadResourceRelationByLinkage(
        context,
        payload: ResourceIdentification & { relationshipKey: string }
      ) {
        const { resourceId, relationshipKey } = payload;
        const relationship =
          resourceProfile.relationships &&
          resourceProfile.relationships[relationshipKey];
        if (!relationship) throw new Error('No such relationship');
        if (Array.isArray(relationship))
          throw new Error('Not implemented for union type relationship');
        const jvGet = components.$jv.protected.get;
        const resource = jvGet({ id: resourceId, type: resourceProfile.type });
        const link =
          resource?._jv?.relationships?.[relationshipKey]?.links?.related;
        if (!link) {
          console.warn('No linkage information assuming empty relation');
          return null;
        }
        const data = await components.$jv.protected.dispatchGet(link);
        const simplifyRelatedResource = entitySimplifier(relationship);

        if ('id' in data._jv) {
          return simplifyRelatedResource(
            jvGet({ id: data._jv.id, type: relationship.type })
          );
        } else {
          const { ...ids } = data;
          return Object.keys(ids).map((id) =>
            simplifyRelatedResource(jvGet({ id, type: relationship.type }))
          );
        }
      },
      async updateResourceRelation(
        context,
        payload: ResourceIdentification & {
          relationshipKey: string;
          relationshipId: string;
          syntaxElement?: string;
        }
      ) {
        const relationship =
          resourceProfile.relationships[payload.relationshipKey];
        if (Array.isArray(relationship))
          throw new Error('Not implemented for union type relationship');
        const updatedRelation = {
          _jv: {
            type: relationship.type,
            id: payload.relationshipId,
          },
        };
        if (payload.syntaxElement) {
          updatedRelation._jv['relationships'] = {
            syntax_element: {
              data: {
                id: payload.syntaxElement,
                type: 'syntax_element',
              },
            },
          };
        }
        const entityToPreserve = {
          ...components.$jv.protected.get({
            id: payload.relationshipId,
            type: relationship.type,
          }),
        };
        const url = `/${resourceProfile.path}/${payload.resourceId}/relationships/${payload.relationshipKey}`;
        await components.$jv.protected.dispatchPatch([
          updatedRelation,
          { url },
        ]);
        components.$jv.protected.commitAddRecords(entityToPreserve);
      },
      async createRelationship(
        context,
        payload: {
          relationshipKey: string;
          relationshipId: string;
        } & ResourceIdentification
      ) {
        const newResource = {
          _jv: {
            type: resourceProfile.type,
            id: payload.resourceId,
          },
        };
        const path = buildRequestPath(resourceProfile, payload);
        const data = await components.$jv.protected.dispatchPost([
          newResource,
          { url: `/${path}` },
        ]);
        return { id: data._jv.id };
      },
      async deleteRelationship(
        context,
        payload: ResourceIdentification & {
          targetPath: string;
          sourceId: string;
        }
      ) {
        const { resourceId, targetPath, sourceId } = payload;
        await components.$jv.protected.dispatchDelete([
          { _jv: { type: resourceProfile.type, id: payload.resourceId } },
          {
            url: `/${targetPath}/${resourceId}/relationships/${resourceProfile.path}/${sourceId}`,
          },
        ]);
      },
    };

    const { dispatch } = getAccessors();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        dispatchLoadResourceRelationByLinkage: dispatch(
          actions.loadResourceRelationByLinkage
        ),
        dispatchCreateRelationship: dispatch(actions.createRelationship),
        dispatchUpdateResourceRelation: dispatch(
          actions.updateResourceRelation
        ),
        dispatchDeleteRelationship: dispatch(actions.deleteRelationship),
      },
    };
  },
});

export default relationManager;
