import { range } from '../math';

const DEFAULT_PAGE_SIZE = 30;

export function pageToStartIndex(page: number, pageSize = DEFAULT_PAGE_SIZE) {
  return Math.max(0, (page - 1) * pageSize);
}

export function pageToEndIndex(
  page: number,
  totalCount: number,
  pageSize = DEFAULT_PAGE_SIZE
) {
  return Math.min(totalCount, pageToStartIndex(page, pageSize) + pageSize);
}

export function itemIndexToPageNumber(
  itemIndex: number,
  pageSize = DEFAULT_PAGE_SIZE
): number {
  return Math.floor(itemIndex / pageSize) + 1;
}

export function itemIndexRangeToPagesRange(
  startIndex: number,
  endIndex: number,
  pageSize = DEFAULT_PAGE_SIZE
): number[] {
  const startPage = itemIndexToPageNumber(startIndex, pageSize);
  const endPage = itemIndexToPageNumber(endIndex - 1, pageSize);
  return range(startPage, endPage + 1);
}

export function extractPageFromPath(path: string): number {
  const url = new URL(path);
  return parseInt(url.searchParams.get('page[number]'), 10);
}

export function parsePageLinks(links?: { current?: string; last?: string }) {
  if (!links || !links.current) return null;
  const currentPage = extractPageFromPath(links.current);
  const lastPage =
    (links.last && extractPageFromPath(links.last)) || currentPage;
  return {
    currentPage,
    lastPage,
  };
}

export function paginate<T>(
  items: T[] = [],
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE
): T[] {
  const from = pageToStartIndex(page, pageSize);
  const to = pageToEndIndex(page, items.length, pageSize);
  return items.slice(from, to);
}

export function fullPaginatedResource<T>(
  items: T[],
  totalCount = 0
): Array<T | null> {
  return new Array(totalCount)
    .fill(null)
    .map((_, index) => items[index] || null);
}

/**
 * Page is indexed if its items number matches exactly the PAGE_SIZE
 * and there are no null or undefined items
 */
export function isPageIndexed<T>(
  items: T[],
  page: number,
  pageSize = DEFAULT_PAGE_SIZE
): boolean {
  if (!items) return false;
  const pageItems = paginate(items, page, pageSize);
  return (
    pageItems.length === pageSize &&
    !pageItems.includes(null) &&
    !pageItems.includes(undefined)
  );
}

export function lastPageNumber(
  totalCount: number,
  pageSize = DEFAULT_PAGE_SIZE
): number {
  return Math.ceil(totalCount / pageSize) || 1;
}
