import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import jv from '../components/jv';
import { buildMultipleRelationsRequestPath } from '@/utils/path';
import { JsonApiIdentification } from '@/models/api';
import { JVRestructuredRecord } from '@/models/jv';

export interface DeltaActionPayload {
  contextId: string;
  tradeStudyId: string;
  deltaId: string;
  groupId: string;
  subgroupDeltaIds: string[];
}

type State = Record<string, never>;

enum DeltaMergeAction {
  Accept = 'accept',
  Reject = 'reject',
  Unreject = 'unreject',
}

export default createModule({
  path: 'tradeStudyDeltas',
  resourceProfile: resources.tradeStudyDelta,
  components: [crud, jv],
  setup({ getAccessors, components, resourceProfile }) {
    const state = (): State => ({});

    const mutations = {};

    const getters = {};

    const actions = {
      async deltaAction(
        context,
        payload: { action: DeltaMergeAction } & DeltaActionPayload
      ) {
        const { contextId, tradeStudyId, deltaId, groupId, subgroupDeltaIds } =
          payload;
        const $jv = components.$jv.protected;
        const path = buildMultipleRelationsRequestPath({
          baseResourceProfile: resources.contexts,
          baseResourceId: contextId,
          relationships: [
            { resourceProfile: resources.tradeStudy, id: tradeStudyId },
            { resourceProfile, id: deltaId, suffix: payload.action },
          ],
        });
        await $jv.dispatchPost([
          { _jv: { type: resourceProfile.type } },
          { url: path },
        ]);
        dispatch(actions.clearAndUpdateRecords)({
          deltaIds: subgroupDeltaIds,
          groupId,
        });
      },
      async rejectDelta(context, payload: DeltaActionPayload) {
        return dispatch(actions.deltaAction)({
          ...payload,
          action: DeltaMergeAction.Reject,
        });
      },

      async acceptDelta(context, payload: DeltaActionPayload) {
        return dispatch(actions.deltaAction)({
          ...payload,
          action: DeltaMergeAction.Accept,
        });
      },

      async unrejectDelta(context, payload: DeltaActionPayload) {
        return dispatch(actions.deltaAction)({
          ...payload,
          action: DeltaMergeAction.Unreject,
        });
      },

      clearAndUpdateRecords(
        context,
        payload: { deltaIds: string[]; groupId: string }
      ) {
        const $jv = components.$jv.protected;
        const { deltaIds, groupId } = payload;
        const targetDeltaGroup = $jv.get({
          id: groupId,
          type: resources.tradeStudyDeltaGroup.type,
        }) as JVRestructuredRecord;
        const relationsToStay = (
          targetDeltaGroup._jv.relationships.deltas
            .data as JsonApiIdentification[]
        ).filter((delta) => !deltaIds.includes(delta.id));
        dispatch(actions.updateGroupRelationDeltas)({
          targetDeltaGroup,
          relationsToStay,
        });
      },
      updateGroupRelationDeltas(
        context,
        payload: {
          targetDeltaGroup: JVRestructuredRecord;
          relationsToStay: JsonApiIdentification[];
        }
      ) {
        const $jv = components.$jv.protected;
        const { targetDeltaGroup, relationsToStay } = payload;
        const updatedDeltaGroup = {
          ...targetDeltaGroup,
          _jv: {
            ...targetDeltaGroup._jv,
            relationships: {
              ...targetDeltaGroup._jv.relationships,
              deltas: { data: relationsToStay },
            },
          },
        };
        $jv.commitReplaceRecords(updatedDeltaGroup);
      },
    };

    const { dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {},
      public: {
        getSimplifiedResourceSet:
          components.$crud.public.getSimplifiedResourceSet,
        dispatchRejectDelta: dispatch(actions.rejectDelta),
        dispatchAcceptDelta: dispatch(actions.acceptDelta),
        dispatchUnrejectDelta: dispatch(actions.unrejectDelta),
      },
    };
  },
});
