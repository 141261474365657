import { Wormhole } from 'portal-vue';
import { computed } from 'vue';

type PortalKeys =
  | 'app-toolbar-print'
  | 'app-toolbar-export'
  | 'app-toolbar-zoom'
  | 'app-toolbar-search'
  | 'app-toolbar-main'
  | 'app-toolbar-list-view'
  | 'app-toolbar-trade-study'
  | 'app-toolbar-meeting-planner'
  | 'app-toolbar-heat-map'
  | 'app-toolbar-revision';

export const useAppLayout = () => {
  const hasContentForPrint = computed(() => hasContentFor('app-toolbar-print'));
  const hasContentForSearch = computed(() =>
    hasContentFor('app-toolbar-search')
  );
  const hasContentForExport = computed(() =>
    hasContentFor('app-toolbar-export')
  );
  const hasContentForZoom = computed(() => hasContentFor('app-toolbar-zoom'));
  const hasContentForListView = computed(() =>
    hasContentFor('app-toolbar-list-view')
  );
  const hasCustomTools = computed(() => hasContentFor('app-toolbar-main'));
  const hasContentForTradeStudy = computed(() =>
    hasContentFor('app-toolbar-trade-study')
  );
  const hasContentForMeetingPlanner = computed(() =>
    hasContentFor('app-toolbar-meeting-planner')
  );
  const hasContentForHeatMap = computed(() =>
    hasContentFor('app-toolbar-heat-map')
  );
  const hasContentForRevision = computed(() =>
    hasContentFor('app-toolbar-revision')
  );

  const hasContentFor = (key: PortalKeys) => !!Wormhole.transports.has(key);

  return {
    hasContentForPrint,
    hasContentForSearch,
    hasContentForExport,
    hasContentForZoom,
    hasContentForListView,
    hasContentForTradeStudy,
    hasCustomTools,
    hasContentForMeetingPlanner,
    hasContentForHeatMap,
    hasContentForRevision,
  };
};
