<template>
  <v-list-item
    class="draggable-item"
    :class="{
      'draggable-item--dragging': isDragging,
    }"
    @dragover="$emit('dragOver', $event)"
    @dragend="$emit('dragEnd', $event)"
    @dragstart="$emit('dragStart', $event)"
    @dragenter="$emit('dragEnter', $event)"
    @dragLeave="$emit('dragLeave', $event)"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <div class="draggable-item__content" :draggable="!disabled">
      <v-icon
        v-if="!disabled && !hideDraggableIcon"
        draggable="true"
        class="draggable-item-move"
      >
        mdi-drag-vertical
      </v-icon>
      <slot name="content" />
    </div>
  </v-list-item>
</template>

<script lang="ts">
import { Vue, Options, Prop } from 'vue-property-decorator';
@Options({
  emits: ['dragStart', 'dragEnd', 'dragEnter', 'dragLeave', 'dragOver'],
})
export default class DraggableListItem extends Vue {
  @Prop({ default: false }) isDragging: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) hideDraggableIcon?: boolean;
}
</script>

<style lang="scss" scoped>
.draggable-item {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  :deep(.v-list-item__content) {
    width: 100%;
  }
}
.draggable-item__content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.draggable-item__actions {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.draggable-item--dragging {
  opacity: 0.4;
}
.draggable-item-move {
  cursor: move;
}
</style>
