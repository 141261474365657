import { OORCreatePayload } from '@/components/design/simo/types';
import { JsonApiIdentification, JsonApiResource } from '@/models/api';
import { JVRestructuredCollection, JVRestructuredRecord } from '@/models/jv';
import { OOC } from '@/models/objectOccurrence';
import { ObjectOccurrenceRelation } from '@/models/objectOccurrenceRelation';
import { OOCComparisonResult, Revision } from '@/models/revision';
import { api } from '@/services/api';
import { resources } from '@/services/resources';
import { RelationType } from '@/utils/objectOccurrenceRelations';
import {
  itemIndexRangeToPagesRange,
  pageToEndIndex,
  pageToStartIndex,
} from '@/utils/pagination/utils';
import {
  PathParameters,
  buildIncludeParam,
  buildParametersPath,
  extractPollingId,
  getProjectIdFromUrlPath,
} from '@/utils/path';
import { GridViewport, createOorComparisonMeta } from '@/utils/simo';
import {
  ChainAnalysisPayload,
  SimoFiltersValue,
  SimoSortValues,
  getOocFilters,
  serializeSimoSession,
} from '@/utils/simo/filters';
import { debounce } from '@/utils/tools';
import { utils as jvUtils } from 'jsonapi-vuex';
import indexManager from '../components/indexManager';
import jv from '../components/jv';
import simoCrud, { SimoContext } from '../components/simoCrud';
import simoIndexManager, {
  ChainAnalysisMeta,
  ComparisonMeta,
  UpdateRequestIndexPayload,
  getSourceTargetIndexes,
} from '../components/simoIndexManager';
import { createModule } from '../utils/factories';
import context from './context';
import customField from './customField';
import customFieldValue from './customFieldValue';
import jvBatch from './jvBatch';
import notifications from './notifications';
import ooc from './ooc';
import oorChainElement from './oorChainElement';
import revision from './revision';
// import { getProjectsByProjectByContextSimo } from '@/openapi/services.gen';
// import { localClient } from '@/openapi/client';
import { featureFlags } from '@/featureFlags';

export const SIMO_PATH = 'simo';
const STORAGE_KEY_SORT_BY_RDS = 'sec-sort-by-RDS';

export interface RelationContext {
  indexId: string;
  chainAnalysis: boolean;
  sourceId: string;
  targetId: string;
}

enum SimoRequestType {
  Simo = 'simo',
  ChainAnalysis = 'chainAnalysis',
  Revision = 'revision',
  RevisionChainAnalysis = 'revisionChainAnalysis',
}

type SimoContextState = {
  filters: SimoFiltersValue | null;
  sort?: SimoSortValues;
  heatMap?: boolean;
  chainAnalysis: ChainAnalysisPayload | null;
  filtersApplied?: boolean;
};

type ContextStateIdentifier = {
  contextId: string;
  comparisonBaseRevisionId?: string;
};

interface State {
  activeContext: ContextStateIdentifier;
  contextState: Record<string, SimoContextState>;
  dirtyStructure: boolean;
  comparisonRequestIndex: Partial<{
    [indexId: string]: Map<string, OOCComparisonResult | null>;
  }>;
  selectedOoc: OOC | null;
  isChainAnalysisVisible: boolean;
}

export type OORFilters = Omit<
  PathParameters,
  'filterObjectOccurrenceSourceIdsCont' | 'filterObjectOccurrenceTargetIdsCont'
>;

const OOC_PAGE_SIZE = 30;

const pathChainAnalysis = (oocId: string) => {
  return `chain_analysis/${oocId}`;
};
const pathRevisionComparison = (
  baseRevisionId: string,
  nextContextId: string
) => {
  return `revision_comparison/${baseRevisionId}/${nextContextId}`;
};

export default createModule({
  path: 'simo',
  resourceProfile: resources.objectOccurrenceRelations,
  components: [jv, simoIndexManager, simoCrud, indexManager],
  modules: [
    ooc,
    oorChainElement,
    context,
    notifications,
    jvBatch,
    customField,
    customFieldValue,
    revision,
  ],
  setup({ getAccessors, components, modules, resourceProfile }) {
    const $simoIndexManager = components.$simoIndexManager.protected;
    const $simoCrud = components.$simoCrud.public;

    const state = (): State => ({
      activeContext: {
        contextId: null,
        comparisonBaseRevisionId: null,
      },
      contextState: {},
      dirtyStructure: false,
      comparisonRequestIndex: {},
      selectedOoc: null,
      isChainAnalysisVisible: false,
    });

    const getters = {
      activeContextId: (state: State) => state.activeContext.contextId,
      revisionBasePath:
        () => (options?: { revisionId: string; contextId: string }) => {
          const revisionId =
            options?.revisionId || read(getters.activeBaseRevisionId)();
          const contextId =
            options?.contextId || read(getters.activeContextId)();
          if (!revisionId || !contextId) return null;
          return pathRevisionComparison(revisionId, contextId);
        },
      activeBaseRevisionId: (state: State) =>
        state.activeContext.comparisonBaseRevisionId,
      activeComparisonBaseContextId: (state: State) => {
        if (!state.activeContext.comparisonBaseRevisionId) return null;
        const nextContextId = state.activeContext.contextId;
        const baseRevision: Revision =
          modules.revision.public.getSimplifiedResourceSet()[
            state.activeContext.comparisonBaseRevisionId
          ];
        const getBaseContextId = modules.context.public.getBaseContextId;
        const revisionContext = baseRevision.project.contexts.find(
          (context) =>
            getBaseContextId(context.id) ===
            (getBaseContextId(nextContextId) || nextContextId)
        );
        return revisionContext.id;
      },
      contextStateIdentifier: () => (contextState: ContextStateIdentifier) => {
        return [contextState.contextId, contextState.comparisonBaseRevisionId]
          .filter(Boolean)
          .join('-');
      },
      activeContextStateIdentifier: () => {
        return read(getters.contextStateIdentifier)({
          contextId: read(getters.activeContextId)(),
          comparisonBaseRevisionId: read(getters.activeBaseRevisionId)(),
        });
      },
      activeSort: (state: State): SimoSortValues => {
        return (
          state.contextState[read(getters.activeContextStateIdentifier)()]
            ?.sort ||
          (localStorage.getItem(STORAGE_KEY_SORT_BY_RDS) as SimoSortValues) ||
          'reference_designation'
        );
      },
      activeHeatMap: (state: State): boolean => {
        return (
          state.contextState[read(getters.activeContextStateIdentifier)()]
            ?.heatMap || false
        );
      },
      filtersApplied: (state: State) =>
        state.contextState[read(getters.activeContextStateIdentifier)()]
          ?.filtersApplied || false,
      activeFilters: (state: State): SimoFiltersValue => {
        const activeFilters =
          state.contextState[read(getters.activeContextStateIdentifier)()]
            ?.filters;
        return {
          structureLevels: activeFilters?.structureLevels || [],
          oocProgress: activeFilters?.oocProgress || null,
          oorProgress: activeFilters?.oorProgress || null,
          includedSingleOocs: activeFilters?.includedSingleOocs || [],
          excludedSingleOocs: activeFilters?.excludedSingleOocs || [],
          includedParentOocs: activeFilters?.includedParentOocs || [],
          oocCodes: activeFilters?.oocCodes || [],
          oorCodes: activeFilters?.oorCodes || [],
          syntaxElementIds: activeFilters?.syntaxElementIds || [],
          owners: activeFilters?.owners || [],
          ownerGroups: activeFilters?.ownerGroups || [],
          ownerCompanies: activeFilters?.ownerCompanies || [],
          includeOocsRelatedToOwned:
            activeFilters?.includeOocsRelatedToOwned || false,
          highlightMatchingOwnershipFiltersCells:
            activeFilters?.highlightMatchingOwnershipFiltersCells || null,
          deadlines: activeFilters?.deadlines || [],
          showExternalInterfaces:
            activeFilters?.showExternalInterfaces || false,
        };
      },
      activeChainAnalysis: (state: State) => {
        const contextState =
          state.contextState[read(getters.activeContextStateIdentifier)()];
        return contextState?.chainAnalysis || null;
      },
      serializedSimoState: () => {
        const filters = read(getters.activeFilters)();
        const activeChainAnalysis = read(getters.activeChainAnalysis)();
        const sort = read(getters.activeSort)();
        const heatMap = read(getters.activeHeatMap)();
        return serializeSimoSession({
          filters,
          activeChainAnalysis,
          sort,
          heatMap,
        });
      },
      allIncludedOocs: (): OOC[] => {
        const filters = read(getters.activeFilters)();
        return [...filters.includedSingleOocs];
      },
      includedRegularOocs: (): OOC[] => {
        const activeChainAnalysis = read(getters.activeChainAnalysis)();
        return activeChainAnalysis
          ? [
              modules.ooc.public.getSimplifiedResourceSet()[
                activeChainAnalysis.oocId
              ],
            ]
          : read(getters.allIncludedOocs)().filter(
              (ooc) => ooc.object_occurrence_type === 'regular'
            );
      },
      includedExternalOocs: (): OOC[] => {
        const set = modules.ooc.public.getSimplifiedResourceSet();
        return read(getters.activeChainAnalysis)()
          ? []
          : read(getters.allIncludedOocs)().filter((ooc) => {
              return ooc.object_occurrence_type === 'external' && !!set[ooc.id]; // check if still exists (can be removed by user)
            });
      },
      ownersPathParameters: (): PathParameters => {
        const filters = read(getters.activeFilters)();
        const filterOOCOwnerIds =
          filters.owners?.map((owner) => owner.id) || [];
        const filterOOCOwnerGroupIds =
          filters.ownerGroups?.map((group) => group.id) || [];
        const filterOOCOwnerCompaniesIn = filters.ownerCompanies || [];
        if (
          !filterOOCOwnerIds.length &&
          !filterOOCOwnerGroupIds.length &&
          !filterOOCOwnerCompaniesIn.length
        )
          return {};
        return {
          filterOOCOwnerIds,
          filterOOCOwnerCompaniesIn,
          filterOOCOwnerGroupIds,
          filterOOCExcludeRelatedToOwned:
            !filters.includeOocsRelatedToOwned || undefined,
        };
      },
      oocsPathParameters: (state: State): PathParameters => {
        const isComparison = !!state.activeContext.comparisonBaseRevisionId;
        const filters = read(getters.activeFilters)();
        const oocRootNodeIds = [
          modules.context.public.getRelatedRootsNodeId(
            state.activeContext.contextId
          ),
          isComparison &&
            modules.context.public.getRelatedRootsNodeId(
              read(getters.activeComparisonBaseContextId)()
            ),
        ].filter(Boolean);
        const chainAnalysis = read(getters.activeChainAnalysis)();
        const oocsToIds = (oocs: OOC[]) => {
          return oocs
            .flatMap((ooc) => [
              ooc.id,
              isComparison &&
                read(getters.oocComparisonResult)(ooc.id)?.previous_ooc_id,
            ])
            .filter(Boolean);
        };
        return {
          sort: read(getters.activeSort)(),
          filterContextId: !state.activeContext.comparisonBaseRevisionId
            ? state.activeContext.contextId
            : undefined,
          ...(chainAnalysis
            ? {
                stepsBackward: chainAnalysis.inboundSteps,
                stepsForward: chainAnalysis.outboundSteps,
              }
            : {}),
          filterObjectOccurrenceStructureLevelIn: filters.structureLevels,
          filterOOCClassificationCodeIn: filters.oocCodes,
          filterSyntaxElementIds: filters.syntaxElementIds,
          filterProgressStepInOrderIn: filters.oocProgress?.orderIn,
          filterProgressStepInWithEmpty: filters.oocProgress?.withEmpty,
          filterAncestorIds: oocsToIds(filters.includedParentOocs),
          filterExclude: [
            ...oocRootNodeIds,
            ...oocsToIds(filters.excludedSingleOocs),
            ...oocsToIds(read(getters.includedRegularOocs)()),
            ...oocsToIds(read(getters.includedExternalOocs)()),
          ].filter((id, index, all) => all.indexOf(id) === index), // deduplicate
          ...read(getters.ownersPathParameters)(),
        };
      },
      oocsBasePath: (): string | undefined => {
        const chainAnalysis = read(getters.activeChainAnalysis)();
        const chainAnalysisPath =
          chainAnalysis && pathChainAnalysis(chainAnalysis.oocId);
        const revisionId = read(getters.activeBaseRevisionId)();
        const contextId = read(getters.activeContextId)();
        const revisionPath =
          revisionId && pathRevisionComparison(revisionId, contextId);
        const specialPathPrefix = [revisionPath, chainAnalysisPath]
          .filter(Boolean)
          .join('/');
        const filters = read(getters.activeFilters)();

        return getBasePathForObjectOccurences(
          filters,
          specialPathPrefix,
          'object_occurrences/externals'
        );
      },
      oorsPathParameters: (): OORFilters => {
        const chainAnalysis = read(getters.activeChainAnalysis)();
        return {
          ...(chainAnalysis
            ? {
                stepsBackward: chainAnalysis.inboundSteps,
                stepsForward: chainAnalysis.outboundSteps,
              }
            : {}),
        };
      },
      requestType: () => {
        const isChainAnalysis = !!read(getters.activeChainAnalysis)();
        const isRevision = !!read(getters.activeBaseRevisionId)();
        if (isChainAnalysis && isRevision)
          return SimoRequestType.RevisionChainAnalysis;
        if (isChainAnalysis) return SimoRequestType.ChainAnalysis;
        if (isRevision) return SimoRequestType.Revision;
        return SimoRequestType.Simo;
      },
      requestId: (state: State) => (pathParameters: PathParameters) => {
        const chainAnalysis = read(getters.activeChainAnalysis)();
        const baseRevisionId = read(getters.activeBaseRevisionId)();
        const filters = read(getters.activeFilters)();
        return [
          read(getters.requestType)(),
          state.activeContext.contextId,
          chainAnalysis ? chainAnalysis.oocId : '',
          baseRevisionId ? baseRevisionId : '',
          `showExternal:${filters.showExternalInterfaces}`,
          Object.entries(pathParameters)
            .map(([key, value]) => `${key}:${value}`)
            .join(';'),
        ].join('_');
      },
      oocComparisonResult: (state: State) => (oocId: string) => {
        return state.comparisonRequestIndex[
          read(getters.activeContextStateIdentifier)()
        ]?.get(oocId);
      },
      previousOocDict: () => (oocIds: string[]) => {
        const prevOocs: Record<string, string> = {};
        if (read(getters.activeBaseRevisionId)()) {
          oocIds.forEach((primaryOocId) => {
            const prevId = read(getters.oocComparisonResult)(
              primaryOocId
            )?.previous_ooc_id;
            if (!prevId) return;
            prevOocs[prevId] = primaryOocId;
          });
        }
        return prevOocs;
      },
      oocsRequestId: () =>
        read(getters.requestId)(read(getters.oocsPathParameters)()),
      oorsRequestId: () =>
        read(getters.requestId)(read(getters.oorsPathParameters)()),
      oocs: (): Array<OOC | null> => {
        let oocs = read(getters.includedRegularOocs)();
        oocs = oocs.concat(
          modules.ooc.public.getPaginatedResourceFull(
            read(getters.requestType)(),
            read(getters.oocsRequestId)()
          )
        );
        oocs = oocs.concat(read(getters.includedExternalOocs)());
        return oocs;
      },
      oocsLoading: () => {
        const anyPageLoading =
          modules.ooc.public.getPagesInProgress(
            read(getters.requestType)(),
            read(getters.oocsRequestId)()
          ).length > 0;
        return anyPageLoading;
      },
      oocsPageLoaded:
        () =>
        (page: number): boolean => {
          const oocs = read(getters.oocs)();
          return oocs
            .slice(
              pageToStartIndex(page, OOC_PAGE_SIZE),
              pageToEndIndex(page, oocs.length, OOC_PAGE_SIZE)
            )
            .every(Boolean);
        },
      isStructureDirty: (state: State) => state.dirtyStructure,
      getOocPagesInViewport: () => (viewport: GridViewport) => {
        const { x, y, width, height } = viewport;
        const sourcePages = itemIndexRangeToPagesRange(
          y,
          y + height,
          OOC_PAGE_SIZE
        );
        const targetPages = itemIndexRangeToPagesRange(
          x,
          x + width,
          OOC_PAGE_SIZE
        );
        return [...new Set([...sourcePages, ...targetPages])];
      },
      getOocsInViewport: () => (viewport: GridViewport) => {
        const { x, y, width, height } = viewport;
        const oocs = read(getters.oocs)();
        const targets = oocs.slice(x, x + width).filter(Boolean);
        const sources = oocs.slice(y, y + height).filter(Boolean);
        return { targets, sources };
      },
      getSelectedOoc: (state: State) => state.selectedOoc,
      getIsChainAnalysisVisible: (state: State) => state.isChainAnalysisVisible,
    };

    const mutations = {
      setOocComparisonResult(
        state: State,
        payload: { indexId: string; ooc: JVRestructuredRecord }
      ) {
        const { indexId, ooc } = payload;
        if (!state.comparisonRequestIndex[indexId]) {
          state.comparisonRequestIndex[indexId] = new Map();
        }
        const previousOocId =
          (ooc?._jv?.relationships?.previous_ooc?.data as JsonApiIdentification)
            ?.id || null;
        state.comparisonRequestIndex[indexId].set(ooc._jv.id, {
          ...ooc._jv.meta?.comparison,
          previous_ooc_id: previousOocId,
          next_ooc_id: ooc._jv.id,
        });
      },
      setContext(state: State, payload: ContextStateIdentifier) {
        state.activeContext = payload;
      },
      setSort(state: State, payload: { sort: SimoSortValues }) {
        const contextIdentifier = read(getters.activeContextStateIdentifier)();
        if (!contextIdentifier) return;
        if (state.contextState[contextIdentifier]) {
          state.contextState[contextIdentifier].sort = payload.sort;
        } else {
          state.contextState[contextIdentifier] = {
            sort: payload.sort,
            filters: state.contextState[contextIdentifier]?.filters,
            chainAnalysis: state.contextState[contextIdentifier]?.chainAnalysis,
          };
        }
      },
      setHeatMap(state: State, payload: { heatMap: boolean }) {
        const contextIdentifier = read(getters.activeContextStateIdentifier)();
        if (!contextIdentifier) return;
        if (state.contextState[contextIdentifier]) {
          state.contextState[contextIdentifier].heatMap = payload.heatMap;
        } else {
          state.contextState[contextIdentifier] = {
            ...state.contextState[contextIdentifier],
            heatMap: payload.heatMap,
          };
        }
      },
      setFilters(
        state: State,
        payload: { filters: SimoFiltersValue | null; filtersApplied?: boolean }
      ) {
        const contextIdentifier = read(getters.activeContextStateIdentifier)();
        if (!contextIdentifier) return;
        if (state.contextState[contextIdentifier]) {
          state.contextState[contextIdentifier].filters = payload.filters;
          state.contextState[contextIdentifier].filtersApplied =
            payload.filtersApplied || false;
        } else {
          state.contextState[contextIdentifier] = {
            filters: payload.filters,
            filtersApplied: payload.filtersApplied,
            chainAnalysis: null,
            sort: null,
          };
        }
      },
      setChainAnalysis(
        state: State,
        payload: {
          chainAnalysis: ChainAnalysisPayload | null;
        }
      ) {
        const contextIdentifier = read(getters.activeContextStateIdentifier)();
        if (!contextIdentifier) return;
        if (state.contextState[contextIdentifier]) {
          state.contextState[contextIdentifier].chainAnalysis =
            payload.chainAnalysis;
        } else {
          state.contextState[contextIdentifier] = {
            filters: null,
            chainAnalysis: payload.chainAnalysis,
          };
        }
      },
      setDirtyStructure(state: State, payload: { dirtyStructure: boolean }) {
        state.dirtyStructure = payload.dirtyStructure;
      },
      setSelectedOoc(state: State, payload: OOC | null) {
        state.selectedOoc = payload;
      },
      setIsChainAnalysisVisible(state: State, payload: boolean) {
        state.isChainAnalysisVisible = payload;
      },
    };

    const batchedRequests = new Map();

    // Thanks to debounce usage we can mitigate issue with data not being updated on BE during batch transaction
    const debouncedLoadOors = debounce(
      async (context, indexId, chainAnalysisFor, filters) => {
        const requests = Array.from(batchedRequests.values());
        batchedRequests.clear();

        const sourceIds = [...new Set(requests.flatMap((r) => r.sourceIds))];
        const targetIds = [...new Set(requests.flatMap((r) => r.targetIds))];

        return dispatch(actions.loadOorsInViewportExplicit)({
          sourceIds,
          targetIds,
          indexId,
          chainAnalysisFor,
          filters,
        });
      },
      1000
    );

    const actions = {
      setContext(context, payload: ContextStateIdentifier) {
        commit(mutations.setContext)(payload);
      },
      setSort(context, payload: { sort: SimoSortValues }) {
        commit(mutations.setSort)(payload);
        localStorage.setItem(STORAGE_KEY_SORT_BY_RDS, payload.sort);
      },
      setHeatMap(context, payload: { heatMap: boolean }) {
        commit(mutations.setHeatMap)(payload);
      },
      setFilters(
        context,
        payload: { filters: SimoFiltersValue | null; filtersApplied?: boolean }
      ) {
        commit(mutations.setFilters)(payload);
      },
      setChainAnalysis(
        context,
        payload: { chainAnalysis: ChainAnalysisPayload | null }
      ) {
        commit(mutations.setChainAnalysis)(payload);
      },
      setSelectedOoc(context, payload: OOC | null) {
        commit(mutations.setSelectedOoc)(payload);
      },
      setIsChainAnalysisVisible(context, payload: boolean) {
        commit(mutations.setIsChainAnalysisVisible)(payload);
      },
      setDirtyStructure(context, payload: { dirtyStructure: boolean }) {
        commit(mutations.setDirtyStructure)(payload);
      },
      async softReloadGrid(context, payload: { viewport: GridViewport }) {
        dispatch(actions.invalidateOorsOutsideViewport)({
          viewport: payload.viewport,
        });
        dispatch(actions.invalidateOocOutsideViewport)({
          viewport: payload.viewport,
        });
        dispatch(actions.reloadViewport)({ viewport: payload.viewport });
      },
      async initializeNewIndex(context, payload: ContextStateIdentifier) {
        if (read(getters.isStructureDirty)()) {
          dispatch(actions.invalidateOocIndexes)({ keepCurrent: false });
          dispatch(actions.setDirtyStructure)({ dirtyStructure: false });
        }
        commit(mutations.setContext)(payload);
        await dispatch(actions.loadOocsPage)({
          page: 1,
        });
      },
      async reloadGrid() {
        modules.ooc.public.dispatchResetIndex({
          requestType: read(getters.requestType)(),
          requestId: read(getters.oocsRequestId)(),
        });
        $simoIndexManager.commitResetRequestIndex({
          indexId: read(getters.oorsRequestId)(),
        });
        // TODO vue 3 branch used this getters.activeComparisonBaseContextId
        await dispatch(actions.initializeNewIndex)({
          contextId: read(getters.activeContextId)(),
          comparisonBaseRevisionId: read(getters.activeBaseRevisionId)(),
        });
      },
      async reloadCell(
        context,
        payload: { targetId: string; sourceId: string }
      ) {
        dispatch(actions.loadOorsInViewport)({
          targetIds: [payload.targetId],
          sourceIds: [payload.sourceId],
        });
      },
      async loadOocsPage(
        context,
        payload: { page: number; overwrite?: boolean }
      ) {
        if (read(getters.isStructureDirty)()) return Promise.resolve();

        const isRevision = !!read(getters.activeBaseRevisionId)();

        const res = await modules.ooc.public.dispatchLoadPaginatedResource({
          ...read(getters.oocsPathParameters)(),
          basePath: read(getters.oocsBasePath)(),
          requestType: read(getters.requestType)(),
          requestId: read(getters.oocsRequestId)(),
          pageInfo: {
            page: payload.page,
            pageSize: OOC_PAGE_SIZE,
          },
          overwrite: payload.overwrite,
        });
        // OOCs after filter and everything is applied.
        const oocs = res.jvData;

        // Find the OOCs for the selected deadlines
        const filters = read(getters.activeFilters)();
        const selectedDeadlineIds = filters.deadlines.map((dl) => dl.id);

        const projectId = getProjectIdFromUrlPath(window.location.pathname);
        // const contextId = getContextIdFromUrlPath(window.location.pathname);

        if (
          projectId &&
          selectedDeadlineIds.length &&
          featureFlags.isDeadlineFilterEnabled
        ) {
          // await getProjectsByProjectByContextSimo({
          //   client: localClient,
          //   path: {
          //     project: projectId,
          //     context: contextId,
          //   },
          //   query: {
          //     milestones: selectedDeadlineIds,
          //   },
          // });
        }

        // Do the filtering

        if (isRevision) {
          Object.values(oocs).forEach((ooc: JVRestructuredRecord) => {
            commit(mutations.setOocComparisonResult)({
              indexId: read(getters.activeContextStateIdentifier)(),
              ooc,
            });
          });
        }
      },
      async reloadViewport(context, payload: { viewport: GridViewport }) {
        const oocPagesToLoad = read(getters.getOocPagesInViewport)(
          payload.viewport
        );
        await Promise.all(
          oocPagesToLoad.map((page) =>
            dispatch(actions.loadOocsPage)({ page, overwrite: true })
          )
        );

        const { targets, sources } = read(getters.getOocsInViewport)(
          payload.viewport
        );
        return dispatch(actions.loadOorsInViewport)({
          sourceIds: sources.map((ooc) => ooc.id),
          targetIds: targets.map((ooc) => ooc.id),
        });
      },
      ensureOocsLoadedInViewport(
        context,
        payload: {
          viewport: GridViewport;
          updateProgress?: (v: number) => void;
        }
      ) {
        const pagesToLoad = read(getters.getOocPagesInViewport)(
          payload.viewport
        ).filter((page) => !read(getters.oocsPageLoaded)(page));
        let loaded = 0;
        return Promise.all(
          pagesToLoad.map((page) =>
            dispatch(actions.loadOocsPage)({ page }).then(() =>
              payload.updateProgress?.(++loaded / pagesToLoad.length)
            )
          )
        );
      },

      async ensureOorsLoadedInViewport(
        context,
        payload: { viewport: GridViewport; async?: boolean }
      ) {
        const indexId = read(getters.oorsRequestId)();
        const { targets, sources } = read(getters.getOocsInViewport)(
          payload.viewport
        );

        // filter out sources/targets for which all pairs (source, target) for all targets/sources have been loaded
        // in simo context it means that whole column / row have been loaded
        // e.g.
        // - when the given viewport is revisited request won't be made at all
        // - when scrolling vertically only additional rows will be requested
        const sourcesToLoad = sources
          .slice()
          .filter((source) =>
            targets.some(
              (target) =>
                !$simoIndexManager.getIsLoadedBySourceAndTarget(
                  indexId,
                  source.id,
                  target.id
                )
            )
          );
        const targetsToLoad = targets
          .slice()
          .filter((target) =>
            sources.some(
              (source) =>
                !$simoIndexManager.getIsLoadedBySourceAndTarget(
                  indexId,
                  source.id,
                  target.id
                )
            )
          );

        return dispatch(actions.loadOorsInViewport)({
          sourceIds: sourcesToLoad.map((ooc) => ooc.id),
          targetIds: targetsToLoad.map((ooc) => ooc.id),
          async: payload.async,
        });
      },
      async loadOorsSyncChainAnalysis(
        context,
        payload: {
          chainAnalysisFor: string;
          pathParameters: PathParameters;
          comparisonBaseRevisionId?: string;
        }
      ): Promise<{
        oorIds: string[];
        meta: Record<
          string,
          ChainAnalysisMeta | (ChainAnalysisMeta & ComparisonMeta)
        >;
      }> {
        const { chainAnalysisFor, pathParameters, comparisonBaseRevisionId } =
          payload;
        const pathPrefix = [
          comparisonBaseRevisionId &&
            pathRevisionComparison(
              comparisonBaseRevisionId,
              read(getters.activeContextId)()
            ),
          pathChainAnalysis(chainAnalysisFor),
        ]
          .filter(Boolean)
          .join('/');
        const basePath = `${pathPrefix}/object_occurrence_relations`;
        const requestType = 'simo';
        const randomIndexId = `simo-${Date.now()}.${Math.random()}`;
        const { jvData } =
          await modules.oorChainElement.protected.dispatchLoadFullResource({
            ...pathParameters,
            requestType,
            requestId: randomIndexId,
            basePath,
            pageSize: 250,
          });

        const meta = Object.values(jvData).reduce<
          Record<string, ChainAnalysisMeta>
        >((acc, oorChainElement) => {
          const oor = oorChainElement.object_occurrence_relation;
          const oorId = oor?._jv?.id;
          if (oorId) {
            const chainAnalysisMeta = {
              steps: oorChainElement.steps || [],
            };
            const comparisonMeta = comparisonBaseRevisionId
              ? createOorComparisonMeta(oor)
              : {};
            acc[oorId] = {
              ...chainAnalysisMeta,
              ...comparisonMeta,
            };
          }
          return acc;
        }, {});

        // prevent memory leaks
        modules.oorChainElement.protected.dispatchResetIndex({
          requestType,
          requestId: randomIndexId,
        });

        return { oorIds: Object.keys(meta), meta };
      },
      async loadOorsSync(
        context,
        payload: {
          pathParameters: PathParameters;
          comparisonBaseRevisionId?: string;
        }
      ): Promise<{ oorIds: string[]; meta?: Record<string, ComparisonMeta> }> {
        const { pathParameters, comparisonBaseRevisionId } = payload;
        const requestType = 'simo';
        const randomIndexId = `simo-${Date.now()}.${Math.random()}`;
        const basePath = comparisonBaseRevisionId
          ? `${pathRevisionComparison(
              comparisonBaseRevisionId,
              read(getters.activeContextId)()
            )}/object_occurrence_relations`
          : undefined;
        const { jvData } =
          await components.$indexManager.public.dispatchLoadFullResource({
            basePath,
            ...pathParameters,
            requestType,
            requestId: randomIndexId,
            pageSize: 250,
          });

        const oorIds = Object.keys(jvData);

        components.$indexManager.public.dispatchResetIndex({
          requestType,
          requestId: randomIndexId,
        });

        if (!comparisonBaseRevisionId)
          return {
            oorIds,
          };
        const meta = Object.entries(jvData).reduce<
          Record<string, ComparisonMeta>
        >((acc, [oorId, oor]) => {
          acc[oorId] = createOorComparisonMeta(oor);
          return acc;
        }, {});

        return {
          oorIds,
          meta,
        };
      },
      async loadOorsAsync(
        context,
        payload: {
          pathParameters: PathParameters;
          comparisonBaseRevisionId?: string;
        }
      ): Promise<{ oorIds: string[]; meta?: Record<string, ComparisonMeta> }> {
        const { pathParameters, comparisonBaseRevisionId } = payload;
        const revisionPath = comparisonBaseRevisionId
          ? `${pathRevisionComparison(
              comparisonBaseRevisionId,
              read(getters.activeContextId)()
            )}/`
          : '';
        const stringifiedPathParameters = buildParametersPath({
          ...pathParameters,
          include: buildIncludeParam(resourceProfile),
        });
        const response = await api.get(
          `/async/${revisionPath}${resourceProfile.path}/${stringifiedPathParameters}`
        );
        const pollLocation = response.headers.location;
        const pollId = extractPollingId(pollLocation);
        const results = await context.dispatch(
          'polling/addJob',
          {
            id: pollId,
            url: pollLocation,
            relationshipKey: resourceProfile.type,
            relatedId: pollId,
          },
          { root: true }
        );

        const rawOors = jvUtils.jsonapiToNorm(
          results.data.data as JsonApiResource[]
        ) as JVRestructuredCollection;
        components.$jv.protected.commitAddRecords(rawOors);
        const includedResources: JsonApiResource[] =
          results.data.included || [];
        includedResources.forEach((item) => {
          const includedItem = jvUtils.jsonapiToNormItem(item);
          components.$jv.protected.commitAddRecords(includedItem);
        });

        const oorIds = Object.keys(rawOors);

        if (!comparisonBaseRevisionId) return { oorIds };

        const meta = Object.entries(rawOors).reduce<
          Record<string, ComparisonMeta>
        >((acc, [id, oor]) => {
          acc[id] = createOorComparisonMeta(oor);
          return acc;
        }, {});
        return {
          oorIds,
          meta,
        };
      },

      async loadOorsInViewportExplicit(
        context,
        payload: {
          sourceIds: string[];
          targetIds: string[];
          indexId: string;
          chainAnalysisFor?: string;
          filters: OORFilters;
          async?: boolean;
        }
      ) {
        const { sourceIds, targetIds, chainAnalysisFor } = payload;
        if (
          !sourceIds.length ||
          !targetIds.length ||
          (sourceIds.length === 1 &&
            targetIds.length === 1 &&
            sourceIds[0] === targetIds[0])
        ) {
          return Promise.resolve();
        }

        const prevSources = read(getters.previousOocDict)(sourceIds);
        const prevTargets = read(getters.previousOocDict)(targetIds);

        const pathParameters: PathParameters = {
          ...payload.filters,
          ...getOocFilters({
            sourceIds: sourceIds.concat(Object.keys(prevSources)),
            targetIds: targetIds.concat(Object.keys(prevTargets)),
          }),
        };
        const comparisonBaseRevisionId = read(getters.activeBaseRevisionId)();
        let oorIds: string[];
        let requestIndexPayload: UpdateRequestIndexPayload;

        if (chainAnalysisFor) {
          const response = await dispatch(actions.loadOorsSyncChainAnalysis)({
            chainAnalysisFor,
            pathParameters,
            comparisonBaseRevisionId,
          });
          oorIds = response.oorIds;
          requestIndexPayload = {
            chainAnalysis: true,
            comparison: false,
            meta: response.meta,
          };
        } else if (payload.async) {
          const response = await dispatch(actions.loadOorsAsync)({
            pathParameters,
            comparisonBaseRevisionId,
          });
          oorIds = response.oorIds;
          requestIndexPayload = {
            chainAnalysis: false,
            comparison: !!comparisonBaseRevisionId,
            meta: response.meta,
          };
        } else {
          const response = await dispatch(actions.loadOorsSync)({
            pathParameters,
            comparisonBaseRevisionId,
          });
          oorIds = response.oorIds;
          requestIndexPayload = {
            chainAnalysis: false,
            comparison: !!comparisonBaseRevisionId,
            meta: response.meta,
          };
        }
        const set = $simoCrud.getSimplifiedResourceSet();
        const oors = oorIds.map<ObjectOccurrenceRelation>((id) => {
          const oor = set[id];
          const sourceId = components.$jv.protected.get({
            id,
            type: resourceProfile.type,
          })._jv?.relationships?.source?.data?.id;
          const targetId = components.$jv.protected.get({
            id,
            type: resourceProfile.type,
          })._jv?.relationships?.target?.data?.id;
          const source = prevSources[sourceId]
            ? { id: prevSources[sourceId] }
            : { id: sourceId };

          const target = prevTargets[targetId]
            ? { id: prevTargets[targetId] }
            : { id: targetId };

          return { ...oor, source, target };
        });

        const { indexId } = payload;
        $simoIndexManager.commitPrepareIndex({ indexId });
        $simoIndexManager.commitUpdateRequestIndex({
          indexId,
          sourceIds,
          targetIds,
          oors,
          ...requestIndexPayload,
        });
      },
      async loadOorsInViewport(
        context,
        payload: { sourceIds: string[]; targetIds: string[]; async?: boolean }
      ) {
        const { sourceIds, targetIds } = payload;
        const requestId = `${sourceIds.join()}-${targetIds.join()}`;
        batchedRequests.set(requestId, { sourceIds, targetIds });

        return debouncedLoadOors(
          context,
          read(getters.oorsRequestId)(),
          read(getters.activeChainAnalysis)()?.oocId,
          read(getters.oorsPathParameters)()
        );
      },
      invalidateOorsOutsideViewport(
        context,
        payload: { viewport: GridViewport }
      ) {
        const { sources: sourcesInViewport, targets: targetsInViewport } = read(
          getters.getOocsInViewport
        )(payload.viewport);
        const indexId = read(getters.oorsRequestId)();
        const sourceIds = sourcesInViewport.map((ooc) => ooc.id);
        const targetIds = targetsInViewport.map((ooc) => ooc.id);
        const stIndexesToPreserve = getSourceTargetIndexes(
          sourceIds,
          targetIds
        );
        $simoIndexManager.commitInvalidateIndexEntriesBySourceAndTarget({
          indexId,
          stIndexesToPreserve,
        });
      },
      async createExternalOoc(
        context,
        payload: { name: string; contextId: string }
      ) {
        const oocRootNodeId = modules.context.public.getRelatedRootsNodeId(
          read(getters.activeContextId)()
        );
        const { id: resourceId } =
          await modules.ooc.public.dispatchCreateResource({
            tree: { contextId: payload.contextId },
            resource: {
              name: payload.name,
              object_occurrence_type: 'external',
            },
            relationships: {
              object_occurrence: oocRootNodeId,
            },
          });
        await modules.ooc.public.dispatchInjectToPageIndex({
          resourceId,
          requestType: read(getters.requestType)(),
          requestId: read(getters.oocsRequestId)(),
          append: true,
        });
        await dispatch(actions.invalidateOocIndexes)({ keepCurrent: true });
      },
      async deleteExternalOoc(
        context,
        payload: { resourceId: string; contextId: string }
      ) {
        await modules.ooc.public.dispatchDeleteResource({
          tree: { contextId: payload.contextId },
          resourceId: payload.resourceId,
        });
        await modules.ooc.public.dispatchRemoveFromPageIndex({
          resourceId: payload.resourceId,
          requestType: read(getters.requestType)(),
          requestId: read(getters.oocsRequestId)(),
        });
        await dispatch(actions.invalidateOocIndexes)({ keepCurrent: true });
      },
      async invalidateOocIndexes(context, payload: { keepCurrent: boolean }) {
        await Promise.all(
          Object.keys(SimoRequestType).map((requestTypeKey) => {
            const requestType = SimoRequestType[requestTypeKey];
            return modules.ooc.public.dispatchResetIndexes({
              requestType,
              keepCurrent:
                payload.keepCurrent &&
                requestType === read(getters.requestType)(),
            });
          })
        );
      },
      invalidateOocOutsideViewport(
        context,
        payload: { viewport: GridViewport }
      ) {
        const pagesToKeep = read(getters.getOocPagesInViewport)(
          payload.viewport
        );
        dispatch(actions.invalidateOocPages)({ pagesToKeep });
      },
      invalidateOocPages(context, payload: { pagesToKeep: number[] }) {
        modules.ooc.protected.dispatchInvalidateOocPages({
          pagesToKeep: payload.pagesToKeep.map((page) => ({
            page,
            pageSize: OOC_PAGE_SIZE,
          })),
          requestType: read(getters.requestType)(),
          requestId: read(getters.oocsRequestId)(),
        });
      },
      async loadChainAnalysisFragment(
        context,
        payload: {
          chainAnalysisFor: string;
          size: number;
          stepsBackward?: number;
          stepsForward?: number;
        }
      ) {
        const {
          chainAnalysisFor,
          size,
          stepsBackward = 1,
          stepsForward = 1,
        } = payload;
        const requestType = 'CAFragment';
        const requestId = [
          chainAnalysisFor,
          size,
          stepsBackward,
          stepsForward,
        ].join(';');
        await modules.ooc.public.dispatchLoadPaginatedResource({
          basePath: `${pathChainAnalysis(chainAnalysisFor)}/object_occurrences`,
          requestType,
          requestId,
          stepsBackward,
          stepsForward,
          pageInfo: {
            pageSize: size,
            page: 1,
          },
        });
        const indexId = requestType + requestId;
        const oocs = modules.ooc.public.getFullResource(
          requestType,
          requestId
        ) as OOC[];
        await dispatch(actions.loadOorsInViewportExplicit)({
          indexId,
          chainAnalysisFor,
          filters: { stepsBackward, stepsForward },
          sourceIds: oocs.map((ooc) => ooc.id),
          targetIds: oocs.map((ooc) => ooc.id),
        });
        return $simoIndexManager.getIndex(indexId) || {};
      },
      async batchOperation(
        context,
        payload: SimoContext & {
          contextId: string;
          relations: { sourceId: string; targetId: string }[];
          relationType: Exclude<RelationType, RelationType.Known>;
          onlyDelete: boolean;
          oorsIds?: string[];
        }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();

        const {
          relations,
          relationType,
          onlyDelete,
          oorsIds,
          ...relationContext
        } = payload;

        if (oorsIds) {
          oorsIds.forEach((oor) => {
            $simoCrud.dispatchDeleteRelation({
              ...relationContext,
              resourceId: oor,
            });
          });
        }
        relations.forEach(({ sourceId, targetId }) => {
          const action = onlyDelete
            ? $simoCrud.dispatchRemoveSpecialRelations
            : relationType === RelationType.NoRelation
              ? $simoCrud.dispatchSetNoRelations
              : $simoCrud.dispatchSetUnknownRelations;

          action({
            ...relationContext,
            contextId: payload.contextId,
            sourceId,
            targetId,
            sync: true,
          });
        });
        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },
      async setRelationBatchOperation(
        context,
        payload: SimoContext & {
          contextId: string;
          relations: { sourceId: string; targetId: string }[];
          resource: OORCreatePayload;
          oorsIds: string[];
        }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        const { relations, contextId, resource, oorsIds, ...relationContext } =
          payload;

        if (oorsIds) {
          oorsIds.forEach((oor) => {
            $simoCrud.dispatchDeleteRelation({
              contextId,
              resourceId: oor,
              ...relationContext,
            });
          });
        }
        relations.forEach(({ sourceId, targetId }) => {
          $simoCrud.dispatchCreateRelation({
            resource: { name: resource.name, number: resource.number },
            sourceId,
            targetId,
            contextId,
            classificationEntryId: resource.classification_entry_id,
            sync: true,
            ...relationContext,
          });
        });

        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },
      async updateRelations(
        context,
        payload: Parameters<typeof $simoCrud.dispatchUpdateRelation>[0]
      ) {
        const updatedClassificationEntry =
          payload.relationships.classification_entry &&
          (typeof payload.relationships.classification_entry === 'string'
            ? payload.relationships.classification_entry
            : (
                payload.relationships
                  .classification_entry as JsonApiIdentification
              ).id);
        const classificationEntryHasChanged =
          !!updatedClassificationEntry &&
          updatedClassificationEntry !==
            $simoCrud.getRelationshipId(
              payload.resourceId,
              'classification_entry'
            );
        await $simoCrud.dispatchUpdateRelation(payload);
        if (classificationEntryHasChanged) {
          const customFields = modules.customField.public.getCustomFields({
            scope: {
              type: resources.contexts.type,
              id: read(getters.activeContextId)(),
            },
            parent: { type: resourceProfile.type },
          });

          customFields.forEach((customField) => {
            modules.customFieldValue.public.dispatchSetCustomFieldValue({
              value: '',
              parentId: payload.resourceId,
              parentType: resourceProfile.type,
              customFieldId: customField.id,
            });
          });
        }
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type GenericAction = (...args: any) => any;

    const withRelationContext =
      <T extends GenericAction>(action: T) =>
      (payload: Omit<Parameters<T>[0], 'indexId' | 'chainAnalysis'>) =>
        action({
          ...payload,
          indexId: read(getters.oorsRequestId)(),
          chainAnalysis: !!read(getters.activeChainAnalysis)(),
        });

    return {
      module: {
        state,
        mutations,
        getters,
        actions,
      },
      protected: {
        dispatchReloadCell: dispatch(actions.reloadCell),
      },
      public: {
        getOocsRequestId: read(getters.oocsRequestId),
        getOorsRequestId: read(getters.oorsRequestId),

        getRevisionBasePath: read(getters.revisionBasePath),
        getActiveContextId: read(getters.activeContextId),
        getActiveChainAnalysis: read(getters.activeChainAnalysis),
        getActiveSort: read(getters.activeSort),
        getActiveHeatMap: read(getters.activeHeatMap),
        getFiltersApplied: read(getters.filtersApplied),
        getActiveFilters: read(getters.activeFilters),
        getSerializeSimoSession: read(getters.serializedSimoState),
        getOocs: read(getters.oocs),
        getOocsLoading: read(getters.oocsLoading),
        getIsStructureDirty: read(getters.isStructureDirty),
        getOocComparisonResult: read(getters.oocComparisonResult),
        dispatchSetDirtyStructure: dispatch(actions.setDirtyStructure),
        getSelectedOoc: read(getters.getSelectedOoc),
        getIsChainAnalysisVisible: read(getters.getIsChainAnalysisVisible),

        dispatchCreateExternalOoc: dispatch(actions.createExternalOoc),
        dispatchDeleteExternalOoc: dispatch(actions.deleteExternalOoc),

        dispatchSetContext: dispatch(actions.setContext),
        dispatchSetSort: dispatch(actions.setSort),
        dispatchSetHeatMap: dispatch(actions.setHeatMap),
        dispatchSetFilters: dispatch(actions.setFilters),
        dispatchSetChainAnalysis: dispatch(actions.setChainAnalysis),
        dispatchSetSelectedOoc: dispatch(actions.setSelectedOoc),
        dispatchSetIsChainAnalysisVisible: dispatch(
          actions.setIsChainAnalysisVisible
        ),

        dispatchReloadGrid: dispatch(actions.reloadGrid),
        dispatchInitializeNewIndex: dispatch(actions.initializeNewIndex),
        dispatchSoftReloadGrid: dispatch(actions.softReloadGrid),

        dispatchEnsureOocsLoadedInViewport: dispatch(
          actions.ensureOocsLoadedInViewport
        ),

        dispatchLoadOorsInViewport: dispatch(actions.loadOorsInViewport),
        dispatchLoadOorsInViewportExplicit: dispatch(
          actions.loadOorsInViewportExplicit
        ),

        dispatchEnsureOorsLoadedInViewport: dispatch(
          actions.ensureOorsLoadedInViewport
        ),

        dispatchLoadChainAnalysisFragment: dispatch(
          actions.loadChainAnalysisFragment
        ),

        // simo index manager
        getIsLoadedBySourceAndTarget: (
          sourceId: string,
          targetId: string,
          indexId?: string
        ) =>
          $simoIndexManager.getIsLoadedBySourceAndTarget(
            indexId || read(getters.oorsRequestId)(),
            sourceId,
            targetId
          ),
        getBySourceAndTarget: (
          sourceId: string,
          targetId: string,
          indexId?: string
        ) =>
          $simoIndexManager.getBySourceAndTarget(
            indexId || read(getters.oorsRequestId)(),
            sourceId,
            targetId
          ),
        getChainAnalysisSteps: (sourceId: string, targetId: string) =>
          $simoIndexManager.getChainAnalysisSteps(
            read(getters.oorsRequestId)(),
            sourceId,
            targetId
          ),
        getIndex: () =>
          $simoIndexManager.getIndex(read(getters.oorsRequestId)()),

        getContextStateIdentifier: read(getters.contextStateIdentifier),
        commitSetOocComparisonResult: commit(mutations.setOocComparisonResult),
        getOorComparison: (sourceId: string, targetId: string) =>
          $simoIndexManager.getComparison(
            read(getters.oorsRequestId)(),
            sourceId,
            targetId
          ),

        // simo crud
        getSimplifiedResourceSet: $simoCrud.getSimplifiedResourceSet,
        dispatchLoadSingleResource: $simoCrud.dispatchLoadSingleResource,
        dispatchCreateRelation: withRelationContext(
          $simoCrud.dispatchCreateRelation
        ),
        dispatchSetNoRelations: withRelationContext(
          $simoCrud.dispatchSetNoRelations
        ),
        dispatchSetUnknownRelations: withRelationContext(
          $simoCrud.dispatchSetUnknownRelations
        ),
        dispatchDeleteRelation: withRelationContext(
          $simoCrud.dispatchDeleteRelation
        ),
        dispatchRemoveSpecialRelations: withRelationContext(
          $simoCrud.dispatchRemoveSpecialRelations
        ),
        dispatchBatchOperation: withRelationContext(
          dispatch(actions.batchOperation)
        ),
        dispatchSetRelationsBatchOperation: withRelationContext(
          dispatch(actions.setRelationBatchOperation)
        ),
        dispatchUpdateRelation: dispatch(actions.updateRelations),
      },
    };
  },
});

export const getBasePathForObjectOccurences = (
  filters: SimoFiltersValue,
  specialPathPrefix: string,
  externalPath: string
) => {
  if (filters.showExternalInterfaces) return externalPath;
  if (specialPathPrefix) return `${specialPathPrefix}/object_occurrences`;
  return undefined;
};
