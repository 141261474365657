<template>
  <AppLoader>
    <template #default="{ ready }">
      <template v-if="ready">
        <router-view />
        <IntercomWidget />
      </template>
    </template>
  </AppLoader>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import AppLoader from '@/components/appLoader.vue';
import { eventBus } from '@/services/eventBus/eventBus';
import { keypressListener } from '@/services/keypressHandler';
import { toggleDevTools } from '@/plugins/devtools';
import { RouteName } from './enums/routing';
import IntercomWidget from '@/components/intercomWidget.vue';

@Options({
  name: 'App',
  components: {
    AppLoader,
    IntercomWidget,
  },
})
export default class App extends Vue {
  created() {
    eventBus.$on('shortcut-used', this.handleGotoShortcut);
    this.$store.dispatch('polling/initPolling');
  }

  mounted() {
    window.addEventListener('keydown', (e) => keypressListener(e));
    window.addEventListener('keyup', (e) => keypressListener(e));
  }

  beforeUnmount() {
    window.removeEventListener('keydown', (e) => keypressListener(e));
    window.removeEventListener('keyup', (e) => keypressListener(e));
    eventBus.$off('shortcut-used', this.handleGotoShortcut);
  }

  handleGotoShortcut(e) {
    const keys = e.keys;
    switch (keys) {
      case 'KeyG-KeyS':
        if (!this.$route.path.includes('/setup')) {
          e.event.preventDefault();
          e.event.stopPropagation();
          this.$router.push({ name: RouteName.Setup });
        }
        break;
      case 'KeyU-KeyG':
        if (!this.$route.path.includes('/admin')) {
          e.event.preventDefault();
          e.event.stopPropagation();
          this.$router.push({ name: RouteName.Admin });
        }
        break;
      case 'KeyP-KeyG':
        e.event.preventDefault();
        e.event.stopPropagation();
        this.$router.push({ name: RouteName.Projects });

        break;
      case 'KeyC-KeyK':
        if (
          this.$route.path.includes('/projects') &&
          !this.$route.path.includes('/core') &&
          this.$route.params.projectId &&
          this.$route.params.contextId
        ) {
          e.event.preventDefault();
          e.event.stopPropagation();
          this.$router.push({
            name: RouteName.Core,
            params: this.$route.params,
          });
        }
        break;
      case 'KeyK-KeyS':
        if (
          this.$route.path.includes('/projects') &&
          !this.$route.path.includes('/simo') &&
          this.$route.params.projectId &&
          this.$route.params.contextId
        ) {
          e.event.preventDefault();
          e.event.stopPropagation();
          this.$router.push({
            name: RouteName.Simo,
            params: this.$route.params,
          });
        }
        break;
      case 'Slash-ControlLeft-AltLeft':
        toggleDevTools();
        break;
      default:
        return;
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/style';

.v-application {
  .secFrameworkHeaderBackground {
    background-color: rgb(var(--v-theme-headerBackground)) !important;
    border-color: rgb(var(--v-theme-headerBackground)) !important;
  }
  .secFrameworkWrapperBackground {
    background-color: rgb(var(--v-theme-wrapperBackground)) !important;
    border-color: rgb(var(--v-theme-wrapperBackground)) !important;
  }
  .secFrameworkCardBackground {
    background-color: rgb(var(--v-theme-cardBackground)) !important;
    border-color: rgb(var(--v-theme-cardBackground)) !important;
  }
}
</style>
