<template>
  <div class="article-container">
    <div class="article-top-section">
      <div class="h3 pl-3">{{ article.title }}</div>
      <v-btn
        :icon="isExpanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
        @click="changeSize"
        variant="text"
        class="expand-button"
      />
    </div>
    <iframe
      class="article-frame"
      height="100%"
      width="100%"
      frameborder="0"
      sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-downloads"
      role="dialog"
      :src="intercomUrlString"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

const props = defineProps<{
  article;
}>();

const isExpanded = ref(false);
const emit = defineEmits<{
  (e: 'changeSize', isExpanded): boolean;
}>();
const changeSize = () => {
  isExpanded.value = !isExpanded.value;
  emit('changeSize', isExpanded.value);
};

const intercomUrlString = computed(() => {
  const userID =
    'user=62ifMVVxcxhUbkD7HS7OM76xjBTVSa3YcV7%2F1rvRd%2Fo5Nvb0kLltwDvlDn0I%0AwBTYCtCHEMRkhZ6%2FqpLDaqb4WNorLrROAiQSDG6METxj%0A&amp';
  return props.article.url + '?iframe=true&amp;' + userID;
});
</script>
<style lang="scss" scoped>
.article-container {
  width: 100%;
}

.article-top-section {
  display: flex;
  justify-content: space-between;
}

.expand-button {
  transform: rotate(90deg);
}
</style>
