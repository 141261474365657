<script lang="ts">
import { defineComponent, h, Comment } from 'vue';
import { Portal } from 'portal-vue';

export default defineComponent({
  props: {
    noPortal: Boolean,
  },
  setup(props, { slots }) {
    const createSeparator = () =>
      h(
        'li',
        {
          class: 'header-breadcrumbs__separator',
          'data-spec': 'separator',
        },
        '/'
      );
    const renderBreadcrumbs = () => {
      const children = slots.default() || [];
      const separatedBreadcrumbItems = children
        .filter((item) => item.type !== Comment)
        .flatMap((item, index) =>
          index === 0 ? item : [createSeparator(), item]
        );
      return h('ul', { class: 'header-breadcrumbs' }, separatedBreadcrumbItems);
    };
    return () => {
      if (props.noPortal) return renderBreadcrumbs();
      return h(Portal, { to: 'header-breadcrumbs' }, renderBreadcrumbs);
    };
  },
});
</script>

<style lang="scss" scoped>
.header-breadcrumbs {
  height: 100%;
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.header-breadcrumbs__separator {
  padding: 0 10px;
}
</style>
