<template>
  <div class="dashboard-widget" ref="dashboardWidget">
    <div :class="['dashboard-widget__header', dragHandleClass]">
      <template v-if="widget">
        <div class="header__chart-info">
          <div class="header__title">{{ widget.name }}</div>
          <div class="header__subtitle">
            {{ widgetContext }}
            <template v-if="widget.chart === 'pie'">
              <span v-if="showWidgetTotal">(Total: {{ widgetTotal }})</span>
              <v-progress-circular
                v-else
                color="primary"
                width="1"
                size="12"
                indeterminate
              />
            </template>
          </div>
          <v-tooltip
            content-class="header__last-update-tooltip"
            location="bottom"
            attach
          >
            <template #activator="{ props: tooltipProps }">
              <div
                :class="{
                  'header__last-update': true,
                  'header__last-update--loading': isLoadingWidgetStats,
                }"
                @click="reloadWidgetStats"
                v-bind="tooltipProps"
              >
                {{ widgetStatsUpdatedTime }}
                <v-progress-circular
                  v-show="widgetStatsUpdatedTime && isLoadingWidgetStats"
                  style="margin-left: 2px"
                  color="black"
                  :size="8"
                  :width="1"
                  indeterminate
                />
              </div>
            </template>
            <small>Reload data</small>
          </v-tooltip>
        </div>
        <div v-if="!readonly" class="header__actions">
          <v-tooltip
            v-for="(action, actionType) of widgetActions"
            :key="actionType"
            location="bottom"
          >
            {{ action.label }}
            <template #activator="{ props: tooltipProps }">
              <v-btn
                icon
                size="small"
                variant="text"
                :disabled="!widget || !widgetStats"
                @click="action.handler"
                v-bind="tooltipProps"
              >
                <v-icon size="small">{{ action.icon }}</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-menu location="start" :attach="$el" width="132">
            <template #activator="{ props: menuAttrs }">
              <v-btn
                v-bind="menuAttrs"
                icon
                variant="text"
                data-spec="menu-button"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list class="pa-0">
              <v-list-item link density="compact" @click="editWidget">
                <v-list-item-title>Edit graph</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item
                link
                density="compact"
                :disabled="isLoadingData"
                @click="printChart()"
              >
                <v-list-item-title>Print graph</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item
                link
                density="compact"
                :disabled="isLoadingData"
                @click="exportToCsv()"
              >
                <v-list-item-title>Export to CSV</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item
                class="text-error-darken-2"
                link
                density="compact"
                @click="deleteWidget"
              >
                <v-list-item-title>Delete graph</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-else>
        <v-skeleton-loader
          class="mt-2 mb-2"
          width="calc(100% - 100px)"
          type="heading"
        />
        <v-skeleton-loader type="text" width="100" />
      </template>
    </div>
    <DashboardWidgetChart
      v-if="showChart"
      :key="chartKey"
      ref="chartRef"
      :class="['dashboard-widget__chart', { isBeingResized }]"
      :widget="widget"
      :widgetStats="isSyntaxInUpdate ? [] : filteredStats"
    >
      <template #no-data-text>
        {{ noDataText }}
      </template>
    </DashboardWidgetChart>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRef, watch } from 'vue';
import DashboardWidgetChart from '@/components/design/dashboard/dashboardWidgetChart.vue';
import { useDirectStore } from '@/composables/store';
import { DashboardWidget } from '@/models/dashboardWidget';
import { composeMessage } from '@/services/errorHandler';
import { printElement } from '@/services/printer';
import { useElementIntersectionVisibility } from '@/composables/elementIntersectionVisibility';
import {
  until,
  useAsyncState,
  useCurrentElement,
  useIntervalFn,
  useTimeAgo,
  watchOnce,
  UseTimeAgoUnitNamesDefault,
} from '@vueuse/core';
import type { AxiosError } from 'axios';

const props = defineProps<{
  widgetId: string;
  dragHandleClass?: string;
  forceRenderStats?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (event: 'deleted:widget'): void;
  (event: 'edit:widget'): void;
}>();

const store = useDirectStore();

const dashboardWidget = ref<HTMLElement>(null);

const chartKey = ref<string>('');

const isBeingResized = ref<boolean>(false);

const { isLoading: isLoadingWidget, execute: loadWidget } = useAsyncState(
  () =>
    store.dashboardWidget.dispatchLoadWidget({
      widgetId: props.widgetId,
    }),
  null,
  {
    onError: (error: AxiosError) => {
      if (error?.response?.status === 404) {
        // widget has been removed from the database
        // but still exists in the dashboard
        emit('deleted:widget');
        return;
      }
      store.notifications.dispatchNotify({
        type: 'error',
        message: composeMessage(error),
      });
    },
    immediate: false,
  }
);

const { isLoading: isLoadingWidgetStats, execute: loadWidgetStats } =
  useAsyncState(
    () =>
      store.dashboardWidget.dispatchLoadWidgetStats({
        widgetId: props.widgetId,
      }),
    null,
    {
      onError: (error: AxiosError) => {
        store.notifications.dispatchNotify({
          type: 'error',
          message: composeMessage(error),
        });
      },
      immediate: false,
    }
  );

const isLoadingData = computed(
  () => isLoadingWidget.value || isLoadingWidgetStats.value
);

const el = useCurrentElement<HTMLElement>();
const isWidgetInViewport = useElementIntersectionVisibility(el);
const showChart = ref(isWidgetInViewport.value || props.forceRenderStats);
if (!showChart.value) {
  watchOnce(
    () => !!(isWidgetInViewport.value || props.forceRenderStats),
    () => {
      showChart.value = true;
    }
  );
}

const showWidgetTotal = computed(
  () => widget.value.chart !== 'bar' && !isLoadingWidgetStats.value
);

const widgetContext = computed(() => {
  if (!widget.value?.context) return '';
  return widget.value.context.name;
});

const widgetStatsDependencies = computed(() => {
  if (!widget.value) return '';
  return [
    widget.value.context?.id,
    widget.value.arguments,
    widget.value.values,
    JSON.stringify(widget.value.arguments_filters),
    JSON.stringify(widget.value.values_filters),
  ].join(';');
});

const widget = computed<DashboardWidget>(
  () => store.dashboardWidget.getSimplifiedResourceSet()[props.widgetId]
);

const widgetStats = computed(() => {
  let stats = store.dashboardWidget.getWidgetStats(props.widgetId);
  if (!stats || !widget.value) return stats;
  if (
    widget.value.arguments === 'owners' ||
    widget.value.arguments === 'owner_groups'
  ) {
    // skip no owner/no owner group bar if it's empty
    stats = stats.filter(
      (bar) =>
        bar.resource_id ||
        Object.values(bar.data).reduce((acc, curr) => acc + curr, 0) > 0
    );
  }
  return stats;
});

const widgetTotal = computed(() =>
  store.dashboardWidget.getWidgetTotal(props.widgetId)
);

const widgetStatsLoadedAt = computed(() => {
  return store.dashboardWidget.getWidgetStatsLoadedAt(props.widgetId);
});

const hasActiveFilterOwnershipYAxis = computed(() => {
  return (
    widget.value.values_filters['owners'] &&
    (!!widget.value.values_filters['owners']['owner_group_ids_in']?.length ||
      !!widget.value.values_filters['owners']['owner_ids_in']?.length)
  );
});

const reloadWidgetStats = () => {
  if (showChart.value && !isLoadingWidgetStats.value) {
    if (isSyntaxInUpdate.value) loadWidget();
    loadWidgetStats();
  }
};

useIntervalFn(
  () => {
    // reload widget stats every 10-15 minutes
    const lastUpdate = widgetStatsLoadedAt.value;
    if (!lastUpdate) return;
    const diffMinutes = (Date.now() - lastUpdate.getTime()) / 60_000;
    if (diffMinutes >= 10) {
      reloadWidgetStats();
    }
  },
  5 * 60 * 1000
);

const relativeTime = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
const timeAgoFormatter =
  <Unit extends UseTimeAgoUnitNamesDefault>(unit: Unit) =>
  (val: number, isPast: boolean, approximated?: boolean) => {
    if (approximated) {
      return `Updated about ${relativeTime.format(isPast ? -val : val, unit)}`;
    }
    return `Updated ${relativeTime.format(isPast ? -val : val, unit)}`;
  };

const isSyntaxInUpdate = computed(() => !!widget.value?.context?.temporal);
const noDataText = computed<string>(() => {
  if (isLoadingWidget.value || isLoadingWidgetStats.value) return 'Loading...';
  else if (isSyntaxInUpdate.value)
    return 'Awaiting syntax update before data can be found';
  return 'No Data';
});

const widgetStatsUpdatedTime = useTimeAgo<UseTimeAgoUnitNamesDefault>(
  widgetStatsLoadedAt,
  {
    showSecond: true,
    updateInterval: 9_000,
    max: 'day',
    messages: {
      justNow: 'Updated just now',
      future: 'Updated just now',
      second: (val: number, isPast: boolean) => {
        if (val <= 5) return 'Updated just now';
        return timeAgoFormatter('second')(val, isPast, true);
      },
      minute: timeAgoFormatter('minute'),
      hour: timeAgoFormatter('hour'),
      day: timeAgoFormatter('day'),
      week: timeAgoFormatter('week'),
      month: timeAgoFormatter('month'),
      year: timeAgoFormatter('year'),
      past: '{0}',
      invalid: '',
    },
  }
);

const filteredStats = computed(() => {
  if (isLoadingWidgetStats.value) return [];
  if (
    widget.value?.arguments === 'ooc_structure_level' &&
    hasActiveFilterOwnershipYAxis.value
  ) {
    return widgetStats.value?.filter((stats) => Object.keys(stats.data).length);
  }
  return widgetStats.value;
});

watch(toRef(props, 'widgetId'), () => loadWidget(), { immediate: true });
watch(
  [widgetStatsDependencies, showChart],
  ([deps, isVisible], [, wasVisible]) => {
    if (!deps || !isVisible) return;
    if (!wasVisible && isLoadingWidgetStats.value) return;
    loadWidgetStats();
  },
  { immediate: true }
);
watch(isBeingResized, (newValue) => {
  if (newValue) {
    updateChartKey();
  }
});

const chartRef = ref<InstanceType<typeof DashboardWidgetChart>>();

type WidgetActionType = 'zoomIn' | 'zoomOut' | 'resetZoom' | 'reloadData';
type WidgetAction = { label: string; icon: string; handler: () => void };
const widgetActions: Record<WidgetActionType, WidgetAction> = {
  zoomIn: {
    label: 'Zoom in',
    icon: 'mdi-plus-circle-outline',
    handler: () => chartRef.value?.zoomIn(),
  },
  zoomOut: {
    label: 'Zoom out',
    icon: 'mdi-minus-circle-outline',
    handler: () => {
      chartRef.value?.zoomOut();
    },
  },
  resetZoom: {
    label: 'Reset zoom',
    icon: 'mdi-overscan',
    handler: () => chartRef.value?.resetZoom(),
  },
  reloadData: {
    label: 'Reload data',
    icon: 'mdi-reload',
    handler: () => reloadWidgetStats(),
  },
};

const createChartImage = (
  options: Parameters<typeof chartRef.value.createImage>[0] = {}
) => {
  return chartRef.value?.createImage({
    ...options,
    subtitle: widgetStatsLoadedAt.value?.toISOString(),
  });
};

const printChart = async () => {
  const img = await createChartImage();
  if (img) {
    img.style.position = 'absolute';
    img.style.maxWidth = '100%';
    img.style.maxHeight = '100%';

    printElement({
      element: img,
    });
  }
};

const exportToCsv = () => {
  const csvData = chartRef.value?.generateCsvData();
  const widgetName = widget.value?.name || 'chart';
  const timestamp = new Date().toISOString();
  const fileName = `${widgetName}-${timestamp}`.replace(/[^\w\d-]/gi, '_');
  const link = document.createElement('a');
  const file = new Blob([csvData], { type: 'text/plain' });
  link.href = URL.createObjectURL(file);
  link.download = `${fileName}.csv`;
  link.click();
  URL.revokeObjectURL(link.href);
};

const deleteWidget = async () => {
  await store.dashboardWidget.dispatchDeleteResource({
    resourceId: props.widgetId,
  });
  emit('deleted:widget');
};

const editWidget = () => {
  emit('edit:widget');
};

const ensureDataLoaded = () => {
  if (!widget.value && !isLoadingWidget.value) {
    loadWidget();
  }
  if (!widgetStats.value && !isLoadingWidgetStats.value) {
    loadWidgetStats();
  }
  return until(
    () => !isLoadingWidget.value && !isLoadingWidgetStats.value
  ).toBeTruthy();
};

const updateChartKey = () => {
  chartKey.value = crypto.randomUUID();
};

const updateResizeEventStatus = (isResizing: boolean) => {
  isBeingResized.value = isResizing;
};

defineExpose({
  createChartImage: (...args: Parameters<typeof createChartImage>) =>
    createChartImage(...args),
  ensureDataLoaded,
  widgetId: props.widgetId,
  updateResizeEventStatus,
});
</script>

<style lang="scss" scoped>
.dashboard-widget {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
}
.dashboard-widget__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header__chart-info {
  position: relative;
  max-width: 100%;
}
.header__title {
  margin-top: 0.2em;
  line-height: 1;
  font-size: 1.25rem;
  font-weight: 500;
  overflow-wrap: break-word;
  overflow: hidden;
  padding: 2px 0;
}
.header__subtitle {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
}
.header__last-update-tooltip {
  padding: 1px 9px;
  white-space: nowrap;
}
.header__last-update {
  font-size: 0.75rem;
  line-height: 1.4;
  height: 1.4em;
  min-width: 85px;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.3s;
}
.header__last-update--loading {
  opacity: 0.22;
}
.header__actions {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 0.6em;
}
.dashboard-widget__chart {
  margin: auto;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.isBeingResized {
  opacity: 0;
}
</style>
