import type { AxiosResponse } from 'axios';
import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import { api } from '@/services/api';
import { extractPollingId } from '@/utils/path';
import urlStruct from './urlStruct';
import { ClassificationTable } from '@/models/classificationTable';
import { Context } from '@/models/context';
import indexManager from '../components/indexManager';
import crud from '../components/crud';

export default createModule({
  path: 'importFile',
  resourceProfile: resources.importFile,
  components: [crud, indexManager],
  modules: [urlStruct],
  setup({ getAccessors, modules, resourceProfile, components }) {
    const actions = {
      async uploadFileAndWaitForImport(
        context,
        payload: {
          importResponse: AxiosResponse;
          file: File;
          isAsync?: boolean;
        }
      ) {
        const { importResponse, file } = payload;
        const importId: string = importResponse.data.data.id;
        const pollLocation = importResponse.headers.location;
        const pollId = extractPollingId(pollLocation);
        let pollingPromise;
        if (pollId && payload.isAsync) {
          pollingPromise = context
            .dispatch(
              'polling/addJob',
              {
                id: pollId,
                url: pollLocation,
                relationshipKey: 'import',
                relationshipId: importId,
                relatedId: pollId,
              },
              { root: true }
            )
            .then((response) => response?.data);
        }
        await modules.urlStruct.public.dispatchCreateFileResource({
          relatedResourceType: resourceProfile.type,
          relationName: 'file',
          id: importId,
          file,
        });
        if (!payload.isAsync) return { id: importId };
        return pollingPromise || Promise.resolve();
      },
      async importClassificationTable(
        context,
        payload: {
          file: File;
          classification_table_name: ClassificationTable['name'];
          classification_table_type: ClassificationTable['classification_table_type'];
        }
      ) {
        const importResponse = await api.post(
          `async/${resourceProfile.path}/create_classification_table`,
          {
            data: {
              type: 'import',
              attributes: {
                identifier: 'ct_ce',
                classification_table_name: payload.classification_table_name,
                classification_table_type: payload.classification_table_type,
              },
            },
          }
        );
        return dispatch(actions.uploadFileAndWaitForImport)({
          importResponse,
          file: payload.file,
          isAsync: true,
        });
      },
      async importContextCore(
        context,
        payload: {
          projectId: string;
          file: File;
          context: Pick<Context, 'name' | 'description'> & {
            syntax_id: string;
            progress?: string;
          };
        }
      ) {
        const importResponse = await api.post(
          `async/${resourceProfile.path}/create_core`,
          {
            data: {
              type: 'import',
              attributes: {
                identifier: 'rds',
                context: payload.context,
              },
              relationships: {
                target: {
                  data: {
                    type: resources.projects.type,
                    id: payload.projectId,
                  },
                },
              },
            },
          }
        );
        return dispatch(actions.uploadFileAndWaitForImport)({
          importResponse,
          file: payload.file,
          isAsync: true,
        });
      },
      async importContextSimo(
        context,
        payload: {
          contextId: string;
          file: File;
        }
      ) {
        const importResponse = await api.post(
          `async/${resourceProfile.path}/create_simo`,
          {
            data: {
              type: 'import',
              attributes: {
                identifier: 'rds',
              },
              relationships: {
                target: {
                  data: {
                    type: resources.contexts.type,
                    id: payload.contextId,
                  },
                },
              },
            },
          }
        );
        return dispatch(actions.uploadFileAndWaitForImport)({
          importResponse,
          file: payload.file,
        });
      },

      async loadLastContextImport(context, payload: { contextId: string }) {
        await components.$indexManager.public.dispatchLoadPaginatedResource({
          requestId: `context-${payload.contextId}`,
          filterTargetType: 'Context',
          filterTargetIdIn: [payload.contextId],
          pageInfo: {
            page: 1,
            pageSize: 1,
          },
          overwrite: true,
          preload: true,
        });
      },
    };
    const { dispatch } = getAccessors();

    return {
      module: {
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        dispatchImportClassificationTable: dispatch(
          actions.importClassificationTable
        ),
        dispatchImportContextCore: dispatch(actions.importContextCore),
        dispatchImportContextSimo: dispatch(actions.importContextSimo),
        dispatchLoadLastContextImport: dispatch(actions.loadLastContextImport),
      },
    };
  },
});
