import { jsonapiModule as createJsonapiModule } from 'jsonapi-vuex';
import { AxiosRequestConfig } from 'axios';
import { api } from '@/services/api';
import { JVRestructuredCollection, JVRestructuredRecord } from '@/models/jv';
import { JsonApiRelationship } from '@/models/api';

declare module 'axios' {
  interface AxiosRequestConfig {
    _sechub_mockResponse?: AxiosResponse | Promise<AxiosResponse>;
  }
}

type JvStoreState = {
  [resourceType in string]?: JVRestructuredCollection;
};

type UpdateRecordsMutation = (
  state: JvStoreState,
  records: JVRestructuredRecord | JVRestructuredCollection
) => void;

const mockedResponse = (requestConfig: AxiosRequestConfig) => {
  if (requestConfig?._sechub_mockResponse) {
    return Promise.resolve(requestConfig._sechub_mockResponse);
  }
  return undefined;
};

/**
 * Axios instance proxy that mocks response
 * if `_sechub_mockResponse` property is set
 */
const apiProxy = new Proxy(api, {
  apply(target, thisArg, argumentsList) {
    const config: AxiosRequestConfig = argumentsList[0];
    return mockedResponse(config) || target.apply(thisArg, argumentsList);
  },
  get(target, prop, receiver) {
    if (['request'].includes(prop as string)) {
      return (config: AxiosRequestConfig) => {
        return mockedResponse(config) || target[prop](config);
      };
    }
    if (['get', 'delete', 'head', 'options'].includes(prop as string)) {
      return (url: string, config?: AxiosRequestConfig) => {
        return mockedResponse(config) || target[prop](url, config);
      };
    }
    if (['patch', 'put', 'post'].includes(prop as string)) {
      return (url: string, data?: unknown, config?: AxiosRequestConfig) => {
        return mockedResponse(config) || target[prop](url, data, config);
      };
    }
    Reflect.get(target, prop, receiver);
  },
});

const mergeRelationshipData = (
  baseRelData: JsonApiRelationship,
  newRelData: JsonApiRelationship
): JsonApiRelationship => {
  if (!baseRelData || !newRelData) return baseRelData || newRelData;
  return { ...baseRelData, ...newRelData };
};

const rebaseRelationships = (
  state: JvStoreState,
  record: JVRestructuredRecord
) => {
  const { type, id, relationships: newRelationships = {} } = record._jv || {};
  if (!type || !id) return;
  const cachedRelationships = state[type]?.[id]?._jv?.relationships;
  if (!cachedRelationships) return;
  const mergedRelationships = { ...cachedRelationships };
  Object.entries(newRelationships).forEach(([relKey, relNew]) => {
    mergedRelationships[relKey] = mergeRelationshipData(
      cachedRelationships[relKey],
      relNew
    );
  });
  record._jv.relationships = mergedRelationships;
};

const isSingleRecord = (
  records: JVRestructuredRecord | JVRestructuredCollection
): records is JVRestructuredRecord => !!records._jv;

const withCachedRelationships = (
  mutation: UpdateRecordsMutation
): UpdateRecordsMutation => {
  return (state, records, ...rest) => {
    const normalizedRecords = isSingleRecord(records)
      ? [records]
      : Object.values(records);
    normalizedRecords.forEach((record) => rebaseRelationships(state, record));
    return mutation(state, records, ...rest);
  };
};

const jsonapiModule = createJsonapiModule(apiProxy, {
  preserveJson: true,
});

jsonapiModule.mutations.addRecords = withCachedRelationships(
  jsonapiModule.mutations.addRecords
);

jsonapiModule.mutations.mergeRecords = withCachedRelationships(
  jsonapiModule.mutations.mergeRecords
);

export default jsonapiModule;
