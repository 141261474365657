import { OOC } from '@/models/objectOccurrence';
import { ObjectOccurrenceRelation } from '@/models/objectOccurrenceRelation';
import { Owner } from '@/models/owner';
import { OwnerGroup } from '@/models/ownerGroup';
import { migrate, Migrations } from '@/services/migration';
import { CrudAccessors } from '@/store/components/crud';
import { isSpecialRelation } from '@/utils/simo';
import { range } from '../math';
import { getOwnershipOfType, OwningEntityType } from '../objectOccurrence';
import { getProgressStep } from '../objectOccurrenceRelations';
import { PathParameters } from '../path';
import { IndexManagerAccessors } from '@/store/components/indexManager';
import { JVRestructuredRecord } from '@/models/jv';
import { SelectableDeadline } from '@/components/deadlines/deadlinesTypes';

const SIMO_FILTERS_VERSION = '0.0.4';

export type SimoSortValues = 'reference_designation' | 'core';

export interface SimoOorFiltersValue {
  oorProgress: ProgressPickerValue | null;
  oorCodes: string[];
}

export interface SimoOocFiltersValue {
  structureLevels: number[];
  oocProgress: ProgressPickerValue | null;
  syntaxElementIds: string[];
  includedSingleOocs: OOC[];
  excludedSingleOocs: OOC[];
  includedParentOocs: OOC[];
  oocCodes: string[];
  owners: Owner[];
  ownerGroups: OwnerGroup[];
  ownerCompanies: string[];
  includeOocsRelatedToOwned: boolean;
  highlightMatchingOwnershipFiltersCells: OwningEntityType | null;
  deadlines: SelectableDeadline[];
  showExternalInterfaces: boolean;
}

export type SimoFiltersValue = SimoOocFiltersValue & SimoOorFiltersValue;

export interface ProgressRangePickerValue {
  gte?: number;
  lte?: number;
}

export interface ProgressPickerValue {
  orderIn: number[] | null;
  withEmpty: boolean;
}

export interface OwnersGroupSelectionValue {
  owners: Owner[];
  groups: string[];
  companies: string[];
}

export interface SimoSessionState {
  filters: SimoFiltersValue | null;
  activeChainAnalysis: ChainAnalysisPayload | null;
  sort: SimoSortValues | null;
  heatMap: boolean;
}

export interface ChainAnalysisPayload {
  oocId: string;
  inboundSteps: number;
  outboundSteps: number;
}

interface SimoSessionContext {
  loadOOC: (id: string) => Promise<OOC>;
  loadOwner: (id: string) => Promise<Owner>;
  loadOwnerGroup: (id: string) => Promise<OwnerGroup>;
}

export const getDefaultFiltersValue = (
  overrides: Partial<SimoFiltersValue> = {}
): SimoFiltersValue => {
  const filters = {
    sort: null,
    structureLevels: [],
    oocProgress: null,
    oorProgress: null,
    syntaxElementIds: [],
    includedSingleOocs: [],
    excludedSingleOocs: [],
    includedParentOocs: [],
    oocCodes: [],
    oorCodes: [],
    owners: [],
    ownerCompanies: [],
    ownerGroups: [],
    includeOocsRelatedToOwned: false,
    highlightMatchingOwnershipFiltersCells: null,
    deadlines: [],
    showExternalInterfaces: false,
  };
  Object.keys(filters).forEach((key) => {
    if (key in overrides && overrides[key] !== 'undefined') {
      filters[key] = overrides[key];
    }
  });
  return filters;
};

export const createSerializerWithDefault = <T>(
  defaultVal: T,
  serialize: (val: T) => string = JSON.stringify
) => {
  const serializedDefaultVal = serialize(defaultVal);
  return (val: T) => {
    const serializedVal = serialize(val ?? defaultVal);
    return serializedVal === serializedDefaultVal ? undefined : serializedVal;
  };
};

export const serializeArrayOfResources = createSerializerWithDefault(
  [],
  (resource) => JSON.stringify((resource || []).map((resource) => resource.id))
);

const filtersSerializers: {
  [K in keyof SimoFiltersValue]: (value: SimoFiltersValue[K]) => string;
} = {
  structureLevels: createSerializerWithDefault([]),
  oocProgress: createSerializerWithDefault(null),
  oorProgress: createSerializerWithDefault(null),
  syntaxElementIds: createSerializerWithDefault([]),
  includedSingleOocs: serializeArrayOfResources,
  excludedSingleOocs: serializeArrayOfResources,
  includedParentOocs: serializeArrayOfResources,
  oocCodes: createSerializerWithDefault([]),
  oorCodes: createSerializerWithDefault([]),
  owners: serializeArrayOfResources,
  ownerGroups: serializeArrayOfResources,
  ownerCompanies: createSerializerWithDefault([]),
  includeOocsRelatedToOwned: createSerializerWithDefault(false),
  highlightMatchingOwnershipFiltersCells: createSerializerWithDefault(null),
  deadlines: createSerializerWithDefault([]),
  showExternalInterfaces: createSerializerWithDefault(false),
};

const serializeChainAnalysisState = (val: ChainAnalysisPayload | null) =>
  val ? JSON.stringify(val) : undefined;

const serializeSorting = (val: SimoSortValues | null) => val || undefined;
const serializeHeatMap = (value: boolean) => (value ? `${value}` : undefined);

export const serializeSimoSession = (
  state: SimoSessionState
): Record<string, string> => {
  const serializedState: Record<string, string> = {};
  Object.keys(filtersSerializers).forEach((key) => {
    serializedState[key] = filtersSerializers[key](state.filters?.[key]);
  });
  serializedState.activeChainAnalysis = serializeChainAnalysisState(
    state.activeChainAnalysis
  );
  serializedState.sort = serializeSorting(state.sort);
  serializedState.heatMap = serializeHeatMap(state.heatMap);
  serializedState.version = SIMO_FILTERS_VERSION;
  return serializedState;
};

const createDeserializerWithDefault =
  <T>(
    defaultVal: T,
    deserialize: (
      str: string,
      ctx: SimoSessionContext
    ) => T | Promise<T> = JSON.parse as () => T
  ) =>
  async (str: string, ctx: SimoSessionContext) => {
    try {
      const value = await deserialize(str, ctx);
      return value;
    } catch (error) {
      return defaultVal;
    }
  };

const progressDeserializer =
  createDeserializerWithDefault<ProgressPickerValue | null>(null, (str) => {
    const obj = JSON.parse(str);
    const value = {
      orderIn: obj.orderIn || null,
      withEmpty: obj.withEmpty ?? true,
    };
    const showAll = !value.orderIn && value.withEmpty;
    return showAll ? null : value;
  });

const oocsDeserializer = createDeserializerWithDefault<OOC[]>(
  [],
  (str, ctx) => {
    const oocIds = JSON.parse(str) as string[];
    const handleError = (err: unknown) => {
      console.warn('[SIMO filters] Unable to load OOC');
      console.error(err);
      return null;
    };
    return Promise.all(
      oocIds.map((id) => ctx.loadOOC(id).catch(handleError))
    ).then((arr) => arr.filter(Boolean));
  }
);

const ownersDeserializer = createDeserializerWithDefault<Owner[]>(
  [],
  (str, ctx) => {
    const ownerIds = JSON.parse(str) as string[];
    const handleError = (err: unknown) => {
      console.warn('[SIMO filters] Unable to load Owner');
      console.error(err);
      return null;
    };
    return Promise.all(
      ownerIds.map((id) => ctx.loadOwner(id).catch(handleError))
    ).then((arr) => arr.filter(Boolean));
  }
);

const ownerGroupsDeserializer = createDeserializerWithDefault<OwnerGroup[]>(
  [],
  (str, ctx) => {
    const ownerGroupIds = JSON.parse(str) as string[];
    const handleError = (err: unknown) => {
      console.warn('[SIMO filters] Unable to load OwnerGroup');
      console.error(err);
      return null;
    };
    return Promise.all(
      ownerGroupIds.map((id) => ctx.loadOwnerGroup(id).catch(handleError))
    ).then((arr) => arr.filter(Boolean));
  }
);

const filtersDeserializers: {
  [K in keyof SimoFiltersValue]: (
    str: string,
    ctx: SimoSessionContext
  ) => SimoFiltersValue[K] | Promise<SimoFiltersValue[K]>;
} = {
  structureLevels: createDeserializerWithDefault([]),
  oocProgress: progressDeserializer,
  oorProgress: progressDeserializer,
  syntaxElementIds: createDeserializerWithDefault([]),
  includedSingleOocs: oocsDeserializer,
  excludedSingleOocs: oocsDeserializer,
  includedParentOocs: oocsDeserializer,
  oocCodes: createDeserializerWithDefault([]),
  oorCodes: createDeserializerWithDefault([]),
  owners: ownersDeserializer,
  ownerGroups: ownerGroupsDeserializer,
  ownerCompanies: createDeserializerWithDefault([]),
  includeOocsRelatedToOwned: createDeserializerWithDefault(false),
  highlightMatchingOwnershipFiltersCells: createDeserializerWithDefault(null),
  deadlines: createDeserializerWithDefault([]),
  showExternalInterfaces: createDeserializerWithDefault(false),
};

const deserializeChainAnalysisState =
  createDeserializerWithDefault<ChainAnalysisPayload | null>(
    null,
    async (str, ctx) => {
      const obj = JSON.parse(str);
      const inboundSteps = +obj.inboundSteps || 0;
      const outboundSteps = +obj.outboundSteps || 0;
      if (obj.oocId && (inboundSteps || outboundSteps)) {
        try {
          const ooc = await ctx.loadOOC(obj.oocId);
          return { oocId: ooc.id, inboundSteps, outboundSteps };
        } catch (err) {
          console.warn('[SIMO filters] Unable to load chain analysis OOC');
          console.error(err);
          return null;
        }
      }
      return null;
    }
  );

export const filtersMigrations: Migrations = {
  // added `version` property
  '0.0.1': (model: Record<string, string>) => {
    return { ...model, version: '0.0.1' };
  },
  // changed default behavior of filtering by owners:
  // it should be "exclusive" by default which means
  // it should show only OOCs owned by selected owners
  // when `includeOocsRelatedToOwned` is `true`
  // it should include OOCs that are related to
  // owned OOCs with some OOR
  '0.0.2': (model: Record<string, string>) => {
    const { exclusiveOwnersOnly, ...newModel } = model;
    let hasOwners = false;
    try {
      hasOwners = !!(model.owners && JSON.parse(model.owners)?.length);
    } catch (err) {
      /* ignore */
    }
    if (hasOwners && !exclusiveOwnersOnly)
      newModel.includeOocsRelatedToOwned = 'true';
    newModel.version = '0.0.2';
    return newModel;
  },
  // replace progress range with steps set
  '0.0.3': async (model: Record<string, string>) => {
    const { oocProgressRange, oorProgressRange, ...newModel } = model;
    const deserializeRange =
      createDeserializerWithDefault<ProgressRangePickerValue | null>(
        null,
        (str) => {
          const obj = JSON.parse(str);
          return {
            lte: obj.lte ?? undefined,
            gte: obj.gte ?? undefined,
          };
        }
      );
    const oocRange = await deserializeRange(
      oocProgressRange,
      {} as SimoSessionContext
    );
    if (oocRange && (oocRange.lte || oocRange.gte)) {
      newModel.oocProgress = JSON.stringify({
        withEmpty: !oocRange.gte,
        orderIn: range(oocRange.gte || 0, oocRange.lte ? oocRange.lte + 1 : 8),
      } as ProgressPickerValue);
    }
    const oorRange = await deserializeRange(
      oorProgressRange,
      {} as SimoSessionContext
    );
    if (oorRange && (oorRange.lte || oorRange.gte)) {
      newModel.oorProgress = JSON.stringify({
        withEmpty: !oorRange.gte,
        orderIn: range(oorRange.gte || 0, oorRange.lte ? oorRange.lte + 1 : 4),
      } as ProgressPickerValue);
    }
    newModel.version = '0.0.3';
    return newModel;
  },
  '0.0.4': async (model: Record<string, string>) => {
    // Looks like ownerGroups is unused, but removing results in failing.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ownerGroups, ...newModel } = model;
    newModel.version = '0.0.4';
    return newModel;
  },
};

export const migrateSerializeSimoSession = async (
  serializedState: Record<string, string>,
  migrations: Migrations
): Promise<Record<string, string>> => {
  const version = serializedState.version || '0.0.0';
  const migratedState = await migrate(serializedState, migrations, version);
  return migratedState;
};

export const deserializeSimoSession = async (
  serializedState: Record<string, string>,
  ctx: SimoSessionContext
): Promise<SimoSessionState> => {
  const model = await migrateSerializeSimoSession(
    serializedState,
    filtersMigrations
  );
  const filters: SimoFiltersValue = await Promise.all(
    Object.entries(filtersDeserializers).map(async ([key, deserialize]) => {
      const value = await deserialize(model[key], ctx);
      return [key, value];
    })
  ).then((entries) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (entries as any[]).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  );
  const deserializeSimoSession: SimoSessionState = {
    filters,
    activeChainAnalysis: await deserializeChainAnalysisState(
      model.activeChainAnalysis,
      ctx
    ),
    sort: model.sort as SimoSortValues,
    heatMap: model?.heatMap === 'true',
  };
  return deserializeSimoSession;
};

export const isEqualSerializedSimoSession = (
  q1: Record<string, string>,
  q2: Record<string, string>
) => {
  if (!q1 && !q2) return true;
  if (!q1 || !q2) return false;
  const keys = [
    ...Object.keys(filtersSerializers),
    'activeChainAnalysis',
    'sort',
    'heatMap',
    'version',
    'selectedCellsX',
    'selectedCellsY',
    'position',
  ];
  return keys.every((key) => (!q1[key] && !q2[key]) || q1[key] === q2[key]);
};

export const getOocFilters = ({
  sourceIds,
  targetIds,
}: {
  sourceIds: string[];
  targetIds: string[];
}): PathParameters => {
  const areEqualSourcesAndTargets =
    sourceIds.length === targetIds.length &&
    sourceIds.every((id, index) => targetIds[index] === id);
  if (areEqualSourcesAndTargets) {
    return { filterObjectOccurrenceIdsCont: sourceIds };
  }
  return {
    filterObjectOccurrenceSourceIdsCont: sourceIds,
    filterObjectOccurrenceTargetIdsCont: targetIds,
  };
};

const hasActiveOorHighlightingFilters = (
  filters: SimoOorFiltersValue
): boolean => {
  return (
    !!filters.oorCodes?.length ||
    (filters.oorProgress &&
      (!!filters.oorProgress?.withEmpty ||
        !!filters.oorProgress?.orderIn?.length))
  );
};

export const canHighlightOwners = (filters: SimoOocFiltersValue): boolean => {
  const ownersLength = filters.owners?.length || 0;
  return ownersLength > 1;
};

export const canHighlightOwnerGroups = (
  filters: SimoOocFiltersValue
): boolean => {
  const ownerGroupsLength = filters.ownerGroups?.length || 0;
  return ownerGroupsLength > 1;
};

const hasActiveOocHighlightingFilters = (
  filters: SimoOocFiltersValue
): boolean => {
  if (filters.highlightMatchingOwnershipFiltersCells === 'owner') {
    return canHighlightOwners(filters);
  }
  if (filters.highlightMatchingOwnershipFiltersCells === 'owner_group') {
    return canHighlightOwnerGroups(filters);
  }
  return false;
};

export const hasActiveHighlightingFilters = (
  filters: SimoFiltersValue
): boolean => {
  return (
    hasActiveOocHighlightingFilters(filters) ||
    hasActiveOorHighlightingFilters(filters)
  );
};

type SimoCellFiltersMatch = {
  match: boolean;
  isOwnersBridge?: boolean;
  isOwnerGroupsBridge?: boolean;
};

export const cellMatchesHighlightingFilters = (cell: {
  source: OOC;
  target: OOC;
  filters: SimoFiltersValue;
  oors: ObjectOccurrenceRelation[];
}): SimoCellFiltersMatch => {
  if (!oorsMatchesFilters(cell.oors, cell.filters)) {
    return { match: false };
  }
  return oocMatchesFilters(cell.source, cell.target, cell.filters);
};

const oocMatchesFilters = (
  source: OOC,
  target: OOC,
  filters: SimoOocFiltersValue
): SimoCellFiltersMatch => {
  if (!hasActiveOocHighlightingFilters(filters)) {
    return { match: true };
  }
  const { match: ownersMatch, isBridge: isOwnersBridge } =
    oocMatchesOwnershipFilter(source, target, filters, 'owner');
  const { match: ownerGroupsMatch, isBridge: isOwnerGroupsBridge } =
    oocMatchesOwnershipFilter(source, target, filters, 'owner_group');
  return {
    match: ownersMatch || ownerGroupsMatch,
    isOwnersBridge,
    isOwnerGroupsBridge,
  };
};

const oocMatchesOwnershipFilter = (
  source: OOC,
  target: OOC,
  filters: SimoOocFiltersValue,
  ownershipType: OwningEntityType
): { match: boolean; isBridge: boolean } => {
  if (
    filters.highlightMatchingOwnershipFiltersCells !== ownershipType ||
    (ownershipType === 'owner' && !canHighlightOwners(filters)) ||
    (ownershipType === 'owner_group' && !canHighlightOwnerGroups(filters))
  ) {
    return { match: false, isBridge: false };
  }
  const entities =
    ownershipType === 'owner' ? filters.owners : filters.ownerGroups;
  const sourceId = getOwnershipOfType(source, ownershipType)?.owning_entity?.id;
  const targetId = getOwnershipOfType(target, ownershipType)?.owning_entity?.id;
  const sourceInFilters = entities.some((entity) => entity.id === sourceId);
  const targetInFilters = entities.some((entity) => entity.id === targetId);
  const isBridge = sourceId !== targetId && sourceInFilters && targetInFilters;
  return { match: isBridge, isBridge };
};

const oorsMatchesFilters = (
  oors: ObjectOccurrenceRelation[],
  filters: SimoOorFiltersValue
) => {
  if (!hasActiveOorHighlightingFilters(filters)) return true;
  if (!oors) return false;
  return oors.some((oor) => {
    if (isSpecialRelation(oor)) return false;
    if (
      filters.oorCodes &&
      filters.oorCodes.length &&
      !filters.oorCodes.includes(oor.classification_entry?.code)
    )
      return false;
    if (filters.oorProgress) {
      const progress = getProgressStep(oor)?.order;
      const isEmpty = !progress && progress !== 0;
      const withoutEmpty = !filters.oorProgress.withEmpty;
      const onlyEmpty =
        filters.oorProgress.withEmpty && !filters.oorProgress.orderIn;
      const allSteps = !onlyEmpty && !filters.oorProgress.orderIn;
      if (withoutEmpty && isEmpty) return false;
      if (onlyEmpty && !isEmpty) return false;
      if (!withoutEmpty && isEmpty) return true;
      if (!allSteps && !filters.oorProgress.orderIn.includes(progress))
        return false;
    }
    return true;
  });
};

export const getActiveFiltersCount = (filters: SimoFiltersValue): number => {
  if (!filters) {
    return 0;
  }
  const notEmpty = (v) => !!v?.length;
  return [
    notEmpty(filters.structureLevels),
    filters.oocProgress?.withEmpty || notEmpty(filters.oocProgress?.orderIn),
    filters.oorProgress?.withEmpty || notEmpty(filters.oorProgress?.orderIn),
    notEmpty(filters.syntaxElementIds),
    notEmpty(filters.includedSingleOocs),
    notEmpty(filters.excludedSingleOocs),
    notEmpty(filters.includedParentOocs),
    notEmpty(filters.oocCodes),
    notEmpty(filters.oorCodes),
    notEmpty(filters.owners),
    notEmpty(filters.ownerGroups),
    notEmpty(filters.ownerCompanies),
    notEmpty(filters.deadlines),
  ].reduce((total, v) => total + (v ? 1 : 0), 0);
};

export const getOrLoadOoc =
  (config: {
    module: IndexManagerAccessors &
      Pick<CrudAccessors, 'getSimplifiedResourceSet'>;
    setOocComparisonResult: (ooc: JVRestructuredRecord) => void;
    contextId: string;
    transformSavedFiltersForRevision?: boolean;
    revisionComparisonBasePath?: string;
  }) =>
  async (oocOrEquivalentId: string): Promise<OOC | null> => {
    const {
      module,
      contextId,
      transformSavedFiltersForRevision,
      revisionComparisonBasePath,
      setOocComparisonResult,
    } = config;
    const isComparison = !!revisionComparisonBasePath;
    const canUseCachedOoc = !isComparison && !transformSavedFiltersForRevision;
    const cachedOoc =
      canUseCachedOoc && module.getSimplifiedResourceSet()[oocOrEquivalentId];
    if (cachedOoc) return cachedOoc;
    const params = isComparison
      ? {
          basePath: revisionComparisonBasePath,
          filterEquivalentId: oocOrEquivalentId,
        }
      : {
          filterContextId: contextId,
          filterEquivalentId: oocOrEquivalentId,
        };
    const { jvData } = await module.dispatchLoadPaginatedResource({
      requestType: '_simo-filters-des',
      overwrite: true,
      pageInfo: {
        page: 1,
        pageSize: 1,
      },
      ...params,
    });
    module.dispatchResetIndexes({ requestType: '_simo-filters-des' });
    const oocId = Object.keys(jvData)[0];
    const ooc = oocId && module.getSimplifiedResourceSet()[oocId];
    if (!ooc) return null;
    if (isComparison) {
      setOocComparisonResult(jvData[oocId]);
    }
    return ooc;
  };

export const getOrLoadOwnerOrGroup =
  <Resource extends Owner | OwnerGroup>(config: {
    module: IndexManagerAccessors &
      Pick<CrudAccessors, 'getSimplifiedResourceSet'>;
    projectId: string;
    transformSavedFiltersForRevision?: boolean;
    revisionComparisonBasePath?: string;
  }) =>
  async (resourceOrEquivalentId: string): Promise<Resource | null> => {
    const {
      module,
      projectId,
      transformSavedFiltersForRevision,
      revisionComparisonBasePath,
    } = config;
    const isComparison = !!revisionComparisonBasePath;
    const canUseCachedResource =
      !isComparison && !transformSavedFiltersForRevision;
    const cachedResource =
      canUseCachedResource &&
      module.getSimplifiedResourceSet()[resourceOrEquivalentId];
    if (cachedResource) return cachedResource;
    const params = isComparison
      ? {
          basePath: revisionComparisonBasePath,
          filterEquivalentId: resourceOrEquivalentId,
        }
      : {
          filterEquivalentId: resourceOrEquivalentId,
          filterProjectId: projectId,
        };
    const { jvData } = await module.dispatchLoadPaginatedResource({
      requestType: '_simo-filters-des',
      overwrite: true,
      pageInfo: {
        page: 1,
        pageSize: 1,
      },
      ...params,
    });
    module.dispatchResetIndexes({ requestType: '_simo-filters-des' });
    const resourceId = Object.keys(jvData)[0];
    const resource =
      resourceId && module.getSimplifiedResourceSet()[resourceId];
    return resource || null;
  };

export const shouldDisableHeatmap = (filters: SimoFiltersValue) => {
  return (
    !!filters.owners.length ||
    !!filters.ownerGroups.length ||
    !!filters.highlightMatchingOwnershipFiltersCells ||
    !!filters.oorCodes.length
  );
};
