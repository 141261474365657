<template>
  <FocusTrap active :initialFocus="() => formRef.queryFocusableNodes()[0]">
    <div class="context-duplicate-form">
      <GenericForm
        ref="form"
        :formConfig="formConfig"
        submitLabel="Copy Context"
        @cancel="onCancel"
        @created="onCreated"
      >
      </GenericForm>
    </div>
  </FocusTrap>
</template>

<script lang="ts">
import { Options, Prop, Ref, Vue } from 'vue-property-decorator';
import { FocusTrap } from 'focus-trap-vue';
import GenericForm from '../genericForm.vue';
import ConfirmDialog from '@/components/confirmDialog.vue';
import {
  FormFieldDefinition,
  FormFieldType,
  FormConfig,
} from '@/services/forms';
import { contextNameRules } from '@/utils/validationRules';
import { Context } from '@/models/context';

@Options({
  components: {
    ConfirmDialog,
    FocusTrap,
    GenericForm,
  },
  emits: ['created', 'cancel'],
})
export default class ProjectsContextDuplicateForm extends Vue {
  @Prop({ required: true }) contextId: string;
  @Ref('form') readonly formRef: GenericForm;

  get formConfig(): FormConfig {
    const schema: FormFieldDefinition[] = [
      {
        attribute: 'project',
        label: 'Copy context into',
        type: FormFieldType.ResourceSelector,
        rules: [(value) => !!value || 'Parent project must be specified'],
        resourceConfig: {
          module: this.$store.$direct.project,
          pathParameters: { filterArchived: false },
        },
      },
      {
        attribute: 'name',
        label: 'Name of new context',
        type: FormFieldType.Text,
        rules: contextNameRules,
        initialValue: `${this.context.name} - Copy`,
      },
    ];
    return {
      schema,
      createResource: (payload) => {
        return this.$store.$direct.context.dispatchDuplicateContext({
          contextId: this.contextId,
          projectId: payload.relationships.project,
          name: `${payload.resource.name}`.trim(),
        });
      },
      updateResource: () => null,
    };
  }

  get context(): Context {
    return this.$store.$direct.context.getSimplifiedResourceSet()[
      this.contextId
    ];
  }

  onCancel() {
    this.$emit('cancel');
  }

  onCreated(contextId: string) {
    this.$emit('created', {
      contextId,
      projectId: this.$store.$direct.context.getRelatedProjectId(contextId),
    });
    this.$store.$direct.notifications.dispatchNotify({
      type: 'success',
      message: this.$t('apps.design.forms.contextCopiedSuccessful'),
    });
  }
}
</script>

<style lang="scss" scoped>
.context-duplicate-form {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.context-duplicate-form__actions {
  position: relative;
  padding: 24px;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    margin: 0 auto;
    width: calc(100% - 48px);
    height: 0;
    border-bottom: 1px solid rgb(var(--v-theme-line));
  }
}
</style>
