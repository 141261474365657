<template>
  <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" class="node-row">
    <NodeWrapper :color="item.hex_color">
      <template #default="{ show }">
        <TreeItemNode
          v-if="show"
          v-bind="$attrs"
          data-spec="node"
          :isRoot="isRoot"
          :item="item"
          :showOOCCreationButtons="showOOCCreationButtons"
          :isStructureLocked="isStructureLocked"
          :canCreateSibling="canCreateSibling"
          :canCreateChild="canCreateChild"
          :isSyntaxTree="isSyntaxTree"
          :isLoading="isLoading"
          :isOOCsTree="isOOCsTree"
          :isHighlighted="isHighlighted"
          :isReadonly="!canEdit"
          :isNarrow="actionsVisible"
          :minDepth="minDepth"
          :maxDepth="maxDepth"
          :editMode="editMode"
          :processingStatus="processingStatus"
          :disablePointerEvents="disablePointerEvents"
          @saveSyntaxNode="saveSyntaxNode"
          @exitEditMode="exitEditMode"
          @dragenter.stop="dragNodeEnter"
          @dragleave.stop="dragNodeLeave"
          @dragover.stop="dragOverNode"
          @drop.stop="dropNode(item.id)"
          @dragstart.stop="dragStart"
          @dragend.stop="dragEnd"
          @click="handleClick"
        >
          <template v-if="editMode && isOOCsTree" #main>
            <OOCEditor
              :editorMode="!isTempNode"
              :editedOOCId="item.id"
              :parentOOCId="item.parentId"
              :position="item.position"
              :isProcessing="isProcessing"
              @save="saveNode"
              @cancel="exitEditMode"
            />
          </template>
          <template v-if="!editMode" #append>
            <slot name="append" :item="item" />
          </template>
          <template v-if="actionsVisible" #actions>
            <div class="actions">
              <v-tooltip
                v-for="action in actions"
                :key="action.icon"
                location="top"
              >
                <span v-html="action.title" />
                <template #activator="{ props }">
                  <div
                    data-spec="action-buttons"
                    v-bind="props"
                    class="action"
                    :class="action.cssClass"
                  >
                    <v-btn
                      :data-spec="action.dataSpec"
                      class="actions__button mx-1"
                      :class="{
                        'actions__button--disabled':
                          isProcessing || action.disabled,
                      }"
                      :style="{ padding: '0 2px' }"
                      :disabled="isProcessing || action.disabled"
                      size="small"
                      variant="text"
                      @click.stop="action.method"
                    >
                      <v-icon :size="action.size || 23" :color="action.color">{{
                        action.icon
                      }}</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-tooltip>
            </div>
          </template>
        </TreeItemNode>
      </template>
    </NodeWrapper>
    <div v-if="showLines" class="line-middle" />
    <div v-if="showLines" v-show="addingSiblingUp" class="line-up" />
    <div
      v-if="showLines"
      v-show="addingSiblingDown"
      class="line-down"
      :style="{ top: siblingLineTopPosition + 'px' }"
    />
    <div
      v-show="draggingChildEnter"
      class="line-child"
      :style="{ left: moveChildArrowLeft + 'px' }"
    />
    <div class="node-filler">
      <div
        class="filler-up"
        v-if="!isRoot"
        @dragenter.stop="dragEnter('up')"
        @dragleave.stop="dragLeave('up')"
        @dragover.stop="dragOverSiblingsArea"
        @drop.stop="dropNode(item.parentId, item.position)"
      />
      <div
        class="filler-down"
        v-if="!isRoot"
        @dragenter.stop="dragEnter('down')"
        @dragleave.stop="dragLeave('down')"
        @dragover.stop="dragOverSiblingsArea"
        @drop.stop="dropNode(item.parentId, item.position + 1)"
      />
    </div>
    <div
      v-if="!isRoot"
      v-show="addingSiblingUp"
      class="notification notification--sibling"
    />
    <div
      v-if="!isRoot"
      v-show="addingSiblingDown"
      class="notification notification--sibling"
      :style="{ top: notificationTopPosition + 'px' }"
    />
    <div
      v-show="draggingChildEnter"
      class="notification notification--child"
      :style="{ left: `calc(${moveChildArrowLeft}px + 60px)` }"
    />
    <div v-show="dragging" ref="dragImg" class="coverup" />
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Inject, Watch } from 'vue-property-decorator';
import { SyntaxItem } from '@/models/syntaxItem';
import { resources } from '@/services/resources';
import OOCEditor from '@/components/design/core/OOCEditor.vue';
import { TreeContextLegacy } from '@/models/treeStructure';
import { OOC } from '@/models/objectOccurrence';
import { getColorFromVariable } from '@/colors/utils';
import TreeItemNode from '@/components/setup/syntax/treeStructure/treeItemNode.vue';
import { ProcessingStatus } from '@/store/components/processingTracker';
import { isTempNodeId } from '@/utils/tree';
import NodeWrapper from '@/components/setup/syntax/treeStructure/nodeWrapper.vue';

interface NodeAction {
  title: string;
  icon: string;
  method: () => unknown;
  disabled?: boolean;
  color: string;
  cssClass?: string;
  size?: string | number;
  dataSpec: string;
}

export const itemHeight = 106;

@Options({
  name: 'TreeItem',
  components: {
    NodeWrapper,
    OOCEditor,
    TreeItemNode,
  },
  emits: [
    'cut',
    'copy',
    'duplicate',
    'paste',
    'select',
    'collapse',
    'createNode',
    'saveNode',
    'cancelEdit',
    'edit',
    'remove',
    'dragStart',
    'dragEnd',
    'dropNode',
  ],
})
export default class NewTreeItem extends Vue {
  @Prop({ default: () => null }) item: SyntaxItem & Partial<OOC>;
  @Prop({ default: () => 1 }) zoomFactor: number;
  @Prop(String) processingStatus: ProcessingStatus | undefined;
  @Prop(Boolean) lockStructure: boolean;
  @Prop(Boolean) readonly: boolean;
  @Prop(Boolean) editorIsActive!: boolean;
  @Prop({ default: () => false }) isLoading: boolean;
  @Prop({ default: () => '' }) selectedNodeId: string;

  @Inject({ from: 'treeContext' }) context: TreeContextLegacy;

  draggingChildEnter = false;
  addingSiblingUp = false;
  addingSiblingDown = false;
  dragging = false;
  selected = false;
  isHovered = false;

  get actionEdit(): NodeAction {
    return {
      title: this.editBtnTooltipText,
      icon: 'mdi-pencil-outline',
      method: () => this.enterEditMode(),
      disabled: this.disableEditButton,
      color: 'rgb(var(--v-theme-primary))',
      dataSpec: 'edit-btn',
    };
  }

  get actionRemove(): NodeAction {
    return {
      title: 'Remove',
      icon: 'mdi-delete-forever',
      method: () => this.removeItem(),
      disabled: this.isStructureLocked,
      color: 'rgb(var(--v-theme-error-lighten-1))',
      dataSpec: 'remove-btn',
    };
  }

  get itemHeight() {
    return itemHeight;
  }

  get editedElement() {
    return (
      this.context.edited &&
      this.context.module.getSimplifiedResourceSet()[this.context.edited]
    );
  }

  get editBtnTooltipText(): string {
    if (!this.disableEditButton) return 'Edit';
    const elType = this.isSyntaxTree ? 'Syntax Element' : 'Object Occurrence';
    const editingNewElement = !this.editedElement;
    if (editingNewElement) {
      return (
        `You are currently editing a new ${elType} and only one editor at a time is allowed.` +
        `<br>Save or discard changes in active editor to work on this ${elType}.`
      );
    }
    const label = this.isSyntaxTree
      ? this.editedElement.name
      : (this.editedElement.prefix || '') +
        (this.editedElement.classification_code || '');
    const labelFormatted = label ? '<strong>' + label + ' </strong> ' : '';

    return (
      `You are currently editing ${elType} ${labelFormatted}and only one editor at a time is allowed.` +
      `<br>Save or discard changes ${
        label ? 'to ' : ''
      }${labelFormatted}to work on this ${elType}.`
    );
  }

  get isProcessing(): boolean {
    return !!this.processingStatus;
  }

  get showSpinner(): boolean {
    return this.isLoading;
  }

  get isSelected(): boolean {
    return this.selectedNodeId === this.item.id;
  }

  get isHighlighted(): boolean {
    return this.isSelected || this.isHovered;
  }

  get editMode(): boolean {
    return (
      !this.readonly && this.canEdit && this.item.id === this.context.edited
    );
  }

  get isTempNode(): boolean {
    return isTempNodeId(this.item.id);
  }

  get siblingLineTopPosition(): number {
    // TODO: wrong number if any child expanded
    return this.itemHeight * (this.item.children.length + 0.5);
  }

  get notificationTopPosition(): number {
    return 46 + this.siblingLineTopPosition;
  }

  get moveChildArrowLeft() {
    return this.isRoot ? 30 : 80;
  }

  get nodeColor() {
    if (this.item.hex_color) return `#${this.item.hex_color}`;
    return this.isHighlighted ? getColorFromVariable('primary') : '#FFFFFF';
  }

  get node(): Record<string, unknown> {
    return this.context.module.getSimplifiedResourceSet()[this.item.id] || {};
  }

  get minDepth(): number {
    return this.node.min_depth as number;
  }

  get maxDepth(): number {
    return this.node.max_depth as number;
  }

  get isRoot(): boolean {
    return !this.item.parentId;
  }

  get showLines(): boolean {
    return !this.isRoot;
  }

  get disableEditButton(): boolean {
    return this.isProcessing || this.editorIsActive;
  }

  get disablePointerEvents(): boolean {
    return this.context.isDraggingNode;
  }

  get actionsVisible() {
    return !!(
      this.isHighlighted &&
      this.actions &&
      !this.readonly &&
      !this.isProcessing &&
      !this.editMode
    );
  }

  get actions(): NodeAction[] | null {
    if (
      this.isOOCsTree &&
      !this.context.canCutDuplicatePaste(this.item.id as string)
    )
      return null;
    if (this.isSyntaxTree && this.isRoot) return null;
    if (this.isSyntaxTree) {
      return [this.actionEdit, this.actionRemove];
    }
    const canPaste =
      this.isOOCsTree && this.context.canPaste(this.item.id as string);
    return [
      {
        ...this.actionEdit,
        cssClass: 'border-right',
      },
      {
        title: 'Cut',
        icon: 'mdi-content-cut',
        method: () => this.$emit('cut'),
        disabled: this.isStructureLocked,
        color: 'rgb(var(--v-theme-primary-lighten-1))',
        size: 20,
        dataSpec: 'cut-btn',
      },
      {
        title: 'Copy',
        icon: 'mdi-content-copy',
        method: () => this.$emit('copy'),
        disabled: this.isStructureLocked,
        color: 'rgb(var(--v-theme-primary-lighten-1))',
        size: 20,
        dataSpec: 'copy-btn',
      },
      {
        title: 'Duplicate',
        icon: 'mdi-content-duplicate',
        method: () => this.$emit('duplicate'),
        disabled: this.isStructureLocked,
        color: 'rgb(var(--v-theme-primary-lighten-1))',
        size: 20,
        dataSpec: 'duplicate-btn',
      },
      {
        title: canPaste === true ? 'Paste' : canPaste || 'Paste',
        icon: 'mdi-content-paste',
        method: () => canPaste === true && this.$emit('paste'),
        disabled: this.isStructureLocked || canPaste !== true,
        color: 'rgb(var(--v-theme-primary-lighten-1))',
        size: 20,
        dataSpec: 'paste-btn',
      },
      {
        ...this.actionRemove,
        cssClass: 'border-left',
      },
    ].filter(Boolean);
  }

  get isOOCsTree(): boolean {
    return this.context.resourceType === resources.objectOccurrences.type;
  }

  get isSyntaxTree(): boolean {
    return this.context.resourceType === resources.syntaxNode.type;
  }

  get isStructureLocked(): boolean {
    return this.isProcessing || this.lockStructure;
  }

  get showOOCCreationButtons(): boolean {
    return (
      this.isHighlighted &&
      this.isOOCsTree &&
      !this.editMode &&
      !this.isStructureLocked &&
      !this.context.loadingTree &&
      !this.readonly &&
      !this.editorIsActive
    );
  }

  get canEdit(): boolean {
    return (
      !this.readonly &&
      !this.isRoot &&
      this.context.canEdit(this.item.id) === true
    );
  }

  get canCreateChild(): boolean {
    return this.context.canCreate(this.item.id) === true;
  }

  get canCreateSibling(): boolean {
    return !this.isRoot && this.context.canCreate(this.item.parentId) === true;
  }

  get canDropAsChild(): boolean {
    return this.context.canDrop(this.item.id) === true;
  }

  get canDropAsSibling(): boolean {
    return this.context.canDrop(this.item.parentId) === true;
  }

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }

  mounted() {
    if (this.isSyntaxTree && this.isTempNode) {
      setTimeout(
        () =>
          this.$refs.minDepth && (this.$refs.minDepth as HTMLElement).focus()
      );
    }
  }

  @Watch('zoomFactor', { deep: true, immediate: true })
  onZoomTypePropertyChanged(value) {
    if (value) {
      this.setZoom();
    }
  }

  handleClick() {
    if (this.isSelected) return;
    if (!this.editMode) this.$emit('select');
  }

  saveSyntaxNode({ min_depth, max_depth }) {
    if (!this.editMode || !this.isSyntaxTree) return;
    if (!this.isProcessing) {
      this.saveNode({
        ...this.item,
        min_depth,
        max_depth,
      });
    }
  }

  createNode({ parentId, position }) {
    if (parentId === this.item.parentId) {
      // create sibling
      this.$emit('collapse');
    }
    this.$emit('createNode', { parentId, position });
  }

  saveNode(node) {
    this.$emit('saveNode', node);
  }

  exitEditMode() {
    if (!this.editMode) return;
    this.$emit('cancelEdit');
  }

  enterEditMode() {
    if (this.isRoot) return;
    this.$emit('edit');
  }

  removeItem() {
    this.$emit('remove');
  }

  setZoom() {
    let nodeFontSize = '16px';
    switch (this.zoomFactor) {
      case 0.5: {
        nodeFontSize = '12px';
        break;
      }
      case 1: {
        nodeFontSize = '16px';
        break;
      }
      case 2: {
        nodeFontSize = '40px';
        break;
      }
    }
    const root = document.documentElement;
    root.style.setProperty(
      '--secCoreTreeZoomFactor',
      this.zoomFactor.toString()
    );
    root.style.setProperty('--sec-nodeFontSize', nodeFontSize);
  }

  dragStart(evt: DragEvent) {
    if (this.isStructureLocked || this.editMode) {
      evt.preventDefault();
      return;
    }
    this.dragging = true;
    evt.dataTransfer.dropEffect = 'copy';
    const crt = this.$refs.dragImg as HTMLElement;

    crt.style.backgroundColor = this.item.hex_color
      ? `#${this.item.hex_color}`
      : getColorFromVariable('primary');
    evt.dataTransfer.setDragImage(crt, 25, 20);
    this.$emit('dragStart');
  }

  dragEnd() {
    this.dragging = false;
    this.$emit('dragEnd');
  }

  dragEnter(loc) {
    if (this.canDropAsSibling && !this.isStructureLocked) {
      this.addingSiblingUp = loc === 'up';
      this.addingSiblingDown = loc === 'down';
    }
  }

  dragLeave(loc) {
    if (loc === 'up') {
      this.addingSiblingUp = false;
    }
    if (loc === 'down') {
      this.addingSiblingDown = false;
    }
  }

  dragNodeEnter() {
    if (this.canDropAsChild && !this.isStructureLocked) {
      this.draggingChildEnter = true;
    }
  }

  dragNodeLeave() {
    this.draggingChildEnter = false;
  }

  dragOverNode(evt) {
    if (this.canDropAsChild && !this.isStructureLocked) {
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'copy';
    }
  }

  dragOverSiblingsArea(evt) {
    if (this.canDropAsSibling && !this.isStructureLocked) {
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'copy';
    }
  }

  dropNode(parentId: string, position = 1) {
    this.draggingChildEnter = false;
    this.addingSiblingUp = false;
    this.addingSiblingDown = false;
    if (this.context.canDrop(parentId) === true && !this.isStructureLocked) {
      this.$emit('dropNode', { parentId, position });
    }
  }
}
</script>

<style scoped lang="scss">
$expanderSize: 24px;
$horizontalLineWidth: 68px;
$nodeExpanderMargin: 56px;
$line-color: rgb(var(--v-theme-grayScale));
$border-line: 1px solid $line-color;

.coverup {
  width: 50px;
  height: 20px;
  border-radius: $border-radius;
  position: fixed;
  bottom: 0;
  left: -200px;
}

.node-row {
  display: flex;
  flex-direction: row;
  height: var(--v-nodeHeight);
  position: relative;
  min-width: 700px;
  &.disabled {
    pointer-events: none;
  }

  .line-up {
    width: 26px;
    height: var(--v-halfNodeHeight);
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
    border-top: $border-line;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 20px;
      border-right: $border-line;
      border-top: $border-line;
      transform-origin: top right;
      width: 7px;
      height: 7px;
      transform: rotate(45deg) translateY(-1px);
    }
  }
  .line-middle {
    width: 57px;
    height: var(--v-halfNodeHeight);
    border-bottom: $border-line;
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
    &::before {
      content: '';
      position: absolute;
      top: -53px;
      left: 0;
      border-left: $border-line;
      height: calc(100% + var(--v-halfNodeHeight));
      z-index: 6;
    }
  }
  .line-down {
    height: var(--v-halfNodeHeight);
    width: 27px;
    z-index: 6;
    position: absolute;
    top: var(--v-halfNodeHeight);
    left: 0;
    box-sizing: border-box;
    border-bottom: $border-line;
    border-left: $border-line;
    &::after {
      content: '';
      position: absolute;
      top: var(--v-halfNodeHeight);
      left: 20px;
      border-right: $border-line;
      border-top: $border-line;
      width: 7px;
      height: 7px;
      transform-origin: top right;
      transform: rotate(45deg) translateY(-1px);
    }
  }

  .line-child {
    bottom: 0;
    position: absolute;
    left: 80px;
    width: 25px;
    height: 26px;
    border-bottom: $border-line;
    border-left: $border-line;
    &::after {
      content: '';
      position: absolute;
      top: 26px;
      left: 17px;
      border-right: $border-line;
      border-top: $border-line;
      width: 7px;
      height: 7px;
      transform-origin: top right;
      transform: rotate(45deg) translateY(-1px);
    }
  }
  .node-filler {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 60px;

    .filler-up {
      height: 50%;
      width: 100%;
      z-index: 6;
      position: relative;
    }

    .filler-down {
      height: 50%;
      width: 100%;
      z-index: 6;
    }
  }
}
.notification {
  position: absolute;
  background-color: #1e8449;
  height: 16px;
  width: 130px;
  border-radius: 6px;

  &--child {
    bottom: -7px;
    left: 120px;
  }

  &--sibling {
    left: 60px;
    top: -7px;
  }
}
.actions {
  display: flex;
  align-items: center;
  padding-right: 8px;

  .action {
    min-height: 36px;
    display: flex;
    align-items: center;
  }

  .actions__button {
    padding-left: 6px;
    padding-right: 6px;
    margin: 1px;
    min-width: auto;
    color: var(--color-on-selected);
    // allow tooltip when disabled
    pointer-events: initial;
  }
  .actions__button--disabled {
    cursor: not-allowed;
  }
}

.border-right {
  margin-right: 6px;
  padding-right: 6px;
  border-right: solid 1px rgba(0, 0, 0, 0.38);
}
.border-left {
  margin-left: 6px;
  padding-left: 6px;
  border-left: solid 1px rgba(0, 0, 0, 0.38);
}
</style>
