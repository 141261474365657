import { ProcessingTrackerAccessors } from '@/store/components/processingTracker';
import { TreeAccessors } from '@/store/components/tree';
import { CrudAccessors } from '@/store/components/crud';
import { ResourceType } from '@/services/resources';
import { OOCComparisonResult } from '@/models/revision';
import {
  OOCEditorPayload,
  OocSyntaxChangesNeeded,
} from '@/models/objectOccurrence';

export interface TreeIndexNode {
  id: string;
  parentId: string;
  position: number;
  comparisonResult?: OOCComparisonResult;
  changes_needed?: OocSyntaxChangesNeeded;
  component_changes_needed?: OocSyntaxChangesNeeded[];
}
export type TreeDataNode<
  T extends { position: number } = { position: number },
> = TreeIndexNode & T;

export type StructuredTreeNode<
  T extends { position: number } = { position: number },
> = TreeDataNode<T> & {
  expandable: boolean;
  expanded: boolean;
  number?: number;
  classification_code?: string;
  children?: Array<StructuredTreeNode<T>>;
  reference_designation?: string;
  prefix?: string;
};

export interface FlatTreeNode<
  T extends {
    position: number;
  } = { position: number },
> {
  item: StructuredTreeNode<T>;
  /**
   * indicates if vertical lines should be visible next to node
   * in tree representation to link siblings on a given level
   * e.g. [true, false, false, true] means that
   * levels 1 and 4 have nodes later in flat tree representation
   * and all nodes from levels 2 and 3 are above current node.
   * Simple visualization below shows how truthy values
   * matches with "|" character:
   *  0 1 2 3
   *  @         -> [false] (root level)
   *  +-@       -> [false, true]
   *  +-@       -> [false, true]
   *  | +-@     -> [false, true, true]
   *  | | +-@   -> [false, true, true, false]
   *  | +-@     -> [false, true, false]
   *  |   +-@   -> [false,  true, false, false]
   *  +-@       -> [false, false]
   */
  unfinishedLevels: boolean[];
}

export interface TreeClipboard {
  id: string;
  cut?: boolean;
}

export type LegacyTempTreeNode<
  T extends { position: number } = { position: number },
> = Partial<TreeDataNode<T>> & {
  id: string;
  deleted?: boolean;
};

export enum TreeOperationType {
  Add,
  Remove,
  Patch,
  Move,
}

type TreeDataNodeAttributes<
  Resource extends { position: number } = { position: number },
> = Omit<TreeDataNode<Resource>, 'id' | 'position' | 'parentId'>;

export interface TreeOperationAdd<
  Resource extends { position: number } = { position: number },
> {
  operationType: TreeOperationType.Add;
  id: string;
  parentId: string;
  position: number;
  attributes: TreeDataNodeAttributes<Resource>;
}
interface TreeOperationPatch<
  Resource extends { position: number } = { position: number },
> {
  operationType: TreeOperationType.Patch;
  id: string;
  attributes: Partial<TreeDataNodeAttributes<Resource>>;
}

interface TreeOperationMove {
  operationType: TreeOperationType.Move;
  id: string;
  parentId: string;
  position: number;
}

interface TreeOperationRemove {
  operationType: TreeOperationType.Remove;
  id: string;
}

export type TreeOperationPayload<
  Resource extends { position: number } = Record<string, unknown> & {
    position: number;
  },
> =
  | Omit<TreeOperationAdd<Resource>, 'id'>
  | TreeOperationPatch<Resource>
  | TreeOperationMove
  | TreeOperationRemove;

export type TreeOperation<
  Resource extends { position: number } = { position: number },
> =
  | TreeOperationAdd<Resource>
  | TreeOperationPatch<Resource>
  | TreeOperationMove
  | TreeOperationRemove;

type TreeModuleAccessors = TreeAccessors &
  ProcessingTrackerAccessors &
  CrudAccessors;

export interface TreeContextLegacy {
  module: TreeModuleAccessors;
  readonly resourceType: ResourceType;

  readonly selected?: string;
  readonly edited?: string;
  readonly clipboard?: TreeClipboard | null;
  readonly loadingTree?: boolean;
  readonly isDraggingNode?: boolean;

  canCreate: (createInId: string) => boolean | string;
  canDrop: (dropToId: string) => boolean | string;
  canCutDuplicatePaste: (id: string) => boolean;
  canPaste: (pasteToId: string) => boolean | string;
  canEdit: (id: string) => boolean | string;
}

export interface NodeCollection {
  readonly selectedNodes: string[];
  readonly lastSelected: string;

  isSelected: (nodeId: string) => boolean;
  selectColumn: (nodeId: string, tree: Readonly<FlatTreeNode[]>) => void;
  selectNode: (
    nodeId: string,
    options?: { override?: boolean; programmatic?: boolean }
  ) => void;
  deselectNode: (nodeId: string) => void;
  clear: () => void;
}

export interface TreeContext {
  readonly rootNode: StructuredTreeNode;

  readonly selectionManager: NodeCollection;

  collapseNode: (nodeId: string) => Promise<void>;
  expandNode: (nodeId: string) => Promise<void>;
  expandPathToNode: (nodeId: string) => Promise<void>;
  isLoadingSubtree: (nodeId: string) => boolean;

  hasClassificationTable: (nodeId: string) => boolean;
  canCut: (nodeId: string) => boolean | string;
  canCopy: (nodeId: string) => boolean | string;
  canMove: (nodeId: string, targetParentId: string) => boolean | string;
  canClone: (nodeId: string, targetParentId: string) => boolean | string;
  canRemove: (nodeId: string) => boolean | string;
  canEdit: (nodeId: string) => boolean | string;
  canEditSystem: (nodeId: string) => boolean | string;
  canEditNumber: (nodeId: string) => boolean | string;

  nextValidNumber: (ooc: OOCEditorPayload) => number;
}
