<template>
  <div class="d-flex flex-column ml-2">
    <span class="d-flex align-center text-caption reset-height">
      <span class="trade-study-dot mr-1" />
      {{ $t('tradeStudies.tradeStudy') }}
    </span>
    <span class="ts-name">{{ name }}</span>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  name: 'ContextSwitcherDropdownTSLabel',
  components: {},
})
export default class ContextSwitcherDropdownTSLabel extends Vue {
  @Prop({ default: '' }) name: string;
}
</script>
<style lang="scss" scoped>
.trade-study-dot {
  background-color: rgb(var(--v-theme-tradeStudy));
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.ts-name {
  display: flex;
  line-height: 1;
  font-size: 1rem;
}
.reset-height {
  height: auto;
}
</style>
