<template>
  <AppLayout
    :application="application"
    :hideSubApplicationsTabs="hideSubApplicationsTabs"
  >
    <slot>
      <div class="sub-application-content">
        <router-view v-slot="{ Component }">
          <PageTransition
            name="slide-x-transition"
            class="router-transition-wrapper"
          >
            <component :is="Component" />
          </PageTransition>
        </router-view>
      </div>
    </slot>
  </AppLayout>
</template>

<script lang="ts" setup>
import AppLayout from '@/components/layout/appLayout.vue';
import PageTransition from '@/components/pageTransition.vue';
import { watch, provide, onBeforeMount, computed } from 'vue';
import { useApplication } from '@/composables/application';
import { useRoute } from 'vue-router';
//TODO check which router should be used, vue-router was on vue 3 branch, composable on master
// import { useRoute } from '@/composables/router';
import { useDirectStore } from '@/composables/store';

withDefaults(
  defineProps<{
    hideSubApplicationsTabs?: boolean;
  }>(),
  {
    hideSubApplicationsTabs: false,
  }
);

const route = useRoute();
const store = useDirectStore();

const {
  application,
  subApplicationAccess,
  changeColorPallet,
  redirectToHomepage,
} = useApplication();

onBeforeMount(() => {
  changeColorPallet();
});

const shouldStartConnection = computed(() => !store.wsUser.getTargetId());

watch(route, () => {
  if (subApplicationAccess.value.access !== true) {
    redirectToHomepage(subApplicationAccess.value.message);
  }
});

watch(
  shouldStartConnection,
  (shouldStart) => {
    if (shouldStart) {
      store.wsUser.dispatchConnect();
    }
  },
  { immediate: true }
);

provide('application', application.value);
</script>

<style lang="scss" scoped>
.sub-application-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-background));
}
.router-transition-wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
