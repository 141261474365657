/* eslint-disable @typescript-eslint/no-explicit-any */
import { getDictionaryFactory } from '@/utils/collections';

export interface ResourceProfile<
  Type extends ResourceType = ResourceType,
  RelationshipKeys extends string = string,
  Relationships extends Record<
    RelationshipKeys,
    ResourceProfile | ResourceProfile[]
  > = any,
> {
  path: string;
  type: Type;
  checkIsEnabled?: () => boolean;
  attributes?: readonly string[];
  parentType?: string;
  parentRelationship?: string;
  dependencyType?: string;
  dependantAttributes?: readonly string[];
  relationships?: Relationships;
  includeRelationships?: string[];
  createByRelationship?: boolean;
  relationshipMany?: boolean;
  createAsComponent?: boolean; // deprecated
}

export type ResourceType =
  | 'classification_entry'
  | 'classification_table'
  | 'context'
  | 'custom_field_value'
  | 'custom_field'
  | 'dashboard'
  | 'delta_group'
  | 'delta'
  | 'doma_classification_entry'
  | 'doma_classification_table'
  | 'export'
  | 'filter_set'
  | 'import'
  | 'meeting_plan'
  | 'domain_relation'
  | 'object_occurrence_relation'
  | 'object_occurrence'
  | 'ooc_classification_entry_stat'
  | 'oor_chain_element'
  | 'owner_company'
  | 'owner_group'
  | 'owner'
  | 'ownership'
  | 'permission'
  | 'progress_model'
  | 'progress_step_checked'
  | 'progress_step'
  | 'project'
  | 'revision_meta_information'
  | 'syntax_element'
  | 'syntax_node'
  | 'syntax'
  | 'tag'
  | 'trade_study'
  | 'url_struct'
  | 'user_color'
  | 'user_permission'
  | 'user'
  | 'widget'
  | 'notification';

type CommonKeys<T> = keyof T;
type AllKeys<T> = T extends any ? keyof T : never;
type Subtract<A, C> = A extends C ? never : A;
type NonCommonKeys<T> = Subtract<AllKeys<T>, CommonKeys<T>>;
type PickType<T, K extends AllKeys<T>> = T extends { [k in K]?: any }
  ? T[K]
  : undefined;
type PickTypeOf<T, K extends string | number | symbol> =
  K extends AllKeys<T> ? PickType<T, K> : never;
type MergeUnionType<T> = {
  [k in CommonKeys<T>]: PickTypeOf<T, k>;
} & {
  [k in NonCommonKeys<T>]: PickTypeOf<T, k>;
};

type RelationshipsPathNormalize<T> =
  T extends Array<infer U>
    ? U extends ResourceProfile
      ? Required<U>['relationships']
      : never
    : T extends ResourceProfile
      ? Required<T>['relationships']
      : never;

type Normalize<T> = T extends ResourceProfile
  ? Required<T>['relationships']
  : never;

type Normalize1<T, Key1 extends keyof Normalize<T>> = MergeUnionType<
  RelationshipsPathNormalize<Normalize<T>[Key1]>
>;
type Normalize2<
  T,
  Key1 extends keyof Normalize<T>,
  Key2 extends keyof Normalize1<T, Key1>,
> = MergeUnionType<RelationshipsPathNormalize<Normalize1<T, Key1>[Key2]>>;
type Normalize3<
  T,
  Key1 extends keyof Normalize<T>,
  Key2 extends keyof Normalize1<T, Key1>,
  Key3 extends keyof Normalize2<T, Key1, Key2>,
> = MergeUnionType<RelationshipsPathNormalize<Normalize2<T, Key1, Key2>[Key3]>>;

const isValidRelationshipPath = (profile: ResourceProfile, path: string[]) => {
  if (!profile) return false;
  if (!path.length) return true;
  const [relKey, ...restPath] = path;
  const relationship = profile.relationships?.[relKey];
  if (!relationship) return false;
  const relationshipProfiles = Array.isArray(relationship)
    ? relationship
    : [relationship];
  return relationshipProfiles.some((relProfile) => {
    return isValidRelationshipPath(relProfile, restPath);
  });
};

export const createIncludeRelationshipPath = <T extends ResourceProfile>(
  resourceProfile: T
) => {
  return function field<
    Key1 extends keyof Normalize<T>,
    Key2 extends keyof Normalize1<T, Key1>,
    Key3 extends keyof Normalize2<T, Key1, Key2>,
    Key4 extends keyof Normalize3<T, Key1, Key2, Key3>,
  >(k1: Key1, k2?: Key2, k3?: Key3, k4?: Key4) {
    const path = [k1, k2, k3, k4].filter(Boolean).join('.');
    if (
      !import.meta.env.PROD &&
      !isValidRelationshipPath(resourceProfile, path.split('.'))
    ) {
      throw new Error(
        `Path '${path}' is not defined for resource profile of type '${resourceProfile.type}'.`
      );
    }
    return path;
  };
};

export const resourceRelationship = <T extends ResourceProfile>(
  resourceProfile: T
) => {
  return function field<Rel extends keyof Normalize<T>>(relationshipKey: Rel) {
    if (
      !import.meta.env.PROD &&
      !isValidRelationshipPath(resourceProfile, [relationshipKey.toString()])
    ) {
      throw new Error(
        `Relationship '${relationshipKey.toString()}' is not defined for resource profile of type '${
          resourceProfile.type
        }'.`
      );
    }
    return relationshipKey;
  };
};

export const getResourceByType = (type: ResourceType) => {
  return Object.values(resources).find(
    (r) => r.type === type
  ) as ResourceProfile;
};

type RelationshipsTypes<
  Profile extends ResourceProfile,
  T extends
    Profile['relationships'][keyof Profile['relationships']] = Profile['relationships'][keyof Profile['relationships']],
> = T extends Array<any> ? T[number]['type'] : T['type'];

export const findRelationshipByType = <
  Profile extends ResourceProfile,
  RelType extends RelationshipsTypes<Profile>,
>(
  resourceProfile: Profile,
  type: RelType
) => {
  const relationshipKey = Object.keys(resourceProfile.relationships).find(
    (relKey) => {
      const relationshipProfile = resourceProfile.relationships[relKey];
      return Array.isArray(relationshipProfile)
        ? relationshipProfile.some((rel) => rel.type === type)
        : relationshipProfile.type === type;
    }
  );
  if (!relationshipKey) {
    console.error(
      `Relation of type '${type}' is not defined for resource profile of type '${resourceProfile.type}'. Returning ${relationshipKey}.`
    );
  }
  return relationshipKey;
};

export interface ResourceRelationshipProfile extends ResourceProfile {
  relationshipMany?: boolean;
  createAsComponent?: boolean;
}

export const createResourceProfile = <Profile extends ResourceProfile>(
  resourceProfile: Profile
) => resourceProfile;

export const checkRelationshipPathIsEnabled = (payload: {
  path: string[];
  relationships?: Record<string, ResourceProfile>;
}) => {
  const { path, relationships } = payload;
  const firstResourceEnabled = relationships?.[path[0]]?.checkIsEnabled
    ? relationships[path[0]]?.checkIsEnabled()
    : true;
  if (path.length === 1) return firstResourceEnabled;
  return (
    firstResourceEnabled &&
    checkRelationshipPathIsEnabled({
      path: path.slice(1),
      relationships: relationships?.[path[0]]?.relationships,
    })
  );
};

export const classificationTable = createResourceProfile({
  path: 'classification_tables',
  type: 'classification_table',
  attributes: [
    'name',
    'description',
    'classification_table_type',
    'published',
    'archived',
    'classification_entries_depth',
    'updated_at',
  ],
  relationships: {
    get tags() {
      return { ...tags, relationshipMany: true };
    },
  },
  includeRelationships: ['tags'],
});

export const classificationEntry = createResourceProfile({
  path: 'classification_entries',
  type: 'classification_entry',
  attributes: ['code', 'definition', 'name', 'ard_part', 'updated_at'],
  parentType: classificationTable.type,
  createByRelationship: true,
  relationshipMany: true,
  relationships: {
    [classificationTable.type]: classificationTable,
    get classification_entries() {
      return classificationEntry;
    },
    get tags() {
      return { ...tags, relationshipMany: true };
    },
  },
  includeRelationships: ['tags'],
});

const classificationEntriesStats = createResourceProfile({
  path: 'object_occurrences/classification_entries_stats',
  type: 'ooc_classification_entry_stat',
  attributes: ['ooc_count'],
  relationships: {
    classification_entry: { ...classificationEntry, relationshipMany: false },
  },
  includeRelationships: ['classification_entry'],
});

const syntax = createResourceProfile({
  path: 'syntaxes',
  type: 'syntax',
  attributes: [
    'name',
    'description',
    'published',
    'archived',
    'aspect',
    'numbers_only',
  ],
});

const tags = createResourceProfile({
  path: 'tags',
  type: 'tag',
  attributes: ['value', 'updated_at'],
  createByRelationship: true,
  relationshipMany: true,
  relationships: {
    [classificationEntry.type]: classificationEntry,
    [classificationTable.type]: classificationTable,
  },
});

const objectOccurrences = createResourceProfile({
  path: 'object_occurrences',
  type: 'object_occurrence',
  parentType: 'object_occurrence',
  parentRelationship: 'part_of',
  createAsComponent: true,
  createByRelationship: true,
  attributes: [
    'changes_needed',
    'component_changes_needed',
    'classification_code',
    'context_id',
    'hex_color',
    'image_key',
    'name',
    'object_occurrence_type',
    'number',
    'position',
    'prefix',
    'validation_errors',
    'reference_designation',
    'updated_at',
  ],
  relationships: {
    object_occurrence: {
      path: 'object_occurrences',
      type: 'object_occurrence',
      createAsComponent: true,
    },
    part_of: {
      path: 'object_occurrences',
      type: 'object_occurrence',
      createAsComponent: true,
    },
    get custom_field_values() {
      return { ...customFieldValue, relationshipMany: true };
    },
    get components() {
      return {
        ...objectOccurrences,
        relationshipMany: true,
      };
    },
    // discrepancy comparison only
    get deleted_components() {
      return {
        ...objectOccurrences,
        relationshipMany: true,
      };
    },
    // discrepancy comparison only
    get previous_ooc() {
      return objectOccurrences;
    },
    get progress_step_checked() {
      return progress;
    },
    get classification_table() {
      return classificationTable;
    },
    get syntax_element() {
      return syntaxElement;
    },
    get classification_entry() {
      return {
        ...classificationEntry,
        relationshipMany: false,
      };
    },
    get allowed_children_syntax_elements() {
      return {
        ...syntaxElement,
        relationshipMany: true,
      };
    },
    get ownerships() {
      return {
        ...ownerships,
        relationshipMany: true,
      };
    },
    get context() {
      return contexts;
    },
  },
  includeRelationships: ['syntax_element', 'ownerships.owning_entity.user'],
});

const customField = createResourceProfile({
  path: 'custom_fields',
  type: 'custom_field',
  attributes: [
    'name',
    'parent_type',
    'created_at',
    'no_relations',
    'unknown_relations',
    'position',
    'description_field_id',
    'updated_at',
  ],
  relationships: {
    get custom_field_value() {
      return { ...customFieldValue, relationshipMany: true };
    },
    get scope() {
      return [contexts, projects];
    },
  },
  createByRelationship: false,
});

const customFieldValue = createResourceProfile({
  path: 'custom_field_values',
  type: 'custom_field_value',
  attributes: ['description', 'updated_at'],
  relationships: {
    get custom_field() {
      return customField;
    },
    get parent() {
      return [
        objectOccurrences,
        objectOccurrenceRelations,
        objectOccurrenceDomainRelation,
      ];
    },
  },
  createByRelationship: false,
});

const urlStruct = createResourceProfile({
  path: '', // no direct path
  type: 'url_struct',
  attributes: ['url'],
  createByRelationship: true,
});

const importFile = createResourceProfile({
  path: 'imports',
  type: 'import',
  attributes: [
    'created_at',
    'failed_at',
    'failure_reason',
    'identifier',
    'import_type',
    'locale',
    'processed',
    'processed_at',
    'processing',
    'processing_at',
    'target_id',
    'target_type',
    'user_id',
    'updated_at',
  ],
  relationships: {
    get target() {
      return contexts || projects || classificationTable;
    },
  },
});

const exportStruct = createResourceProfile({
  path: 'exports',
  type: 'export',
  attributes: [
    'created_at',
    'export_type',
    'processing',
    'processed',
    'processing_at',
    'processed_at',
    'valid_until',
    'failed_at',
    'updated_at',
  ],
  relationships: {
    get context() {
      return contexts;
    },
    get user() {
      return users;
    },
    get csv() {
      return urlStruct;
    },
    get xlsx() {
      return urlStruct;
    },
  },
});

const meetingPlanner = createResourceProfile({
  path: 'meeting_plans',
  type: 'meeting_plan',
  attributes: [
    'resource_type',
    'rooms_number',
    'duration',
    'start_date',
    'end_date',
    'start_time',
    'end_time',
    'breaks',
    'url',
    'processing',
    'processing_at',
    'failed_at',
    'updated_at',
  ],
  relationships: {
    get context() {
      return contexts;
    },
    get url() {
      return urlStruct;
    },
  },
  includeRelationships: ['url'],
});

const objectOccurrenceRelations = createResourceProfile({
  path: 'object_occurrence_relations',
  type: 'object_occurrence_relation',
  attributes: [
    'name',
    'number',
    'no_relations',
    'unknown_relations',
    'updated_at',
  ],
  relationships: {
    target: objectOccurrences,
    source: objectOccurrences,
    classification_entry: {
      ...classificationEntry,
      relationshipMany: false,
    },
    get progress_step_checked() {
      return progress;
    },
    get custom_field_values() {
      return { ...customFieldValue, relationshipMany: true };
    },
  },
  includeRelationships: ['classification_entry', 'progress_step_checked'],
});

const oorChainElement = createResourceProfile({
  path: 'chain_analysis',
  type: 'oor_chain_element',
  attributes: ['steps'],
  relationships: {
    get object_occurrence_relation() {
      return objectOccurrenceRelations;
    },
  },
  includeRelationships: [
    'object_occurrence_relation',
    ...objectOccurrenceRelations.includeRelationships.map(
      (rel) => `object_occurrence_relation.${rel}`
    ),
  ],
});

const projects = createResourceProfile({
  path: 'projects',
  type: 'project',
  attributes: [
    'name',
    'description',
    'archived',
    'revisions_count',
    'published',
    'created_at',
    'updated_at',
  ],
  relationships: {
    get active_contexts() {
      return {
        ...contexts,
        relationshipMany: true,
      };
    },
    get archived_contexts() {
      return {
        ...contexts,
        relationshipMany: true,
      };
    },
    get contexts() {
      return {
        ...contexts,
        relationshipMany: true,
      };
    },
    get progress_step_checked() {
      return progress;
    },
    get revision_meta_information() {
      return revisionMetaInformation;
    },
    get custom_fields() {
      return { ...customField, relationshipMany: true };
    },
    get prev_revision() {
      return projects;
    },
  },
  includeRelationships: [
    'progress_step_checked',
    'custom_fields',
    'revision_meta_information',
  ],
});

const contexts = createResourceProfile({
  path: 'contexts',
  type: 'context',
  parentType: projects.type,
  createByRelationship: true,
  attributes: [
    'name',
    'description',
    'archived',
    'published',
    'created_at',
    'max_ooc_depth',
    'temporal',
    'updated_at',
    'number_of_code_changes_needed',
    'number_of_definition_changes_needed',
  ],
  relationships: {
    get project() {
      return projects;
    },
    get root_object_occurrence() {
      return objectOccurrences;
    },
    get syntax() {
      return syntax;
    },
    get progress_step_checked() {
      return progress;
    },
    get trade_study() {
      return tradeStudy;
    },
    get custom_fields() {
      return { ...customField, relationshipMany: true };
    },
    get simo_classification_table() {
      return classificationTable;
    },
    get base_context() {
      return contexts;
    },
  },
  includeRelationships: [
    'trade_study',
    'progress_step_checked',
    'syntax',
    'custom_fields',
    'simo_classification_table',
    'base_context',
  ],
});

const syntaxElement = createResourceProfile({
  path: 'syntax_elements',
  type: 'syntax_element',
  attributes: [
    'name',
    'aspect',
    'min_number',
    'max_number',
    'hex_color',
    'classification_table_id',
    'updated_at',
  ],
  parentType: syntax.type,
  createByRelationship: true,
  relationships: {
    [classificationTable.type]: classificationTable,
    [syntax.type]: syntax,
  },
});

const progressModel = createResourceProfile({
  path: 'progress_models',
  type: 'progress_model',
  attributes: [
    'published',
    'archived',
    'progress_model_type',
    'name',
    'updated_at',
  ],
  relationships: {
    get progress_steps() {
      return progressStep;
    },
  },
  includeRelationships: ['progress_steps'],
});

const progressStep = createResourceProfile({
  path: 'progress_steps',
  type: 'progress_step',
  attributes: ['name', 'order', 'hex_color', 'updated_at'],
  relationshipMany: true,
  relationships: {
    get progress_model() {
      return progressModel;
    },
  },
});

const progress = createResourceProfile({
  path: 'progress',
  type: 'progress_step_checked',
  attributes: [],
  relationshipMany: true,
  relationships: {
    get progress_step() {
      return {
        ...progressStep,
        relationshipMany: false,
      };
    },
    get target() {
      return [projects, contexts, objectOccurrences, objectOccurrenceRelations];
    },
  },
});

const permissions = createResourceProfile({
  path: 'permissions',
  type: 'permission',
  attributes: ['name', 'description', 'updated_at'],
});

const userPermissions = createResourceProfile({
  path: 'user_permissions',
  type: 'user_permission',
  attributes: ['updated_at'],
  relationships: {
    get target() {
      return [projects, contexts, objectOccurrences];
    },
    get permission() {
      return permissions;
    },
    get user() {
      return users;
    },
  },
});

const users = createResourceProfile({
  path: 'users',
  type: 'user',
  attributes: ['name', 'email'],
  relationships: {
    get user_permissions() {
      return { ...userPermissions, relationshipMany: true };
    },
  },
  includeRelationships: ['user_permissions'],
});

const userColor = createResourceProfile({
  path: 'user_colors',
  type: 'user_color',
  attributes: ['hex_color', 'created_at', 'updated_at'],
  relationships: {
    get user() {
      return users;
    },
  },
});

const syntaxNode = createResourceProfile({
  path: 'syntax_nodes',
  type: 'syntax_node',
  createByRelationship: true,
  createAsComponent: true,
  parentType: 'syntax_node',
  parentRelationship: 'parent',
  dependencyType: syntaxElement.type,
  dependantAttributes: ['name', 'hex_color'],
  attributes: [
    'position',
    'min_depth',
    'max_depth',
    'syntax_element_id',
    'updated_at',
  ],
  relationships: {
    get components() {
      return {
        ...syntaxNode,
        relationshipMany: true,
      };
    },
    syntax_element: syntaxElement,
    syntax_node: {
      path: 'syntax_nodes',
      type: 'syntax_node',
      createAsComponent: true,
    },
    parent: {
      createAsComponent: true,
      path: 'syntax_nodes',
      type: 'syntax_node',
    },
  },
});

export enum TradeStudiesRelationshipTypes {
  BaseContext = 'upstream_context',
  TradeStudyContext = 'downstream_context',
  Creator = 'creator',
}

const tradeStudy = createResourceProfile({
  type: 'trade_study',
  path: 'trade_studies',
  attributes: [
    'name',
    'copy_oors',
    'copy_permissions',
    'created_at',
    'updated_at',
  ],
  createByRelationship: true,
  relationships: {
    get [TradeStudiesRelationshipTypes.BaseContext]() {
      return contexts;
    },
    get [TradeStudiesRelationshipTypes.TradeStudyContext]() {
      return contexts;
    },
    get [TradeStudiesRelationshipTypes.Creator]() {
      return users;
    },
  },
});

const revisionMetaInformation = createResourceProfile({
  path: 'revisions',
  type: 'revision_meta_information',
  parentType: projects.type,
  createByRelationship: true,
  attributes: [
    'description',
    'created_at',
    'number',
    'pending',
    'jid',
    'updated_at',
  ],
  relationships: {
    get project() {
      return projects;
    },
    get creator() {
      return users;
    },
    get base_project() {
      return projects;
    },
  },
});

const tradeStudyDelta = createResourceProfile({
  type: 'delta',
  path: 'deltas',
  attributes: ['created_at', 'updated_at', 'changes_hash', 'changes_type'],
  relationships: {
    get ts_object_occurrence_relation() {
      return objectOccurrenceRelations;
    },
    get ctx_object_occurrence_relation() {
      return objectOccurrenceRelations;
    },
  },
});

const tradeStudyDeltaGroup = createResourceProfile({
  type: 'delta_group',
  path: 'comparison',
  attributes: [],
  includeRelationships: [
    'ts_object_occurrences',
    'ctx_object_occurrences',
    'deltas.ts_object_occurrence_relation.target',
    'deltas.ts_object_occurrence_relation.source',
    'deltas.ts_object_occurrence_relation.classification_entry',
    'deltas.ts_object_occurrence_relation.progress_step_checked',
    'deltas.ctx_object_occurrence_relation.target',
    'deltas.ctx_object_occurrence_relation.source',
    'deltas.ctx_object_occurrence_relation.classification_entry',
    'deltas.ctx_object_occurrence_relation.progress_step_checked',
  ],
  relationships: {
    get deltas() {
      return { ...tradeStudyDelta, relationshipMany: true };
    },
    get ts_object_occurrences() {
      return { ...objectOccurrences, relationshipMany: true };
    },
    get ctx_object_occurrences() {
      return { ...objectOccurrences, relationshipMany: true };
    },
  },
});

const dashboard = createResourceProfile({
  type: 'dashboard',
  path: 'dashboards',
  attributes: ['name', 'widget_positions', 'updated_at'],
  relationships: {
    get user() {
      return { ...users, relationshipMany: false };
    },
    get project() {
      return projects;
    },
    get widgets() {
      return { ...dashboardWidget, relationshipMany: true };
    },
  },
});

const dashboardWidget = createResourceProfile({
  type: 'widget',
  path: 'widgets',
  attributes: [
    'name',
    'chart',
    'arguments',
    'values',
    'arguments_filters',
    'values_filters',
    'updated_at',
  ],
  relationships: {
    get dashboard() {
      return dashboard;
    },
    get context() {
      return contexts;
    },
  },
});

const owners = createResourceProfile({
  type: 'owner',
  path: 'owners',
  attributes: ['name', 'title', 'company', 'updated_at'],
  relationships: {
    get user() {
      return { ...users, relationshipMany: false };
    },
    get project() {
      return projects;
    },
    get groups() {
      return { ...ownerGroups, relationshipMany: true };
    },
  },
  includeRelationships: ['user'],
});

const ownerCompanies = createResourceProfile({
  type: 'owner_company',
  path: 'owners/companies',
  attributes: ['name'],
});

const ownerGroups = createResourceProfile({
  type: 'owner_group',
  path: 'owner_groups',
  attributes: ['name'],
  relationships: {
    get project() {
      return projects;
    },
    get members() {
      return { ...owners, relationshipMany: true };
    },
  },
});

const ownerships = createResourceProfile({
  type: 'ownership',
  path: 'ownerships',
  createByRelationship: true,
  attributes: ['primary', 'updated_at'],
  includeRelationships: ['owning_entity'],
  relationships: {
    get object_occurrence() {
      return { ...objectOccurrences, relationshipMany: false };
    },
    get owning_entity() {
      return [owners, ownerGroups];
    },
  },
});

const filterSet = createResourceProfile({
  type: 'filter_set',
  path: 'filter_sets',
  attributes: ['name', 'filters', 'created_at', 'updated_at'],
  relationships: {
    get context() {
      return contexts;
    },
  },
});

const domaClassificationTable = createResourceProfile({
  type: 'doma_classification_table',
  path: 'doma_classification_tables',
  attributes: [
    'archived',
    'archived_at',
    'published',
    'published_at',
    'description',
    'name',
    'updated_at',
  ],
  relationships: {
    get classification_entries() {
      return domaClassificationEntry;
    },
  },
});

const domaClassificationEntry = createResourceProfile({
  type: 'doma_classification_entry',
  path: 'doma_classification_entries',
  attributes: [
    'code',
    'from_aspect',
    'to_aspect',
    'class_name',
    'a_to_b_name',
    'b_to_a_name',
    'definition',
    'long_definition',
    'updated_at',
  ],
  relationships: {
    classification_table: domaClassificationTable,
    get classification_entry() {
      return domaClassificationEntry;
    },
    get classification_entries() {
      return { ...domaClassificationEntry, relationshipMany: false };
    },
  },
});

const objectOccurrenceDomainRelation = createResourceProfile({
  type: 'domain_relation',
  path: 'domain_relations',
  attributes: ['name', 'name', 'created_at', 'updated_at'],
  relationships: {
    target: objectOccurrences,
    source: objectOccurrences,
    doma_classification_entry: domaClassificationEntry,
    get custom_field_values() {
      return { ...customFieldValue, relationshipMany: true };
    },
  },
});

const notifications = createResourceProfile({
  type: 'notification',
  path: 'notifications',
  attributes: [
    'origin_id',
    'origin_type',
    'user_id',
    'created_at',
    'updated_at',
  ],
  relationships: {
    origin: exportStruct || importFile || classificationTable,
  },
});

export const resources = getDictionaryFactory<ResourceProfile>({
  validator: (profile) => {
    // check if included relationships are defined
    const { includeRelationships = [] } = profile;
    const invalidPaths = includeRelationships.filter(
      (path) => !isValidRelationshipPath(profile, path.split('.'))
    );
    if (invalidPaths.length) {
      throw new Error(
        `Some relationships included in profile of type '${
          profile.type
        }' are not defined (${invalidPaths.join(', ')})`
      );
    }
  },
})({
  classificationEntriesStats,
  classificationEntry,
  classificationTable,
  contexts,
  customField,
  customFieldValue,
  dashboard,
  dashboardWidget,
  domaClassificationEntry,
  domaClassificationTable,
  exportStruct,
  filterSet,
  importFile,
  meetingPlanner,
  notifications,
  objectOccurrenceDomainRelation,
  objectOccurrenceRelations,
  objectOccurrences,
  oorChainElement,
  ownerCompanies,
  ownerGroups,
  owners,
  ownerships,
  permissions,
  progress,
  progressModel,
  progressStep,
  projects,
  revisionMetaInformation,
  syntax,
  syntaxElement,
  syntaxNode,
  tags,
  tradeStudy,
  tradeStudyDelta,
  tradeStudyDeltaGroup,
  urlStruct,
  userColor,
  userPermissions,
  users,
});
