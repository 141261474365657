<template>
  <li class="header-breadcrumbs-item">
    <slot />
  </li>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';

@Options({})
export default class HeaderBreadcrumbsItem extends Vue {}
</script>

<style lang="scss" scoped>
.header-breadcrumbs-item {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
}
</style>
