<template>
  <v-list-item lines="three" class="user-notifications-export__list-item">
    <div>
      <v-list-item-title>{{ notificationTitle }}</v-list-item-title>
      <v-list-item-subtitle v-if="!!project && !!context">
        {{ project.name }} - {{ context.name }}
      </v-list-item-subtitle>
      <template v-if="isCompleted">
        <v-list-item-subtitle> Started: {{ timeStart }}</v-list-item-subtitle>
        <v-list-item-subtitle> Finished: {{ timeEnd }}</v-list-item-subtitle>
      </template>
      <template v-else>
        <v-skeleton-loader
          type="text"
          max-width="250"
          class="mt-2"
        ></v-skeleton-loader>
        <v-skeleton-loader type="text" max-width="250"></v-skeleton-loader>
      </template>
    </div>

    <div class="user-notifications-export__files">
      <template v-if="isCompleted">
        <a v-if="origin.csv?.url" :href="origin.csv.url"> Download csv </a>
        <a v-if="origin.xlsx?.url" :href="origin.xlsx.url"> Download xlsx </a>
      </template>
      <v-progress-circular
        v-else
        size="20"
        color="var(--v-color-base)"
        indeterminate
      />
    </div>
  </v-list-item>
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import { Context } from '@/models/context';
import { useDirectStore } from '@/composables/store';
import { Project } from '@/models/project';
import { createIncludeRelationshipPath, resources } from '@/services/resources';
import { useNotification } from '@/composables/useNotification';
import { ExportStruct } from '@/models/exportStruct';

type Props = {
  notificationId: string;
};

const props = defineProps<Props>();

const store = useDirectStore();
const { timeEnd, timeStart, notificationTitle, isCompleted, origin } =
  useNotification<ExportStruct>(props.notificationId);

const contextId = computed<string>(() => {
  const contextId = store.exportStruct.getRelationshipId(
    origin.value.id,
    'context'
  ) as string;
  return contextId;
});

const context = computed<Context>(() => {
  if (!contextId.value) return null;
  return store.context.getSimplifiedResourceSet()[contextId.value];
});

const project = computed<Project>(() => {
  if (!context.value) return null;
  return store.project.getSimplifiedResourceSet()[
    store.context.getRelatedProjectId(context.value.id)
  ];
});

watch(
  contextId,
  (resourceId) => {
    if (!resourceId) return;
    store.context.dispatchLoadSingleResource({
      resourceId,
      include: [createIncludeRelationshipPath(resources.contexts)('project')],
    });
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.user-notifications-export__list-item :deep(.v-list-item__content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-notifications-export__files {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  margin: 0;
}
</style>
