<template>
  <ResourceTable
    ref="resourceTable"
    :resourceConfig="resourceConfig"
    :customColumns="columns"
    :selectedItemId="selectedItemId"
    @click:item="emit('click:item', $event)"
    :requestType="requestType"
    class="projects-page-archived"
    hideHeaders
  >
    <template #cursor="{ attrs, on }">
      <ResourceTableCursor v-bind="attrs" v-on="on" />
    </template>
    <template #attribute-revisions="{ item }">
      <span>
        {{ $tc('projectRevisions.nProjectRevisions', item.revisions_count) }}
      </span>
    </template>
    <template #attribute-meta="{ item }">
      <v-chip
        :class="{ 'background--highlighted': !item.archived }"
        :color="item.archived ? '' : 'white'"
      >
        {{ item.archived ? 'Archived' : 'Active' }}
      </v-chip>
    </template>
    <template #attribute-actions="{ item }">
      <slot name="attribute-actions" :item="item" />
    </template>
    <template #attribute-role="{ item }">
      <RoleDisplayer :projectID="item.id" :userID="getUserId()" />
    </template>
  </ResourceTable>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import ResourceTable from '../resourceTable/resourceTable.vue';
import RoleDisplayer from '@/components/admin/users/roleDisplayer.vue';
import ResourceTableCursor from '@/components/resourceTable/resourceTableCursor.vue';
import { ResourceConfig, TableColumnDefinition } from '@/utils/resourceTable';
import { useDirectStore } from '@/composables/store';
import { getUserId } from '@/auth';

type Props = {
  columns: TableColumnDefinition[];
  requestType: string;
  query: string;
  selectedItemId?: string;
};

type Emits = {
  (e: 'click:item', projectId: string): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const store = useDirectStore();

const resourceConfig = computed<ResourceConfig>(() => ({
  module: store.project,
  pathParameters: {
    filterArchived: true,
    filterArchivedOrHasArchivedContent: true,
    query: props.query,
  },
}));
</script>
<style lang="scss" scoped></style>
