import {
  ChartType,
  Chart,
  Plugin,
  Tooltip,
  TooltipPositionerFunction,
  LinearScaleOptions,
} from 'chart.js';

declare module 'chart.js' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    hooks: HooksOptions;
    chartAreaOffset: ChartAreaOffsetOptions;
    customLegend: CustomLegendOptions;
  }

  interface TooltipPositionerMap {
    top: TooltipPositionerFunction<ChartType>;
  }
}

type HooksOptions = Partial<{
  beforeRender: (chart: Chart) => unknown;
  afterRender: (chart: Chart) => unknown;
}>;

type ChartAreaOffsetOptions = Partial<{
  /** minimal `y.max` value */
  min: number;
  /** offset for `y.max` as factor of the highest stack */
  offset: number;
}>;

export type CustomLegendItem = {
  label: string;
  color: string;
  hoverColor: string;
  isVisible: boolean;
  toggleVisibility: () => void;
};

type CustomLegendOptions = Partial<{
  onUpdate: (items: CustomLegendItem[]) => void;
}>;

export const HooksPlugin: Plugin<ChartType, HooksOptions> = {
  id: 'hooks',
  beforeRender: (chart, args, options) => {
    options.beforeRender?.(chart);
  },
  afterRender: (chart, args, options) => {
    options.afterRender?.(chart);
  },
};

Tooltip.positioners.top = (elements) => {
  const lastElement = elements.slice(-1)[0];
  if (!lastElement) return false;
  return {
    x: lastElement.element.x,
    y: lastElement.element.y,
  };
};

/**
 * Plugin extends y axis so that there is a certain amount of space
 * above the highest bar (measured as a percentage of the highest bar).
 */
export const ChartAreaOffsetPlugin: Plugin<ChartType, ChartAreaOffsetOptions> =
  {
    id: 'chartAreaOffset',
    beforeUpdate: (chart, args, _options = {}) => {
      const options: ChartAreaOffsetOptions = {
        min: _options.min ?? 1,
        offset: _options.offset ?? 0,
      };

      let suggestedMax = options.min;

      if (options.offset > 0) {
        const dataLength = chart.data.labels.length;
        const totals = chart.data.datasets.reduce(
          (sums, dataset, datasetIndex) => {
            if (!chart.isDatasetVisible(datasetIndex)) {
              return sums;
            }
            return sums.map((sum, index) => sum + (+dataset.data[index] || 0));
          },
          new Array(dataLength).fill(0)
        );
        const maxTotal = Math.max(0, ...totals);
        const maxTotalWithOffset =
          maxTotal + Math.ceil(maxTotal * options.offset);
        suggestedMax = Math.max(suggestedMax, maxTotalWithOffset);
      }

      const yScaleOptions = chart.options.scales.y as LinearScaleOptions;
      if (yScaleOptions.suggestedMax !== suggestedMax) {
        yScaleOptions.suggestedMax = suggestedMax;
        chart.update();
      }
    },
  };

export const CustomLegendPlugin: Plugin<ChartType, CustomLegendOptions> = {
  id: 'customLegend',
  afterUpdate(chart, args, options) {
    const items = chart.data.datasets.map<CustomLegendItem>(
      (dataset, datasetIndex) => {
        const isVisible = chart.isDatasetVisible(datasetIndex);
        return {
          label: dataset.label,
          color: dataset.backgroundColor as string,
          hoverColor: dataset.hoverBackgroundColor as string,
          isVisible,
          toggleVisibility: () => {
            chart.setDatasetVisibility(datasetIndex, !isVisible);
            chart.update();
          },
        };
      }
    );
    options.onUpdate?.(items);
  },
};
