import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import indexManager from '../components/indexManager';
import crud, { CrudAccessors } from '../components/crud';
import jv from '../components/jv';

export default createModule({
  path: 'ownerGroups',
  resourceProfile: resources.ownerGroups,
  components: [indexManager, crud, jv],
  setup({ components, getAccessors }) {
    const actions = {
      async deleteResource(
        context,
        payload: Parameters<CrudAccessors['dispatchDeleteResource']>[0]
      ): ReturnType<CrudAccessors['dispatchDeleteResource']> {
        await components.$crud.public.dispatchDeleteResource(payload);
        try {
          const relatedOwnerships = components.$jv.protected.get(
            resources.ownerships.type,
            `$[?(@._jv.relationships.owning_entity.data.id=="${payload.resourceId}")]`
          );
          Object.keys(relatedOwnerships).forEach((id) => {
            components.$jv.protected.commitDeleteRecord({
              _jv: { id, type: resources.ownerships.type },
            });
          });
        } catch (error) {
          console.error(error);
          /* ignore error */
        }
      },
      deleteExternalResource(
        context,
        payload: Parameters<CrudAccessors['dispatchDeleteResource']>[0]
      ) {
        try {
          const relatedOwnerships = components.$jv.protected.get(
            resources.ownerships.type,
            `$[?(@._jv.relationships.owning_entity.data.id=="${payload.resourceId}")]`
          );
          Object.keys(relatedOwnerships).forEach((id) => {
            components.$jv.protected.commitDeleteRecord({
              _jv: { id, type: resources.ownerships.type },
            });
          });
        } catch (error) {
          console.error(error);
          /* ignore error */
        }
      },
    };
    const { dispatch } = getAccessors();
    return {
      module: {
        actions,
      },
      protected: {
        dispatchDeleteExternalResource: dispatch(
          actions.deleteExternalResource
        ),
      },
      public: {
        ...components.$indexManager.public,
        ...components.$crud.public,
        dispatchDeleteResource: dispatch(actions.deleteResource),
      },
    };
  },
});
