import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud, { ResourceIdentification } from '../components/crud';
import indexManager from '../components/indexManager';
import processingTracker from '../components/processingTracker';
import archiver from '../components/archiver';
import jv from '../components/jv';
import { extractRelatedEntityId } from '@/utils/jvTools';
import { extractPollingId } from '@/utils/path';
import { api } from '@/services/api';
import { JsonApiResource } from '@/models/api';

export interface ProjectsPageFilters {
  showArchivedResources: boolean;
}

export interface ProjectFilters {
  showRelationsForSelected: boolean;
}

const getDefaultFilters = (): ProjectsPageFilters => ({
  showArchivedResources: false,
});

const getDefaultProjectFilters = (): ProjectFilters => ({
  showRelationsForSelected: false,
});

interface State {
  filters: ProjectsPageFilters;
  projectFilters: ProjectFilters;
}

export default createModule({
  path: 'project',
  resourceProfile: resources.projects,
  components: [archiver, crud, indexManager, processingTracker, jv],
  setup({ components, getAccessors, resourceProfile }) {
    const state = (): State => ({
      filters: getDefaultFilters(),
      projectFilters: getDefaultProjectFilters(),
    });

    const getters = {
      filters: (state: State) => state.filters,
      projectFilters: (state: State) => state.projectFilters,
    };

    const mutations = {
      setFilters(state: State, payload: { filters: ProjectsPageFilters }) {
        state.filters = payload.filters;
      },
      setProjectFilters(state: State, payload: { filters: ProjectFilters }) {
        state.projectFilters = payload.filters;
      },
    };

    const actions = {
      archiveResource(_, payload: ResourceIdentification) {
        components.$archiver.public.dispatchArchiveResource(payload);
        const relatedContexts = Object.values(
          components.$jv.protected.get(resources.contexts.type)
        ).filter((context) => {
          return (
            extractRelatedEntityId('project', context) === payload.resourceId
          );
        });
        relatedContexts.forEach((context) => {
          components.$jv.protected.commitReplaceRecords({
            ...context,
            archived: true,
          });
        });
      },
      setFilters(_, payload: { filters: ProjectsPageFilters }) {
        commit(mutations.setFilters)({ filters: { ...payload.filters } });
      },
      setProjectFilters(_, payload: { filters: ProjectFilters }) {
        commit(mutations.setProjectFilters)({
          filters: { ...payload.filters },
        });
      },
      clearFilters(_) {
        commit(mutations.setFilters)({ filters: getDefaultFilters() });
      },
      clearProjectFilters(_) {
        commit(mutations.setProjectFilters)({
          filters: {
            showRelationsForSelected: false,
          },
        });
      },
      async duplicateProject(
        context,
        payload: { projectId: string; name: string }
      ) {
        const path = `/async/${resourceProfile.path}/${payload.projectId}/copy`;
        const response = await api.post(path, {
          data: {
            type: resourceProfile.type,
            attributes: {
              name: payload.name,
            },
          },
        });
        const pollLocation = response.headers.location;
        const pollId = extractPollingId(pollLocation);
        if (!pollId) throw new Error('Unexpected response');
        const pollingPromise: Promise<{ data?: { data?: JsonApiResource } }> =
          context.dispatch(
            'polling/addJob',
            {
              id: pollId,
              url: pollLocation,
            },
            { root: true }
          );
        return pollingPromise.then((response) => {
          const copiedProjectId = response.data.data.id;
          // refetch the project:
          // committing to jv store is not sufficient because
          // polling returns context without relationships included
          return components.$crud.public
            .dispatchLoadSingleResource({
              resourceId: copiedProjectId,
            })
            .then(() => ({ id: copiedProjectId }));
        });
      },
    };
    const { read, commit, dispatch } = getAccessors<State>();
    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$archiver.public,
        ...components.$indexManager.public,
        ...components.$processingTracker.public,
        dispatchArchiveResource: dispatch(actions.archiveResource),
        getFilters: read(getters.filters),
        getProjectFilters: read(getters.projectFilters),
        dispatchSetFilters: dispatch(actions.setFilters),
        dispatchSetProjectFilters: dispatch(actions.setProjectFilters),
        dispatchClearFilters: dispatch(actions.clearFilters),
        dispatchClearProjectFilters: dispatch(actions.clearProjectFilters),
        dispatchDuplicateProject: dispatch(actions.duplicateProject),
      },
    };
  },
});
