<template>
  <v-menu
    :close-on-content-click="false"
    v-model="isMenuOpen"
    offset="8"
    location="bottom"
    max-width="500"
    min-width="500"
    max-height="375"
  >
    <template #activator="{ props }">
      <v-badge
        :model-value="shouldShowIndicator && !isMenuOpen"
        color="error"
        dot
        offset-x="12"
        offset-y="12"
      >
        <v-btn v-bind="props" icon>
          <v-icon>mdi-bell-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list class="notifications__list">
      <v-list-item>
        <v-list-item-title>Latest notifications</v-list-item-title>
      </v-list-item>
      <div class="px-3" v-if="loading">
        <v-skeleton-loader
          class="mb-2"
          type="paragraph"
          v-for="i in NOTIFICATIONS_AMOUNT"
          :key="i"
        />
      </div>
      <div class="px-3" v-else-if="notifications.length === 0">
        <v-alert type="info" icon="mdi-information-outline" variant="text">
          No new notifications
        </v-alert>
      </div>
      <template v-else>
        <template
          v-for="notification in notifications"
          :key="
            isUserNotification(notification)
              ? notification?.id
              : (notification?.notification as any).csv_endpoint
          "
        >
          <component
            v-if="isUserNotification(notification)"
            :is="getNotificationComponent(notification.origin_type)"
            :notificationId="notification.id"
          />
          <UserNotificationsExportNewSchema
            v-else
            :notification="notification.notification"
          />
        </template>
      </template>
    </v-list>
  </v-menu>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { composeMessage } from '@/services/errorHandler';
import { UserNotification } from '@/models/userNotification';
import { Notification } from '@/openapi';
import UserNotificationsExport from '@/components/userNotifications/userNotificationsExport.vue';
import userNotificationsImport from './userNotifications/userNotificationsImport.vue';
import UserNotificationsExportNewSchema from './userNotifications/userNotificationsExportNewSchema.vue';
import { useNotificationStore } from '@/stores/notifications';
import { storeToRefs } from 'pinia';

const NOTIFICATIONS_AMOUNT = 5;

const store = useDirectStore();
const { fetchNotifications, setHasNewNotification } = useNotificationStore();
const { notifications: piniaNotifications, hasNewNotification } = storeToRefs(
  useNotificationStore()
);

const isMenuOpen = ref(false);
const loading = ref(false);

const latestMenuClosed = ref(new Date());

const vuexNotifications = computed<UserNotification[]>(() =>
  store.userNotifications.getFullResource()
);

const notifications = computed<(UserNotification | Notification)[]>(() =>
  [...piniaNotifications.value, ...vuexNotifications.value].slice(
    0,
    NOTIFICATIONS_AMOUNT
  )
);

const shouldShowIndicator = computed(
  () =>
    vuexNotifications.value.some(({ updated_at }) => {
      return new Date(updated_at) > latestMenuClosed.value;
    }) || hasNewNotification.value
);

const isUserNotification = (
  item: UserNotification | Notification
): item is UserNotification => 'origin_type' in item;

const getNotificationComponent = (type: UserNotification['origin_type']) => {
  switch (type) {
    case 'Export':
      return UserNotificationsExport;
    case 'Import':
      return userNotificationsImport;
    default:
      return null;
  }
};

const fetchLatestNotifications = async () => {
  try {
    loading.value = true;
    await fetchNotifications();
  } catch (err) {
    store.notifications.dispatchNotify({
      type: 'error',
      message: composeMessage(err),
    });
  } finally {
    loading.value = false;
  }
};

watch(isMenuOpen, (isOpen) => {
  if (isOpen && (notifications.value.length === 0 || hasNewNotification)) {
    fetchLatestNotifications();
  }
  if (isOpen) {
    setHasNewNotification(false);
  }
  if (!isOpen) {
    latestMenuClosed.value = new Date();
  }
});
</script>
<style lang="scss" scoped>
.user-notifications__notification {
  margin: 0 16px;
  border-radius: 4px;

  & :deep(.v-list-item__action) {
    align-self: auto;
  }
}

.notifications__list {
  width: 500px;
}
</style>
