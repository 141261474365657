import { OODR } from '@/models/objectOccurrenceDomainRelation';
import { SyntaxAspectType } from '@/models/syntax';
import { directedDomaClassificationEntry } from './classificationEntries';

export const directedOodr = (
  sourceId: string,
  from: SyntaxAspectType,
  to: SyntaxAspectType,
  oodr: OODR
): OODR => {
  return {
    ...oodr,
    ...(oodr.target.id === sourceId
      ? { source: oodr.target, target: oodr.source }
      : {}),
    doma_classification_entry: directedDomaClassificationEntry(
      from,
      to,
      oodr.doma_classification_entry
    ),
  };
};
