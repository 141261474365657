<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { eventBus } from '@/services/eventBus/eventBus';
import { tableCursor } from '@/services/tableCursor';
import { clamp } from '@/utils/math';
import ResourceTableRow from './resourceTableRow.vue';

@Options({
  emits: ['expand:row', 'collapse:row', 'click:row'],
})
export default class ResourceTableCursor extends Vue {
  @Prop({ required: true }) rows: (typeof ResourceTableRow)[];

  get rowsNumber() {
    return this.rows.length;
  }

  get rowsSorted() {
    return this.rows
      .slice()
      .sort(
        (a, b) =>
          (a.$el as HTMLTableRowElement).rowIndex -
          (b.$el as HTMLTableRowElement).rowIndex
      );
  }

  getFocusedRowIndex() {
    return this.rowsSorted.findIndex((row) => row.$el.matches(':focus-within'));
  }

  mounted() {
    eventBus.$on('keydown', this.onKeyDown);
  }

  beforeUnmount() {
    eventBus.$off('keydown', this.onKeyDown);
  }

  onKeyDown(event: KeyboardEvent) {
    tableCursor(event, {
      focusedRow: this.getFocusedRowIndex(),
      focusRow: this.safeFocusRow,
      expandRow: this.expandRow,
      collapseRow: this.collapseRow,
      clickRow: this.clickRow,
    });
  }

  safeFocusRow(index: number) {
    if (this.rowsNumber) {
      const safeIndex = clamp(index, 0, this.rowsNumber - 1);
      const tableRow = this.rowsSorted[safeIndex].$el as HTMLTableRowElement;
      tableRow.focus();
    }
  }

  getItemByIndex(index: number): (typeof ResourceTableRow)['item'] {
    return this.rowsSorted[index]?.item;
  }

  expandRow(index: number) {
    const item = this.getItemByIndex(index);
    if (item) {
      this.$emit('expand:row', item);
    }
  }

  collapseRow(index: number) {
    const item = this.getItemByIndex(index);
    if (item) {
      this.$emit('collapse:row', item);
    }
  }

  clickRow(index: number) {
    const item = this.getItemByIndex(index);
    if (item) {
      this.$emit('click:row', item);
    }
  }

  render() {
    return null;
  }
}
</script>
