<template>
  <template v-if="isLoadingArticles">
    <div class="progress-wheel-wrapper">
      <v-progress-circular
        :indeterminate="true"
        color="secondary"
        size="70"
        width="5"
      />
    </div>
  </template>
  <template v-else-if="!articlesToShow">
    <div class="article-no-results">
      <v-icon icon="mdi-alert-circle-outline" size="x-large" />
      <h3>Try again</h3>
      <div>No resualts for "{{ props.searchValue }}"</div>
    </div>
  </template>
  <template v-else>
    <div class="article-list">
      <v-list lines="three">
        <v-list-item
          v-for="article in articlesToShow"
          :key="article.id"
          :title="article.title"
          :subtitle="filterArticleBody(article.body)"
          @click="emit('article-selected', article)"
          id="article-item"
        >
        </v-list-item>
      </v-list>
    </div>
    <v-btn
      v-if="hasMoreToShow"
      class="show-more-button"
      @click="loadMoreOrLess"
      variant="text"
      color="primary"
      size="small"
      density="comfortable"
    >
      {{ showMoreText }}
    </v-btn>
  </template>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, onBeforeMount, watch, nextTick } from 'vue';
import { useDirectStore } from '@/composables/store';
import { composeMessage } from '@/services/errorHandler';

const store = useDirectStore();

const props = defineProps<{
  heightOfContainer: number;
  searchValue: string;
}>();

const emit = defineEmits<{
  (e: 'article-selected', article): void;
}>();

const showAllArticles = ref(false);
const isLoadingArticles = ref(false);
const articlesNumber = ref(0);

const loadMoreOrLess = () => {
  showAllArticles.value = !showAllArticles.value;
};

const calculateArticlesToShow = () => {
  //the 105 is the height of article in the v-list-item plus some padding;
  articlesNumber.value = props.heightOfContainer / 105;
};

const hasMoreToShow = computed(() => {
  return articles.value.length >= articlesToShow.value.length;
});
const showMoreText = computed(() => {
  let buttonText: string;
  if (showAllArticles.value) buttonText = 'Show less';
  else
    buttonText = `Show ${
      articles.value.length - articlesToShow.value.length
    } more articles`;
  return buttonText;
});
const filterArticleBody = (bodyString: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = bodyString;
  const articleText = tempDiv.querySelectorAll('p');
  return articleText[0].textContent;
};

const articlesToShow = computed(() => {
  if (articles.value) {
    if (showAllArticles.value) return articles.value;
    const reducedNumberArticles = articles.value.slice(0, articlesNumber.value);
    return reducedNumberArticles;
  }
  return false;
});

const articles = computed(() => {
  return props.searchValue ? searchedArticles.value : allArticles.value;
});

const allArticles = computed(() => {
  const allArticles = store.intercom.getAllArticles();
  const publishedArticles: any = allArticles.filter(
    (allArticles: any) => allArticles.state === 'published'
  );
  return publishedArticles;
});

const searchedArticles = computed(() => {
  const articles = store.intercom.getAllSearchArticles();
  return articles.articles;
});

const loadAllArticles = async () => {
  try {
    isLoadingArticles.value = true;
    await store.intercom.dispatchLoadIntercomArticles();
  } catch (error) {
    store.notifications.dispatchNotify({
      type: 'error',
      message: composeMessage(error),
    });
  } finally {
    isLoadingArticles.value = false;
  }
};

const loadSearchArticles = async (searchQuery) => {
  try {
    isLoadingArticles.value = true;
    await store.intercom.dispatchSearchIntercomArticles(searchQuery);
  } catch (error) {
    store.notifications.dispatchNotify({
      type: 'error',
      message: composeMessage(error),
    });
  } finally {
    isLoadingArticles.value = false;
  }
};

onBeforeMount(() => {
  loadAllArticles();
});

onMounted(() => {
  nextTick(calculateArticlesToShow);
});

watch(
  () => props.heightOfContainer,
  () => {
    calculateArticlesToShow();
  }
);

watch(
  () => props.searchValue,
  () => {
    loadSearchArticles(props.searchValue);
  }
);
</script>
<style lang="scss" scoped>
.progress-wheel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.article-no-results {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.article-list {
  overflow: auto;
  height: 100%;
}
.show-more-button {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: white;
}
</style>
