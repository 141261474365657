import { CoreNodesCollection } from '@/utils/core';
import { FlatTreeNode } from '@/models/treeStructure';
import { createModuleComponent } from '../utils/factories';
import { ComponentType } from './_types';
import { treeId, TreeIdentification } from '@/utils/tree';

interface State {
  activeTree: TreeIdentification | null;
  selectedNodes: Partial<{
    [treeId: string]: {
      selectedNodeId: string | null;
    };
  }>;
  multipleSelect: Partial<{
    [treeId: string]: CoreNodesCollection;
  }>;
}

class ActivatableNodesCollection extends CoreNodesCollection {
  private activateTree: () => void;
  private isTreeActive: () => boolean;

  constructor(activateTreeCb: () => void, isTreeActive: () => boolean) {
    super();
    this.activateTree = activateTreeCb;
    this.isTreeActive = isTreeActive;
  }

  selectColumn(nodeId: string, tree: FlatTreeNode[]) {
    super.selectColumn(nodeId, tree);
    this.activateTree();
  }
  selectNode(
    nodeId: string,
    options?: { override?: boolean; programmatic?: boolean }
  ) {
    super.selectNode(nodeId, options);
    if (!options?.programmatic) this.activateTree();
  }
  deselectNode(nodeId: string) {
    super.deselectNode(nodeId);
    this.activateTree();
  }
  get selectedNodes(): string[] {
    if (!this.isTreeActive()) return [];
    return super.selectedNodes;
  }
}

const treeSelectionManager = createModuleComponent({
  type: ComponentType.TreeSelectionManager,
  setup: ({ getAccessors }) => {
    const state = (): State => ({
      activeTree: null,
      selectedNodes: {},
      multipleSelect: {},
    });

    const getters = {
      activeTree: (state: State) => state.activeTree,
      activeTreeId: (state: State) =>
        state.activeTree && treeId(read(getters.activeTree)()),
      selectedNodeId: (state: State) => (tree: TreeIdentification) =>
        state.selectedNodes[treeId(tree)]?.selectedNodeId,
      multipleSelectionManager: (state: State) => (tree: TreeIdentification) =>
        state.multipleSelect[treeId(tree)],
    };

    const mutations = {
      setActiveTree(
        state: State,
        payload: { tree: TreeIdentification | null }
      ) {
        state.activeTree = payload.tree;
      },
      selectNode(
        state: State,
        payload: { tree: TreeIdentification; id: string | null }
      ) {
        if (!state.selectedNodes[treeId(payload.tree)]) {
          state.selectedNodes[treeId(payload.tree)] = {
            selectedNodeId: payload.id,
          };
        } else {
          state.selectedNodes[treeId(payload.tree)].selectedNodeId = payload.id;
        }
      },
      createSelectionManager(
        state: State,
        payload: { tree: TreeIdentification }
      ) {
        if (!state.multipleSelect[treeId(payload.tree)]) {
          state.multipleSelect[treeId(payload.tree)] =
            new ActivatableNodesCollection(
              () => dispatch(actions.setActiveTree)(payload),
              () => read(getters.activeTreeId)() === treeId(payload.tree)
            );
        }
      },
    };

    const actions = {
      setActiveTree(context, payload: { tree: TreeIdentification | null }) {
        commit(mutations.setActiveTree)(payload);
      },
      selectNode(
        context,
        payload: {
          tree: TreeIdentification;
          id: string | null;
          noActivate?: boolean;
        }
      ) {
        commit(mutations.selectNode)(payload);
        if (payload.id && !payload.noActivate) {
          commit(mutations.setActiveTree)({ tree: payload.tree });
        }
      },
      createSelectionManager(context, payload: { tree: TreeIdentification }) {
        if (read(getters.multipleSelectionManager)(payload.tree)) return;
        commit(mutations.createSelectionManager)(payload);
      },
    };

    const { read, commit, dispatch } = getAccessors();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        getActiveTree: read(getters.activeTree),
        getSelectedNodeId: read(getters.selectedNodeId),
        getMultipleSelectionManager: read(getters.multipleSelectionManager),

        dispatchSetActiveTree: dispatch(actions.setActiveTree),
        dispatchSelectNode: dispatch(actions.selectNode),
        dispatchCreateManager: dispatch(actions.createSelectionManager),
      },
    };
  },
});

export default treeSelectionManager;
