import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import jv from '../components/jv';
import { api } from '@/services/api';
import { extractPollingId } from '@/utils/path';
import { JsonApiResource } from '@/models/api';
import { OOCModifiedField } from '@/models/revision';

type BaseOOCFilters = {
  addition: boolean;
  deletion: boolean;
  change: boolean;
};

type ChangeOOCFilters = {
  name: boolean;
  // color: boolean;
  progress: boolean;
  description: boolean;
  // image: boolean;
  owner: boolean;
  owner_group: boolean;
};

export const oocApiFieldToFilterKey = (
  field: OOCModifiedField | 'DESCRIPTION'
): keyof ChangeOOCFilters =>
  (
    ({
      NAME: 'name',
      // COLOR: 'color',
      PROGRESS: 'progress',
      DESCRIPTION: 'description',
      // IMAGE: 'image',
      OWNER: 'owner',
      OWNERGROUP: 'owner_group',
    }) as const
  )[field.split(':')[0]];

export interface RevisionOOCFilters {
  base: BaseOOCFilters;
  change: ChangeOOCFilters;
}

interface State {
  filters: {
    ooc: RevisionOOCFilters;
  };
}

export const getDefaultOOCFilters = (): RevisionOOCFilters => ({
  base: {
    addition: true,
    deletion: true,
    change: true,
  },
  change: {
    name: true,
    // color: true,
    progress: true,
    description: true,
    // image: true,
    owner: true,
    owner_group: true,
  },
});

export default createModule({
  path: 'revision',
  resourceProfile: resources.revisionMetaInformation,
  components: [crud, jv, indexManager],
  setup({ getAccessors, components, resourceProfile }) {
    const state = (): State => ({
      filters: {
        ooc: getDefaultOOCFilters(),
      },
    });
    const getters = {
      oocFilters: (state: State) => state.filters.ooc,
      getRevisionProjectId:
        () =>
        (revisionId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            revisionId,
            'project'
          );
        },
      getBaseProjectId:
        () =>
        (revisionId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            revisionId,
            'base_project'
          );
        },
    };
    const mutations = {
      setOOCFilters(state: State, payload: { filters: RevisionOOCFilters }) {
        state.filters.ooc = payload.filters;
      },
    };
    const actions = {
      setOOCFilters(context, payload: { filters: RevisionOOCFilters }) {
        commit(mutations.setOOCFilters)(payload);
      },
      clearOOCFilters() {
        commit(mutations.setOOCFilters)({ filters: getDefaultOOCFilters() });
      },
      async createRevision(
        context,
        payload: { projectId: string; description: string; include?: string[] }
      ) {
        const response = await api.post(
          `async/projects/${payload.projectId}/revision`,
          {
            data: {
              type: resourceProfile.type,
              attributes: {
                description: payload.description,
              },
            },
          }
        );
        const pollLocation = response.headers.location;
        const pollingId = extractPollingId(pollLocation);
        if (!pollingId) throw new Error('Unexpected response');
        return dispatch(actions.waitForRevisionCreated)({
          pollingId,
          include: payload.include,
        });
      },
      async updateRevision(
        context,
        payload: { projectId: string; description: string; revisionId: string }
      ) {
        await components.$crud.public.dispatchUpdateResource({
          resource: {
            description: payload.description,
          },
          resourceId: payload.revisionId,
        });
      },
      async waitForRevisionCreated(
        context,
        payload: {
          pollingId: string;
          include?: string[];
        }
      ) {
        const { pollingId, include } = payload;
        const pollingPromise: Promise<{ data?: { data?: JsonApiResource } }> =
          context.dispatch(
            'polling/addJob',
            {
              id: pollingId,
              url: `${window.__env.VUE_APP_API_URL}/polling/${pollingId}`,
            },
            { root: true }
          );
        const pollingResponse = await pollingPromise;
        const { id } = pollingResponse.data.data.relationships
          .revision_meta_information.data as JsonApiResource;
        await components.$crud.public.dispatchLoadSingleResource({
          resourceId: id,
          include,
        });
        return { id };
      },
    };
    const { read, dispatch, commit } = getAccessors<State>();
    return {
      module: {
        state,
        mutations,
        getters,
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        getBaseProjectId: read(getters.getBaseProjectId),
        gerRevisionProjectId: read(getters.getRevisionProjectId),
        getOOCFilters: read(getters.oocFilters),
        dispatchSetOOCFilters: dispatch(actions.setOOCFilters),
        dispatchClearOOCFilters: dispatch(actions.clearOOCFilters),
        dispatchCreateRevision: dispatch(actions.createRevision),
        dispatchUpdateRevision: dispatch(actions.updateRevision),
        dispatchWaitForRevisionCreated: dispatch(
          actions.waitForRevisionCreated
        ),
      },
    };
  },
});
