import { getNotifications, Notification } from '@/openapi';
import { localClient } from '@/openapi/client';
import { defineStore } from 'pinia';
import { readonly, ref } from 'vue';

export const useNotificationStore = defineStore('notifications-store', () => {
  const notifications = ref<Notification[]>([]);
  const hasNewNotification = ref<boolean>(false);

  const fetchNotifications = async () => {
    try {
      const { data } = await getNotifications({
        client: localClient,
      });
      notifications.value = data as Notification[];
    } catch (err) {
      console.error(err);
    }
  };

  const setHasNewNotification = (value: boolean) =>
    (hasNewNotification.value = value);

  return {
    notifications: readonly(notifications),
    hasNewNotification: readonly(hasNewNotification),
    fetchNotifications,
    setHasNewNotification,
  };
});
