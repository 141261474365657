<template>
  <v-list class="draggable-list">
    <transition-group
      v-if="draggingItem"
      class="draggable-list__preview"
      name="reorder"
      tag="div"
    >
      <template v-for="item in orderedValue" :key="`preview_${item}`">
        <DraggableListItem :hideDraggableIcon="hideDraggableIcon">
          <template #prepend>
            <slot name="prepend" :item="item" />
          </template>
          <template #content>
            <slot name="item" :item="item" />
          </template>
        </DraggableListItem>
      </template>
    </transition-group>

    <template v-for="(item, index) in modelValue" :key="item">
      <DraggableListItem
        :ref="`dragImage_${item}`"
        :isDragging="draggingItem === item"
        :style="{ opacity: draggingItem && '0' }"
        :disabled="disabled"
        :hideDraggableIcon="hideDraggableIcon"
        @dragEnter="(e) => onDragEnter(e, index)"
        @dragLeave="(e) => onDragLeave(e, index)"
        @dragOver="(e) => onDragOver(e)"
        @dragEnd="(e) => onDragEnd()"
        @dragStart="(e) => onDragStart(e, item)"
      >
        <template #prepend>
          <slot name="prepend" :item="item" />
        </template>
        <template #content>
          <slot name="item" :item="item" />
        </template>
      </DraggableListItem>
    </template>
    <slot />
  </v-list>
</template>

<script lang="ts">
import { Vue, Options, Prop } from 'vue-property-decorator';
import DraggableListItem from './draggableListItem.vue';
@Options({
  components: {
    DraggableListItem,
  },
  emits: ['update:modelValue', 'dragEnd'],
})
export default class DraggableList extends Vue {
  @Prop({ default: () => [] }) modelValue: string[];
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) hideDraggableIcon?: boolean;

  draggingItem: string | null = null;
  dropTargetIndex: number | null = null;

  get orderedValue() {
    const value = [...this.modelValue];
    value.splice(value.indexOf(this.draggingItem), 1);
    value.splice(this.dropTargetIndex, 0, this.draggingItem);
    return value;
  }

  onDragStart(event: DragEvent, item: string) {
    this.draggingItem = item;
    this.dropTargetIndex = this.modelValue.indexOf(item);
    const dragImage = this.$refs[`dragImage_${item}`][0].$el;
    event.dataTransfer.setDragImage(dragImage, 0, 0);
  }
  onDragEnd() {
    this.$emit('dragEnd', this.draggingItem);
    const newValue = this.orderedValue;
    this.draggingItem = null;
    this.dropTargetIndex = null;
    this.$emit('update:modelValue', newValue);
  }
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragEnter(event: DragEvent, index: number) {
    event.stopPropagation();
    if (this.draggingItem) {
      this.dropTargetIndex = index;
    }
  }

  onDragLeave(event: DragEvent, index: number) {
    event.stopPropagation();
    if (this.dropTargetIndex === index) {
      this.dropTargetIndex = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.draggable-list {
  position: relative;
}
.draggable-list__preview {
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.draggable-list__indicator {
  height: 0;
  outline: 1px solid rgb(var(--v-theme-primary));
}
.reorder-move {
  transition: transform 0.3s;
}
</style>
