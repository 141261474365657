const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const formatDate = (date: Date | string): string | boolean => {
  if (!date) return false;

  const parsedDate = new Date(date);
  const year = parsedDate.getFullYear();
  const month = padTo2Digits(parsedDate.getMonth() + 1);
  const day = padTo2Digits(parsedDate.getDate());
  return `${year}-${month}-${day}`;
};

export const formatDateTime = (date: Date | string): string | boolean => {
  if (!date) return false;

  const parsedDate = new Date(date);
  const formattedDate = formatDate(parsedDate);
  const hours = padTo2Digits(parsedDate.getHours());
  const minutes = padTo2Digits(parsedDate.getMinutes());

  return `${formattedDate} ${hours}:${minutes}`;
};

export const formatDateTimeWithSeconds = (
  date: Date | string
): string | boolean => {
  if (!date) return false;
  const parsedDate = new Date(date);
  const formattedDateTime = formatDateTime(parsedDate);
  const seconds = padTo2Digits(parsedDate.getSeconds());

  return `${formattedDateTime}:${seconds}`;
};

export const formatDateTimeWithMiliseconds = (
  date: Date | string
): string | boolean => {
  if (!date) return false;
  const parsedDate = new Date(date);
  const formattedDateTimeWithSeconds = formatDateTimeWithSeconds(parsedDate);
  const milliseconds = String(parsedDate.getMilliseconds()).padStart(3, '0');

  return `${formattedDateTimeWithSeconds}.${milliseconds}`;
};
