import { OOC } from '@/models/objectOccurrence';
import { Owner } from '@/models/owner';
import { OwnerGroup } from '@/models/ownerGroup';
import { ResourceType } from '@/services/resources';
import { SimplifiedResource } from './jvTools';

export type OwningEntityType = Extract<ResourceType, 'owner' | 'owner_group'>;

export function singleLevelReferenceDesignation(ooc: Partial<OOC>) {
  const prefix = ooc?.prefix ?? '';
  const code = ooc?.classification_code ?? '';
  const number = ooc?.number ?? '';
  return `${prefix}${code}${number}`;
}

export function convertFrdToMlrd(value: string): string {
  return value.substring(value.indexOf('>') + 1);
}

export const getOwner = (ooc: OOC) => {
  return (getOwnershipOfType(ooc, 'owner')?.owning_entity as Owner) || null;
};

export const getOwnerGroup = (ooc: OOC) => {
  return (
    (getOwnershipOfType(ooc, 'owner_group')?.owning_entity as OwnerGroup) ||
    null
  );
};

export const getOwnershipOfType = (ooc: OOC, type: OwningEntityType) => {
  return (
    ooc?.ownerships?.find(
      (ownership) =>
        (ownership.owning_entity as SimplifiedResource<Owner | OwnerGroup>)
          ?.__type === type
    ) || null
  );
};
