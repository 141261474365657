import { JsonApiIdentification } from '@/models/api';
import { JVRestructuredRecord, JVTopLevelSingleResource } from '@/models/jv';
import { OOCComparisonResult } from '@/models/revision';
import {
  TreeDataNode,
  StructuredTreeNode,
  TreeIndexNode,
  TreeOperation,
  TreeOperationType,
} from '@/models/treeStructure';
import { omit } from './tools';

/**
 * For revisions comparison:
 * - `contextId` should point to the next context
 * - `comparisonBaseRevisionId` should point to the previous revision
 */
export type TreeIdentification = {
  contextId: string;
  comparisonBaseRevisionId?: string;
};

export const treeId = (tree: TreeIdentification): string =>
  [tree.contextId, tree.comparisonBaseRevisionId].filter(Boolean).join('_');

export const isTempNodeId = (nodeId: string) =>
  !nodeId || nodeId.startsWith('temp');

export interface TreeIndexEntry {
  id: string;
  parentId?: string;
  comparisonResult?: OOCComparisonResult;
}

export type TreeIndex = TreeIndexEntry[];

export function parentChain(
  treeIndex: TreeIndex,
  nodeId: string,
  chain: string[] = []
): string[] {
  const nodeIdx = treeIndex.find((it) => it.id === nodeId);
  return nodeIdx && nodeIdx.parentId
    ? parentChain(treeIndex, nodeIdx.parentId, chain.concat(nodeIdx.parentId))
    : chain;
}

export function nodeInParentChain(
  treeIndex: TreeIndex,
  nodeId: string,
  parentId: string
) {
  const chain = parentChain(treeIndex, nodeId);
  return chain.includes(parentId);
}

export function getSubtreeIds(treeIndex: TreeIndex, id: string): string[] {
  const childrenIds: string[] = treeIndex
    .filter((el) => el.parentId && el.parentId === id)
    .map((el) => el.id);
  return [id].concat(
    childrenIds.flatMap(getSubtreeIds.bind(undefined, treeIndex))
  );
}

const withComparisonResult = <Node extends TreeIndexEntry>(
  treeIndexNode: Node,
  record: JVRestructuredRecord
): Node => {
  if (!record?._jv?.meta?.comparison) return treeIndexNode;
  const previousOoc =
    (record?._jv?.relationships?.previous_ooc?.data as JsonApiIdentification) ||
    null;
  const comparisonResult: OOCComparisonResult = {
    ...record._jv.meta?.comparison,
    previous_ooc_id: previousOoc?.id,
  };
  if (comparisonResult && comparisonResult.action === 'DELETED') {
    comparisonResult.previous_ooc_id =
      comparisonResult.previous_ooc_id || record._jv.id;
  } else {
    comparisonResult.next_ooc_id = record._jv.id;
  }
  return {
    ...treeIndexNode,
    comparisonResult,
  };
};

const withDeletedComparisonResult = <Node extends TreeIndexEntry>(
  treeIndexNode: Node
): Node => {
  return {
    ...treeIndexNode,
    comparisonResult: {
      action: 'DELETED',
      previous_ooc_id: treeIndexNode.id,
    },
  };
};

export function traverseSubtreeRelations(
  parentNode: JVTopLevelSingleResource,
  depth: number
): Array<TreeIndexEntry & { depth: number }> {
  const parentId = parentNode._jv.id;

  const indexNodeBase = { depth, parentId };

  const childrenIds = (parentNode && Object.keys(parentNode.components)) || [];
  const children = childrenIds.map<TreeIndexEntry & { depth: number }>((id) => {
    const childNode = parentNode.components[id];
    return withComparisonResult({ id, ...indexNodeBase }, childNode);
  });

  const deletedChildrenIds =
    (parentNode && Object.keys(parentNode.deleted_components || {})) || [];
  const deletedChildren = deletedChildrenIds.map<
    TreeIndexEntry & { depth: number }
  >((id) => {
    return withDeletedComparisonResult({ id, ...indexNodeBase });
  });

  const allChildren = children.concat(deletedChildren);

  return allChildren.concat(
    depth > 0
      ? allChildren.flatMap((ch) =>
          traverseSubtreeRelations(
            parentNode.components[ch.id] ||
              parentNode.deleted_components[ch.id],
            depth - 1
          )
        )
      : []
  );
}

export function getStructuredSubtree(
  rootNode: TreeDataNode,
  treeData: TreeDataNode[],
  nodesToLoad: Set<string>,
  expandedNodes: Set<string> = new Set()
): StructuredTreeNode {
  const children = treeData
    .filter((child) => child.parentId === rootNode.id)
    .sort((a, b) => a.position - b.position);

  const expandable = children.length > 0 || nodesToLoad.has(rootNode.id);
  const isRoot = !rootNode.parentId;
  const isLoaded = !nodesToLoad.has(rootNode.id);
  const isExpanded = expandedNodes.has(rootNode.id);

  return {
    ...rootNode,
    expandable,
    expanded: isRoot || (isLoaded && isExpanded),
    children: children.map((child) =>
      getStructuredSubtree(child, treeData, nodesToLoad, expandedNodes)
    ),
  };
}

export function computeTreeData<
  Resource extends { position: number } = { position: number },
>(context: {
  treeIndex: TreeIndexNode[];
  getResourceAttributes: (id: string) => Resource;
}): Array<TreeDataNode<Resource>> {
  const { getResourceAttributes, treeIndex } = context;
  return treeIndex.map((treeIndexNode) => ({
    ...getResourceAttributes(treeIndexNode.id),
    ...treeIndexNode,
  }));
}

interface TreeIndexPatch {
  id: string;
  parentId: string;
  position: number;
}

const treeDataRemoveNode = <T extends { position: number }>(
  treeData: Array<TreeDataNode<T>>,
  nodeToRemove: TreeDataNode<T>,
  withSubtree: boolean
): [Array<TreeDataNode<T>>, TreeIndexPatch[]] => {
  const patches: TreeIndexPatch[] = [];
  const decreasePosition = <T extends TreeDataNode>(node: T) => {
    const newNode = { ...node, position: node.position - 1 };
    patches.push({
      id: node.id,
      parentId: node.parentId,
      position: newNode.position,
    });
    return newNode;
  };
  const { parentId, position } = nodeToRemove;
  const idsToRemove = withSubtree
    ? getSubtreeIds(treeData, nodeToRemove.id)
    : [nodeToRemove.id];
  const newTreeData = treeData
    .filter((node) => !idsToRemove.includes(node.id))
    .map((node) =>
      node.parentId === parentId && node.position > position
        ? decreasePosition(node)
        : node
    );
  return [newTreeData, patches];
};

const treeDataAddNode = <T extends { position: number }>(
  treeData: Array<TreeDataNode<T>>,
  nodeToAdd: TreeDataNode<T>
): [Array<TreeDataNode<T>>, TreeIndexPatch[]] => {
  const patches: TreeIndexPatch[] = [];
  const increasePosition = <T extends TreeDataNode>(node: T) => {
    const newNode = { ...node, position: node.position + 1 };
    patches.push({
      id: node.id,
      parentId: node.parentId,
      position: newNode.position,
    });
    return newNode;
  };
  const { parentId, position } = nodeToAdd;
  const newTreeData = treeData
    .map((node) =>
      node.parentId === parentId && node.position >= position
        ? increasePosition(node)
        : node
    )
    .concat([nodeToAdd]);
  return [newTreeData, patches];
};

const treeDataMoveNode = <T extends { position: number }>(
  treeData: Array<TreeDataNode<T>>,
  nodeToMove: TreeDataNode<T>,
  parentId: string,
  position: number
): [Array<TreeDataNode<T>>, TreeIndexPatch[]] => {
  const patchedNode = { ...nodeToMove, parentId, position };
  const [treeDataAfterRemove, removePatches] = treeDataRemoveNode(
    treeData,
    nodeToMove,
    false
  );
  const [newTreeData, addPatches] = treeDataAddNode(
    treeDataAfterRemove,
    patchedNode
  );
  return [newTreeData, removePatches.concat(addPatches)];
};

export function applyTreeDataOperation<
  Resource extends { position: number } = { position: number },
>(
  treeData: Array<TreeDataNode<Resource>>,
  operation: TreeOperation<Resource>
): [Array<TreeDataNode<Resource>>, TreeIndexPatch[]] {
  switch (operation.operationType) {
    case TreeOperationType.Patch: {
      const originalNode = treeData.find((node) => node.id === operation.id);
      if (!originalNode) {
        break; // return original tree data
      }
      const attributes = omit<Partial<TreeDataNode>>(operation.attributes, [
        'id',
        'parentId',
        'position',
      ]);
      const patchedNode = { ...originalNode, ...attributes };
      const newTreeData = treeData
        .filter((node) => node.id !== operation.id)
        .concat([patchedNode]);
      return [newTreeData, []];
    }

    case TreeOperationType.Add: {
      const nodeAlreadyExists = treeData.some(
        (node) => node.id === operation.id
      );
      if (nodeAlreadyExists) {
        if (!import.meta.env.PROD) {
          console.warn(
            'Trying to add a node that already exists in tree index'
          );
        }
        break; // return original tree data
      }
      const node = {
        ...operation.attributes,
        id: operation.id,
        parentId: operation.parentId,
        position: operation.position,
      };
      return treeDataAddNode(treeData, node as TreeDataNode<Resource>);
    }

    case TreeOperationType.Remove: {
      const node = treeData.find((node) => node.id === operation.id);
      if (!node) {
        break; // return original tree data
      }
      return treeDataRemoveNode(treeData, node, true);
    }

    case TreeOperationType.Move: {
      const originalNode = treeData.find((node) => node.id === operation.id);
      if (!originalNode) {
        break; // return original tree data
      }
      return treeDataMoveNode(
        treeData,
        originalNode,
        operation.parentId,
        operation.position
      );
    }
  }
  return [treeData.slice(), []];
}
