<template>
  <v-snackbar
    :modelValue="showNotification"
    :timeout="-1"
    :color="color"
    @update:modelValue="(val) => !val && closeActiveNotification()"
  >
    {{ message }}
    <template #actions>
      <v-btn variant="text" @click.prevent="action.callback">
        {{ action.label }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Options, Vue, Watch } from 'vue-property-decorator';
import { AppNotification } from '@/store/modules/notifications';

const colors: { [key in AppNotification['type']]: string } = {
  info: 'primary',
  success: 'success',
  error: 'error',
};

@Options({
  name: 'AppNotifications',
})
export default class AppNotifications extends Vue {
  timeoutId: number | null = null;
  showNotification = false;
  activeNotification: AppNotification | null = null;

  get timeout(): number {
    return (
      (this.activeNotification && this.activeNotification.timeout) || 10000
    );
  }

  get color(): string {
    return (
      (this.activeNotification &&
        colors[this.activeNotification.type || 'info']) ||
      colors.info
    );
  }

  get message(): string {
    return (this.activeNotification && this.activeNotification.message) || '';
  }

  get action(): AppNotification['action'] {
    return (
      (this.activeNotification && this.activeNotification.action) ||
      this.defaultAction
    );
  }

  get defaultAction(): AppNotification['action'] {
    return {
      label: this.$t('notification.close') as string,
      callback: this.closeActiveNotification,
    };
  }

  get storeNotification(): AppNotification | null {
    return this.$store.$direct.notifications.getActiveNotification();
  }

  @Watch('storeNotification', { immediate: true })
  onActiveNotificationChange(newVal, oldVal): void {
    if (oldVal === newVal) return;
    if (this.timeoutId) clearTimeout(this.timeoutId);
    this.showNotification = !!newVal;
    if (newVal) {
      // copy the storeNotification to not lose the reference when it's disabled
      // this way closing animation can still happen without blinking styles
      this.activeNotification = newVal;
      this.timeoutId = window.setTimeout(
        this.closeActiveNotification,
        this.timeout
      );
    }
  }

  closeActiveNotification(): void {
    this.$store.$direct.notifications.dispatchCloseActiveNotification();
  }
}
</script>
