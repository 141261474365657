import { OOC } from '@/models/objectOccurrence';
import { ObjectOccurrenceRelation } from '@/models/objectOccurrenceRelation';
import { range } from '../math';
import { OOCComparisonResult } from '@/models/revision';
import { JVRestructuredRecord } from '@/models/jv';
import { ComparisonMeta } from '@/store/components/simoIndexManager';
import { JsonApiIdentification } from '@/models/api';

export interface GridViewport {
  x: number;
  y: number;
  width: number;
  height: number;
}
export interface GridCoordinates {
  x: number;
  y: number;
}

export const isSpecialRelation = (oor: ObjectOccurrenceRelation) => {
  return oor.no_relations || oor.unknown_relations;
};

export function cellInHighlightedRow(
  highlightFor: GridCoordinates,
  coords: GridCoordinates
): boolean {
  if (!highlightFor) return false;
  const { x, y } = coords;
  if (highlightFor.x > highlightFor.y) {
    return y === highlightFor.y && x < highlightFor.x && x > highlightFor.y;
  } else if (highlightFor.x < highlightFor.y) {
    return y === highlightFor.y && x > highlightFor.x && x < highlightFor.y;
  } else return false;
}

const rotate = ({ x, y }: GridCoordinates): GridCoordinates => ({ x: y, y: x });

export function cellInHighlightedColumn(
  highlightFor: GridCoordinates,
  coords: GridCoordinates
): boolean {
  if (!highlightFor) return false;
  return cellInHighlightedRow(rotate(highlightFor), rotate(coords));
}

export const compareCoords = (a: GridCoordinates, b: GridCoordinates) => {
  return a && b && a.x === b.x && a.y === b.y;
};

enum SimoSelectionType {
  Cells,
  Range,
  OpenRange,
}

export enum SimoRangeOrientation {
  Row,
  Column,
}

export enum SimoRangeDirection {
  Forward,
  Backward,
}

type SimoSelection =
  | {
      type: SimoSelectionType.Cells;
      base: GridCoordinates;
      all: GridCoordinates[];
    }
  | {
      type: SimoSelectionType.Range;
      base: GridCoordinates;
      end: GridCoordinates;
    };

export class SimoCellsCollection {
  private selection: SimoSelection | null = null;

  public get isRangeSelection(): boolean {
    return (
      !!this.selection &&
      [SimoSelectionType.Range, SimoSelectionType.OpenRange].includes(
        this.selection.type
      )
    );
  }

  public get rangeSelectionOrientation(): SimoRangeOrientation | undefined {
    if (this.selection?.type === SimoSelectionType.Range) {
      return this.getOrientation(this.selection);
    }
  }

  public get isOOCSelected(): boolean {
    return (
      !this.selection ||
      (this.selection.type === SimoSelectionType.Cells &&
        this.selection.all.length === 1 &&
        this.selection.base.x === this.selection.base.y)
    );
  }

  public selectedOOC(oocs: OOC[]): OOC | null {
    if (!this.selection) return oocs[0] || null;
    if (!this.isOOCSelected) return null;
    return oocs[this.selection.base.x] || null;
  }

  private getOrientation(
    selection: { type: SimoSelectionType.Range } & SimoSelection
  ): SimoRangeOrientation {
    return selection.base.y === selection.end.y
      ? SimoRangeOrientation.Row
      : SimoRangeOrientation.Column;
  }

  public isCellSelected(coords: GridCoordinates): boolean {
    if (!this.selection) {
      return coords.x === 0 && coords.y === 0;
    }
    if (this.selection.type === SimoSelectionType.Cells) {
      return this.selection.all.some((cell) => compareCoords(cell, coords));
    }
    if (this.selection.type === SimoSelectionType.Range) {
      const isOOC = coords.x === coords.y;
      const orientation = this.getOrientation(this.selection);
      const inCommonLine =
        orientation === SimoRangeOrientation.Row
          ? this.selection.base.y === coords.y
          : this.selection.base.x === coords.x;
      return (
        !isOOC &&
        inCommonLine &&
        this.inRange(this.selection, coords, orientation)
      );
    }
    return false;
  }

  public getBaseSelectedCell(): GridCoordinates {
    if (!this.selection) {
      return { x: 0, y: 0 };
    }
    return this.selection.base;
  }

  public getSelectedCellsCoords(): readonly GridCoordinates[] {
    if (!this.selection) {
      return [{ x: 0, y: 0 }];
    }
    return this.selectionToCoords(this.selection);
  }

  private inRange(
    range: { type: SimoSelectionType.Range } & SimoSelection,
    coords: GridCoordinates,
    orientation: SimoRangeOrientation
  ) {
    const axis = orientation === SimoRangeOrientation.Row ? 'x' : 'y';
    const { base, end } = range;
    return (
      base &&
      end &&
      coords[axis] >= Math.min(base[axis], end[axis]) &&
      coords[axis] <= Math.max(base[axis], end[axis])
    );
  }

  private getCoordsRange(
    from: GridCoordinates,
    to: GridCoordinates,
    orientation: SimoRangeOrientation
  ): GridCoordinates[] {
    const [commonAxis, axis] =
      orientation === SimoRangeOrientation.Row
        ? (['y', 'x'] as const)
        : (['x', 'y'] as const);
    const diff = range(
      Math.min(from[axis], to[axis]),
      Math.max(from[axis], to[axis]) + 1
    );
    return diff.map((value) => ({
      [commonAxis as 'x']: from[commonAxis],
      [axis as 'y']: value,
    }));
  }

  private selectionToCoords(selection: SimoSelection): GridCoordinates[] {
    if (selection.type === SimoSelectionType.Cells) {
      return selection.all;
    }
    if (selection.type === SimoSelectionType.Range) {
      return this.getCoordsRange(
        selection.base,
        selection.end,
        this.getOrientation(selection)
      ).filter((c) => c.x !== c.y); // filter out OOCs
    }
  }

  public clear() {
    this.selection = null;
  }

  private isBaseCell({ x, y }: GridCoordinates) {
    if (!this.selection) return false;
    const { base } = this.selection;
    return base.x === x && base.y === y;
  }

  private canDeselectCell() {
    if (!this.selection) return false;
    if (
      this.selection.type === SimoSelectionType.Cells &&
      this.selection.all.length < 2
    )
      return false;

    return true;
  }

  private deselectCell({ x, y }: GridCoordinates) {
    if (!this.canDeselectCell()) return false;
    const newSelection = this.getSelectedCellsCoords().filter(
      (coords) => !compareCoords(coords, { x, y })
    );

    this.selection = {
      type: SimoSelectionType.Cells,
      base: this.isBaseCell({ x, y })
        ? newSelection[newSelection.length - 1]
        : this.selection.base,
      all: newSelection,
    };
  }

  public addCell(x: number, y: number, addToSelection?: boolean): boolean {
    // ignore selecting OOC in multi select mode
    if (x === y && addToSelection) return false;
    if (addToSelection && this.isCellSelected({ x, y })) {
      this.deselectCell({ x, y });
      return false;
    }

    const coords = { x, y };
    const all =
      addToSelection &&
      !this.isOOCSelected &&
      this.selection &&
      this.selection.type === SimoSelectionType.Cells
        ? [...this.selection.all, coords]
        : [coords];
    this.selection = {
      type: SimoSelectionType.Cells,
      base: coords,
      all,
    };
    return true;
  }

  private boxSelection({ x, y }: GridCoordinates) {
    if (!this.selection) return;
    const selectedCells = [];

    const minX = Math.min(this.selection.base.x, x);
    const maxX = Math.max(this.selection.base.x, x);
    const minY = Math.min(this.selection.base.y, y);
    const maxY = Math.max(this.selection.base.y, y);

    for (let y = minY; y <= maxY; y++) {
      for (let x = minX; x <= maxX; x++) {
        if (x === y) continue;
        selectedCells.push({ x, y });
      }
    }
    return selectedCells;
  }

  public addRange(x: number, y: number): boolean {
    const baseCell = this.getBaseSelectedCell();
    if (!baseCell) return false;
    const commonX = x === baseCell.x;
    const commonY = y === baseCell.y;
    if (!commonX && !commonY) {
      const selection = this.boxSelection({ x, y });
      this.selection = {
        type: SimoSelectionType.Cells,
        base: this.selection.base,
        all: selection,
      };
      return false;
    }
    if (this.selection && this.selection.type === SimoSelectionType.Range) {
      if (commonX || commonY) {
        // Skip
      } else {
        this.selection = {
          type: SimoSelectionType.Range,
          base: baseCell,
          end: { x, y },
        };
        return false;
      }
    }
    if (x === baseCell.x && y === baseCell.y) {
      this.selection = {
        type: SimoSelectionType.Cells,
        base: baseCell,
        all: [baseCell],
      };
      return true;
    }
    this.selection = {
      type: SimoSelectionType.Range,
      base: baseCell,
      end: { x, y },
    };
    return true;
  }
}

export const hasModifiedFields = (
  comparisonResult: OOCComparisonResult
): boolean => {
  const simoOocFields = ['NAME', 'OWNER', 'OWNERGROUP'];
  return (
    ['CREATED', 'DELETED'].includes(comparisonResult?.action) ||
    !!comparisonResult?.modified_fields?.some((field) =>
      simoOocFields.includes(field)
    )
  );
};

export const cellComparisonResult = (
  source: OOCComparisonResult,
  target: OOCComparisonResult
): OOCComparisonResult => {
  if (!source?.action && !target?.action) return null;
  const priorities: { [index: string]: number } = {
    null: 0,
    MODIFIED: 1,
    CREATED: 2,
    DELETED: 3,
  };
  const sourcePriority =
    (!!hasModifiedFields(source) && priorities[source.action]) || 0;
  const targetPriority =
    (!!hasModifiedFields(target) && priorities[target.action]) || 0;
  if (!sourcePriority && !targetPriority) return null;
  return sourcePriority > targetPriority ? source : target;
};

export const createOorComparisonMeta = (
  oor: JVRestructuredRecord
): ComparisonMeta => {
  const comparison = oor?._jv?.meta?.comparison || {};
  return {
    comparisonResult: {
      ...comparison,
      previous_oor_id: (
        oor?._jv?.relationships?.previous_oor?.data as JsonApiIdentification
      )?.id,
      next_oor_id: comparison.action !== 'DELETED' ? oor._jv.id : null,
    },
  };
};
