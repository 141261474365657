import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import indexManager from '../components/indexManager';
import crud from '../components/crud';

export default createModule({
  path: 'userNotifications',
  resourceProfile: resources.notifications,
  components: [indexManager, crud],
  setup({ components }) {
    return {
      module: {},
      public: {
        ...components.$indexManager.public,
        ...components.$crud.public,
      },
    };
  },
});
