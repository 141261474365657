import { createModule } from '../utils/factories';
import {
  ResourceProfile,
  resourceRelationship,
  resources,
} from '@/services/resources';
import indexManager from '../components/indexManager';
import jv from '../components/jv';
import crud, { CrudAccessors } from '../components/crud';
import { OODR } from '@/models/objectOccurrenceDomainRelation';
import ooc from './ooc';
import { OOC } from '@/models/objectOccurrence';
import context from './context';
import { Context } from '@/models/context';
import { directedOodr } from '@/utils/doma/oodrs';
import { WebSocketEvent } from '@/models/ws';
import { OperationConfig } from '@/services/delayedResponse';
import delayedResponseContext from '@/store/components/delayedResponseContext';
import { JsonApiIdentification } from '@/models/api';

const REQUEST_TYPE_CONTEXT_RELATIONS = 'context-relations';
const getContextIndexId = (sourceContextId: string, targetContextId: string) =>
  [sourceContextId, targetContextId].sort().join(';');

type SimplifiedOperationIdentifier =
  | { operation: 'create' }
  | {
      operation: Exclude<WebSocketEvent['operation'], 'create'>;
      targetId: string;
    };

interface State {
  contextRelations: {
    [contextIndexId: string]: {
      [sourceId: string]: string[]; // oodrIds
    };
  };
}

export default createModule({
  path: 'doma',
  resourceProfile: resources.objectOccurrenceDomainRelation,
  components: [jv, crud, indexManager, delayedResponseContext],
  modules: [ooc, context],
  setup({ getAccessors, components, modules, resourceProfile }) {
    const state = (): State => ({
      contextRelations: {},
    });

    const getDelayedResponseService =
      components.$delayedResponseContext.protected.getDelayedResponseService;

    const getters = {
      getBySource:
        (state: State) =>
        (
          oocSourceId: string,
          sourceContextId: string,
          targetContextId: string
        ) => {
          const indexId = getContextIndexId(sourceContextId, targetContextId);
          const oodrIds = state.contextRelations[indexId]?.[oocSourceId] || [];
          const oodrSet = components.$crud.public.getSimplifiedResourceSet();
          const fromAspect =
            modules.context.public.getSimplifiedResourceSet()[sourceContextId]
              ?.syntax?.aspect;
          const toAspect =
            modules.context.public.getSimplifiedResourceSet()[targetContextId]
              ?.syntax?.aspect;
          return oodrIds
            .map((id) => {
              const oodr = oodrSet[id] as OODR;
              return (
                oodr &&
                directedOodr(oocSourceId, fromAspect, toAspect, oodrSet[id])
              );
            })
            .filter(Boolean);
        },
      getContexts: () => (oodrId: string) => {
        const oodr = components.$crud.public.getSimplifiedResourceSet()[
          oodrId
        ] as OODR;
        if (!oodr?.source || !oodr?.target) {
          return { source: undefined, target: undefined };
        }
        const sourceContextId = modules.ooc.public.getRelationshipIdFromLinkage(
          oodr.source?.id,
          resourceRelationship(resources.objectOccurrences)('context')
        ) as string;
        const targetContextId = modules.ooc.public.getRelationshipIdFromLinkage(
          oodr.target?.id,
          resourceRelationship(resources.objectOccurrences)('context')
        ) as string;
        return {
          source: modules.context.public.getSimplifiedResourceSet()[
            sourceContextId
          ] as Context,
          target: modules.context.public.getSimplifiedResourceSet()[
            targetContextId
          ] as Context,
        };
      },
      getTargetOoc: () => (oodrId: string) => {
        const sourceId = components.$crud.public.getRelationshipId(
          oodrId,
          resourceRelationship(resourceProfile)('target')
        ) as string;
        return (
          sourceId &&
          (modules.ooc.public.getSimplifiedResourceSet()[sourceId] as OOC)
        );
      },
    };

    const mutations = {
      createContextIndex(state: State, payload: { contextIndexId: string }) {
        const { contextIndexId } = payload;
        state.contextRelations[contextIndexId] = {};
      },
      addRelationToContextIndex(
        state: State,
        payload: {
          oodrId: string;
          contextIndexId: string;
        }
      ) {
        const { oodrId, contextIndexId } = payload;
        if (!state.contextRelations[contextIndexId]) return;
        const oodrJvResource = components.$jv.protected.get({
          id: oodrId,
          type: resourceProfile.type,
        });
        const sourceId = oodrJvResource._jv.relationships.source.data.id;
        const targetId = oodrJvResource._jv.relationships.target.data.id;
        if (!state.contextRelations[contextIndexId][sourceId]) {
          state.contextRelations[contextIndexId][sourceId] = [];
        }
        if (!state.contextRelations[contextIndexId][targetId]) {
          state.contextRelations[contextIndexId][targetId] = [];
        }
        state.contextRelations[contextIndexId][sourceId].push(oodrId);
        state.contextRelations[contextIndexId][targetId].push(oodrId);
      },
    };

    const wrapRequest = <Res>(
      contextId: string,
      promise: Promise<Res>,
      operationDef: SimplifiedOperationIdentifier,
      transformWsResponse?: OperationConfig<Res>['transformWsResponse']
    ): Promise<Res> => {
      return getDelayedResponseService(contextId).waitForOperation(promise, {
        ...operationDef,
        targetType: resourceProfile.type,
        preventsDefault: true,
        transformWsResponse,
      });
    };

    const actions = {
      async loadContextOodrs(
        context,
        payload: { sourceContextId: string; targetContextId: string }
      ) {
        const { sourceContextId, targetContextId } = payload;
        const requestType = REQUEST_TYPE_CONTEXT_RELATIONS;
        const requestId = getContextIndexId(sourceContextId, targetContextId);
        await components.$indexManager.public.dispatchLoadFullResource({
          requestType,
          requestId,
          filterContextIds: [payload.sourceContextId, payload.targetContextId],
        });
        const oodrs = components.$indexManager.public.getFullResourceIds(
          requestType,
          requestId
        ) as string[];
        const contextIndexId = getContextIndexId(
          payload.sourceContextId,
          payload.targetContextId
        );
        commit(mutations.createContextIndex)({ contextIndexId });
        oodrs.forEach((oodrId) => {
          commit(mutations.addRelationToContextIndex)({
            oodrId,
            contextIndexId,
          });
        });
      },
      async createResource(
        context,
        payload: Omit<
          Parameters<CrudAccessors['dispatchCreateResource']>[0],
          'relationships'
        > & {
          relationships: {
            source: string;
            target: string;
            doma_classification_entry: string;
          };
          sourceContextId: string;
          targetContextId: string;
        }
      ) {
        const { id: oodrId } = await wrapRequest(
          payload.sourceContextId,
          components.$crud.public.dispatchCreateResource(payload),
          { operation: 'create' },
          (wsRes) => ({
            id: (
              wsRes.data.data.relationships.target.data as JsonApiIdentification
            ).id,
          })
        );

        const contextIndexId = getContextIndexId(
          payload.sourceContextId,
          payload.targetContextId
        );

        commit(mutations.addRelationToContextIndex)({
          contextIndexId,
          oodrId,
        });

        return { id: oodrId };
      },
      async deleteResource(
        context,
        payload: {
          contextId: string;
          oodrId: string;
        }
      ) {
        await wrapRequest(
          payload.contextId,
          components.$crud.public.dispatchDeleteResource({
            resourceId: payload.oodrId,
          }),
          { operation: 'delete', targetId: payload.oodrId }
        );
      },
      async updateRelation(
        context,
        payload: {
          contextId: string;
          resourceId: string;
          resource: Record<string, unknown>;
          relationships: ResourceProfile['relationships'];
        }
      ) {
        await wrapRequest(
          payload.contextId,
          components.$crud.public.dispatchUpdateResource({
            ...payload,
          }),
          { operation: 'update', targetId: payload.resourceId }
        );
      },
      externalAddOodr(
        context,
        payload: {
          oodrId: string;
        }
      ) {
        const { target, source } = read(getters.getContexts)(payload.oodrId);
        const contextIndexId = getContextIndexId(source.id, target.id);
        commit(mutations.addRelationToContextIndex)({
          contextIndexId,
          oodrId: payload.oodrId,
        });
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        mutations,
        getters,
        actions,
      },
      protected: {
        dispatchExternalAddOodr: dispatch(actions.externalAddOodr),
      },
      public: {
        ...components.$crud.public,
        getOodrsBySource: read(getters.getBySource),
        getContexts: read(getters.getContexts),
        dispatchLoadContextOodrs: dispatch(actions.loadContextOodrs),
        dispatchCreateResource: dispatch(actions.createResource),
        dispatchDeleteResource: dispatch(actions.deleteResource),
        dispatchUpdateResource: dispatch(actions.updateRelation),
      },
    };
  },
});
