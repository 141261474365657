import { createEventBus } from '@/services/eventBus/utils';
import { ResourceType } from '@/services/resources';

export type EventTypeDict = {
  keydown: KeyboardEvent;
  'shortcut-used': { event: KeyboardEvent; keys: string };
  'show-shortcuts': undefined;
  'websocket-resource-update': { type: ResourceType; id: string };
  'deselect-doma-aspect': string;
  'ooc-batch-delete': undefined;
  'doma-drag-drop-order-changed': {
    draggedContextID: string;
    droppedOnContextID: string;
  };
  syntax_update: {
    action:
      | 'syntax_update_started'
      | 'syntax_update_cancelled'
      | 'syntax_update_finished';
  };
};

export const eventBus = createEventBus<EventTypeDict>();

eventBus.$emit('show-shortcuts');
