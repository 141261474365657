<template>
  <v-menu
    v-model="userMenu"
    :close-on-content-click="false"
    location="bottom"
    width="200"
    min-width="200"
  >
    <template #activator="{ props }">
      <v-btn
        v-bind="props"
        data-spec="user-avatar"
        variant="text"
        :rounded="false"
        width="88px"
        height="64px"
      >
        <v-icon size="x-large">mdi-account-outline</v-icon>
      </v-btn>
    </template>
    <v-sheet :rounded="false" class="pt-4 pl-4 pr-4">
      <div class="user-panel__menu">
        {{ userName }}
        <v-btn
          data-spec="logout-btn"
          color="primary"
          @click.stop="logout"
          class="ma-4"
          max-width="100px"
        >
          {{ $t('topbar.user.logOut') }}
        </v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-property-decorator';
import { logout, getUserId } from '@/auth';
import { User } from '@/models/user';

@Options({})
export default class UserPanel extends Vue {
  userMenu = false;

  logout() {
    logout();
  }

  get userName() {
    const userInfo = this.$store.$direct.users.getSimplifiedResourceSet()[
      getUserId()
    ] as User;
    return userInfo?.name ?? '';
  }

  created() {
    this.$store.$direct.users.dispatchLoadSingleResource({
      resourceId: getUserId(),
    });
  }
}
</script>
<style lang="scss">
.user-panel__menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
</style>
