export enum ComponentType {
  Jv = '$jv',
  Crud = '$crud',
  IndexManager = '$indexManager',
  Publisher = '$publisher',
  Archiver = '$archiver',
  RelationManager = '$relationManager',
  ProcessingTracker = '$processingTracker',
  Tree = '$tree',
  TreeIndexManager = '$treeIndexManager',
  TreeOperations = '$treeOperations',
  TreeSelectionManager = '$treeSelectionManager',
  SimoIndexManager = '$simoIndexManager',
  SimoCrud = '$simoCrud',
  DelayedResponseContext = '$delayedResponseContext',
  WsConnection = '$wsConnection',
}
