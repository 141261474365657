export const canSeeRevisionRequestType = (projectId: string) =>
  `${projectId}-can-see-revision`;

export const simoListKey = 'sec-simo-list';

export const panelLayoutDataAttributes = {
  mainContent: 'data-panel-layout-main-content',
  item: 'data-panel-layout-item',
  itemPreventActions: 'data-panel-layout-item-prevent-actions',
};
