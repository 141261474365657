import Launchpad from '@/views/launchpad/launchpad.vue';
import { ApplicationType } from '@/enums/applicationType';
import { RouteName } from '@/enums/routing';
import { createAppRoute, SecRouteConfig } from '@/router/utils';

const launchpadRoutes: SecRouteConfig[] = [
  createAppRoute(ApplicationType.Launchpad, {
    path: '/launchpad',
    name: RouteName.Launchpad,
    component: Launchpad,
    meta: { requiresAuth: true },
  }),
];

export default launchpadRoutes;
