<template>
  <ApplicationPage :hideSubApplicationsTabs="!contextId || !projectId">
    <HeaderBreadcrumbs>
      <HeaderBreadcrumbsItem>
        <button
          class="app-title__link"
          @click="goToProjectsPage"
          :disabled="!projectId"
        >
          All Projects
        </button>
      </HeaderBreadcrumbsItem>
      <HeaderBreadcrumbsItem
        v-if="!!currentProject"
        :class="{ [projectsSwitcherActiveClass]: showProjectsSwitcherDropdown }"
      >
        <button @click="onProjectNameClick">
          {{ currentProject.name }}
          <v-icon size="small" v-if="canSwitchProjects">
            {{
              showProjectsSwitcherDropdown
                ? 'mdi-chevron-up'
                : 'mdi-chevron-down'
            }}
          </v-icon>
        </button>
      </HeaderBreadcrumbsItem>
      <HeaderBreadcrumbsItem v-if="!!currentContext">
        {{ currentContext.name }}
        <ContextTSLabel v-if="onTradeStudy" :name="tradeStudyName" />
      </HeaderBreadcrumbsItem>
    </HeaderBreadcrumbs>
    <ProjectsSwitcherDrawer
      v-if="!!currentProject"
      v-model="showProjectsSwitcherDropdown"
      :ignoreElement="`.${projectsSwitcherActiveClass}`"
    />
    <slot>
      <div class="sub-application-content">
        <router-view v-slot="{ Component }" :key="routerKey">
          <PageTransition
            name="slide-x-transition"
            class="router-transition-wrapper"
          >
            <component :is="Component" />
          </PageTransition>
        </router-view>
      </div>
    </slot>
  </ApplicationPage>
</template>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount, computed, onBeforeMount, ref } from 'vue';
import { eventBus } from '@/services/eventBus/eventBus';
import ApplicationPage from './applicationPage.vue';
import { ApplicationType } from '@/enums/applicationType';
import { Project } from '@/models/project';
import HeaderBreadcrumbs from '@/components/headerBreadcrumbs.vue';
import HeaderBreadcrumbsItem from '@/components/headerBreadcrumbsItem.vue';
import ProjectsSwitcherDrawer from '@/components/design/projectsSwitcherDrawer.vue';
import ContextTSLabel from '@/components/tradeStudy/contextTSLabel.vue';
import PageTransition from '@/components/pageTransition.vue';
import { RouteName } from '@/enums/routing';
import { useApplication } from '@/composables/application';
import { useDirectStore } from '@/composables/store';
import { Context } from '@/models/context';
import { useRoute, useRouter, RouteLocationNormalizedLoaded } from 'vue-router';
import { keypressHandler } from '@/services/keypressHandler';

const store = useDirectStore();
const route = useRoute();
const router = useRouter();
const { setApplicationType } = useApplication();

const showProjectsSwitcherDropdown = ref(false);

const currentProject = computed<Project>(() => {
  return (
    projectId.value &&
    (store.project.getSimplifiedResourceSet()[projectId.value] as Project)
  );
});

const canSwitchProjects = computed(
  () => route.name === RouteName.ProjectDetails
);

const projectsSwitcherActiveClass = computed(() => 'project-title--active');

const contextId = computed(() => {
  return route.params.contextId as string;
});

const projectId = computed(() => {
  return route.params.projectId as string;
});

const goToProjectsPage = () => {
  router.push({ name: RouteName.Projects });
};

const onProjectNameClick = () => {
  if (canSwitchProjects.value) {
    showProjectsSwitcherDropdown.value = !showProjectsSwitcherDropdown.value;
  } else {
    router.push({
      name: RouteName.ProjectDetails,
      params: {
        projectId: projectId.value,
      },
    });
  }
};

const currentContext = computed<Context>(() => {
  return (
    contextId.value &&
    (store.context.getSimplifiedResourceSet()[contextId.value] as Context)
  );
});

const onTradeStudy = computed<boolean>(() => {
  return !!currentContext.value?.trade_study;
});

const tradeStudyName = computed(() => {
  return onTradeStudy.value && currentContext.value.trade_study.name;
});

const onShortcutUsed = (e: { event: KeyboardEvent; keys: string }) => {
  keypressHandler(e, true, false, () => {
    if (e.keys === 'KeyD-KeyK') {
      if (!projectId.value) return;
      if (route.name === RouteName.Dashboard) return;
      router.push({
        name: RouteName.Dashboard,
        params: {
          projectId: projectId.value,
        },
      });
    }
  });
};

const routerKey = computed(() => determineRouterKey(route));

const shouldForceRefresh = (route: RouteLocationNormalizedLoaded): boolean =>
  route.meta.forceRefresh === true;

const generateDynamicKey = (): string => Date.now().toString();

const getStableKey = (route: RouteLocationNormalizedLoaded): string =>
  route.matched[1]?.path || '';

const determineRouterKey = (route: RouteLocationNormalizedLoaded): string =>
  shouldForceRefresh(route) ? generateDynamicKey() : getStableKey(route);

onMounted(() => {
  eventBus.$on('shortcut-used', onShortcutUsed);
});
onBeforeUnmount(() => eventBus.$on('shortcut-used', onShortcutUsed));
onBeforeMount(() => setApplicationType(ApplicationType.Design));
</script>

<style lang="scss" scoped>
.sub-application-content {
  width: 100%;
  height: 100%;
  background-color: rgb(var(--v-theme-background));
}

.router-transition-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.icon-caret {
  transition: transform 200ms ease-in-out;
}

.icon--rotate {
  transform: rotate(180deg);
}

.project-title--active {
  opacity: 0.7;
}
</style>
