import { createModule } from '../utils/factories';
import {
  createIncludeRelationshipPath,
  resourceRelationship,
  resources,
} from '@/services/resources';
import indexManager from '../components/indexManager';
import createTreeComponent from '../components/tree';
import processingTracker from '../components/processingTracker';
import { OOC } from '@/models/objectOccurrence';
import jv from '../components/jv';
import { TreeOperationType } from '@/models/treeStructure';
import { omit } from '@/utils/tools';
import crud from '../components/crud';
import { extractRelatedEntityId } from '@/utils/jvTools';
import ownership from './ownership';
import { parentChain, TreeIdentification } from '@/utils/tree';
import { JsonApiIdentification } from '@/models/api';
import { PageInfo } from '@/utils/pagination/pageIndex';
import { JVTopLevelSingleResource } from '@/models/jv';
import owners from './owners';
import { getOwnershipOfType, OwningEntityType } from '@/utils/objectOccurrence';
import jvBatch from './jvBatch';
import progress from './progress';
import { DelayedResponseService } from '@/services/delayedResponse';
import delayedResponseContext from '../components/delayedResponseContext';
import store from '..';

export const OOC_PATH = 'ooc';

type OwnershipMeta = { inherited: { id: string; inherited: boolean }[] };

let getDelayedResponseService: (contextId: string) => DelayedResponseService;
const setSimoDirty = () =>
  // SPIKE (TODO: find a better way)
  // quick solution to allow circular dependencies between `ooc` and `simo`
  // problem: when `simo` module is added as a module dependency it breaks type inference
  store.$_moduleInstances.simo.public.dispatchSetDirtyStructure({
    dirtyStructure: true,
  });

const tree = createTreeComponent({
  includeRelationships: [
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'syntax_element'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'ownerships',
      'owning_entity',
      'user'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'allowed_children_syntax_elements'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'progress_step_checked'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'components',
      'syntax_element'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'components',
      'ownerships',
      'owning_entity',
      'user'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'components',
      'allowed_children_syntax_elements'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'components',
      'progress_step_checked'
    ),
    createIncludeRelationshipPath(resources.objectOccurrences)(
      'components',
      'classification_entry'
    ),
  ],
  wrapCreate(promise, { tree }) {
    // Request is considered successful if it's acknowledge by the websocket event or HTTP response - whatever comes first.
    return getDelayedResponseService(tree.contextId)
      .waitForOperation(promise, {
        targetType: resources.objectOccurrences.type,
        preventsDefault: true,
        operation: 'create',
        transformWsResponse: (wsRes) => ({
          id: (
            wsRes.data.data.relationships.target.data as JsonApiIdentification
          ).id,
        }),
      })
      .then((response) => {
        setSimoDirty();
        return response;
      });
  },
  wrapUpdate(promise, { tree, targetId }) {
    // Request is considered successful if it's acknowledge by the websocket event or HTTP response - whatever comes first.
    return getDelayedResponseService(tree.contextId)
      .waitForOperation(promise, {
        targetType: resources.objectOccurrences.type,
        preventsDefault: true,
        operation: 'update',
        targetId,
      })
      .then((response) => {
        setSimoDirty();
        return response;
      });
  },
  wrapDelete(promise, { tree, targetId }) {
    // Request is considered successful if it's acknowledge by the websocket event or HTTP response - whatever comes first.
    return getDelayedResponseService(tree.contextId)
      .waitForOperation(promise, {
        targetType: resources.objectOccurrences.type,
        preventsDefault: true,
        operation: 'delete',
        targetId,
      })
      .then((response) => {
        setSimoDirty();
        return response;
      });
  },
});

export default createModule({
  path: 'ooc',
  resourceProfile: resources.objectOccurrences,
  components: [
    jv,
    crud,
    indexManager,
    processingTracker,
    tree,
    delayedResponseContext,
  ],
  modules: [ownership, owners, progress, jvBatch],
  setup({ components, modules, getAccessors, resourceProfile }) {
    getDelayedResponseService = (contextId: string) =>
      components.$delayedResponseContext.protected.getDelayedResponseService(
        contextId
      );

    const getters = {
      hasOwnershipsInherited: () => (oocId: string, type: OwningEntityType) => {
        const ooc = components.$crud.public.getSimplifiedResourceSet()[oocId];
        const ownership = getOwnershipOfType(ooc, type);
        if (!ownership) return false;
        const ownershipsRelationship = components.$jv.protected.get({
          id: oocId,
          type: resourceProfile.type,
        })?._jv.relationships?.ownerships;
        const inheritedMeta: OwnershipMeta = ownershipsRelationship?.meta;
        return !!inheritedMeta?.inherited?.find(
          (meta) => meta.id === ownership.id
        )?.inherited;
      },
      computeOwnershipOfType:
        () =>
        (
          tree: TreeIdentification,
          oocId: string,
          type: OwningEntityType
        ): {
          id: string;
          inherited: boolean;
        } | null => {
          const ooc = components.$crud.public.getSimplifiedResourceSet()[oocId];
          const currentOwnership = getOwnershipOfType(ooc, type);
          if (currentOwnership) {
            return {
              id: currentOwnership.id,
              inherited: false,
            };
          }
          const parentOocs = parentChain(
            components.$tree.public.getTreeData(tree),
            oocId
          )
            .map(
              (id) =>
                components.$crud.public.getSimplifiedResourceSet()[id] as OOC
            )
            .filter(Boolean);
          const inheritedOwnership = parentOocs
            .map((ooc) => getOwnershipOfType(ooc, type))
            .find(Boolean);
          return inheritedOwnership
            ? { id: inheritedOwnership.id, inherited: true }
            : null;
        },
    };

    const actions = {
      async externalOwnershipRemove(
        context,
        payload: {
          tree: TreeIdentification;
          oocId: string;
          owningEntity: {
            id: string;
            type: OwningEntityType;
          };
        }
      ) {
        const { owningEntity } = payload;
        const ownershipOwner = read(getters.computeOwnershipOfType)(
          payload.tree,
          payload.oocId,
          'owner'
        );
        const ownershipGroup = read(getters.computeOwnershipOfType)(
          payload.tree,
          payload.oocId,
          'owner_group'
        );
        await components.$jv.protected.commitAlterRelationship({
          id: payload.oocId,
          type: resourceProfile.type,
          relationshipKey: 'ownerships',
          relationship: {
            data: [
              owningEntity.type === 'owner'
                ? null
                : ownershipOwner && {
                    id: ownershipOwner.id,
                    type: resources.ownerships.type,
                  },
              owningEntity.type === 'owner_group'
                ? null
                : ownershipGroup && {
                    id: ownershipGroup.id,
                    type: resources.ownerships.type,
                  },
            ].filter(Boolean),
            meta: {
              inherited: [
                owningEntity.type === 'owner'
                  ? null
                  : ownershipOwner && {
                      id: ownershipOwner.id,
                      inherited: ownershipOwner.inherited,
                    },
                owningEntity.type === 'owner_group'
                  ? null
                  : ownershipGroup && {
                      id: ownershipGroup.id,
                      inherited: ownershipGroup.inherited,
                    },
              ].filter(Boolean),
            },
          },
        });
      },
      async externalOwnershipAdd(
        context,
        payload: {
          tree: TreeIdentification;
          oocId: string;
          owningEntity: {
            id: string;
            type: OwningEntityType;
          };
        }
      ) {
        await dispatch(actions.externalOwnershipRemove)(payload);
        const previousOwnershipOfType = read(getters.computeOwnershipOfType)(
          payload.tree,
          payload.oocId,
          payload.owningEntity.type
        );
        await components.$jv.protected.commitAlterRelationship({
          id: payload.oocId,
          type: resourceProfile.type,
          relationshipKey: 'ownerships',
          relationship: (relationshipData) => ({
            ...relationshipData,
            data: [
              ...(Array.isArray(relationshipData.data)
                ? relationshipData.data
                : []
              ).filter(
                ({ id }) =>
                  !previousOwnershipOfType || previousOwnershipOfType.id !== id
              ),
              { id: payload.owningEntity.id, type: resources.ownerships.type },
            ],
            meta: {
              inherited: [
                ...(relationshipData?.meta?.inherited || []).filter(
                  ({ id }) =>
                    !previousOwnershipOfType ||
                    previousOwnershipOfType.id !== id
                ),
                { id: payload.owningEntity.id, inherited: false },
              ],
            },
          }),
        });
      },
      externalRemoveNode(
        context,
        payload: {
          tree: TreeIdentification;
          id: string;
          descendantsIds: string[];
        }
      ) {
        components.$tree.protected.dispatchApplyTreeOperation({
          tree: payload.tree,
          operation: {
            operationType: TreeOperationType.Remove,
            id: payload.id,
          },
        });
      },
      externalAddNode(
        context,
        payload: {
          tree: TreeIdentification;
          resource: JVTopLevelSingleResource;
        }
      ) {
        if (payload.resource.object_occurrence_type === 'external') return;
        const id = payload.resource._jv.id;
        const parentId = extractRelatedEntityId(
          resourceProfile.parentRelationship,
          payload.resource
        );
        const position = payload.resource.position;
        components.$tree.protected.dispatchApplyTreeOperation({
          tree: payload.tree,
          operation: {
            operationType: TreeOperationType.Add,
            id,
            parentId,
            position,
            attributes: omit(payload.resource, ['_jv']),
          },
        });
      },
      externalUpdateNode(
        context,
        payload: {
          tree: TreeIdentification;
          resource: JVTopLevelSingleResource;
        }
      ) {
        const id = payload.resource._jv.id;
        const localResource = components.$jv.protected.get({
          id,
          type: resourceProfile.type,
        });
        if (
          payload.resource.object_occurrence_type === 'external' ||
          !localResource
        )
          return;
        const parentRel = resourceProfile.parentRelationship;
        const parentId =
          extractRelatedEntityId(parentRel, payload.resource) ||
          extractRelatedEntityId(parentRel, localResource);
        const position = payload.resource.position || localResource.position;
        components.$tree.protected.dispatchApplyTreeOperation({
          tree: payload.tree,
          operation: {
            operationType: TreeOperationType.Move,
            id,
            parentId,
            position,
          },
        });
      },
      async assignOwnership(
        context,
        payload: {
          tree: TreeIdentification;
          oocId: string;
          owningEntity: {
            id: string;
            type: OwningEntityType;
          };
          sync?: true;
        }
      ) {
        const previousOwnershipOfType = read(getters.computeOwnershipOfType)(
          payload.tree,
          payload.oocId,
          payload.owningEntity.type
        );
        const clearOwnerships = dispatch(actions.clearOwnerships)({
          tree: payload.tree,
          oocId: payload.oocId,
          owningEntityType: payload.owningEntity.type,
          doNotRecreateInherited: true,
        }).catch((error) => {
          console.error(error);
          /* ignore error */
        });
        if (!payload.sync) await clearOwnerships;
        const { id } = await modules.ownership.public.dispatchCreateResource({
          relationshipKey: 'object_occurrence',
          relationshipId: payload.oocId,
          relationships: {
            owning_entity: payload.owningEntity,
          },
          resource: {
            primary: true,
          },
        });
        await components.$jv.protected.commitAlterRelationship({
          id: payload.oocId,
          type: resourceProfile.type,
          relationshipKey: 'ownerships',
          relationship: (relationshipData) => ({
            ...relationshipData,
            data: [
              ...(Array.isArray(relationshipData.data)
                ? relationshipData.data
                : []
              ).filter(
                ({ id }) =>
                  !previousOwnershipOfType || previousOwnershipOfType.id !== id
              ),
              { id, type: resources.ownerships.type },
            ],
            meta: {
              inherited: [
                ...(relationshipData?.meta?.inherited || []).filter(
                  ({ id }) =>
                    !previousOwnershipOfType ||
                    previousOwnershipOfType.id !== id
                ),
                { id, inherited: false },
              ],
            },
          }),
        });
      },
      async clearOwnerships(
        context,
        payload: {
          tree: TreeIdentification;
          oocId: string;
          owningEntityType: OwningEntityType;
          doNotRecreateInherited?: boolean;
        }
      ) {
        const oocSet = components.$crud.public.getSimplifiedResourceSet();
        const ooc = oocSet[payload.oocId] as OOC;
        if (
          !ooc ||
          read(getters.hasOwnershipsInherited)(
            payload.oocId,
            payload.owningEntityType
          )
        )
          return;
        const ownershipsOfType = ooc.ownerships?.filter((ownership) => {
          const entityData = modules.ownership.public.getRelationshipData(
            ownership.id,
            resourceRelationship(resourceProfile.relationships.ownerships)(
              'owning_entity'
            )
          ) as JsonApiIdentification;
          return entityData?.type === payload.owningEntityType;
        });
        await Promise.all(
          ownershipsOfType.map(({ id }) =>
            modules.ownership.public.dispatchDeleteResource({ resourceId: id })
          )
        );
        if (!payload.doNotRecreateInherited) {
          const ownershipOwner = read(getters.computeOwnershipOfType)(
            payload.tree,
            payload.oocId,
            'owner'
          );
          const ownershipGroup = read(getters.computeOwnershipOfType)(
            payload.tree,
            payload.oocId,
            'owner_group'
          );
          await components.$jv.protected.commitAlterRelationship({
            id: payload.oocId,
            type: resourceProfile.type,
            relationshipKey: 'ownerships',
            relationship: {
              data: [
                ownershipOwner && {
                  id: ownershipOwner.id,
                  type: resources.ownerships.type,
                },
                ownershipGroup && {
                  id: ownershipGroup.id,
                  type: resources.ownerships.type,
                },
              ].filter(Boolean),
              meta: {
                inherited: [
                  ownershipOwner && {
                    id: ownershipOwner.id,
                    inherited: ownershipOwner.inherited,
                  },
                  ownershipGroup && {
                    id: ownershipGroup.id,
                    inherited: ownershipGroup.inherited,
                  },
                ].filter(Boolean),
              },
            },
          });
        }
      },
      invalidateOocPages(
        _,
        payload: {
          pagesToKeep: PageInfo[];
          requestType: string;
          requestId: string;
        }
      ) {
        components.$indexManager.public.dispatchInvalidatePageIndex(payload);
      },
      async colorBatchOperations(
        _,
        payload: {
          ids: string[];
          hexColor: string;
          tree: TreeIdentification;
        }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        const { ids, hexColor } = payload;
        ids.forEach((id) => {
          components.$crud.public.dispatchUpdateResource({
            resourceId: id,
            resource: { hex_color: hexColor },
          });
        });
        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },

      async systemTagsBatchOperations(
        _,
        payload: {
          updateData: any;
          tree: TreeIdentification;
        }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        payload.updateData.forEach((updatedNode) => {
          components.$crud.public.dispatchUpdateResource({
            resourceId: updatedNode.originalNodeId,
            resource: {
              name: updatedNode.name,
              number: `${updatedNode.number}`,
            },
            relationships: {
              classification_entry: {
                id: updatedNode.newTagId,
                type: 'classification_entry',
              },
              syntax_element: {
                id: updatedNode.syntaxElementId,
                type: 'syntax_element',
              },
            },
          });
        });
        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },

      async ownershipsBatchOperations(
        context,
        payload: {
          tree: TreeIdentification;
          oocsIds: string[];
        } & (
          | {
              operationType: 'create';
              owningEntity: { id: string; type: OwningEntityType };
            }
          | { operationType: 'delete'; owningEntityType: OwningEntityType }
        )
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        const { tree, oocsIds, operationType } = payload;
        oocsIds.forEach((id) => {
          if (operationType === 'create') {
            dispatch(actions.assignOwnership)({
              tree,
              oocId: id,
              owningEntity: payload.owningEntity,
              sync: true,
            });
          } else {
            dispatch(actions.clearOwnerships)({
              tree,
              oocId: id,
              owningEntityType: payload.owningEntityType,
            });
          }
        });
        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/ownerships/batch`,
        });
      },
      async batchDeleteOocs(
        context,
        payload: { tree: TreeIdentification; oocsIds: string[] }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        const { tree, oocsIds } = payload;
        oocsIds.forEach((resourceId) =>
          components.$tree.public.dispatchDeleteResource({
            tree,
            resourceId,
          })
        );
        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },
    };

    const { dispatch, read } = getAccessors();

    return {
      module: {
        getters,
        actions,
      },
      protected: {
        dispatchExternalRemoveNode: dispatch(actions.externalRemoveNode),
        dispatchExternalAddNode: dispatch(actions.externalAddNode),
        dispatchExternalUpdateNode: dispatch(actions.externalUpdateNode),
        dispatchInvalidateOocPages: dispatch(actions.invalidateOocPages),
        dispatchExternalOwnershipRemove: dispatch(
          actions.externalOwnershipRemove
        ),
        dispatchExternalOwnershipAdd: dispatch(actions.externalOwnershipAdd),
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        ...components.$processingTracker.public,
        ...components.$tree.public,
        getHasOwnershipsInherited: read(getters.hasOwnershipsInherited),
        dispatchAssignOwnership: dispatch(actions.assignOwnership),
        dispatchClearOwnerships: dispatch(actions.clearOwnerships),
        dispatchBatchColorOperation: dispatch(actions.colorBatchOperations),
        dispatchBatchOwnerships: dispatch(actions.ownershipsBatchOperations),
        dispatchBatchDeleteOocs: dispatch(actions.batchDeleteOocs),
        dispatchBatchSystemTags: dispatch(actions.systemTagsBatchOperations),
      },
    };
  },
});
