<template>
  <tr
    tabindex="0"
    :class="['resource-table-row text-body-2', resourceRowComputedClasses]"
    :style="{ '--row-depth': depth }"
  >
    <slot>
      <template v-for="(column, index) in columns" :key="column.attribute">
        <td
          :style="column.tdStyle"
          :class="column.tdClass"
          v-on="column.tdListeners || {}"
        >
          <div
            :data-spec="`attribute-${column.attribute}`"
            :class="{
              'nested-primary-cell': index === 0 && nesting,
            }"
          >
            <template v-if="index === 0 && nesting">
              <v-progress-circular
                v-if="loadingChildren"
                class="mr-2"
                style="width: 24px"
                size="14"
                width="3"
                indeterminate
              />
              <v-icon
                v-else
                data-spec="expand-children-arrow"
                class="mr-2"
                :style="{
                  color: 'inherit',
                  transform: isExpanded ? 'rotate(90deg)' : '',
                  visibility: hasChildren ? 'visible' : 'hidden',
                }"
              >
                mdi-menu-right
              </v-icon>
            </template>

            <slot
              :name="`attribute-${column.attribute}`"
              :item="item"
              :parent="parent"
              :isSelected="isSelected"
            >
              <template v-if="column.attribute === 'name'">
                <span class="app-link">{{ item.name || '' }}</span>
              </template>
              <template v-else>
                {{ (item && item[column.attribute]) || '' }}
              </template>
            </slot>
          </div>
        </td>
      </template>
    </slot>
  </tr>
</template>

<script setup lang="ts">
import { TableColumnDefinition } from '@/utils/resourceTable';
import { useRegistrant } from '@/composables/registrable';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    columns?: TableColumnDefinition[];
    item?: { id: string; name?: string } | null;
    parent?: { id: string; name?: string } | null;
    isSelected?: boolean;
    nesting?: boolean;
    depth?: number;
    hasChildren?: boolean;
    loadingChildren?: boolean;
    isExpanded?: boolean;
  }>(),
  {
    columns: () => [],
    item: null,
    parent: null,
    depth: 0,
  }
);

useRegistrant();

const resourceRowComputedClasses = computed(() => ({
  'resource-table-row--selected': props.isSelected,
  'resource-table-row--nesting': props.nesting,
}));
</script>

<style lang="scss" scoped>
.resource-table-row {
  background-color: rgba(0, 0, 0, calc(0.03 * var(--row-depth)));
  outline: 0;
  // move above highlighted background
  & > td > div {
    position: relative;
    z-index: 1;
  }
  & > td:first-of-type {
    padding-left: calc(24px + 10px * var(--row-depth));
  }
  &:hover,
  &:focus,
  &:focus-within {
    background-color: transparent !important;
    td {
      @include highlighted-background;
    }
  }
  &.resource-table-row--selected {
    td {
      background-color: rgb(var(--v-theme-primary));
      color: white;

      :deep(.app-link) {
        color: white;
      }
    }
    &:hover,
    &:focus,
    &:focus-within {
      td {
        @include highlighted-background(
          0.7,
          rgb(var(--v-theme-primary-darken-1))
        );
      }
    }
  }
}
.nested-primary-cell {
  display: flex;
  align-items: center;
}
</style>
