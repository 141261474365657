import { createModule } from '../utils/factories';
import { resources, ResourceProfile, ResourceType } from '@/services/resources';
import { JsonApiIdentification } from '@/models/api';
import progressModel from './progressModel';
import progressStep from './progressStep';
import jv from '../components/jv';
import crud from '../components/crud';
import { ProgressStep } from '@/models/progressStep';
import { ProgressStepChecked } from '@/models/progressStepChecked';
import { JVRestructuredRecord } from '@/models/jv';
import jvBatch from './jvBatch';

const getTargetRelationshipKey = (targetType: string) => {
  const targetResourceProfile = Object.values(resources).find(
    (profile) => profile.type === targetType
  ) as ResourceProfile;
  return Object.keys(targetResourceProfile?.relationships || {}).find((key) => {
    const rel = targetResourceProfile.relationships[key];
    return !Array.isArray(rel) && rel.type === resources.progress.type;
  });
};

export default createModule({
  path: 'progress',
  resourceProfile: resources.progress,
  components: [jv, crud],
  modules: [progressModel, progressStep, jvBatch],
  setup({ getAccessors, components, modules, resourceProfile }) {
    const getters = {
      progressSteps:
        () =>
        (targetType: string): ProgressStep[] => {
          const targetModel = modules.progressModel.public
            .getFullResource()
            .find((model) => model.progress_model_type === targetType);
          return (
            (targetModel &&
              modules.progressStep.public.getFullResource(targetModel.id)) ||
            []
          );
        },
      progressStepsChecked:
        () =>
        (targetType: string, targetId: string): ProgressStepChecked[] => {
          const target = components.$jv.protected.get({
            id: targetId,
            type: targetType,
          }) as JVRestructuredRecord;
          const targetRelationshipKey = getTargetRelationshipKey(targetType);
          const stepsChecked = [
            target?._jv?.relationships?.[targetRelationshipKey]?.data || [],
          ].flat();
          return stepsChecked.map(
            ({ id }) => components.$crud.public.getSimplifiedResourceSet()[id]
          );
        },
    };

    const actions = {
      async updateProgress(
        context,
        payload: {
          target: JsonApiIdentification;
          progressStepId: string;
          progressStepsCheckedIds?: string[];
        }
      ) {
        const {
          target,
          progressStepId,
          progressStepsCheckedIds = [],
        } = payload;
        const $jv = components.$jv.protected;
        const { id: progressStepCheckedId } =
          await components.$crud.public.dispatchCreateResource({
            resource: {},
            relationships: { target, progress_step: progressStepId },
          });
        // update target included relationship in jv store
        dispatch(actions.updateTargetProgressRelationship)({
          target,
          progressStepCheckedId,
        });
        // remove resources automatically removed by API from jv store
        progressStepsCheckedIds.forEach((id) => {
          $jv.commitDeleteRecord({
            _jv: { id, type: resourceProfile.type },
          });
        });
      },
      async resetProgress(
        context,
        payload: { progressStepId: string; target: JsonApiIdentification }
      ) {
        const { progressStepId, target } = payload;
        try {
          await components.$crud.public.dispatchDeleteResource({
            resourceId: progressStepId,
          });
          dispatch(actions.updateTargetProgressRelationship)({
            target,
            progressStepCheckedId: null,
          });
        } catch (err) {
          console.error(err);
          throw new Error(err);
        }
      },
      // update target included relationship in jv store
      async updateTargetProgressRelationship(
        context,
        payload: {
          target: JsonApiIdentification;
          progressStepCheckedId: string | null;
        }
      ) {
        const $jv = components.$jv.protected;
        const { target, progressStepCheckedId } = payload;
        const targetProfile = Object.values(resources).find(
          (r) => r.type === target.type
        ) as ResourceProfile;
        const targetProgressRelationshipKey =
          targetProfile &&
          Object.keys(targetProfile.relationships || {}).find((relKey) => {
            const relationshipProfile = targetProfile.relationships[relKey];
            return (
              !Array.isArray(relationshipProfile) &&
              relationshipProfile.type === resourceProfile.type
            );
          });
        const relationship = progressStepCheckedId
          ? {
              data: [{ id: progressStepCheckedId, type: resourceProfile.type }],
            }
          : { data: [] };
        if (targetProgressRelationshipKey) {
          $jv.commitAlterRelationship({
            id: target.id,
            type: target.type,
            relationshipKey: targetProgressRelationshipKey,
            relationship,
          });
        }
      },
      async batchRemoveProgress(
        context,
        payload: {
          progresses: {
            targetId: string;
            progressId: string;
          }[];
          targetType: ResourceType;
        }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        const { progresses, targetType } = payload;

        progresses.forEach((progress) => {
          dispatch(actions.resetProgress)({
            target: { id: progress.targetId, type: targetType },
            progressStepId: progress.progressId,
          });
        });

        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },

      async batchUpdateProgress(
        context,
        payload: {
          ids: string[];
          progressId: string;
          targetType: ResourceType;
        }
      ) {
        modules.jvBatch.protected.dispatchStartBatchTransaction();
        const { ids, progressId, targetType } = payload;

        ids.forEach((id) => {
          dispatch(actions.updateProgress)({
            target: { id, type: targetType },
            progressStepId: progressId,
          });
        });

        return modules.jvBatch.protected.dispatchPerformBatchTransaction({
          url: `async/${resourceProfile.path}/batch`,
        });
      },
    };

    const { read, dispatch } = getAccessors();

    return {
      module: {
        getters,
        actions,
      },
      protected: {
        dispatchUpdateTargetProgressRelationship: dispatch(
          actions.updateTargetProgressRelationship
        ),
      },
      public: {
        getProgressSteps: read(getters.progressSteps),
        getProgressStepsChecked: read(getters.progressStepsChecked),
        dispatchUpdateProgress: dispatch(actions.updateProgress),
        dispatchResetProgress: dispatch(actions.resetProgress),
        dispatchBatchUpdateProgress: dispatch(actions.batchUpdateProgress),
        dispatchBatchRemoveProgress: dispatch(actions.batchRemoveProgress),
      },
    };
  },
});
