const messages = {
  general: {
    search: 'Search',
    unknownAuthor: 'Unknown author',
    reloadPage: 'Reload page',
    save: 'Save',
    continue: 'Continue',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    reset: 'Reset',
    generate: 'Generate',
    confirm: 'Confirm',
    close: 'Close',
    showAll: 'Show All',
    collapse: 'Collapse',
    delete: 'Delete',
    loading: 'Loading...',
  },
  apps: {
    admin: {
      appName: 'Admin',
      topbar: {
        tools: {
          account: 'Account',
          users: 'Users',
        },
      },
    },
    design: {
      forms: {
        uniqueProjectName: 'Project name must be unique',
        contextCopiedSuccessful: 'Context was successfully copied',
      },
      appName: 'Design',
      chooseContext: 'Choose a context',
      topbar: {
        tools: {
          core: 'Core',
          simo: 'Simo',
          doma: 'Doma',
          doco: 'Doco',
          dashboard: 'Dashboard',
        },
      },
      core: {
        filters: {
          domaRelations: 'DOMA relations',
          relationsForSelected: 'Only show relations for selected element',
        },
        batch: {
          deleteSystems: 'Delete {amount} systems',
          deleteSystemsConfirmTitle: "You're about to delete {amount} systems",
        },
        showNestedRelations: 'Show nested relations',
        hideNestedRelations: 'Hide nested relations',
        confirmDeleteCustomFieldTitle: 'Delete field',
        confirmDeleteCustomFieldText:
          'Deleting this field will delete it for all object occurrences. Are you sure you want to continue?',
        deleteCustomField: 'Delete field',
        selectClassificationTable:
          'SIMO classification table has to be selected for the context',
        noClassification: 'No classification selected',
        confirmProgressStepChangeTitle: 'Changing progress step',
        confirmProgressStepChangeText:
          'Progress for 1 ooc will change | Progress for {amount} oocs will change',
        confirmOwnershipChangeTitle: 'Changing ownerships',
        confirmOwnershipChangeText:
          'You are about to change ownership to {target} for systems previously owned by {sources}. Do you want to continue?',
      },
      simo: {
        filters: {
          syntaxElements: 'Syntax element',
          oocProgress: 'System progress',
          oorProgress: 'Interface progress',
          oorCode: 'Interface classification code',
          includeOoc: 'Include system',
          includeChildren: 'Only show subsystems of',
          excludeOoc: 'Exclude systems',
          owners: 'Owners',
          ownerGroups: 'Owner groups',
          chainAnalysis: 'Chain analysis',
          analysedObjectOccurrence: 'Analysed Object Occurrence',
          highlightOwnership: 'Highlight interfaces',
          highlightOwners: 'owners',
          highlightOwnerGroups: 'owner groups',
          structureLevel: 'Structure level',
          sortBy: 'Sort by',
          deadline: 'Deadlines',
        },
        confirmRemoveRelationsTitle: 'Remove relations',
        confirmChangeProgressTitle: 'Change progresses',
        confirmRemoveRelationsText:
          '{amount} cells have relations set and will be removed',
        confirmDifferentRelationCodes:
          'Progress will change for all types of relationships: {codes}.',
        confirmDifferentRelations:
          'Some of the selected cells have no relations or unknown relations.',
        oorsHaveDifferentProgresses:
          'Not all relations have the same progress.',
        oorsProgressWillBeRemoved:
          'Progresses for selected relations will be removed.',
        notValidRelationsForProgressSet:
          'There are no relations in the selected cells',
        confirmProcess: 'Do you want to continue?',
        noResults: 'There are no results to display',
        selectedCells: 'Selected interface | Selected interfaces ({count})',
        lockedStructureInfo:
          'This context has been changed and this grid may look different now. Reload SIMO to get newest changes.',
        saveFilterSet: 'Save a filter set',
        loadFilterSet: 'Load a filter set',
        loadFilterSetWarning:
          'Loading a filter set erases your current filter progress',
        filtersSetNameLabel: 'Name',
        filtersSetNameError: 'Provide a name for filter set',
        filtersSetSelectSetLabel: 'Filter set to update',
        filtersSetSelectSetError: 'Select filter set to update',
        filtersSetDateLabel: 'Date',
        filtersSavedAt: 'Saved {date}',
        noSavedFilterSets: 'There are no saved filter sets',
        noFilterSetsMatchingQuery: 'There are no filter sets matching query',
        nothingSelected: 'Nothing is selected',
        mostRecent: 'Most recent filters',
        sidePanel: {
          deleteInterface: 'Delete interface | Delete {count} Interfaces',
        },
      },
      dialogAlert: {
        childrenNodes: 'Possible relations in the children nodes',
        moreObjects: 'More dependant objects',
        moreOOCs: 'More dependant object occurrences',
        title: 'You\'re about to delete "{resource}"',
        noRelations: 'No dependant relations',
      },
    },
    setup: {
      appName: 'Setup',
      topbar: {
        tools: {
          classification: 'Classification',
          syntax: 'Syntax',
          ownership: 'Ownership',
          progressModeling: 'Progress modeling',
        },
      },
      classification: {
        examples: {
          placeholder: 'Enter examples...',
        },
        type: 'Type',
      },
      users: {
        selectProject: 'select a project',
        selectContext: 'select a context',
        denyAdminRole: 'Deny the admin role',
        denyAdminRoleText:
          'You are removing the admin role from yourself. You will not be able to undo it yourself, only another admin will be able to grant it to you again. Do you want to continue?',
        roleSelector: {
          updating: 'Updating',
          addPermission: 'Add  Role',
          removeRole: 'Revoke Permission',
        },
        drawers: {
          close: 'Close',
          project: 'Project',
          role: 'Role',
        },
      },
    },
    launchpad: {
      appName: 'Launchpad',
      sections: {
        welcome: 'Welcome to the SEC-HUB',
        recentWork: 'Recent work',
      },
      buttonsText: {
        start: 'Start',
        addWidget: '+ Add Widget',
      },
    },
    login: {
      appName: 'Login',
      emailField: 'Email',
      passwordField: 'Password',
      loginButton: 'Login',
      required: 'Required',
      invalidEmail: 'Invalid e-mail.',
    },
  },
  topbar: {
    user: {
      darkTheme: 'Dark theme',
      logOut: 'Log out',
    },
  },
  sidebar: {
    tools: {
      search: 'Search',
      filter: 'Filter',
      print: 'Print',
      listView: 'List view',
      treeView: 'Tree view',
      enterFullscreen: 'Full screen',
      exitFullscreen: 'Exit full screen',
      tradeStudy: 'Trade Study',
      export: 'Export',
      import: 'Import',
      zoom: {
        in: 'Zoom in',
        out: 'Zoom out',
      },
      owners: 'Owners',
      ownerGroups: 'Owner groups',
      meetingPlanner: 'Meeting Planner',
      duplicateRevision: 'Duplicate revision as project',
      sortByRDS: 'Click to sort by RDS',
      sortByCORE: 'Click to sort by CORE',
    },
  },
  tradeStudies: {
    tradeStudy: 'Trade Study',
    tradeStudies: 'Trade Studies',
    createButton: 'Create Trade Study',
    noCoreEditWarning: 'Only the SIMO grid will be editable',
    otherTradeStudiesOfContext: 'Other trade studies based on this context',
    noOtherTradeStudiesOfContext:
      'There are no other trade studies based on this context',
    tradeStudiesOfContext: 'Trade studies based on this context',
    noTradeStudiesOfContext: 'There are no trade studies based on this context',
    creatingWait: 'Creating Trade Study, please wait...',
    compareModeOn: 'Compare mode on',
    exitCompareMode: 'Exit Compare Mode',
    backToMainContext: 'Back to main context',
    createdSuccess: 'Trade Study Created',
    mainContext: 'main context',
    rejectingMultipleDeltas:
      'Rejecting this delta will automatically reject all linked deltas',
    tradeStudyOf: 'Trade study based on "{context}"',
    baseContextReadOnlyInfo:
      'It is not possible to merge deltas to archived or obsolete contexts',
    invalidGroupMergeInfo:
      'It is not possible to merge this delta.\nAt least one of the object occurrences no longer exist in the main context.',
    invalidGroupMicroSimoInfo:
      'It is not possible to show comparison for this group. At least one of the object occurrences no longer exist in the main context.',
  },
  projectRevisions: {
    nRevision: 'Revision {number}',
    nProjectRevisions: '– | {count} Revision | {count} Revisions',
    revisionList: 'Revision list',
    createRevision: 'Create a revision',
    comeBackLater:
      'Creating revision... This may take a while, please come back later',
    revisionCreationFailed: 'Revision creation failed. Please try again...',
    currentRevision: "Revision {number}'",
    filters: {
      base: {
        addition: 'Created systems',
        deletion: 'Deleted systems',
        change: 'Modified systems',
      },
      change: {
        name: 'Name',
        progress: 'Progress',
        color: 'Color',
        description: 'Description',
        image: 'Image',
        owner: 'Owner',
        owner_group: 'Owner group',
      },
    },
  },
  ownership: {
    addNewOwner: 'add new owner',
    addNewOwnerGroup: 'add new owner group',
    labelName: 'Owner',
    labelTrueUser: 'SEC-Hub User',
    labelTitle: 'Role',
    labelGroups: 'Groups',
    labelCompany: 'Company',
    labelGroupName: 'Group',
    labelGroupOwners: 'Members',
    filterName: 'Owner',
    filterRole: 'Role',
    filterGroup: 'Group filter',
    filterCompany: 'Company filter',
    filterGroupName: 'Group',
    formValidationNameRequired: 'Owner is required',
    unregisteredOwner: 'unregistered owner',
    owner: 'Owner',
    ownerGroup: 'Owner group',
    noOwner: 'No owner',
    noOwnerGroup: 'No owner group',
    confirmRemoveOwnerTitle: 'Remove owner',
    confirmRemoveOwnerText:
      'Removing this owner will remove it from all contexts of this project and remove its assignment to all resources within the project. Are you sure you want to continue?',
    confirmRemoveOwnerGroupTitle: 'Remove owner group',
    confirmRemoveOwnerGroupText:
      'Removing this group will remove it from all contexts of this project and remove its assignment to all resources within the project. Are you sure you want to continue?',
  },
  archiving: {
    archiveLabel: 'Archive',
    restoreLabel: 'Restore',
    confirmArchiveProject: 'Are you sure you want to archive this project?',
    confirmArchiveContext: 'Are you sure you want to archive this context?',
    archiveProjectWarning:
      'Archiving this project will also archive the following contexts:',
  },
  publishing: {
    publishLabel: 'Publish',
    confirmPublishSyntax: 'Publish syntax',
    confirmPublishClassificationTable: 'Publish classification table',
    publishSyntaxWarning:
      'Publishing the syntax is not reversible. Once published, it won’t be possible to edit or change its contents. Do you want to continue?',
    publishClassificationTableWarning:
      'Publishing the classification table is not reversible. Once published, it won’t be possible to edit or change its contents. Do you want to continue?',
  },
  errors: {
    generic: 'Something went wrong, please try again later...',
    dataMissing:
      'Error occurred while fetching data. Please note that some data might be missing',
    appLoading: 'There was a problem loading data. Try coming back later.',
    '1001.internal_server_error': 'Internal server error',
    '1002': 'Resource does not exist',
    '1003':
      'Invalid or insufficient request, please correct and resend the request',
    '1004.no_current_account': 'Unable to determine the account',
    '1004.no_current_user': 'Unable to determine the user',
    '1004.no_local_user':
      'Unable to retrieve the user from the authentication provider',
    '1005': 'You do not have permission',
    '1101':
      'You are trying to change {resource} that is immutable and cannot be changed',
    '1103':
      'You are trying to delete {resource} that has not beed archived. Archive resource to be able to delete it',
    '1104':
      "You are trying to update {resource} that has already been published. Published resources are immutable and can't be changed",
    '1105':
      'You are trying to use archived {resource}, please use up to date resources instead',
    '2001.1a.name_required':
      'Name has already been taken, please use unique name',
    '2003.1d.syntax_node_required':
      'Unable to determine a Syntax Node for this Object Occurrence. Please contact support.',
    '2006.3a.not_unique_number':
      'Number has already been taken, please use unique number',
  },
  notification: {
    close: 'Close',
  },
  meetingPlanner: {
    break: 'Break',
    rooms: 'Meeting Rooms',
    unlimitedRoom: 'Unlimited Rooms',
    schedule: 'schedule',
    ownersType: 'Select type of Owners',
    block: 'Blocking',
    meetingLenght: 'Meeting length',
    meetingLenghtHint: 'Meeting length in minutes',
    title: 'Create',
    load: 'Load',
    addBreak: 'Add break',
    downloadRecent: 'download recently generated plan',
    download: 'Download',
    processing: 'Processing...',
    generatedPlansTitle: 'Generated Plans',
    generatingFailed: 'Generating failed',
    noPreviousPlans: 'No previous plans',
    nRooms: 'unlimited rooms | {count} room | {count} rooms',
    nMinMeetings: '{count}-min meetings',
    nBreaks: 'no breaks | {count} break | {count} breaks',
    ownershipType: {
      owner: 'owners',
      owner_group: 'owner groups',
    },
  },
  descriptionField: {
    editHeader: 'Edit Header',
    editText: 'Edit Text',
    delete: 'Delete',
    showMore: 'Show More',
    showLess: 'Show Less',
    moveUp: 'Move up',
    moveDown: 'Move down',
  },
  export: {
    minimumContextSelected:
      'You have to select at least 2 contexts to export Domain Relations',
    ongoingDomaExport: 'Doma export is in progress',
  },
  syntaxUpdate: {
    updateTitle: 'Are you sure you want to change syntax for this context?',
    updateText:
      'New syntax, will result in a new revision, and the structure will remain locked for all Viewers and Editors until the transition is finished.\nThis may take a couple minutes',
    update: 'Update syntax',
    successfullyStarted: 'Syntax update process successfully started.',
    confirmCancelSyntaxUpdate:
      'Are you sure you want to cancel syntax update for this context?',
    notAllowedDuringActiveTradeStudies:
      'Action not allowed during active trade studies.',
    notAllowedDuringSyntaxUpdate: 'Action not allowed during syntax update.',
    oneOfContextsIsInProcessOfSyntaxUpdate:
      'Action not allowed: one project context is currently undergoing a syntax update.',
    successfullyCanceled: 'Syntax update canceled. No changes applied.',
    successfullyFinished:
      'Syntax update complete. Changes applied successfully.',
    finishSyntaxUpdateTitle: 'Are you sure you want to apply syntax update?',
    cancelSyntaxUpdateTitle: 'Are you sure you want to cancel syntax update?',
    projectHasSyntaxUpdateInProgress:
      'Syntax update in progress. Option temporarily unavailable.',
    changeCodeRequired: 'Code change required',
    syntax_update_started: 'We are updating the syntax for this context.',
    syntax_update_cancelled:
      'We have cancelled the syntax update for this context.',
    syntax_update_finished:
      'We have finished updating the syntax for this context.',
    classCodeErrorsAmount: 'Class code errors - {amount}',
    definitionChangedAmount: 'Only definition changed - {amount}',
  },
  tooltip: {
    viewInSIMO: 'View in SIMO',
    viewInCORE: 'View in CORE',
  },
  deadlines: {
    viewTitle: 'Deadlines',
    defaultView: 'Default View',
    myDeadlines: 'My Deadlines',
    noInterfacesAssigned: 'No interfaces assigned',
    searchAndFilterSection: {
      search: 'Search',
      searchPlaceholder: 'Type here',
      owners: 'Filter on owner',
      ownersPlaceholder: 'Owner',
      status: 'Status',
      statusPlaceholder: 'Active/Completed',
      from: 'Date From',
      to: 'Date To',
      timeFrame: 'Time Frame',
      clearFilters: 'Clear filters',
    },
    deadlineProps: {
      name: 'Name',
      deadline: 'Deadline',
      reminder: 'Reminder',
      progressTarget: 'Expected Progress Level',
      description: 'Description',
      completion: 'Completion',
    },
    deleteModalTitle: 'System Requirement Documentation',
    deleteModalSubtitle:
      'Complete and submit the initial system requirement documents for review.',
    deleteModal: {
      dueDate: 'Due Date',
      reminder: 'Reminder',
      progressTarget: 'Progress Target',
      interfacesAssigned: 'Interfaces Assigned',
      completed: 'Completed',
      uncompleted: 'Uncompleted',
    },
    createDeadline: 'Create Deadline',
    updateDeadline: 'Update Deadline',
    dueDate: 'Due Date: {time}',
    reminder: 'Reminder: {time}',
    progressTarget: 'Progress Target: ',
    interfaceProgress: 'Interface progress {progress}',
    totalInterfaces: 'Total interfaces',
    completedInterfaces: 'Completed interfaces',
    interface: 'Interface',
    sourceOwner: 'Source Owner',
    targetOwner: 'Target Owner',
    noOwner: 'No Owner',
    currentProgress: 'Current Progress',
    assignDeadlines: 'Select Deadline | Assign Deadline | Assign {n} Deadlines',
    attachDeadline: 'Attach deadline',
    removeDeadline: 'Remove deadline',
    noDataAvailable: 'No Data Available',
    enterValidDateFormat: 'Please enter valid dates in YYYY-MM-DD format',
    startDateBiggerThenEndError: 'Start date must be before end date',
    notAssigned: 'Not assigned',
    notSet: 'Not set',
  },
  eventLog: {
    title: 'Event Log',
    treeNodeMenuTitle: 'Open in Event Logs',
    textFields: {
      rds: 'RDS Code',
      user: 'User',
      action: 'Action',
      timeframe: 'Timeframe',
      name: 'Name',
      aspect: 'Aspect',
      ressource: 'Ressource',
    },
    refresh: 'Refresh',
  },
};
export default messages;
