<template>
  <div
    class="virtual-list"
    ref="scrollArea"
    v-resize="onResize"
    @scroll.passive="handleScroll"
  >
    <div class="virtual-list__inner">
      <div
        class="virtual-list__expander"
        :style="expanderStyle"
        ref="expanderWrapper"
      >
        <div
          class="virtual-list__viewport"
          :style="transformToIndex(startIndex)"
        >
          <div
            v-for="{ item, index } in viewportItems"
            :key="getKey ? getKey(item) : index"
            :style="item.style"
          >
            <slot name="item" :index="index" :row="item" />
          </div>
        </div>
      </div>
      <slot name="append" />
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Options, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import { clamp } from '@/utils/math';
import { debounce, throttle } from '@/utils/tools';

interface VirtualListViewport {
  offset: number;
  size: number;
  startIndex: number;
}

@Options({
  name: 'VirtualList',
  emits: ['update:scrollTop'],
})
export default class VirtualList<Item> extends Vue {
  @Prop({ required: true }) itemHeight!: number;
  @Prop({ default: () => [] }) items!: Readonly<Item[]>;
  @Prop({ default: 2 }) bufferSize!: number;
  @Prop({ default: 0 }) throttle!: number;
  @Prop() getKey?: (item: Item) => string;
  @Prop() treeInitialPosition: number;

  rawStartIndex = 0;
  rawEndIndex = 0;
  animationFrameRequested?: boolean;

  useInitialSetup = true;

  get bufferInPixels() {
    return this.itemHeight * this.bufferSize;
  }

  get dependencies() {
    return [
      this.rows,
      this.bufferSize,
      this.itemHeight,
      this.treeInitialPosition,
    ].join(';');
  }

  get expanderStyle() {
    return {
      height: `${this.itemHeight * this.rows}px`,
    };
  }

  get rows() {
    return this.items.length;
  }

  get startIndex() {
    return clamp(this.rawStartIndex, 0, this.rows - 1);
  }

  get endIndex() {
    return clamp(this.rawEndIndex, 0, this.rows);
  }

  public updateVisibleItems(): VirtualListViewport {
    const scroll = this.getScroll();
    scroll.start -= this.bufferInPixels;
    scroll.end += this.bufferInPixels;
    this.rawStartIndex = Math.floor(scroll.start / this.itemHeight);
    this.rawEndIndex = Math.ceil(scroll.end / this.itemHeight);
    return this.onVisibleItemsChange();
  }

  applyInitialScrollState(scrollArea: Element | null, scrollTopValue: number) {
    scrollArea.scrollTop = scrollTopValue;
    this.useInitialSetup = false;
  }
  debouncedApplyInitialScrollState = debounce(
    this.applyInitialScrollState.bind(this),
    1000
  );

  mounted() {
    this.updateVisibleItems();
    if (this.throttle)
      this.handleScroll = throttle(this.handleScroll, this.throttle);
  }

  transformToIndex(index) {
    return {
      // use absolute positioning instead of transform to not create a z-index stacking context
      position: 'absolute' as const,
      top: `${this.itemHeight * index}px`,
    };
  }

  get viewportItems() {
    return this.items
      .slice(this.startIndex, this.endIndex)
      .map((item, index) => ({ index: this.startIndex + index, item }));
  }

  @Emit('update:viewport')
  onVisibleItemsChange(): VirtualListViewport {
    return {
      offset: this.startIndex,
      size: this.endIndex - this.startIndex,
      startIndex: this.startIndex,
    };
  }

  getElementRect(el: Element) {
    return {
      top: el ? el.getBoundingClientRect().top : 0,
      bottom: el ? el.getBoundingClientRect().bottom : 0,
    };
  }

  getScroll() {
    const expanderWrapper = this.$refs.expanderWrapper as Element;
    const scrollArea = this.$refs.scrollArea as Element;
    const start =
      this.getElementRect(scrollArea).top -
      this.getElementRect(expanderWrapper).top;
    return { start, end: start + scrollArea.clientHeight };
  }

  @Watch('dependencies')
  onDependenciesChange() {
    this.$nextTick(() => this.updateVisibleItems());
    if (this.useInitialSetup) {
      this.debouncedApplyInitialScrollState(
        this.$refs.scrollArea as Element,
        this.treeInitialPosition
      );
    }
  }

  onResize() {
    this.updateVisibleItems();
  }

  handleScroll() {
    if (!this.throttle) {
      if (!this.animationFrameRequested) {
        this.animationFrameRequested = true;
        requestAnimationFrame(() => {
          this.animationFrameRequested = false;
          this.updateVisibleItems();
          this.$emit(
            'update:scrollTop',
            (this.$refs.scrollArea as Element).scrollTop
          );
        });
      }
    } else {
      this.updateVisibleItems();
    }
  }
}
</script>

<style lang="scss" scoped>
.virtual-list {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.virtual-list__inner {
  position: relative;
}
.virtual-list__expander {
  position: relative;
}
.virtual-list__viewport {
  position: relative;
  top: 0;
  left: 0;
  min-width: 100%;
}
</style>
