import { MaybeRef } from '@vueuse/core';
import { computed, ref, toRef, unref, watch } from 'vue';

export const useSearchInput = (props: {
  selectedItem: MaybeRef<unknown>;
  itemValue: (item: unknown) => string;
  itemText: (item: unknown) => string;
  isMenuOpen: MaybeRef<boolean>;
  initialValue?: MaybeRef<string>;
}) => {
  const inputModel = ref('');

  const selectedItemText = computed(() =>
    unref(props.selectedItem) ? props.itemText(unref(props.selectedItem)) : ''
  );
  const isSearchQueryChanged = computed(
    () => inputModel.value !== selectedItemText.value
  );

  watch(
    [toRef(props, 'isMenuOpen'), selectedItemText],
    ([isOpen]) => {
      if (isOpen) return;
      inputModel.value = selectedItemText.value;
    },
    { immediate: true }
  );

  watch(toRef(props, 'initialValue'), (val) => {
    if (val) inputModel.value = unref<string>(val);
  });

  return {
    inputModel,
    isSearchQueryChanged,
  };
};
