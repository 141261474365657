<template>
  <v-app>
    <AppNotifications />

    <PortalTarget name="app" />
    <DesignNavigationDrawer
      v-if="showDesignDrawer"
      :labelsVisible="labelsVisible"
      :miniToolbarWidth="miniToolbarWidth"
      @expanded="toggleToolbarLabels"
    />
    <v-fade-transition>
      <v-sheet
        v-if="toolbarDrawerVisible"
        class="toolbar-drawer cardBackground background--highlighted"
        :style="{
          '--toolbar-drawer-offset-x': showDesignDrawer
            ? `${miniToolbarWidth}px`
            : '0',
        }"
        :theme="!$vuetify.theme.themes['dark'] ? 'light' : ''"
        data-spec="toolbar-drawer"
        v-click-outside="disableActiveTool"
      >
        <div class="toolbar-drawer-portal-target">
          <PortalTarget name="toolbar-drawer" :key="activeTool" />
        </div>
      </v-sheet>
    </v-fade-transition>

    <v-app-bar class="topbar" color="primary" height="64">
      <div class="app-toolbar-title">
        <div class="mr-6"></div>
        <v-toolbar-title class="app-toolbar-title">
          <PortalTarget name="header-breadcrumbs">
            <HeaderBreadcrumbs noPortal>
              <HeaderBreadcrumbsItem>
                {{ application.name }}
              </HeaderBreadcrumbsItem>
            </HeaderBreadcrumbs>
          </PortalTarget>
        </v-toolbar-title>
      </div>

      <div class="header-menu">
        <HeaderMenuBar
          v-if="!hideSubApplicationsTabs"
          :subApplications="subApplications"
        />
      </div>
      <div class="header-user">
        <div class="header-user__notifications">
          <UserNotifications />
        </div>
        <MorePanel v-if="canSeeMoreApplications" />

        <HelpButton @click="handleHelpSidebar(true)" />

        <v-divider class="ma-0" vertical />
        <UserPanel />
      </div>
    </v-app-bar>
    <PortalTarget
      v-if="hasContentForProjectsDrawer && isOnProjectDetailsPage"
      name="projects-drawer-list"
    />

    <v-main
      :class="isOnToolBarPage ? 'main-content-without-toolbar' : 'main-content'"
    >
      <AppToolBanner
        v-if="!isOnToolBarPage"
        :fullscreenMode="fullscreenMode"
        @fullscreen="toggleFullscreen"
      />
      <PortalTarget name="app-main-content" />
      <slot />
      <ResourceTablePanel
        :show="isHelpSideBarOpen"
        :clickOutSideEnabled="!hasArticleOpen"
        @close="handleHelpSidebar(false)"
        :class="{ 'help-sidebar-large': isHelpSidebarExpanded }"
      >
        <HelpSidebar
          @hasArticleOpen="changeHasArticleOpen"
          @close="handleHelpSidebar(false)"
          @openKeyboardShortcuts="handleShortCutsModal(true)"
          @openReleaseNotes="handleReleaseNotesModal(true)"
          @changeSize="changeSizeOfHelpDrawer"
        />
      </ResourceTablePanel>
      <div id="teleport"></div>
    </v-main>

    <KeyShortcuts
      class="resource-table-panel--prevent-close"
      :show="shortcutsVisible"
      @closeModal="handleShortCutsModal(false)"
    />
    <ReleaseNotes
      class="resource-table-panel--prevent-close"
      :show="releaseNotesVisible"
      @closeModal="handleReleaseNotesModal(false)"
    />
  </v-app>
</template>

<script lang="ts">
import { Options, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { PortalTarget, Wormhole } from 'portal-vue';
import { RouteLocation } from 'vue-router';
import UserPanel from '@/components/userPanel.vue';
import MorePanel from '@/components/morePanel.vue';
import HelpButton from '@/components/help/helpButton.vue';
import HelpSidebar from '@/components/help/helpSidebar.vue';
import UserNotifications from '@/components/userNotifications.vue';
import HeaderMenuBar from '@/components/headerMenuBar.vue';
import HeaderBreadcrumbs from '@/components/headerBreadcrumbs.vue';
import HeaderBreadcrumbsItem from '@/components/headerBreadcrumbsItem.vue';
import { eventBus } from '@/services/eventBus/eventBus';
import AppNotifications from '@/components/appNotifications.vue';
import AppToolBanner from '@/components/toolbanner/appToolBanner.vue';
import { Application } from '@/models/application';
import { getApplications } from '@/services/applicationsConfig';
import KeyShortcuts from '@/components/keyShortcuts/keyShortcuts.vue';
import AppToolbarItem from '@/components/toolbar/appToolbarItem.vue';
import DesignNavigationDrawer from '@/components/design/layout/designNavigationDrawer.vue';
import { ToolbarContext } from './types';
import { RouteName } from '@/enums/routing';
import ResourceTablePanel from '@/components/resourceTable/resourceTablePanel.vue';
import ReleaseNotes from '@/components/help/releaseNotes.vue';
import { featureFlags } from '@/featureFlags';

@Options({
  name: 'AppLayout',
  components: {
    AppToolbarItem,
    AppNotifications,
    UserPanel,
    MorePanel,
    HelpButton,
    HelpSidebar,
    UserNotifications,
    HeaderMenuBar,
    HeaderBreadcrumbs,
    HeaderBreadcrumbsItem,
    KeyShortcuts,
    PortalTarget,
    AppToolBanner,
    DesignNavigationDrawer,
    ResourceTablePanel,
    ReleaseNotes,
  },
  provide() {
    const self = this as AppLayout;
    return {
      toolbarContext: {
        get activeTool() {
          return self.activeTool;
        },
        get close() {
          return self.disableActiveTool;
        },
        get setTool() {
          return self.setActiveTool;
        },
        get isExpanded() {
          return self.labelsVisible;
        },
      } as ToolbarContext,
    };
  },
})
export default class AppLayout extends Vue {
  @Prop() application: Application;
  @Prop(Boolean) hideSubApplicationsTabs: boolean;
  @Ref() fullscreen!: { toggle: () => void };

  isHelpSideBarOpen = false;
  shortcutsVisible = false;
  releaseNotesVisible = false;
  labelsVisible = false;
  activeTool: string | number | null = null;
  fullscreenMode = false;
  isHelpSidebarExpanded = false;
  hasArticleOpen = false;

  get toolbarDrawerVisible(): boolean {
    return !!this.activeTool && this.hasContentFor('toolbar-drawer');
  }

  get hasContentForProjectsDrawer(): boolean {
    return this.hasContentFor('projects-drawer-list');
  }

  get isOnProjectDetailsPage(): boolean {
    return this.$route.name === RouteName.ProjectDetails;
  }

  changeSizeOfHelpDrawer(isExpanded: boolean) {
    this.isHelpSidebarExpanded = isExpanded;
  }

  changeHasArticleOpen(hasArticleOpen: boolean) {
    this.hasArticleOpen = hasArticleOpen;
  }

  hasContentFor(key: string): boolean {
    return !!Wormhole.getContentForTarget(key).length;
  }

  created() {
    eventBus.$on('shortcut-used', this.handleShowShortcuts);
    document.addEventListener('fullscreenchange', this.fullscreenChange);
  }

  beforeUnmount() {
    eventBus.$off('shortcut-used', this.handleShowShortcuts);
    document.removeEventListener('fullscreenchange', this.fullscreenChange);
  }

  // toggleDevTools() {
  //   toggleDevTools();
  // }

  handleShowShortcuts(e: { event: KeyboardEvent; keys: string }) {
    if (!['Slash-ControlLeft', 'Slash-ControlRight'].includes(e.keys)) return;
    this.handleShortCutsModal(true);
  }

  handleShortCutsModal(open: boolean) {
    this.shortcutsVisible = open;
  }

  handleReleaseNotesModal(open: boolean) {
    this.releaseNotesVisible = open;
  }

  handleHelpSidebar(open: boolean) {
    this.isHelpSideBarOpen = open;
  }

  get miniToolbarWidth() {
    return 54;
  }

  get showDesignDrawer(): boolean {
    return this.$route.matched.some(
      (record) => record.name === RouteName.Design
    );
  }

  get canSeeMoreApplications() {
    return this.$store.$direct.userPermission.canSeeOtherApps();
  }

  get applications(): Application[] {
    return getApplications().filter(
      (app) => !app.checkPermissions || app.checkPermissions()
    );
  }

  get subApplications() {
    return this.application.subApplications(this.$route).map((app) => {
      const hasPermission = app.checkPermissions
        ? app.checkPermissions(this.$route)
        : { access: true };
      return {
        ...app,
        checkPermissions: hasPermission,
      };
    });
  }

  get isOnToolBarPage() {
    if (featureFlags.isOptionalToolbarEnabled) {
      return (
        this.$route.name === RouteName.Launchpad ||
        this.$route.name === RouteName.EventLog ||
        this.$route.name === RouteName.Owner ||
        this.$route.name === RouteName.OwnerGroups ||
        this.$route.name === RouteName.ProjectDetails
      );
    } else {
      return (
        this.$route.name === RouteName.Launchpad ||
        this.$route.name === RouteName.EventLog
      );
    }
  }

  setActiveTool(toolId: string | number) {
    if (toolId) {
      this.activeTool = toolId;
      this.labelsVisible = false;
    }
  }

  disableActiveTool(ev?: MouseEvent) {
    if (!ev || !ev.defaultPrevented) {
      this.activeTool = null;
    }
  }

  toggleToolbarLabels() {
    this.labelsVisible = !this.labelsVisible;
    if (this.labelsVisible) {
      this.disableActiveTool();
    } else {
      // Vuetify bug: `v-navigation-drawer` should set it by itself
      // this.$vuetify.defaults.global. = this.miniToolbarWidth;
    }
  }
  async toggleFullscreen() {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      await document.exitFullscreen();
    }
  }

  fullscreenChange() {
    this.fullscreenMode = !!document.fullscreenElement;
  }

  @Watch('$route')
  onRouteChange(newRoute: RouteLocation, oldRoute: RouteLocation) {
    const stripPositionParam = (url: string) => {
      const parsedUrl = new URL(url, window.location.origin);
      parsedUrl.searchParams.delete('position');
      return parsedUrl.pathname + parsedUrl.search;
    };

    if (
      stripPositionParam(newRoute.fullPath) !==
      stripPositionParam(oldRoute.fullPath)
    )
      this.disableActiveTool();
    const simoRouteNames = [
      RouteName.Simo,
      RouteName.RevisionSimo,
      RouteName.SimoTradeStudy,
    ];
    if (simoRouteNames.includes(newRoute.name as RouteName)) {
      const oocs = this.$store.$direct.simo.getOocs();
      const isPending = this.$store.$direct.simo.getOocsLoading();
      if (!oocs.length && !isPending) {
        this.setActiveTool('simo-filters-tool');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-button {
  width: 100% !important;
  height: 40px !important;
  padding: 0 8px !important;
  justify-content: flex-start;
}

.header-menu,
.header-user {
  display: flex !important;
  height: 100%;
  justify-self: end !important;
}

.header-user {
  align-items: center;
}

.header-user__notifications {
  display: flex;
  align-items: center;
}

.header-menu {
  display: flex !important;
  height: 100%;
}

.topbar {
  overflow: visible;

  /* ::v-deep .v-toolbar__extension, */
  :deep(.v-toolbar__content) {
    display: grid !important;
    grid-template-columns: 1fr auto 1fr !important;
    align-items: center !important;
    padding: 0;
  }
}

.main-content {
  position: relative;
  max-height: calc(100vh - 54px);
  z-index: 3;
  // disable transition of v-main element (vuetify)
  // this transition distorts the size of containers
  // measured after resizing the browser window (on resize)
  // TODO: replace with $main-transition sass variable
  transition: none !important;
}

.main-content-without-toolbar {
  position: relative;
  max-height: 100vh;
  z-index: 3;
  transition: none !important;
}

.app-toolbar-title {
  display: flex;
  overflow: visible;
  justify-self: start !important;
  padding: 0 !important;
  margin: 0 !important;
}

.toolbar-list {
  :deep(.v-list-item) {
    justify-content: flex-start !important;
    padding-left: 8px;
    padding-right: 8px;
  }

  :deep(.v-list-item__icon) {
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 16px;
  }
}

.toolbar-list__expand-content {
  margin-left: auto !important;
  flex-grow: 0;
  flex-basis: auto;
}

.toolbar__export {
  order: 2;
}

.toolbar-drawer {
  position: fixed;
  top: calc(#{$app-topbar-height} + #{$app-tool-banner-height});
  left: var(--toolbar-drawer-offset-x);
  height: calc(100vh - #{$app-topbar-height} - #{$app-tool-banner-height});
  width: $app-toolbar-drawer-width;
  z-index: 4;
  box-shadow: 10px 0 10px -5px rgba(0, 0, 0, 0.2);
}

.toolbar-drawer-portal-target {
  position: relative;
  width: 100%;
  height: 100%;
}

.button-position {
  place-self: center !important;
}

.toolbar-divider {
  margin: 8px !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.help-sidebar-large {
  width: 500px !important;
}
</style>
