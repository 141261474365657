<template>
  <div v-if="activeTab" class="d-flex justify-center">
    <v-tabs
      ref="tabs"
      :model-value="activeTab"
      align-items="center"
      height="100%"
      bg-color="transparent"
      color="white"
    >
      <v-tooltip
        location="bottom"
        v-for="subApplication in subApplications"
        :key="subApplication.id"
      >
        <span
          v-html="tooltipText(subApplication)"
          class="tooltip-font-size"
        ></span>
        <template #activator="{ props }">
          <div v-bind="props">
            <v-tab
              :data-spec="`tab-${subApplication.name.toLowerCase()}`"
              :to="subApplicationRoute(subApplication)"
              :disabled="!subApplication.checkPermissions.access"
              :prepend-icon="subApplication.icon"
              :text="subApplication.name"
              :style="{ color: 'rgba(255, 255, 255, 0.6)' }"
            />
          </div>
        </template>
      </v-tooltip>
    </v-tabs>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { SubApplication } from '@/models/subApplication';
import { RouteName } from '@/enums/routing';

type ResolvedSubApplications = Omit<SubApplication, 'checkPermissions'> & {
  checkPermissions: ReturnType<SubApplication['checkPermissions']>;
};

@Options({})
export default class HeaderMenuBar extends Vue {
  @Prop() subApplications!: ResolvedSubApplications[];

  get activeTab() {
    const subAppRootRouteName = this.$route.matched.find(
      (r) => r.meta.subApplicationRoot
    )?.meta.subApplicationRoot as RouteName;
    const subApplication = this.subApplications.find(
      (subApp) => subApp.route === subAppRootRouteName
    );
    return subApplication && this.subApplicationPath(subApplication);
  }

  subApplicationRoute(subApplication: ResolvedSubApplications) {
    return { name: subApplication.route, params: this.$route.params };
  }

  subApplicationPath(subApplication: ResolvedSubApplications) {
    return this.$router.resolve(this.subApplicationRoute(subApplication))?.href;
  }

  textUppercase(text: string) {
    return text.toUpperCase();
  }

  tooltipText(subApplication: ResolvedSubApplications) {
    if (subApplication.checkPermissions.access)
      return subApplication.tooltip || subApplication.name.toUpperCase();
    return 'message' in subApplication.checkPermissions
      ? subApplication.checkPermissions.message
      : 'No access';
  }
}
</script>
<style lang="scss" scoped>
:deep(.v-tabs a) {
  height: 100%;
}
.tooltip-font-size {
  font-size: 15px;
}
</style>
