import { ApplicationType } from '@/enums/applicationType';
import { RouteName } from '@/enums/routing';
import {
  createAppRoute,
  createSubAppRoutes,
  SecRouteConfig,
} from '@/router/utils';
import Design from '@/views/design.vue';
import ProjectsPage from '@/components/design/projectsPage.vue';
import SupplyChain from '@/router/supplyChain/supplyChain.vue';
import {
  contextsDependencies,
  projectDependencies,
  revisionDependencies,
} from '@/router/projects/supplyChains';
import { featureFlags } from '@/featureFlags';

const projectsRoutes: SecRouteConfig[] = [
  createAppRoute(ApplicationType.Design, {
    path: '/projects',
    name: RouteName.Design,
    component: Design,
    redirect: { name: RouteName.Projects },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: RouteName.Projects,
        component: ProjectsPage,
        meta: {
          title: 'PROJECTS',
          meta: { forceRefresh: true },
        },
      },
      {
        path: ':projectId',
        component: SupplyChain,
        redirect: RouteName.Projects,
        props: (route) => ({
          dependencies: projectDependencies(route.params.projectId as string),
          fallback: { name: RouteName.Projects },
        }),
        children: [
          createSubAppRoutes(RouteName.ProjectDetails, [
            {
              path: '',
              name: RouteName.ProjectDetails,
              component: () =>
                import('@/components/design/ProjectDetailsPage.vue'),
              props: true,
              meta: {
                title: 'Project homepage',
              },
            },
          ]),
          createSubAppRoutes(RouteName.Revisions, [
            {
              path: 'revisions',
              name: RouteName.Revisions,
              component: () =>
                import('@/components/revisions/revisionList.vue'),
              props: true,
              meta: {
                title: 'Revisions',
              },
            },
          ]),
          createSubAppRoutes(RouteName.Dashboard, [
            {
              path: 'dashboard',
              name: RouteName.Dashboard,
              component: () =>
                import('@/components/design/dashboard/dashboard.vue'),
              props: true,
              meta: {
                title: 'Dashboard',
              },
              beforeEnter: (to, from, next) => {
                if (to.params.contextId) {
                  next({
                    ...to,
                    params: { ...to.params, contextId: undefined },
                  });
                } else {
                  next();
                }
              },
            },
          ]),
          createSubAppRoutes(RouteName.EventLog, [
            {
              path: 'event-log',
              name: RouteName.EventLog,
              component: () =>
                import('@/components/_strict/eventLog/eventLog.vue'),
              props: true,
              meta: {
                title: 'Event Log',
              },
            },
          ]),
          createSubAppRoutes(RouteName.Owner, [
            {
              path: 'owner',
              name: RouteName.Owner,
              component: () =>
                import(
                  featureFlags.isNewOwnerPageEnabled
                    ? '@/components/_strict/owner/ownersPage.vue'
                    : '@/components/ownership/ownersPage.vue'
                ),
              props: true,
              meta: {
                title: 'Owner',
              },
            },
          ]),
          createSubAppRoutes(RouteName.OwnerGroups, [
            {
              path: 'owner-groups',
              name: RouteName.OwnerGroups,
              component: () =>
                import(
                  featureFlags.isNewOwnerPageEnabled
                    ? '@/components/_strict/owner/ownerGroupsPage.vue'
                    : '@/components/ownership/ownerGroupsPage.vue'
                ),
              props: true,
              meta: {
                title: 'Owner Groups',
              },
            },
          ]),
        ],
      },
      {
        path: ':projectId/:contextId',
        component: SupplyChain,
        redirect: RouteName.Projects,
        props: (route) => ({
          dependencies: [
            ...projectDependencies(route.params.projectId as string),
            ...contextsDependencies([route.params.contextId as string]),
          ],
          fallback: { name: RouteName.Projects },
        }),
        children: [
          createSubAppRoutes(RouteName.Core, [
            {
              path: 'core',
              name: RouteName.Core,
              component: () => import('@/components/design/core/core.vue'),
              props: (route) => ({
                ...route.params,
                contextIds: [route.params.contextId],
              }),
              meta: {
                title: 'CORE',
              },
            },
            {
              path: 'core/list',
              name: RouteName.CoreList,
              component: () =>
                import('@/components/design/core/coreListView.vue'),
              props: true,
              meta: {
                title: 'CORE',
              },
            },
          ]),
          createSubAppRoutes(RouteName.Simo, [
            {
              path: 'simo',
              name: RouteName.Simo,
              component: () => import('@/components/design/simo/simo.vue'),
              props: true,
              meta: {
                title: 'SIMO',
              },
            },
            {
              path: 'simo/tradeStudy',
              name: RouteName.SimoTradeStudy,
              component: () =>
                import('@/components/tradeStudy/compareDeltas.vue'),
              props: true,
              meta: {
                title: 'Trade Study',
              },
            },
          ]),
        ],
      },
      {
        path: ':projectId/:contextId',
        component: SupplyChain,
        redirect: RouteName.Projects,
        props: (route) => ({
          dependencies: [
            ...revisionDependencies({
              revisionId: route.params.revisionId as string,
              nextRevisionId: route.params.nextRevisionId as string,
              baseProjectId: route.params.projectId as string,
              baseContextIds: [route.params.contextId as string],
            }),
          ],
          fallback: { name: RouteName.Projects },
        }),
        children: [
          createSubAppRoutes(RouteName.RevisionCore, [
            {
              path: 'core/rev/:revisionId/:nextRevisionId?',
              name: RouteName.RevisionCore,
              component: () =>
                import('@/components/design/core/revisionCore.vue'),
              props: (route) => ({
                baseProjectId: route.params.projectId,
                baseContextIds: [route.params.contextId],
                revisionId: route.params.revisionId,
                nextRevisionId: route.params.nextRevisionId,
              }),
              meta: {
                title: 'CORE - revision',
              },
            },
          ]),
          createSubAppRoutes(RouteName.RevisionSimo, [
            {
              path: 'simo/rev/:revisionId/:nextRevisionId?',
              name: RouteName.RevisionSimo,
              component: () =>
                import('@/components/design/simo/revisionSimo.vue'),
              props: (route) => ({
                baseProjectId: route.params.projectId,
                baseContextId: route.params.contextId,
                revisionId: route.params.revisionId,
                nextRevisionId: route.params.nextRevisionId,
              }),
              meta: {
                title: 'SIMO - revision',
              },
            },
          ]),
        ],
      },
      {
        path: ':projectId/:contextId/:additionalContextIds+/core/rev/:revisionId/:nextRevisionId?',
        component: SupplyChain,
        redirect: RouteName.Projects,
        props: (route) => {
          const baseContextIds = [
            route.params.contextId as string,
            ...route.params.additionalContextIds,
          ];
          return {
            dependencies: revisionDependencies({
              revisionId: route.params.revisionId as string,
              nextRevisionId: route.params.nextRevisionId as string,
              baseProjectId: route.params.projectId as string,
              baseContextIds,
            }),
            fallback: { name: RouteName.Projects },
          };
        },
        children: [
          createSubAppRoutes(RouteName.RevisionCore, [
            {
              path: '',
              name: RouteName.RevisionCoreSplitView,
              component: () =>
                import('@/components/design/core/revisionCore.vue'),
              props: (route) => {
                return {
                  ...route.params,
                  baseProjectId: route.params.projectId,
                  baseContextIds: [
                    route.params.contextId,
                    ...route.params.additionalContextIds,
                  ],
                };
              },
              meta: {
                title: 'CORE - revision',
              },
            },
          ]),
        ],
      },
      {
        path: ':projectId/:contextId/:additionalContextIds+/core',
        component: SupplyChain,
        redirect: RouteName.Projects,
        props: (route) => {
          const contextIds = [
            route.params.contextId,
            ...(route.params.additionalContextIds as string[]),
          ] as string[];
          return {
            dependencies: [
              ...projectDependencies(route.params.projectId as string, {
                optional: true,
              }),
              ...contextsDependencies(contextIds),
            ],
            fallback: { name: RouteName.Projects },
          };
        },
        children: [
          createSubAppRoutes(RouteName.Core, [
            {
              path: '',
              name: RouteName.CoreSplitView,
              component: () => import('@/components/design/core/core.vue'),
              props: (route) => ({
                ...route.params,
                contextIds: [
                  route.params.contextId,
                  ...route.params.additionalContextIds,
                ],
              }),
              meta: {
                title: 'CORE',
              },
            },
          ]),
        ],
      },
    ],
  }),
];

export default projectsRoutes;
