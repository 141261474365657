import { resources } from '@/services/resources';
import { createModule } from '../utils/factories';
import crud from '../components/crud';
import indexManager from '../components/indexManager';

export default createModule({
  path: 'colors',
  resourceProfile: resources.userColor,
  components: [crud, indexManager],
  modules: [],
  setup({ components }) {
    return {
      module: {},
      protected: {},
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
      },
    };
  },
});
