import { createModule } from '../utils/factories';
import { resources, ResourceProfile } from '@/services/resources';
import crud from '../components/crud';
import jv from '../components/jv';
import relationManager from '../components/relationManager';
import indexManager from '../components/indexManager';
import { JsonApiIdentification } from '@/models/api';

type State = Record<string, never>;

export default createModule({
  path: 'tags',
  resourceProfile: resources.tags,
  components: [jv, crud, relationManager, indexManager],
  setup({ getAccessors, components, resourceProfile }) {
    const mutations = {
      updateRelation(
        state: State,
        payload: {
          targetType: string;
          targetId: string;
          tagId: string;
          remove?: boolean;
        }
      ) {
        const { targetType, targetId, tagId, remove } = payload;
        const $jv = components.$jv.protected;
        const targetResource = $jv.get({ id: targetId, type: targetType });
        const updatedResource = {
          ...targetResource,
          _jv: {
            ...targetResource._jv,
            relationships: {
              ...targetResource._jv.relationships,
              tags: {
                data: remove
                  ? (
                      targetResource._jv.relationships.tags
                        .data as JsonApiIdentification[]
                    ).filter((t) => t.id !== tagId)
                  : [
                      ...(targetResource._jv.relationships.tags
                        .data as JsonApiIdentification[]),
                      { id: tagId, type: resourceProfile.type },
                    ],
              },
            },
          },
        };
        $jv.commitReplaceRecords(updatedResource);
      },
    };

    const getters = {
      getRelatedTags:
        () =>
        (
          targetType: string,
          targetId: string
        ): { id: string; value: string }[] => {
          const tagsSet = components.$jv.protected.getRelated(
            `${targetType}/${targetId}`
          )[resourceProfile.path];
          if (!tagsSet) return [];
          return Object.keys(tagsSet)
            .filter((t) => !!tagsSet[t].value)
            .map((t) => {
              return { value: tagsSet[t].value, id: tagsSet[t]._jv.id };
            });
        },
    };

    const actions = {
      async createResource(
        context,
        payload: Parameters<
          typeof components.$crud.public.dispatchCreateResource
        >[0]
      ) {
        const { relationshipKey, relationshipId } = payload;
        const { id } =
          await components.$crud.public.dispatchCreateResource(payload);
        commit(mutations.updateRelation)({
          targetType: relationshipKey,
          targetId: relationshipId,
          tagId: id,
        });
      },
      async addTag(
        context,
        payload: Parameters<
          typeof components.$relationManager.public.dispatchCreateRelationship
        >[0]
      ) {
        const { relationshipKey, relationshipId, resourceId } = payload;
        await components.$relationManager.public.dispatchCreateRelationship(
          payload
        );
        commit(mutations.updateRelation)({
          targetType: relationshipKey,
          targetId: relationshipId,
          tagId: resourceId,
        });
      },
      async removeTag(
        context,
        payload: { targetResource: ResourceProfile; targetId; tagId }
      ) {
        const { targetResource, targetId, tagId } = payload;
        await components.$relationManager.public.dispatchDeleteRelationship({
          resourceId: targetId,
          targetPath: targetResource.path,
          sourceId: tagId,
        });
        commit(mutations.updateRelation)({
          targetType: targetResource.type,
          targetId,
          tagId,
          remove: true,
        });
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        mutations,
        getters,
        actions,
      },
      public: {
        ...components.$indexManager.public,
        dispatchCreateTag: dispatch(actions.createResource),
        dispatchAddTag: dispatch(actions.addTag),
        dispatchRemoveTag: dispatch(actions.removeTag),

        getRelatedTags: read(getters.getRelatedTags),
      },
    };
  },
});
