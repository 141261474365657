<template>
  <div class="projects-page">
    <ResourceTable
      ref="resourceTable"
      :resourceConfig="resourceConfig"
      :customColumns="customColumns"
      :selectedItemId="panelProjectId"
      @click:item="onItemClick"
      @update:items="onItemsChange"
    >
      <ResourceTableSearch v-model="searchQuery" />

      <ResourceTablePanel :show="!!panelProject" @close="cancelEdit">
        <template v-if="panelProject">
          <ProjectsProjectForm
            v-if="panelProject.type === 'project'"
            :key="panelProjectId || 'new'"
            :projectId="panelProject.isNew === true ? null : panelProject.id"
            :hideAddContextButton="!showAddButtons"
            :projectNamesList="projectsNames"
            @cancel="cancelEdit"
            @created="onProjectCreated({ id: $event })"
            @duplicate="
              showDuplicateProjectPanel({ projectId: panelProjectId })
            "
            @archive="onArchive"
          />
          <ProjectsProjectDuplicateForm
            v-if="panelProject.type === 'project-duplicate'"
            :key="panelProject.id"
            :projectId="panelProject.id"
            :projectNamesList="projectsNames"
            @cancel="cancelEdit"
            @created="onProjectCreated({ id: $event })"
          />
        </template>
      </ResourceTablePanel>

      <template #cursor="{ attrs, on }">
        <ResourceTableCursor v-bind="attrs" v-on="on" />
      </template>

      <template #body-prepend-row="{ columns }">
        <ResourceTableRow v-if="showAddButtons && userCanCreateProject">
          <td :colspan="columns.length">
            <v-btn color="primary" size="small" @click.prevent="onNewProject">
              <v-icon start>mdi-plus</v-icon>
              Add Project
            </v-btn>
          </td>
        </ResourceTableRow>
      </template>

      <template #attribute-name="{ item }">
        <span class="app-link app-lin--import-progress">
          {{ item.name }}
          <v-tooltip
            location="right"
            v-if="hasProjectImportInProgress(item.id)"
          >
            <span>Import in progress</span>
            <template #activator="{ props }">
              <v-progress-circular
                v-bind="props"
                indeterminate
                width="3"
                size="16"
                color="rgb(var(--v-theme-primary))"
              />
            </template>
          </v-tooltip>
        </span>
      </template>
      <template #attribute-revisions="{ item }">
        <span>
          {{ $tc('projectRevisions.nProjectRevisions', item.revisions_count) }}
        </span>
      </template>
      <template #attribute-role="{ item }">
        <RoleDisplayer :projectID="item.id" />
      </template>

      <template #attribute-meta="{ item }">
        <v-chip
          :class="{ 'background--highlighted': !item.archived }"
          :color="item.archived ? undefined : 'primary'"
        >
          {{ item.archived ? 'Archived' : 'Active' }}
        </v-chip>
      </template>

      <template #attribute-actions="{ item }">
        <TableManagerRowButton
          :label="canEditProject() ? 'Edit details' : 'Show details'"
          :icon="canEditProject() ? 'mdi-pencil-outline' : 'mdi-eye-outline'"
          variant="'text'"
          @click="showDetailsPanel({ item })"
        />
      </template>
      <template #body-append-row v-if="canSeeArchivedProjects">
        <ResourceTableRow
          @click="toggleArchived"
          :class="{ 'projects-page__archived-row--active': showArchived }"
        >
          <td :colspan="customColumns.length">
            <div class="projects-page__archived-row">
              <v-icon size="small">
                {{ showArchived ? 'mdi-chevron-up' : 'mdi-chevron-right' }}
              </v-icon>
              <span>{{
                showArchived
                  ? 'Hide archived projects'
                  : 'Show archived projects'
              }}</span>
            </div>
          </td>
        </ResourceTableRow>
      </template>
    </ResourceTable>

    <ProjectsPageArchived
      :columns="customColumns"
      :query="searchQuery"
      :selectedItemId="panelProjectId"
      :requestType="archiveRequestType"
      @click:item="onItemClick"
      v-if="showArchived"
    >
      <template #attribute-actions="{ item }">
        <TableManagerRowButton
          :label="canEditProject() ? 'Edit details' : 'Show details'"
          :icon="canEditProject() ? 'mdi-pencil-outline' : 'mdi-eye-outline'"
          variant="'text'"
          @click="showDetailsPanel({ item })"
        />
      </template>
    </ProjectsPageArchived>
  </div>
</template>

<script lang="ts">
import { Options, Ref, Vue } from 'vue-property-decorator';
import ResourceTable from '@/components/resourceTable/resourceTable.vue';
import ResourceTableRow from '@/components/resourceTable/resourceTableRow.vue';
import ResourceTableSearch from '@/components/resourceTable/resourceTableSearch.vue';
import ResourceTableCursor from '@/components/resourceTable/resourceTableCursor.vue';
import ResourceTablePanel from '@/components/resourceTable/resourceTablePanel.vue';
import ProjectsContextForm from '@/components/design/projectsContextForm.vue';
import ProjectsContextDuplicateForm from '@/components/design/projectsContextDuplicateForm.vue';
import ProjectsProjectForm from '@/components/design/projectsProjectForm.vue';
import ProjectsProjectDuplicateForm from '@/components/design/projectsProjectDuplicateForm.vue';
import TableManagerRowButton from '@/components/tableManager/tableManagerRowButton.vue';
import {
  actionsColumn,
  defaultColumns,
  ResourceConfig,
  TableColumnDefinition,
} from '@/utils/resourceTable';
import { Project } from '@/models/project';
import { RouteName } from '@/enums/routing';
import ProjectsPageArchived from './projectsPageArchived.vue';
import { getIndexRequestIdentifier } from '@/utils/path';
import RoleDisplayer from '@/components/admin/users/roleDisplayer.vue';

type PanelProject =
  | ({ type: 'project' } & ({ isNew: true } | { isNew: false; id: string }))
  | { type: 'project-duplicate'; id: string };

@Options({
  components: {
    ProjectsContextForm,
    ProjectsContextDuplicateForm,
    ProjectsProjectForm,
    ProjectsProjectDuplicateForm,
    ResourceTable,
    ResourceTableRow,
    ResourceTableSearch,
    ResourceTableCursor,
    ResourceTablePanel,
    TableManagerRowButton,
    ProjectsPageArchived,
    RoleDisplayer,
  },
})
export default class ProjectsPage extends Vue {
  @Ref('resourceTable') readonly resourceTableRef: ResourceTable;

  searchQuery = '';
  panelProject: PanelProject | null = null;
  showArchived = false;
  projectsNames: string[] = [];

  toggleArchived() {
    this.showArchived = !this.showArchived;
  }

  onItemClick(projectId: string) {
    this.$router.push({
      name: RouteName.ProjectDetails,
      params: {
        projectId,
      },
    });
  }

  get RouteName() {
    return RouteName;
  }
  get archiveRequestType(): string {
    return 'projects-archive';
  }
  get panelProjectId(): string | null {
    if (!this.panelProject || !('id' in this.panelProject)) return null;
    return this.panelProject.id;
  }
  get resourceConfig(): ResourceConfig {
    return {
      module: this.$store.$direct.project,
      pathParameters: this.pathParameters,
    };
  }
  get customColumns(): TableColumnDefinition[] {
    return [
      {
        ...defaultColumns.name,
        tdStyle: { width: '20%' },
      },
      {
        header: 'revisions',
        attribute: 'revisions',
        sortable: false,
        tdStyle: { width: '160px' },
      },
      {
        header: 'role',
        attribute: 'role',
        sortable: false,
        tdStyle: { width: '160px' },
      },
      {
        header: '',
        attribute: 'meta',
        sortable: false,
        tdStyle: { width: '160px' },
      },
      actionsColumn,
    ];
  }
  get filters() {
    return this.$store.$direct.project.getFilters();
  }
  get showAddButtons() {
    return !this.filters.showArchivedResources;
  }
  get userCanCreateProject() {
    return this.$store.$direct.userPermission.canCreateProject();
  }
  get canSeeArchivedProjects() {
    return this.$store.$direct.userPermission.canSeeArchivedProjects();
  }

  get pathParameters() {
    return {
      query: this.searchQuery,
      filterArchived: false,
    };
  }

  hasProjectImportInProgress(projectId: string) {
    const imports = this.$store.$direct.importFile.getPaginatedResource(
      'default',
      `projects-page-${projectId}`
    );
    return imports.some(({ processing }) => processing);
  }

  canEditProject() {
    return this.$store.$direct.userPermission.canWriteProject();
  }

  showDetailsPanel(payload: { item: Project }) {
    this.panelProject = {
      type: 'project',
      isNew: false,
      id: payload.item.id,
    };
  }
  showDuplicateProjectPanel(payload: { projectId: string }) {
    this.panelProject = {
      type: 'project-duplicate',
      id: payload.projectId,
    };
  }
  onNewProject() {
    if (!this.userCanCreateProject) return;
    this.panelProject = {
      type: 'project',
      isNew: true,
    };
  }
  onProjectCreated({ id }) {
    this.$store.$direct.project.dispatchInjectToPageIndex({ resourceId: id });
    this.panelProject = {
      type: 'project',
      isNew: false,
      id,
    };
  }

  cancelEdit() {
    this.panelProject = null;
  }

  updateNotAllowedProjectNames(projects: Project[]) {
    this.projectsNames = projects.map(({ name }) => name.toLowerCase());
  }

  onItemsChange(projects: Project[]) {
    this.updateNotAllowedProjectNames(projects);
  }

  onArchive({ projectId, archived }: { projectId: string; archived: boolean }) {
    this.$store.$direct.project.dispatchRemoveFromPageIndex({
      resourceId: projectId,
      requestType: !archived ? this.archiveRequestType : 'default',
      requestId: getIndexRequestIdentifier({
        query: this.searchQuery,
        filterArchivedOrHasArchivedContent: !archived || undefined,
        filterArchived: !archived,
      }),
    });
    this.$store.$direct.project.dispatchInjectToPageIndex({
      resourceId: projectId,
      requestType: archived ? this.archiveRequestType : 'default',
      requestId: getIndexRequestIdentifier({
        query: this.searchQuery,
        filterArchivedOrHasArchivedContent: archived || undefined,
        filterArchived: archived,
      }),
    });
  }
}
</script>

<style lang="scss" scoped>
.projects-page {
  overflow-y: auto;
  height: 100%;
}
.projects-page .main-panel {
  height: fit-content;
}
.projects__link--disabled {
  pointer-events: none;
}

.hover-details {
  opacity: 0;

  tr:focus-within &,
  tr:hover & {
    opacity: 1;
  }
}
.app-lin--import-progress {
  display: flex;
  align-items: center;
  gap: 8px;
}
.projects-page__archived-row {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgb(var(--v-theme-primary));
  text-transform: uppercase;
  span {
    font-size: 12px;
    font-weight: 500;
  }
}
.projects-page__archived-row--active {
  background-color: rgb(var(--v-theme-background));
}
</style>
