<template>
  <PageTransition>
    <div>
      <v-overlay
        scrim="rgb(var(--v-theme-background))"
        v-if="!ready"
        :modelValue="!accountLoaded"
        class="align-center justify-center"
        persistent
      >
        <transition name="fade" mode="out-in">
          <v-progress-circular
            v-if="!errorMessage"
            data-spec="loader"
            indeterminate
            size="64"
            color="rgb(var(--v-theme-color))"
          />
          <div v-else class="error-wrapper">
            <div>
              <v-icon
                class="error__icon"
                color="rgb(var(--v-theme-color))"
                size="128"
              >
                mdi-alert-circle-outline
              </v-icon>
            </div>
            <div>{{ $t('errors.appLoading') }}</div>
            <div class="error__message">{{ errorMessage }}</div>
            <button class="error__reload app-link" @click="reloadPage">
              {{ $t('general.reloadPage') }}
            </button>
          </div>
        </transition>
      </v-overlay>
      <slot v-else :ready="ready" />
    </div>
  </PageTransition>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useDirectStore } from '@/composables/store';
import { composeMessage } from '@/services/errorHandler';
import PageTransition from '@/components/pageTransition.vue';
import {
  refresh,
  removeRefreshPerformed,
  setRefreshPerformed,
  wasRefreshPerformed,
} from '@/auth';

const store = useDirectStore();
const route = useRoute();

const errorMessage = ref('');
const sourcesLoaded = ref(false);

const accountLoaded = computed(() => store.account.getLoaded());
const accountRequired = computed(() =>
  route.matched.some((record) => record.meta.requiresAuth)
);

const accountReady = computed(
  () => !accountRequired.value || accountLoaded.value
);

const ready = computed(() => accountReady.value && sourcesLoaded.value);

watch(
  accountReady,
  (isReady) => {
    if (!isReady) {
      store.account
        .dispatchLoadAccount()
        .then(() => {
          removeRefreshPerformed();
        })
        .catch((error) => {
          errorMessage.value = composeMessage(error);
          if (!wasRefreshPerformed()) {
            setRefreshPerformed();
            reloadPage();
          }
        });
    }
  },
  { immediate: true }
);

watch(
  accountLoaded,
  (loaded) => {
    if (loaded) {
      loadSharedData();
      store.account.dispatchCheckTokenTime();
    }
  },
  { immediate: true }
);

const loadSharedData = () => {
  store.progressModel.dispatchLoadFullResource({});
};

const reloadPage = async () => {
  await refresh();
  window.location.reload();
};

onMounted(() => {
  window.addEventListener('load', () => (sourcesLoaded.value = true));
});

onBeforeUnmount(() => store.account.dispatchClearTokenInterval());
</script>

<style lang="scss" scoped>
.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(var(--v-theme-color));
  line-height: 1.4;
}
.error__icon {
  opacity: 0.15;
  margin-bottom: $space-regular;
}
.error__message {
  margin-top: $space-x-small;
  opacity: 0.5;
  font-size: 85%;
}
.error__reload {
  padding: 10px;
  font-weight: normal;
  font-size: 85%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
