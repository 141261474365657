import { computed, ref } from 'vue';
import { ApplicationType } from '@/enums/applicationType';
import { useRoute, useRouter } from 'vue-router';
import { SubApplication, SubApplicationAccess } from '@/models/subApplication';
import {
  getApplicationByType,
  getSubApplicationByCurrentRoute,
} from '@/services/applicationsConfig';
import { Application } from '@/models/application';
import { useDirectStore } from './store';
import { useTheme } from 'vuetify';

const applicationType = ref<ApplicationType>();

export const useApplication = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useDirectStore();
  const vuetifyTheme = useTheme();

  const application = computed<Application>(() =>
    getApplicationByType(applicationType.value)
  );

  const subApplication = computed<SubApplication>(() =>
    getSubApplicationByCurrentRoute(application.value, route)
  );

  const subApplicationAccess = computed<SubApplicationAccess>(() => {
    if (application.value.checkPermissions) {
      const hasAccess = application.value.checkPermissions();
      if (!hasAccess) return { access: false };
    }
    if (subApplication.value?.checkPermissions) {
      const response = subApplication.value.checkPermissions(route);
      if (!response.access) return response;
    }
    return { access: true };
  });

  const setApplicationType = (type: ApplicationType) =>
    (applicationType.value = type);

  const redirectToHomepage = (message: string) => {
    router.replace('/');
    store.notifications.dispatchNotify({
      message: message || 'You have no access to this page',
      type: 'error',
    });
  };

  const changeColorPallet = () => {
    if (subApplicationAccess.value.access !== true) {
      redirectToHomepage(subApplicationAccess.value.message);
    }
    vuetifyTheme.global.name.value = application.value.color;
  };

  return {
    application,
    subApplication,
    subApplicationAccess,
    setApplicationType,
    changeColorPallet,
    redirectToHomepage,
  };
};
