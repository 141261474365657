<template>
  <AppToolBannerSearch ref="toolbarSearch" :active="!!modelValue">
    <SidebarList class="pa-2">
      <SidebarListGroup>
        <SidebarListGroupSection>
          <v-text-field
            v-model="searchQuery"
            label="Search"
            variant="outlined"
            autofocus
            flat
            hide-details
            single-line
            clearable
            clear-icon="mdi-close"
            append-inner-icon="mdi-magnify"
            @keydown.enter="submit"
            @click:append="submit"
            @click:clear="clear"
            bg-color="rgb(var(--v-theme-cardBackground))"
          />
        </SidebarListGroupSection>
        <v-list-item class="pb-4">
          <div class="text-right">
            <v-btn
              data-spec="apply-search-button"
              color="primary"
              @click="submit"
            >
              Apply
            </v-btn>
          </div>
        </v-list-item>
      </SidebarListGroup>
    </SidebarList>
  </AppToolBannerSearch>
</template>

<script lang="ts">
import { Options, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import AppToolBannerSearch from '@/components/toolbanner/appToolBannerSearch.vue';
import SidebarList from '@/components/sidebar/sidebarList.vue';
import SidebarListGroup from '@/components/sidebar/sidebarListGroup.vue';
import SidebarListGroupSection from '@/components/sidebar/sidebarListGroupSection.vue';

@Options({
  components: {
    AppToolBannerSearch,
    SidebarList,
    SidebarListGroup,
    SidebarListGroupSection,
  },
  emits: ['update:modelValue'],
})
export default class ResourceTableSearch extends Vue {
  @Prop(String) modelValue: string;
  @Ref() readonly toolbarSearch: AppToolBannerSearch;

  searchQuery = '';

  submit() {
    this.$emit('update:modelValue', this.searchQuery);
    this.close();
  }

  clear() {
    this.$emit('update:modelValue', '');
    this.close();
  }

  close() {
    this.toolbarSearch?.close();
  }

  @Watch('modelValue', { immediate: true })
  onActiveQueryChange(value: string) {
    this.searchQuery = value;
  }
}
</script>
