import type { Directive, DirectiveBinding, ObjectDirective } from 'vue';

interface EllipsisOptions {
  lines?: number;
  lineHeight?: number;
  showTooltip?: boolean;
}

interface ExtendedHTMLElement extends HTMLElement {
  _observer?: ResizeObserver;
}

export const vEllipsis: Directive<ExtendedHTMLElement, EllipsisOptions> = {
  mounted(
    el: ExtendedHTMLElement,
    binding: DirectiveBinding<EllipsisOptions>
  ): void {
    const options: Required<EllipsisOptions> = {
      lines: binding.value?.lines ?? 1,
      lineHeight:
        binding.value?.lineHeight ?? parseInt(getComputedStyle(el).lineHeight),
      showTooltip: binding.value?.showTooltip ?? true,
    };

    const styles: Partial<CSSStyleDeclaration> = {
      display: '-webkit-box',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: options.lines.toString(),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'break-word',
      maxHeight: `${options.lineHeight * options.lines}px`,
    };

    Object.assign(el.style, styles);

    const originalText: string = el.textContent || '';

    // Add tooltip if text is truncated
    const observer = new ResizeObserver(() => {
      const isTruncated: boolean = el.scrollHeight > el.clientHeight;

      if (isTruncated && options.showTooltip) {
        el.title = originalText;
      } else {
        el.removeAttribute('title');
      }
    });

    observer.observe(el);

    // Store observer for cleanup
    el._observer = observer;
  },

  unmounted(el: ExtendedHTMLElement): void {
    el._observer?.disconnect();
  },
};

// Optional: import this composable for programmatic usage
export function useEllipsis() {
  function applyEllipsis(
    element: HTMLElement,
    options: EllipsisOptions = {}
  ): void {
    const directiveBinding: DirectiveBinding<EllipsisOptions> = {
      value: options,
      oldValue: null,
      arg: '',
      modifiers: {},
      instance: null,
      dir: vEllipsis as ObjectDirective<any, EllipsisOptions>,
    };

    (vEllipsis as any).mounted(
      element as ExtendedHTMLElement,
      directiveBinding
    );
  }

  function removeEllipsis(element: HTMLElement): void {
    (vEllipsis as any).unmounted(element as ExtendedHTMLElement);
  }

  return {
    applyEllipsis,
    removeEllipsis,
  };
}
