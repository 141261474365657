import { createI18n } from 'vue-i18n';
import en from './en';

const i18n = createI18n({
  locale: 'en',
  allowComposition: true,
  messages: { en },
  silentTranslationWarn: true,
});

const translate = (
  key: string,
  item: Record<string, unknown> | null = null
): string => {
  if (!key) {
    return '';
  }
  if (item) return i18n.global.t(key, item) as string;
  else return i18n.global.t(key) as string;
};

const translationExists = (key: string): boolean => {
  if (!key) {
    return false;
  }

  return i18n.global.te(key);
};

export { i18n, translate, translationExists };
