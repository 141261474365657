import { createModule } from '../utils/factories';
import permission from '../modules/permission';
import userPermission from '../modules/userPermission';
import { api } from '@/services/api';
import { JsonApiResource } from '@/models/api';
import { getAccessToken, getExpirationTime, refresh } from '@/auth';

interface State {
  loaded: boolean;
  tokenIntervalId: string;
}

export default createModule({
  path: 'account',
  modules: [permission, userPermission],
  setup({ modules, getAccessors }) {
    const state = (): State => ({
      loaded: false,
      tokenIntervalId: typeof setTimeout,
    });

    const getters = {
      loaded: (state: State) => state.loaded,
      feVersionData: () => {
        const version = (import.meta.env.VUE_APP_VERSION || '').trim();
        return {
          version,
          changelog_link: version
            ? `https://sec-hub-api-changelog.s3-eu-west-1.amazonaws.com/CHANGELOG-fe-${version}.md`
            : '#',
        };
      },
    };

    const mutations = {
      setAccountLoaded(state: State, payload: { loaded: boolean }) {
        state.loaded = payload.loaded;
      },
      setTokenIntervalId(state: State, payload: { intervalId }) {
        state.tokenIntervalId = payload.intervalId;
      },
      clearTokenInterval(state: State) {
        clearTimeout(+state.tokenIntervalId);
        state.tokenIntervalId = null;
      },
    };

    const actions = {
      async loadAccount() {
        if (read(getters.loaded)()) {
          return Promise.resolve();
        }
        return Promise.all([
          modules.userPermission.public.dispatchLoadCurrentUserPermissions(),
          modules.permission.public.dispatchLoadFullResource(),
        ]).then(() => commit(mutations.setAccountLoaded)({ loaded: true }));
      },

      async loadIntercomData() {
        const { data } = await api.get<{ data: JsonApiResource }>(
          `/utils/intercom/hmac`
        );
        return data.data;
      },
      async checkTokenTime() {
        if (!read(getters.loaded)) return;

        const date = new Date(0);
        // 1 minute before expiration time to have some space for token refresh
        date.setUTCSeconds(getExpirationTime() - 60);
        if (date < new Date()) {
          await refresh();
          const token = getAccessToken();
          api.defaults.headers['Authorization'] = `Bearer ${token}`;
        }

        commit(mutations.setTokenIntervalId)({
          intervalId: setTimeout(dispatch(actions.checkTokenTime), 5000),
        });
      },
      clearTokenInterval() {
        commit(mutations.clearTokenInterval);
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        getLoaded: read(getters.loaded),
        getAppVersionData: read(getters.feVersionData),
        dispatchLoadAccount: dispatch(actions.loadAccount),
        dispatchLoadIntercomData: dispatch(actions.loadIntercomData),
        dispatchCheckTokenTime: dispatch(actions.checkTokenTime),
        dispatchClearTokenInterval: dispatch(actions.clearTokenInterval),
      },
    };
  },
});
