<template>
  <ApplicationPage :hideSubApplicationsTabs="true">
    <div class="main-content_layout">
      <div
        :class="
          isScreenSmall
            ? 'action-section-wrapper-small'
            : 'action-section-wrapper-full-size'
        "
      >
        <div v-if="!isScreenSmall" class="logo-image-container">
          <img class="logo-image" :src="SECHubLogo" />
        </div>
        <div v-else></div>
        <div :class="{ 'recent-work-component_wrapper': isScreenSmall }">
          <template v-if="recentWorkURLs.length > 0">
            <RecentWorkSlider
              class="recent-work-component"
              :recentWorkURLs="recentWorkURLs"
            ></RecentWorkSlider>
          </template>
          <template v-else>
            <div class="recent-work__welcome-placeholder">
              <div class="text-h2 font-weight-light text-center pb-5">
                {{ $t('apps.launchpad.sections.welcome') }}
              </div>
              <v-btn
                color="secondary"
                :to="{ name: projectApplication.route }"
                min-height="120"
                min-width="240"
              >
                <div class="start-button__title">
                  {{ $t('apps.launchpad.buttonsText.start') }}
                </div>
              </v-btn>
            </div>
          </template>
        </div>
        <div v-if="recentWorkURLs.length > 0" class="project-button_wrapper">
          <v-btn
            color="secondary"
            :to="{ name: projectApplication.route }"
            :class="isScreenSmall ? 'circle-button' : 'project-button'"
          >
            <div v-show="isScreenSmall !== true" class="button__title">
              {{ projectApplication.name }}
            </div>
            <v-icon class="button__logo" size="40">{{
              projectApplication.icon
            }}</v-icon>
          </v-btn>
        </div>
      </div>

      <div
        :class="
          isScreenSmall
            ? 'dashboard-section-small-size'
            : 'dashboard-section-full-size'
        "
      >
        <LaunchpadDashboard :projects="projects" />
      </div>
    </div>
  </ApplicationPage>
</template>

<script lang="ts" setup>
import { getApplications } from '@/services/applicationsConfig';
import { ApplicationType } from '@/enums/applicationType';
import { useLastUsedStore } from '@/services/lastUsedLocalStore';
import { getIDsForRecentWorkCards } from '@/auth';
import { RecentWorkURL } from '@/models/recentWorkURL';
import { RECENT_WORK_STORAGE_KEY } from '@/utils/createRecentWorkURL';
import { computed, onBeforeMount, onMounted, ref, onBeforeUnmount } from 'vue';
import { useApplication } from '@/composables/application';
import { useDirectStore } from '@/composables/store';
import RecentWorkSlider from '@/views/launchpad/recentWorkSlider.vue';
import LaunchpadDashboard from '@/views/launchpad/launchpadDashboard.vue';
import ApplicationPage from '@/views/applicationPage.vue';
import SECHubLogo from '@/assets/SEC-Hub-logo.svg';

const store = useDirectStore();
const { setApplicationType, changeColorPallet } = useApplication();
const windowWidth = ref(window.innerWidth);
const isScreenSmall = ref(false);

const projectApplication = computed(() => {
  return getApplications().find((app) => {
    return app.type === ApplicationType.Design;
  });
});

const lastUsedUrlInfo = computed(() => {
  return useLastUsedStore<RecentWorkURL>(
    getIDsForRecentWorkCards(),
    RECENT_WORK_STORAGE_KEY,
    3
  );
});

const recentWorkURLs = computed(() => {
  const vwPx = windowWidth.value;
  const cardWidth = 240;
  const cardsAmountCoefficient = isScreenSmall.value ? 0.7 : 0.55;

  const cardsAmount = Math.floor((vwPx * cardsAmountCoefficient) / cardWidth);

  return lastUsedUrlInfo.value.store.slice(0, cardsAmount);
});

const loadProjects = () => {
  store.project.dispatchLoadPaginatedResource({
    requestType: 'launchpad',
    pageInfo: { page: 1, pageSize: 10 },
    sort: '-created_at',
    filterArchived: false,
  });
};
const projects = computed(() => {
  return store.project.getFullResource('launchpad');
});

const checkScreenSize = () => {
  windowWidth.value = window.innerWidth;
  isScreenSmall.value = windowWidth.value < 1650;
};

onBeforeMount(() => {
  setApplicationType(ApplicationType.Launchpad);
  changeColorPallet();
});

onMounted(() => {
  loadProjects();
  checkScreenSize();
  window.addEventListener('resize', checkScreenSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkScreenSize);
});
</script>

<style lang="scss" scoped>
.main-content_layout {
  display: grid;
  grid-template-rows: 2fr 3fr;
  grid-template-columns: 100%;
  justify-content: center;
  height: 100%;
  overflow: auto;
}

.action-section-wrapper-full-size {
  width: 100%;
  justify-content: space-between;
  display: grid;
  align-items: center;
  grid-template-columns: 20% 55% 20%;
  background-color: #ffffff;
}

.action-section-wrapper-small {
  width: 100%;
  justify-content: space-between;
  display: grid;
  align-items: center;
  grid-template-columns: 10% 70% 10%;
  background-color: #ffffff;
}
.logo-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-image {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  align-content: center;
  justify-content: center;
  padding: 30px;
}

.recent-work-component {
  justify-content: center;
}

.recent-work__welcome-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-button_wrapper {
  display: flex;
  flex: 1 0 20%;
  align-items: center;

  .project-button {
    min-width: 200px;
    min-height: 100px;
    transition: all 0.5s ease-out;
  }

  .circle-button {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 0;
    transition: all 0.5s ease-out;
  }
  .button__title {
    font-weight: 400;
    font-size: 25px !important;
    align-items: center;
  }
  .button__logo {
    display: flex;
    justify-self: center;
  }
}

.start-button__title {
  font-weight: 400;
  font-size: 25px !important;
  align-items: center;
}

*.dashboard-section-full-size {
  display: grid;
  grid-template-columns: 55%;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--v-wrapperBackground-base);
}

*.dashboard-section-small-size {
  display: grid;
  grid-template-columns: 70%;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  min-height: 100%;
  background-color: var(--v-wrapperBackground-base);
}
</style>
