import { createModule } from '../utils/factories';
import { createIncludeRelationshipPath, resources } from '@/services/resources';
import crud from '@/store/components/crud';
import jv from '@/store/components/jv';
import { DashboardWidgetStatsItem } from '@/models/dashboardWidget';
import indexManager from '../components/indexManager';

type State = {
  statsLoadedAt: Map<string, Date>;
};

export default createModule({
  path: 'dashboardWidget',
  resourceProfile: resources.dashboardWidget,
  components: [crud, jv, indexManager],
  setup({ components, resourceProfile, getAccessors }) {
    const state = (): State => ({
      statsLoadedAt: new Map(),
    });
    const getters = {
      widgetStatsLoadedAt: (state: State) => (widgetId: string) => {
        return state.statsLoadedAt.get(widgetId);
      },
      widgetStats:
        () =>
        (widgetId: string): DashboardWidgetStatsItem[] => {
          const widgetStats = components.$jv.protected.get(
            'dashboard_stat',
            `$[?(@._jv.relationships.widget.data?.id=="${widgetId}")]`
          );
          return Object.values(widgetStats)[0]?.stats;
        },
      widgetTotal:
        () =>
        (widgetId: string): number | null => {
          const widgetStats = components.$jv.protected.get(
            'dashboard_stat',
            `$[?(@._jv.relationships.widget.data?.id=="${widgetId}")]`
          );
          return Object.values(widgetStats)[0]?.total;
        },
    };
    const mutations = {
      updateStatsLoadedAt(state: State, payload: { widgetId: string }) {
        state.statsLoadedAt.set(payload.widgetId, new Date());
      },
    };
    const actions = {
      async loadWidget(context, payload: { widgetId: string }) {
        await components.$crud.public.dispatchLoadSingleResource({
          resourceId: payload.widgetId,
          include: createIncludeRelationshipPath(resources.dashboardWidget)(
            'context'
          ),
        });
      },
      async loadWidgetStats(context, payload: { widgetId: string }) {
        await components.$crud.public.dispatchLoadSingleResource({
          resourceId: payload.widgetId,
          basePath: `${resourceProfile.path}/${payload.widgetId}/stats`,
        });
        commit(mutations.updateStatsLoadedAt)({ widgetId: payload.widgetId });
      },
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        getWidgetStats: read(getters.widgetStats),
        getWidgetTotal: read(getters.widgetTotal),
        getWidgetStatsLoadedAt: read(getters.widgetStatsLoadedAt),
        dispatchLoadWidget: dispatch(actions.loadWidget),
        dispatchLoadWidgetStats: dispatch(actions.loadWidgetStats),
      },
    };
  },
});
