<template>
  <v-list-group
    v-if="expandable"
    :value="value"
    class="list-group"
    :class="{ 'list-group--highlighted': highlight }"
  >
    <template v-if="header || $slots.header" #activator="{ props }">
      <v-list-item v-bind="props" :value="!collapsed">
        <v-list-subheader
          class="pa-0 list-item__header font-weight-bold text-h6"
        >
          <slot name="header">{{ header }}</slot>
        </v-list-subheader>
      </v-list-item>
    </template>

    <slot />
  </v-list-group>

  <div
    v-else
    class="list-group"
    :class="{ 'list-group--highlighted': highlight }"
    data-spec="list-group"
  >
    <v-list-item v-if="header || $slots.header" data-spec="list-group">
      <v-list-subheader class="list-item__header font-weight-bold text-h6">
        <slot name="header">{{ header }}</slot>
      </v-list-subheader>
    </v-list-item>

    <slot />
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({})
export default class SidebarListGroup extends Vue {
  @Prop({ default: '' }) header: string;
  @Prop(Boolean) highlight: boolean;
  @Prop(Boolean) expandable: boolean;
  @Prop(Boolean) collapsed: boolean;
  @Prop(String) value?: string;
}
</script>

<style lang="scss" scoped>
.list-group {
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgb(var(--v-theme-primary)) !important;
    height: 1px;
    opacity: 0.15;
  }
}
.list-group--highlighted {
  background-color: rgba(var(--v-theme-primary), 0.09);
}
.list-group:first-of-type {
  padding-top: 8px;
}
.list-group :deep(.v-list-group__header) {
  padding-bottom: 0;
}
.list-item__header {
  line-height: 1.2em;
  width: 100%;
  padding: 0;
  padding-inline-start: 0 !important;
  -webkit-padding-start: 0 !important;
  color: rgba(0, 0, 0, 0.87);
  .theme--dark & {
    color: rgba(255, 255, 255, 0.87);
  }
}
:deep(.v-list-group__items .v-list-item) {
  padding-inline-start: 16px !important;
  -webkit-padding-start: 16px !important;
}
</style>
