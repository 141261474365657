interface FeatureFlags {
  readonly isEventLogEnabled: boolean;
  readonly isDeadlineFilterEnabled: boolean;
  readonly isOptionalToolbarEnabled: boolean;
  readonly isNewOwnerPageEnabled: boolean;
}

export const featureFlags: FeatureFlags = {
  isEventLogEnabled: false,
  isDeadlineFilterEnabled: false,
  isOptionalToolbarEnabled: true,
  isNewOwnerPageEnabled: true,
};
