import PrinterCanvas from './PrinterCanvas';

const isAfterPrintEventSupported = 'onafterprint' in window;

export default class PrinterRequest {
  private printPromise: {
    resolve: () => void;
    reject: () => void;
    promise?: Promise<void>;
  } = null;
  private canvas: PrinterCanvas;
  private progress = 0;

  constructor(canvas: PrinterCanvas) {
    this.canvas = canvas;
    this.finish = this.finish.bind(this);
    this.print = this.print.bind(this);
    this.destroy = this.destroy.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.updateInfo();
  }

  public print() {
    if (this.printPromise?.promise) {
      return this.printPromise.promise;
    }
    const promise = new Promise<void>((resolve, reject) => {
      this.printPromise = { resolve, reject };

      if (isAfterPrintEventSupported) {
        window.addEventListener('afterprint', this.finish);
      }

      window.print();

      if (!isAfterPrintEventSupported) {
        this.finish();
      }
    });
    this.printPromise.promise = promise;
    this.updateInfo();
    return promise;
  }

  public destroy() {
    if (isAfterPrintEventSupported) {
      window.removeEventListener('afterprint', this.finish);
    }
    if (this.printPromise) {
      this.printPromise.reject();
    }
    this.canvas.unmount();
  }

  public setProgress(val: number) {
    this.progress = val;
    this.updateInfo();
  }

  private finish() {
    if (this.printPromise) {
      this.printPromise.resolve();
    }
    this.destroy();
  }

  private updateInfo() {
    if (!this.printPromise) {
      const info = `Preparing to print...${
        this.progress ? ` ${(this.progress * 100).toFixed()}%` : ''
      }`;
      this.canvas.setInfo(info);
    } else {
      this.canvas.setInfo('Printing...');
    }
  }
}
