import { App, ref } from 'vue';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    readonly __devtools__: boolean;
  }
}

const isVisible = ref(!!sessionStorage.getItem('sec-dev-tools'));

if (isVisible.value) {
  console.log('> dev tools are ON');
}

export const toggleDevTools = () => {
  isVisible.value = !isVisible.value;
  if (isVisible.value) {
    sessionStorage.setItem('sec-dev-tools', 'on');
    console.log('> dev tools are ON');
  } else {
    sessionStorage.removeItem('sec-dev-tools');
    console.log('> dev tools are OFF');
  }
};

const devtools = {
  install(app: App) {
    app.mixin({
      computed: {
        __devtools__(): boolean {
          return isVisible.value;
        },
      },
    });
  },
};

export default devtools;
