import { ClassificationTable } from './classificationTable';

export interface SyntaxElement {
  id: string;
  name: string;
  hex_color?: string;
  aspect?: string;
  min_number?: number;
  max_number?: number;
  classification_table?: ClassificationTable;
}

export interface SyntaxElementDraft {
  id?: string;
  name: string;
  hex_color?: string;
  aspect?: string | null;
  min_number?: number;
  max_number?: number;
  classification_table_id?: string;
}

export const ranges = [
  {
    label: '1..9',
    min_number: 1,
    max_number: 9,
  },
  {
    label: '01..99',
    min_number: 1,
    max_number: 99,
  },
  {
    label: '001..999',
    min_number: 1,
    max_number: 999,
  },
  {
    label: '1..∞',
    min_number: 1,
    max_number: null,
  },
];

export const aspectWhitelist = ['%', '-', '+', '=', '#'];
