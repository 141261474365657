import { MaybeElementRef, useIntersectionObserver } from '@vueuse/core';
import { ref } from 'vue';

export const useElementIntersectionVisibility = (target: MaybeElementRef) => {
  const targetIsVisible = ref(false);

  useIntersectionObserver(
    target,
    ([{ isIntersecting }]) => {
      targetIsVisible.value = isIntersecting;
    },
    { threshold: 0 }
  );
  return targetIsVisible;
};
