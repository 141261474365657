<template>
  <v-btn
    v-bind="$attrs"
    size="x-small"
    class="mr-1 btn-action"
    :disabled="disabled || loading"
  >
    <span v-if="loading">
      <v-progress-circular
        data-spec="loader"
        indeterminate
        size="14"
        width="3"
        color="grey"
      />
      {{ labelLoading }}
    </span>
    <span v-else>
      <v-icon v-if="icon" class="mr-1">
        {{ icon }}
      </v-icon>
      {{ label }}
    </span>
  </v-btn>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  inheritAttrs: false,
})
export default class TableManagerRowButton extends Vue {
  @Prop({ type: String, default: '' }) label: string;
  @Prop({ type: String, default: '' }) labelLoading: string;
  @Prop({ type: String, default: '' }) icon: string;
  @Prop(Boolean) loading: boolean;
  @Prop(Boolean) disabled: boolean;
}
</script>

<style lang="scss" scoped>
.btn-action .v-icon {
  font-size: 16px;
}
</style>
