<template>
  <v-banner class="app-tool-banner">
    <div class="app-tool-banner__content">
      <div class="app-tool-banner__domain">
        <div
          class="app-tool-banner__domain-multiple"
          v-if="hasContentForSearch"
        >
          <PortalTarget name="app-toolbar-search" multiple />
        </div>
        <div class="app-tool-banner__domain-multiple" v-if="hasCustomTools">
          <PortalTarget name="app-toolbar-main" multiple />
        </div>
        <PortalTarget v-if="hasContentForExport" name="app-toolbar-export" />
        <PortalTarget
          v-if="hasContentForMeetingPlanner"
          name="app-toolbar-meeting-planner"
        />
        <PortalTarget
          v-if="hasContentForListView"
          name="app-toolbar-list-view"
        />
        <PortalTarget name="app-toolbar-chain-analysis" />
      </div>
      <PortalTarget v-if="hasContentForHeatMap" name="app-toolbar-heat-map" />
      <div class="revision-portal-target">
        <PortalTarget
          v-if="hasContentForRevision"
          name="app-toolbar-revision"
        />
      </div>
      <div class="app-tool-banner__generals">
        <PortalTarget v-if="hasContentForPrint" name="app-toolbar-print" />
        <div
          class="app-tool-banner__generals-multiple"
          v-if="hasContentForZoom"
        >
          <PortalTarget name="app-toolbar-zoom" multiple />
        </div>
        <AppToolbarBannerItem
          @click="emit('fullscreen')"
          :icon="!fullscreenMode ? 'mdi-fullscreen' : 'mdi-fullscreen-exit'"
          key="toolbar-fullscreen"
        />
      </div>
    </div>
  </v-banner>
</template>
<script lang="ts" setup>
import { PortalTarget } from 'portal-vue';
import AppToolbarBannerItem from './appToolBannerItem.vue';
import { useAppLayout } from '@/composables/useAppLayout';

type Props = {
  fullscreenMode: boolean;
};

type Emits = {
  (e: 'fullscreen'): void;
};

defineProps<Props>();
const emit = defineEmits<Emits>();

const {
  hasContentForPrint,
  hasContentForExport,
  hasContentForSearch,
  hasContentForZoom,
  hasContentForListView,
  hasContentForMeetingPlanner,
  hasContentForHeatMap,
  hasContentForRevision,
  hasCustomTools,
} = useAppLayout();
</script>
<style lang="scss" scoped>
$items-gap: 16px;

.app-tool-banner {
  height: $app-tool-banner-height;
  padding: 12px;
  background-color: rgb(var(--v-theme-primary-lighten-4));
  color: rgba(0, 0, 0, 0.54);
  & :deep(.v-banner__wrapper) {
    padding: 12px;
    height: 100%;
  }

  /* To prevent from cutting v-badge in half */
  & :deep(.v-banner__content) {
    overflow: unset;
  }
}

.app-tool-banner__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-tool-banner__content > div {
  display: flex;
  gap: $items-gap;
}
.app-tool-banner__domain-multiple,
.app-tool-banner__generals-multiple {
  display: flex;
  gap: $items-gap;
}

.revision-portal-target.vue-portal-target {
  display: block;
}
</style>
