<template>
  <div class="side-bar_top-section">
    <div class="side-bar__top-section__top-row">
      <div>
        <v-btn
          v-show="!showDefaultView"
          @click="showDefaultView = true"
          variant="text"
          icon="mdi-arrow-left"
        />
      </div>
      <div v-if="showDefaultView" class="side-bar__title">Help</div>
      <div v-else class="side-bar__title" />
      <div>
        <v-btn variant="text" icon="mdi-close" @click="emit('close')" />
      </div>
    </div>
    <SearchBar
      v-if="showDefaultView"
      @update:modelValue="setSearchValue"
      label="Search help articles"
      :debounceOn="true"
    />
  </div>
  <SidebarList>
    <div v-if="showDefaultView" class="side-bar__default-view">
      <ResizeObserver @notify="heightOfContainer" />
      <SidebarListGroup class="side-bar__articles-list" id="article-container">
        <ArticleList
          @article-selected="handleArticleSelected"
          :heightOfContainer="heightOfArticleContainer"
          :searchValue="searchValue"
        />
      </SidebarListGroup>

      <div class="side-bar__keyboard-shortcuts">
        <v-list density="compact">
          <div v-for="(item, i) in listOfHelpItems" :key="i">
            <template v-if="item.disabled">
              <v-tooltip location="left" :text="item.tooltip">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-list-item
                      class="side-bar__action-list-items"
                      :href="
                        item.action === 'mailto'
                          ? constructMailTo(item)
                          : item.link
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                      :disabled="item.disabled"
                      @click="handleClick(item)"
                    >
                      <template #prepend>
                        <v-icon
                          :icon="item.icon"
                          class="side-bar__action-list-items__icon"
                        />
                      </template>

                      <v-list-item-title>{{ item.text }}</v-list-item-title>

                      <template #append>
                        <v-icon
                          v-if="item.externalLink"
                          icon="mdi-open-in-new"
                          size="x-small"
                        />
                      </template>
                    </v-list-item>
                  </div>
                </template>
              </v-tooltip>
            </template>

            <template v-else>
              <v-list-item
                class="side-bar__action-list-items"
                :href="
                  item.action === 'mailto' ? constructMailTo(item) : item.link
                "
                target="_blank"
                rel="noopener noreferrer"
                :disabled="item.disabled"
                @click="handleClick(item)"
              >
                <template #prepend>
                  <v-icon
                    :icon="item.icon"
                    class="side-bar__action-list-items__icon"
                  />
                </template>

                <v-list-item-title>{{ item.text }}</v-list-item-title>

                <template #append>
                  <v-icon
                    v-if="item.externalLink"
                    icon="mdi-open-in-new"
                    size="x-small"
                  />
                </template>
              </v-list-item>
            </template>
          </div>
          <v-list-item
            data-spec="changelog-link"
            :href="appVersionData.changelog_link"
            disabled
            target="_blank"
            rel="noreferrer"
            density="compact"
            class="app-version__item"
          >
            <div class="app-version">
              Version {{ appVersionData.version || '1.2.3' }}
            </div>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <intercomArticle
      v-if="!showDefaultView"
      class="side-bar__article-view"
      :article="selectedArticle"
      @changeSize="emitChangeSize"
    />
  </SidebarList>
</template>
<script lang="ts" setup>
import SidebarList from '@/components/sidebar/sidebarList.vue';
import SidebarListGroup from '@/components/sidebar/sidebarListGroup.vue';
import ArticleList from '@/components/help/ArticlesList.vue';
import intercomArticle from '@/components/help/IntercomArticle.vue';
import SearchBar from '@/components/searchBar.vue';
import ResizeObserver from '@/components/resizeObserver.vue';
import { onMounted, ref, watch } from 'vue';
import { useDirectStore } from '@/composables/store';

type Emits = {
  (e: 'close'): void;
  (e: 'openKeyboardShortcuts'): void;
  (e: 'openReleaseNotes'): void;
  (e: 'hasArticleOpen', value): void;
  (e: 'changeSize', isExpanded): void;
};

const emit = defineEmits<Emits>();

const store = useDirectStore();
const appVersionData = store.account.getAppVersionData();

const showDefaultView = ref(true);
const selectedArticle = ref({});
const heightOfArticleContainer = ref(0);
const searchValue = ref('');

const listOfHelpItems = [
  {
    text: 'Knowledge base',
    icon: 'mdi-book',
    link: 'https://help.sec-hub.com/en/',
    externalLink: true,
  },
  {
    text: 'Contact support',
    icon: 'mdi-email',
    email: 'support@sec-hub.com',
    subject: 'Issue Report from [Your Company/Your Name]',
    body:
      `Company Name: [Your Company Name]\n` +
      `Contact Information: [Your Contact Email or Phone]\n\n` +
      `Date of Incident: [Date when the issue occurred]\n` +
      `Issue Summary: [A brief, clear description of the issue]\n\n` +
      `Detailed Description:\n` +
      `Please provide a detailed description of the issue, including any error messages, the expected outcome, and the actual outcome.\n\n` +
      `Steps to Reproduce:\n` +
      `If applicable, list the steps to reproduce the issue.\n\n` +
      `Impact of Issue:\n` +
      `Describe the impact of the issue on your work, including how critical the issue is to your operations.\n\n` +
      `Feature Request or Inquiry:\n` +
      `If you are requesting a new feature or inquiring about the existence of a feature, please describe your needs or question here.\n\n` +
      `Additional Comments:\n` +
      `Include any other relevant information or specific requests.\n\n` +
      `Thank you for taking the time to report this issue. We strive to address all inquiries as promptly as possible.`,
    externalLink: true,
    action: 'mailto',
  },
  {
    text: 'What is new?',
    icon: 'mdi-bullhorn-outline',
    emit: 'openReleaseNotes',
    disabled: true,
    tooltip: 'Feature temporarily disabled, coming back soon',
  },
  {
    text: 'Keyboard shortcuts',
    icon: 'mdi-keyboard',
    emit: 'openKeyboardShortcuts',
  },
];

const setSearchValue = (searchQuery) => {
  searchValue.value = searchQuery;
};
const handleArticleSelected = (article) => {
  showDefaultView.value = false;
  selectedArticle.value = article;
};

const handleClick = (item) => {
  emit(item.emit);
};

const emitChangeSize = (isExpanded) => {
  emit('changeSize', isExpanded);
};

const emitHasArticleOpen = (hasArticleOpen: boolean) => {
  emit('hasArticleOpen', hasArticleOpen);
};

const heightOfContainer = () => {
  const articleContainerElement = document.getElementById('article-container');
  heightOfArticleContainer.value = articleContainerElement.offsetHeight;
};

const constructMailTo = (item) => {
  const base = `mailto:${item.email}`;
  const params = [];
  if (item.subject) params.push(`subject=${encodeURIComponent(item.subject)}`);
  if (item.body) params.push(`body=${encodeURIComponent(item.body)}`);

  return `${base}?${params.join('&')}`;
};

watch(showDefaultView, (value) => {
  if (value) {
    emitChangeSize(false);
  }
  emitHasArticleOpen(!value);
});

onMounted(() => {
  heightOfContainer();
});
</script>
<style lang="scss" scoped>
.side-bar_top-section {
  background-color: rgba(var(--v-theme-));
  padding-bottom: 10px;
  border-bottom: thin solid rgba(var(--v-theme-background));
}

.side-bar__top-section__top-row {
  display: grid;
  width: 100%;
  grid-template-columns: 10% 80% 10%;
  padding: 0 15px 0 15px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.side-bar__title {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 56px;
}

.side-bar__action-list-items {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;

  .side-bar__action-list-items__icon {
    height: 20px;
    width: 20px;
  }

  .side-bar__action-list-items__text {
    padding-left: 10px;
  }
}

.side-bar__default-view {
  height: calc(100vh - 185px);
  display: flex;
  flex-direction: column;
}

.side-bar__article-view {
  height: calc(100vh - 160px);
  overflow: hidden;
}

.side-bar__articles-list {
  flex: 2 0 auto;
  max-height: calc(100% - 216px);
  overflow: hidden;
}

.side-bar__keyboard-shortcuts {
  bottom: 10px;
}

.app-version__item {
  text-align: center;
}
</style>
