<template>
  <v-tooltip location="right" :disabled="toolbar.isExpanded">
    <template #activator="{ props }">
      <v-list-item
        v-bind="props"
        :class="{
          'app-toolbar-item--secondary': secondary,
          'app-toolbar-item--disabled': disabled,
          'app-toolbar-item--active': active,
        }"
        class="app-toolbar-item"
        :disabled="disabled"
        :title="label"
        link
        :exact="exact"
        :to="to"
        :inactive="inactive"
        @click="toggleTool"
      >
        <template #prepend>
          <v-tooltip location="top right" offset="20" :disabled="!badge">
            <span>In process of syntax update</span>
            <template #activator="{ props: tooltipProps }">
              <v-badge
                v-bind="tooltipProps"
                offset-y="-5"
                :offset-x="toolbar.isExpanded ? '-3' : '-10'"
                class="app-toolbar-item__badge"
                :class="{ 'app-toolbar-item__badge-context': !isMdiIcon }"
                :model-value="!!badge"
                color="error"
                :content="typeof badge === 'string' ? badge : ''"
                :dot="badgeIsDot"
              >
                <slot name="icon" />
                <v-icon :color="iconColor" v-if="isMdiIcon">
                  {{ icon }}
                </v-icon>
              </v-badge>
            </template>
          </v-tooltip>
        </template>
        <portal v-if="active && hasDrawer" :to="drawerPortalTarget">
          <slot name="drawer" :close="toolbar.close" :active="active" />
        </portal>
      </v-list-item>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Options, Prop, Vue, Inject, Watch, Ref } from 'vue-property-decorator';
import { Portal } from 'portal-vue';
import { ToolbarContext } from '../layout/types';
import { RouteLocation } from 'vue-router';

@Options({
  components: {
    Portal,
  },
  emits: ['activated', 'deactivated', 'click'],
})
export default class AppToolbarItem extends Vue {
  @Prop(String) toolId: string;
  @Prop({ default: 'mdi-pencil-outline' }) icon: string;
  @Prop({ default: '' }) label: string;
  @Prop(Boolean) secondary: boolean;
  @Prop(Boolean) disabled: boolean;
  @Prop(Boolean) exact?: boolean;
  @Prop({ default: false }) badgeNeeded?: boolean;
  @Prop({ default: () => null }) to?: Partial<RouteLocation>;
  @Prop(Boolean) inactive?: boolean;
  @Prop({ default: '', type: [String, Boolean] }) badge?: string | boolean;
  @Prop({ default: undefined }) iconColor?: string;
  @Prop({ default: 'toolbar-drawer' }) drawerPortalTarget: string;

  @Inject({ from: 'toolbarContext' }) toolbar: ToolbarContext;

  @Ref('app-toolbar-item-name') readonly appToolbarItemName: HTMLSpanElement;

  readonly randomId = `tool-${Math.random()}`.replace('.', '-');

  get id() {
    return this.toolId || this.randomId;
  }

  public get active() {
    return this.toolbar.activeTool === this.id;
  }

  get hasDrawer() {
    return !!this.$slots.drawer;
  }

  get badgeIsDot() {
    if (!this.badge) return false;
    return typeof this.badge === 'boolean';
  }

  get isMdiIcon() {
    return this.icon.includes('mdi');
  }

  toggleTool(ev?: MouseEvent) {
    if (this.hasDrawer) {
      // Prevent hiding toolbar if already opened
      ev?.preventDefault();
      if (this.active) {
        this.deactivate();
      } else {
        this.activate();
      }
    }
    this.$emit('click', ev);
  }

  public activate() {
    if (this.hasDrawer) {
      this.toolbar.setTool(this.id);
    }
  }

  public deactivate() {
    this.toolbar.close();
  }

  @Watch('active')
  onActiveStateChange(active) {
    if (active) {
      this.$emit('activated');
    } else {
      this.$emit('deactivated');
    }
  }
}
</script>

<style lang="scss" scoped>
.app-toolbar-item {
  width: 100%;
  > :deep(.v-list-item__content > .v-list-item-title) {
    font-size: 1rem;
    font-weight: 400;
  }

  :deep(.v-list-item__prepend > .v-badge ~ .v-list-item__spacer) {
    display: none;
  }
}
.app-toolbar-item--secondary {
  opacity: 0.7;
  &:hover,
  &:focus {
    opacity: 1;
  }
}
.app-toolbar-item--active {
  @include highlighted-background;
  & {
    background-color: rgb(var(--v-theme-cardBackground));
    opacity: 1;
    color: rgb(var(--v-theme-on-colorInverse));
  }
}
.app-toolbar-item--disabled {
  opacity: 0.3;
}

.app-toolbar-item > :deep(.v-list-item__prepend) {
  margin-left: 6px;
  margin-inline-end: 16px;
}
</style>
