import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import relationManager from '../components/relationManager';
import processingTracker from '../components/processingTracker';
import publisher from '../components/publisher';
import archiver from '../components/archiver';

export interface SyntaxFilters {
  showArchived: boolean;
}

const getDefaultFilters = (): SyntaxFilters => ({
  showArchived: false,
});

interface State {
  filters: SyntaxFilters;
}

export default createModule({
  path: 'syntax',
  resourceProfile: resources.syntax,
  components: [
    crud,
    indexManager,
    relationManager,
    processingTracker,
    publisher,
    archiver,
  ],
  setup({ getAccessors, components }) {
    const state = (): State => ({
      filters: getDefaultFilters(),
    });

    const getters = {
      filters: (state: State) => state.filters,
      getRelatedRootsNodeId:
        () =>
        (syntaxId: string): string | null => {
          return components.$crud.public.getRelationshipIdFromLinkage(
            syntaxId,
            'root_syntax_node'
          );
        },
      hasObsoleteClassificationTable:
        () =>
        (syntaxId: string): boolean => {
          return !!components.$crud.public.getResourceMetaProperty(
            syntaxId,
            'obsolete'
          );
        },
    };

    const mutations = {
      setFilters(state: State, payload: { filters: SyntaxFilters }) {
        state.filters = payload.filters;
      },
    };

    const actions = {
      setFilters(_, payload: { filters: SyntaxFilters }) {
        commit(mutations.setFilters)({ filters: { ...payload.filters } });
      },
      clearFilters(_) {
        commit(mutations.setFilters)({ filters: getDefaultFilters() });
      },
    };
    const { read, dispatch, commit } = getAccessors<State>();
    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        ...components.$processingTracker.public,
        ...components.$crud.public,
        ...components.$indexManager.public,
        ...components.$publisher.public,
        ...components.$archiver.public,
        getRelatedRootsNodeId: read(getters.getRelatedRootsNodeId),
        getHasObsoleteClassificationTable: read(
          getters.hasObsoleteClassificationTable
        ),
        getFilters: read(getters.filters),
        dispatchSetFilters: dispatch(actions.setFilters),
        dispatchClearFilters: dispatch(actions.clearFilters),
      },
    };
  },
});
