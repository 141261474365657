import { computed, ComputedRef, ref, watch } from 'vue';
import { RouteRecordName } from 'vue-router';
import { useRoute } from './router';
import { Project } from '@/models/project';
import { useDirectStore } from './store';
import { RouteName } from '@/enums/routing';
import { Context } from '@/models/context';
import { aspects } from '@/models/syntax';
import { Revision } from '@/models/revision';
import { PathParameters, getIndexRequestIdentifier } from '@/utils/path';
import { featureFlags } from '@/featureFlags';

type ContextItem = {
  baseContext: Context;
  revisionContext: Context | null;
  archived: boolean;
};

type ProjectLayoutConfig = {
  initialLoad?: boolean;
};

interface ProjectRoute {
  label: string;
  icon: string;
  to: {
    name: string;
    params?: {
      projectId: string;
    };
  };
  disabled?: boolean;
}

export const useProjectLayout = (
  config: ProjectLayoutConfig = { initialLoad: true }
) => {
  const route = useRoute();
  const store = useDirectStore();

  const projectId = computed(() => route.value.params.projectId as string);

  const loadingContexts = ref(false);

  const userCanUpdateSyntax = computed(() =>
    store.userPermission.canUpdateContextSyntax(projectId.value)
  );

  const hasTemporalContext = computed(() => {
    if (!contexts.value) return false;
    return contexts.value.some((ctx) => ctx?.baseContext?.temporal);
  });

  const revisionId = computed<string | null>(
    () => (route.value.params.revisionId as string) || null
  );

  const revision = computed<Revision | null>(() =>
    revisionId.value
      ? store.revision.getSimplifiedResourceSet()[revisionId.value]
      : null
  );

  const nextRevisionId = computed<string | null>(
    () => (route.value.params.nextRevisionId as string) || null
  );

  const isCompareMode = computed<boolean>(
    () => !!revisionId.value && !!nextRevisionId.value
  );
  const compareRevision = computed<Revision | null>(() =>
    isCompareMode.value
      ? store.revision.getSimplifiedResourceSet()[nextRevisionId.value]
      : null
  );

  const project = computed<Project>(() =>
    projectId.value
      ? store.project.getSimplifiedResourceSet()[projectId.value as string]
      : null
  );

  const contextRequestType = computed<string>(
    () => `project-${projectId.value}`
  );

  const contextPathParameters = computed<PathParameters>(() =>
    projectId.value
      ? {
          filterProjectId: projectId.value,
          filterArchived: false,
          filterArchivedOrHasArchivedContent: false,
        }
      : {}
  );

  const notArchivedContexts = computed<Context[]>(() =>
    store.context.getFullResource(
      contextRequestType.value,
      getIndexRequestIdentifier(contextPathParameters.value)
    )
  );

  const getSyntaxAspect = (contextId: string) => {
    const context: Context =
      store.context.getSimplifiedResourceSet()[contextId];
    return aspects.find((aspect) => aspect.type === context?.syntax?.aspect)
      ?.prefix;
  };

  const formatContextName = (contextId: string) => {
    const context: Context =
      store.context.getSimplifiedResourceSet()[contextId];
    if (!context || !context.syntax?.id) return;
    return `${getSyntaxAspect(contextId)}${context.name}`;
  };

  const createAppPath = (
    context: Context,
    routeName: RouteName | RouteRecordName,
    revisionParams?: Record<string, string>
  ) => {
    return {
      name: routeName,
      params: {
        projectId: projectId.value,
        contextId: context?.id,
        ...(isCompareMode.value && revisionParams),
      },
    };
  };

  const contextBasedRoutes = computed(() => {
    if (!projectId.value) return [];
    const revisionParams = {
      nextRevisionId: nextRevisionId.value,
      revisionId: revisionId.value,
    };
    const isInDomaRevision =
      route.value.name === RouteName.RevisionCoreSplitView;
    const isInDoma = route.value.name === RouteName.CoreSplitView;
    const isCoreOrSimo =
      route.value.name === RouteName.Core ||
      route.value.name === RouteName.Simo;
    const routeName =
      (isInDomaRevision && RouteName.RevisionCore) ||
      (revisionId.value && route.value.name) ||
      (isInDoma && RouteName.Core) ||
      (isCoreOrSimo && route.value.name) ||
      RouteName.Core;
    return baseContexts.value
      .filter((ctx) => !ctx.archived)
      .map((ctx) => {
        return {
          id: ctx?.baseContext?.id,
          label: formatContextName(ctx?.baseContext?.id),
          icon: getSyntaxAspect(ctx?.baseContext?.id),
          to: createAppPath(
            ctx?.baseContext,
            routeName,
            isCompareMode.value && revisionParams
          ),
        };
      });
  });

  const contexts = computed<ContextItem[]>(() => {
    if (!projectId.value) return [];
    const contexts = revision.value
      ? revision.value?.project?.contexts || []
      : (store.context.getFullResource(contextRequestType.value) as Context[]);
    const contextItems: ContextItem[] = contexts.map((ctx) => ({
      baseContext: revisionId.value ? ctx.base_context : ctx,
      revisionContext: revisionId.value ? ctx : null,
      archived: ctx.archived,
    }));
    if (!isCompareMode.value) return contextItems;
    const compareBaseContextsIds =
      nextRevisionId.value === 'current'
        ? store.context
            .getFullResource(contextRequestType.value)
            .filter((ctx) => !ctx.archived)
            .map((ctx) => ctx.id)
        : compareRevision.value?.project?.contexts?.map(
            (ctx) => ctx.base_context.id
          ) || [];
    return contextItems.filter((ctx) =>
      compareBaseContextsIds.includes(ctx?.baseContext?.id)
    );
  });

  const projectBasedRoutes: ComputedRef<ProjectRoute[]> = computed(() => {
    if (!projectId.value) return [];
    return [
      {
        label: 'Homepage',
        icon: 'mdi-home-outline',
        to: {
          name: RouteName.ProjectDetails,
        },
      },
      {
        label: 'Dashboard',
        icon: 'mdi-poll',
        to: {
          name: RouteName.Dashboard,
        },
      },
      {
        label: project.value?.revisions_count
          ? `Revisions (${project.value.revisions_count})`
          : 'Revisions',
        icon: 'mdi-file-edit-outline',
        to: {
          name: RouteName.Revisions,
        },
        disabled: !store.userPermission.canReadRevisions(projectId.value),
      },
      {
        label: 'Event Log',
        icon: 'mdi-text-box-search-outline',
        to: {
          name: RouteName.EventLog,
        },
        disabled: !featureFlags.isEventLogEnabled,
      },
    ].filter(({ disabled }) => !disabled);
  });

  const moreRoutes: ProjectRoute[] = [
    {
      label: 'Owner',
      icon: 'mdi-account',
      to: {
        name: RouteName.Owner,
      },
    },
    {
      label: 'Owner Groups',
      icon: 'mdi-account-multiple',
      to: {
        name: RouteName.OwnerGroups,
      },
    },
  ];

  const baseContexts = computed(() =>
    contexts.value.filter(
      (ctx) => !ctx.archived && !ctx.baseContext?.trade_study
    )
  );

  const loadContexts = async () => {
    if (!projectId.value) return;
    loadingContexts.value = true;
    await store.context.dispatchLoadFullResource({
      requestType: contextRequestType.value,
      ...contextPathParameters.value,
    });
    loadingContexts.value = false;
  };

  watch(
    projectId,
    () => {
      if (config.initialLoad) loadContexts();
    },
    { immediate: true }
  );

  const injectToPage = (resourceId: string) => {
    store.context.dispatchInjectToPageIndex({
      resourceId,
      requestId: getIndexRequestIdentifier(contextPathParameters.value),
      requestType: contextRequestType.value,
    });
  };

  const removeFromPage = (resourceId: string) => {
    store.context.dispatchRemoveFromPageIndex({
      resourceId,
      requestId: getIndexRequestIdentifier(contextPathParameters.value),
      requestType: contextRequestType.value,
    });
  };

  return {
    loadingContexts,
    project,
    contexts,
    revisionId,
    nextRevisionId,
    projectBasedRoutes,
    moreRoutes,
    contextBasedRoutes,
    notArchivedContexts,
    userCanUpdateSyntax,
    hasTemporalContext,
    formatContextName,
    injectToPage,
    removeFromPage,
    createAppPath,
  };
};
