import { Directive, DirectiveBinding } from 'vue';

const keyDownHandler = (event: KeyboardEvent, binding: DirectiveBinding) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
    binding.value(event);
  }
};

const inputTypes = ['INPUT', 'TEXTAREA'];

const findInput = (el: HTMLElement): HTMLElement => {
  if (inputTypes.includes(el.tagName)) return el;
  return el.querySelector(inputTypes.join(', '));
};

const listeners: Map<HTMLElement, (event: KeyboardEvent) => void> = new Map();

export const OnCtrlMetaEnter: Directive<
  HTMLElement,
  (e: KeyboardEvent) => unknown
> = {
  beforeMount(el, binding) {
    const input = findInput(el);
    if (!input) return;
    const handler = (event: KeyboardEvent) => keyDownHandler(event, binding);
    listeners.set(input, handler);
    input.addEventListener('keydown', handler);
  },

  unmounted(el) {
    const input = findInput(el);
    if (!input) return;
    const handler = listeners.get(input);
    listeners.delete(input);
    if (handler) {
      input.removeEventListener('keydown', handler);
    }
  },
};
