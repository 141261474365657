import { utils } from 'jsonapi-vuex';
import { JsonApiRelationship } from '@/models/api';
import { createModuleComponent } from '../utils/factories';
import { ComponentType } from './_types';
import store from '@/store';
import type {
  JvDeletePayload,
  JvGet,
  JvPatchPayload,
  JvPostPayload,
  JVRestructuredRecord,
  JVTopLevelCollectionResource,
  JVTopLevelSingleResource,
} from '@/models/jv';
import { JvBatchAccessors } from '../modules/jvBatch';
import { GetterFunctionHandler } from '@/store/utils/accessors';

export type JvComponentGet = (
  data:
    | string
    | {
        id: string;
        type: string;
      },
  jsonPath?: string
) => JVRestructuredRecord | Record<string, JVRestructuredRecord>;

export default createModuleComponent({
  type: ComponentType.Jv,
  dependencies: [],
  setup: ({ getAccessors }) => {
    const withBatch: JvBatchAccessors['withBatch'] = (operation) => {
      if (store?.$_moduleInstances?.jvBatch?.protected?.withBatch) {
        return store.$_moduleInstances.jvBatch.protected.withBatch(operation);
      }
      return operation.payload;
    };

    const getters = {
      get:
        (state, getters, rootState, rootGetters): JvComponentGet =>
        (data, jsonPath?) => {
          const jvGet = rootGetters['jv/get'] as JvGet;
          if (typeof data === 'string') return jvGet(data, jsonPath);
          const jvRecord = jvGet({ _jv: data }, jsonPath);
          return Object.keys(jvRecord).length ? jvRecord : undefined;
        },
      getRelated: (state, getters, rootState, rootGetters) =>
        rootGetters['jv/getRelated'],
    };

    const actions = {
      // JV MUTATIONS
      commitDeleteRecord(context, payload: unknown) {
        return context.commit('jv/deleteRecord', payload, { root: true });
      },
      commitAddRecords(context, payload: unknown) {
        return context.commit('jv/addRecords', payload, { root: true });
      },
      commitReplaceRecords(context, payload: unknown) {
        return context.commit('jv/replaceRecords', payload, { root: true });
      },
      commitMergeRecords(
        context,
        payload: { record: unknown; touchRelations?: boolean }
      ) {
        context.commit('jv/mergeRecords', payload.record, { root: true });
        const record = payload.record as JVTopLevelSingleResource;
        if (
          payload.touchRelations &&
          record._jv &&
          record._jv.type &&
          record._jv.id
        ) {
          // if new object has a relation the cached object hasn't we need to replace entire object with it's copy to make sure
          // Vue reactivity is notified and getters can properly track the new relation
          const updatedRecord = getterAccessors.get(record._jv.type)[
            record._jv.id
          ];
          context.commit(
            'jv/replaceRecords',
            { ...updatedRecord },
            { root: true }
          );
        }
      },
      commitClearRecords(context, payload: unknown) {
        return context.commit('jv/clearRecords', payload, { root: true });
      },
      // JV ACTIONS
      dispatchGet(context, payload: string) {
        return context.dispatch('jv/get', payload, { root: true }) as Promise<
          JVTopLevelSingleResource | JVTopLevelCollectionResource
        >;
      },
      async dispatchPost(context, payload: JvPostPayload) {
        const response = (await context.dispatch(
          'jv/post',
          withBatch({ type: 'add', payload }),
          {
            root: true,
          }
        )) as JVTopLevelSingleResource;
        try {
          if (!response._jv.id && response._jv?.json?.data?.id) {
            // If server response is different than 200/201 JV can't handle caching resource to store, so we have to do it manually
            // Status 202 is used by us when resource has been created but is still pending
            const normalizedData = utils.jsonapiToNorm(response._jv.json.data);
            dispatch(actions.commitAddRecords)(normalizedData);
          }
        } catch (error) {
          // do not throw: error here should not break the whole request
          console.error(error);
        }
        return response;
      },
      dispatchPatch(context, payload: JvPatchPayload) {
        return context.dispatch(
          'jv/patch',
          withBatch({ type: 'update', payload }),
          {
            root: true,
          }
        ) as Promise<JVTopLevelSingleResource>;
      },
      dispatchDelete(context, [meta, axiosConfig]: JvDeletePayload) {
        const payload = [
          meta,
          { ...axiosConfig, data: null },
        ] as unknown as JvDeletePayload;
        return context.dispatch(
          'jv/delete',
          withBatch({ type: 'remove', payload }),
          {
            root: true,
          }
        ) as Promise<void>;
      },
      // CUSTOM
      commitAlterRelationship(
        context,
        payload: {
          type: string;
          id: string;
          relationshipKey: string;
          relationship:
            | JsonApiRelationship
            | ((rel: JsonApiRelationship | undefined) => JsonApiRelationship);
        }
      ) {
        const resource = getterAccessors.get({
          id: payload.id,
          type: payload.type,
        });
        if (resource) {
          const updatedResource = {
            ...resource,
            _jv: {
              ...resource._jv,
              relationships: {
                ...resource._jv.relationships,
                [payload.relationshipKey]:
                  typeof payload.relationship === 'function'
                    ? payload.relationship(
                        resource._jv.relationships[payload.relationshipKey]
                      )
                    : payload.relationship,
              },
            },
          };
          dispatch(actions.commitReplaceRecords)(updatedResource);
        }
      },
    };

    const { read, dispatch } = getAccessors();
    const getterAccessors = {
      get: read(
        getters.get as unknown as GetterFunctionHandler<
          Record<string, never>,
          Record<string, never>,
          Parameters<ReturnType<typeof getters.get>>,
          ReturnType<ReturnType<typeof getters.get>>
        >
      ),
      getRelated: read(
        getters.get as unknown as GetterFunctionHandler<
          Record<string, never>,
          Record<string, never>,
          Parameters<ReturnType<typeof getters.getRelated>>,
          ReturnType<ReturnType<typeof getters.getRelated>>
        >
      ),
    };

    return {
      module: {
        state: {},
        mutations: {},
        getters,
        actions,
      },
      protected: {
        ...getterAccessors,

        commitDeleteRecord: dispatch(actions.commitDeleteRecord),
        commitAddRecords: dispatch(actions.commitAddRecords),
        commitReplaceRecords: dispatch(actions.commitReplaceRecords),
        commitMergeRecords: (record: unknown, touchRelations?: boolean) =>
          dispatch(actions.commitMergeRecords)({ record, touchRelations }),
        commitClearRecords: dispatch(actions.commitClearRecords),
        commitAlterRelationship: dispatch(actions.commitAlterRelationship),

        dispatchGet: dispatch(actions.dispatchGet),
        dispatchPost: dispatch(actions.dispatchPost),
        dispatchPatch: dispatch(actions.dispatchPatch),
        dispatchDelete: dispatch(actions.dispatchDelete),
      },
    };
  },
});
