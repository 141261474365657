<template>
  <v-form
    ref="form"
    class="pa-6"
    @submit.prevent="submit"
    @keydown.capture.esc="cancel"
  >
    <template v-for="field in formConfig.schema" :key="field.attribute">
      <FormField :label="field.label">
        <v-text-field
          v-if="field.type === FormFieldType.Text"
          v-model="model[field.attribute]"
          :rules="field.rules"
          :loading="isAttributeLoading(field.attribute)"
          :disabled="(!field.ignoreFormReadonly && readonly) || field.readonly"
          :placeholder="field.placeholder"
          :error-messages="field.errorMessages"
          variant="outlined"
          @blur="saveAttribute(field.attribute)"
          @keyup.enter="saveAttribute(field.attribute)"
        />

        <v-textarea
          v-else-if="field.type === FormFieldType.Textarea"
          v-model="model[field.attribute]"
          :rules="field.rules"
          :loading="isAttributeLoading(field.attribute)"
          :disabled="(!field.ignoreFormReadonly && readonly) || field.readonly"
          :placeholder="field.placeholder"
          :error-messages="field.errorMessages"
          variant="outlined"
          @blur="saveAttribute(field.attribute)"
          @keydown.enter="handleEnter"
        />

        <template v-else-if="field.type === FormFieldType.Select">
          <v-select
            v-model="model[field.attribute]"
            :rules="field.rules"
            :loading="isAttributeLoading(field.attribute)"
            :disabled="
              (!field.ignoreFormReadonly && readonly) || field.readonly
            "
            :placeholder="field.placeholder"
            :items="field.options"
            :menu-props="{ location: 'bottom', attach: $el }"
            :item-title="'text'"
            :error-messages="field.errorMessages"
            variant="outlined"
            @update:model-value="saveAttribute(field.attribute)"
          />
          <div
            class="menu-container"
            :id="`generic-form-${field.attribute}-menu`"
          />
        </template>

        <template v-else-if="field.type === FormFieldType.ProgressWidget">
          <ProgressWidget
            v-if="editMode"
            class="mb-6"
            :targetId="field.targetId"
            :targetType="field.targetType"
            :readonly="
              (!field.ignoreFormReadonly && readonly) || field.readonly
            "
          />

          <ProgressWidgetField
            v-else
            class="mb-6"
            v-model:selectedProgressStepId="progressStepModel"
            :progressSteps="field.progressSteps"
            :readonly="
              (!field.ignoreFormReadonly && readonly) || field.readonly
            "
          />
        </template>

        <template v-else-if="field.type === FormFieldType.ResourceSelector">
          <ResourceSelector
            :model-value="model[field.attribute]"
            @update:model-value="
              handleResourceSelectorUpdate($event, field.attribute)
            "
            :rules="field.rules"
            :resourceConfig="field.resourceConfig"
            :disabled="
              (!field.ignoreFormReadonly && readonly) || field.readonly
            "
            :placeholder="field.placeholder"
            :menu-props="{
              bottom: true,
              offsetY: false,
              nudgeTop: 24,
              attach: $el,
            }"
            :error-messages="field.errorMessages"
            :itemText="field.itemText"
            variant="outlined"
            @blur="saveRelationship(field.attribute)"
          >
            <template
              v-if="$slots[`field-${field.attribute}.item`]"
              #item="slotProps"
            >
              {{ slotProps }}
              <slot
                :name="`field-${field.attribute}.item`"
                v-bind="slotProps"
              />
            </template>
          </ResourceSelector>
          <div
            class="menu-container"
            :id="`generic-form-${field.attribute}-menu`"
          />
        </template>

        <slot
          v-else-if="field.type === FormFieldType.Slot"
          :name="`field-${field.attribute}`"
          :field="field"
          :editMode="editMode"
          :loading="isAttributeLoading(field.attribute)"
          :saveAttribute="saveAttribute"
          :saveRelationship="saveRelationship"
          :values="model"
        />
      </FormField>
    </template>

    <div v-if="showActionButtons" class="text-right pt-2">
      <template v-if="!editMode">
        <v-btn
          class="mr-2"
          :disabled="loading"
          color="primary"
          variant="text"
          size="small"
          @click="cancel"
        >
          Cancel
        </v-btn>

        <v-btn
          type="submit"
          :loading="loading"
          elevation="0"
          color="primary"
          size="small"
        >
          <v-icon start>mdi-plus</v-icon> {{ submitLabel }}
        </v-btn>
      </template>
      <slot name="secondary-action" :loading="loading" />
    </div>
  </v-form>
</template>

<script lang="ts">
import { Options, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { tabbable } from 'tabbable';
import FormField from '@/components/formField.vue';
import ProgressWidget from '@/components/progressWidget.vue';
import ProgressWidgetField from '@/components/progressWidgetField.vue';
import ResourceSelector from '@/components/resourceSelector.vue';
import { composeMessage } from '@/services/errorHandler';
import {
  isIgnoredField,
  isRelationshipField,
  FormFieldDefinition,
  FormFieldType,
  FormConfig,
  CreateResourcePayload,
} from '@/services/forms';
import { pick } from '@/utils/tools';
import type { VForm } from 'vuetify/components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Resource = Partial<Record<string, any>> & {
  id: string;
};

@Options({
  components: {
    FormField,
    ProgressWidget,
    ProgressWidgetField,
    ResourceSelector,
  },
  emits: ['created', 'cancel', 'failed'],
})
export default class GenericForm extends Vue {
  @Prop() resource: Resource;
  @Prop({ required: true }) formConfig: FormConfig;
  @Prop({ default: 'Create' }) submitLabel: string;
  @Prop({ default: false }) showErrorDetails: boolean;
  @Prop(Boolean) readonly: boolean;
  @Prop({ default: false }) progressInPayload?: boolean;

  @Ref('form') formRef: VForm;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: Record<string, any> = {};
  progressStepModel?: string = null;
  loadingAttributes = new Set<string>();
  loading = false;

  get FormFieldType() {
    return FormFieldType;
  }

  get editMode() {
    return !!this.resource;
  }

  get resourceId() {
    return this.resource?.id;
  }

  get hasSecondaryAction() {
    return !!this.$slots['secondary-action'];
  }

  get showActionButtons() {
    return !this.editMode || this.hasSecondaryAction;
  }

  get notIgnoredFields() {
    return this.formConfig.schema.filter(
      (field) => !isIgnoredField(field, this.progressInPayload)
    );
  }

  get attributeFields() {
    return this.notIgnoredFields.filter(
      (field) => !isRelationshipField(field, this.progressInPayload)
    );
  }

  get relationshipFields() {
    return this.notIgnoredFields.filter((field) =>
      isRelationshipField(field, this.progressInPayload)
    );
  }

  get progressField() {
    return this.formConfig.schema.find(
      (field) => field.type === FormFieldType.ProgressWidget
    ) as FormFieldDefinition & { type: FormFieldType.ProgressWidget };
  }

  get slotFields() {
    return this.formConfig.schema.filter(
      (field) => field.type === FormFieldType.Slot
    ) as Array<FormFieldDefinition & { type: FormFieldType.Slot }>;
  }

  get fieldsWithAdditionalRequests() {
    return this.slotFields.filter((field) => field.afterCreated);
  }

  get createPayload(): CreateResourcePayload {
    const resource = pick(
      this.model,
      this.attributeFields.map((field) => field.attribute)
    );
    const relationships = this.relationshipFields
      .map((field) => field.attribute)
      .reduce(
        (rels, rel) => {
          if (this.model[rel]) {
            rels[rel] = (this.model[rel] as Resource).id;
          }
          return rels;
        },
        {} as Record<string, string>
      );
    if (this.progressInPayload && this.progressStepModel)
      relationships['progress'] = this.progressStepModel;
    return {
      resource,
      relationships,
    };
  }

  handleEnter(evtPayload: KeyboardEvent) {
    if (!evtPayload.shiftKey) {
      evtPayload.preventDefault();
      this.submit();
    }
  }

  handleResourceSelectorUpdate(payload: any, modelKey: string) {
    if (!payload) return;
    this.model[modelKey] = payload;
  }

  isAttributeLoading(attribute: string) {
    return this.loadingAttributes.has(attribute);
  }

  validateField(attribute: string): boolean {
    const field = this.formConfig.schema.find(
      (field) => field.attribute === attribute
    );
    return (
      !field?.rules ||
      field.rules.every((rule) => rule(this.model[attribute]) === true)
    );
  }

  saveAttribute(attribute: string) {
    if (!this.editMode || !this.validateField(attribute)) return;
    const oldValue = this.resource[attribute];
    const newValue = this.model[attribute];
    if (oldValue !== newValue) {
      this.loadingAttributes.add(attribute);
      this.formConfig
        .updateResource({
          resourceId: this.resource.id,
          resource: { [attribute]: newValue },
        })
        .finally(() => {
          this.loadingAttributes.delete(attribute);
          this.model[attribute] = this.resource[attribute];
        });
    }
  }

  saveRelationship(attribute: string) {
    if (!this.editMode || !this.validateField(attribute)) return;
    const oldValue: { id?: string } = this.resource[attribute];
    const newValue: { id?: string } = this.model[attribute];
    if (
      newValue &&
      (!oldValue || oldValue.id !== newValue.id) &&
      !this.loadingAttributes.has(attribute)
    ) {
      this.loadingAttributes.add(attribute);
      this.formConfig
        .updateResource({
          resourceId: this.resource.id,
          resource: {},
          relationships: { [attribute]: newValue.id },
        })
        .finally(() => {
          this.loadingAttributes.delete(attribute);
          this.model[attribute] = this.resource[attribute];
        });
    }
  }

  createInitialModel() {
    return this.notIgnoredFields.reduce((model, field) => {
      const defaultValue =
        field.initialValue !== undefined
          ? field.initialValue
          : isRelationshipField(field)
            ? null
            : '';
      model[field.attribute] = this.resource
        ? this.resource[field.attribute]
        : defaultValue;
      return model;
    }, {});
  }

  async submit() {
    if (this.editMode || this.loading) return;
    const { valid } = (await this.formRef?.validate()) || {};
    if (!valid) return;
    this.loading = true;
    this.formConfig
      .createResource(this.createPayload)
      .then(({ id }) => {
        this.performAdditionalRequests(id).finally(() => {
          this.$emit('created', id);
        });
      })
      .catch((error) => {
        this.$store.$direct.notifications.dispatchNotify({
          type: 'error',
          message: composeMessage(error, {
            preferDetail: this.showErrorDetails,
          }),
        });
        this.$emit('failed');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  performAdditionalRequests(resourceId: string) {
    return Promise.all([
      ...this.fieldsWithAdditionalRequests.map((field) =>
        field.afterCreated({ resourceId })
      ),
      this.updateProgress(resourceId),
    ]);
  }

  updateProgress(resourceId: string) {
    if (
      !this.progressField ||
      !this.progressStepModel ||
      this.progressInPayload
    ) {
      return Promise.resolve();
    }
    return this.$store.$direct.progress
      .dispatchUpdateProgress({
        target: { id: resourceId, type: this.progressField.targetType },
        progressStepId: this.progressStepModel,
      })
      .catch((error) => {
        this.$store.$direct.notifications.dispatchNotify({
          type: 'error',
          message: composeMessage(error),
        });
      });
  }

  cancel() {
    this.$emit('cancel');
  }

  /* public! */
  public queryFocusableNodes() {
    const elements = tabbable(this.formRef.$el);
    return elements;
  }

  @Watch('resourceId', { immediate: true })
  onResourceChange() {
    this.model = this.createInitialModel();
    this.loadingAttributes = new Set();
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  position: relative;
}
</style>
