<template>
  <div class="label-wrapper">
    <v-skeleton-loader
      :loading="!ooc"
      transition="fade-transition"
      type="sentences"
    >
      <v-tooltip location="top" :disabled="!tooltip || !textOverflows">
        <span>{{ reference + ' ' + name }}</span>
        <template #activator="{ props }">
          <div
            :data-spec="`nodeId-${ooc.id}`"
            ref="label"
            class="object-occurrence-label"
            :class="{
              nowrap,
              'font-weight-regular': lightFont || withParentReferences,
            }"
            v-bind="props"
          >
            <template v-if="withParentReferences">
              {{ parentReferences }}
              <b :class="{ 'text-primary': highlightReference }">
                {{ reference }}
              </b>
              {{ name }}
            </template>
            <template v-else-if="nameOnly">
              {{ name }}
            </template>
            <template v-else>
              <span
                class="reference"
                :style="referenceStyle"
                :class="{
                  'reference--single-line': singleLineReference,
                  'font-weight-bold': !lightFont,
                }"
              >
                {{ reference }}
              </span>
              {{ name }}
            </template>
          </div>
        </template>
      </v-tooltip>
    </v-skeleton-loader>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { OOC } from '@/models/objectOccurrence';
import {
  convertFrdToMlrd,
  singleLevelReferenceDesignation,
} from '@/utils/objectOccurrence';
import { getColorFromVariable } from '@/colors/utils';
import { ColorType } from '@/colors/colorPalette';

@Options({})
export default class ObjectOccurrenceLabel extends Vue {
  @Prop() ooc: OOC;
  @Prop(Boolean) tooltip: boolean;
  @Prop(Boolean) nowrap: boolean;
  @Prop(Boolean) mldr: boolean;
  @Prop(Boolean) singleLineReference: boolean;
  @Prop(Boolean) withParentReferences: boolean;
  @Prop(Boolean) nameOnly: boolean;
  @Prop(Boolean) lightFont: boolean;
  @Prop({ default: false }) highlightReference: boolean;
  @Prop({ default: null }) color: ColorType;
  @Prop({ default: true }) showName: boolean;

  textOverflows = false;

  get referenceStyle() {
    return this.color ? { color: getColorFromVariable(this.color) } : {};
  }

  checkTextOverflow() {
    const label = this.$refs.label as HTMLElement;
    this.textOverflows = label && label.scrollWidth > label.offsetWidth;
  }

  updated() {
    this.checkTextOverflow();
  }

  get reference(): string {
    if (!this.ooc) return '';
    if (!this.mldr) {
      return singleLevelReferenceDesignation(this.ooc);
    }
    return convertFrdToMlrd(this.ooc.reference_designation || '');
  }

  get name(): string {
    return this.ooc && this.showName ? this.ooc.name : '';
  }
  get parentReferences(): string {
    if (!this.ooc) return '';
    const { reference_designation, prefix } = this.ooc;
    const fullReference = convertFrdToMlrd(reference_designation || '');
    return fullReference.substring(0, fullReference.lastIndexOf(prefix)).trim();
  }
}
</script>

<style lang="scss" scoped>
.label-wrapper {
  overflow: hidden;
}
.object-occurrence-label {
  text-overflow: ellipsis;
  overflow: hidden;
}
.nowrap {
  white-space: nowrap;
}
.reference {
  word-break: break-word;
}
.reference--single-line {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
