import {
  permissionDefinitions,
  PermissionGlobal,
  PermissionProject,
  PermissionContext,
  PermissionTargetType,
  PermissionType,
} from './permissions';

export type SimplifiedUserPermission = {
  type: PermissionType;
  target?: string | null;
};

export enum PermissionOwnershipType {
  None = 'none',
  Indirect = 'indirect',
  Direct = 'direct',
}
export const permissionRoles = {
  admin: {
    name: 'Admin',
    permission: PermissionGlobal.Admin,
    color: 'purple',
  },
  manager: {
    name: 'Manager',
    permission: PermissionProject.Write,
    color: 'blue',
  },

  editor: {
    name: 'Editor',
    permission: PermissionProject.Context_write,
    color: 'green',
  },

  viewer: {
    name: 'Viewer',
    permission: PermissionProject.Read,
    color: 'orange',
  },
  //
  // custom: {
  //   name: 'Custom',
  //   permission: 'custom',
  //   color: 'red',
  // },
};

const hasDirectPermission = (
  userPermissions: SimplifiedUserPermission[],
  type: PermissionType,
  targetId?: string
) => {
  return userPermissions.some(
    (permission) =>
      permission.type === type &&
      ((!permission.target && !targetId) || permission.target === targetId)
  );
};

export function getDirectPermissions(
  userPermissions: SimplifiedUserPermission[],
  projectId?: string,
  contextId?: string
): Set<PermissionType> {
  return new Set([
    ...Object.values(PermissionGlobal).filter((type) =>
      hasDirectPermission(userPermissions, type)
    ),
    ...Object.values(PermissionProject).filter((type) =>
      hasDirectPermission(userPermissions, type, projectId)
    ),
    ...Object.values(PermissionContext).filter((type) =>
      hasDirectPermission(userPermissions, type, contextId)
    ),
  ]);
}

function getImpliedHierarchicalPermissions(
  targetTypes: PermissionTargetType[],
  initialPermissions: PermissionType[]
): Set<PermissionType> {
  if (!initialPermissions.length) return new Set();
  const iteration = initialPermissions
    .flatMap((type) => permissionDefinitions[type].implies || [])
    .filter(
      (type) =>
        targetTypes.includes(permissionDefinitions[type].targetType) &&
        !initialPermissions.includes(type)
    );
  return new Set([
    ...iteration,
    ...getImpliedHierarchicalPermissions(targetTypes, iteration),
  ]);
}

function getImpliedTradeStudyPermissions(
  userPermissions: SimplifiedUserPermission[],
  upstreamContextId: string
): Set<PermissionType> {
  return new Set(
    [PermissionContext.Write, PermissionContext.Read].filter((type) => {
      return (
        !!upstreamContextId &&
        hasDirectPermission(userPermissions, type, upstreamContextId)
      );
    })
  );
}

export function calculatePermissions(
  userPermissions: SimplifiedUserPermission[],
  projectId?: string,
  context?: {
    id: string;
    isTradeStudy: boolean;
    upstreamContextId?: string;
  }
): Record<PermissionType, PermissionOwnershipType> {
  const directPermissions = getDirectPermissions(
    userPermissions,
    projectId,
    context?.id
  );
  const targetTypes = [
    PermissionTargetType.Global,
    projectId && PermissionTargetType.Project,
    context?.id && PermissionTargetType.Context,
  ].filter(Boolean);
  const impliedTradeStudyPermissions = context?.isTradeStudy
    ? getImpliedTradeStudyPermissions(
        userPermissions,
        context.upstreamContextId
      )
    : new Set<PermissionType>();
  const indirectPermissions = new Set([
    ...getImpliedHierarchicalPermissions(targetTypes, [
      ...directPermissions,
      ...impliedTradeStudyPermissions,
    ]),
    ...impliedTradeStudyPermissions,
  ]);
  return [
    ...Object.values(PermissionGlobal),
    ...Object.values(PermissionProject),
    ...Object.values(PermissionContext),
  ].reduce(
    (acc, type) => ({
      ...acc,
      [type]: directPermissions.has(type)
        ? PermissionOwnershipType.Direct
        : indirectPermissions.has(type)
          ? PermissionOwnershipType.Indirect
          : PermissionOwnershipType.None,
    }),
    {} as Record<PermissionType, PermissionOwnershipType>
  );
}
