import { Directive, DirectiveBinding, ref, Ref } from 'vue';

interface HoverDirectiveElement extends HTMLElement {
  _hoverListeners?: {
    mouseenter: (event: MouseEvent) => void;
    mouseleave: (event: MouseEvent) => void;
  };
  _isHovered?: Ref<boolean>;
}

interface HoverBinding {
  onHoverChange: (...args: any[]) => void;
  params?: any; // Additional parameters to pass to the callback
}

export const vHover: Directive<HoverDirectiveElement> = {
  mounted(el: HoverDirectiveElement, binding: DirectiveBinding<HoverBinding>) {
    // Create a reactive reference to track hover state
    const isHovered = ref(false);
    el._isHovered = isHovered;

    const addHoverClass = (): void => {
      isHovered.value = true;
      if (binding.value.params !== undefined) {
        binding.value.onHoverChange(isHovered, binding.value.params);
      } else {
        binding.value.onHoverChange(isHovered);
      }
    };

    const removeHoverClass = (e: MouseEvent): void => {
      if (!el.contains(e.relatedTarget as Node)) {
        isHovered.value = false;
        if (binding.value.params !== undefined) {
          binding.value.onHoverChange(isHovered, binding.value.params);
        } else {
          binding.value.onHoverChange(isHovered);
        }
      }
    };

    el.addEventListener('mouseenter', addHoverClass);
    el.addEventListener('mouseleave', removeHoverClass);

    // Store event listeners for cleanup
    el._hoverListeners = {
      mouseenter: addHoverClass,
      mouseleave: removeHoverClass,
    };
  },

  unmounted(el: HoverDirectiveElement) {
    // Clean up event listeners
    if (el._hoverListeners) {
      el.removeEventListener('mouseenter', el._hoverListeners.mouseenter);
      el.removeEventListener('mouseleave', el._hoverListeners.mouseleave);
      delete el._hoverListeners;
    }
    delete el._isHovered;
  },
};

// Helper function to create a hover state ref
export function useHoverState(): Ref<boolean> {
  return ref(false);
}
