<template>
  <v-list-item lines="two" class="user-notifications-import__list-item">
    <div>
      <v-list-item-title>{{ notificationTitle }}</v-list-item-title>
      <template v-if="canSeeImportContext">
        <v-list-item-subtitle v-if="!!project && !!context && !loadingContext">
          {{ project.name }} - {{ context.name }}
        </v-list-item-subtitle>
        <v-skeleton-loader
          v-else
          type="list-item"
          class="user-notifications-import__project-title-loader"
        ></v-skeleton-loader>
      </template>
      <template v-if="isCompleted">
        <v-list-item-subtitle> Started: {{ timeStart }}</v-list-item-subtitle>
        <v-list-item-subtitle> Finished: {{ timeEnd }}</v-list-item-subtitle>
      </template>
      <template v-else>
        <v-skeleton-loader
          type="text"
          max-width="250"
          class="mt-2"
        ></v-skeleton-loader>
        <v-skeleton-loader type="text" max-width="250"></v-skeleton-loader>
      </template>
    </div>

    <div>
      <v-tooltip location="bottom" v-if="isFailed" max-width="400">
        <template #activator="{ props: attrs }">
          <v-icon color="error" v-bind="attrs"> mdi-alert-circle </v-icon>
        </template>
        <span>{{ origin.failure_reason }} </span>
      </v-tooltip>
      <v-progress-circular
        v-if="!isCompleted"
        size="20"
        color="var(--v-color-base)"
        indeterminate
      />
      <v-btn
        v-if="canSeeImportContext"
        color="primary"
        size="small"
        @click="redirectToContext"
      >
        See Context
      </v-btn>
    </div>
  </v-list-item>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { ImportStruct } from '@/models/importStruct';
import { useDirectStore } from '@/composables/store';
import { useRouter } from '@/composables/router';
import { RouteName } from '@/enums/routing';
import { Project } from '@/models/project';
import { Context } from '@/models/context';
import { useNotification } from '@/composables/useNotification';
import { resourceRelationship, resources } from '@/services/resources';
import { JsonApiIdentification } from '@/models/api';

type Props = {
  notificationId: string;
};

const props = defineProps<Props>();

const store = useDirectStore();
const router = useRouter();
const {
  timeEnd,
  timeStart,
  notificationTitle,
  importType,
  isFailed,
  isCompleted,
  origin,
} = useNotification<ImportStruct>(props.notificationId);

const loadingContext = ref(false);

const isSimoOrCore = computed(() =>
  ['CORE', 'SIMO'].includes(importType.value)
);

const canSeeImportContext = computed<boolean>(
  () => isSimoOrCore.value && !isFailed.value && isCompleted.value
);

const contextId = computed(() => {
  if (!isSimoOrCore.value) return null;
  const relationshipData = store.importFile.getRelationshipData(
    origin.value.id,
    resourceRelationship(resources.importFile)('target')
  ) as JsonApiIdentification;
  if (relationshipData.type !== 'context') return null;
  return relationshipData.id;
});

const relatedProject = computed(() =>
  isSimoOrCore.value ? store.context.getRelatedProjectId(contextId.value) : null
);

const redirectToContext = () => {
  router.push({
    name: importType.value === 'CORE' ? RouteName.Core : RouteName.Simo,
    params: {
      contextId: contextId.value,
      projectId: relatedProject.value,
    },
  });
};

const project = computed<Project>(() => {
  if (!relatedProject.value) return null;
  return store.project.getSimplifiedResourceSet()[relatedProject.value];
});

const context = computed<Context>(() => {
  return isSimoOrCore.value
    ? store.context.getSimplifiedResourceSet()[contextId.value]
    : null;
});

watch(
  contextId,
  async (resourceId) => {
    if (resourceId && !relatedProject.value) {
      loadingContext.value = true;
      store.context.dispatchLoadSingleResource({
        resourceId,
        include: ['project'],
      });
      loadingContext.value = false;
    }
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.user-notifications-import__project-title-loader {
  :deep(.v-skeleton-loader__list-item) {
    height: 24px;
    padding-left: 0;
  }

  :deep(.v-skeleton-loader__text) {
    height: 8px;
  }
}
.user-notifications-export__files {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  margin: 0;
}
.user-notifications-import__list-item :deep(.v-list-item__content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
