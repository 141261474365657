import { createDependency } from '@/router/supplyChain/utils';
import projectSupply from '@/router/projects/supplies/projectSupply';
import contextSupply from '@/router/projects/supplies/contextSupply';
import sameProjectGuard from '@/router/projects/supplies/sameProjectGuard';
import revisionSupply from '@/router/projects/supplies/revisionSupply';

export const contextsDependencies = (contextIds: string[]) => {
  return [
    ...contextIds.flatMap((contextId) => [
      createDependency({
        dependency: contextSupply,
        props: { contextId },
      }),
    ]),
    ...(contextIds.length > 1
      ? [
          createDependency({
            dependency: sameProjectGuard,
            props: { contextIds },
          }),
        ]
      : []),
  ];
};

export const projectDependencies = (
  projectId: string,
  options?: { optional?: boolean }
) => [
  createDependency({
    dependency: projectSupply,
    props: { projectId, optional: options?.optional || false },
  }),
];

export const revisionDependencies = (props: {
  revisionId: string;
  nextRevisionId?: string;
  baseProjectId: string;
  baseContextIds: string[];
}) => [
  createDependency({
    dependency: revisionSupply,
    props,
  }),
];
