import { ClassificationEntry } from '@/models/classificationEntry';
import { OOC, OOCType } from '@/models/objectOccurrence';
import { aspectWhitelist, SyntaxElement } from '@/models/syntaxElement';
import { dedupe, escapeRegExp } from './tools';
import { aspects } from '@/models/syntax';

export interface OOCEditorSuggestion {
  __suggestionId: string;
  name: string;
  aspect?: string;
  hex_color?: string;
  syntax_element_name?: string;
  syntax_element_id?: string; // undefined  -> no changes
  classification_entry_id?: string | null; // null -> no relationship, undefined -> no changes
  classification_entry_code?: string;
  isAlternativeName?: boolean;
  classification_entry_name?: string;
  definition?: string;
}

export const isValidOOCSuggestion = (suggestion: OOCEditorSuggestion) => {
  return !!(suggestion.aspect && suggestion.name);
};

export const suggestionWithId = (
  suggestion: Omit<OOCEditorSuggestion, '__suggestionId'>
): OOCEditorSuggestion => {
  const aspectToString = (str: string): string => {
    if (str) {
      aspects.forEach((aspect) => {
        const re = new RegExp(escapeRegExp(aspect.prefix), 'g');
        str = str.replace(re, aspect.sub);
      });
      return str;
    } else {
      return '';
    }
  };

  return {
    ...suggestion,
    __suggestionId: [
      aspectToString(suggestion.aspect),
      suggestion.classification_entry_code?.replace(/\?/g, 'q_'),
      suggestion.name?.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_'),
      suggestion.syntax_element_id,
    ].join('_'),
  };
};

export const defaultSuggestion = (
  ooc?: OOC,
  syntaxElementId?: string | null,
  classificationEntryId?: string | null
): OOCEditorSuggestion => {
  return suggestionWithId({
    name: ooc?.name || '',
    aspect: ooc?.prefix,
    hex_color: ooc?.hex_color,
    syntax_element_id: syntaxElementId,
    syntax_element_name: ooc?.syntax_element?.name,
    classification_entry_id: classificationEntryId,
    classification_entry_code: ooc ? ooc.classification_code : null,
  });
};

export const suggestionToOOC = (option: OOCEditorSuggestion): OOC => {
  return {
    id: 'fake-ooc',
    classification_code: option.classification_entry_code,
    name: option.classification_entry_name,
    prefix: option.aspect,
    hex_color: option.hex_color,
    object_occurrence_type: OOCType.Regular,
    number: null,
    position: null,
    reference_designation: '',
    syntax_element: {
      id: 'fake-syntax-element',
      name: option.syntax_element_name,
    },
    validation_errors: [],
  };
};

export interface SuggestionsContext {
  classificationEntries: ClassificationEntry[];
  syntaxElements: SyntaxElement[];
  getClassificationEntryTableId: (classificationEntryId: string) => string;
  getSyntaxElementTableId: (syntaxElementId: string) => string;
}

export const unclassifiedSuggestions = (
  context: SuggestionsContext,
  query = ''
): OOCEditorSuggestion[] => {
  const unclassifiedSyntaxElements = context.syntaxElements
    .filter((element) => !context.getSyntaxElementTableId(element.id))
    .map((element) => ({
      aspect: element.aspect || '',
      hex_color: element.hex_color || '',
      syntax_element_name: element.name || '',
      syntax_element_id: element.id,
    }));
  return dedupe(unclassifiedSyntaxElements, (option) =>
    [option.aspect, option.hex_color].join(';')
  ).map((option) =>
    suggestionWithId({
      ...option,
      name: query,
      classification_entry_id: null,
      classification_entry_code: null,
    })
  );
};

interface SyntaxElementsInfo {
  aspects: Set<string>;
  syntaxElementsByAspect: Record<string, SyntaxElement[]>;
}

const createSyntaxElementsInfoLookup = (context: {
  syntaxElements: SyntaxElement[];
  getSyntaxElementTableId: (syntaxElementId: string) => string;
}): Record<string, SyntaxElementsInfo> => {
  return context.syntaxElements.reduce<Record<string, SyntaxElementsInfo>>(
    (dict, element) => {
      const tableId = context.getSyntaxElementTableId(element.id);
      if (!tableId) return dict;
      if (dict[tableId]) {
        dict[tableId].aspects.add(element.aspect);
        dict[tableId].syntaxElementsByAspect[element.aspect] = [element].concat(
          dict[tableId].syntaxElementsByAspect[element.aspect]
        );
      } else {
        dict[tableId] = {
          aspects: new Set([element.aspect]),
          syntaxElementsByAspect: {
            [element.aspect]: [element],
          },
        };
      }
      return dict;
    },
    {}
  );
};

export const classifiedSuggestions = (
  context: SuggestionsContext,
  query = ''
): OOCEditorSuggestion[] => {
  const {
    classificationEntries,
    syntaxElements,
    getClassificationEntryTableId,
    getSyntaxElementTableId,
  } = context;

  const syntaxElementsInfoByTableId = createSyntaxElementsInfoLookup({
    syntaxElements,
    getSyntaxElementTableId,
  });
  return classificationEntries.flatMap((entry) => {
    const classificationTableId = getClassificationEntryTableId(entry.id);
    const { aspects, syntaxElementsByAspect } =
      syntaxElementsInfoByTableId[classificationTableId];
    return Array.from(aspects).flatMap((aspect) => {
      const bestNameMatch = classificationEntryNameMatch(entry, query);
      if (!bestNameMatch) return [];
      const syntaxElements = syntaxElementsByAspect[aspect];
      return syntaxElements.map((element) =>
        suggestionWithId({
          aspect,
          name: bestNameMatch,
          hex_color: element.hex_color || '',
          syntax_element_name: element.name || '',
          syntax_element_id: element.id,
          classification_entry_id: entry.id,
          classification_entry_code: entry.ard_part || entry.code,
          classification_entry_name: entry.name,
          definition: entry.definition,
        })
      );
    });
  });
};

const patternAspect = `(${aspectWhitelist.map(escapeRegExp).join('|')})`;

// 'ABC' => /(???|a??|ab?|abc)/
const getPatternCode = (code: string): string => {
  const codeAlternatives = new Array(code.length + 1)
    .fill('')
    .map((_, knownPartLength) => {
      return (
        escapeRegExp(code.slice(0, knownPartLength)).toLowerCase() +
        '\\?'.repeat(code.length - knownPartLength)
      );
    });
  return `(${codeAlternatives.join('|')})`;
};

export const classificationEntryAlternativeNames = (
  classificationEntry: ClassificationEntry
): string[] => {
  const tags =
    classificationEntry.tags?.map((tag) => tag.value).filter(Boolean) || [];
  return [classificationEntry.name, ...tags];
};

export const classificationEntryNameMatch = (
  classificationEntry: ClassificationEntry,
  query = ''
): string | null => {
  const normalizedQuery = query.trim().toLowerCase();
  const codePattern = getPatternCode(
    classificationEntry.ard_part || classificationEntry.code
  );
  const aspectCodeRegExp = new RegExp(`^${patternAspect}?${codePattern}$`);
  const hasCodeMatch = aspectCodeRegExp.test(normalizedQuery);

  if (hasCodeMatch) return classificationEntry.name;
  return (
    classificationEntryNameSortedMatches(
      classificationEntry,
      normalizedQuery
    )[0] || classificationEntry.name
  );
};

export const classificationEntryNameSortedMatches = (
  classificationEntry: ClassificationEntry,
  normalizedQuery: string
): string[] => {
  const classificationEntryMatches = classificationEntryAlternativeNames(
    classificationEntry
  ).flatMap((name) => {
    const normalizedName = name.trim().toLowerCase();
    const patternQuery = escapeRegExp(normalizedQuery);
    const queryRegExp = new RegExp(`(^|\\s)${patternQuery}`);
    const matchIndex = normalizedName.search(queryRegExp);
    if (matchIndex < 0) return [];
    return [{ name, matchIndex }];
  });
  const sortedMatches = classificationEntryMatches.sort((a, b) => {
    const aWordIndex = a.name.slice(0, a.matchIndex + 1).split(/\s/).length;
    const bWordIndex = b.name.slice(0, b.matchIndex + 1).split(/\s/).length;
    return aWordIndex - bWordIndex;
  });
  return sortedMatches.map(({ name }) => name);
};
