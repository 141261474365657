export default class PrinterCanvas {
  private canvas: HTMLElement;
  private canvasTitle: HTMLElement;
  private canvasInfo: HTMLElement;
  private element: Node;
  private originalParentNode: Node | null;
  private elementPlaceholder: Node;
  private isMounted = false;

  constructor(element: Node, title?: string) {
    this.originalParentNode = element.parentNode;
    this.element = element;

    this.canvas = this.createElement('div', 'printer-canvas');
    if (title) {
      this.canvasTitle = this.createElement('h1', 'printer-canvas__title');
      this.canvasTitle.innerText = title;
      this.canvas.appendChild(this.canvasTitle);
    }
    this.canvasInfo = this.createElement('div', 'printer-canvas__info');
    this.canvasInfo.innerText = 'Printing...';
    this.canvas.appendChild(this.canvasInfo);
    this.elementPlaceholder = document.createComment('placeholder');
  }

  public mount() {
    if (!this.isMounted) {
      document.body.appendChild(this.canvas);
      document.body.classList.add('print-in-progress');
      this.originalParentNode?.replaceChild(
        this.elementPlaceholder,
        this.element
      );
      this.canvas.appendChild(this.element);
      this.isMounted = true;
    }
  }

  public unmount() {
    if (this.isMounted) {
      this.originalParentNode?.replaceChild(
        this.element,
        this.elementPlaceholder
      );
      document.body.removeChild(this.canvas);
      document.body.classList.remove('print-in-progress');
      this.isMounted = false;
    }
  }

  public setInfo(text: string) {
    this.canvasInfo.innerText = text;
  }

  private createElement(element: string, className: string) {
    const comp = document.createElement(element);
    comp.className = className;
    return comp;
  }
}
