import { createModule } from '../utils/factories';
import { api } from '@/services/api';
import indexManager from '../components/indexManager';
import crud from '@/store/components/crud';
import jv from '@/store/components/jv';

interface State {
  articles: [];
  searchedArticles: any;
}

export default createModule({
  path: 'intercom',
  components: [crud, jv, indexManager],
  setup({ getAccessors }) {
    const state = (): State => ({
      articles: [],
      searchedArticles: {},
    });
    const getters = {
      fetchedArticles: (state: State) => {
        return state.articles;
      },
      fetchedSearchArticles: (state: State) => {
        return state.searchedArticles;
      },
    };

    const mutations = {
      async setArticles(state: State, payload) {
        state.articles = payload.data;
      },
      async setSearchArticles(state: State, payload) {
        state.searchedArticles = payload.data;
      },
    };
    const actions = {
      async loadIntercomArticles() {
        const response = await api.get('/intercom/articles', {
          headers: {
            Accept: 'application/json',
            'Intercom-Version': '2.10',
          },
          params: {
            per_page: '35',
          },
        });
        commit(mutations.setArticles)(response.data);
      },
      async searchIntercomArticles(_, searchQuery) {
        const response = await api.get('/intercom/articles/search', {
          headers: {
            Accept: 'application/json',
            'Intercom-Version': window.__env.VUE_APP_INTERCOM_VERSION,
          },
          params: {
            phrase: searchQuery,
            state: 'published',
          },
        });
        commit(mutations.setSearchArticles)(response.data);
      },
    };
    const { dispatch, commit, read } = getAccessors<State>();
    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      public: {
        dispatchLoadIntercomArticles: dispatch(actions.loadIntercomArticles),
        dispatchSearchIntercomArticles: dispatch(
          actions.searchIntercomArticles
        ),
        getAllArticles: read(getters.fetchedArticles),
        getAllSearchArticles: read(getters.fetchedSearchArticles),
      },
    };
  },
});
