<template>
  <div class="form-field">
    <div v-if="label || $slots['label']" class="section-label">
      <span :class="{ 'form-field--required': required }">
        <slot name="label">{{ label }}</slot>
      </span>
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({})
export default class FormField extends Vue {
  @Prop({ default: '' }) label: string;
  @Prop(Boolean) required?: boolean;
}
</script>

<style lang="scss" scoped>
.form-field :deep(.v-text-field--outlined) {
  & fieldset {
    background-color: rgb(var(--v-theme-cardBackground));
  }

  &.v-input--is-focused fieldset {
    background-color: rgb(var(--v-theme-primary-lighten-5));

    .theme--dark & {
      background-color: rgb(var(--v-theme-primary-darken-4));
    }
  }

  .v-text-field__details {
    padding-left: 0 !important;
  }
}
.form-field--required {
  position: relative;
}
.form-field--required::after {
  content: '*';
  color: rgb(var(--v-theme-error));
  position: absolute;
  top: -5px;
  right: -7px;
}
</style>
