import { computed, h, onBeforeUnmount, toRef, VNode, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { useRoute } from 'vue-router';
import { RouteName } from '@/enums/routing';
import { createSupply } from '@/router/supplyChain/utils';
import BannerWarning from '@/components/bannerWarning.vue';
import { Context } from '@/models/context';

const routesUsingContextChannel: RouteName[] = [
  RouteName.Simo,
  RouteName.Core,
  RouteName.CoreList,
  RouteName.CoreSplitView,
  RouteName.RevisionCore,
  RouteName.RevisionCoreSplitView,
  RouteName.RevisionSimo,
];

type ContextSupplyProps = { contextId: string };

export default createSupply<ContextSupplyProps>(({ props, onFail }) => {
  const store = useDirectStore();

  const context = computed<Context>(() => {
    const ctx = store.context.getSimplifiedResourceSet()[props.contextId];
    return ctx;
  });

  const route = useRoute();

  const routeName = computed(() => route.name as RouteName);
  const isChannelUsedByRoute = computed(() =>
    routesUsingContextChannel.includes(routeName.value)
  );
  const hasChannelFor = computed(
    () => !!store.wsContext.getTargetId(props.contextId)
  );
  const isRevisionContext = computed(
    () => !!store.context.getBaseContextId(props.contextId)
  );
  const shouldStartConnection = computed(
    () =>
      isChannelUsedByRoute.value &&
      !!context.value &&
      !hasChannelFor.value &&
      !isRevisionContext.value
  );

  const isReady = computed(() => {
    return !!context.value;
  });

  const loadContext = () => {
    return store.context.dispatchLoadSingleResource({
      resourceId: props.contextId,
    });
  };

  watch(
    toRef(props, 'contextId'),
    async (contextId) => {
      if (contextId) {
        await loadContext().catch((error) => console.error(error));
        if (!context.value) {
          onFail();
        }
      } else {
        onFail();
      }
    },
    { immediate: true }
  );

  watch(
    shouldStartConnection,
    (shouldStart) => {
      if (shouldStart) {
        store.wsContext.dispatchConnect({
          contextId: props.contextId,
        });
      }
    },
    { immediate: true }
  );

  onBeforeUnmount(() => {
    store.wsContext.dispatchDisconnect(props.contextId);
  });

  const hasContextObsoleteSyntax = computed(() =>
    store.context.getHasObsoleteSyntax(props.contextId)
  );

  const render = (renderChildren: () => VNode) => {
    if (hasContextObsoleteSyntax.value) {
      return h('div', { class: 'banner-wrapper' }, [
        h(
          BannerWarning,
          { key: props.contextId },
          `Context "${context.value.name}" uses archived syntax or classification table`
        ),
        renderChildren(),
      ]);
    }
  };

  return { isReady, render };
});
