import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import jv from '../components/jv';
import axios from 'axios';

interface FileResourcePayload {
  relatedResourceType: string;
  relationName: string;
  id: string;
}

function getFileRelationUrl({
  relatedResourceType,
  relationName,
  id,
}: FileResourcePayload) {
  const targetProfile = Object.values(resources).find(
    (r) => r.type === relatedResourceType
  );
  const url = `/${targetProfile.path}/${id}/relationships/${relationName}`;
  return url;
}

export default createModule({
  path: 'urlStruct',
  resourceProfile: resources.urlStruct,
  components: [jv],
  setup({ getAccessors, components, resourceProfile }) {
    const actions = {
      async createFileResource(
        context,
        payload: FileResourcePayload & { file: File }
      ) {
        const newResource = {
          _jv: {
            type: resourceProfile.type,
          },
          content_type: payload.file.type,
        };
        const url = getFileRelationUrl(payload);
        // Send request to get url to upload image
        const res = await components.$jv.protected.dispatchPost([
          newResource,
          { url },
        ]);
        // Remove temporary url from store
        components.$jv.protected.commitDeleteRecord(res);
        // Upload image using url returned from previous request
        await axios.put(res.url, payload.file, {
          headers: { 'Content-Type': payload.file.type },
        });
      },
      loadFileResource(context, payload: FileResourcePayload) {
        const url = getFileRelationUrl(payload);
        return components.$jv.protected.dispatchGet(url);
      },
      deleteFileResource(context, payload: FileResourcePayload) {
        const url = getFileRelationUrl(payload);
        return components.$jv.protected.dispatchDelete([
          {
            _jv: {
              type: resourceProfile.type,
              id: payload.id,
            },
          },
          { url },
        ]);
      },
    };
    const { dispatch } = getAccessors<Record<string, never>>();

    return {
      module: {
        actions,
      },
      public: {
        dispatchCreateFileResource: dispatch(actions.createFileResource),
        dispatchLoadFileResource: dispatch(actions.loadFileResource),
        dispatchDeleteFileResource: dispatch(actions.deleteFileResource),
      },
    };
  },
});
