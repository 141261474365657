import { computed, h, toRef, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { createSupply } from '@/router/supplyChain/utils';
import { Revision } from '@/models/revision';
import { createIncludeRelationshipPath, resources } from '@/services/resources';
import {
  contextsDependencies,
  projectDependencies,
} from '@/router/projects/supplyChains';
import SupplyChain from '@/router/supplyChain/supplyChain.vue';

export default createSupply<{
  baseProjectId: string;
  baseContextIds: string[];
  revisionId: string;
  nextRevisionId?: string;
}>(({ props, onFail }) => {
  const store = useDirectStore();

  const getRevision = (id: string) => {
    return store.revision.getSimplifiedResourceSet()[id] as Revision;
  };

  const revision = computed(() => getRevision(props.revisionId));
  const revisionLoaded = computed(() => !!revision.value);
  const nextRevision = computed(
    () => props.nextRevisionId && getRevision(props.nextRevisionId)
  );
  const nextRevisionLoaded = computed(
    () =>
      !props.nextRevisionId ||
      props.nextRevisionId === 'current' ||
      !!nextRevision.value
  );

  const revisionBaseProjectId = computed(() =>
    store.revision.getBaseProjectId(props.revisionId)
  );
  const nextRevisionBaseProjectId = computed(() => {
    if (!props.nextRevisionId) return null;
    if (props.nextRevisionId === 'current') return props.baseProjectId;
    return store.revision.getBaseProjectId(props.nextRevisionId);
  });

  const revisionContextIds = computed(() => {
    return (
      revision.value?.project?.contexts
        ?.filter((context) =>
          props.baseContextIds.includes(
            store.context.getBaseContextId(context.id)
          )
        )
        .map((context) => context.id) || []
    );
  });

  const nextRevisionContextIds = computed(() => {
    if (!props.nextRevisionId) return [];
    if (props.nextRevisionId === 'current') return props.baseContextIds;
    return (
      nextRevision.value?.project?.contexts
        ?.filter((context) =>
          props.baseContextIds.includes(
            store.context.getBaseContextId(context.id)
          )
        )
        .map((context) => context.id) || []
    );
  });

  const arePropsConsistent = computed(() => {
    if (!revisionLoaded.value) return false;
    const matchingRevisionProject =
      revisionBaseProjectId.value === props.baseProjectId;
    const matchingNextRevisionProject =
      !props.nextRevisionId ||
      nextRevisionBaseProjectId.value === props.baseProjectId;
    const matchingRevisionContexts =
      revisionContextIds.value.length === props.baseContextIds.length;
    const matchingNextRevisionContexts =
      !props.nextRevisionId ||
      nextRevisionContextIds.value.length === props.baseContextIds.length;
    return (
      matchingRevisionProject &&
      matchingNextRevisionProject &&
      matchingRevisionContexts &&
      matchingNextRevisionContexts
    );
  });

  const loadRevision = (resourceId: string) =>
    store.revision.dispatchLoadSingleResource({
      resourceId,
      include: createIncludeRelationshipPath(resources.revisionMetaInformation)(
        'project',
        'contexts',
        'base_context'
      ),
    });

  const areRevisionsReady = computed(
    () =>
      revisionLoaded.value &&
      nextRevisionLoaded.value &&
      arePropsConsistent.value
  );

  watch(
    [toRef(props, 'revisionId'), toRef(props, 'nextRevisionId')],
    async ([revisionId, nextRevisionId]) => {
      if (revisionId) {
        const shouldLoadNextRevision =
          nextRevisionId && nextRevisionId !== 'current';
        const results = await Promise.allSettled([
          loadRevision(revisionId),
          shouldLoadNextRevision && loadRevision(nextRevisionId),
        ]);
        results.forEach((result) => {
          if (result.status === 'rejected') console.error(result.reason);
        });
      }
      if (
        revisionId === props.revisionId &&
        nextRevisionId === props.nextRevisionId &&
        !areRevisionsReady.value
      ) {
        onFail();
      }
    },
    { immediate: true }
  );

  const isReady = computed(() => areRevisionsReady.value);

  const revisionProjectId = computed(() => revision.value?.project?.id);
  const nextRevisionProjectId = computed(() => {
    if (!props.nextRevisionId) return null;
    if (props.nextRevisionId === 'current') return props.baseProjectId;
    return nextRevision.value?.project?.id;
  });

  const render = (renderChildren) => {
    return h(
      SupplyChain,
      {
        dependencies: areRevisionsReady.value
          ? [
              ...projectDependencies(revisionProjectId.value, {
                optional: true,
              }),
              ...(props.nextRevisionId
                ? projectDependencies(nextRevisionProjectId.value, {
                    optional: true,
                  })
                : []),
              ...contextsDependencies(revisionContextIds.value),
              ...(props.nextRevisionId
                ? contextsDependencies(nextRevisionContextIds.value)
                : []),
            ]
          : [],
        onFail,
      },
      {
        default: renderChildren,
      }
    );
  };

  return {
    isReady,
    render,
  };
});
