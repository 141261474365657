<script lang="ts">
import { defineComponent, h, PropType, useSlots } from 'vue';
import { RouteLocationRaw, RouterView } from 'vue-router';
import { VProgressCircular } from 'vuetify/components';
import { RouteName } from '@/enums/routing';
import { useRouter } from 'vue-router';
import { Dependency } from '@/router/supplyChain/utils';

export default defineComponent({
  props: {
    dependencies: {
      type: Array as PropType<Dependency[]>,
      default: () => [],
    },
    onFail: {
      type: Function as PropType<() => void>,
      default: null,
    },
    fallback: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: null,
    },
  },
  setup(props) {
    const slots = useSlots();

    const renderReadyContent = () => {
      return slots.default?.()[0] || h(RouterView);
    };

    const renderContent = ({ isReady }: { isReady: boolean }) => {
      return isReady
        ? renderReadyContent()
        : h('div', { class: 'info-container' }, [
            h(VProgressCircular, { indeterminate: true, size: 64 }),
          ]);
    };

    const router = useRouter();
    const onFail = () => {
      if (props.onFail) {
        props.onFail();
      } else {
        router.replace(props.fallback || { name: RouteName.Root });
      }
    };

    return () =>
      props.dependencies
        .slice()
        .reverse()
        .reduce((renderChildren, { dependency, props }) => {
          return ({ isReady }) =>
            h(
              dependency,
              {
                key: JSON.stringify(props),
                externalReady: isReady,
                props,
                onFail,
              },
              { default: renderChildren }
            );
        }, renderContent)({ isReady: true });
  },
});
</script>

<style lang="scss" scoped>
.info-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
