import { computed, toRef, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { createSupply } from '@/router/supplyChain/utils';
import { createIncludeRelationshipPath, resources } from '@/services/resources';

export default createSupply<{
  projectId: string;
  optional?: boolean;
}>(({ props, onFail }) => {
  const store = useDirectStore();

  const project = computed(
    () => store.project.getSimplifiedResourceSet()[props.projectId]
  );

  const loadProject = () => {
    return store.project.dispatchLoadSingleResource({
      resourceId: props.projectId,
      include: [
        createIncludeRelationshipPath(resources.projects)('prev_revision'),
      ],
    });
  };

  const loadNonArchivedAndNonTradeStudyContext = () => {
    return store.context.dispatchLoadActiveNonTradeStudyContext({
      projectId: props.projectId,
    });
  };

  watch(
    toRef(props, 'projectId'),
    async (projectId) => {
      if (projectId) {
        await Promise.all([
          loadProject(),
          loadNonArchivedAndNonTradeStudyContext(),
        ]).catch((error) => {
          if (error.response.status === 403) {
            // User has no access to project.
            // We ignore this error because user can access
            // context and see CORE and SIMO normally
            return;
          }
          console.error(error);
          onFail();
        });
      } else {
        onFail();
      }
    },
    { immediate: true }
  );

  const isReady = computed(() => props.optional || !!project.value);

  return {
    isReady,
  };
});
