<template>
  <portal to="app-toolbar-search">
    <AppToolBannerItem
      key="toolbar-search"
      ref="toolbarItem"
      icon="mdi-magnify"
      :label="$t('sidebar.tools.search')"
      :badge="badge"
    >
      <template #drawer="context">
        <slot v-bind="context" />
      </template>
    </AppToolBannerItem>
  </portal>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import AppToolBannerItem from '@/components/toolbanner/appToolBannerItem.vue';
import { Portal } from 'portal-vue';

type BannerItemInstance = InstanceType<typeof AppToolBannerItem>;

@Options({
  components: {
    Portal,
    AppToolBannerItem,
  },
})
export default class AppToolBannerSearch extends Vue {
  @Prop(Boolean) active: boolean;

  get badge(): string {
    return this.active ? '!' : undefined;
  }

  public show() {
    const item = this.$refs.toolbarItem as unknown as BannerItemInstance;
    item?.activate();
  }

  public close() {
    (this.$refs.toolbarItem as unknown as BannerItemInstance)?.deactivate();
  }
}
</script>
