<template>
  <div class="list-section">
    <div
      :class="{
        'list-section__item-content': true,
        'list-section__item-content--divider': !joinNext,
        'no-overflow': noOverflow,
      }"
    >
      <div class="section-label" v-if="header">
        <slot name="header">
          {{ header }}
        </slot>
      </div>
      <div
        :class="{
          'list-section__content': true,
          'no-overflow': noOverflow,
        }"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({})
export default class SidebarListGroupSection extends Vue {
  @Prop({ default: '' }) header: string;
  @Prop(Boolean) joinNext: boolean;
  @Prop(Boolean) noOverflow: boolean;
}
</script>

<style lang="scss" scoped>
.list-section {
  max-width: 100%;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}
.list-section__header {
  opacity: 0.5;
  margin-bottom: 6px;
}
.list-section__content {
  max-width: 100%;
}
.list-section__item-content {
  overflow: visible;
  padding-bottom: 0;
  max-width: 100%;
  padding: 12px 0;
}
.no-overflow {
  overflow: hidden;
}
.list-section__item-content--divider {
  padding-bottom: 12px;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(var(--v-theme-primary)) !important;
    opacity: 0.15;
  }

  .list-section:last-of-type & {
    padding-bottom: 20px;

    &::after {
      display: none;
    }
  }
}
</style>
