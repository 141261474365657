<template>
  <ResourceList
    :resourceConfig="resourceConfig"
    :query="debouncedQuery"
    :queryFilterKey="queryFilterKey"
    :requestType="requestType"
    :requestId="requestId"
    :reloadOnRender="reloadOnRender"
    @update:items="handleUpdateItems"
  >
    <template #default="{ hasMore, loadMore, loading, query: activeQuery }">
      <component
        v-memo="[selectableItemsList, loading]"
        v-bind="$attrs"
        v-model="selectionModel"
        :is="component"
        ref="vcomponent"
        v-model:search="internalQuery"
        :items="selectableItemsList"
        :loading="isMenuActive && loading"
        item-value="id"
        :item-title="itemText"
        :multiple="multiple"
        return-object
        no-filter
        @blur="blur"
        @change="internalQuery = ''"
        @update:menu="setMenuState"
        @click:clear="$emit('update:modelValue', [])"
        :bg-color="bgColor"
      >
        <!-- @vue-skip -->
        <template v-for="(_, slotName) in $slots" #[slotName]="slotProps">
          <slot :name="slotName" v-bind="slotProps" />
        </template>

        <template #no-data>
          <v-list-item v-show="!hasMore() && !loading">
            <v-list-item-title>
              {{
                activeQuery ? 'No matching objects found' : 'No objects found'
              }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <template #append-item>
          <div
            v-if="hasMore() || loading"
            class="my-4 text-center overflow-hidden"
          >
            <div
              v-if="!loading && hasMore()"
              v-intersect="(isIntersecting) => isIntersecting && loadMore()"
            />
            <v-progress-circular
              indeterminate
              color="rgb(var(--v-theme-color))"
            />
          </div>
        </template>
      </component>
    </template>
  </ResourceList>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import { VAutocomplete, VSelect } from 'vuetify/components';
import ResourceList from '@/components/resourceList.vue';
import { ResourceListConfig } from '@/utils/resourceList';
import { debounce } from '@/utils/tools';
import { ObjectMapping, useSimpleMapper } from '@/utils/simpleObjectMapper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Resource = Partial<Record<string, any>> & {
  id: string;
  name?: string;
};

type ResourceValue = Resource | Resource[] | null;

@Options({
  components: {
    ResourceList,
  },
  emits: ['update:modelValue', 'blur', 'update:items', 'update:query'],
})
export default class ResourceSelector extends Vue {
  @Prop({ type: [Object, Array] }) modelValue: ResourceValue;
  @Prop({ required: true }) resourceConfig: ResourceListConfig;
  @Prop({ default: 'query' }) queryFilterKey: string;
  @Prop({ default: 'resourceSelector' }) requestType: string;
  @Prop({ default: '' }) requestId: string;
  @Prop({ type: Function, default: (item) => item.name }) itemText: (
    item: unknown
  ) => string;
  @Prop(Boolean) reloadOnRender: boolean;
  @Prop(Boolean) noSearch: boolean;
  @Prop(Array) customItems?: Resource[];
  @Prop(Boolean) multiple: boolean;
  @Prop({ type: Boolean, default: true }) emitBlur: boolean;
  @Prop({ type: String, default: 'rgb(var(--v-theme-cardBackground))' })
  bgColor: string;
  @Prop({ type: Array as () => ObjectMapping[] | null, default: null })
  customSelectableItemMapping: ObjectMapping[] | null = null;

  internalQuery: string | null = '';
  debouncedQuery = '';
  isMenuActive = false;
  selectableItems = [];

  get component() {
    return this.noSearch ? VSelect : VAutocomplete;
  }

  get query() {
    if (
      !this.multiple &&
      this.selectionModel &&
      this.itemText(this.selectionModel) === this.internalQuery
    ) {
      return '';
    }
    return this.internalQuery;
  }

  get selectionModel() {
    return this.modelValue;
  }

  set selectionModel(value: ResourceValue) {
    this.$emit('update:modelValue', value);
  }

  normalizedQuery(query: string) {
    return (query || '').trim().toLowerCase();
  }

  setDebouncedQuery(query: string) {
    this.debouncedQuery = this.normalizedQuery(query);
  }

  handleUpdateItems(payload) {
    this.selectableItems = payload;
  }

  get selectableItemsList() {
    const valueOptions = this.modelValue ? [this.modelValue].flat() : [];
    const items = this.selectableItems
      .concat(valueOptions)
      .concat(this.customItems?.filter(Boolean) || []);

    const uniqueItems = [];
    const seen = new Set();

    for (const item of items) {
      if (!seen.has(item.id)) {
        seen.add(item.id);
        uniqueItems.push(item);
      }
    }
    return this.customSelectableItemMapping
      ? uniqueItems.map((item) =>
          useSimpleMapper(item, this.customSelectableItemMapping)
        )
      : uniqueItems;
  }

  @Watch('selectableItemsList')
  onSelectableItemsListChange(val) {
    this.$emit('update:items', val);
  }

  blur(event: FocusEvent) {
    if (!this.emitBlur) return;
    this.internalQuery = '';
    this.$emit('blur', event);
  }

  @Watch('query')
  onQueryChange(newQuery) {
    this.setDebouncedQuery(newQuery);
    this.$emit('update:query', newQuery);
  }

  created() {
    this.setDebouncedQuery = debounce(this.setDebouncedQuery, 250);
  }

  setMenuState(isMenuOpen: boolean) {
    this.isMenuActive = isMenuOpen;
  }
}
</script>
