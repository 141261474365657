import chroma from 'chroma-js';
import { getColorFromVariable } from '@/colors/utils';
import { ColorType, Lightness } from '@/colors/colorPalette';

// based on: https://www.w3.org/TR/WCAG20/
export function getContrastColor(hexColor: string): '#000000' | '#FFFFFF' {
  return getColorLuminance(hexColor) > 0.179 ? '#000000' : '#FFFFFF'; // after algebraical simplifications
}

export function determineHighlightColor(
  color: string,
  inverse: boolean
): string {
  if (inverse && !isColorCloseToWhite(color)) {
    return getInverseContrastColor(color);
  }

  if (isColorCloseToWhite(color)) {
    return '#888888'; // Return grey for white or near white colors
  }

  const luminance = getColorLuminance(color);
  if (luminance < 0.2) {
    return '#BBBBBB'; // Use custom grey for very dark unselected colors
  }

  return color; // Default to original color for unselected
}

// Function needed for the cases to check if the node color is set close to white
function isColorCloseToWhite(color: string): boolean {
  const whiteThreshold = 0.85;
  const luminance = getColorLuminance(color);
  return luminance > whiteThreshold;
}

function getInverseContrastColor(
  hexColor: string
): '#000000' | '#FFFFFF' | '#BBBBBB' {
  const luminance = getColorLuminance(hexColor);

  if (luminance > 0.179) {
    return '#FFFFFF'; // Bright colors get white text
  } else if (luminance > 0.09) {
    return '#000000'; // Mid-toned colors get black text
  } else {
    return '#BBBBBB'; // Dark colors get grey text
  }
}

export function getColorLuminance(hexColor: string): number {
  // Returns color luminance as number < 0; 1 >
  const color = parseColor(hexColor);

  if (typeof color !== 'string') {
    console.warn(`Wrong color format, please insert valid hex color`);
    return 1;
  }

  const split = chroma(`#${color}`).rgb();

  const R = colorLuminanceCheck(split[0]);
  const G = colorLuminanceCheck(split[1]);
  const B = colorLuminanceCheck(split[2]);

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
}

function colorLuminanceCheck(color: number): number {
  const color01 = color / 255;
  return color01 <= 0.03928
    ? color01 / 12.92
    : Math.pow((color01 + 0.055) / 1.055, 2.4);
}

function parseColor(color: string): string | null {
  if (!color) return null;
  let hexColor;
  const prefix = 'var(--v-theme-';
  if (color.startsWith(prefix)) {
    const colorArr = color.slice(prefix.length, -1).split('-');
    const colorLightness =
      colorArr.length > 1 ? [colorArr[1], colorArr[2]].join('-') : null;
    hexColor = getColorFromVariable(
      colorArr[0] as ColorType,
      colorLightness as Lightness
    );
  } else {
    hexColor = color;
  }

  const reg3 = /^[0-9a-f][0-9a-f][0-9a-f]$/i;
  const reg6 = /^[0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f][0-9a-f]$/i;
  switch (hexColor.length) {
    case 3:
      if (reg3.test(hexColor)) {
        return `${hexColor[0]}${hexColor[0]}${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}`;
      }
      return null;
    case 4:
      if (hexColor[0] !== '#') return null;
      if (reg3.test(hexColor.slice(1, 4))) {
        return `${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}${hexColor[3]}${hexColor[3]}`;
      }
      return null;
    case 6:
      if (reg6.test(hexColor)) {
        return hexColor;
      }
      return null;
    case 7:
      if (hexColor[0] !== '#') return null;
      if (reg6.test(hexColor.slice(1, 7))) {
        return hexColor.slice(1, 7);
      }
      return null;
    default:
      return null;
  }
}
