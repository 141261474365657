<template>
  <v-icon class="pr-1" :color="calculateRole.color"> mdi-circle </v-icon>
  {{ calculateRole.name }}
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useDirectStore } from '@/composables/store';
import { permissionRoles } from '@/services/permissionsCalculator';

const props = defineProps<{
  projectID: string;
}>();

const store = useDirectStore();

const calculateRole = computed(() => {
  if (store.userPermission.isAdmin()) return permissionRoles.admin;
  if (store.userPermission.isManager(props.projectID))
    return permissionRoles.manager;
  if (store.userPermission.isEditor(props.projectID))
    return permissionRoles.editor;
  else store.userPermission.isViewer(props.projectID);
  return permissionRoles.viewer;
  //else return permissionRoles.custom;
});
</script>
