import { FlatTreeNode } from '@/models/treeStructure';
import { NodeCollection } from '@/models/treeStructure';

export enum CoreSelectionType {
  Column = 'column',
  Nodes = 'nodes',
}

type NodeSelection = {
  type: CoreSelectionType;
  base: string;
  all: string[];
};

export class CoreNodesCollection implements NodeCollection {
  private selection: NodeSelection[] = [];
  private multiSelect: boolean;
  public lastSelected: string | null = null;

  public setMultiSelect(allow: boolean) {
    this.multiSelect = allow;
    if (!allow) {
      this.selection = this.lastSelected
        ? [{ type: CoreSelectionType.Nodes, base: this.lastSelected, all: [] }]
        : [];
    }
  }

  private selectedColumnIndex(nodeId: string): number {
    return this.selection.findIndex(
      (selection) =>
        selection.base === nodeId && selection.type == CoreSelectionType.Column
    );
  }

  private lastNodeTypeIndex() {
    return this.selection
      .map(({ type }) => type)
      .lastIndexOf(CoreSelectionType.Nodes);
  }

  private shouldCreateNewNodesType() {
    return !this.selection.some(
      (selection) => selection.type === CoreSelectionType.Nodes
    );
  }

  private removeAlreadySelected(ids: string[]): void {
    const selection = this.selection.map((selection) => {
      const all = selection.all.filter((id) => !ids.includes(id));
      const isBase = ids.includes(selection.base);
      if (isBase && selection.all.length === 1) return undefined;
      return {
        ...selection,
        all,
        base: all[0],
      };
    });

    this.selection = selection.filter(Boolean);
  }

  public isSelected(id: string) {
    return this.selectedNodes.includes(id);
  }

  public clear() {
    this.selection = [];
    this.lastSelected = null;
  }

  public deselectNode(id: string) {
    if (this.selectedNodes.length === 1) return;
    const selection = this.selection
      .map((selection) => {
        if (selection.base === id && selection.all.length) {
          const all = selection.all
            .splice(1, selection.all.length - 1)
            .filter((id) => id !== selection.base);
          return {
            ...selection,
            base: all[0],
            all,
          };
        }
        if (selection.base === id) {
          return undefined;
        }
        if (selection.all.length) {
          return {
            ...selection,
            all: selection.all.filter((nodeId) => nodeId !== id),
          };
        }
        return selection;
      })
      .filter(Boolean);
    this.selection = selection;
    this.lastSelected =
      this.selectedNodes.length > 1 ? null : this.selectedNodes[0];
  }

  private addToSelection(nodeId: string) {
    if (this.shouldCreateNewNodesType()) {
      this.selection.push({
        type: CoreSelectionType.Nodes,
        base: nodeId,
        all: [],
      });
      this.lastSelected = nodeId;
      return;
    }
    this.selection[this.lastNodeTypeIndex()].all.push(nodeId);
    this.lastSelected = nodeId;
  }

  public selectColumn(nodeId: string, tree: FlatTreeNode[]) {
    if (!this.multiSelect) return;
    if (!this.lastSelected) return;
    const start = tree.findIndex(({ item }) => item.id === this.lastSelected);
    const end = tree.findIndex(({ item }) => item.id === nodeId);
    const parentId = tree[start].item.parentId;
    if (tree[end].item.parentId !== parentId) return;

    const arrayIndexes = {
      start: start > end ? end : start,
      end: start > end ? start + 1 : end + 1,
    };

    const partTree = tree
      .slice(arrayIndexes.start, arrayIndexes.end)
      .filter(({ item }) => item.parentId === parentId)
      .map(({ item }) => item.id)
      .filter((id) => id !== this.lastSelected);
    this.removeAlreadySelected(partTree);

    const columnExist = this.selectedColumnIndex(this.lastSelected);
    if (columnExist < 0) {
      this.selection.push({
        type: CoreSelectionType.Column,
        base: this.lastSelected,
        all: [this.lastSelected, ...partTree],
      });
      return;
    }
    this.selection[columnExist].all = [this.lastSelected, ...partTree];
  }

  public selectNode(nodeId: string, options?: { override?: boolean }) {
    if (options?.override || !this.multiSelect) {
      this.clear();
      this.selection.push({
        type: CoreSelectionType.Nodes,
        base: nodeId,
        all: [nodeId],
      });
      this.lastSelected = nodeId;
      return;
    }
    this.addToSelection(nodeId);
  }

  public get selectedNodes() {
    if (!this.selection) return [];
    const ids = Object.values(this.selection).flatMap((node) => [
      node.base,
      ...node.all,
    ]);
    return [...new Set(ids)];
  }
}
