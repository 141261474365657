<template>
  <v-menu :offset="[8, -10]">
    <template #activator="{ props: attrs }">
      <v-btn v-bind="attrs" color="white" class="more-panel__activator" icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-sheet>
      <v-list-item
        :to="projectsRoute.location"
        :disabled="!projectsRoute.checkPermissions()"
        :prepend-icon="projectsRoute.icon"
        height="56"
        active-color="primary"
      >
        <v-list-item-title>
          {{ projectsRoute.name }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="subApp in subApplications"
        :key="subApp.id"
        :class="subApp.route === subAppRootRouteName && 'v-list-item--active'"
        :to="subApplicationRoute(subApp, subApp.parent)"
        :disabled="!subApp.checkPermissions.access"
        :prepend-icon="subApp.icon"
        height="56"
        active-color="primary"
      >
        <v-list-item-title>
          {{ subApp.name }}
        </v-list-item-title>
      </v-list-item>
    </v-sheet>
  </v-menu>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { RouteLocation } from 'vue-router';
import { useRoute, useRouter } from '@/composables/router';
import { RouteName } from '@/enums/routing';
import { Application } from '@/models/application';
import { SubApplication } from '@/models/subApplication';
import { getApplications } from '@/services/applicationsConfig';

const route = useRoute();
const router = useRouter();

const applications = computed<Application[]>(() =>
  getApplications().filter(
    (app) => !app.checkPermissions || app.checkPermissions()
  )
);

const projectsRoute = computed(() => {
  const projectApp = applications.value.find(
    (app) => app.route === RouteName.Design
  );

  return {
    ...projectApp,
    location: { name: RouteName.Projects },
  };
});

const subApplications = computed(() =>
  applications.value
    .filter((app) => app.route !== RouteName.Design && app.subApplications)
    .flatMap((app) =>
      app.subApplications(route.value).map((subApp) => ({
        ...subApp,
        parent: app,
      }))
    )
    .map((subApp) => {
      const hasPermission = subApp.checkPermissions
        ? subApp.checkPermissions(route.value)
        : { access: true };
      return {
        ...subApp,
        checkPermissions: hasPermission,
      };
    })
    .filter(Boolean)
);

const subAppRootRouteName = computed(() => {
  return route.value.matched.find((r) => r.meta.subApplicationRoot)?.meta
    .subApplicationRoot;
});

const subApplicationRoute = (
  subApplication: Omit<SubApplication, 'checkPermissions'>,
  application: Application
): Partial<RouteLocation> => {
  const subAppLocation = {
    name: subApplication.route,
    params: route.value.params,
  };
  const { fullPath } = router.resolve(subAppLocation, route.value);
  if (fullPath === '/') {
    // empty path means that some route parameters are missing and route is not resolved correctly
    // in such case we should redirect to application route which should not have any required params
    return {
      name: application.route,
      params: route.value.params,
      hash: `#${subApplication.route}`,
    };
  }
  return subAppLocation;
};
</script>
<style lang="scss" scoped>
.more-panel__activator {
  background-color: transparent;
}
</style>
