import { readonly, ref } from 'vue';
import { defineStore } from 'pinia';
import {
  postDomaByProjectExport,
  postProjectsByProjectByContextCoreExport,
  postProjectsByProjectByContextSimoExport,
} from '@/openapi';
import { localClient } from '@/openapi/client';

export const useExportStore = defineStore('export-store', () => {
  const ongoingExports = ref<Set<string>>(new Set());

  const domaExport = async (projectId: string) => {
    try {
      ongoingExports.value.add(projectId);
      await postDomaByProjectExport({
        client: localClient,
        path: {
          project: projectId,
        },
      });
    } catch (err) {
      console.error(err);
      ongoingExports.value.delete(projectId);
    }
  };

  const coreExport = async (projectId: string, context: string) => {
    try {
      ongoingExports.value.add(projectId);
      await postProjectsByProjectByContextCoreExport({
        client: localClient,
        path: {
          context: context,
          project: projectId,
        },
      });
    } catch (err) {
      console.error(err);
      ongoingExports.value.delete(projectId);
    }
  };
  const simoExport = async (projectId: string, context: string) => {
    try {
      ongoingExports.value.add(projectId);
      await postProjectsByProjectByContextSimoExport({
        client: localClient,
        path: {
          context: context,
          project: projectId,
        },
      });
    } catch (err) {
      console.error(err);
      ongoingExports.value.delete(projectId);
    }
  };

  const downloadFile = async (
    url: string,
    { filename = '' }: { filename?: string }
  ) => {
    try {
      const { data }: { data: Blob } = await localClient.get({
        url,
        responseType: 'blob',
      });
      const fileType = data.type === 'text/csv' ? 'csv' : 'xlsx';
      const link = document.createElement('a');
      const file = new Blob([data], { type: 'text/plain' });
      const name = filename || 'Export';
      link.href = URL.createObjectURL(file);
      link.download = `${name}.${fileType}`;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (err) {
      console.error(err);
    }
  };

  return {
    domaOngoingExports: readonly(ongoingExports),
    domaExport,
    coreExport,
    simoExport,
    downloadFile,
    removeDomaOngoingExport: (projectId: string) =>
      ongoingExports.value.delete(projectId),
  };
});
