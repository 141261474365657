<template>
  <div class="ooc-editor-wrapper">
    <form data-spec="object-occurrence-editor" @submit.prevent="submit">
      <div class="input-wrapper">
        <template v-if="!noSyntaxElementSelected">
          <div class="code-field">
            <span>{{ selectionAspect }}</span>
            <span data-spec="ooc-code">{{ selectionClassificationCode }}</span>
          </div>
          <div class="number-input">
            <v-text-field
              data-spec="number-input"
              :id="numberInputId"
              v-model="numberInput"
              placeholder="1"
              variant="underlined"
              density="compact"
              min="1"
              hide-details
              type="number"
              :error="!isNumberValid"
              @keydown.self="onKeydown"
            />
          </div>
        </template>
        <OOCEditorAutocomplete
          v-model="selection"
          :editedOOCName="editedOOCName"
          :allowedSyntaxElements="allowedSyntaxElements"
          :placeholder="autocompletePlaceholder"
          :disabled="reachedSyntaxLimit"
          :attach="attach"
          :dense="zoom <= 1"
          :autofocus="editorType === oocEditTypes.Name"
          :markPreviousName="!!suggestion"
          hide-details
          @submit="submit"
          @cancel="cancel"
        />
        <div class="buttons-wrapper">
          <v-tooltip location="top">
            <span>Save</span>
            <template #activator="{ props }">
              <v-btn
                data-spec="save-btn"
                v-bind="props"
                ref="save"
                :disabled="isSubmitDisabled"
                :loading="isSaving"
                size="small"
                variant="text"
                color="primary"
                type="submit"
              >
                <v-icon size="small"> mdi-content-save-edit </v-icon>
              </v-btn>
            </template>
          </v-tooltip>
          <v-tooltip location="top">
            <span>Cancel</span>
            <template #activator="{ props }">
              <v-btn
                ref="cancel"
                v-bind="props"
                color="error"
                variant="text"
                size="small"
                @click.stop="cancel"
                @keydown.enter.stop="cancel"
                @keydown.self="onKeydown"
              >
                <span>
                  <v-icon size="small"> mdi-close </v-icon>
                </span>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Inject, Options, Prop, Vue } from 'vue-property-decorator';
import {
  OOCEditorPatch,
  OOCEditorPayload,
  OOC,
  OOCType,
} from '@/models/objectOccurrence';
import { SyntaxElement } from '@/models/syntaxElement';
import OOCEditorAutocomplete from './OOCEditorAutocomplete.vue';
import {
  defaultSuggestion,
  isValidOOCSuggestion,
  OOCEditorSuggestion,
} from '@/utils/oocSuggestions';
import { resourceRelationship, resources } from '@/services/resources';
import { OocEditTypes } from '@/components/treeStructure/types';
import { TreeContext } from '@/models/treeStructure';

@Options({
  name: 'OOCEditor',
  components: {
    OOCEditorAutocomplete,
  },
  emits: ['create', 'patch', 'cancel'],
})
export default class OOCEditor extends Vue {
  @Prop() parentOOCId: string;
  @Prop() editedOOCId: string;
  @Prop(Boolean) editorMode: boolean;
  @Prop(Boolean) isSaving: boolean;
  @Prop({ default: 1 }) position: number;
  @Prop({ default: 1 }) zoom: number;
  @Prop() suggestion?: OOCEditorSuggestion;
  @Prop() editorType?: OocEditTypes;
  @Prop() attach: HTMLElement | string | undefined;

  @Inject({ from: 'treeContext' }) context: TreeContext;

  selection: OOCEditorSuggestion = defaultSuggestion();
  numberInput: number = null;

  get selectionAspect(): string {
    return this.selection.aspect || '';
  }

  get selectionClassificationCode(): string {
    return this.selection.classification_entry_code || '';
  }

  get oocEditTypes() {
    return OocEditTypes;
  }

  get autocompletePlaceholder() {
    if (this.reachedSyntaxLimit) return "Can't add OOC, syntax limits reached";
    // keep current name as placeholder (autocomplete does not show query while not focused)
    return this.selection.name || 'Start typing...';
  }

  onKeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      e.preventDefault();
      this.cancel();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      this.submit();
    }
  }

  get isSubmitDisabled() {
    return (
      this.isSaving || this.noSyntaxElementSelected || !this.payloadIsValid
    );
  }

  get numberInputId() {
    return crypto.randomUUID();
  }

  submit() {
    if (this.noNewData) return this.cancel();
    if (this.isSubmitDisabled) return;

    if (this.editorMode) {
      this.$emit('patch', {
        node: this.patchPayload,
        syntaxSelection: this.selection,
      });
    } else {
      this.$emit('create', this.fullPayload);
    }
  }

  focusCancelButton() {
    ((this.$refs.cancel as Vue)?.$el as HTMLButtonElement)?.focus();
  }

  /*
    It means that we just have started making brand new OOC
  */
  get noSyntaxElementSelected() {
    return !this.fullPayload.prefix;
  }

  getSyntaxElementId(oocId: string) {
    return this.$store.$direct.ooc.getRelationshipId(
      oocId,
      resourceRelationship(resources.objectOccurrences)('syntax_element')
    ) as string;
  }

  getClassificationEntryId(oocId: string) {
    return this.$store.$direct.ooc.getRelationshipId(
      oocId,
      resourceRelationship(resources.objectOccurrences)('classification_entry')
    ) as string;
  }

  get editedOocSuggestion(): OOCEditorSuggestion {
    const syntaxElementId = this.editorMode
      ? this.getSyntaxElementId(this.editedOOCId)
      : undefined;
    const classificationEntryId = this.editorMode
      ? this.getClassificationEntryId(this.editedOOCId)
      : undefined;

    return defaultSuggestion(
      {
        ...this.editedOOC,
        classification_code:
          this.suggestion?.classification_entry_code ||
          this.editedOOC?.classification_code,
      },
      this.suggestion?.syntax_element_id
        ? this.suggestion.syntax_element_id
        : syntaxElementId,
      this.suggestion?.classification_entry_id
        ? this.suggestion.classification_entry_id
        : classificationEntryId
    );
  }

  get initialSelection(): OOCEditorSuggestion {
    return this.editedOocSuggestion;
  }

  get initialNumber(): number | null {
    return this.editorMode ? this.editedOOC.number : null;
  }

  get parentOOC(): OOC {
    return this.$store.$direct.ooc.getSimplifiedResourceSet()[this.parentOOCId];
  }

  get editedOOC(): OOC {
    return this.editorMode
      ? this.$store.$direct.ooc.getSimplifiedResourceSet()[this.editedOOCId]
      : undefined;
  }

  get editedOOCName() {
    return this.editedOOC?.name;
  }

  get reachedSyntaxLimit(): boolean {
    return !this.allowedSyntaxElements.length;
  }

  get allowedSyntaxElements(): SyntaxElement[] {
    return this.parentOOC.allowed_children_syntax_elements || [];
  }

  get fullPayload(): OOCEditorPayload {
    return {
      id: this.editedOOCId,
      parentId: this.parentOOCId,
      name: this.selection?.name?.trim?.(),
      classification_code: this.selection.classification_entry_code,
      classification_entry_id: this.selection.classification_entry_id,
      prefix: this.selection.aspect,
      number: this.numberInput,
      position: this.editorMode ? this.editedOOC.position : this.position,
      object_occurrence_type: this.editorMode
        ? this.editedOOC.object_occurrence_type
        : OOCType.Regular,
      hex_color: this.editorMode
        ? this.editedOOC.hex_color
        : this.selection.hex_color || '',
      syntax_element_name: this.selection.syntax_element_name,
      syntax_element_id: this.selection.syntax_element_id,
    };
  }

  get patchPayload(): OOCEditorPatch {
    const oldData = this.initialSelection;
    const pickIfChanged = <T extends string | number>(value: T, oldValue: T) =>
      value !== oldValue ? value : undefined;

    return {
      id: this.fullPayload.id,
      name: pickIfChanged(this.fullPayload.name, oldData.name),
      number: pickIfChanged(this.numberInput, this.initialNumber),
      classification_entry_id:
        this.suggestion?.classification_entry_id ||
        pickIfChanged(
          this.fullPayload.classification_entry_id,
          oldData.classification_entry_id
        ),
      syntax_element_id:
        this.suggestion?.syntax_element_id ||
        pickIfChanged(
          this.fullPayload.syntax_element_id,
          oldData.syntax_element_id
        ),
      // skip the rest of attributes - it's not possible to change them with OOCEditor:
      // position, object_occurrence_type, hex_color (always based on SE in OOC Editor)
    };
  }

  get isNumberValid() {
    const number = this.fullPayload.number;
    return number == null || number === '' || +number > 0;
  }

  get payloadIsValid(): boolean {
    return isValidOOCSuggestion(this.selection) && this.isNumberValid;
  }

  /*
    Payload for edited OOC equals original data
  */
  get noNewData(): boolean {
    if (!this.editorMode) return this.noSyntaxElementSelected;
    return Object.entries(this.patchPayload).every(([key, value]) => {
      return key === 'id' || !value;
    });
  }

  notifyError(message) {
    this.$store.$direct.notifications.dispatchNotify({
      message,
      type: 'error',
    });
  }

  cancel() {
    this.$emit('cancel');
  }

  getNumberInput(): HTMLInputElement {
    return document.getElementById(this.numberInputId) as HTMLInputElement;
  }

  mounted() {
    if (this.reachedSyntaxLimit) {
      this.notifyError("Reached syntax limit. Can't create OOC");
      this.$nextTick(this.focusCancelButton);
    }
    if (this.editorType === this.oocEditTypes.Number) {
      this.getNumberInput()?.focus();
      this.getNumberInput()?.select();
    }
  }
  created() {
    this.selection = this.initialSelection;
    this.numberInput = this.initialNumber;

    if (
      this.suggestion &&
      this.suggestion.classification_entry_code !==
        this.editedOOC.classification_code
    )
      this.numberInput = this.context.nextValidNumber(this.fullPayload);
  }
}
</script>

<style lang="scss">
:root {
  --secCoreTreeZoomFactor: 1;
  --sec-nodeBottomPadding: calc(16px * var(--secCoreTreeZoomFactor));
  --sec-nodeItemHeight: calc(58px * var(--secCoreTreeZoomFactor));
  --sec-verticalLineTop: calc(
    -1 * calc(calc(var(--sec-nodeBottomPadding) +
            calc(var(--sec-nodeItemHeight) / 2)))
  );
  --sec-deepthVerticalLineHeight: calc(
    var(--sec-nodeItemHeight) + var(--sec-nodeBottomPadding)
  );
  --sec-nodeFontSize: 16px;
  --sec-nodePadding: calc(16px * var(--secCoreTreeZoomFactor));
}
</style>

<style scoped lang="scss">
$expanderSize: 24px;
$horizontalLineWidth: 68px;
$nodeExpanderMargin: 56px;
$line-color: rgb(var(--v-theme-grayScale));
$border-line: 1px solid $line-color;

.ooc-editor-wrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  height: auto;
  overflow: visible;
  font-size: var(--core-node-font-size);
  form {
    height: 100%;
  }

  .input-wrapper {
    padding: 0 4px 0 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    :deep(.v-input) {
      padding-bottom: 8px;
    }

    .code-field {
      color: rgb(var(--v-theme-color));
    }

    .code-field,
    .number-input {
      margin-right: 7px;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-right: -4px;
      margin-left: 10px;
      min-width: 70px;

      & > button {
        margin: 0;
        min-width: 0;
        padding: 0 6px;
      }
    }
  }

  .code-field {
    display: flex;
    align-items: center;
    height: 40px;
    margin-right: 10px;
    font-weight: bold;
  }

  .number-input {
    width: 100px;
  }
}
</style>
