import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';
import { ApplicationType } from '@/enums/applicationType';
import { RouteName } from '@/enums/routing';

export type SecRouteConfig = RouteRecordRaw & {
  children?: SecRouteConfig[];
  name?: RouteName;
};

export const createAppRoute = (
  type: ApplicationType,
  config: SecRouteConfig
) => ({
  ...config,
  meta: {
    ...config.meta,
    applicationType: type,
  },
});

export const createSubAppRoutes = (
  mainRoute: RouteName,
  children: SecRouteConfig[]
): SecRouteConfig => {
  const route = {
    path: '',
    component: {
      render() {
        return h(RouterView, this.$attrs);
      },
    },
    redirect: { name: mainRoute },
    children,
    meta: { subApplicationRoot: mainRoute },
  };
  return route;
};
