import {
  findRelationshipByType,
  getResourceByType,
  resources,
  ResourceType,
} from '@/services/resources';
import { createModule } from '../utils/factories';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import jv from '../components/jv';
import { JsonApiIdentification } from '@/models/api';

export default createModule({
  path: 'customFieldValue',
  resourceProfile: resources.customFieldValue,
  components: [crud, indexManager, jv],
  setup({ getAccessors, components, resourceProfile }) {
    const getters = {
      customFieldValue:
        () =>
        (payload: {
          parentId: string;
          parentType: ResourceType;
          customFieldId: string;
        }) => {
          const resources = components.$jv.protected.get(
            resourceProfile.type,
            // https://github.com/dchester/jsonpath#jsonpath-syntax
            [
              '$[?(',
              `@._jv.relationships.parent.data?.id=="${payload.parentId}" &&`,
              `@._jv.relationships.custom_field.data?.id=="${payload.customFieldId}"`,
              ')]',
            ].join('')
          );
          const id = Object.keys(resources)[0];
          return id && components.$crud.public.getSimplifiedResourceSet()[id];
        },
    };

    const actions = {
      async setCustomFieldValue(
        context,
        payload: {
          value: string;
          parentId: string;
          parentType: ResourceType;
          customFieldId: string;
        }
      ): Promise<{ id: string } | void> {
        const currentCustomFieldValue = read(getters.customFieldValue)(payload);
        if (currentCustomFieldValue && !payload.value?.trim()) {
          return components.$crud.public.dispatchDeleteResource({
            resourceId: currentCustomFieldValue.id,
          });
        }

        if (currentCustomFieldValue) {
          return components.$crud.public
            .dispatchUpdateResource({
              resourceId: currentCustomFieldValue.id,
              resource: { description: payload.value },
            })
            .then(() => ({ id: currentCustomFieldValue.id }));
        }
        const { id } = await components.$crud.public.dispatchCreateResource({
          resource: {
            description: payload.value,
          },
          relationships: {
            custom_field: payload.customFieldId,
            parent: {
              id: payload.parentId,
              type: payload.parentType,
            },
          },
        });

        components.$jv.protected.commitAlterRelationship({
          id: payload.parentId,
          type: payload.parentType,
          relationshipKey: findRelationshipByType(
            getResourceByType(payload.parentType),
            resourceProfile.type
          ),
          relationship: (currentRelationship) => {
            return (
              currentRelationship && {
                ...currentRelationship,
                data: [
                  ...((currentRelationship.data as JsonApiIdentification[]) ||
                    []),
                  { id, type: resourceProfile.type },
                ],
              }
            );
          },
        });
      },
    };

    const { read, dispatch } = getAccessors();
    return {
      module: { getters, actions },
      public: {
        getCustomFieldValue: read(getters.customFieldValue),
        dispatchSetCustomFieldValue: dispatch(actions.setCustomFieldValue),
        dispatchLoadAllByParent: (payload: { parentId: string }) =>
          components.$indexManager.public.dispatchLoadFullResource({
            filterParentId: payload.parentId,
          }),
      },
    };
  },
});
