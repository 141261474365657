import Admin from '@/views/admin.vue';
import { ApplicationType } from '@/enums/applicationType';
import { RouteName } from '@/enums/routing';
import {
  createAppRoute,
  createSubAppRoutes,
  SecRouteConfig,
} from '@/router/utils';

const adminRoutes: SecRouteConfig[] = [
  createAppRoute(ApplicationType.Admin, {
    path: '/admin',
    name: RouteName.Admin,
    component: Admin,
    redirect: { name: RouteName.Users },
    meta: { requiresAuth: true },
    children: [
      createSubAppRoutes(RouteName.Users, [
        {
          path: 'users',
          name: RouteName.Users,
          component: () => import('@/components/admin/users/users.vue'),
          meta: {
            title: 'User manager',
          },
        },
      ]),
    ],
  }),
];

export default adminRoutes;
