<script lang="ts">
import { defineComponent, h } from 'vue';
import {
  login,
  clearIdToken,
  clearAccessToken,
  setIdToken,
  setAccessToken,
  getAccessToken,
  hasAuthParameters,
  STORAGE_REDIRECT_URL,
} from '@/auth';
import { setApiHeader, hasHeader } from '@/services/api';
import { simoListKey } from '@/utils/constants';

export default defineComponent({
  name: 'AuthCallback',
  render: () => h('div'),
  mounted() {
    let redirectToUrl = localStorage.getItem(STORAGE_REDIRECT_URL);
    if (!redirectToUrl) redirectToUrl = '/';

    if (hasAuthParameters()) {
      this.$nextTick(() => {
        setAccessToken();
        setIdToken();
        if (!hasHeader('Authorization')) {
          const token = getAccessToken();
          setApiHeader('Authorization', `Bearer ${token}`);
        }
        window.location.href = redirectToUrl;
        localStorage.removeItem(STORAGE_REDIRECT_URL);
      });
    } else {
      localStorage.removeItem(simoListKey);
      clearIdToken();
      clearAccessToken();
      login();
    }
  },
});
</script>
