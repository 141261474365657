import PrinterCanvas from './PrinterCanvas';
import PrinterRequest from './PrinterRequest';

interface PagedMediaRules {
  orientation?: 'landscape' | 'portrait';
  margin?: string;
}
interface PrinterPayload {
  element: Element;
  title?: string;
  style?: PagedMediaRules;
  onBeforePrint?: (request: PrinterRequest) => void | Promise<void>;
  onAfterPrint?: (request: PrinterRequest) => void | Promise<void>;
}

export function printElement({
  element,
  title,
  style,
  onBeforePrint,
  onAfterPrint,
}: PrinterPayload): PrinterRequest {
  const canvas = new PrinterCanvas(element, title);
  canvas.mount();
  const request = new PrinterRequest(canvas);
  setTimeout(async () => {
    if (onBeforePrint) {
      try {
        await onBeforePrint(request);
      } catch (error) {
        console.error(error);
        request.destroy();
        return;
      }
    }
    setPagedMediaStyle(style);
    await request.print();
    setPagedMediaStyle();
    if (onAfterPrint) onAfterPrint(request);
  });
  return request;
}

const setPagedMediaStyle = (() => {
  const styleBlock = document.createElement('style');
  document.head.appendChild(styleBlock);
  return (rules: PagedMediaRules = {}) => {
    styleBlock.innerHTML = `
      @page {
        size: ${rules.orientation || 'auto'};
        margin: ${rules.margin || 'auto'};
      }
    `;
  };
})();
