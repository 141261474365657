import { computed, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { createSupply } from '@/router/supplyChain/utils';

export default createSupply<{
  contextIds: string[];
}>(({ props, onFail }) => {
  const store = useDirectStore();

  const getProjectId = (contextId: string) =>
    store.context.getRelatedProjectId(contextId);

  const haveAllProjectIds = computed(() => {
    return props.contextIds.every((contextId) => getProjectId(contextId));
  });

  const haveSameProject = computed(() => {
    if (!props.contextIds.length) return true;
    const projectId = getProjectId(props.contextIds[0]);
    return props.contextIds
      .slice(1)
      .every((contextId) => getProjectId(contextId) === projectId);
  });

  const isReady = computed(() => haveSameProject.value);

  watch(
    haveAllProjectIds,
    (value) => {
      if (value && !isReady.value) onFail();
    },
    { immediate: true }
  );

  return {
    isReady,
  };
});
