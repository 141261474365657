import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import indexManager from '../components/indexManager';
import crud from '../components/crud';

export default createModule({
  path: 'progressModel',
  resourceProfile: resources.progressModel,
  components: [indexManager, crud],
  setup({ components }) {
    return {
      module: {},
      public: {
        ...components.$indexManager.public,
        getSimplifiedResourceSet:
          components.$crud.public.getSimplifiedResourceSet,
      },
    };
  },
});
