import { ColorType, Lightness } from './colorPalette';
import chroma from 'chroma-js';

const rgbToHex = (rgb: string): string => {
  return chroma(`rgb(${rgb})`).hex();
};

export function getColorFromVariable(
  colorType: ColorType,
  lightness?: Lightness
) {
  // check current theme to tell vue reactivity system
  // that this results depends on it – if this method is called
  // inside computed value it will be recomputed on every theme change
  // tslint:disable-next-line: no-unused-expression
  // vuetify.framework.theme.currentTheme;
  const prefix = `--v-theme-${colorType}`;
  const fullVarName = lightness ? `${prefix}-${lightness}` : prefix;
  const value = getComputedStyle(document.documentElement).getPropertyValue(
    fullVarName
  );
  if (value === '') {
    console.warn('CSS variable: ', colorType, ' does not exist.');
    return '#000000';
  }
  return rgbToHex(value.trim());
}
