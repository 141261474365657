import { DomaClassificationEntry } from '@/models/domaClassificationEntry';
import { SyntaxAspectType } from '@/models/syntax';

export const isAllowedDomaClassificationEntry = (
  from: SyntaxAspectType,
  to: SyntaxAspectType,
  entry: DomaClassificationEntry
): boolean => {
  const bothFunctional = from === 'functional' && to === 'functional';
  const bothLocation = from === 'location' && to === 'location';
  const bothProduct = from === 'product' && to === 'product';
  const bothType = from === 'type' && to === 'type';
  const isUserDefined = from === 'user_defined' || to === 'user_defined';
  2;

  const fromLocationToType = from === 'location' && to === 'type';
  const fromTypeToLocation = from === 'type' && to === 'location';
  const fromFunctionalToType = from === 'functional' && to === 'type';
  const fromTypeToFunctional = from === 'type' && to === 'functional';

  // TODO: Code below should be implemented based on new csv file we get from backend

  return (
    isUserDefined ||
    ((bothFunctional || bothProduct) && entry.from_aspect === 'functional') ||
    (bothLocation && entry.from_aspect === 'location') ||
    (bothType && entry.from_aspect === 'type') ||
    ((fromLocationToType || fromTypeToLocation) &&
      entry.from_aspect !== 'object' &&
      (entry.from_aspect === 'location' || entry.from_aspect === 'type')) ||
    ((fromFunctionalToType || fromTypeToFunctional) &&
      entry.from_aspect !== 'object' &&
      (entry.from_aspect === 'functional' || entry.from_aspect === 'type')) ||
    ((entry.from_aspect === 'object' || entry.from_aspect === from) &&
      !fromLocationToType &&
      !fromFunctionalToType &&
      !bothType &&
      (entry.to_aspect === 'object' || entry.to_aspect === to)) ||
    ((entry.to_aspect === 'object' || entry.to_aspect === from) &&
      !fromTypeToLocation &&
      !fromTypeToFunctional &&
      !bothType &&
      (entry.from_aspect === 'object' || entry.from_aspect === to))
  );
};

export const directedDomaClassificationEntry = (
  from: SyntaxAspectType,
  to: SyntaxAspectType,
  entry: DomaClassificationEntry
): DomaClassificationEntry => {
  if (entry.to_aspect === from || entry.from_aspect === to) {
    return reverseDomaClassificationEntry(entry);
  }
  return entry;
};

export const reverseDomaClassificationEntry = (
  entry: DomaClassificationEntry
): DomaClassificationEntry => {
  return {
    ...entry,
    a_to_b_name: entry.b_to_a_name,
    b_to_a_name: entry.a_to_b_name,
    from_aspect: entry.to_aspect,
    to_aspect: entry.from_aspect,
  };
};
