import { getAccessToken } from '@/auth';
import { createClient } from '@hey-api/client-axios';
import { Operation } from 'fast-json-patch';
import { OperationType, PatchOperation } from './types.gen';

const DOTNET_BASE_URL = window.__env.VUE_APP_DOTNET_API_URL;

export const localClient = createClient({
  baseURL: DOTNET_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

localClient.instance.interceptors.request.use((config) => {
  config.paramsSerializer = {
    indexes: null,
  };
  config.headers.set('Authorization', 'Bearer ' + getAccessToken());
  return config;
});

// For editing entities we are using RFC6902 - JSON Patch: https://datatracker.ietf.org/doc/html/rfc6902
// For creating the list of patches to an object we use fast-json-patch and because of that we need
// to convert the generated operation type from openapi to the one from fast-json-patch
type GetObjectChangeOperations = (operations: Operation[]) => PatchOperation[];
export const getObjectChangeOperations: GetObjectChangeOperations = (
  operations
) =>
  operations.map((patch: Operation) => ({
    op: patch.op as OperationType,
    path: patch.path,
    value: 'value' in patch ? patch.value : undefined,
    from: 'from' in patch ? patch.from : undefined,
  }));
