<template>
  <portal to="projects-drawer-list">
    <v-slide-x-transition>
      <v-sheet
        v-if="modelValue"
        ref="projectsDrawerList"
        class="project-switcher-drawer elevation-10"
        id="project-switcher-drawer"
      >
        <v-list-item height="60">
          <v-list-item-title class="projects-switcher-drawer__heading">
            Choose a project
          </v-list-item-title>

          <v-list-item-action>
            <v-btn
              width="36"
              height="36"
              icon
              variant="text"
              @click="emit('update:modelValue', false)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-skeleton-loader
          v-if="loadingProjects && !activeProjects.length"
          type="list-item, list-item, list-item"
        />
        <template v-else>
          <v-list class="projects-list">
            <v-list-item
              v-for="project in activeProjects"
              :key="project.id"
              base-color="primary"
              class="projects-list__item"
              @click="goToProject(project.id)"
            >
              <v-list-item-title>
                {{ project.name }}
              </v-list-item-title>
              <template #append>
                <v-list-item-action v-if="isCurrentProject(project.id)" end>
                  <v-chip color="primary" size="small"> current </v-chip>
                </v-list-item-action>
              </template>
            </v-list-item>
            <template v-if="canSeeArchivedContexts">
              <v-list-item
                base-color="primary"
                class="projects-list__archived-activator"
                @click="showArchivedProjects = !showArchivedProjects"
              >
                <template #prepend>
                  <v-list-item-action start>
                    <v-icon color="primary" size="small">
                      {{
                        showArchivedProjects
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-right'
                      }}
                    </v-icon>
                  </v-list-item-action>
                </template>
                <v-list-item-title>
                  {{
                    showArchivedProjects
                      ? 'Hide archived projects'
                      : 'Show archived projects'
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-skeleton-loader
                v-if="showArchivedProjects && loadingArchivedProjects"
                type="list-item, list-item, list-item"
              />
              <div v-if="showArchivedProjects && !loadingArchivedProjects">
                <v-list-item
                  v-for="project in archivedProjects"
                  :key="project.id"
                  class="projects-list__item"
                  base-color="primary"
                  @click="goToProject(project.id)"
                >
                  <v-list-item-title>
                    {{ project.name }}
                  </v-list-item-title>
                  <template #append>
                    <v-list-item-action v-if="isCurrentProject(project.id)" end>
                      <v-chip color="primary" size="small"> current </v-chip>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </div>
              <v-list-item v-if="showEmptyArchiveProjects">
                <v-list-item-title class="projects-list__archived-empty">
                  No archived projects found
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </template>
      </v-sheet>
    </v-slide-x-transition>
  </portal>
</template>
<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { Portal } from 'portal-vue';
import { computed, nextTick, ref, watch } from 'vue';
import { useDirectStore } from '@/composables/store';
import { PathParameters } from '@/utils/path';
import { composeMessage } from '@/services/errorHandler';
import { Project } from '@/models/project';
import { useRoute, useRouter } from '@/composables/router';
import { RouteName } from '@/enums/routing';

type Props = {
  modelValue: boolean;
  ignoreElement: string;
};

type Emits = {
  (e: 'input', value: boolean): void;
  (e: 'update:modelValue', value: boolean): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const store = useDirectStore();
const route = useRoute();
const router = useRouter();

const loadingProjects = ref(false);
const loadingArchivedProjects = ref(false);
const showArchivedProjects = ref(false);

const projectsDrawerList = ref();
const projectId = computed(() => route.value.params.projectId);

const ACTIVE_PROJECTS_REQUEST_TYPE = 'switcher-projects-active';
const ARCHIVED_PROJECTS_REQUEST_TYPE = 'switcher-projects-archived';

const activeProjects = computed<Project[]>(() =>
  store.project.getFullResource(ACTIVE_PROJECTS_REQUEST_TYPE)
);

const archivedProjects = computed<Project[]>(() =>
  store.project.getFullResource(ARCHIVED_PROJECTS_REQUEST_TYPE)
);

const showEmptyArchiveProjects = computed<boolean>(
  () =>
    showArchivedProjects.value &&
    !loadingArchivedProjects.value &&
    !archivedProjects.value.length
);

const setLoading = (isArchived: boolean, value: boolean) => {
  if (isArchived) loadingArchivedProjects.value = value;
  else loadingProjects.value = value;
};

const loadProjects = async (
  config: Pick<
    PathParameters,
    'filterArchived' | 'filterArchivedOrHasArchivedContent'
  > & { requestType: string }
) => {
  setLoading(config.filterArchived, true);
  await store.project
    .dispatchLoadFullResource({ ...config })
    .catch((err) =>
      store.notifications.dispatchNotify({
        type: 'error',
        message: composeMessage(err),
      })
    )
    .finally(() => setLoading(config.filterArchived, false));
};

const isCurrentProject = (id: string) => projectId.value === id;

const canGoToProject = (projectId: string) => {
  const isCurrent = isCurrentProject(projectId);
  const isOnProjectDetailsPage = route.value.name === RouteName.ProjectDetails;

  return !isCurrent || (isCurrent && !isOnProjectDetailsPage);
};

const goToProject = (projectId: string) => {
  if (!canGoToProject(projectId)) return;
  router.push({
    name: RouteName.ProjectDetails,
    params: {
      projectId,
    },
  });
};

onClickOutside(projectsDrawerList, () => emit('update:modelValue', false), {
  ignore: [props.ignoreElement],
});

const scrollToBottom = () => {
  const drawer = document.getElementById('project-switcher-drawer');
  drawer.scrollTo({
    top: drawer.scrollHeight,
    behavior: 'smooth',
  });
};

const canSeeArchivedContexts = computed(() =>
  store.userPermission.canSeeArchivedProjects()
);

watch(
  () => props.modelValue,
  (value) => {
    if (!value) return;
    loadProjects({
      filterArchived: false,
      requestType: ACTIVE_PROJECTS_REQUEST_TYPE,
    });
  }
);

watch(showArchivedProjects, async (value) => {
  if (!value) return;
  await loadProjects({
    filterArchivedOrHasArchivedContent: true,
    filterArchived: true,
    requestType: ARCHIVED_PROJECTS_REQUEST_TYPE,
  });
  nextTick(() => {
    scrollToBottom();
  });
});
</script>
<style lang="scss" scoped>
.project-switcher-drawer {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100vh - 64px);
  width: 400px;
  overflow: auto;
  z-index: 2000;
}
.project-switcher-drawer > :deep(.v-list-item > .v-list-item__content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.projects-switcher-drawer__heading {
  font-weight: 600;
}
.projects-list {
  padding: 0;
}
.projects-list__item {
  border-top: 1px solid rgba(#212121, 0.08);
  background-color: #fbfbfb;

  :deep(.v-list-item-title) {
    font-weight: 700;
    font-size: 14px;
  }

  :deep(.v-list-item__action) {
    min-width: fit-content;
  }
}

.projects-list__item:last-child {
  border-bottom: 1px solid rgba(#212121, 0.08);
}
.projects-list__archived-activator {
  gap: 8px;
  :deep(.v-list-item-title) {
    font-size: 14px;
  }
}

.projects-list__archived-empty {
  text-align: center;
  font-size: 14px;
  color: var(--v-colorInverse-darken4);
}
</style>
