import { createModule, ModuleProtectedAccessors } from '../utils/factories';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { utils } from 'jsonapi-vuex';
import type { AtomicOperation } from '@/models/jv';
import { api } from '@/services/api';
import { extractPollingId } from '@/utils/path';

export type JvBatchAccessors = ModuleProtectedAccessors<typeof jvBatch>;

type OperationHandler = {
  operation: AtomicOperation;
  resolve: (response: AxiosResponse) => void;
  reject: (response: AxiosError) => void;
};

type State = {
  activeBatch: {
    operations: OperationHandler[];
  } | null;
};

const jvBatch = createModule({
  path: 'jvBatch',
  setup({ getAccessors }) {
    const state = (): State => ({
      activeBatch: null,
    });

    const getters = {
      activeBatch: (state: State) => state.activeBatch,
      hasActiveBatch: (state: State) => !!state.activeBatch,
    };

    const mutations = {
      initializeBatch(state: State) {
        if (state.activeBatch) {
          throw new Error('[jvBatch] Active batch already exists');
        }
        state.activeBatch = { operations: [] };
      },
      clearBatch(state: State) {
        if (!state.activeBatch) {
          throw new Error('[jvBatch] No active batch');
        }
        state.activeBatch = null;
      },
      addToBatch(state: State, payload: OperationHandler) {
        if (!state.activeBatch) {
          throw new Error('[jvBatch] No active batch');
        }
        state.activeBatch.operations.push(payload);
      },
    };

    const actions = {
      startBatchTransaction() {
        commit(mutations.initializeBatch)();
        // batch operations should be added synchronously
        // close batch at the end of the tick
        setTimeout(() => {
          if (read(getters.hasActiveBatch)()) {
            console.error(
              '[jvBatch] Batch should be initialized and cleared within the same tick'
            );
            commit(mutations.clearBatch)();
          }
        });
      },
      addToBatch(context, payload: { operation: AtomicOperation }) {
        let resolve, reject;
        const mockedResponse = new Promise<AxiosResponse>((res, rej) => {
          resolve = res;
          reject = rej;
        });
        commit(mutations.addToBatch)({
          operation: payload.operation,
          resolve,
          reject,
        });
        return mockedResponse;
      },
      async performBatchTransaction(context, payload: { url: string }) {
        if (!read(getters.hasActiveBatch)()) {
          throw new Error('[jvBatch] No active batch');
        }
        const operations = read(getters.activeBatch)().operations;
        commit(mutations.clearBatch)();
        const atomicOperations = operations.map(({ operation }) => ({
          op: operation.type,
          href: operation.payload[1].url,
          data:
            operation.type === 'remove'
              ? undefined
              : utils.normToJsonapi(operation.payload[0])?.data,
        }));
        let response: AxiosResponse;
        let error: unknown;
        try {
          const asyncResponse = await api({
            url: payload.url,
            method: 'post',
            headers: {
              'Content-Type':
                'application/vnd.api+json; ext="https://jsonapi.org/ext/atomic"',
              Accept:
                'application/vnd.api+json; ext="https://jsonapi.org/ext/atomic"',
            },
            data: { 'atomic:operations': atomicOperations },
          });

          const pollLocation = asyncResponse.headers.location;
          const pollId = extractPollingId(pollLocation);
          if (!pollId) throw new Error('Unexpected response');
          const pollingPromise = context.dispatch(
            'polling/addJob',
            { id: pollId, url: pollLocation },
            { root: true }
          ) as Promise<AxiosResponse>;

          response = await pollingPromise;
        } catch (err) {
          error = err;
        }

        const hasErrors = !!error || !response;
        /* Why: if we would like to execute all operations at once
         *      it could happen that data in resolve would not have data passed for add operation
         *
         *      If we would like to execute all operations at once and there will be remove and add operations
         *      we will pass no data for resolve method for our operations with add type
         *
         *      operations can have length of 100 and data in response can have length of 50 (only if we are adding something)
         */
        const removeOperations = operations.filter(
          ({ operation }) => operation.type === 'remove'
        );
        const addOperations = operations.filter(
          ({ operation }) => operation.type === 'add'
        );
        const updateOperations = operations.filter(
          ({ operation }) => operation.type === 'update'
        );

        removeOperations.forEach(({ resolve, reject }, index) => {
          if (!hasErrors) {
            resolve({
              ...response,
              data: response.data['atomic:results'][index],
            });
          } else {
            reject(error as AxiosError);
          }
        });
        addOperations.forEach(({ resolve, reject }, index) => {
          if (!hasErrors) {
            resolve({
              ...response,
              data: response.data['atomic:results'][index],
            });
          } else {
            reject(error as AxiosError);
          }
        });
        updateOperations.forEach(({ resolve, reject }, index) => {
          if (!hasErrors) {
            resolve({
              ...response,
              data: response.data['atomic:results'][index],
            });
          } else {
            reject(error as AxiosError);
          }
        });

        if (hasErrors) return Promise.reject(error);
        return response;
      },
    };

    const withBatch = <Operation extends AtomicOperation>(
      operation: Operation
    ) => {
      if (!read(getters.hasActiveBatch)()) return operation.payload;
      const mockResponse = dispatch(actions.addToBatch)({ operation });
      const axiosConfig: AxiosRequestConfig = {
        ...operation.payload[1],
        _sechub_mockResponse: mockResponse,
      };
      return [operation.payload[0], axiosConfig];
    };

    const { read, commit, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {
        withBatch,
        dispatchStartBatchTransaction: dispatch(actions.startBatchTransaction),
        dispatchPerformBatchTransaction: dispatch(
          actions.performBatchTransaction
        ),
      },
      public: {},
    };
  },
});

export default jvBatch;
