<template>
  <div
    class="element-wrapper elevation-1"
    :class="{
      'element-wrapper--root': isRoot,
      'element-wrapper--editable': !isRoot,
      'element-wrapper--editSyntax': !isRoot && editMode && isSyntaxTree,
      'element-wrapper--editOOC': !isRoot && editMode && isOOCsTree,
    }"
    :style="{ opacity: isDeleting ? '0.7' : '1' }"
    :draggable="!isReadonly && !editMode"
    @keydown.enter="saveSyntaxNode"
    @keydown.esc="exitEditMode"
    @dragenter.stop="$emit('dragenter', $event)"
    @dragleave.stop="$emit('dragleave', $event)"
    @dragover.stop="$emit('dragover', $event)"
    @drop.stop="$emit('drop', $event)"
    @dragstart.stop="$emit('dragstart', $event)"
    @dragend.stop="$emit('dragend', $event)"
    @click="$emit('click', $event)"
  >
    <slot name="main">
      <template v-if="editMode && isSyntaxTree">
        <div class="node-name-container">
          <span class="node-name node-name--edit-mode">
            {{ itemName }}
          </span>
        </div>
        <div class="node-content-wrapper">
          <div class="input-wrapper">
            <span>Min depth:</span>
            <input
              data-spec="min-depth-input"
              ref="minDepth"
              type="number"
              min="1"
              v-model.number="localMinDepth"
            />
          </div>
          <div class="input-wrapper">
            <span>Max depth:</span>
            <v-tooltip :model-value="showDepthTooltip" location="right">
              <template #activator="{ props }">
                <input
                  data-spec="max-depth-input"
                  type="number"
                  min="1"
                  v-model.number="localMaxDepth"
                  v-bind="props"
                />
              </template>
              <span>{{ maxDepthTooltip }}</span>
            </v-tooltip>
          </div>
          <v-btn
            data-spec="save-btn"
            class="mr-1"
            size="x-small"
            color="primary"
            :disabled="!validInputs || isProcessing"
            @click.stop="saveSyntaxNode"
          >
            Save
          </v-btn>
          <span v-if="isProcessing">
            <v-progress-circular
              indeterminate
              size="14"
              width="3"
              color="grey"
            />
            Saving changes
          </span>
          <v-btn
            :disabled="isProcessing"
            class="mr-1"
            size="x-small"
            color="primary"
            data-spec="cancel-btn"
            @click.stop="exitEditMode"
          >
            Cancel
          </v-btn>
        </div>
      </template>
      <template v-else-if="item">
        <div v-show="showOOCCreationButtons" class="create-OOC-buttons">
          <div class="create-OOC-button-wrapper sibling">
            <v-btn
              data-spec="add-btn"
              v-if="!isRoot && canCreateSibling"
              icon
              variant="text"
              color="primary"
              @click.stop="
                createNode({
                  parentId: item.parentId,
                  position: item.position + 1,
                })
              "
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" :size="32"> mdi-plus-box </v-icon>
                </template>
                <span>Add sibling</span>
              </v-tooltip>
            </v-btn>
          </div>
          <div v-if="canCreateChild" class="create-OOC-button-wrapper child">
            <v-btn
              data-spec="add-btn"
              icon
              variant="text"
              color="primary"
              @click.stop="createNode({ parentId: item.id, position: 1 })"
            >
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" :size="32"> mdi-plus-box </v-icon>
                </template>
                <span>Add child</span>
              </v-tooltip>
            </v-btn>
          </div>
        </div>
        <div
          class="node-wrapper"
          :class="{
            'node-wrapper--selected': isHighlighted && !isDeleting,
            'node-wrapper--disable-pointer-events': disablePointerEvents,
          }"
        >
          <span
            v-if="!isRoot"
            class="node-color"
            :style="{ backgroundColor: nodeColor }"
          />
          <div class="node-info-wrapper" :style="nodeInfoWrapperStyle">
            <div
              class="node-info"
              :class="{ 'node-info--syntax': isSyntaxTree }"
              :data-spec="`nodeId-${item.id}`"
            >
              <TreeItemLabel
                :item="item"
                :isRoot="isRoot"
                :isSyntaxTree="isSyntaxTree"
                :isOOCsTree="isOOCsTree"
                :referenceBgColor="labelReferenceColor"
                :isHighlighted="isHighlighted"
              >
                <template #additionalContent>
                  <div
                    v-if="isSyntaxTree"
                    data-spec="syntax-caption"
                    class="text-caption"
                  >
                    ({{ item.min_depth || '0' }},{{ item.max_depth || '∞' }})
                  </div>
                  <template v-if="__devtools__">
                    <div style="font-size: 8px">id: {{ item.id }}</div>
                    <div style="font-size: 8px">
                      position: {{ item.position }}
                    </div>
                  </template>
                </template>
              </TreeItemLabel>
            </div>
            <v-progress-circular
              data-spec="loader"
              indeterminate
              v-if="showSpinner"
              size="20"
              width="3"
              color="primary"
              class="ml-2"
            />
            <v-icon
              v-if="hasValidationErrors"
              color="error"
              size="small"
              class="ml-2"
            >
              mdi-alert-circle
            </v-icon>
            <slot name="append" />
          </div>
        </div>
      </template>
    </slot>
    <slot name="actions" />
  </div>
</template>

<script lang="ts">
import { Options, Prop, Watch, Vue } from 'vue-property-decorator';
import { SyntaxItem } from '@/models/syntaxItem';
import { OOC } from '@/models/objectOccurrence';
import ObjectOccurrenceLabel from '@/components/design/objectOccurrenceLabel.vue';
import TreeItemLabel from '@/components/setup/syntax/treeStructure/treeItemLabel.vue';
import { ProcessingStatus } from '@/store/components/processingTracker';

@Options({
  name: 'TreeItemNode',
  components: {
    ObjectOccurrenceLabel,
    TreeItemLabel,
  },
  emits: [
    'click',
    'createNode',
    'dragend',
    'dragenter',
    'dragleave',
    'dragover',
    'dragstart',
    'drop',
    'exitEditMode',
    'saveSyntaxNode',
  ],
})
export default class TreeItemNode extends Vue {
  @Prop({ default: () => null }) item:
    | (Partial<SyntaxItem> & Partial<OOC>)
    | null;
  @Prop(String) processingStatus: ProcessingStatus | undefined;
  @Prop(Boolean) showOOCCreationButtons: boolean;
  @Prop(Boolean) canCreateSibling: boolean;
  @Prop(Boolean) canCreateChild: boolean;
  @Prop(Boolean) isSyntaxTree: boolean;
  @Prop(Boolean) isOOCsTree: boolean;
  @Prop(Boolean) isHighlighted: boolean;
  @Prop(Boolean) isReadonly: boolean;
  @Prop(Boolean) isLoading: boolean;
  @Prop(Boolean) editMode: boolean;
  @Prop(Boolean) isRoot: boolean;
  @Prop(Boolean) isNarrow: boolean;
  @Prop(Boolean) disablePointerEvents: boolean;
  @Prop({ default: () => null }) minDepth: number | null;
  @Prop({ default: () => null }) maxDepth: number | null;

  localMinDepth: number | string = 0;
  localMaxDepth: number | string = 0;

  mounted() {
    this.localMinDepth = this.minDepth;
    this.localMaxDepth = this.maxDepth;
    if (this.editMode) {
      this.enterEditMode();
    }
  }

  get isProcessing(): boolean {
    return !!this.processingStatus;
  }

  get isDeleting(): boolean {
    return this.processingStatus === 'delete';
  }

  get nodeColor() {
    if (this.item?.hex_color) return `#${this.item.hex_color}`;
    return 'rgb(var(--v-theme-cardBackground))';
  }

  get validMinDepth(): boolean {
    if (!this.localMinDepth) return false;
    const parsed =
      typeof this.localMinDepth === 'string'
        ? parseInt(this.localMinDepth)
        : this.localMinDepth;
    return parsed > 0;
  }

  get validMaxDepth(): boolean {
    return (
      !this.localMaxDepth ||
      (this.validMinDepth && this.localMaxDepth >= this.localMinDepth)
    );
  }

  get validInputs(): boolean {
    return this.validMinDepth && this.validMaxDepth;
  }

  get maxDepthTooltip(): string {
    return this.validMinDepth
      ? "Max depth can't be lower than Min depth"
      : 'Min depth has to be specified';
  }

  get showDepthTooltip(): boolean {
    return !this.validInputs;
  }

  get itemName(): string {
    return this.item?.name || '';
  }

  get hasValidationErrors(): boolean {
    if (this.item?.validation_errors) {
      return this.item.validation_errors.length > 0;
    } else return false;
  }

  get nodeInfoWrapperStyle() {
    return {
      width: this.isHighlighted && this.isNarrow ? '358px' : '400px',
    };
  }

  get showSpinner(): boolean {
    return this.isLoading || (this.isProcessing && !this.isDeleting);
  }

  get labelReferenceColor(): string {
    return this.isHighlighted
      ? this.nodeColor
      : 'rgb(var(--v-theme-cardBackground))';
  }

  saveSyntaxNode() {
    if (!this.validInputs || !(this.editMode && this.isSyntaxTree)) return;
    this.$emit('saveSyntaxNode', {
      min_depth: this.localMinDepth,
      max_depth: this.localMaxDepth || null,
    });
  }

  exitEditMode() {
    if (this.editMode && this.isSyntaxTree) {
      this.localMinDepth = this.minDepth;
      this.localMaxDepth = this.maxDepth;
    }
    this.$emit('exitEditMode');
  }

  createNode({ parentId, position = 1 }) {
    this.$emit('createNode', { parentId, position });
  }

  enterEditMode() {
    if (!this.isRoot && this.isSyntaxTree) {
      this.$nextTick(
        () =>
          this.$refs.minDepth && (this.$refs.minDepth as HTMLElement).focus()
      );
    }
  }

  @Watch('editMode')
  onEditModeChange() {
    if (this.editMode) {
      this.enterEditMode();
    }
  }
}
</script>

<style lang="scss" scoped>
.element-wrapper {
  min-width: 402px;
  height: 56px;
  background-color: rgb(var(--v-theme-cardBackground));
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 23px;
  left: 57px;
  z-index: 7;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.2),
    0 2px 1px rgba(0, 0, 0, 0.12),
    0 1px 1px rgba(0, 0, 0, 0.14);
}
.element-wrapper--root {
  left: 0 !important;
}
.element-wrapper--editable {
  cursor: pointer;
}
.element-wrapper--editOOC {
  z-index: 8;
}
.element-wrapper--editSyntax {
  flex-direction: column;
  height: 170px;
  z-index: 9;
  padding: 8px 12px;
  .input-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    input {
      outline: none;
      border: solid 1px rgb(var(--v-theme-primary));
      border-radius: 2px;
      margin: 6px 0 6px auto;
      width: 40px;
      padding-left: 2px;
      &:focus {
        outline: solid 1px rgb(var(--v-theme-primary));
      }
    }
  }
}
.node-wrapper {
  display: flex;
  align-items: center;
  min-width: 402px;
  flex-grow: 1;
  position: relative;
}
.node-wrapper--disable-pointer-events {
  pointer-events: none;
}
.node-wrapper--selected {
  min-width: 350px;
}
.create-OOC-buttons {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .create-OOC-button-wrapper {
    z-index: 1;
    position: absolute;
    overflow: visible;
    height: 0;
    width: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.sibling {
      left: 50%;
      bottom: -24px;
    }
    &.child {
      right: -20px;
      bottom: 50%;
    }
  }
}
.node-color {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: $border-radius 0 0 $border-radius;
  width: $tree-node-color-width;
  height: 100%;
}
.node-info-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 $space-x-small;
  &:deep(> *) {
    flex-shrink: 0;
  }
}
.node-info {
  flex-shrink: 1;
  margin-right: auto;
  min-width: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.node-info--syntax {
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.node-name {
  max-width: 100%;
  margin-bottom: 0;
}
.node-content-wrapper {
  position: relative;
}
</style>
