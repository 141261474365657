import { createModuleComponent } from '../utils/factories';
import { ComponentType } from './_types';
import { TreeIndexNode, TreeOperation } from '@/models/treeStructure';
import {
  computeTreeData,
  applyTreeDataOperation,
  TreeIdentification,
  treeId,
} from '@/utils/tree';
import jv from './jv';
import crud from './crud';
import { pick } from '@/utils/tools';

interface State {
  trees: Partial<{
    [treeId: string]: {
      treeIndex: TreeIndexNode[];
    };
  }>;
}

const treeIndexManager = createModuleComponent({
  type: ComponentType.TreeIndexManager,
  dependencies: [jv, crud],
  setup: ({ resourceProfile, getAccessors, components }) => {
    const state = (): State => ({
      trees: {},
    });

    const mutations = {
      resetTreeIndex(state: State, payload: { tree: TreeIdentification }) {
        state.trees[treeId(payload.tree)] = { treeIndex: [] };
      },
      updateTreeIndex(
        state: State,
        payload: {
          tree: TreeIdentification;
          indexPatch: TreeIndexNode[];
          replaceLevels?: boolean;
        }
      ) {
        if (!state.trees[treeId(payload.tree)]) return;
        const { replaceLevels } = payload;
        // get last occurrence of patch for a given node
        const indexPatch = payload.indexPatch.filter(
          (node, index, all) =>
            !all.slice(index + 1).some((n) => n.id === node.id)
        );
        const patchedIds = indexPatch.map((patch) => patch.id);
        let unchangedIndex = state.trees[treeId(payload.tree)].treeIndex.filter(
          (entry) => !patchedIds.includes(entry.id)
        );
        if (replaceLevels) {
          const patchedParentIds = indexPatch
            .map((patch) => patch.parentId)
            .filter(Boolean);
          unchangedIndex = unchangedIndex.filter(
            (entry) => !patchedParentIds.includes(entry.parentId)
          );
        }
        state.trees[treeId(payload.tree)].treeIndex = [
          ...unchangedIndex,
          ...indexPatch,
        ];
      },
      applyTreeOperation(
        state: State,
        payload: { tree: TreeIdentification; operation: TreeOperation }
      ) {
        if (!state.trees[treeId(payload.tree)]) return;
        const operation =
          'attributes' in payload.operation
            ? { ...payload.operation, attributes: {} }
            : payload.operation;
        const [treeIndex, patches] = applyTreeDataOperation(
          state.trees[treeId(payload.tree)].treeIndex,
          operation
        );
        state.trees[treeId(payload.tree)].treeIndex = treeIndex;
        patches
          .filter((node) => node.id !== payload.operation.id)
          .forEach(({ id, position }) => {
            components.$jv.protected.commitMergeRecords({
              _jv: { id, type: resourceProfile.type },
              position,
            });
          });
      },
    };

    const getters = {
      treeIndex: (state: State) => (tree: TreeIdentification) =>
        state.trees[treeId(tree)]?.treeIndex || [],
      treeData: () => (tree: TreeIdentification) =>
        computeTreeData({
          treeIndex: read(getters.treeIndex)(tree),
          getResourceAttributes: (id: string) => {
            const resource = components.$jv.protected.get({
              id,
              type: resourceProfile.type,
            });
            if (!resourceProfile.dependencyType) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return pick(resource, resourceProfile.attributes) as any;
            }
            const relatedId =
              components.$crud.public.getRelationshipIdFromLinkage(
                id,
                resourceProfile.dependencyType
              );
            const relatedResource = components.$jv.protected.get({
              id: relatedId,
              type: resourceProfile.dependencyType,
            });
            return {
              ...pick(resource, resourceProfile.attributes),
              ...pick(relatedResource, resourceProfile.dependantAttributes),
              relatedId,
            };
          },
        }),
    };

    const actions = {
      applyTreeOperation(
        context,
        payload: { tree: TreeIdentification; operation: TreeOperation }
      ) {
        commit(mutations.applyTreeOperation)(payload);
      },
    };

    const { read, commit, dispatch } = getAccessors();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {
        commitResetTreeIndex: commit(mutations.resetTreeIndex),
        commitUpdateTreeIndex: commit(mutations.updateTreeIndex),
        dispatchApplyTreeOperation: dispatch(actions.applyTreeOperation),
        getTreeData: read(getters.treeData),
      },
      public: {},
    };
  },
});

export default treeIndexManager;
