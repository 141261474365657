import { createModule } from '../utils/factories';
import { resources, TradeStudiesRelationshipTypes } from '@/services/resources';
import { TradeStudyAttributes } from '@/models/tradeStudy';
import crud from '../components/crud';
import jv from '../components/jv';
import indexManager from '../components/indexManager';
import { api } from '@/services/api';
import { buildRequestPath } from '@/utils/path';
import { JsonApiIdentification } from '@/models/api';
import delayedResponseContext from '../components/delayedResponseContext';

type State = Record<string, never>;

export default createModule({
  path: 'tradeStudies',
  resourceProfile: resources.tradeStudy,
  components: [crud, indexManager, delayedResponseContext, jv],
  setup({ getAccessors, components, resourceProfile }) {
    const getDelayedResponseContext =
      components.$delayedResponseContext.protected.getDelayedResponseService;

    const state = (): State => ({});

    const mutations = {};

    const getters = {
      getTradeStudyContextId: () => (tradeStudyId: string) => {
        return components.$crud.public.getRelationshipId(
          tradeStudyId,
          TradeStudiesRelationshipTypes.TradeStudyContext
        ) as string;
      },
      getBaseContextId: () => (tradeStudyId: string) => {
        return components.$crud.public.getRelationshipId(
          tradeStudyId,
          TradeStudiesRelationshipTypes.BaseContext
        ) as string;
      },
    };

    const actions = {
      async createTradeStudy(
        context,
        payload: {
          resource: TradeStudyAttributes;
          contextId: string;
        }
      ) {
        const path = buildRequestPath(resourceProfile, {
          relationshipKey: TradeStudiesRelationshipTypes.BaseContext,
          relationshipId: payload.contextId,
        });
        const createPromise = api.post(path, {
          data: {
            type: resourceProfile.type,
            attributes: payload.resource,
          },
        });
        const response = await getDelayedResponseContext(
          payload.contextId
        ).waitForDelayedResponse(createPromise);
        const targetIdentification = response.data?.relationships?.target
          ?.data as JsonApiIdentification;
        return { id: targetIdentification?.id };
      },
      async closeTradeStudy(context, payload: { tradeStudyId: string }) {
        return components.$crud.public.dispatchDeleteResource({
          resourceId: payload.tradeStudyId,
        });
      },
    };

    const { read, dispatch } = getAccessors<State>();

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {},
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,

        getTradeStudyContextId: read(getters.getTradeStudyContextId),
        getBaseContextId: read(getters.getBaseContextId),
        dispatchCreateResource: dispatch(actions.createTradeStudy),
        dispatchCloseTradeStudy: dispatch(actions.closeTradeStudy),
      },
    };
  },
});
