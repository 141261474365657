import { RecentWorkURL } from '@/models/recentWorkURL';

export const createRecentWorkURL = (
  payload: Omit<RecentWorkURL, 'id'>
): RecentWorkURL => {
  const id =
    payload.type === 'DOMA'
      ? 'DOMA'
      : [...payload.contextIds, payload.type].join();
  return { ...payload, id };
};

export const RECENT_WORK_STORAGE_KEY = 'recent-work-storage-key';
