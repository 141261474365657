import axios, {
  AxiosError,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import { getAccessToken, login } from '@/auth';
import store from '@/store';
import { translate } from '@/localization';
import { delay } from '../utils/tools';

declare module 'axios' {
  interface AxiosRequestConfig {
    _sechub_retry?: number;
  }
}

export const HEADER_REQUEST_ID = 'x-request-id';
const performedRequestsIds = new Set();

interface Headers extends RawAxiosRequestHeaders {
  Accept: string;
  'Accept-Language': string;
  Authorization?: string;
  'Cache-Control'?: string;
  'Content-Type': string;
}

const REQUEST_RETRIES = 5;
const RETRY_DELAY = 500;
const RETRY_CODES_RANGES: number[][] = [
  // Client errors that we should retry without user interaction
  [400, 400], // Bad request
  [408, 408], // Request timeout
  [499, 499], // Client closed request

  // Server errors which we should always retry at least a few times
  [500, 599],
];

const baseURL = window.__env.VUE_APP_API_URL;

export const headers: Headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
  'Accept-Language': 'en',
};

const token = getAccessToken();
if (token) {
  headers.Authorization = `Bearer ${token}`;
}

export const api = axios.create({
  baseURL,
  headers,
});

export function hasHeader(header) {
  return (
    !!api.defaults.headers[header] || !!api.defaults.headers.common[header]
  );
}
export function setApiHeader(header, value) {
  api.defaults.headers.common[header] = value;
}
export function removeApiHeader(header) {
  delete api.defaults.headers.common[header];
}

export function wasRequestPerformed(requestId: string) {
  return performedRequestsIds.has(requestId);
}

async function retryRequest(error: AxiosError) {
  if (!error.config) return Promise.reject(error);
  error.config._sechub_retry = error.config._sechub_retry ?? REQUEST_RETRIES;
  if (!shouldRetryRequest(error)) return Promise.reject(error);
  error.config._sechub_retry -= 1;
  await delay(RETRY_DELAY);
  return api.request(error.config);
}

function shouldRetryRequest(error: AxiosError) {
  const status = error?.response?.status;
  const retries = error?.config?._sechub_retry || 0;
  if (retries <= 0) return false;
  if (status) {
    // check if status is within code ranges
    return RETRY_CODES_RANGES.some(
      ([min, max]) => status >= min && status <= max
    );
  }
  return true;
}

function detectDataMissing(response) {
  if (Array.isArray(response.data?.data)) {
    return response.data?.data?.some(
      (rec) => rec.meta?.missing_required_fields?.length
    );
  } else {
    return !!response.data?.data?.meta?.missing_required_fields?.length;
  }
}

//  This is the only workaround we've found that allows us to prevent Axios from removing 'Content-Type' header from the 'get' requests.
//  Axios removes them if data object is 'undefined' so here, we're setting it to 'null' instead
api.interceptors.request.use((config) => {
  if (typeof config.data === 'undefined') {
    config.data = null;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    _validateJsonApiResponse(response);

    performedRequestsIds.add(response.headers[HEADER_REQUEST_ID]);

    if (detectDataMissing(response)) {
      store.$direct.notifications.dispatchNotify({
        type: 'error',
        message: translate('errors.dataMissing'),
      });
    }
    return response;
  },
  (error) => {
    const errorStatus = error.response?.status;
    switch (errorStatus) {
      case 401:
        login();
        break;
      default:
        return retryRequest(error);
    }
  }
);

const _validateJsonApiResponse = (response: AxiosResponse) => {
  const contentType =
    response.headers['content-type'] &&
    response.headers['content-type']
      .toString()
      .includes('application/vnd.api+json');
  if (window.__env?.production || !contentType) {
    return;
  }

  const onError = (collection: string, duplicates: Set<string>) => {
    console.error(
      `[API Response Validator] Response \`${collection}\` contains duplicated resources: ${Array.from(
        duplicates
      ).join(', ')}`
    );
  };

  const data = response?.data?.data;
  if (data && Array.isArray(data)) {
    const duplicates = _getDuplicatedResources(data);
    if (duplicates.size) onError('data', duplicates);
  }

  const included = response?.data?.included;
  if (included && Array.isArray(included)) {
    const duplicates = _getDuplicatedResources(included);
    if (duplicates.size) onError('included', duplicates);
  }
};

const _getDuplicatedResources = (jsonApiCollection: unknown[]) => {
  const resources = new Set<string>();
  const duplicates = new Set<string>();
  jsonApiCollection.forEach((item: unknown) => {
    if (typeof item !== 'object' || !('type' in item) || !('id' in item)) {
      return;
    }
    const identification = `${item.type}:${item.id}`;
    if (resources.has(identification)) {
      duplicates.add(identification);
    }
    resources.add(identification);
  });
  return duplicates;
};
