// import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css';
import { h } from 'vue';
import { createVuetify, IconProps, IconSet } from 'vuetify';
import * as components from 'vuetify/components';
import { ClickOutside, Intersect, Resize } from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import rdsSort from '@/components/vuetify/icons/rdsSort.vue';
import treeStructureSort from '@/components/vuetify/icons/treeStructureSort.vue';
import {
  designPalette,
  getPaletteByTheme,
  setupPalette,
  adminPalette,
  launchpadPalette,
} from '../colors/colorPalette';

const systemSvgIconComponents = {
  rdsSort,
  treeStructureSort,
};

const sysSvgs: IconSet = {
  component: (props: IconProps) => {
    return h(props.tag, [
      h(systemSvgIconComponents[props.icon as string], {
        class: 'v-icon__svg',
      }),
    ]);
  },
};

export const vuetify = createVuetify({
  components: {
    ...components,
  },
  directives: {
    ClickOutside,
    Intersect,
    Resize,
  },
  defaults: {
    VSkeletonLoader: {
      color: 'transparent',
    },
  },
  theme: {
    themes: {
      dark: { dark: true, colors: { ...getPaletteByTheme('dark') } },
      light: { dark: false, colors: { ...getPaletteByTheme('light') } },
      appDesign: {
        colors: getPaletteByTheme('light', designPalette),
      },
      appSetup: {
        colors: getPaletteByTheme('light', setupPalette),
      },
      appAdmin: {
        colors: getPaletteByTheme('light', adminPalette),
      },
      appLaunchpad: {
        colors: getPaletteByTheme('light', launchpadPalette),
      },
    },
    variations: {
      colors: ['secondary', 'accent', 'error', 'warning'],
      lighten: 5,
      darken: 5,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      sys: sysSvgs,
    },
  },
});
