<template>
  <ApplicationPage />
</template>

<script lang="ts" setup>
import ApplicationPage from './applicationPage.vue';
import { ApplicationType } from '@/enums/applicationType';
import { useApplication } from '@/composables/application';
import { onBeforeMount } from 'vue';

const { setApplicationType } = useApplication();
onBeforeMount(() => setApplicationType(ApplicationType.Admin));
</script>
