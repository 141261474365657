import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import devtools from '@/plugins/devtools';
import { OnCtrlMetaEnter } from '@/plugins/directive-on-ctrl-meta-enter';
import { Uppercase } from '@/plugins/directive-uppercase';
import { IsOverflowing } from '@/plugins/directive-overflow';
import { vHover } from '@/plugins/directive-hover';
import { vuetify } from './plugins/vuetify';
import { i18n } from './localization';
import * as Sentry from '@sentry/vue';
import PortalVue from 'portal-vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: Unreachable code error
import { createPinia } from 'pinia';
import { UnheadPlugin } from '@unhead/vue/vue2';
import { vEllipsis } from '@/plugins/directive-ellipsis';

const app = createApp(App);
const pinia = createPinia();
const environment = window.__env.VUE_APP_ENVIRONMENT || 'development';
const sentryDSN = window.__env.VUE_APP_SENTRY_DSN;

if (sentryDSN) {
  Sentry.init({
    app,
    dsn: sentryDSN,
    environment,
    tracesSampleRate: environment === 'development' ? 0.1 : 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  });
}

app.use(router);
app.use(store);
app.use(pinia);
app.use(UnheadPlugin);
app.use(devtools);
app.use(i18n);
app.use(vuetify);
app.use(PortalVue, {
  portalName: false,
  portalTargetName: false,
});

app.config.performance = true;

app.directive('on-ctrl-meta-enter', OnCtrlMetaEnter);
app.directive('uppercase', Uppercase);
app.directive('is-overflowing', IsOverflowing);
app.directive('hover', vHover);
app.directive('ellipsis', vEllipsis);

if (
  window.__env === undefined ||
  (import.meta.env.PROD && !window.__env.production)
) {
  alert('Configuration error!');
} else {
  app.mount('#app');
}
