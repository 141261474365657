import Setup from '@/views/setup.vue';
import { ApplicationType } from '@/enums/applicationType';
import { RouteName } from '@/enums/routing';
import {
  createAppRoute,
  createSubAppRoutes,
  SecRouteConfig,
} from '@/router/utils';

const setupRoutes: SecRouteConfig[] = [
  createAppRoute(ApplicationType.Setup, {
    path: '/setup',
    name: RouteName.Setup,
    component: Setup,
    redirect: { name: RouteName.ClassificationTables },
    meta: { requiresAuth: true },
    children: [
      createSubAppRoutes(RouteName.ClassificationTables, [
        {
          path: 'classificationTablesManager',
          name: RouteName.ClassificationTables,
          component: () =>
            import(
              '@/components/setup/classification/classificationTablesManager.vue'
            ),
          meta: {
            title: 'Classification table manager',
          },
        },
        {
          path: 'classificationTable/:classificationTableId',
          name: RouteName.ClassificationEntries,
          component: () =>
            import('@/components/setup/classification/classificationTable.vue'),
          props: true,
          meta: {
            title: 'Classification table manager',
          },
        },
      ]),
      createSubAppRoutes(RouteName.SyntaxManager, [
        {
          path: 'syntaxesManager',
          name: RouteName.SyntaxManager,
          component: () =>
            import('@/components/setup/syntax/syntaxesManager.vue'),
          meta: {
            title: 'Syntax manager',
          },
        },
        {
          path: 'syntax',
          name: RouteName.Syntax,
          component: () => import('@/components/setup/syntax/syntax.vue'),
          meta: {
            title: 'Syntax manager',
          },
        },
      ]),
    ],
  }),
];

export default setupRoutes;
