<template>
  <div class="dashboard-component-wrapper">
    <div class="dashboard_component">
      <v-tabs
        v-model="selectedProject"
        class="dashboard__v-tabs"
        show-arrows
        mandatory
        selected-class="dashboard__v-tabs-selected"
      >
        <v-tab v-for="project in projects" :key="project.id">
          {{ project.name }}
        </v-tab>
      </v-tabs>
      <v-sheet elevation="20" class="rounded-b" height="100%">
        <div v-if="isLoadingWidgets" class="progress-wheel-wrapper">
          <v-progress-circular
            :indeterminate="true"
            color="secondary"
            size="70"
            width="5"
          />
        </div>
        <v-carousel
          v-if="!isLoadingWidgets"
          v-model="currentDashboard"
          :cycle="multipleWidgets"
          hide-delimiters
          interval="20000"
          :show-arrows="false"
          height="100%"
        >
          <template v-if="widgets.length > 0">
            <div v-if="multipleWidgets" class="arrow-buttons_container">
              <v-btn @click="prevWidget" variant="text" class="p-2">
                <v-icon color="black">mdi-chevron-left</v-icon>
              </v-btn>
              <div class="text-h4 font-weight-light">
                {{ currentDashboard + 1 + '/' + widgets.length }}
              </div>
              <v-btn @click="nextWidget" variant="text" class="p-2">
                <v-icon color="black">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-carousel-item
              v-for="widget in widgets"
              :key="widget.id"
              @click="
                handleChangeRouteOnClick({
                  name: RouteName.Dashboard,
                  params: { projectId: widgetProject(widget).id },
                })
              "
              height="100%"
            >
              <div v-if="!isLoadingWidgets" class="dashboard-item">
                <div class="dashboard-title-text">
                  <div class="text-h3 font-weight-light text-start pl-5">
                    {{ widget.context.name }}
                  </div>
                </div>
                <DashboardWidget :widgetId="widget.id" readonly />
              </div>
            </v-carousel-item>
          </template>
          <template v-else>
            <div class="dashboard-dummy-wrapper">
              <img class="dashboard-dummy-image" :src="dashboardDummyPic" />
              <v-btn
                class="dashboard-dummy-button"
                color="secondary"
                @click="
                  handleChangeRouteOnClick({
                    name: RouteName.Dashboard,
                    params: { projectId: selectedProjectID },
                  })
                "
              >
                {{ $t('apps.launchpad.buttonsText.addWidget') }}
              </v-btn>
            </div>
          </template>
        </v-carousel>
      </v-sheet>
    </div>
  </div>
</template>
<script lang="ts" setup>
import DashboardWidget from '@/components/design/dashboard/dashboardWidget.vue';
import { createIncludeRelationshipPath, resources } from '@/services/resources';
import { RouteName } from '@/enums/routing';
import { useDirectStore } from '@/composables/store';
import { computed, onMounted, ref, watch } from 'vue';
import { Project } from '@/models/project';
import { RouteLocation, useRouter } from 'vue-router';
import dashboardDummyPic from '@/assets/Dashboard-Dummy.svg';
import { composeMessage } from '@/services/errorHandler';

const STORAGE_KEY_SELECTED_PROJECT_LAUNCHPAD = 'sec-selected-project-launchpad';

const props = defineProps<{
  projects: Project[];
}>();

const router = useRouter();
const store = useDirectStore();
const selectedProject = ref(0);
const currentDashboard = ref(0);
const isLoadingWidgets = ref(false);

const loadWidgets = async () => {
  isLoadingWidgets.value = true;
  try {
    await store.dashboardWidget.dispatchLoadPaginatedResource({
      requestType: 'launchpad',
      pageInfo: { page: 1, pageSize: 3 },
      sort: '-updated_at',
      filterProjectId: selectedProjectID.value,
      include: createIncludeRelationshipPath(resources.dashboardWidget)(
        'context',
        'project'
      ),
    });
  } catch (error) {
    store.notifications.dispatchNotify({
      type: 'error',
      message: composeMessage(error),
    });
  }
  isLoadingWidgets.value = false;
};

const selectedProjectID = computed(() => {
  return props.projects[selectedProject.value]?.id;
});

const multipleWidgets = computed(() => {
  return widgets.value.length > 1;
});

const widgets = computed(() => {
  return store.dashboardWidget.getFullResource('launchpad');
});

const widgetProject = (widget) => {
  const projectID = store.context.getRelatedProjectId(widget.context.id);

  return store.project.getSimplifiedResourceSet()[projectID];
};

const nextWidget = () => {
  currentDashboard.value = (currentDashboard.value + 1) % widgets.value.length;
};

const prevWidget = () => {
  currentDashboard.value =
    (currentDashboard.value - 1 + widgets.value.length) % widgets.value.length;
};

onMounted(() => {
  if (localStorage.getItem(STORAGE_KEY_SELECTED_PROJECT_LAUNCHPAD)) {
    selectedProject.value = JSON.parse(
      localStorage.getItem(STORAGE_KEY_SELECTED_PROJECT_LAUNCHPAD)
    );
  }
});

watch(selectedProject, () => {
  localStorage.setItem(
    STORAGE_KEY_SELECTED_PROJECT_LAUNCHPAD,
    JSON.stringify(selectedProject.value)
  );
  loadWidgets();
});

watch(selectedProjectID, () => {
  if (selectedProjectID.value) {
    loadWidgets();
  }
});

const handleChangeRouteOnClick = (route: { name: string; params: object }) => {
  router.push(route as RouteLocation);
};
</script>
<style lang="scss" scoped>
*.dashboard-component-wrapper {
  display: flex;
  align-items: center;
  height: 90%;
  width: 100%;
}

.dashboard_component {
  flex: 1 0 auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  max-width: 100%;
}

.dashboard__v-tabs {
  background-color: rgb(var(--v-theme-primary));
  color: rgba(255, 255, 255, 0.5);
  min-height: 44px;
  height: 44px;
  max-width: 100%;
}

.dashboard__v-tabs-selected {
  color: white;
}

.dashboard-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.dashboard-title-text {
  display: flex;
  justify-content: flex-start;
  padding-right: 50px;
}

.dashboard-dummy-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.dashboard-dummy-image {
  opacity: 50%;
  height: auto;
  object-fit: contain;
}

.dashboard-dummy-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  opacity: 100% !important;
}

.arrow-buttons_container {
  display: flex;
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 3;
  align-items: center;
}

.progress-wheel-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
