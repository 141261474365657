import { translate, translationExists } from '@/localization';
import { AxiosError, AxiosResponse } from 'axios';

export interface ApiError {
  status?: string;
  code?: string;
  title?: string;
  detail?: string;
}

type ComposerOptions = {
  resourceName?: string;
  preferDetail?: boolean;
};

export function composeMessage(
  data: AxiosError | AxiosResponse,
  options: ComposerOptions = {}
): string {
  const messages: string[] =
    (data as AxiosError<{ errors: ApiError[] }>)?.response?.data?.errors
      ?.map((e) => translateError(e, options))
      .filter(Boolean) || [];
  if (messages.length === 0) return translate('errors.generic');
  if (messages.length > 5) {
    messages.splice(5);
    messages.push('...');
  }
  return messages.join(', ');
}

export function translateError(
  error: ApiError,
  options: ComposerOptions = {}
): string | undefined {
  if (options.preferDetail && error.detail) return error.detail;
  if (error.code && translationExists(`errors.${error.code}`)) {
    return translate(`errors.${error.code}`, {
      resource: options.resourceName || 'resource',
    });
  }
  if (error.status && translationExists(`errors.${error.status}`)) {
    return translate(`errors.${error.status}`, {
      resource: options.resourceName || 'resource',
    });
  }
  return error.detail || error.title;
}
