<template>
  <ApplicationPage />
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, watch } from 'vue';
import ApplicationPage from './applicationPage.vue';
import { ApplicationType } from '@/enums/applicationType';
import { RouteName } from '@/enums/routing';
import { useApplication } from '@/composables/application';
import { useRoute } from 'vue-router';
import { useDirectStore } from '@/composables/store';

const route = useRoute();
const store = useDirectStore();
const { setApplicationType } = useApplication();

onBeforeMount(() => {
  setApplicationType(ApplicationType.Setup);
});

const subAppRootRouteName = computed(
  () =>
    route.matched.find((r) => r.meta.subApplicationRoot)?.meta
      .subApplicationRoot as RouteName
);

watch(
  subAppRootRouteName,
  (newSubAppRoot) => {
    if (newSubAppRoot === RouteName.SyntaxManager)
      store.syntax.dispatchClearFilters();
    if (newSubAppRoot === RouteName.ClassificationTables)
      store.classificationTable.dispatchClearFilters();
  },
  { immediate: true }
);
</script>
