export function tableCursor(
  event: KeyboardEvent,
  context: {
    focusedRow: number;
    focusRow: (index: number) => void;
    expandRow?: (index: number) => void;
    collapseRow?: (index: number) => void;
    clickRow?: (index: number) => void;
  }
): void {
  const { focusedRow, focusRow } = context;
  event.preventDefault();
  event.stopPropagation();
  switch (event.key) {
    case 'Enter':
      return context.clickRow?.(focusedRow);
    case 'ArrowUp':
      return focusRow(focusedRow - 1);
    case 'ArrowDown':
      return focusRow(focusedRow + 1);
    case 'ArrowRight':
      return context.expandRow?.(focusedRow);
    case 'ArrowLeft':
      return context.collapseRow?.(focusedRow);
  }
}
