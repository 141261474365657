<template>
  <div
    class="dashboard-widget-chart"
    :style="{
      overflow: tooltip.visible
        ? ''
        : 'hidden' /* must be hidden for resizing */,
    }"
  >
    <div v-if="!widgetStats?.length" class="dashboard-widget-chart__no-data">
      <slot name="no-data-text">No data</slot>
    </div>
    <div ref="legendRef" class="legend" v-if="widget?.chart === 'bar'">
      <div class="legend__title">{{ legendTitle }}</div>
      <div
        v-for="item in legendItems"
        :key="item.label"
        :class="{
          legend__item: true,
          'legend__item--hidden': !item.isVisible,
        }"
        :style="{
          '--color': item.color,
          '--hoverColor': item.hoverColor,
        }"
        @click="item.toggleVisibility"
      >
        {{ item.label }}
      </div>
    </div>
    <div ref="barChartWrapper" class="dashboard-widget__chart-wrapper">
      <div :style="{ width: calculatedChartWidthInPx, height: '100%' }">
        <BarChart
          v-if="widget?.chart === 'bar'"
          ref="chartRef"
          :data="chartData"
          :options="chartOptions"
        />
        <PieChart
          v-else
          ref="chartRef"
          :data="pieChartData"
          :options="pieChartOptions"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import {
  Bar as BarChart,
  Pie as PieChart,
  ChartComponentRef,
} from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartData,
  ChartOptions,
  ArcElement,
  TooltipModel,
  SubTitle,
} from 'chart.js';
import Zoom from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import chroma from 'chroma-js';
import { until } from '@vueuse/shared';
import {
  ChartAreaOffsetPlugin,
  CustomLegendItem,
  CustomLegendPlugin,
  HooksPlugin,
} from '@/utils/chartjs';
import { chartStacks, xAxisOptions } from '@/utils/dashboard';
import {
  DashboardWidget,
  DashboardWidgetStatsItem,
} from '@/models/dashboardWidget';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  Zoom,
  ChartDataLabels,
  HooksPlugin,
  ChartAreaOffsetPlugin,
  CustomLegendPlugin
);

type ChartConfig = {
  showBarCount?: boolean;
  showTotalCount?: boolean;
};

const props = withDefaults(
  defineProps<{
    widget?: DashboardWidget;
    widgetStats?: DashboardWidgetStatsItem[];
    config?: ChartConfig;
  }>(),
  {
    widget: null,
    widgetStats: null,
    config: () => ({}),
  }
);

const defaultColors: Record<string, string> = {
  A: '#D50000',
  AA: '#DD2C00',
  B: '#304FEA',
  BA: '#2962FF',
  BB: '#0091EA',
  BC: '#00B8D4',
  BD: '#00BFA5',
  BE: '#00C853',
  BF: '#64DD17',
  BG: '#AEEA00',
  CA: '#FF6D00',
  CB: '#FFAB00',
  CC: '#FFC000',
  CD: '#FFD600',
  C: '#FFFF00',
  D: '#710298',
  DA: '#9900CC',
  DB: '#AA00FF',
  DC: '#BF61FF',
  DD: '#D28FFF',
  F: '#5D4037',
  FA: '#795548',
  FB: '#8D6E63',
  FC: '#A1887F',
  FD: '#BCAAA4',
  H: '#B0BEC5',
  HA: '#607D8B',
  HB: '#455A64',
  G: '#FFFFFF',
  GA: '#D9D9D9',
  GB: '#F2F2F2',
};

const pieChartDataset = computed<ChartData<'pie'>['datasets']>(() => {
  if (!props.widget || !props.widgetStats) return [];
  return [
    {
      backgroundColor: props.widgetStats.map((stats) => {
        return stats.hex_color
          ? `#${stats.hex_color}`
          : defaultColors[stats.name] || '#000000';
      }),
      data: props.widgetStats.map((stats) =>
        Object.values(stats.data).reduce((acc, curr) => acc + curr, 0)
      ),
    },
  ];
});

const createPieChartLabel = (bar: DashboardWidgetStatsItem) => {
  const { name = '', data = {} } = bar;

  const reduced = Object.values(data).reduce((acc, value) => (acc += value), 0);

  return `${name} (${reduced})`;
};

const pieChartData = computed<ChartData<'pie'>>(() => ({
  labels: props.widgetStats?.map((bar) => createPieChartLabel(bar)) || [],
  datasets: pieChartDataset.value,
}));

const pieChartOptions = computed<Partial<ChartOptions<'pie'>>>(() => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      display: false,
    },
  },
  layout: {
    padding: {
      top: 32,
    },
  },
  interaction: {
    mode: 'index',
  },
  plugins: {
    datalabels: {
      display: false,
    },
    hooks: {
      beforeRender: () => {
        isRendering.value = true;
      },
      afterRender: () => {
        isRendering.value = false;
      },
      chartAreaOffset: {
        min: 2,
        offset: 0.1,
      },
    },
  },
}));
const defaultConfig = {
  showBarCount: false,
  showTotalCount: true,
};

const chartConfig = computed<ChartConfig>(() => ({
  ...defaultConfig,
  ...props.config,
}));

const chartRef = ref<ChartComponentRef>(null);

const tooltip = ref({
  x: 0,
  y: 0,
  visible: false,
  label: '',
  total: 0,
  dataPoints: [] as Array<{
    color: string;
    label: string;
    value: string;
  }>,
});

const externalTooltipHandler = (context: {
  chart: ChartJS;
  tooltip: TooltipModel<'bar'>;
}) => {
  tooltip.value = {
    x: context.tooltip.caretX,
    y: context.tooltip.caretY,
    visible: !!context.tooltip.opacity,
    label: context.tooltip.title?.join(','),
    total: context.tooltip.dataPoints?.reduce(
      (sum, dataPoint) => sum + (dataPoint.raw as number),
      0
    ),
    dataPoints: context.tooltip.dataPoints
      ?.filter((dataPoint) => dataPoint.raw)
      .map((dataPoint) => ({
        color: context.chart.data.datasets[dataPoint.datasetIndex]
          ?.hoverBackgroundColor as string,
        label: dataPoint.dataset.label,
        value: dataPoint.formattedValue,
      }))
      .reverse(),
  };
};

const legendTitle = 'Progress';
const legendItems = ref<CustomLegendItem[]>([]);

const barChartWrapper = ref<HTMLElement>(null);

const chartOptions = computed<Partial<ChartOptions<'bar'>>>(() => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      title: {
        display: !!props.widgetStats?.length,
        text:
          props.widget?.values === 'ooc_progress'
            ? '# of systems'
            : '# of interfaces',
      },
      stacked: true,
      min: 0,
      ticks: {
        precision: 0,
      },
    },
  },
  layout: {
    padding: {
      top: 32, // TODO
    },
  },
  interaction: {
    mode: 'index',
  },
  plugins: {
    hooks: {
      beforeRender: () => {
        isRendering.value = true;
      },
      afterRender: () => {
        isRendering.value = false;
      },
    },
    chartAreaOffset: {
      min: 2,
      offset: 0.1,
    },
    customLegend: {
      onUpdate(items) {
        legendItems.value = items;
      },
    },
    legend: {
      display: false,
      position: 'top',
      align: 'end',
      title: {
        display: true,
        text: legendTitle,
        position: 'end',
        padding: 0,
      },
      labels: {
        generateLabels: (chart) => {
          return chart.data.datasets
            .map((dataset, datasetIndex) => {
              const isVisible = chart.isDatasetVisible(datasetIndex);
              const color = dataset.backgroundColor as string;
              return (
                isVisible && {
                  text: dataset.label,
                  datasetIndex,
                  fillStyle: color,
                  strokeStyle: 'rgba(0, 0, 0, 0.4)',
                  borderRadius: 3,
                  lineWidth: 1,
                }
              );
            })
            .filter(Boolean);
        },
        boxWidth: 14,
        boxHeight: 14,
        padding: 8,
      },
    },
    title: {
      display: false,
      text: props.widget?.name,
      align: 'start',
      color: 'rgb(var(--v-theme-color))',
      font: {
        family: 'var(--font-family-sans-serif)',
        weight: 'bold',
        size: 16,
      },
      padding: 0,
    },
    subtitle: {
      display: false,
      text: 'sdfsdfsdf',
      align: 'start',
      color: 'rgba(0,0,0,0.4)',
    },
    zoom: {
      pan: {
        enabled: true,
        mode: 'x',
      },
      zoom: {
        pinch: {
          enabled: true,
        },
        mode: 'x',
      },
    },
    datalabels: {
      labels: {
        value: {
          color: 'white',
          padding: 0,
        },
        total: {
          color: 'black',
          anchor: 'end',
          align: 'top',
          offset: -2,
        },
      },
    },
    tooltip: {
      enabled: true,
      position: 'top',
      external: externalTooltipHandler,
      callbacks: {
        label: (context) => {
          if (!context.raw) return null;
          return `${context.dataset.label}: ${context.raw}`;
        },
        footer: (context) => {
          const total = context?.reduce(
            (sum, dataPoint) => sum + (dataPoint.raw as number),
            0
          );
          return `Total: ${total}`;
        },
      },
    },
  },
}));

const sortByDataName = (dataObjA, dataObjB) => {
  return dataObjA.name.localeCompare(dataObjB.name);
};

const sortedWidgetStas = computed(() => {
  if (!props.widgetStats) return [];
  return [...props.widgetStats].sort(sortByDataName);
});

const chartDatasets = computed<ChartData<'bar'>['datasets']>(() => {
  if (!props.widget) return [];
  if (!props.widget.values) return [];
  const stacks = chartStacks[props.widget.values];
  return (
    stacks?.map((stack, index) => {
      return {
        label: stack.label,
        data: sortedWidgetStas.value.map((bar) => bar.data[stack.label] || 0),
        hoverBackgroundColor: stack.color,
        backgroundColor: chroma(stack.color).brighten(0.2).desaturate(1).css(),
        datalabels: {
          labels: {
            value: {
              display: (context) => {
                const value =
                  context.chart.data.datasets[context.datasetIndex].data[
                    context.dataIndex
                  ];
                return chartConfig.value.showBarCount && value ? 'auto' : false;
              },
            },
            total: {
              display: (context) => {
                if (!chartConfig.value.showTotalCount) return false;
                const lastVisibleBar = context.chart
                  .getSortedVisibleDatasetMetas()
                  .slice(-1)[0];
                const isLastVisibleBar = lastVisibleBar?.index === index;
                return isLastVisibleBar;
              },
              formatter: (_, context) => {
                context.chart.getSortedVisibleDatasetMetas().slice(-1)[0];
                const total = context.chart.data.datasets.reduce(
                  (sum, dataset, datasetIndex) => {
                    if (!context.chart.isDatasetVisible(datasetIndex)) {
                      return sum;
                    }
                    return sum + (dataset.data[context.dataIndex] as number);
                  },
                  0
                );
                return `${total}`;
              },
            },
          },
        },
      };
    }) || []
  );
});

const chartData = computed<ChartData<'bar'>>(() => {
  return {
    labels: sortedWidgetStas.value?.map((bar) => bar.name) || [],
    datasets: chartDatasets.value,
  };
});

const getChartInstance = () => {
  return chartRef.value?.chart;
};

const zoomIn = () => {
  const chart = getChartInstance();
  const currentRange = chart.scales.x;
  const rangeSize = currentRange.max - currentRange.min + 1;
  const newRangeSize = Math.ceil(rangeSize / 2);
  const newRange = {
    min: currentRange.min,
    max: currentRange.min + newRangeSize - 1,
  };
  chart.zoomScale('x', newRange, 'active');
};
const zoomOut = () => {
  const chart = getChartInstance();
  if (!chart.isZoomedOrPanned()) return;
  const currentRange = chart.scales.x;
  const rangeSize = currentRange.max - currentRange.min + 1;
  const newRangeSize = rangeSize * 2;
  const newRange = {
    min: currentRange.min - Math.floor(rangeSize / 2),
    max: currentRange.max + Math.ceil(rangeSize / 2),
  };
  const bounds = chart.getInitialScaleBounds().x;
  if (newRange.min < bounds.min) {
    newRange.min = bounds.min;
    newRange.max = Math.min(newRange.min + newRangeSize - 1, bounds.max);
  } else if (newRange.max > bounds.max) {
    newRange.max = bounds.max;
    newRange.min = Math.max(newRange.max - newRangeSize + 1, bounds.min);
  }
  chart.zoomScale('x', newRange, 'active');
};
const resetZoom = () => {
  const chart = getChartInstance();
  chart.resetZoom();
};

const isRendering = ref(false);

const setImageTitle = (
  options: { subtitle?: string; titleSize?: number } = {}
) => {
  const chart = getChartInstance();
  chart.options.plugins.title.display = true;
  chart.options.plugins.subtitle.text = options.subtitle || '';
  chart.options.plugins.subtitle.display = !!options.subtitle;
  if (typeof chart.options.plugins.title.font !== 'function') {
    chart.options.plugins.title.font.size = options.titleSize || 16;
  }
  return () => {
    chart.options.plugins.subtitle.display = false;
    chart.options.plugins.subtitle.text = '';
    chart.options.plugins.title.display = false;
  };
};

const setImageLegend = () => {
  const chart = getChartInstance();
  chart.options.plugins.legend.display = true;
  return () => {
    chart.options.plugins.legend.display = false;
  };
};

const disableAnimations = () => {
  const chart = getChartInstance();
  const originalTransitionDuration =
    chart.options.transitions.active.animation.duration;
  chart.options.transitions.active.animation.duration = 0;

  return () => {
    chart.options.transitions.active.animation.duration =
      originalTransitionDuration;
  };
};

const createImage = async (
  options: {
    size?: { width: number; height: number };
    titleSize?: number;
    subtitle?: string;
  } = {}
): Promise<HTMLImageElement | null> => {
  const chart = getChartInstance();
  if (!chart || !props.widget || !props.widgetStats) return null;
  await until(() => !isRendering.value).toBeTruthy();

  const resetTitle = setImageTitle(options);
  const resetLegend = setImageLegend();
  const resetAnimations = disableAnimations();

  chart.update('none');
  chart.resize(options.size?.width, options.size?.height);
  const img = new Image();
  img.src = chart.canvas.toDataURL();

  resetTitle();
  if (props.widget.chart === 'bar') resetLegend();
  resetAnimations();

  chart.update('none');
  chart.resize();
  return img;
};

const generateCsvData = () => {
  const { labels, datasets } = chartData.value;
  const xAxis = xAxisOptions(props.widget?.chart).find(
    ({ value }) => value === props.widget?.arguments
  );
  const header = [
    xAxis?.label || '',
    ...datasets.map((dataset) => dataset.label),
  ].join(',');
  const rows = labels.map((label, index) => {
    const values = chartData.value.datasets.map(
      (dataset) => dataset.data[index]
    );
    return [label, ...values].join(',');
  });
  return [header, ...rows].join('\n');
};

const wrapperWidth = ref<number>(0);

const chartWidth = computed(() => {
  const baseWidth = 25; //width of a single bar
  const chartWidth = baseWidth * (props.widgetStats?.length ?? 0);

  return Math.max(wrapperWidth.value, chartWidth);
});

let wrapperObserver = null;

onMounted(() => {
  wrapperObserver = new ResizeObserver((entries) => {
    wrapperWidth.value = entries[0].target.clientWidth;
  });

  wrapperObserver.observe(barChartWrapper.value);
});

const calculatedChartWidthInPx = computed(() => {
  const width = chartWidth.value;
  return `${width}px`;
});

defineExpose({
  zoomIn,
  zoomOut,
  resetZoom,
  createImage,
  generateCsvData,
});
</script>

<style lang="scss" scoped>
.dashboard-widget-chart {
  position: relative;
  max-width: 100%;
}

.dashboard-widget-chart__no-data {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 20px 40px;
  opacity: 0.4;
}

.dashboard-widget-chart__tooltip {
  padding: 0.3em 0;
}

.tooltip__data-point {
  display: flex;
  align-items: center;
  font-size: 0.8em;
}

.tooltip__title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.2em;
}

.tooltip__color-box {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.tooltip__label {
  font-weight: bold;
  margin-right: 0.4em;
}

.legend {
  position: absolute;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin: 0.5em 0;
  font-size: 0.8em;
}

.legend__title {
  opacity: 0.7;
  margin-right: 0.5em;
}

.legend__item {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  font-size: 0.9em;
  line-height: 18px;
  text-align: center;
  color: #fff;
  margin-left: 1px;
  background-color: var(--color);
  transition:
    background-color 0.2s,
    color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--hoverColor);
  }
}

.legend__item--hidden {
  position: relative;
  color: inherit;
  background: transparent !important;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 10 10'><rect x='0' y='0' width='10' height='10' fill='rgba(255,255,255,0.8)' /><path d='M10 0 L0 10' stroke='gray' stroke-width='0.5'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size:
      100% 100%,
      auto;
    opacity: 0.6;
    transition: opacity 0.2s;
  }

  &:hover {
    opacity: 1;

    &::after {
      opacity: 0;
    }
  }
}

.dashboard-widget__chart-wrapper {
  width: 100%;
  overflow-x: auto;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}
</style>
