<template>
  <FocusTrap
    :active="userCanEditProject && !isDialogOpened"
    :initialFocus="() => formRef.queryFocusableNodes()[0]"
  >
    <div class="project-form">
      <GenericForm
        ref="form"
        :resource="project"
        :formConfig="formConfig"
        :readonly="!userCanEditProject"
        submitLabel="Create Project"
        @cancel="onCancel"
        @created="onCreated"
      >
        <template #field-revisions v-if="canSeeRevisions">
          <p class="mb-0">
            {{
              $t('projectRevisions.currentRevision', { number: lastRevision })
            }}
          </p>
        </template>
      </GenericForm>

      <!-- <div v-if="canDuplicate" class="project-form__actions">
        <v-btn color="primary" variant="outlined" @click="onDuplicate">
          <v-icon start>mdi-content-copy</v-icon> Duplicate
        </v-btn>
      </div> -->

      <v-tooltip location="left" :disabled="false" v-if="editMode">
        <template #activator="{ props }">
          <div v-bind="props" class="project-form__actions">
            <v-btn color="primary" variant="outlined" :disabled="true">
              <v-icon start>mdi-content-copy</v-icon> Duplication
            </v-btn>
          </div>
        </template>
        <span>
          Duplication of projects is unfortunately down for maintenance at the
          moment.
        </span>
      </v-tooltip>

      <div v-if="editMode && userCanEditProject" class="project-form__archive">
        <v-btn
          v-if="isArchived"
          color="error"
          :loading="isUnarchiving"
          variant="outlined"
          @click="unarchiveProject"
        >
          <v-icon start>mdi-restore</v-icon>{{ $t('archiving.restoreLabel') }}
        </v-btn>
        <v-btn
          v-else
          color="error"
          :loading="isArchiving"
          variant="outlined"
          @click="confirmArchive"
        >
          <v-icon start>mdi-alert-circle</v-icon
          >{{ $t('archiving.archiveLabel') }}
        </v-btn>
        <ConfirmDialog
          ref="confirmDialog"
          :title="$t('archiving.confirmArchiveProject')"
          okButtonText="Archive"
          okButtonColor="error"
          cancelButtonIcon="mdi-close"
          okButtonIcon="mdi-alert-circle"
          :disableButtons="isLoading"
          maxWidth="540"
        >
          <v-card-text
            v-if="!relatedContexts.length && fetchingRelatedContexts"
            class="justify-center"
          >
            <v-progress-circular
              indeterminate
              size="64"
              color="rgb(var(--v-theme-color))"
            />
          </v-card-text>
          <v-card-text v-if="relatedContexts.length">
            {{ $t('archiving.archiveProjectWarning') }}
            <ul>
              <li v-for="context in relatedContexts" :key="context.id">
                {{ context.name }}
              </li>
            </ul>
          </v-card-text>
        </ConfirmDialog>
      </div>
    </div>
  </FocusTrap>
</template>

<script lang="ts">
import { Options, Prop, Ref, Vue } from 'vue-property-decorator';
import { FocusTrap } from 'focus-trap-vue';
import GenericForm from '../genericForm.vue';
import ConfirmDialog from '@/components/confirmDialog.vue';
import { Project } from '@/models/project';
import {
  FormFieldDefinition,
  FormFieldType,
  FormConfig,
} from '@/services/forms';
import { composeMessage } from '@/services/errorHandler';
import { Context } from '@/models/context';
import { projectNameRules } from '@/utils/validationRules';

const REQUEST_TYPE = 'project-related-contexts';

@Options({
  components: {
    ConfirmDialog,
    FocusTrap,
    GenericForm,
  },
  emits: ['created', 'cancel', 'duplicate', 'add-context', 'archive'],
})
export default class ProjectsProjectForm extends Vue {
  @Prop({ default: null }) projectId: string | null;
  @Prop(Boolean) hideAddContextButton: boolean;
  @Prop({ required: true }) projectNamesList: string[];
  @Ref('form') readonly formRef: GenericForm;
  @Ref() readonly confirmDialog: ConfirmDialog;

  isArchiving = false;
  isUnarchiving = false;
  fetchingRelatedContexts = false;
  isDialogOpened = false;

  get isLoading() {
    return this.isArchiving || this.fetchingRelatedContexts;
  }

  get formConfig(): FormConfig {
    const schema: FormFieldDefinition[] = [
      {
        attribute: 'name',
        label: 'Name',
        type: FormFieldType.Text,
        rules: [
          ...projectNameRules,
          (value: string) => {
            if (this.editMode && this.project.name === value) return true;
            return (
              !this.projectNamesList.includes(value.toLowerCase()) ||
              this.$t('apps.design.forms.uniqueProjectName')
            );
          },
        ],
      },
      {
        attribute: 'description',
        label: 'Description',
        type: FormFieldType.Textarea,
      },
      this.canSeeRevisions && {
        attribute: 'revisions',
        label: 'Last revision',
        type: FormFieldType.Slot,
        ignoreValue: true,
      },
    ];
    return {
      schema: schema.filter(Boolean),
      createResource: this.$store.$direct.project.dispatchCreateResource,
      updateResource: this.$store.$direct.project.dispatchUpdateResource,
    };
  }

  get project(): Project {
    return (
      this.projectId &&
      this.$store.$direct.project.getSimplifiedResourceSet()[this.projectId]
    );
  }

  get editMode() {
    return !!this.projectId;
  }

  get userCanEditProject() {
    return (
      !this.editMode ||
      (!!this.project && this.$store.$direct.userPermission.canWriteProject())
    );
  }

  get canDuplicate() {
    return (
      this.$store.$direct.userPermission.canCreateProject() && this.editMode
    );
  }

  get isArchived() {
    return this.editMode && this.project.archived;
  }

  get relatedContexts() {
    return this.$store.$direct.context.getFullResource(
      REQUEST_TYPE,
      this.projectId
    ) as Context[];
  }

  get canSeeRevisions(): boolean {
    if (!this.projectId) return false;
    return (
      this.$store.$direct.userPermission.canReadRevisions(this.projectId) &&
      this.editMode &&
      !!this.lastRevision
    );
  }

  async mounted() {
    await this.$store.$direct.context.dispatchLoadActiveNonTradeStudyContext({
      projectId: this.projectId,
    });
  }

  get lastRevision() {
    return this.project?.revisions_count;
  }

  handleShowDialog() {
    this.fetchRelatedContexts();
  }

  async confirmArchive() {
    this.isDialogOpened = true;
    this.fetchRelatedContexts();
    this.confirmDialog
      .confirm()
      .then((val) => {
        if (val) {
          this.archiveProject();
          this.$emit('archive', { projectId: this.projectId, archived: true });
        }
      })
      .finally(() => (this.isDialogOpened = false));
  }

  async archiveProject() {
    if (this.editMode && !this.isArchived) {
      this.isArchiving = true;
      await this.$store.$direct.project
        .dispatchArchiveResource({ resourceId: this.projectId })
        .catch((err) =>
          this.$store.$direct.notifications.dispatchNotify({
            type: 'error',
            message: composeMessage(err),
          })
        );
      this.isArchiving = false;
    }
  }

  async unarchiveProject() {
    if (this.editMode && this.isArchived) {
      this.isUnarchiving = true;
      await this.$store.$direct.project
        .dispatchUnarchiveResource({ resourceId: this.projectId })
        .catch((err) =>
          this.$store.$direct.notifications.dispatchNotify({
            type: 'error',
            message: composeMessage(err),
          })
        );
      this.isUnarchiving = false;
      this.$emit('archive', { projectId: this.projectId, archived: false });
    }
  }
  onDuplicate() {
    this.$emit('duplicate');
  }

  async fetchRelatedContexts() {
    this.fetchingRelatedContexts = true;
    await this.$store.$direct.context
      .dispatchLoadFullResource({
        filterProjectId: this.projectId,
        filterArchived: false,
        requestType: REQUEST_TYPE,
        requestId: this.projectId,
      })
      .catch((err) =>
        this.$store.$direct.notifications.dispatchNotify({
          type: 'error',
          message: composeMessage(err),
        })
      );
    this.fetchingRelatedContexts = false;
  }

  onCancel() {
    this.$emit('cancel');
  }

  onCreated(projectId: string) {
    this.$emit('created', projectId);
  }
}
</script>

<style lang="scss" scoped>
.project-form {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.project-form__actions {
  position: relative;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;

  &::before {
    position: absolute;
    top: 0;
    content: '';
    display: block;
    margin: 0 auto;
    width: calc(100% - 48px);
    height: 0;
    border-bottom: 1px solid rgb(var(--v-theme-line));
  }
}

.project-form__archive {
  margin-top: auto;
  padding: 24px;
  text-align: right;
}
</style>
