<template>
  <v-tooltip location="right" :disabled="toolbar.isExpanded">
    <span>{{ groupTitle }}</span>
    <template #activator="{ props: attrs }">
      <v-list-group
        :prepend-icon="icon"
        value="TreeView"
        :append-icon="!!contextsOrder.length ? '$expand' : ''"
        :class="{
          'design-navigation-drawer-draggable--disabled': !contextsOrder.length,
        }"
        v-bind="attrs"
      >
        <template #activator="{ props: activatorProps }">
          <v-list-item
            :title="groupTitle"
            :prepend-icon="icon"
            v-bind="activatorProps"
            class="draggable-list-item__activator"
          />
        </template>
        <template #default>
          <DraggableList
            :modelValue="contextsOrder"
            @update:modelValue="onOrderChange"
          >
            <template #prepend="{ item }">
              <v-tooltip v-if="showSyntaxUpdateBadge(item)" location="top">
                <span>In process of syntax update</span>
                <template #activator="{ props: tooltipProps }">
                  <v-badge
                    color="error"
                    content="!"
                    v-bind="tooltipProps"
                  ></v-badge>
                </template>
              </v-tooltip>
            </template>
            <template #item="{ item }">
              <v-tooltip
                location="right"
                :disabled="formatContextName(item)?.length <= 12"
              >
                <span>{{ formatContextName(item) }}</span>
                <template #activator="{ props: tooltipAttrs }">
                  <v-list-item
                    class="design-navigation-drawer-draggable__item"
                    v-bind="tooltipAttrs"
                  >
                    <v-btn
                      icon
                      size="default"
                      width="36"
                      height="36"
                      variant="text"
                      @click="toggleContext(item)"
                    >
                      <v-icon
                        :color="isActive(item) ? 'white' : 'grey lighten-1'"
                      >
                        {{
                          isActive(item)
                            ? 'mdi-checkbox-marked'
                            : 'mdi-checkbox-blank'
                        }}
                      </v-icon>
                    </v-btn>

                    <v-list-item-title>
                      {{ formatContextName(item) }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-tooltip>
            </template>
          </DraggableList>
        </template>
      </v-list-group>
    </template>
  </v-tooltip>
</template>
<script lang="ts" setup>
import { computed, inject, onMounted, onUnmounted, ref, watch } from 'vue';
import DraggableList from '@/components/draggableList.vue';
import { ToolbarContext } from '@/components/layout/types';
import { useRoute, useRouter } from 'vue-router';
import { useProjectLayout } from '@/composables/projectLayout';
import { RouteName } from '@/enums/routing';
import { eventBus } from '@/services/eventBus/eventBus';
import { Context } from '@/models/context';
import { useDirectStore } from '@/composables/store';

type Props = {
  icon: string;
  groupTitle: string;
  projectId: string;
};

const props = defineProps<Props>();

const { formatContextName, contexts, revisionId, nextRevisionId } =
  useProjectLayout();

const route = useRoute();
const router = useRouter();
const store = useDirectStore();

const toolbar = inject<ToolbarContext>('toolbarContext');

const contextsOrder = ref<string[]>([]);

onMounted(() => {
  eventBus.$on('deselect-doma-aspect', toggleContext);
  eventBus.$on('doma-drag-drop-order-changed', setupNewContextOrder);
});

onUnmounted(() => {
  eventBus.$off('deselect-doma-aspect');
  eventBus.$off('doma-drag-drop-order-changed');
});

const showSyntaxUpdateBadge = (contextId: string) => {
  const context = store.context.getSimplifiedResourceSet()[
    contextId
  ] as Context;
  return !!context?.temporal;
};

const activeContextIds = computed(() => {
  return [
    route.params.contextId,
    ...((route.params.additionalContextIds as string[]) || []),
  ].filter(Boolean);
});

const baseActiveContexts = computed(() =>
  contexts.value.filter(
    (context) => !context.baseContext?.trade_study && !context.archived
  )
);

const baseContexts = computed(() => baseActiveContexts.value);

const orderedActiveContextsIds = computed(() =>
  contextsOrder.value.filter((c) => activeContextIds.value.includes(c))
);
const activeContextIdentifier = computed(() =>
  activeContextIds.value.join('/')
);

const orderedActiveContextsIdentifier = computed(() =>
  orderedActiveContextsIds.value.join('/')
);

const isActive = (contextId: string) => {
  return activeContextIds.value.includes(contextId);
};

const setupNewContextOrder = (dragAndDropData: {
  draggedContextID: string;
  droppedOnContextID: string;
}) => {
  const draggedIndex = contextsOrder.value.findIndex(
    (entry) => entry === dragAndDropData.draggedContextID
  );

  const droppedOnIndex = contextsOrder.value.findIndex(
    (entry) => entry === dragAndDropData.droppedOnContextID
  );

  if (draggedIndex === -1 || droppedOnIndex === -1) return;

  const newOrder = [...contextsOrder.value];
  newOrder.splice(draggedIndex, 1);
  newOrder.splice(droppedOnIndex, 0, dragAndDropData.draggedContextID);
  onOrderChange(newOrder);
};

const onOrderChange = (newOrder: string[]) => {
  contextsOrder.value = newOrder;
  if (orderedActiveContextsIdentifier.value !== activeContextIdentifier.value) {
    updateRoute(orderedActiveContextsIds.value);
  }
};

const updateRevisionRoute = (contextIds: string[]) => {
  if (contextIds.length === 1) {
    router.push({
      name: RouteName.RevisionCore,
      params: {
        projectId: props.projectId,
        contextId: contextIds[0],
        revisionId: revisionId.value,
        nextRevisionId: nextRevisionId.value,
      },
    });
  }
  if (contextIds.length > 1) {
    const orderedContextIds = contextsOrder.value.filter((c) =>
      contextIds.includes(c)
    );
    router.push({
      name: RouteName.RevisionCoreSplitView,
      params: {
        projectId: props.projectId,
        contextId: orderedContextIds[0],
        additionalContextIds: orderedContextIds.slice(1),
        revisionId: revisionId.value,
        nextRevisionId: nextRevisionId.value,
      },
    });
  }
};

const updateRoute = (contextIds: string[]) => {
  if (revisionId.value) {
    updateRevisionRoute(contextIds);
    return;
  }
  if (contextIds.length === 1) {
    router.push({
      name: RouteName.Core,
      params: {
        projectId: props.projectId,
        contextId: contextIds[0],
      },
    });
  }
  if (contextIds.length > 1) {
    const orderedContextIds = contextsOrder.value.filter((c) =>
      contextIds.includes(c)
    );
    router.push({
      name: RouteName.CoreSplitView,
      params: {
        projectId: props.projectId,
        contextId: orderedContextIds[0],
        additionalContextIds: orderedContextIds.slice(1),
      },
    });
  }
};

const toggleContext = (contextId: string) => {
  const contextIds = activeContextIds.value.slice();
  const isSelected = isActive(contextId);
  if (isSelected) {
    const index = contextIds.indexOf(contextId);
    contextIds.splice(index, 1);
  } else {
    contextIds.push(contextId);
  }
  updateRoute(contextIds as string[]);
};

watch(
  baseContexts,
  () => {
    contextsOrder.value = contextsOrder.value
      .filter((id) => baseContexts.value.some((c) => c.baseContext?.id === id))
      .concat(baseContexts.value.map((c) => c.baseContext?.id))
      .filter((id, index, all) => all.indexOf(id) === index);
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.draggable-list-item__activator :deep(.v-list-item-title) {
  font-size: 1rem;
}
.design-navigation-drawer-draggable--disabled {
  opacity: 0.5;
}

.design-navigation-drawer-draggable__item {
  padding-left: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & > :deep(.v-list-item__content) {
    display: flex;
    align-items: center;
  }
}
</style>
