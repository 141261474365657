import { utils } from 'jsonapi-vuex';
import { createModuleComponent } from '../utils/factories';
import { DelayedResponseService } from '@/services/delayedResponse';
import { ResourceType } from '@/services/resources';
import { WebSocketEvent, WebSocketEventResponse } from '@/models/ws';
import { JsonApiIdentification } from '@/models/api';
import { getAccessToken } from '@/auth';
import { buildParametersPath } from '@/utils/path';
import { ComponentType } from './_types';
import { JVTopLevelSingleResource } from '@/models/jv';

const wssURL = `${window.__env.VUE_APP_WSS_HOST}`;
const auth0Domain = window.btoa(window.__env.VUE_APP_AUTH0_DOMAIN);

interface Socket {
  target: JsonApiIdentification;
  socket: WebSocket;
  connectionId: string | null;
  connected: boolean;
  heartbeatInterval: number | null;
  delayedResponseService: DelayedResponseService | null;
}

interface State {
  sockets: Socket[];
}

export interface OnMessagePayload {
  event: JVTopLevelSingleResource & WebSocketEvent;
  data: WebSocketEventResponse;
  target: JsonApiIdentification;
}

const defaultSocket = ({
  target,
  socket,
}: {
  target: JsonApiIdentification;
  socket: WebSocket;
}): Socket => ({
  target,
  socket,
  connectionId: null,
  connected: false,
  heartbeatInterval: null,
  delayedResponseService: null,
});

export default createModuleComponent({
  type: ComponentType.WsConnection,
  dependencies: [],
  setup({ getAccessors }) {
    const state = (): State => ({
      sockets: [],
    });

    const getters = {
      socket: (state: State) => (resource: string) =>
        state.sockets.find((socket) => socket.target.id === resource),

      // TODO: What should happen if connection can not be established?
      /* In Simo, Core and Doma we are depending on below service and it cause errors if
       * there is not connection. User is not able to perform any CRUD operation, because
       * deleyedResponseService return null
       */
      delayedResponseService: (state: State) => (resource: string) => {
        const delayedResponseService = state.sockets.find(
          (socket) => socket.target.id === resource
        )?.delayedResponseService;
        return delayedResponseService ? delayedResponseService : null;
      },
    };

    const mutations = {
      setSocket(
        state: State,
        payload: { target: JsonApiIdentification; socket: WebSocket }
      ) {
        const { target, socket } = payload;
        state.sockets.push({
          ...defaultSocket({ target, socket }),
        });
      },
      setConnected(state: State, resourceId: string) {
        const socket = state.sockets.find((s) => s.target.id === resourceId);
        socket.connected = true;
        socket.connectionId = `c-${Date.now() + Math.random()}`;
        socket.heartbeatInterval = window.setInterval(() => {
          socket.socket.send(JSON.stringify({ action: 'heartbeat' }));
        }, 30 * 1000);
      },
      cleanup(state: State, resourceId: string) {
        const socketIndex = state.sockets.findIndex(
          (socket) => socket.target.id === resourceId
        );
        clearInterval(state.sockets[socketIndex].heartbeatInterval);
        state.sockets[
          socketIndex
        ].delayedResponseService.cleanOperationHandlers();
        state.sockets.splice(socketIndex, 1);
      },
      initializeCreateResponseService(state: State, context: string) {
        const socketIndex = state.sockets.findIndex(
          (socket) => socket.target.id === context
        );
        state.sockets[socketIndex].delayedResponseService =
          new DelayedResponseService();
      },
    };

    const actions = {
      async connect(
        context,
        payload: {
          target: JsonApiIdentification;
          onMessageReceived?: (payload: OnMessagePayload) => void;
          onMessageUnhandled?: (payload: OnMessagePayload) => void;
          onError?: (payload: unknown) => unknown;
        }
      ) {
        const { target, onMessageReceived, onMessageUnhandled, onError } =
          payload;
        const prevTarget = read(getters.socket)(payload.target.id)?.target;
        if (
          prevTarget &&
          prevTarget.id === target.id &&
          prevTarget.type === target.type
        ) {
          return;
        }
        await dispatch(actions.disconnect)(target.id);
        const pathParameters = buildParametersPath({
          wsTargetType: target.type,
          wsTargetId: target.id,
          wsAuthorization: getAccessToken(),
          wsAuth0Domain: auth0Domain,
        });
        const socket = new WebSocket(`${wssURL}${pathParameters}`);
        socket.addEventListener('message', (e) =>
          dispatch(actions.handleMessage)({
            socketId: target.id,
            event: e,
            onMessageReceived,
            onMessageUnhandled,
          })
        );
        socket.addEventListener('error', onError);
        socket.addEventListener('close', () =>
          dispatch(actions.handleClose)({ target })
        );
        socket.addEventListener('open', () =>
          commit(mutations.setConnected)(target.id)
        );
        commit(mutations.setSocket)({ target, socket });
        commit(mutations.initializeCreateResponseService)(target.id);
      },
      async disconnect(context, resourceId: string) {
        return new Promise<void>((resolve) => {
          const socket = read(getters.socket)(resourceId)?.socket;
          if (!socket) return resolve();
          socket.addEventListener('close', () => resolve());
          socket.close();
        });
      },
      async handleMessage(
        context,
        payload: {
          socketId: string;
          event: MessageEvent;
          onMessageReceived?: (payload: OnMessagePayload) => void;
          onMessageUnhandled?: (payload: OnMessagePayload) => void;
        }
      ) {
        try {
          const data = JSON.parse(payload.event.data) as
            | WebSocketEventResponse
            | 'OK';
          if (data === 'OK') {
            // heartbeat response
            return;
          }
          const normalizedEvent = utils.jsonapiToNorm(
            data.data
          ) as JVTopLevelSingleResource & WebSocketEvent;
          const target = normalizedEvent?._jv?.relationships?.target
            ?.data as JsonApiIdentification;
          payload.onMessageReceived?.({
            event: normalizedEvent,
            data,
            target,
          });
          const defaultPrevented = getDelayedResponseService(
            payload.socketId
          ).runOperationHandlers({
            operation: normalizedEvent.operation,
            targetType: target?.type as ResourceType,
            targetId: target?.id,
            data,
            event: payload.event,
          });

          if (defaultPrevented) return;

          payload.onMessageUnhandled?.({
            event: normalizedEvent,
            data,
            target,
          });
        } catch (err) {
          console.error(err);
        }
      },
      handleClose(context, payload: { target: JsonApiIdentification }) {
        const prevTarget = read(getters.socket)(payload.target.id)?.target;
        if (
          prevTarget &&
          prevTarget.id === payload.target.id &&
          prevTarget.type === payload.target.type
        ) {
          commit(mutations.cleanup)(payload.target.id);
        }
      },
    };

    const { read, commit, dispatch } = getAccessors();
    const getDelayedResponseService = read(getters.delayedResponseService);

    const getIsConnected = (resource: string) =>
      read(getters.socket)(resource)?.connected;

    const getTarget = (resource: string) =>
      read(getters.socket)(resource)?.target;

    const getConnectionId = (resource: string) =>
      read(getters.socket)(resource)?.connectionId;

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {
        getDelayedResponseService,
        dispatchHandleMessage: dispatch(actions.handleMessage),
      },
      public: {
        getIsConnected,
        getTarget,
        getConnectionId,
        dispatchConnect: dispatch(actions.connect),
        dispatchDisconnect: dispatch(actions.disconnect),
      },
    };
  },
});
