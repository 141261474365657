import { createModule } from '../utils/factories';
import { resources } from '@/services/resources';
import crud from '../components/crud';
import indexManager from '../components/indexManager';
import processingTracker from '../components/processingTracker';

type State = Record<string, never>;

export default createModule({
  path: 'tradeStudyDeltaGroups',
  resourceProfile: resources.tradeStudyDeltaGroup,
  components: [crud, indexManager, processingTracker],
  setup({ components }) {
    const state = (): State => ({});

    const mutations = {};

    const getters = {};

    const actions = {};

    return {
      module: {
        state,
        getters,
        mutations,
        actions,
      },
      protected: {},
      public: {
        ...components.$crud.public,
        ...components.$indexManager.public,
        ...components.$processingTracker.public,
      },
    };
  },
});
