import { createRouter, createWebHistory } from 'vue-router';
import { requireAuth } from '@/auth';
import { SecRouteConfig } from '@/router/utils';
import { RouteName } from '@/enums/routing';
import projectsRoutes from '@/router/projects/routes';
import setupRoutes from '@/router/setup/routes';
import adminRoutes from '@/router/admin/routes';
import launchpadRoutes from '@/router/launchpad/routes';
import Callback from '@/auth/callback.vue';

const routes: SecRouteConfig[] = [
  {
    path: '/',
    name: RouteName.Root,
    redirect: { path: '/launchpad' },
  },
  ...launchpadRoutes,
  ...projectsRoutes,
  ...setupRoutes,
  ...adminRoutes,
  {
    path: '/callback',
    name: RouteName.AuthCallback,
    component: Callback,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RouteName.Root },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Go through routes and extract meta information such as page title etc.
  let title = 'SEC-Hub';
  if (to.meta && to.meta.title) title = `${to.meta.title} - ${title}`;
  document.title = title;

  // Determine if page requires authentication
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    return requireAuth(to, from, next);
  }

  next();
});

export default router;
