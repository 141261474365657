import {
  ChartTypes,
  DashboardWidget,
  DashboardWidgetFilters,
  XAxisType,
  YAxisType,
} from '@/models/dashboardWidget';
import { OOC } from '@/models/objectOccurrence';
import { Owner } from '@/models/owner';
import { OwnerGroup } from '@/models/ownerGroup';
import { Revision } from '@/models/revision';

type ChartStack = {
  label: string;
  color: string;
};

export type DefaultWidgetState = Omit<
  DashboardWidget,
  'id' | 'values_filters' | 'arguments_filters'
> & {
  values_filters: DashboardWidgetFilters['values_filters'];
  arguments_filters: DashboardWidgetFilters['arguments_filters'];
};

const BarChartXAxisOptions: { value: XAxisType; label: string }[] = [
  { value: 'owners', label: 'Owners' },
  { value: 'owner_groups', label: 'Owner groups' },
  { value: 'ooc_structure_level', label: 'Systems' },
  { value: 'revisions', label: 'Revisions' },
];

const PieChartXAxisOptions: { value: XAxisType; label: string }[] = [
  { value: 'simo_complete', label: 'SIMO Complete' },
  { value: 'simo_interfaces', label: 'SIMO Interfaces' },
];

type XAxisOptions = typeof BarChartXAxisOptions | typeof PieChartXAxisOptions;

export const xAxisOptions = (barType: ChartTypes): XAxisOptions => {
  if (barType === 'pie') return PieChartXAxisOptions;
  else return BarChartXAxisOptions;
};

export const BarChartYAxiosOptions: { value: YAxisType; label: string }[] = [
  { value: 'ooc_progress', label: 'System Progress' },
  { value: 'oor_progress', label: 'Interface Progress' },
];

export const PieChartYAxiosOptions: unknown[] = [];

export type YAxisOptions =
  | typeof BarChartYAxiosOptions
  | typeof PieChartYAxiosOptions;

export const yAxisLabels = (barType: ChartTypes): YAxisOptions => {
  if (barType === 'pie') return PieChartYAxiosOptions;
  else return BarChartYAxiosOptions;
};

export const defaultWidgetState = (): DefaultWidgetState => ({
  name: '',
  context: null,
  arguments: null,
  values: null,
  chart: 'bar', // Default by backend
  arguments_filters: {
    owners: {
      owner_group_ids_in: [],
      owner_ids_in: [],
    },
    structure_level_in: [],
    include_ids: [],
    exclude: [],
    syntax_element_id_in: [],
    revision_id_in: [],
  },
  values_filters: {
    revision_id_eq: null,
    owners: {
      owner_ids_in: [],
      owner_group_ids_in: [],
    },
    structure_level_in: [],
    syntax_element_id_in: [],
    progress_step_in: null,
    oor_progress_step_in: null,
    include_ids: [],
    exclude: [],
  },
});

const createArrayOfResourceIds = (
  resources: { id: string }[] | number[] | string[]
): string[] | number[] | undefined => {
  const arr = resources?.map((res) => res?.id || res);
  return arr?.length ? arr : undefined;
};
const createResourceId = (
  resource: { id?: string } | null
): string | undefined => resource?.id || undefined;
const createObjectResource = (
  resource: {
    orderIn: number[] | null;
    withEmpty: boolean;
  } | null
): { order_in: number[] | null; with_empty: boolean } | null =>
  resource
    ? {
        order_in: resource.orderIn || undefined,
        with_empty: resource.withEmpty,
      }
    : undefined;
const createRevisionResource = (
  resources: Revision[]
): DashboardWidget['arguments_filters']['revision_id_in'] => {
  if (!resources.length) return undefined;
  const revisionIds = resources
    .filter((res) => res.id !== 'current')
    .map(({ id }) => id);
  return {
    revision_ids: revisionIds.length ? revisionIds : undefined,
    with_current_state: !!resources.find(({ id }) => id === 'current'),
  };
};

type DeserializedFilters = DefaultWidgetState['arguments_filters'] &
  DefaultWidgetState['values_filters'];

type SerializedFilters = DashboardWidget['arguments_filters'] &
  DashboardWidget['values_filters'];

const createDeserializerWithDefault =
  <FilterType extends keyof SerializedFilters>(
    defaultVal: DeserializedFilters[FilterType],
    deserialize: (
      value: SerializedFilters[FilterType],
      ctx: FiltersContext
    ) =>
      | DeserializedFilters[FilterType]
      | Promise<DeserializedFilters[FilterType]>
  ) =>
  async (values: SerializedFilters[FilterType], ctx: FiltersContext) => {
    try {
      const value = await deserialize(values, ctx);
      return value;
    } catch (error) {
      return defaultVal;
    }
  };

const deserializeObjectResource = (
  resource: { order_in: number[] | null; with_empty: boolean } | null
): DefaultWidgetState['values_filters']['progress_step_in'] =>
  resource
    ? { orderIn: resource.order_in, withEmpty: resource.with_empty }
    : null;

const syntaxElementDeserializer =
  createDeserializerWithDefault<'syntax_element_id_in'>([], (ids) => {
    if (!Array.isArray(ids)) return [];
    return ids;
  });

const structureLevelsDeserializer =
  createDeserializerWithDefault<'structure_level_in'>([], (ids) => {
    if (!Array.isArray(ids)) return [];
    return ids.map((id) => +id);
  });

const oocsDeserializer = createDeserializerWithDefault<
  'include_ids' | 'exclude'
>([], (ids, ctx) => {
  if (typeof ids === 'string') return;
  const handleError = (err: unknown) => {
    console.warn('[Widget filters] Unable to load OOC');
    console.error(err);
    return null;
  };
  return Promise.all(ids.map((id) => ctx.loadOOC(id).catch(handleError))).then(
    (arr) => arr.filter(Boolean)
  );
});

const singleRevisionDeserializer =
  createDeserializerWithDefault<'revision_id_eq'>(null, (id, ctx) => {
    if (Array.isArray(id) || !id) return;
    const handleError = (err: unknown) => {
      console.warn('[Widget filters] Unable to load Revision');
      console.error(err);
      return null;
    };
    return ctx.loadRevision(id).catch(handleError);
  });

const revisionDeserializer = createDeserializerWithDefault<'revision_id_in'>(
  null,
  async (revision_state, ctx) => {
    const results = revision_state?.with_current_state
      ? [{ id: 'current' }]
      : [];
    const handleError = (err: unknown) => {
      console.warn('[Widget filters] Unable to load Revision');
      console.error(err);
      return null;
    };
    const revisions = await Promise.all(
      revision_state?.revision_ids?.map((id) =>
        ctx.loadRevision(id).catch(handleError)
      ) || []
    ).then((arr) => arr.filter(Boolean));
    return [...revisions, ...results];
  }
);

const ownershipDeserializer = createDeserializerWithDefault<'owners'>(
  { owner_group_ids_in: [], owner_ids_in: [] },
  async (ownership, ctx) => {
    const handleError = (err: unknown) => {
      console.warn('[Widget filters] Unable to load Owner');
      console.error(err);
      return null;
    };

    return {
      owner_ids_in: await Promise.all(
        ownership?.owner_ids_in?.map((id) =>
          ctx.loadOwner(id).catch(handleError)
        ) || []
      ).then((arr) => arr.filter(Boolean)),
      owner_group_ids_in: await Promise.all(
        ownership?.owner_group_ids_in?.map((id) =>
          ctx.loadOwnerGroup(id).catch(handleError)
        ) || []
      ).then((arr) => arr.filter(Boolean)),
    };
  }
);

const filtersSerializers = {
  structure_level_in: createArrayOfResourceIds,
  syntax_element_id_in: createArrayOfResourceIds,
  include_ids: createArrayOfResourceIds,
  exclude: createArrayOfResourceIds,
  progress_step_in: createObjectResource,
  oor_progress_step_in: createObjectResource,
  revision_id_in: createRevisionResource,
  revision_id_eq: createResourceId,
  owner_group_ids_in: createArrayOfResourceIds,
  owner_ids_in: createArrayOfResourceIds,
};

export const filtersDeserializers: {
  [Key in keyof SerializedFilters]: (
    value: SerializedFilters[Key],
    ctx: FiltersContext
  ) => DeserializedFilters[Key] | Promise<DeserializedFilters[Key]>;
} = {
  include_ids: oocsDeserializer,
  exclude: oocsDeserializer,
  revision_id_eq: singleRevisionDeserializer,
  owners: ownershipDeserializer,
  progress_step_in: deserializeObjectResource,
  oor_progress_step_in: deserializeObjectResource,
  structure_level_in: structureLevelsDeserializer,
  syntax_element_id_in: syntaxElementDeserializer,
  revision_id_in: revisionDeserializer,
};

export const deserializeWidgetFilters = async <
  FilterType extends 'values_filters' | 'arguments_filters',
>(
  filters: DashboardWidget[FilterType],
  ctx: FiltersContext
): Promise<DefaultWidgetState[FilterType]> => {
  const deserializedFilters = await Promise.all(
    Object.entries(filtersDeserializers).map(async ([key, deserialze]) => {
      const value = await deserialze(filters[key], ctx);
      return { [key]: value } as DefaultWidgetState[FilterType];
    })
  ).then((res) =>
    res.reduce(
      (acc, prop) => ({ ...acc, ...prop }),
      {} as DefaultWidgetState[FilterType]
    )
  );
  return deserializedFilters;
};

export const serializeWidgetFilters = <
  FilterType extends 'values_filters' | 'arguments_filters',
>(
  filters: DashboardWidgetFilters[FilterType]
): DashboardWidget[FilterType] => {
  const serializedState: DashboardWidget[FilterType] = {};
  Object.keys(filters).forEach((key) => {
    if (key.includes('owners') || key.includes('revision_id_eq')) return;
    serializedState[key] = filtersSerializers[key](filters[key]) || undefined;
  });
  Object.keys(filters.owners).forEach((key) => {
    if (!serializedState['owners']) serializedState['owners'] = {};
    serializedState['owners'][key] =
      filtersSerializers[key](filters.owners[key]) || undefined;
  });
  serializedState.owners &&
  !Object.values(serializedState.owners)
    .map((val) => val)
    .filter(Boolean).length
    ? (serializedState['owners'] = undefined)
    : null;

  if ('revision_id_in' in filters && filters.revision_id_in) {
    serializedState['revision_id_in'] =
      filtersSerializers['revision_id_in'](filters.revision_id_in) || undefined;
  }
  if ('revision_id_eq' in filters && filters.revision_id_eq) {
    if (!serializedState['revision_id_eq'])
      serializedState['revision_id_eq'] =
        filtersSerializers['revision_id_eq'](filters['revision_id_eq']) ||
        undefined;
  }
  return serializedState;
};

export type FiltersContext = {
  loadOOC: (id: string) => Promise<OOC>;
  loadOwner: (id: string) => Promise<Owner>;
  loadOwnerGroup: (id: string) => Promise<OwnerGroup>;
  loadRevision: (id: string) => Promise<Revision>;
};

const skipYAxis = (chartType: ChartTypes) => ['pie'].includes(chartType);

export const convertWidget = (widget: Omit<DefaultWidgetState, 'context'>) => {
  return {
    ...widget,
    values: skipYAxis(widget.chart) ? undefined : widget.values,
    arguments_filters: serializeWidgetFilters(widget.arguments_filters),
    values_filters: skipYAxis(widget.chart)
      ? undefined
      : serializeWidgetFilters(widget.values_filters),
  };
};

export const chartStacks: Record<YAxisType, ChartStack[]> = {
  ooc_progress: [
    { label: '0', color: '#333333' },
    { label: '1', color: '#ff0000' },
    { label: '2', color: '#f73109' },
    { label: '3', color: '#ef5c11' },
    { label: '4', color: '#dfa21f' },
    { label: '5', color: '#ccd02b' },
    { label: '6', color: '#8ec036' },
    { label: '7', color: '#5fb03d' },
    { label: '?', color: '#999999' },
  ],
  oor_progress: [
    { label: '0', color: '#333333' },
    { label: '1', color: '#ff0000' },
    { label: '2', color: '#ed7d31' },
    { label: '3', color: '#00b050' },
    { label: '?', color: '#999999' },
  ],
};
