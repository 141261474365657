<template>
  <v-list-item class="ooc-list-item" style="padding: 0" :disabled="disabled">
    <div class="ooc-list-item__content" :style="{ borderLeftColor: oocColor }">
      <v-list-item-title class="ooc-list-item__title" :style="{ fontSize }">
        <object-occurrence-label
          :ooc="ooc"
          :lightFont="lightFont"
          :withParentReferences="withParentReferences"
        />
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="alternativeName"
        class="ooc-list-item__subtitle"
        :style="{ fontSize }"
      >
        {{ alternativeName }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="!twoLines">
        {{ ooc.reference_designation }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        class="ooc-list-item__syntax-element"
        :style="{ fontSize }"
      >
        {{ ooc.syntax_element?.name }}
      </v-list-item-subtitle>
    </div>
    <template #append>
      <slot name="append-inner" />
    </template>

    <slot name="append"></slot>
  </v-list-item>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import ObjectOccurrenceLabel from '@/components/design/objectOccurrenceLabel.vue';
import { OOC } from '@/models/objectOccurrence';

@Options({
  components: {
    ObjectOccurrenceLabel,
  },
})
export default class OOCSearch extends Vue {
  @Prop({ type: Object, required: true }) ooc!: OOC;
  @Prop(Boolean) lightFont: boolean;
  @Prop(Boolean) twoLines: boolean;
  @Prop(Boolean) withParentReferences: boolean;
  @Prop(Boolean) disabled;
  @Prop({ type: String }) alternativeName?: string;
  @Prop({ type: String }) fontSize?: string;

  get oocColor() {
    return this.ooc.hex_color && `#${this.ooc.hex_color}`;
  }
}
</script>

<style lang="scss">
// unscoped
.ooc-list-item {
  padding: 0;
  overflow: hidden;
}
.ooc-list-item__content {
  border-left: $tree-node-color-width solid transparent;
  padding: $space-x-small $space-small !important;
  flex-flow: column;
  align-items: flex-start;
  .v-list--dense &,
  .v-list-item--dense & {
    padding: $space-xx-small $space-x-small !important;
  }
}
.ooc-list-item__title {
  align-self: flex-start;
  overflow: visible;
}
.ooc-list-item__syntax-element {
  opacity: 0.5;
  overflow: visible;
}
.ooc-list-item__subtitle {
  font-style: italic;
  color: rgb(var(--v-theme-color));
  overflow: visible;
}
</style>
