<template>
  <div>
    <slot v-if="show" :show="show" />
    <div v-else class="element-placeholder">
      <span class="node-color" :style="{ backgroundColor: nodeColor }" />
      <div class="content-placeholder" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({
  name: 'NodeWrapper',
  components: {},
})
export default class NodeWrapper extends Vue {
  @Prop() color?: string;
  show = false;
  get nodeColor() {
    return this.color ? `#${this.color}` : 'rgb(var(--v-theme-cardBackground))';
  }
  mounted() {
    setTimeout(() => {
      this.show = true;
    });
  }
}
</script>

<style lang="scss" scoped>
.element-placeholder {
  min-width: 402px;
  height: 56px;
  background-color: rgb(var(--v-theme-cardBackground));
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 23px;
  left: 57px;
  z-index: 7;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.2),
    0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 1px rgba(0, 0, 0, 0.14);
}
.node-color {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: $border-radius 0 0 $border-radius;
  width: $tree-node-color-width;
  height: 100%;
}
.content-placeholder {
  width: 45%;
  height: 20px;
  background-color: #e9e9e9;
  position: absolute;
  top: 17px;
  left: 45px;
  border-radius: 4px;
}
</style>
