<template>
  <v-fade-transition>
    <v-sheet
      v-if="show"
      class="resource-table-panel cardBackground elevation-4"
      v-click-outside="{
        handler: close,
        include: include,
        closeConditional: onCloseConditional,
      }"
    >
      <slot />
    </v-sheet>
  </v-fade-transition>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';

export const resourceTablePanelPreventCloseClass =
  'resource-table-panel--prevent-close';

@Options({
  emits: ['close'],
})
export default class ResourceTablePanel extends Vue {
  @Prop(Boolean) show: boolean;
  @Prop({ default: true }) clickOutSideEnabled: boolean;

  onCloseConditional() {
    return this.clickOutSideEnabled;
  }

  close(event: MouseEvent) {
    if (!event?.defaultPrevented) {
      this.$emit('close');
    }
  }
  include() {
    return Array.from(
      document.querySelectorAll('.' + resourceTablePanelPreventCloseClass)
    );
  }
}
</script>

<style lang="scss" scoped>
.resource-table-panel {
  position: fixed;
  top: $app-topbar-height;
  right: 0;
  height: calc(100vh - #{$app-topbar-height});
  width: 320px;
  z-index: 20;
  overflow: auto;
}
</style>
