/* eslint-disable @typescript-eslint/no-explicit-any */
import type { VDataTable } from 'vuetify/components/VDataTable';
import { IndexManagerAccessors } from '@/store/components/indexManager';
import type { StyleValue } from 'vue';
import { CrudAccessors } from '@/store/components/crud';
import { PathParameters } from '@/utils/path';
import { getDictionaryFactory } from './collections';

type Flatten<T> = T extends Readonly<Array<infer U>> ? Flatten<U> : T;
export type DataTableHeader = Flatten<VDataTable['$props']['headers']>;

export type ResourceConfig = {
  module: IndexManagerAccessors & CrudAccessors;
  pathParameters?: PathParameters & { basePath?: string };
  transformResourceIndex?: (items: any[]) => any[];
} & NestedResourceConfig;

type NestedResourceConfig =
  | { nesting?: false }
  | {
      nesting: true;
      nestingModule?: IndexManagerAccessors;
      nestingRelationshipKey: string;
      nestingPathParameters?: (item: any) => PathParameters;
      transformNestedResourceIndex?: (items: any[]) => any[];
    };

export interface TableColumnDefinition {
  header: string;
  headerProps?: Pick<DataTableHeader, 'align'> & { class?: any };
  attribute: string;
  sortable?: boolean; // default true
  tdStyle?: StyleValue;
  tdClass?: string;
  tdListeners?: Record<string, (event: any) => any>;
}

export const actionsColumn: TableColumnDefinition = {
  header: 'Actions',
  sortable: false,
  attribute: 'actions',
  tdClass: 'buttons-cell',
  tdListeners: {
    click: (event: MouseEvent) => event.stopPropagation(),
  },
};

export const defaultColumns = getDictionaryFactory<TableColumnDefinition>()({
  name: { header: 'Name', attribute: 'name', tdStyle: { width: '20%' } },
  description: { header: 'Description', attribute: 'description' },
});

export const nestedRequestType = (
  parentId: string,
  relationshipKey: string
) => {
  return `nested-${parentId}-${relationshipKey}`;
};
